import Rating from '@material-ui/lab/Rating';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StarRatingContainer = styled(Rating)`
  color: ${colors.purple};
  opacity: 0.5;
  display: inline-flex;
  gap: 0.5rem;

  &.Mui-disabled {
    opacity: 1;
    pointer-events: none;
  }
  &.Mui-focusVisible .MuiRating-iconActive {
    outline: 1px solid ${colors.purple};
  }
  &.MuiRating-sizeSmall {
    font-size: 1.125rem;
  }
  &.MuiRating-sizeLarge {
    font-size: 1.875rem;
  }
  &.MuiRating-readOnly {
    opacity: 1;
  }
  .MuiRating-iconEmpty {
    color: ${colors.purple};
  }
  .MuiRating-iconActive {
    transform: scale(1.2);
  }

  .MuiRating-iconFilled {
    transform: scale(1.3);
  }
`;
