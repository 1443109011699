import { OutlinedInput } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ResponsiveDialog } from 'components/ResponsiveDialog';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DialogContainer = styled(ResponsiveDialog)``;

export const TitleContainer = styled(DialogTitle)`
  margin: 0;
  text-align: center;
  padding: ${(p) => p.theme.spacing(1.25, 5, 2, 5)};
  > * {
    font-size: 2rem;
    color: ${colors.richBlack};
  }
`;

export const ContentContainer = styled(DialogContent)`
  margin: 0;
  padding: 0px;
  margin-bottom: ${(p) => p.theme.spacing(5)}px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.spacing(0, 0, 2.5, 0)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5, 2.5, 5)};
  }
`;

export const SearchForm = styled.form`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;
`;

export const SearchField = styled(OutlinedInput)`
  color: ${colors.black};
  padding: 0;
  font-size: 0.85rem;
  margin: 0;
  flex: 1;
  > input {
    padding-top: ${(p) => p.theme.spacing(1.5)}px;
    padding-bottom: ${(p) => p.theme.spacing(1.5)}px;
  }

  &.search {
    margin-right: ${(p) => p.theme.spacing(1.25)}px;
  }
`;
