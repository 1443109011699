import { useMediaQuery, useTheme } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { UnauthBasketPlacementDialog } from 'components/feature/PortfolioBuilder/UnauthBasketPlacement/UnauthBasketPlacement';
import type { Basket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useFundsBasket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  PortfolioRebalancingStatus,
  useAccountsQuery,
  usePortfolioRebalancingsQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { dynamicPortfolioConstructionBasePath } from 'paths';
import React, { useEffect, useRef, useState } from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { MiniBasket } from './MiniBasket';
import { UnAuthMiniBasket } from './MiniBasket/MiniBasket';
import {
  Badge,
  IconWrapper,
  LoadingBasketIcon,
  UnavailableBasketIcon,
} from './MiniBasket/_shared.styles';

export interface PureBasketIconProps {
  count?: number;
  showBadge?: boolean;
  children?: React.ReactNode | JSX.Element | String;
  clickOnIcon?: boolean;
  onClick?: () => void;
  onEnter?: () => void;
  onExit?: () => void;
}

export function PureBasketIcon({
  count = 0,
  showBadge = true,
  children,
  clickOnIcon = true,
  onClick,
  onEnter,
  onExit,
  ...props
}: PureBasketIconProps) {
  const handleHover = () => {
    if (onEnter) {
      onEnter();
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'app header',
        value: 'open basket icon',
      });
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'app header',
        value: 'open basket icon',
      });
    }
  };

  return (
    <IconWrapper
      onClick={clickOnIcon ? () => {} : handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={onExit}
      {...props}
    >
      <FaShoppingBasket onClick={clickOnIcon ? handleClick : () => {}} />
      {showBadge && <Badge>{count > 0 ? count : ''}</Badge>}
      {children}
    </IconWrapper>
  );
}

export interface AllocateLocalBasketIconProps {
  onOrdersAllocated: () => void;
}

export function AllocateLocalBasketIcon({
  onOrdersAllocated,
  ...props
}: AllocateLocalBasketIconProps) {
  const location = useLocation();
  const locationPortfolioBuilder = location.pathname.includes(
    dynamicPortfolioConstructionBasePath
  );

  const [miniBasketOpen, setMiniBasketOpen] = useState(
    locationPortfolioBuilder
  );

  const handleOnClick = () => {
    setMiniBasketOpen(!miniBasketOpen);
  };

  return (
    <>
      <PureBasketIcon
        onClick={handleOnClick}
        {...props}
        clickOnIcon={false}
      ></PureBasketIcon>
      <UnauthBasketPlacementDialog
        open={miniBasketOpen}
        onClose={() => {
          setMiniBasketOpen(false);
        }}
        onItemsAllocated={() => {
          setMiniBasketOpen(false);
          onOrdersAllocated();
        }}
      />
    </>
  );
}

export interface BasketIconProps {}

const useBasketPopup = () => {
  const theme = useTheme();
  const [miniBasketOpen, setMiniBasketOpen] = useState(false);
  const timeoutRef = useRef<number>();
  const aboveLaptop = useMediaQuery(theme.breakpoints.up('md'));

  const handleOnHover = () => {
    if (!aboveLaptop) return null;
    setMiniBasketOpen(true);
    clearTimeout(timeoutRef.current);
  };

  const handleOnLeave = () => {
    if (!aboveLaptop) return null;
    timeoutRef.current = setTimeout(() => {
      setMiniBasketOpen(false);
    }, 500);
  };

  const handleOnClick = () => {
    if (aboveLaptop) return null;
    setMiniBasketOpen(!miniBasketOpen);
  };

  return {
    miniBasketOpen,
    handleOnHover,
    handleOnLeave,
    handleOnClick,
    setMiniBasketOpen,
  };
};

export function LocalBasketIcon({ ...props }: BasketIconProps) {
  const {
    miniBasketOpen,
    handleOnHover,
    handleOnLeave,
    handleOnClick,
    setMiniBasketOpen,
  } = useBasketPopup();
  const { basketBuyOrders, basketSummary } = useFundsBasket({
    selectedAccountId: null,
  });

  const count = basketSummary.orderCount;

  return (
    <PureBasketIcon
      onClick={handleOnClick}
      onEnter={handleOnHover}
      onExit={handleOnLeave}
      count={count}
      showBadge={count > 0}
      {...props}
    >
      <UnAuthMiniBasket
        open={miniBasketOpen}
        onClose={() => setMiniBasketOpen(false)}
        buyOrders={basketBuyOrders}
      />
    </PureBasketIcon>
  );
}

export function AccountBasketIcon({ ...props }: BasketIconProps) {
  const {
    miniBasketOpen,
    handleOnHover,
    handleOnLeave,
    handleOnClick,
    setMiniBasketOpen,
  } = useBasketPopup();

  const { signedIn } = useAuth();

  const userAccountsQuery = useAccountsQuery(undefined, {
    enabled: signedIn,
  });
  const accounts = userAccountsQuery.data?.accounts?.filter(
    (acc) => acc.status === AccountStatus.Active
  );

  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery(
    {
      filter: { active: true },
    },
    {
      enabled: signedIn,
    }
  );

  const baskets =
    portfolioRebalancingsQuery.data?.portfolioRebalancings.filter(
      (a) => a.status === PortfolioRebalancingStatus.Draft
    ) || null;

  const count = baskets
    ? baskets.reduce(
        (orders, a) => orders + a.buyOrders.length + a.sellOrders.length,
        0
      )
    : 0;

  return (
    <QueryState
      loadingComponent={
        <IconWrapper>
          <LoadingBasketIcon />
        </IconWrapper>
      }
      errorComponent={
        <IconWrapper>
          <UnavailableBasketIcon />
        </IconWrapper>
      }
      {...portfolioRebalancingsQuery}
    >
      {() => (
        <PureBasketIcon
          onClick={handleOnClick}
          onEnter={handleOnHover}
          onExit={handleOnLeave}
          count={count}
          showBadge={count > 0}
          {...props}
        >
          <QueryState loadingComponent={<></>} {...userAccountsQuery}>
            {() => {
              return (
                <MiniBasket
                  accounts={accounts || []}
                  baskets={baskets || []}
                  open={miniBasketOpen}
                  onClose={() => setMiniBasketOpen(false)}
                />
              );
            }}
          </QueryState>
        </PureBasketIcon>
      )}
    </QueryState>
  );
}

export function BasketIcon({ ...props }: BasketIconProps) {
  const basketType = useWhichBasketType();
  const [basketLs] = useLocalStorage<Basket>('nonAccountBasket', {});
  const hasUnknownBasket = !!basketLs.unknown;
  const [hasUnknownBasketToAllocate, setHasUnknownBasketToAllocate] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (
      hasUnknownBasketToAllocate === undefined &&
      hasUnknownBasket &&
      basketType === 'AccountBasket'
    ) {
      setHasUnknownBasketToAllocate(true);
    }
  }, [basketType, hasUnknownBasket, hasUnknownBasketToAllocate]);

  if (hasUnknownBasketToAllocate) {
    return (
      <AllocateLocalBasketIcon
        {...props}
        onOrdersAllocated={() => {
          setHasUnknownBasketToAllocate(false);
        }}
      />
    );
  } else if (basketType === 'AccountBasket') {
    return <AccountBasketIcon {...props} />;
  } else {
    return <LocalBasketIcon {...props} />;
  }
}
