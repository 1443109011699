function getCognitoContextParameters() {
  if (
    process.env.REACT_APP_COGNITO_CLIENT_ID &&
    process.env.REACT_APP_COGNITO_POOL_ID
  ) {
    return {
      clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      poolId: process.env.REACT_APP_COGNITO_POOL_ID,
    };
  } else {
    return null;
  }
}

function getCognitoUserContextData(username: string) {
  const context = getCognitoContextParameters();
  if (!context) {
    return null;
  }

  return AmazonCognitoAdvancedSecurityData.getData(
    username,
    context.poolId,
    context.clientId
  );
}

export default getCognitoUserContextData;
