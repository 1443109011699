import { colors } from 'constants/colors';
import { FaMinus, FaPlus, FaShoppingBasket } from 'react-icons/fa';
import styled from 'styled-components';

export interface typeColorProps {
  $type: 'buy' | 'sell';
}

export const ShoppingBasketIcon = styled(FaShoppingBasket)<typeColorProps>`
  color: ${({ $type }) => ($type === 'buy' ? colors.success : colors.danger)};
  margin-right: 0.25rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 0.5rem;
  }
`;

export const PlusIcon = styled(FaPlus)`
  color: ${colors.success};
  width: 0.5rem;
`;

export const MinusIcon = styled(FaMinus)`
  color: ${colors.danger};
  width: 0.5rem;
`;
