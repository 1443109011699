import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  UseFormMethods,
  useForm,
} from 'react-hook-form';
import { UnionToIntersection } from 'type-fest';
import { ObjectSchema } from 'yup';

export type WatchMethod<T extends FieldValues> = UnionToIntersection<
  UseFormMethods<T>['watch']
>;
export type ClearErrors<T extends FieldValues> = UnionToIntersection<
  UseFormMethods<T>['clearErrors']
>;

interface FormProps<T extends FieldValues> {
  children: React.ReactNode;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  onSubmit?: (
    data: UnpackNestedValue<T>,
    isDirty: boolean,
    event?: React.BaseSyntheticEvent
  ) => any | Promise<any>;
  onError?: SubmitErrorHandler<T>;
  schema?: ObjectSchema<any>;
  watcher?: (watch: WatchMethod<T>, clearErrors: ClearErrors<T>) => void;
  validationContext?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    // },
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

/**
 * @deprecated use useForm instead
 */
export function Form<T extends FieldValues>({
  defaultValues,
  schema,
  onSubmit,
  onError,
  watcher,
  children,
  validationContext: additionalValidationContext,
}: FormProps<T>) {
  const classes = useStyles();

  const validationContext = useRef<any>({
    isDirty: false,
    ...additionalValidationContext,
  });

  const methods = useForm<T>({
    defaultValues,
    resolver: schema && yupResolver(schema),
    context: validationContext.current,
  });
  const { isDirty } = methods.formState;
  validationContext.current.isDirty =
    isDirty ||
    (defaultValues && Object.values(defaultValues).some((v) => !v || v === ''));
  additionalValidationContext &&
    Object.keys(additionalValidationContext).forEach((key) => {
      validationContext.current[key] = additionalValidationContext[key];
    });

  useEffect(() => {
    watcher && watcher(methods.watch, methods.clearErrors);
  });

  const onFormSubmit = useCallback<SubmitHandler<T>>(
    (data, event?) => {
      return onSubmit && onSubmit(data, isDirty, event);
    },
    [onSubmit, isDirty]
  );

  return (
    <FormProvider {...methods}>
      <form
        className={classes.root}
        onSubmit={methods.handleSubmit(onFormSubmit, onError)}
      >
        {children}
      </form>
    </FormProvider>
  );
}
