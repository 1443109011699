import {
  assetClassColors,
  colors,
  magentaTriad,
  mauveTriad,
  purpleTriad,
} from 'constants/colors';
import { mix } from 'polished';

const textColourLookUp = [
  colors['purple-700'],
  colors['purple-600'],
  colors['purple-500'],
  colors['purple-400'],
  colors['purple-300'],
];

export const assetClassBreakdownColors: Record<string, string> = {
  ...assetClassColors,
  'Alternative investment strategies': mauveTriad['triad-200'],
  'Money Market': magentaTriad['triad+100'],
  Other: purpleTriad['triad-100'],
};

export const getTxtColourForIndex = (index: number, count: number) => {
  const proportionThrough = index / count;
  const colourLookUpIndex = Math.floor(
    proportionThrough * textColourLookUp.length
  );
  return textColourLookUp[colourLookUpIndex];
};

export const getBubbleColourForProportion = (proportion: number) => {
  return mix(proportion, colors['purple-300'], colors['purple-200']);
};

export const getTreeColourForProportion = (proportion: number) => {
  return mix(proportion, colors['mauve-500'], colors['mauve-400']);
};

export const getMapColourForProportion = (proportion: number) => {
  return mix(proportion, colors.magenta, colors['magenta-100']);
};

export const mapColourForNegativeProportion = colors['richBlue-500'];

export const getDonutColourForProportion = (proportion: number) => {
  return mix(proportion, colors['purple-500'], colors['purple-300']);
};
