import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  InstrumentSelector,
  InstrumentSelectorAlignment,
  InstrumentSelectorProps,
} from 'components/InstrumentSelector/InstrumentSelector';
import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { useFilterInstrumentsUserCanTrade } from 'hooks/useFilterInstrumentsUserCanTrade';
import styled from 'styled-components';
import { SmallShadow } from 'theme/shared/base.styles';

interface ShareClassContainerProps {
  $align: InstrumentSelectorAlignment;
  $isWide: boolean;
}

const ShareClassContainer = styled.div<ShareClassContainerProps>`
  width: 100%;
  max-width: ${({ $isWide }) => ($isWide ? '1280px' : '1000px')};
  margin: 80px auto;
  background-color: ${colors.white};
  border: 1px solid ${colors['grey-100']};
  border-radius: 8px;
  padding: 1rem;
  ${SmallShadow}

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: ${({ $isWide }) => ($isWide ? '40px auto' : '40px auto 80px auto')};
  }
`;

interface ShareClassesBoxProps extends InstrumentSelectorProps {
  $isWide?: boolean;
}

export function ShareClassesBox({
  instruments,
  $align = InstrumentSelectorAlignment.left,
  $isWide = true,
  ...props
}: ShareClassesBoxProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const filteredInstruments = useFilterInstrumentsUserCanTrade(instruments);

  if (
    filteredInstruments.length < 2 ||
    !filteredInstruments.every((i) => i.incomeAccumulationFlag)
  ) {
    return null;
  }

  return (
    <ShareClassContainer $isWide={$isWide} $align={$align}>
      {$align === InstrumentSelectorAlignment.center && !isMobile ? (
        <H4 $textAlign={TextAlign.center}>Available share classes</H4>
      ) : (
        <H4>Available share classes</H4>
      )}
      <InstrumentSelector
        {...props}
        instruments={filteredInstruments}
        $align={$align}
      />
    </ShareClassContainer>
  );
}
