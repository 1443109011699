import { Card } from 'components/design-system/Card/Card';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { CardContainer } from 'components/design-system/Card/Card';

export const MyFundsFundCardLayout = styled.div`
  display: grid;
  align-items: stretch;
  gap: 0.75rem 1.25rem;
  grid-template-areas: 'icon' 'content' 'actions';
  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 3rem 1fr;
    grid-template-areas: 'icon content actions';
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 3rem 1fr auto;
    grid-template-areas: 'icon content actions';
  }
`;

export const AssetIconContainer = styled.div`
  grid-area: icon;
  align-self: flex-start;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-self: center;
  }
  svg,
  img {
    flex-shrink: 0;
    width: 100%;
    max-width: 3rem;
  }
`;

export const ActionsWrapper = styled.div`
  grid-area: actions;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: column;
    justify-content: center;
  }
`;

interface StyledChevronButtonProps {
  $color?: string;
}

export const StyledChevronButton = styled.button<StyledChevronButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: 0;
  border-radius: 100%;
  justify-self: flex-end;
  background-color: transparent;
  padding: 0;
  align-self: center;
  cursor: pointer;
  color: ${({ $color }) => ($color === 'light' ? colors.white : 'inherit')};
  &:hover {
    background-color: ${({ $color }) =>
      $color === 'light' ? colors.darkGrey : colors.grey};
  }
`;

export const DetailsOverview = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.7fr 0.8fr 32px;
  padding-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr 1fr 32px;
  }
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.7fr 0.8fr 32px;
  gap: 0.5rem;
  align-items: center;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr 1fr 32px;
  }
`;

export const DarkUniverseCard = styled(Card)`
  background-color: black;
  p,
  h6 a {
    color: white;
    font-weight: 400 !important;
  }
`;

export const StyledCardsContainer = styled(CardContainer)`
  padding-bottom: 1rem;
`;

export const WrappableText = styled(Text)`
  display: grid;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
