import { Tab } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { StyledNavLink, StyledTabs, StyledTavNav } from './TabNav.styles';
import { useLocation } from 'react-router-dom';

export interface TabNavProps {
  navItems: {
    label: string;
    href: string;
  }[];
}

export function TabNav({ navItems }: TabNavProps) {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const refTabNav = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (activeTab === null) return;
    const index = navItems.findIndex((nav) => nav.href === location.pathname);
    setActiveTab(index > -1 ? index : 0);
  }, [activeTab, location.pathname, navItems]);

  const scrollToTopOfSection = () => {
    const sibling = refTabNav.current?.nextSibling as HTMLElement;
    if (sibling !== null && sibling !== undefined) {
      const headerOffset = 128; // the header is always 80px height and the sub nav has a fixed height of 48px, so we no longer need different calculations
      const y = sibling.offsetTop - headerOffset;
      window.scrollTo(0, y);
    }
  };

  return (
    <StyledTavNav ref={refTabNav}>
      <StyledTabs
        value={activeTab || 0}
        onChange={handleChange}
        variant="scrollable"
      >
        {navItems.map(({ href, label }, index) => (
          <Tab
            key={index}
            label={
              <StyledNavLink
                to={href}
                exact={index === 0}
                onClick={scrollToTopOfSection}
              >
                {label}
              </StyledNavLink>
            }
          />
        ))}
      </StyledTabs>
    </StyledTavNav>
  );
}
