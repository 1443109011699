import { FormState as FormStateType, useFormContext } from 'react-hook-form';

export interface FormStateProps {
  children: (props: FormStateType<any>) => JSX.Element;
}

export function FormState({ children }: FormStateProps) {
  const { formState } = useFormContext();
  return children(formState);
}
