import {
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { useQueryClient } from 'react-query';

export function useUpdateInitialAccountTypeMutation() {
  const queryClient = useQueryClient();

  return useUpdateUserProfileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
    },
  });
}
