import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import { SkipButton } from 'components/design-system/StepComponents/StepComponents';
import { trackGa } from 'helpers/track';
import { useCallback, useEffect, useState } from 'react';

interface ResendCodeButtonProps {
  disabled?: boolean;
  action: () => Promise<void>;
}

export function CooldownButton({
  disabled = false,
  action,
}: ResendCodeButtonProps) {
  const [cooldown, setCooldown] = useState(30);

  const handleClick = useCallback(async () => {
    setCooldown(30);
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
      mfaStatus: 'resent',
    });
    await action();
  }, [action]);

  useEffect(() => {
    let timer: null | number = null;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown((prev) => prev - 1), 1000);
    }
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [cooldown]);

  return (
    <SkipButton
      type="button"
      className="richBlue"
      onClick={handleClick}
      variant="outlined"
      disabled={disabled || cooldown > 0}
    >
      Resend code {cooldown > 0 ? ` (${cooldown}s)` : null}
    </SkipButton>
  );
}
