import { useAuth } from 'context/AuthContext';
import { useClientSummaryPositionsQuery } from 'generated/graphql';
import { useMemo } from 'react';

export function useUserHolds(isin: string | string[]) {
  const isins = useMemo(() => (!Array.isArray(isin) ? [isin] : isin), [isin]);

  const { signedIn } = useAuth();

  const { isError, isLoading, data } = useClientSummaryPositionsQuery(
    undefined,
    {
      enabled: signedIn,
    }
  );

  return useMemo(() => {
    if (!signedIn || isError || isLoading || !isins.length || !isins[0]) {
      return false;
    }

    const positions = data!.clientSummary!.positions;

    return positions.some((p) => isins.some((isin) => isin === p.isin));
  }, [data, isError, isLoading, isins, signedIn]);
}
