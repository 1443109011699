import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import { trackGa } from 'helpers/track';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export interface SetTotpMfaMethodSuccessStepProps {
  onProceed?: () => void;
  switchedFromSms: boolean;
  proceedBtnText: string;
}

export function SetTotpMfaMethodSuccessStep({
  onProceed,
  switchedFromSms,
  proceedBtnText,
}: SetTotpMfaMethodSuccessStepProps) {
  const handleContinue = () => {
    trackGa({
      event: GaEventNames.mfa,
      method: 'TOTP',
    });
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
      mfaStatus: 'complete',
    });
    onProceed?.();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Authenticator set</StepTitle>
        <StepIntroduction>
          <StepIntroductionTypography>
            From now on, to log in you'll use your username, password and a
            one-time code generated by your authenticator app.
          </StepIntroductionTypography>
          {switchedFromSms && (
            <StepIntroductionTypography>
              You will no longer be sent an SMS message when logging in.
            </StepIntroductionTypography>
          )}
        </StepIntroduction>
      </StepContent>
      <StepActions>
        <StepButton className="magenta" onClick={handleContinue}>
          {proceedBtnText}
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
