import { useAccountsQuery } from 'generated/graphql';
import sortBy from 'lodash/sortBy';
import { useFundsBasket } from '../useFundsBasket';
import { GetAssetGroupString } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';
import { sortingSelector } from './sortingHelpers';

interface IBreakdownObj {
  [key: string]: {
    amount: number;
  };
}

export interface BasketBreakdownType {
  name: string;
  amount: number;
  percentage: number;
}

export const useGetBasketBreakdown = (
  selectedAccountId: string | null,
  getAssetGroupString: GetAssetGroupString,
  includeAsset?: IncludeAssetType
) => {
  const {
    basketBuyOrders: basket,
    basketSellOrders,
    instrumentsByIsinQuery,
  } = useFundsBasket({
    selectedAccountId,
    includeBreakdown: true,
  });
  const selectedAccount = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  }).data?.accounts?.find((account) => account.id === selectedAccountId);

  const combinedBuySellBasket = [...basket, ...basketSellOrders];

  const basketBreakdownObj = combinedBuySellBasket.reduce<IBreakdownObj>(
    (value, basketItem) => {
      const position = selectedAccount?.positions.find((position) => {
        return position.isin === basketItem.isin;
      });

      const instrument = instrumentsByIsinQuery.data?.instrumentsByIsins?.nodes.find(
        (instrument) => {
          return instrument.isin === basketItem.isin;
        }
      );

      const asset = position?.instrument?.asset || instrument?.asset!;

      if (includeAsset && !includeAsset(asset)) {
        return value;
      }

      const region = getAssetGroupString(asset);

      const amount =
        basketItem.type === 'buyOrder' ? basketItem.amount : -basketItem.amount;

      return {
        ...value,
        [region]: {
          amount: amount + (value[region]?.amount || 0),
        },
      };
    },
    {}
  );

  const totalBasketAmount = Object.values(basketBreakdownObj).reduce(
    (value, item) => value + item.amount,
    0
  );

  const unorderedBasketBreakdowns: BasketBreakdownType[] = basketBreakdownObj
    ? Object.keys(basketBreakdownObj).map((breakdownName) => ({
        name: breakdownName,
        amount: basketBreakdownObj[breakdownName].amount,
        percentage:
          (basketBreakdownObj[breakdownName].amount / totalBasketAmount) * 100,
      }))
    : [];
  const basketBreakdowns = sortBy(unorderedBasketBreakdowns, sortingSelector);

  return { basketBreakdownObj, basketBreakdowns, totalBasketAmount };
};
