import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import countries from 'assets/countries.json';
import { FormState } from 'components/Form/FormState';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const nationalitySchema = Yup.object().shape({
  nationality: Yup.string()
    .defined()
    .label('Country of nationality')
    .default('')
    .required(),
  otherNationalities: Yup.array()
    .default([])
    .of(Yup.string().ensure())
    .ensure(),
});

type NationalityFormValues = Yup.Asserts<typeof nationalitySchema>;

interface OptionsType {
  value: string;
  label: string;
}

export function NationalityStep({ onProceed, onGoBack }: StepProps) {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const validationContext = useRef<any>({});
  const [selectedNationalities, setSelectedNationalities] = useState<
    Array<string>
  >([]);

  const countryOptions: OptionsType[] = useMemo(
    () =>
      countries.map((c) => ({
        value: c.code,
        label: c.name,
      })),
    []
  );

  const userProfile = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileQuery.getKey()
  )?.userProfile!;

  const defaultValues = nationalitySchema.cast({
    nationality: userProfile.nationality || '',
    otherNationalities: userProfile.otherNationalities || [],
  });

  const selectedNationalityOptions =
    countryOptions.find(
      (opt: OptionsType) => opt.value === userProfile.nationality
    ) ?? null;

  const selectedNationalitiesOptions =
    countryOptions.filter((opt: OptionsType) =>
      userProfile?.otherNationalities?.includes(opt.value)
    ) ?? null;

  const {
    mutateAsync,
    isLoading: mutatingUserProfile,
    isError,
  } = useUpdateUserProfileMutation();

  const validationSchema = nationalitySchema;

  const [hasMultipleNationalities, setHasMultipleNationalities] = useState(
    !!defaultValues.otherNationalities?.length
  );

  const methods = useForm<NationalityFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: validationContext.current,
    shouldUnregister: false,
  });

  const {
    formState,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    trigger,
    control,
  } = methods;
  const { isDirty } = formState;

  validationContext.current.formState = formState;

  const watching = watch(['nationality', 'otherNationalities']);

  const otherNationalitiesStr = _.sortBy(watching.otherNationalities).join(',');

  useEffect(() => {
    const updatedNationalities = _([
      watching.nationality,
      ...watching.otherNationalities,
    ])
      .compact()
      .sortBy()
      .value();

    if (!_.isEqual(selectedNationalities, updatedNationalities)) {
      clearErrors('nationalIdentifiers');
      setValue('nationalIdentifiers', [], { shouldDirty: true });
      setSelectedNationalities(updatedNationalities);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, setValue, watching.nationality, otherNationalitiesStr]);

  const onSubmit = async (data: NationalityFormValues) => {
    if (isDirty) {
      await mutateAsync(
        {
          input: {
            nationality: {
              main: data.nationality,
              other: data.otherNationalities.filter(
                (n: string) => n !== data.nationality
              ),
            },
          },
        },
        {
          onSuccess: (_, { input }) => {
            queryClient.setQueryData<UserProfileQuery>(
              useUserProfileQuery.getKey(),
              (oldData) => {
                return {
                  userProfile: {
                    ...oldData?.userProfile!,
                    nationality: input.nationality?.main,
                    otherNationalities: input.nationality?.other,
                  },
                };
              }
            );
          },
        }
      );

      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.nationality,
        nationality: [data.nationality, ...data.otherNationalities].join(', '),
      });
    }

    onProceed();
  };

  const handleBack = () => {
    onGoBack();
  };

  const handleMultipleNationalities = (checked: boolean) => {
    if (!checked) {
      setValue('otherNationalities', [], { shouldDirty: true });
    }
    setHasMultipleNationalities(checked);
  };

  const isLoading = mutatingUserProfile;

  const customStyles = {
    control: (baseStyles: any) => ({
      ...baseStyles,
      background: 'transparent',
      border: '0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.8)',
      borderRadius: '0',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.8)',
      },
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      boxShadow: 'none',
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      color: colors.richBlue,
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      padding: '0',
      background: 'white',
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      color: colors.richBlue,
    }),
    multiValue: (baseStyles: any) => ({
      ...baseStyles,
      background: colors.clouds,
      paddingLeft: '4px',
      fontSize: '1rem',
    }),
    multiValueRemove: (baseStyles: any) => ({
      ...baseStyles,
      '&:hover': {
        background: colors.clouds,
      },
    }),
    multiValueLabel: () => ({
      color: colors.richBlue,
      fontSize: '1rem',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (baseStyles: any) => ({
      ...baseStyles,
      '*': {
        color: colors.richBlack,
      },
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      backgroundColor: colors.white,
      maxWidth: '30rem',
      borderRadius: '0.5rem',
      overflow: 'hidden',
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.04)' : 'white',
      color: state.isSelected ? 'black' : 'black',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    }),
  };

  return (
    <StepContainer>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <StepContent>
            <StepTitle>What’s your nationality?</StepTitle>

            <ServerError isVisible={isError} />

            <StepFormContainer>
              <Controller
                control={control}
                name="nationality"
                render={({ onChange }) => (
                  <>
                    <Select<OptionsType>
                      placeholder="Country of nationality"
                      isDisabled={isLoading}
                      options={countryOptions}
                      defaultValue={selectedNationalityOptions}
                      onChange={(selectedOption) => {
                        onChange(selectedOption?.value);
                        trigger();
                      }}
                      styles={customStyles}
                    />
                  </>
                )}
              />
              <FormControlLabel
                disabled={isLoading}
                control={
                  <Checkbox
                    checked={hasMultipleNationalities}
                    onChange={(e) =>
                      handleMultipleNationalities(e.target.checked)
                    }
                    disabled={isLoading}
                  />
                }
                label={'I have multiple nationalities'}
              />

              {hasMultipleNationalities && (
                <Controller
                  control={control}
                  name="otherNationalities"
                  render={({ onChange }) => (
                    <Select<OptionsType, true>
                      placeholder="Other countries (select all)"
                      isDisabled={isLoading}
                      options={countryOptions}
                      defaultValue={selectedNationalitiesOptions}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions?.map(
                          (option: OptionsType) => option.value
                        );
                        onChange(selectedValues);
                        trigger();
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      isClearable={false}
                      hideSelectedOptions={false}
                    />
                  )}
                />
              )}
            </StepFormContainer>
          </StepContent>

          <StepActions>
            <FormState>
              {({ isSubmitting }) => (
                <>
                  <StepButton
                    type="submit"
                    className="magenta"
                    disabled={isSubmitting || isLoading}
                  >
                    Continue
                  </StepButton>
                  <GoBackButton onClick={handleBack} disabled={isSubmitting} />
                </>
              )}
            </FormState>
          </StepActions>
        </FormProvider>
      </form>
    </StepContainer>
  );
}
