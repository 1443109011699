import { PendingOrdersIndicator } from 'components/HoldingCard/HoldingCard.styles';
import { AssetIcon } from 'components/Icons';
import { QueryState } from 'components/QueryState';
import { Seo } from 'components/Seo/Seo';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H6, assetClasses } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import { Pill } from 'components/design-system/Pill/Pill';
import {
  FontSize,
  FontStyle,
  FontWeight,
  Text,
  TextLarge,
  TextXS,
} from 'components/design-system/Text/Text';
import { getKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import {
  LocalRecurringOrder,
  useAutoSaveInvestState,
} from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import {
  Asset,
  WrapperType,
  useAccountsQuery,
  useAssetInstrumentsQuery,
} from 'generated/graphql';
import { filterPositions } from 'helpers/filterPositions';
import {
  getHoldingName,
  getInstrumentSelectorLabel,
} from 'helpers/instrumentNaming';
import { isDarkUniversePosition, sortPositions } from 'helpers/positionHelpers';
import { trackGa } from 'helpers/track';
import { generateFundDetailsPath } from 'paths';
import { useCallback, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccount,
  AccountsQueryAccountPosition,
  AccountsQueryAccountPositionInstrument,
} from 'types/graphqlTypes';
import { AddBuyOrderToBasketDialog } from '../AddToBasket/AddBuyOrderToBasketDialog';
import {
  AddSellOrderToBasketDialog,
  useDialog,
} from '../AddToBasket/AddSellOrderToBasketDialog';
import { NoMatchCopy } from '../FundsBasket/FundsBasket.styles';
import { useFundsBasket } from '../hooks/useFundsBasket';
import {
  ActionsWrapper,
  AssetIconContainer,
  DarkUniverseCard,
  DetailsContainer,
  DetailsOverview,
  MyFundsFundCardLayout,
  StyledCardsContainer,
  StyledChevronButton,
  WrappableText,
} from './MyFundsFundCard.styles';

interface MyFundCardProps {
  canSell?: boolean;
  canBuy?: boolean;
  index: number;
  position: AccountsQueryAccountPosition;
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
  selectedAccount: AccountsQueryAccount;
  instrumentsHeld: AccountsQueryAccountPositionInstrument[];
  action?: ({
    instrument,
  }: {
    instrument: AccountsQueryAccountPositionInstrument;
  }) => JSX.Element;
  getInstrumentRegularOrder: (isin: string) => LocalRecurringOrder | undefined;
}

const MyFundCard = ({
  canBuy = true,
  canSell = true,
  index,
  position,
  selectedAccountId,
  selectedAccountType,
  instrumentsHeld,
  action,
  getInstrumentRegularOrder,
}: MyFundCardProps) => {
  const Action = action;
  const [showDetails, setShowDetails] = useState(false);
  const { get } = useFundsBasket({ selectedAccountId });
  const sellDialogProps = useDialog();
  const buyDialogProps = useDialog();
  const isActive = position.instrument?.status === 'Active';

  const instrumentsQuery = useAssetInstrumentsQuery(
    {
      id: position?.instrument?.asset?.id!,
    },
    { enabled: !!position?.instrument?.asset?.id }
  );

  const shareClass = useCallback(() => {
    return instrumentsQuery.data?.asset?.instruments
      ? getInstrumentSelectorLabel(
          position?.instrument!,
          instrumentsQuery.data?.asset?.instruments.nodes
        )
      : null;
  }, [instrumentsQuery.data?.asset?.instruments, position?.instrument]);

  if (!position.instrument || !position.instrument.asset) {
    return null;
  }
  const asset = position.instrument.asset;
  const assetClassName = (asset.assetClass?.name as assetClasses) || undefined;
  const existingBuyOrder =
    typeof asset?.id === 'number' ? get(position.instrument.isin) : undefined;
  const pendingSell = position.pendingSellAmount;

  const handleAddSellToBasket = () => {
    sellDialogProps.openDialog();
  };
  const handleAddBuyToBasket = () => {
    buyDialogProps.openDialog();
  };

  const trackFundOpen = (asset: PartialDeep<Asset>, index: number) => {
    const tags = asset.tags?.nodes
      ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
      : [];

    trackGa({
      event: GaEventNames.selectItem,
      ecommerce: {
        items: [
          {
            item_id: asset.id,
            item_name: asset.name,
            item_type: asset.assetClass?.name,
            affiliation: 'Tillit',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
            index: index,
            item_list_name: 'DPC MyFunds list',
          },
        ],
      },
    });
  };

  const isDarkUniverse = isDarkUniversePosition(position);

  const regularOrder = getInstrumentRegularOrder(position.instrument.isin);

  const FundCard = isDarkUniverse ? DarkUniverseCard : Card;
  return (
    <QueryState {...instrumentsQuery}>
      {() => (
        <>
          <AddSellOrderToBasketDialog
            asset={asset}
            selectedPosition={position}
            selectedAccountType={selectedAccountType}
            selectedAccountId={selectedAccountId}
            {...sellDialogProps}
          />
          <AddBuyOrderToBasketDialog
            {...buyDialogProps}
            asset={asset}
            selectedPosition={position}
            selectedAccountType={selectedAccountType}
            selectedAccountId={selectedAccountId}
          />
          <FundCard>
            <MyFundsFundCardLayout>
              <AssetIconContainer>
                {!isDarkUniverse && assetClassName && (
                  <AssetIcon name={assetClassName} />
                )}
              </AssetIconContainer>
              <div>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <H6
                    assetClass={assetClassName}
                    darkUniverse={isDarkUniverse || false}
                    $noMargin
                  >
                    <Link
                      to={{
                        pathname: generateFundDetailsPath({
                          id: asset?.id!,
                          slug: asset?.slug!,
                        }),
                        state: {
                          shouldGoBack: true,
                        },
                      }}
                      onClick={() => trackFundOpen(asset, index)}
                    >
                      {position?.hasPendingOrders && <PendingOrdersIndicator />}
                      {getHoldingName(position?.instrument!, instrumentsHeld)}
                    </Link>{' '}
                  </H6>
                  {regularOrder && (
                    <Pill $fontSize={FontSize.xs}>Regular order</Pill>
                  )}
                </div>
                {!isDarkUniverse && (
                  <Text $noMargin $fontStyle={FontStyle.italic}>
                    {asset.description}
                  </Text>
                )}
                <DetailsOverview>
                  {typeof position.currentValue === 'number' && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>Current value:</strong>{' '}
                      {format.currencyFull(position.currentValue)}
                    </WrappableText>
                  )}
                  {!!position.allocation && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>Current position:</strong>{' '}
                      {format.percent(position.allocation)}
                    </WrappableText>
                  )}
                  {!!position.quantity && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>Units held:</strong>{' '}
                      {format.units(
                        pendingSell
                          ? position.quantity - pendingSell
                          : position.quantity
                      )}
                    </WrappableText>
                  )}
                  {typeof position.growthProportion === 'number' && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>Return to date:</strong>{' '}
                      {format.percent(position.growthProportion)}
                    </WrappableText>
                  )}
                  <StyledChevronButton
                    onClick={() => {
                      setShowDetails(!showDetails);
                    }}
                    $color={isDarkUniverse ? 'light' : undefined}
                  >
                    {showDetails ? (
                      <HiChevronUp size="1.5rem" />
                    ) : (
                      <HiChevronDown size="1.5rem" />
                    )}
                  </StyledChevronButton>
                </DetailsOverview>
                <DetailsContainer
                  style={{
                    maxHeight: showDetails ? '5rem' : 0,
                    paddingTop: showDetails ? '0.5rem' : 0,
                  }}
                >
                  {!!position?.instrument?.asset?.ongoingChargePercent && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>OCF:</strong>{' '}
                      {position.instrument.asset.ongoingChargePercent}%
                    </WrappableText>
                  )}
                  {shareClass() && (
                    <WrappableText
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <strong>Share class:</strong> {shareClass()}
                    </WrappableText>
                  )}

                  {regularOrder && (
                    <>
                      {' '}
                      <WrappableText
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $noMargin
                      >
                        <strong>Regular order percentage:</strong>{' '}
                        {format.percent3dp(
                          regularOrder.proportion,
                          undefined,
                          false
                        )}
                      </WrappableText>
                    </>
                  )}
                  {!!position?.instrument?.factsheetUrl && (
                    <TextXS
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $noMargin
                    >
                      <StyledLink
                        href={position.instrument.factsheetUrl}
                        target="_blank"
                      >
                        Factsheet
                      </StyledLink>
                    </TextXS>
                  )}
                  {!!position?.instrument?.kiidDocumentUrl &&
                    position?.instrument?.instrumentType && (
                      <TextXS
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $noMargin
                      >
                        <StyledLink
                          href={position.instrument.kiidDocumentUrl}
                          target="_blank"
                        >
                          {getKidDocName(position?.instrument?.instrumentType)}
                        </StyledLink>
                      </TextXS>
                    )}
                </DetailsContainer>
              </div>

              {Action ? (
                <ActionsWrapper>
                  <Action instrument={position?.instrument!} />
                </ActionsWrapper>
              ) : (
                <ActionsWrapper>
                  {asset?.id && canBuy && isActive && (
                    <CustomButtonV2
                      $size="extraSmall"
                      onClick={handleAddBuyToBasket}
                    >
                      {existingBuyOrder?.type === 'buyOrder'
                        ? 'Update buy basket'
                        : 'Buy more'}
                    </CustomButtonV2>
                  )}
                  {asset?.id && canSell && isActive && (
                    <CustomButtonV2
                      $size="extraSmall"
                      $color={isDarkUniverse ? 'light' : 'richBlack'}
                      disabled={
                        position?.currentValue! <= 0 &&
                        position?.hasPendingOrders
                      }
                      onClick={handleAddSellToBasket}
                    >
                      {existingBuyOrder?.type === 'sellOrder'
                        ? 'Update sell basket'
                        : 'Sell'}
                    </CustomButtonV2>
                  )}
                </ActionsWrapper>
              )}
            </MyFundsFundCardLayout>
          </FundCard>
        </>
      )}
    </QueryState>
  );
};

interface MyFundsListProps {
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
  action?: ({
    instrument,
  }: {
    instrument: AccountsQueryAccountPositionInstrument;
  }) => JSX.Element;
}

export const MyFundsList = ({
  selectedAccountId,
  selectedAccountType,
  action,
}: MyFundsListProps) => {
  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const selectedAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.id === selectedAccountId
  );
  const { state } = useAutoSaveInvestState();

  const getInstrumentRegularOrder = (isin: string) => {
    return state?.orders?.find((order) => order.isin === isin);
  };

  const hasNoPositions =
    selectedAccount &&
    selectedAccount.positions.filter((p) => !!p.isin && !!p.instrument)
      .length === 0;

  const [inUniversePositions] = filterPositions(
    selectedAccount?.positions || []
  );
  const instrumentsHeld = inUniversePositions.map((p) => p.instrument!);

  const sortedPositions = sortPositions(selectedAccount?.positions || []);

  return (
    <div>
      <Seo title="My funds" description="Invest in funds picked by experts" />
      <StyledCardsContainer>
        {!selectedAccountId && (
          <NoMatchCopy>
            <TextLarge $noMargin $fontWeight={FontWeight.normal}>
              No funds
            </TextLarge>
          </NoMatchCopy>
        )}
        {hasNoPositions ? (
          <NoMatchCopy>
            <TextLarge $noMargin $fontWeight={FontWeight.normal}>
              You don't currently hold any funds
            </TextLarge>
          </NoMatchCopy>
        ) : (
          selectedAccount &&
          sortedPositions.map((position, index) => (
            <MyFundCard
              key={position.isin}
              index={index}
              position={position}
              selectedAccount={selectedAccount}
              selectedAccountId={selectedAccountId}
              selectedAccountType={selectedAccountType}
              instrumentsHeld={instrumentsHeld}
              getInstrumentRegularOrder={getInstrumentRegularOrder}
              action={action}
            />
          ))
        )}
      </StyledCardsContainer>
    </div>
  );
};
