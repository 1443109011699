import { Table } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

interface StyledTableProps {
  $dark?: boolean;
}

export const StyledTable = styled(Table)<StyledTableProps>`
  .MuiTableCell-head {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 300;
    padding: ${(p) => p.theme.spacing(0, 2.5, 2.5, 2.5)};
    border-bottom: 1px solid ${colors.lightGrey};

    color: ${(p) => (p.$dark ? colors.white : colors.richBlack)};
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      border-radius: none;
      background-color: ${transparentize(0.95, colors.purple)};

      &:nth-child(2n) {
        background-color: ${(p) =>
          p.$dark ? transparentize(0.9, colors.white) : colors.white};
      }
    }
    .MuiTableCell-body {
      border-bottom: 1px solid ${colors.lightGrey};
      font-size: 1rem;
      font-weight: 400;
      padding: ${(p) => p.theme.spacing(1.25, 2.5)};
      color: ${(p) => (p.$dark ? colors.white : colors.richBlack)};
    }
  }
`;

interface MobileListContainerProps {
  $dark: boolean;
}

export const MobileListContainer = styled.div<MobileListContainerProps>`
  color: ${(p) => (p.$dark ? colors.white : colors.richBlue)};

  & > * + * {
    margin-top: ${(p) => p.theme.spacing(2)}px;
  }
`;

StyledTable.defaultProps = {
  $dark: false,
};

MobileListContainer.defaultProps = {
  $dark: false,
};
