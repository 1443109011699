import { QueryState } from 'components/QueryState';
import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { useDepositDetailsByAccountQuery } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import {
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
} from 'paths';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { AccountsQueryAccount } from 'types/graphqlTypes';
import { useEffectOnce } from 'usehooks-ts';
import {
  LocalRecurringDeposits,
  useAutoSaveInvestState,
} from '../AutoSaveInvestContext';
import { AmountStep } from './steps/AmountStep';
import { CantDeposit } from './steps/CantDeposit';
import { DayStep } from './steps/DayStep';
import { IntroductionStep } from './steps/IntroductionStep';
import { InvestOrHoldAsCashStep } from './steps/InvestOrHoldAsCashStep';

enum MultistepFormSteps {
  Introduction = 'Introduction',
  Day = 'Day',
  Amount = 'Amount',
  InvestOrHoldAsCash = 'InvestOrHoldAsCash',
  Instructions = 'Instructions',
}

export interface AutoSaveInvestProps {
  action: 'setup' | 'setup-auto-invest';
  account: AccountsQueryAccount;
}

export function AutoSaveInvestInner({ account, action }: AutoSaveInvestProps) {
  const [, setMode] = useMode();

  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.multiStepFormStart,
    });
  });

  const { state, setState } = useAutoSaveInvestState();

  const [paymentDate, setPaymentDate] = useState<number | undefined>();
  const [amount, setAmount] = useState<number | undefined>();

  const [activeStep, setActiveStep] = useState<MultistepFormSteps>(
    MultistepFormSteps.Introduction
  );

  const recurringTransactionsDeposits = state?.deposits ?? [];
  const recurringTransactionsOrders = state?.orders ?? [];

  const setRecurringTransactionsDeposits = (
    newDeposits: LocalRecurringDeposits[]
  ) => {
    setState({ ...state, deposits: newDeposits });
  };

  const history = useHistory();

  return (
    <div>
      {activeStep === MultistepFormSteps.Introduction && (
        <IntroductionStep
          wrapperType={account.wrapperType}
          onProceed={() => {
            trackGa({
              event: GaEventNames.multiStepFormStep,
              [GaProperties.form]: `auto-save-invest - ${action}`,
              [GaProperties.formStep]: 'introduction',
            });
            setActiveStep(MultistepFormSteps.Day);
          }}
        />
      )}
      {account.id && activeStep === MultistepFormSteps.Day && (
        <DayStep
          accountId={account.id}
          paymentDate={paymentDate}
          onProceed={(newPaymentDate) => {
            trackGa({
              event: GaEventNames.multiStepFormStep,
              [GaProperties.form]: `auto-save-invest - ${action}`,
              [GaProperties.formStep]: 'day',
            });
            setPaymentDate(newPaymentDate);
            setActiveStep(MultistepFormSteps.Amount);
          }}
          onGoBack={() => {
            setActiveStep(MultistepFormSteps.Introduction);
          }}
        />
      )}
      {account.id && paymentDate && activeStep === MultistepFormSteps.Amount && (
        <AmountStep
          account={account}
          wrapperType={account.wrapperType}
          amount={amount}
          onProceed={(newAmount) => {
            trackGa({
              event: GaEventNames.multiStepFormStep,
              [GaProperties.form]: `auto-save-invest - ${action}`,
              [GaProperties.formStep]: 'amount',
            });

            setAmount(newAmount);

            setActiveStep(MultistepFormSteps.InvestOrHoldAsCash);
          }}
          onGoBack={() => {
            setActiveStep(MultistepFormSteps.Day);
          }}
        />
      )}

      {account.id &&
        paymentDate &&
        amount &&
        activeStep === MultistepFormSteps.InvestOrHoldAsCash && (
          <InvestOrHoldAsCashStep
            onProceed={(newSelectedRecurringDeposits) => {
              trackGa({
                event: GaEventNames.multiStepFormStep,
                [GaProperties.form]: `auto-save-invest - ${action}`,
                [GaProperties.formStep]: 'Invest Or hold as cash',
              });

              const anyDepositHasChanges = recurringTransactionsDeposits.some(
                (deposits) => {
                  return (
                    deposits.isDeleted || deposits.isEdited || deposits.isNew
                  );
                }
              );
              const anyOrderHasChanges = recurringTransactionsOrders?.some(
                (order) => {
                  return order.isDeleted || order.isEdited || order.isNew;
                }
              );
              const hasChanges = anyDepositHasChanges || anyOrderHasChanges;

              const newDeposit = {
                paymentDate: paymentDate!,
                amount: amount!,
                autoInvest: newSelectedRecurringDeposits === 'autoInvest',
                isNew: true,
                isEdited: false,
                isDeleted: false,
              };

              const newDeposits = [
                ...(recurringTransactionsDeposits || []),
                newDeposit,
              ];
              setRecurringTransactionsDeposits(newDeposits);

              setMode({
                wrapperType: account.wrapperType,
                mode: 'autoSaveInvest',
              });

              if (newSelectedRecurringDeposits === 'holdAsCash' && hasChanges) {
                trackGa({
                  event: GaEventNames.multiStepFormFinish,
                });
                history.push(
                  generateAutoSaveInvestSubPath({
                    action: 'confirm',
                    wrapperType: getPathSegmentForWrapperType(
                      account.wrapperType
                    ),
                  })
                );
              } else if (
                (newSelectedRecurringDeposits === 'holdAsCash' &&
                  !hasChanges) ||
                newSelectedRecurringDeposits === 'autoInvest'
              ) {
                history.push(
                  generateAutoSaveInvestPathPath({
                    wrapperType: getPathSegmentForWrapperType(
                      account.wrapperType
                    ),
                  })
                );
              }
            }}
            onGoBack={() => {
              setActiveStep(MultistepFormSteps.Amount);
            }}
          />
        )}
    </div>
  );
}

export function AutoSaveInvestNewDeposit({
  account,
  action,
}: AutoSaveInvestProps) {
  const history = useHistory();
  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery(
    {
      id: account.id as string,
    },
    {
      enabled: typeof account.id === 'string',
    }
  );
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;

  const canDeposit = depositDetailsByAccount?.canDeposit;
  const reason = depositDetailsByAccount?.statusReason;

  const { state } = useAutoSaveInvestState();

  const hasDeposits = state?.deposits && state?.deposits?.length > 0;

  useEffect(() => {
    if (hasDeposits) {
      history.push(
        generateAutoSaveInvestPathPath({
          wrapperType: getPathSegmentForWrapperType(account.wrapperType),
        })
      );
    }
  }, [hasDeposits, account.wrapperType, history]);

  return (
    <StepFormWrapper>
      <QueryState {...depositDetailsByAccountQuery}>
        {() => {
          if (!canDeposit && reason) {
            return (
              <StepWrapper>
                <CantDeposit reason={reason} />
              </StepWrapper>
            );
          }

          return (
            <StepWrapper>
              <AutoSaveInvestInner account={account} action={action} />
            </StepWrapper>
          );
        }}
      </QueryState>
    </StepFormWrapper>
  );
}
