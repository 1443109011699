import { useSelectAssetColor } from 'hooks/useAssetBrandDefinition';
import React from 'react';
import {
  TeamContainer,
  TeamHeading,
  TeamSingle,
  TeamSingleJobTitle,
  TeamSingleName,
} from './Styles/TeamContainer.style';

interface TeamProps {
  team: Array<any>;
  assetClass: string;
}

export function TeamContentContainer({ team, assetClass }: TeamProps) {
  const assetBrand = useSelectAssetColor(assetClass);

  return (
    <TeamContainer>
      {team.map((person, i) => (
        <TeamSingle key={i} selectedColour={assetBrand}>
          <TeamHeading>
            {person.jobTitle && (
              <>
                <TeamSingleName>{person.name}</TeamSingleName>{' '}
                <TeamSingleJobTitle>{person.jobTitle}</TeamSingleJobTitle>
              </>
            )}
            {!person.jobTitle && (
              <>
                <TeamSingleName>{person.name}</TeamSingleName>{' '}
              </>
            )}
          </TeamHeading>
        </TeamSingle>
      ))}
    </TeamContainer>
  );
}
