import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { SetupMfaMethodForm } from 'components/feature/setupMfa/SetupMfaMethodForm';
import { SetupSmsMfaForm } from 'components/feature/setupMfa/sms/SetupSmsMfaForm';
import { useToggle } from 'hooks/useFeatureToggle';
import { dashboardMyDetailsPath } from 'paths';
import { useHistory } from 'react-router-dom';

export function SetupMfa() {
  const history = useHistory();
  const [allowTotp] = useToggle('global-allow-totp');

  return (
    <StepFormWrapper>
      <StepWrapper>
        {allowTotp?.enabled && (
          <SetupMfaMethodForm
            onProceed={() => history.push(dashboardMyDetailsPath)}
          />
        )}
        {!allowTotp?.enabled && (
          <SetupSmsMfaForm
            onProceed={() => history.push(dashboardMyDetailsPath)}
            switchingToExistingSms={false}
          />
        )}
      </StepWrapper>
    </StepFormWrapper>
  );
}
