import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  Eligibility,
  useAccountEligibilitiesQuery,
  WrapperType,
} from 'generated/graphql';
import { useMemo } from 'react';

function useAccountEligibility(wrapperType: WrapperType) {
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery();
  return useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.find(
        (account) => account?.wrapperType === wrapperType
      )?.eligibility,
    [accountEligibilitiesQuery.data?.accountEligibilities, wrapperType]
  );
}

function useHasAccountWithEligibility(eligibility: Eligibility) {
  const { signedIn } = useAuth();
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery(undefined, {
    enabled: signedIn,
  });
  return useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.some(
        (account) => account?.eligibility === eligibility
      ),
    [accountEligibilitiesQuery.data?.accountEligibilities, eligibility]
  );
}

export function useHasUnopenedAccount() {
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery();
  return useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.find(
        (account) => account?.accountStatus === AccountStatus.Unopened
      ),
    [accountEligibilitiesQuery.data?.accountEligibilities]
  );
}

export function useCanOpenAccount(wrapperType: WrapperType) {
  const eligibility = useAccountEligibility(wrapperType);
  return eligibility === Eligibility.CanOpen;
}

export function useHasPendingAccount() {
  return useHasAccountWithEligibility(Eligibility.Pending);
}

export function useHasOnboardingAccount() {
  return useHasAccountWithEligibility(Eligibility.Onboarding);
}

export function useHasOpenableAccount() {
  return useHasAccountWithEligibility(Eligibility.CanOpen);
}
