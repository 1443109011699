import { UsePaginationProps } from '@material-ui/lab';
import { StyledPagination, StyledPaginationWrapper } from './Pagination.styles';

export interface PaginationProps {
  page: number;
  count: number;
  onChange: UsePaginationProps['onChange'];
}

export function Pagination({ page, count, onChange }: PaginationProps) {
  return (
    <StyledPagination
      page={page}
      count={count}
      hidePrevButton={page === 1}
      hideNextButton={page === count}
      onChange={onChange}
    />
  );
}

export const PaginationAlignment = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

export type PaginationAlignmentType = keyof typeof PaginationAlignment;

export interface PaginationWrapperProps {
  $alignment?: PaginationAlignmentType;
  children: React.ReactNode | JSX.Element | string;
}

export function PaginationWrapper({
  children,
  $alignment = 'start',
}: PaginationWrapperProps) {
  return (
    <StyledPaginationWrapper $align={$alignment}>
      {children}
    </StyledPaginationWrapper>
  );
}
