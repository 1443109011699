import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import min from 'lodash/min';
import styled from 'styled-components';

export const TreeBreakdownContainer = styled.div`
  position: relative;
  pointer-events: visible;
  padding: 0.5rem 0 0 0;
`;

export const TreeBreakdownSvg = styled.svg`
  max-width: 100%;
`;

export const LeafContentLayout = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  height: 100%;
`;

interface LeafHeadingProps {
  $leafWidth: number;
  $leafHeight: number;
  $weight: number;
}

const getLeafHeadingFontSize = ({
  $leafHeight,
  $leafWidth,
  $weight,
}: LeafHeadingProps) => {
  // Calculate the base font size based on the proportion - this works most of the time.
  const baseFontSize = Math.round(100 * ($weight * 5 * 0.75 + 1)) / 100;

  // The base font works most of the time
  //   - except when there is a leaf with 50%+ - the font sizes needs a cpa
  //   - except when the treemap generates a tall & narrow leaf or short & wide - then the font size can be too big
  // See breakdownSectorsExample2 OR 258 in nonprod
  const maxFontSize = $leafWidth < 100 || $leafHeight < 75 ? 0.75 : 3;

  return `${min([baseFontSize, maxFontSize])}rem`;
};

export const LeafHeading = styled(Text)<LeafHeadingProps>`
  font-size: ${getLeafHeadingFontSize};
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: ${colors.white};
  font-weight: 400;
  text-transform: uppercase;
`;

interface LeafValueProps {
  $leafWidth: number;
  $weight: number;
  $isMobile: boolean;
}

const getLeafValueFontSize = ({
  $leafWidth,
  $weight,
  $isMobile,
}: LeafValueProps) => {
  // Calculate the base font size based on the proportion - this works most of the time.
  const baseFontSize = Math.round(100 * ($weight * 5 * 4 + 1)) / 100;

  // The base font works most of the time
  //   - except when there is a leaf with 50%+ - the font sizes needs a cpa
  //   - except when the treemap generates a tall & narrow leaf - then the font size can be too big
  // See breakdownSectorsExample2 OR 258 in nonprod
  const maxFontSize =
    $leafWidth > 250
      ? 5
      : $leafWidth > 200
      ? $isMobile
        ? 4
        : 4.5
      : $leafWidth > 150
      ? $isMobile
        ? 3
        : 3.5
      : $leafWidth > 100
      ? $isMobile
        ? 1
        : 2.5
      : 1;

  return `${min([baseFontSize, maxFontSize])}rem`;
};

export const LeafValue = styled.div<LeafValueProps>`
  font-size: ${getLeafValueFontSize};
  font-family: 'GT Super', serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-top: auto;
  font-weight: 300;
  line-height: 1em;
  color: ${colors.white};
  text-align: right;
  opacity: 0.8;
`;
