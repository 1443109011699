import { WrapperType } from 'generated/graphql';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import { GetAssetGroupString } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';
import { useGetCurrentBreakdown } from './useGetCurrentBreakdown';
import { useGetRebalancingBreakdown } from './useGetRebalancingBreakdown';

export const useCombinedRebalancingBreakdown = (
  accountType: WrapperType,
  portfolioRebalancingId: string,
  getAssetGroupString: GetAssetGroupString,
  includeAsset?: IncludeAssetType
) => {
  const { accountsQuery, breakdownsObj } = useGetCurrentBreakdown(
    accountType,
    getAssetGroupString,
    includeAsset
  );
  const {
    rebalancingBreakdownObj,
    totalBasketAmount,
    portfolioRebalancingsQuery,
  } = useGetRebalancingBreakdown(
    portfolioRebalancingId,
    getAssetGroupString,
    includeAsset
  );

  const activeRegions = union(
    Object.keys(breakdownsObj || {}),
    Object.keys(rebalancingBreakdownObj || {})
  );

  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === accountType
  );
  const currentMarketValue = currentAccount?.valuationSummary?.marketValue || 0;
  const availableCash = currentAccount?.valuationSummary?.uninvestedCash || 0;

  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;

  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;

  const combinedBreakdown = sortBy(
    activeRegions.map((activeRegion) => {
      const existingAmount = breakdownsObj
        ? breakdownsObj[activeRegion]?.amount ?? 0
        : 0;

      const currentPercentage = breakdownsObj
        ? breakdownsObj[activeRegion]?.percentage ?? 0
        : 0;

      if (activeRegion === 'Cash') {
        const updateCashAmount = existingAmount - totalBasketAmount;
        return {
          name: activeRegion,
          amount: updateCashAmount > 0 ? updateCashAmount : 0,
          percentage:
            updateCashAmount > 0
              ? (updateCashAmount / currentMarketValue) * 100
              : 0,
          existingAmount,
        };
      }

      const rebalancingBreakdownPoint =
        rebalancingBreakdownObj && rebalancingBreakdownObj[activeRegion]
          ? rebalancingBreakdownObj[activeRegion]
          : { amount: 0 };
      const newAmount = rebalancingBreakdownPoint.amount;
      const amount = existingAmount + newAmount;
      const percentage = (amount / percentageOf) * 100;
      return {
        name: activeRegion,
        existingAmount,
        amount,
        existingPercentage: currentPercentage,
        percentage,
        percentageChange: percentage - currentPercentage,
      };
    }),
    [
      ({ name }) => name !== 'Pending buys',
      ({ name }) => name !== 'Cash',
      'existingPercentage',
      'percentage',
    ]
  ).reverse();

  return { combinedBreakdown, portfolioRebalancingsQuery };
};

export type CombinedBreakdown = ReturnType<
  typeof useCombinedRebalancingBreakdown
>;
