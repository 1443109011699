export function createPopup(onBeforeUnload: () => void) {
  const popup = window.open(
    undefined,
    undefined,
    'width=800,height=600,toolbar=0,menubar=0,location=0'
  );
  popup?.addEventListener('beforeunload', onBeforeUnload, false);

  return popup;
}
