import { useGetReturnPath } from 'hooks/useGetReturnPath';
import { useState } from 'react';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import { MfaStep } from '../signIn/steps/MfaStep/MfaStep';
import { useSignInTracking } from '../signIn/steps/_shared/useSignInTracking';
import { ConfirmStep } from './steps/ConfirmStep/ConfirmStep';
import { EmailStep } from './steps/Email/EmailStep';

type step = 'email' | 'confirm' | 'mfa';

/**
 * ResetPasswordForm component
 */
export const ResetPasswordForm = () => {
  const [activeStep, setActiveStep] = useState<step>('email');
  const [email, setEmail] = useState<string | undefined>();
  const returnPath = useGetReturnPath();
  const [loginResult, setLoginResult] = useState<any>();
  const trackSignIn = useSignInTracking();

  return (
    <Wrapper>
      {activeStep === 'email' && (
        <EmailStep
          onProceed={({ email }) => {
            setEmail(email);
            setActiveStep('confirm');
          }}
        />
      )}
      {email && activeStep === 'confirm' && (
        <ConfirmStep
          email={email}
          onProceed={async (confirmResetPasswordResponse) => {
            if (confirmResetPasswordResponse.requireMfa) {
              setLoginResult(confirmResetPasswordResponse);
              setActiveStep('mfa');
            } else {
              await trackSignIn(confirmResetPasswordResponse.userId);
              window.location.href = returnPath;
            }
          }}
        />
      )}
      {activeStep === 'mfa' && (
        <MfaStep
          defaultLoginResult={loginResult}
          onProceed={() => {
            window.location.href = returnPath;
          }}
        />
      )}
    </Wrapper>
  );
};
