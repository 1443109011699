import { QueryState } from 'components/QueryState';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import { GoBackButton } from 'components/design-system/StepComponents/StepComponents';
import { FontSize, Text, TextNormal } from 'components/design-system/Text/Text';
import { currencyFull } from 'formatting';
import {
  WrapperType,
  useAccountsQuery,
  useExecutePortfolioRebalancingSellsMutation,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import {
  generateDynamicPortfolioConstructionBasketPath,
  generateFundDetailsPath,
} from 'paths';
import { useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ImportantSellInformation } from '../../ImportantInformation/ImportantSellInformation';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import {
  ButtonContainer,
  FundDoLink,
  Pill,
  PillContainer,
  Section,
} from '../AddToBasketDialog.style';
import { PolicyPreTradeText } from 'components/feature/Checkout/PolicyPreTradeText/PolicyPreTradeText';

interface QuickSellOrderConfirmProps {
  quickOrderId: string;
  selectedAccountId: string;
  selectedAccountType: WrapperType;
  onProceed: () => void;
  onGoBack: () => void;
}

export const QuickSellOrderConfirm = ({
  quickOrderId,
  selectedAccountId,
  selectedAccountType,
  onProceed,
  onGoBack,
}: QuickSellOrderConfirmProps) => {
  const userProfileQuery = useUserProfileQuery();
  const employment = userProfileQuery.data?.userProfile?.employments?.[0];

  const [idempotencyToken] = useState(uuid());
  const portfolioRebalancingQuery = usePortfolioRebalancingQuery({
    id: quickOrderId,
  });
  const portfolioRebalancing =
    portfolioRebalancingQuery.data?.portfolioRebalancing;
  const sellOrder = portfolioRebalancing?.sellOrders[0];
  const instrument = sellOrder?.instrument;

  const draftPortfolioRebalancingsQuery = usePortfolioRebalancingsQuery({
    filter: { active: true },
  });
  const draftPortfolioRebalancing =
    draftPortfolioRebalancingsQuery.data?.portfolioRebalancings[0];
  const activeDraftPortfolioRebalancingsIncludesQuickOrderInstrument = draftPortfolioRebalancing?.sellOrders.some(
    (sellOrder) => sellOrder.instrument.isin === instrument?.isin
  );

  const executePortfolioRebalancingSellsMutationQuery = useExecutePortfolioRebalancingSellsMutation();
  const {
    mutateAsync,
    error,
    isLoading,
    isSuccess,
  } = executePortfolioRebalancingSellsMutationQuery;

  const { remove } = useFundsBasket({ selectedAccountId });

  const executePortfolioRebalancingSell = async () => {
    try {
      await mutateAsync({
        input: {
          idempotencyToken: idempotencyToken,
          portfolioRebalancingId: quickOrderId,
        },
      });
      await remove(sellOrder?.isin!);
      onProceed();
    } catch {
      // handled by state
    }
  };

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === selectedAccountType
  );
  const existingPosition = currentAccount?.positions?.find(
    (position) => position.isin === sellOrder?.isin
  );

  const asset = instrument?.asset;

  return (
    <div>
      <QueryState {...portfolioRebalancingQuery}>
        {() => {
          if (
            portfolioRebalancing?.buyOrders.length !== 0 &&
            portfolioRebalancing?.sellOrders.length !== 1
          ) {
            return <div>Something went wrong</div>;
          }
          return (
            <div>
              <PillContainer>
                <Pill $filled $color="sell">
                  SELL
                </Pill>
                <Pill>{getShortNameForWrapperType(selectedAccountType)}</Pill>
              </PillContainer>
              <div>
                <H4>{sellOrder?.instrument.name}</H4>
                <FundDoLink
                  to={{
                    pathname: generateFundDetailsPath({
                      id: asset?.id!,
                      slug: asset?.slug!,
                    }),
                    state: {
                      shouldGoBack: true,
                    },
                  }}
                  target="_blank"
                  // onClick={() => trackFundOpen('link', asset, index)}
                >
                  What does this fund do? <HiExternalLink />
                </FundDoLink>
              </div>
              <Section>
                <Text $fontSize={FontSize.extraLarge} $noMargin>
                  {currencyFull(sellOrder?.enteredAmount!)}
                </Text>
              </Section>
              {activeDraftPortfolioRebalancingsIncludesQuickOrderInstrument && (
                <Alert severity={Severity.info}>
                  <TextNormal $noMargin>
                    Note - you have a sell order in your{' '}
                    <StyledLink
                      as={Link}
                      to={generateDynamicPortfolioConstructionBasketPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      })}
                    >
                      basket
                    </StyledLink>{' '}
                    for this instrument - if you do a quick checkout we'll
                    remove that from your basket
                  </TextNormal>
                </Alert>
              )}

              <ImportantSellInformation
                showTopBar={false}
                selectedInstrument={sellOrder?.instrument!}
                amountEntered={sellOrder?.enteredAmount!}
                existingPosition={existingPosition!}
              />
              <Text style={{ marginTop: '1rem', textAlign: 'center' }}>
                The value of the investment sold will depend upon the time of
                execution.
              </Text>
              {employment && <PolicyPreTradeText employment={employment} />}
              <ButtonContainer>
                <CustomButtonV2
                  onClick={() => executePortfolioRebalancingSell()}
                  disabled={isLoading || isSuccess}
                >
                  Confirm Order
                </CustomButtonV2>
                {error && <div>Something went wrong</div>}
                <GoBackButton onClick={onGoBack} />
              </ButtonContainer>
            </div>
          );
        }}
      </QueryState>
    </div>
  );
};
