import { Typography } from '@material-ui/core';
import { StyledTextField } from 'components/Input';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const CantFindYourProvider = styled.button`
  background: none;
  border: none;
  color: ${colors.magenta};
  padding: 0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const AccountNumberHelpText = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(1.25)}px;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  color: ${colors.richBlue};
`;

export const NoOptionCopy = styled(Typography)`
  color: ${colors.richBlack};
  font-size: 0.75rem;
  a {
    color: ${colors.magenta};
    text-decoration: underline;
  }
`;

export const StyledInput = styled(StyledTextField)`
  position: relative;
  &.MuiTextField-root {
    label {
      top: -4px;
      font-size: 1rem;
      font-weight: 300;
    }
    .MuiInput-root {
      margin-top: 0.75rem;
      input,
      textarea {
        font-size: 1rem;
        font-weight: 300;
        color: ${colors.richBlue};
      }
    }
  }
`;
