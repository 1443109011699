import Kfd23 from 'assets/ISA_KFD.pdf';
import Kfd24 from 'assets/ISA_KFD_2024.pdf';
import { AffirmationKind } from 'generated/graphql';
import { useToggle } from './useFeatureToggle';

export enum IsaRuleset {
  TAX_YEAR_2023_24 = 'TAX_YEAR_2023_24',
  TAX_YEAR_2024_25 = 'TAX_YEAR_2024_25',
}

export const useIsaRuleset = () => {
  const [taxYear2024Rules] = useToggle('global-apply-isa-rules-update-2024-25');

  const ruleset = taxYear2024Rules?.enabled
    ? IsaRuleset.TAX_YEAR_2024_25
    : IsaRuleset.TAX_YEAR_2023_24;

  const affirmationsRequired = [AffirmationKind.ReadAndUnderstoodIsaKfd];
  if (ruleset === IsaRuleset.TAX_YEAR_2023_24) {
    affirmationsRequired.push(AffirmationKind.NoExistingIsaThisTaxYear);
  }

  return {
    ruleset,
    affirmationsRequired,
    kfd: ruleset === IsaRuleset.TAX_YEAR_2024_25 ? Kfd24 : Kfd23,
    declarationPdf:
      ruleset === IsaRuleset.TAX_YEAR_2024_25
        ? 'https://d1ur1kq4xhe5pz.cloudfront.net/images/ISA-Declaration-2024-2025.pdf'
        : 'https://d1ur1kq4xhe5pz.cloudfront.net/images/ISA-Declaration-201021.pdf',
  };
};
