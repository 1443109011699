import * as Yup from 'yup';

export interface PasswordStrengthAssessmentBreakdown {
  lengthCheck: boolean;
  containsUpperCheck: boolean;
  containsLowerCheck: boolean;
  containsNumberCheck: boolean;
  containsSpecialCheck: boolean;
}

export interface PasswordStrengthAssessment {
  pass: boolean;
  breakdown: PasswordStrengthAssessmentBreakdown;
}

const passwordSchema = Yup.string()
  .required('Password is required.')
  .min(8, 'Must be at least 8 characters.')
  .matches(/\d/, 'Must contain a number.')
  .matches(/[A-Z]/, 'Must contain an upper case letter.')
  .matches(/[a-z]/, 'Must contain a lowercase letter.')
  .matches(/[^A-Za-z\d]/, 'Must contain a special character.');

const ratePassword: (password: string) => PasswordStrengthAssessment = (
  password: string = ''
) => {
  const lengthCheck = password.length >= 8;
  const containsUpperCheck = /[A-Z]/.test(password);
  const containsLowerCheck = /[a-z]/.test(password);
  const containsNumberCheck = /[0-9]/.test(password);
  const containsSpecialCheck = /[^A-Za-z\d]/.test(password);

  return {
    pass:
      lengthCheck &&
      containsUpperCheck &&
      containsLowerCheck &&
      containsNumberCheck &&
      containsSpecialCheck,
    breakdown: {
      lengthCheck,
      containsUpperCheck,
      containsLowerCheck,
      containsNumberCheck,
      containsSpecialCheck,
    },
  };
};

export { passwordSchema, ratePassword };
