import countries from 'assets/countries.json';
import { Container } from 'components/Container/Container.style';
import { NationalIdentifierDialog } from 'components/Dialogs/NationalIdentifier/NationalIdentifierDialog';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import {
  UserProfileQuery,
  useMifidReportingNationalityQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import { useIntercom } from 'react-use-intercom';
import { Base } from './Base';
import { DoubleCol, SingleAction } from './MyDetails.style';

function getCountryName(code: string) {
  const countryObj = countries.filter((country) => {
    return country.code === code;
  });
  return countryObj[0].name;
}

type NationalIdentifierTokenised = NonNullable<
  UserProfileQuery['userProfile']
>['nationalIdentifierTokenised'];

export interface PurePersonalDetailsProps {
  fullName: string;
  NINumber?: string;
  nationality: string;
  cid?: string | null;
  otherNationalities?: Array<string>;
  nationalIdentifierTokenised: NationalIdentifierTokenised;
  identifiersProvided: boolean;
  onClick: () => void;
  onAddMifid: () => void;
}

export function PurePersonalDetails({
  fullName,
  NINumber,
  nationality,
  cid,
  otherNationalities,
  nationalIdentifierTokenised,
  identifiersProvided,
  onClick,
  onAddMifid,
}: PurePersonalDetailsProps) {
  return (
    <Base title="Personal details">
      <Container padding="none">
        <ReadOnlyInput
          id="fullName-read-only-input"
          label="Full name"
          defaultValue={fullName}
        />
        <ReadOnlyInput
          id="NI-read-only-input"
          label="National Insurance number"
          defaultValue={NINumber ?? 'Not provided'}
        />
        <ReadOnlyInput
          id="nationality-read-only-input"
          label="Primary country of nationality"
          defaultValue={
            nationality ? getCountryName(nationality) : 'Not provided'
          }
        />
        {!!otherNationalities?.length && (
          <ReadOnlyInput
            id="otherNationality-read-only-input"
            label={`Additional ${
              otherNationalities.length > 1 ? 'countries' : 'country'
            } of nationality`}
            defaultValue={otherNationalities
              .map((code) => getCountryName(code))
              .join(', ')}
          />
        )}

        {nationalIdentifierTokenised?.value?.maskedValue &&
          identifiersProvided && (
            <ReadOnlyInput
              id="NI-read-only-input"
              label="National identifier"
              defaultValue={
                nationalIdentifierTokenised?.value?.maskedValue ??
                'Not provided'
              }
            />
          )}

        {!identifiersProvided && (
          <DoubleCol>
            <ReadOnlyInput
              id="NI-read-only-input"
              label="National identifier"
              defaultValue={
                nationalIdentifierTokenised?.value?.maskedValue ??
                'Not provided'
              }
            />
            <CustomButtonV2
              $color="secondary"
              onClick={onAddMifid}
              disabled={!nationality}
            >
              Add National Identifier
            </CustomButtonV2>
          </DoubleCol>
        )}
        <SingleAction>
          <CustomButtonV2 $color="secondary" onClick={() => onClick?.()}>
            Change my details
          </CustomButtonV2>
        </SingleAction>
      </Container>
    </Base>
  );
}

export function PersonalDetails() {
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const clientData = userProfileQuery.data?.clientSummary;
  const shouldShowMIFID =
    userProfileQuery.data?.userProfile?.mifidIdentifierSupplied;

  const reportingNationalIdentifiers = useMifidReportingNationalityQuery(
    {
      nationalities: [
        userProfileQuery.data?.userProfile?.nationality,
        ...(userProfileQuery.data?.userProfile?.otherNationalities || []),
      ] as Array<string>,
    },
    { enabled: !!userProfileQuery.data?.userProfile?.nationality }
  );

  const { showNewMessages } = useIntercom();

  const { showModal } = useModal();

  const handleClick = () => {
    showNewMessages(`I would like to change my details.`);

    trackGa({
      event: GaEventNames.selectContent,
      content_type: `talk to support`,
      item_id: 'dashboard - my details - personal - change details clicked',
    });
  };

  const handleAddMifid = () => {
    showModal(NationalIdentifierDialog);
    trackGa({
      event: GaEventNames.selectContent,
      content_type: `talk to support`,
      item_id: 'dashboard - my details - add national identifier',
    });
  };

  return (
    <>
      <QueryState {...userProfileQuery}>
        {() => (
          <QueryState {...reportingNationalIdentifiers}>
            {() => (
              <PurePersonalDetails
                fullName={`${userProfile?.first} ${userProfile?.last}`}
                NINumber={
                  userProfile?.nationalInsuranceNumberTokenised?.maskedValue
                }
                nationalIdentifierTokenised={
                  userProfile?.nationalIdentifierTokenised
                }
                nationality={userProfile?.nationality ?? ''}
                cid={clientData?.secclId}
                otherNationalities={
                  userProfile?.otherNationalities as Array<string>
                }
                identifiersProvided={
                  shouldShowMIFID ||
                  false ||
                  (reportingNationalIdentifiers.data?.mifidReportingNationality
                    .identifiers.length || 0) <= 0
                }
                onClick={handleClick}
                onAddMifid={handleAddMifid}
              />
            )}
          </QueryState>
        )}
      </QueryState>
    </>
  );
}
