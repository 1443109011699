import { Paper } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Box = styled(Paper)`
  padding: 0;
  background-color: ${colors.white};

  ${(p) =>
    p.theme.darkUniverse &&
    `
    background-color: transparent;
  `}
`;
