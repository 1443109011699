import { QueryState } from 'components/QueryState';
import { FontSize, Text } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import {
  WrapperType,
  useAccountQuery,
  useClientSummaryQuery,
} from 'generated/graphql';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import {
  BasketSummaryCardInner,
  IsaLimitHit,
  StyledBasketSummaryCard,
} from './FundBasketSummary.styles';

export interface FundBasketSummaryProps {
  selectedAccountId?: string;
}

/**
 * Basket summary component
 *
 * A summary of items in the basket from the useFundsBasket hook containing:
 * - Count of items in each assetClass
 * - Total amount
 *
 * Using Card Text component from the design system
 */
export function FundBasketSummary({
  selectedAccountId,
}: FundBasketSummaryProps) {
  const {
    basketBuyOrders: basket,
    basketSummary,
    basketSellOrders,
  } = useFundsBasket({
    selectedAccountId,
  });

  const clientSummary = useClientSummaryQuery(undefined, {
    enabled: !!selectedAccountId,
  });

  const accountQuery = useAccountQuery(
    { id: selectedAccountId! }, // this will be non-null as it will be a string if the query is enabled
    {
      enabled: !!selectedAccountId,
    }
  );
  const uninvestedCash =
    accountQuery.data?.account?.valuationSummary?.uninvestedCash || 0;

  const account = accountQuery.data?.account;
  const isIsa = account?.wrapperType === WrapperType.Isa;
  const remainingIsaLimit =
    clientSummary.data?.clientSummary?.remainingIsaSubscriptionAmount;
  const overRemainingIsaLimit =
    isIsa &&
    remainingIsaLimit !== null &&
    remainingIsaLimit !== undefined &&
    basketSummary.amount - uninvestedCash > remainingIsaLimit;

  const combinedBasket = [...basket, ...basketSellOrders];

  const assetClassCount = combinedBasket.reduce((acc, fund) => {
    const assetClass = fund.assetClass;
    if (assetClass) {
      acc[assetClass] = acc[assetClass] ? acc[assetClass] + 1 : 1;
    }
    return acc;
  }, {} as { [key: string]: number });

  return (
    <QueryState {...clientSummary}>
      {() => (
        <QueryState {...accountQuery}>
          {() => (
            <StyledBasketSummaryCard>
              <BasketSummaryCardInner>
                <Text $noMargin>
                  {Object.keys(assetClassCount)
                    .map(
                      (assetClass) =>
                        `${assetClass} (${assetClassCount[assetClass]})`
                    )
                    .join(', ')}
                </Text>
                {basketSummary.amount >= 0 ? (
                  <Text $noMargin>
                    Basket balance:{' '}
                    <strong>{format.currencyFull(basketSummary.amount)}</strong>
                  </Text>
                ) : (
                  <Text $noMargin>
                    Estimated cash raised (net):{' '}
                    <strong>
                      +{format.currencyFull(Math.abs(basketSummary.amount))}
                    </strong>
                  </Text>
                )}
                {overRemainingIsaLimit && (
                  <IsaLimitHit $noMargin $fontSize={FontSize.normal}>
                    Your order value is over your remaining ISA Subscription,{' '}
                    {format.currencyFull(remainingIsaLimit)}.
                  </IsaLimitHit>
                )}
              </BasketSummaryCardInner>
            </StyledBasketSummaryCard>
          )}
        </QueryState>
      )}
    </QueryState>
  );
}
