import { useMediaQuery, useTheme } from '@material-ui/core';
import { PerformanceSeriesKind } from 'generated/graphql';
import { useMemo } from 'react';
import { BaseGraph } from './BaseGraph/BaseGraph';

export function PlaceholderGraph() {
  const muiTheme = useTheme();
  const atLeastSm = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const atLeastLaptop = useMediaQuery(muiTheme.breakpoints.up('md'));

  const labels = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8], []);
  const series = useMemo(
    () => ({
      name: '',
      kind: PerformanceSeriesKind.GrowthProportion,
      data: [0, 100, 0, -100, 0, 100, 0, -100, 0],
    }),
    []
  );

  const getHeight = () => {
    if (atLeastLaptop) {
      return 310;
    }

    if (atLeastSm) {
      return 330;
    }

    return 300;
  };

  return (
    <BaseGraph
      labels={labels}
      area={series}
      graphSettings={{ height: getHeight() }}
      renderLegend={false}
      graphType="placeholder"
      graphLocation="dashboard"
    />
  );
}
