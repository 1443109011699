import { Typography } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import { TextNormal } from 'components/design-system/Text/Text';
import { StyledSpanLink } from 'components/feature/Transfers/startTransferForm/steps/_shared/StyledSpanLink';
import {
  useOpenBankingProvidersQuery,
  useSetOpenBankingProviderIdMutation,
} from 'generated/graphql';
import React, { useEffect } from 'react';
import {
  ProviderTile,
  ProvidersContainer,
  ProvidersDialogContent,
} from '../styles/Deposit.styles';
import { TrueLayerTermsOfService } from './TrueLayerTermsOfService';

export interface SelectProviderStepProps {
  showServerError: boolean;
  onSelected: (
    manual: boolean,
    afterPopup?: () => Promise<void> | void
  ) => void;
  onScrollToTop: () => void;
}

export function SelectProviderStep({
  showServerError,
  onSelected,
  onScrollToTop,
}: SelectProviderStepProps) {
  const openBankingProvidersQuery = useOpenBankingProvidersQuery();

  const {
    mutateAsync: setOpenBankingProviderId,
    isLoading,
    isError,
    reset,
  } = useSetOpenBankingProviderIdMutation();

  const handleClick = async (providerId: string | null) => {
    reset();
    onSelected(!providerId, async () => {
      await setOpenBankingProviderId({ input: { providerId } });
    });
  };

  useEffect(() => {
    if (isError) {
      onScrollToTop();
    }
  }, [isError, onScrollToTop]);

  return (
    <>
      <StepTitle>Select your bank</StepTitle>
      <ProvidersDialogContent className="trueLayer__selectProviderStep">
        <TrueLayerTermsOfService />
        <ServerError isVisible={isError || showServerError} />
        <QueryState {...openBankingProvidersQuery}>
          {() => (
            <>
              <ProvidersContainer>
                {openBankingProvidersQuery.data?.openBankingProviders.map(
                  (p) => (
                    <ProviderTile
                      key={p.id}
                      disabled={isLoading}
                      onClick={() => handleClick(p.id)}
                    >
                      <img src={p.iconSvgUrl} alt={p.name} />
                      <Typography>{p.name}</Typography>
                    </ProviderTile>
                  )
                )}
              </ProvidersContainer>
              <TextNormal $noMargin>
                Bank not listed? Make a{' '}
                <StyledSpanLink as="button" onClick={() => handleClick(null)}>
                  manual transfer
                </StyledSpanLink>{' '}
                instead.
              </TextNormal>
            </>
          )}
        </QueryState>
      </ProvidersDialogContent>
    </>
  );
}
