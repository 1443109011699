import { datadogLogs } from '@datadog/browser-logs';
import { Typography } from '@material-ui/core';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { useRateInteractionMutation } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React, { useEffect, useState } from 'react';
import { StarRating } from '../StarRating/StarRating';
import {
  Acknowledgement,
  MainCopy,
  OnboardingFeedbackContainer,
  SkipButton,
} from './OnboardingFeedback.style';

export interface PureOnboardingFeedbackProps {
  submitted: boolean;
  rating: number | null;
  skipped: boolean;
  children: React.ReactNode;
  onSkip: () => void;
  onSubmit: (rating: number) => void;
}

export function PureOnboardingFeedback({
  submitted,
  rating,
  skipped,
  children,
  onSkip,
  onSubmit,
}: PureOnboardingFeedbackProps) {
  return (
    <>
      {!submitted && !skipped ? (
        <OnboardingFeedbackContainer>
          <Typography variant="h3">How did we do today?</Typography>
          <MainCopy>
            Rate your experience of setting up an investment account.
          </MainCopy>

          <StarRating
            name="onboarding-feedback"
            value={rating}
            readOnly={!!rating}
            onSelection={onSubmit}
          />

          <p>
            <SkipButton disabled={!!rating} onClick={() => onSkip()}>
              Skip
            </SkipButton>
          </p>
        </OnboardingFeedbackContainer>
      ) : (
        <>
          {!skipped && (
            <Acknowledgement>Thanks for your feedback!</Acknowledgement>
          )}
          {children}
        </>
      )}
    </>
  );
}

export interface OnboardingFeedbackProps {
  children: React.ReactNode;
}

export function OnboardingFeedback({ children }: OnboardingFeedbackProps) {
  const [submitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState<number | null>(null);
  const [skipped, setSkipped] = useState<boolean>(false);

  const { mutate, isError, error } = useRateInteractionMutation();

  useEffect(() => {
    if (isError) {
      datadogLogs.logger.error('Rating submission failed', { error, rating });
    }
  }, [error, isError, rating]);

  const handleRating = (rating: number) => {
    setRating(rating);
    mutate({ input: { interactionName: 'onboarding', rating } });

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.onboardingRating,
      onboardingRating: rating,
    });

    setTimeout(() => {
      setSubmitted(true);
    }, 1000);
  };

  const handleSkip = () => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.onboardingRating,
      onboardingRating: 'skipped',
    });
    setSkipped(true);
  };

  return (
    <PureOnboardingFeedback
      submitted={submitted}
      rating={rating}
      skipped={skipped}
      onSkip={handleSkip}
      onSubmit={handleRating}
    >
      {children}
    </PureOnboardingFeedback>
  );
}
