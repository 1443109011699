import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { currencyFull, date, percent } from 'formatting';
import {
  PerformanceComparisonPeriod,
  PerformanceSeriesKind,
  useAccountPerformanceQuery,
} from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import _, { Dictionary } from 'lodash';
import numeral from 'numeral';
import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { Contributions } from 'strings/tooltips';
import { useTheme } from 'styled-components';
import { Except } from 'type-fest';
import {
  AreaSeries,
  BaseGraph,
  BaseGraphProps,
  ExtrasRenderFunction,
  LineSeries,
  Series,
  TooltipRenderFunction,
} from '../BaseGraph/BaseGraph';
import { Disclaimer, TooltipContent } from '../BaseGraph/BaseGraph.styles';
import { TooltipHandle } from '../BaseGraph/Tooltip';
import { AccountContributionsGraph } from './AccountContributionsGraph';

interface Point {
  x: number;
  y: number;
}

export interface AccountPerformanceGraphProps {
  accountId?: string;
  period: PerformanceComparisonPeriod;
}

export function AccountPerformanceGraph({
  accountId,
  period,
}: AccountPerformanceGraphProps) {
  const muiTheme = useTheme();
  const atLeastSm = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const atLeastLaptop = useMediaQuery(muiTheme.breakpoints.up('md'));

  const [suppressTimeWeightedGrowthPercentagesToggle] = useToggle(
    'global-suppress-time-weighted-growth-percentages'
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const performanceQuery = useAccountPerformanceQuery(
    {
      accountId: accountId!,
      period,
    },
    {
      enabled: !!accountId,
    }
  );

  const series = useMemo(
    () => _.keyBy(performanceQuery.data?.accountPerformance?.series, 'kind'),
    [performanceQuery.data?.accountPerformance?.series]
  );

  const labels = useMemo(
    () =>
      performanceQuery.data?.accountPerformance?.labels?.map((label) => {
        const dateParts = label!.split('/').map((p) => parseInt(p));
        return Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0]);
      }) ?? [],
    [performanceQuery.data?.accountPerformance?.labels]
  );

  const latestConfirmedValuationDate: number | undefined =
    performanceQuery.data?.accountPerformance?.latestConfirmedValuationDate &&
    Date.parse(
      performanceQuery.data?.accountPerformance?.latestConfirmedValuationDate +
        'Z'
    );

  const contributions: AreaSeries = {
    ...series[PerformanceSeriesKind.Contributions],
    curveFactory: d3.curveStepAfter,
    fill: colors['purple-50'],
    legendEntry: (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: colors['purple-50'],
        }}
      />
    ),
    description: Contributions,
  };

  const accountValuePath = useRef<SVGPathElement>();
  const accountValuePathRef: React.RefCallback<SVGPathElement> = useCallback(
    (element) => {
      if (element) {
        accountValuePath.current = element;
        new MutationObserver(() => {
          // This is needed to recalculate stroke dash array after the path is rendered
          forceUpdate();
        }).observe(element, {
          attributeFilter: ['d'],
        });
      }
    },
    []
  );

  const lastAccountValuationDate = labels[labels.length - 1];

  const estimatedDays =
    labels.length -
    1 -
    labels.indexOf(latestConfirmedValuationDate ?? lastAccountValuationDate);

  const accountValue: LineSeries = {
    ...series[PerformanceSeriesKind.ClosingValue],
    stroke: colors.richBlue,
    strokeWidth: '2px',
    extraProps: (xScale, yScale) => {
      if (estimatedDays && accountValuePath.current) {
        const path = accountValuePath.current;

        const lastConfirmedValue =
          accountValue.data[accountValue.data.length - 1 - estimatedDays] ?? 0;

        const dashArrayStart: Point = {
          x: xScale(latestConfirmedValuationDate!),
          y: yScale(lastConfirmedValue),
        };

        const pathLength = path.getTotalLength();
        const step = pathLength / labels.length;

        const dist = (p1: Point, p2: Point) => {
          const dx = p2.x - p1.x;
          const dy = p2.y - p1.y;
          return Math.sqrt(dx * dx + dy * dy);
        };

        let bestLength = pathLength;
        let segment = labels.length - 1;
        for (let i = 0; i < labels.length; i++) {
          const _p = path.getPointAtLength(i * step);

          const distance = dist(_p, dashArrayStart);
          if (distance < bestLength) {
            bestLength = distance;
            segment = i;
          }
        }

        const dashArrayStartLength = segment * step;

        const dashArrayStr = `${dashArrayStartLength}${' 4'.repeat(
          (pathLength - dashArrayStartLength) / 4
        )}`;

        return {
          ref: accountValuePathRef,
          strokeDasharray: dashArrayStr,
        };
      }

      return {
        ref: accountValuePathRef,
      };
    },
  };

  const lines = [accountValue];

  if (estimatedDays) {
    lines.unshift({
      data: [],
      name: 'Estimated value',
      stroke: colors.richBlue,
      legendEntry: (
        <div
          style={{
            backgroundImage: `linear-gradient(to right, ${colors.richBlue} 50%, rgba(255, 255, 255, 0) 20%)`,
            backgroundPosition: 'left top',
            backgroundSize: '4px 3px',
            backgroundRepeat: 'repeat-x',
            height: '3px',
            borderStyle: 'none',
          }}
        />
      ),
    } as LineSeries);
  }

  const eventsByDay = useMemo(
    () =>
      _(performanceQuery.data?.accountPerformance?.events)
        .groupBy((e) => Date.parse(e.date.substring(0, 10)))
        .mapValues((byDay) =>
          _(byDay)
            .groupBy((e) => e.displayName)
            .mapValues((byDisplayName) =>
              _.sumBy(byDisplayName, (e) => e.value)
            )
            .value()
        )
        .value(),
    [performanceQuery.data?.accountPerformance?.events]
  );

  const renderTooltip = (tooltip: TooltipHandle, index: number) => {
    const isEstimated =
      latestConfirmedValuationDate &&
      labels[index] > latestConfirmedValuationDate;

    tooltip.setStyles({
      backgroundColor: isEstimated ? colors.white : colors.richBlue,
      borderColor: colors.richBlue,
      textColor: isEstimated ? colors.richBlack : colors.white,
    });

    return (
      <TooltipContent>
        <Typography>{date(labels[index])}</Typography>
        <dl>
          <dt>Growth:</dt>
          <dd>
            {suppressTimeWeightedGrowthPercentagesToggle?.enabled
              ? series[PerformanceSeriesKind.GrowthValue].data[index] !== null
                ? currencyFull(
                    series[PerformanceSeriesKind.GrowthValue].data[index]!
                  )
                : '-'
              : series[PerformanceSeriesKind.GrowthProportion].data[index] !==
                null
              ? percent(
                  series[PerformanceSeriesKind.GrowthProportion].data[index]!
                )
              : '-'}
          </dd>
          <dt>{isEstimated ? 'Estimated value:' : 'Account value:'}</dt>
          <dd>
            {series[PerformanceSeriesKind.ClosingValue].data[index] !== null
              ? currencyFull(
                  series[PerformanceSeriesKind.ClosingValue].data[index]!
                )
              : '-'}
          </dd>
          {contributions ? (
            <>
              <dt>Net contributions:</dt>
              <dd>
                {contributions.data[index] !== null
                  ? currencyFull(contributions.data[index]!)
                  : '-'}
              </dd>
            </>
          ) : null}
          {_.map(eventsByDay[labels[index]], (amount, displayName) => (
            <React.Fragment key={displayName}>
              <dt>{displayName}:</dt>
              <dd>{currencyFull(amount)}</dd>
            </React.Fragment>
          ))}
        </dl>
      </TooltipContent>
    );
  };

  const tickFormat = (value: number, numDecimals: number) =>
    numeral(value).format('$0,0' + (numDecimals > 0 ? '.00' : ''));

  const getHeight = () => {
    if (atLeastLaptop) {
      return 310;
    }

    if (atLeastSm) {
      return 330;
    }

    return 300;
  };

  const asOfValuationDate =
    latestConfirmedValuationDate ??
    (labels.length ? labels[labels.length - 1] : null);

  return (
    <Box position="relative" width="100%" minHeight={getHeight()}>
      <QueryState
        {...performanceQuery}
        isError={
          (performanceQuery.isError ||
            !Object.keys(series).length ||
            !labels.length) as any
        }
        loadingAbsolute
      >
        {() => (
          <>
            {contributions.data?.length ? (
              <AccountContributionsGraph
                accountValue={accountValue}
                contributions={contributions}
                eventsByDay={eventsByDay}
                graphSettings={{
                  height: getHeight(),
                  marginLeft: 70,
                }}
                growth={series[PerformanceSeriesKind.GrowthProportion]}
                labels={labels}
                renderTooltipContent={renderTooltip}
                tickFormat={tickFormat}
                tooltipReference={accountValue}
              />
            ) : (
              <FallbackGraph
                series={series}
                labels={labels}
                eventsByDay={eventsByDay}
                tickFormat={tickFormat}
                graphSettings={{
                  height: getHeight(),
                  marginLeft: 70,
                }}
                graphLocation="Dashboard"
                graphType="Fallback Account Performance"
              />
            )}

            <Disclaimer>
              Performance charts show data supplied by Seccl Custody.{' '}
              {asOfValuationDate
                ? `Chart
              valuations are as of market close on ${date(
                asOfValuationDate
              )} and delays may
              cause them to differ from other valuations displayed on this page.
              Past performance is not a reliable indicator of future results.`
                : `Delays may
              cause them to differ from other valuations displayed on this page.
              Past performance is not a reliable indicator of future results.`}
            </Disclaimer>
          </>
        )}
      </QueryState>
    </Box>
  );
}

interface FallbackGraphProps extends Except<BaseGraphProps, 'area'> {
  series: Dictionary<Series>;
  eventsByDay: {
    [x: string]: {
      [x: string]: number;
    };
  };
}

// Temporary fallback
function FallbackGraph({
  series,
  labels,
  tickFormat,
  graphSettings,
  eventsByDay,
  graphType,
  graphLocation,
}: FallbackGraphProps) {
  const area = series[PerformanceSeriesKind.ClosingValue];

  const renderTooltip: TooltipRenderFunction = (_tooltip, index) => {
    return (
      <TooltipContent>
        <Typography>{date(labels[index])}</Typography>
        <dl>
          <dt>Growth:</dt>
          <dd>
            {series[PerformanceSeriesKind.GrowthProportion].data[index] !== null
              ? percent(
                  series[PerformanceSeriesKind.GrowthProportion].data[index]!
                )
              : '-'}
          </dd>
          <dt>Account value:</dt>
          <dd>
            {series[PerformanceSeriesKind.ClosingValue].data[index] !== null
              ? currencyFull(
                  series[PerformanceSeriesKind.ClosingValue].data[index]!
                )
              : '-'}
          </dd>
          {_.map(eventsByDay[labels[index]], (amount, displayName) => (
            <React.Fragment key={displayName}>
              <dt>{displayName}:</dt>
              <dd>{currencyFull(amount)}</dd>
            </React.Fragment>
          ))}
        </dl>
      </TooltipContent>
    );
  };

  const renderEvents: ExtrasRenderFunction = (x, y) => {
    return Object.keys(eventsByDay)
      ?.filter(
        (eventDay) => new Date(parseInt(eventDay)).getTime() >= labels[0]
      )
      ?.map((eventDay) => {
        const settlementDate = new Date(parseInt(eventDay)).setHours(
          0,
          0,
          0,
          0
        );
        const index = d3.bisectCenter(labels, settlementDate);

        return (
          <circle
            key={settlementDate}
            fill={colors.magenta}
            cx={x(labels[index]!)}
            cy={y(area.data[index]!)}
            r="5"
          />
        );
      });
  };

  return (
    <BaseGraph
      labels={labels}
      area={area}
      tickFormat={tickFormat}
      renderTooltipContent={renderTooltip}
      graphSettings={graphSettings}
      renderExtras={renderEvents}
      legendAlignment={'center'}
      YAxisFontSize={'large'}
      YAxisMaxTicksHints={4}
      showMobileSpecificTooltip={true}
      graphLocation={graphLocation}
      graphType={graphType}
    />
  );
}
