import {
  SellOrderDetailsByAccountDocument,
  SellOrderDetailsByAccountQuery,
  SellOrderDetailsByAccountQueryVariables,
} from 'generated/graphql';
import { UseQueryOptions, useQueries } from 'react-query';
import { useFetcher } from '../api/TillitGraphQL';

export const useSellOrderDetailsByAccountQueries = <
  TData = SellOrderDetailsByAccountQuery,
  TError = unknown
>(
  variables: SellOrderDetailsByAccountQueryVariables[],
  options?: UseQueryOptions<SellOrderDetailsByAccountQuery, TError, TData>
) => {
  const fetcher = useFetcher<
    SellOrderDetailsByAccountQuery,
    SellOrderDetailsByAccountQueryVariables
  >(SellOrderDetailsByAccountDocument);
  return useQueries(
    variables.map((variable) => ({
      queryKey: ['SellOrderDetailsByAccount', variable],
      queryFn: fetcher.bind(null, variable),
      ...options,
    }))
  );
};
