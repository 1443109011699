import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'components/design-system/FormElements/Radio';
import { H3, H5 } from 'components/design-system/Heading/Heading';
import {
  GoBackButton,
  StepButton,
} from 'components/design-system/StepComponents/StepComponents';
import { TextNormal } from 'components/design-system/Text/Text';
import { targetDateInstrumentReturnType } from 'components/feature/FundDetails/helpers/getTargetDateInstrument';
import { useTargetDateInfo } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { WrapperType } from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { generateFundDetailsPath } from 'paths';
import { useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { AnyAsset } from 'types/graphqlTypes';
import * as yup from 'yup';
import {
  ButtonContainer,
  ErrorMessage,
  FundDoLink,
  Pill,
  PillContainer,
  Section,
} from '../AddToBasketDialog.style';
import {
  VintageHeader,
  VintageSelector,
  VintageWrapper,
} from './VerifyVintageSelection.styles';

interface getDateRangeDescriptionProps {
  startDate: number | null;
  endDate: number | null;
}

function getDateRangeDescription({
  startDate,
  endDate,
}: getDateRangeDescriptionProps) {
  if (!startDate && endDate) {
    return `${endDate} and earlier`;
  }
  if (startDate && !endDate) {
    return `${startDate} and beyond`;
  }

  return `${startDate}-${endDate}`;
}

const VerifyVintageSelectionSchema = yup.object().shape({
  instrumentSelector: yup
    .string()
    .required()
    .typeError('Please select a vintage'),
});

interface VerifyVintageSelectionFormValues {
  instrumentSelector: string;
}

export interface VerifyVintageSelectionStepBuyProps {
  asset: AnyAsset;
  selectedAccountType?: WrapperType;
  selectedIsin?: string;
  targetDateInstrument: targetDateInstrumentReturnType;
  selectedInstrument: targetDateInstrumentReturnType;
  handleInstrumentChange?: (isin: string) => void;
  onProceed: () => void;
  onGoBack?: () => void;
}

export function VerifyVintageSelectionStepBuy({
  targetDateInstrument,
  selectedInstrument,
  asset,
  selectedAccountType,
  selectedIsin,
  handleInstrumentChange,
  onProceed,
  onGoBack,
}: VerifyVintageSelectionStepBuyProps) {
  const { savedTargetDateInfo, setTargetDateInfo } = useTargetDateInfo();

  const targetVintageIsin = targetDateInstrument.instrument?.isin;

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    errors,
  } = useForm<VerifyVintageSelectionFormValues>({
    defaultValues: {
      instrumentSelector: selectedIsin,
    },
    resolver: yupResolver(VerifyVintageSelectionSchema),
  });

  const resetTargetDateInfo = (isin: string) => {
    if (targetVintageIsin === isin) {
      if (
        !savedTargetDateInfo ||
        !savedTargetDateInfo.intendedRetirementAge ||
        !savedTargetDateInfo.yearOfBirth
      ) {
        return null;
      }
      setTargetDateInfo(savedTargetDateInfo);
    }
  };

  const onSubmit = async (data: VerifyVintageSelectionFormValues) => {
    if (data.instrumentSelector) {
      resetTargetDateInfo(data.instrumentSelector);
      await handleInstrumentChange?.(data.instrumentSelector);
      onProceed();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PillContainer>
        <Pill $filled $color="buy">
          BUY
        </Pill>
        {selectedAccountType && selectedAccountType !== WrapperType.Unknown && (
          <Pill>{getShortNameForWrapperType(selectedAccountType)}</Pill>
        )}
      </PillContainer>
      <div>
        <H3>{asset.name}</H3>
        <FundDoLink
          to={{
            pathname: generateFundDetailsPath({
              id: asset?.id!,
              slug: asset?.slug!,
            }),
            state: {
              shouldGoBack: true,
            },
          }}
          target="_blank"
          // onClick={() => trackFundOpen('link', asset, index)}
        >
          What does this fund do? <HiExternalLink />
        </FundDoLink>
      </div>
      <Section>
        <TextNormal>
          You are about to invest in a vintage that does not match the
          information you have given us. The vintage we think you may want to
          invest in is based on the year which you said you want to retire.
        </TextNormal>
        <TextNormal $noMargin>
          Please review the options below and select the vintage you want to
          invest in before proceeding.
        </TextNormal>
      </Section>

      <VintageSelector>
        <div>
          <H5>You have selected this vintage</H5>
          <VintageWrapper>
            <Radio
              name={'instrumentSelector'}
              value={selectedIsin}
              ref={register}
              label={
                <VintageHeader $noMargin>
                  Target retirement date range:{' '}
                  {getDateRangeDescription({
                    startDate: selectedInstrument?.startDate,
                    endDate: selectedInstrument?.endDate,
                  })}
                </VintageHeader>
              }
              wrapLabel={false}
            />
          </VintageWrapper>
        </div>
        <div>
          <H5>Your vintage match</H5>
          <VintageWrapper>
            <Radio
              name={'instrumentSelector'}
              value={targetDateInstrument?.instrument?.isin!}
              ref={register}
              label={
                <VintageHeader $noMargin>
                  Target retirement date range:{' '}
                  {targetDateInstrument?.startDate}
                  {targetDateInstrument?.endDate
                    ? `-${targetDateInstrument?.endDate}`
                    : ' and beyond'}
                </VintageHeader>
              }
              wrapLabel={false}
            />
          </VintageWrapper>
        </div>
      </VintageSelector>

      {errors.instrumentSelector && (
        <ErrorMessage>{errors.instrumentSelector.message}</ErrorMessage>
      )}

      <ButtonContainer>
        <StepButton className="magenta" type="submit" disabled={isSubmitting}>
          Continue
        </StepButton>
        {onGoBack && <GoBackButton onClick={onGoBack} />}
      </ButtonContainer>
    </form>
  );
}
