import React from 'react';
import styled from 'styled-components';
import { CustomButtonV2 } from '../Button/CustomButtonV2';
import { H2 } from '../Heading/Heading';

export const OptionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    div {
      width: 100%;
      max-width: 20rem;
    }
  }
`;

export const StyledOptionCard = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto auto;
  justify-items: center;
  align-items: center;
  box-shadow: -2rem -2rem 4rem #fafbff, 0px 1rem 2rem rgba(166, 171, 189, 0.5);
  border-radius: 1rem;
  text-align: center;
  font-weight: 300;

  button {
    width: 100%;
    margin-top: auto;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 17rem;
  }
`;

const OptionCardTitleEl = styled(H2)`
  font-family: 'GT Super', serif;
  font-size: 1.25rem;
  max-width: 12rem;
  font-weight: 400;
`;

const OptionCardDivider = styled.hr`
  width: 1.5rem;
  border-color: rgba(215, 22, 235, 0.25);
  border-width: 0 0 1px 0;
`;

const OptionCardDesc = styled.div`
  margin-bottom: 2rem;
`;

export interface OptionCardProps {
  title: string | React.ReactNode | JSX.Element;
  children: string | React.ReactNode | JSX.Element;
  extraInfo?: React.ReactNode | JSX.Element;
  onClick?: () => void;
  buttonLabel?: string;
  buttonDisabled?: boolean;
}

/**
 *  This will render a card UI
 * @param title - title of the card as string
 * @param children - description of the card
 * @param extraInfo - Extra info to be displayed under the button
 * @param onClick - optional function to be called when the button is clicked
 * @param buttonLabel - optional label for the button as string
 * @param buttonDisabled - optional boolean to set the state of the button
 * @returns React.ReactNode
 */
export function OptionCard({
  title,
  children,
  extraInfo,
  onClick,
  buttonLabel,
  buttonDisabled = false,
}: OptionCardProps) {
  return (
    <StyledOptionCard>
      <OptionCardTitleEl>{title}</OptionCardTitleEl>
      <OptionCardDivider />
      <OptionCardDesc>{children}</OptionCardDesc>
      {buttonLabel && (
        <CustomButtonV2 onClick={() => onClick?.()} disabled={buttonDisabled}>
          {buttonLabel}
        </CustomButtonV2>
      )}
      {extraInfo && <>{extraInfo}</>}
    </StyledOptionCard>
  );
}
