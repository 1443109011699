import {
  FontStyle,
  TextAlign,
  TextNormal,
} from 'components/design-system/Text/Text';

interface BreakdownAttributionProps {
  sectorAndRegionAttributionText?: string;
  holdingsAttributionText: string;
  alignCenter?: boolean;
}

export function BreakdownAttribution({
  sectorAndRegionAttributionText,
  holdingsAttributionText,
  alignCenter = false,
}: BreakdownAttributionProps) {
  if (sectorAndRegionAttributionText) {
    return (
      <>
        <TextNormal
          $fontStyle={FontStyle.italic}
          $textAlign={alignCenter ? TextAlign.center : undefined}
        >
          {holdingsAttributionText}
        </TextNormal>
        <TextNormal
          $fontStyle={FontStyle.italic}
          $textAlign={alignCenter ? TextAlign.center : undefined}
        >
          {sectorAndRegionAttributionText}
        </TextNormal>
      </>
    );
  } else {
    return (
      <TextNormal
        $fontStyle={FontStyle.italic}
        $textAlign={alignCenter ? TextAlign.center : undefined}
      >
        {holdingsAttributionText}
      </TextNormal>
    );
  }
}
