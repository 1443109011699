import { datadogLogs, LogsEvent } from '@datadog/browser-logs';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Providers } from './Providers';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_MSW === 'on') {
    const { worker } = require('./mocks/browser');
    worker.start();
  }
}

if (process.env.NODE_ENV === 'production') {
  datadogLogs.init({
    service: 'app',
    version: process.env.REACT_APP_DD_VERSION,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN!,
    site: process.env.REACT_APP_DD_SITE!,
    env: process.env.REACT_APP_DD_ENV,
    beforeSend: (e: LogsEvent) => {
      // remove query string
      try {
        e.view.url = e.view.url.replace(/\?.+/i, '');
        e.view.referrer = e.view.referrer?.replace(/\?.+/i, '');
        if (e.http) {
          e.http.url = e.http.url.replace(/\?.+/i, '');
        }
      } catch {}
    },
  });
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
  api_host: 'https://api-eu.mixpanel.com',
  opt_out_tracking_by_default: true,
  disable_persistence: true,
  cookie_domain: '.tillitinvest.com',
  debug: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
