import { FormControlLabel, Link, Typography } from '@material-ui/core';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { HiOutlineDownload } from 'react-icons/hi';
import styled from 'styled-components';

// Title
export const DialogTitle = styled(Typography)`
  margin-bottom: 22px;
`;

// Copy
export const CopyContainer = styled.div`
  position: relative;
  width: 100%;

  p {
    margin-bottom: ${(p) => p.theme.spacing(1.5)}px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

// Download copy of declaration
export const DownloadContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: 5rem 1fr 5rem;
  }

  justify-items: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background-color: ${transparentize(0.93, colors.richBlue)};
  margin: 37px 0;
  padding: 0.25rem 0.25rem;
`;
export const DownloadLink = styled(Link)`
  font-weight: 300;
  cursor: pointer;
  color: ${colors.richBlue};
`;
export const DownloadIcon = styled(HiOutlineDownload)`
  font-size: 1.5rem;
  color: ${colors.richBlue};
  justify-self: start;
`;

// Affirmations
export const AffirmationsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 0;
`;
export const CustomFormControlLabel = styled(FormControlLabel)`
  span {
    font-weight: 300;
  }
`;

// Actions
export const ActionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacing(1)}px;

  & > *:last-child {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

export const GiaLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-self: center;
  color: ${colors.richBlue};
  margin-top: 20px;
`;

export const IsaStepTitle = styled(StepTitle)`
  margin-bottom: 0.5rem;
`;
