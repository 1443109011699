import { useMediaQuery, useTheme } from '@material-ui/core';
import { FundDetails } from 'components/FundDetails/FundDetails';
import { Loading } from 'components/Loading';
import * as format from 'formatting';
import { useFundInfoBoxQuery } from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import { FundInfoBoxSection } from 'types/graphqlTypes';
import { BoxContainer } from './BoxContainer';
import { FundsCostsAndChargesLegacy } from './FundsCostsAndChargesLegacy';
import { MiFIDCostsAndCharges } from './MiFIDCostsAndCharges';
import {
  DetailsContainer,
  StyledBoxInner,
  StyledBoxRow,
  StyledDetailsBox,
  StyledDetailsContent,
} from './Styles/DetailsBox.style';
import { H4 } from 'components/design-system/Heading/Heading';

export interface FundCostsAndChargesProps {
  fundInfo: NonNullable<FundInfoBoxSection>;
  selectedIsin: string;
  instrumentType: string;
  kiidDocumentUrl: string;
}

function FundCostsAndCharges({
  fundInfo,
  selectedIsin,
  instrumentType,
  kiidDocumentUrl,
}: FundCostsAndChargesProps) {
  const [useMifidIllustrations, mifidIllustrationsQuery] = useToggle(
    'global-use-mifid-illustrations'
  );
  const lumpSum = 5000;

  return (
    <>
      {!mifidIllustrationsQuery.isFetched ? (
        <Loading />
      ) : useMifidIllustrations?.enabled ? (
        <MiFIDCostsAndCharges
          lumpSum={lumpSum}
          selectedIsin={selectedIsin}
          trackPopoverClick={() => {}}
          instrumentType={instrumentType}
          kiidDocumentUrl={kiidDocumentUrl}
        />
      ) : (
        <FundsCostsAndChargesLegacy fundInfo={fundInfo} lumpSum={lumpSum} />
      )}
    </>
  );
}

interface DetailsAccordionProps {
  fundInfo: NonNullable<FundInfoBoxSection>;
  selectedIsin: string;
  instrumentType: string;
  kiidDocumentUrl: string;
}

function FundDetailsBox({
  fundInfo,
  selectedIsin,
  instrumentType,
  kiidDocumentUrl,
}: DetailsAccordionProps) {
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  if (
    fundInfo.title === null ||
    fundInfo.title === undefined ||
    fundInfo.data === null ||
    fundInfo.data === undefined
  ) {
    return null;
  }

  return (
    <StyledDetailsBox>
      {fundInfo.title === 'Fees' ? (
        <FundCostsAndCharges
          fundInfo={fundInfo}
          selectedIsin={selectedIsin}
          instrumentType={instrumentType}
          kiidDocumentUrl={kiidDocumentUrl}
        />
      ) : (
        <>
          <H4>
            {fundInfo.title.charAt(0).toUpperCase() + fundInfo.title.slice(1)}
          </H4>

          <StyledDetailsContent>
            <DetailsContainer>
              {fundInfo.data.map((data) => {
                if (data === null) {
                  return null;
                }
                let value = data.valueRendered || data.emptyValue;

                if (value === undefined || value === null) {
                  return null;
                }

                if (data.kind === 'PERCENTAGE' && typeof value === 'string') {
                  const convertedVal = (parseFloat(value) / 100).toString();
                  value = format.percent(convertedVal);
                }

                let label = data.name;
                if (data.source === 'ASSET_MANAGER') {
                  label += '*';
                }

                return (
                  <FundDetails
                    key={label}
                    label={label}
                    value={value}
                    variant="outlined"
                    valueAlign={isMobileUp ? 'left' : 'right'}
                  />
                );
              })}
            </DetailsContainer>
          </StyledDetailsContent>
        </>
      )}
    </StyledDetailsBox>
  );
}

interface DetailsBoxProps {
  isin: string;
  instrumentType: string;
  kiidDocumentUrl: string;
}

export function DetailsBox({
  isin,
  instrumentType,
  kiidDocumentUrl,
}: DetailsBoxProps) {
  const fundInfoQuery = useFundInfoBoxQuery({ isin: isin });
  const fundInfo = fundInfoQuery.data?.fundInfobox?.sections || [];

  const fundDetailsBox = fundInfo
    .sort((a, b) => {
      if (a!.title === 'Holdings' && b!.title === 'Other details') {
        return -1; // a should come before b
      }
      if (a!.title === 'Other details' && b!.title === 'Holdings') {
        return 1; // b should come before a
      }
      return 0; // keep original order for other cases
    })
    .map((rowData, i) => {
      if (rowData === null) {
        return null;
      }
      return (
        <FundDetailsBox
          fundInfo={rowData}
          key={rowData.title}
          selectedIsin={isin}
          instrumentType={instrumentType}
          kiidDocumentUrl={kiidDocumentUrl}
        />
      );
    });

  return (
    <BoxContainer>
      <StyledBoxInner>
        <StyledBoxRow>
          {fundDetailsBox.filter((box, i) => i < fundDetailsBox.length / 2)}
        </StyledBoxRow>
        <StyledBoxRow>
          {fundDetailsBox.filter((box, i) => i >= fundDetailsBox.length / 2)}
        </StyledBoxRow>
      </StyledBoxInner>
    </BoxContainer>
  );
}
