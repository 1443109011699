import type { ConfirmStepSuccessProps } from '../confirm/steps/ConfirmStep';

export function deriveSuccessMsg(confirmSuccessMeta: ConfirmStepSuccessProps) {
  if (
    confirmSuccessMeta.deposits === 'added' &&
    confirmSuccessMeta.orders === 'added'
  ) {
    return 'Your regular deposit and investment plan has been setup';
  }

  if (
    confirmSuccessMeta.deposits === 'updated' &&
    confirmSuccessMeta.orders === 'updated'
  ) {
    return 'Your regular deposit and investment plan has been updated';
  }

  if (
    confirmSuccessMeta.deposits === 'removed' &&
    confirmSuccessMeta.orders === 'removed'
  ) {
    return 'Your regular deposit and investment plan has been removed';
  }

  let confirmString = '';

  if (confirmSuccessMeta.deposits === 'added') {
    confirmString = 'Your regular deposit has been setup';
  }
  if (confirmSuccessMeta.deposits === 'updated') {
    confirmString = 'Your regular deposit has been updated';
  }
  if (confirmSuccessMeta.deposits === 'removed') {
    confirmString = 'Your regular deposit has been removed';
  }

  if (confirmSuccessMeta.orders === 'added') {
    confirmString += ' and your regular investment has been setup';
  }
  if (confirmSuccessMeta.orders === 'updated') {
    confirmString += ' and your regular investment has been updated';
  }
  if (confirmSuccessMeta.orders === 'removed') {
    confirmString += ' and your regular investment has been removed';
  }

  return confirmString;
}
