const validKeyForNumberInput = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Backspace',
  'Tab',
];

export const isNumberInputValid = (ev: React.KeyboardEvent) => {
  return (
    validKeyForNumberInput.includes(ev.key) ||
    (ev.metaKey === true && ev.key === 'a') ||
    (ev.shiftKey === true && ev.key === 'a')
  );
};
