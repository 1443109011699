import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import * as format from 'formatting';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Table, Td, Tr } from '../_shared/Table.styles';

interface RegionTableComparisonProps {
  data: any;
  name: string;
}
export const BreakdownTableComparison = ({
  data,
  name,
}: RegionTableComparisonProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <Td>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              {name}
            </TextSmall>
          </Td>
          <Td>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Est. position (%)
            </Text>
          </Td>
          <Td>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Est. value (£)
            </Text>
          </Td>
          <Td>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Change (£)
            </Text>
          </Td>
        </tr>
      </thead>
      {orderBy(data, 'end', 'desc').map(
        ({ text, amount, end, existingAmount }) => (
          <Tr
            status={
              amount > existingAmount
                ? 'buying'
                : amount < existingAmount
                ? 'selling'
                : 'neutral'
            }
          >
            <Td>
              <TextXS $noMargin>{text}</TextXS>
            </Td>
            <Td>
              <Text
                $fontSize={FontSize.xs}
                $fontWeight={FontWeight.light}
                $textAlign={TextAlign.right}
                $noMargin
              >
                {format.percent(end / 100)}
              </Text>
            </Td>
            <Td>
              <Text
                $fontSize={FontSize.xs}
                $fontWeight={FontWeight.light}
                $textAlign={TextAlign.right}
                $noMargin
              >
                {format.currencyFull(amount)}
              </Text>
            </Td>
            <Td>
              <Text
                $fontSize={FontSize.xs}
                $fontWeight={FontWeight.light}
                $textAlign={TextAlign.right}
                $noMargin
              >
                {format.currencyFull(amount - existingAmount)}
              </Text>
            </Td>
          </Tr>
        )
      )}
    </Table>
  );
};
