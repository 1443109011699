import { useTheme } from '@material-ui/core';
import { BuyButton } from 'components/Button/BuyButton';
import { SellButton } from 'components/Button/SellButton';
import { AddFundToRecurringOrderButton } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddFundToRecurringOrderButton';
import { useMode } from 'components/feature/mode/useMode';
import { useAuth } from 'context/AuthContext';
import { useToggle } from 'hooks/useFeatureToggle';
import { useIsAssetAvailableToBuyById } from 'hooks/useIsAssetAvailableToBuy';
import { AnyAsset } from 'types/graphqlTypes';
import { FundActions } from './FundActions';

interface HeaderActionsOldProps {
  asset: AnyAsset;
  isin: string;
  userHolds: boolean;
  onBuy: () => void;
  onSell: () => void;
}

export function HeaderActionsOld({
  asset,
  isin,
  userHolds,
  onBuy,
  onSell,
}: HeaderActionsOldProps) {
  const [mode] = useMode();
  const theme = useTheme();
  const isDarkUniverse = theme.darkUniverse;
  const { signedIn } = useAuth();
  const isAssetAvailableToBuy = useIsAssetAvailableToBuyById(
    asset.id!.toString()
  );

  if (!isAssetAvailableToBuy) {
    return null;
  }

  if (!isin) {
    return null;
  }

  if (!signedIn) {
    return (
      <BuyButton
        assetId={asset.id!.toString()}
        selectedIsin={isin}
        onClick={onBuy}
      />
    );
  }

  if (mode?.mode === 'autoSaveInvest') {
    return (
      <AddFundToRecurringOrderButton
        selectedAssetId={asset.id!}
        selectedIsin={isin}
      />
    );
  }

  if (userHolds) {
    return (
      <>
        <SellButton
          asset={asset}
          canSell={true}
          isin={isin}
          $color={`${isDarkUniverse ? 'white' : 'secondary'}`}
          variant={`${isDarkUniverse ? 'outlined' : 'contained'}`}
          onClick={onSell}
        />

        <BuyButton
          assetId={asset.id!.toString()}
          selectedIsin={isin}
          onClick={onBuy}
          label="Buy More"
        />
      </>
    );
  } else {
    return (
      <BuyButton
        assetId={asset.id!.toString()}
        selectedIsin={isin}
        onClick={onBuy}
      />
    );
  }
}

interface HeaderActionsProps extends HeaderActionsOldProps {
  handleInstrumentChange: (isin: string) => void;
}

export function HeaderActions({
  asset,
  isin,
  userHolds,
  onBuy,
  onSell,
  handleInstrumentChange,
}: HeaderActionsProps) {
  const [toggleBuyDialogModeStep] = useToggle('global-buy-dialog-mode-step');

  if (toggleBuyDialogModeStep?.enabled) {
    return (
      <FundActions
        asset={asset}
        isin={isin}
        userHolds={userHolds}
        handleInstrumentChange={handleInstrumentChange}
      />
    );
  } else {
    return (
      <HeaderActionsOld
        asset={asset}
        isin={isin}
        userHolds={userHolds}
        onBuy={onBuy}
        onSell={onSell}
      />
    );
  }
}
