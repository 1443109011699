import { colors } from 'constants/colors';
import styled, { css, keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const spacing = css`
  padding: ${(p) => p.theme.spacing(1.25, 2.5)};

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(2.5)}px;
  }
`;

export const Container = styled.div`
  height: auto;
  //min-height: 100vh;
  display: grid;
  place-items: center;
  ${spacing};
`;

export const ContainerAbsolute = styled(Container)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  z-index: 1;
  ${spacing};
`;

export const Spinner = styled.div`
  width: 70px;
  text-align: center;
  & div:nth-child(2) {
    animation-delay: -0.16s;
  }

  & div:nth-child(3) {
    animation-delay: -0.32s;
  }
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.color || 'currentColor'};

  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
`;
