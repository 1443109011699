import { datadogLogs } from '@datadog/browser-logs';
import { StarRating } from 'components/Rating/StarRating/StarRating';
import { Text } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { useRateInteractionMutation } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React, { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { DpFlowTypes } from '../../../flowType';
import { CheckoutFeedbackContainer } from './CheckoutFeedback.style';

export interface PureCheckoutFeedbackProps {
  submitted: boolean;
  rating: number | null;
  children: React.ReactNode;
  onSubmit: (rating: number) => void;
}

export function PureCheckoutFeedback({
  submitted,
  rating,
  children,
  onSubmit,
}: PureCheckoutFeedbackProps) {
  return (
    <>
      {!submitted ? (
        <CheckoutFeedbackContainer>
          <Text>Please rate your checkout experience</Text>
          <StarRating
            name="checkout-feedback"
            value={rating}
            readOnly={!!rating}
            onSelection={onSubmit}
          />
        </CheckoutFeedbackContainer>
      ) : (
        <>
          <Text $noMargin>Thanks for your feedback!</Text>
          {children}
        </>
      )}
    </>
  );
}

export interface OnboardingFeedbackProps {
  children: React.ReactNode;
  activeFlow: DpFlowTypes;
}

export function CheckoutFeedback({
  children,
  activeFlow,
}: OnboardingFeedbackProps) {
  const [submitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState<number | null>(null);

  const { mutate, error } = useRateInteractionMutation({
    onError: () => {
      datadogLogs.logger.error('Rating submission failed', { error, rating });
    },
  });

  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.feedbackOpportunity,
      item_id: 'checkout',
      checkoutFlow: activeFlow,
    });
  });

  const handleRating = (rating: number) => {
    setRating(rating);
    mutate({ input: { interactionName: 'checkout', rating } });

    trackGa({
      event: GaEventNames.feedback,
      item_id: 'checkout',
      value: rating,
      checkoutFlow: activeFlow,
    });

    setTimeout(() => {
      setSubmitted(true);
    }, 1000);
  };

  return (
    <PureCheckoutFeedback
      submitted={submitted}
      rating={rating}
      onSubmit={handleRating}
    >
      {children}
    </PureCheckoutFeedback>
  );
}
