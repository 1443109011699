import { colors } from 'constants/colors';
import { Text } from 'components/design-system/Text/Text';
import styled, { css } from 'styled-components';

export const RowContainer = styled.div<DetailsContainerProps>`
  width: 100%;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / -1;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 3.75rem;
`;

export const ActionsWarning = styled.div`
  background-color: ${colors['mauve-50']};
  max-width: 640px;
  text-align: center;
  margin-bottom: 1rem;
  padding: 1rem 1rem 1.25rem 1rem;
  border-radius: 0.5rem;
`;

interface DetailsContainerProps {
  $showDetails?: boolean;
  $hasDetails?: boolean;
}

export const OrderDetailsContainer = styled.div`
  border-bottom: 1px solid ${colors.midGrey};
`;

export const OverviewContainerHeaderBaseStyles = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  gap: 0rem 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    grid-template-rows: auto;
    gap: 0rem 2%;
    grid-template-columns: 1fr 15% 15% 10% 10% 2rem;
  }

  padding: 0.5rem 0;
  align-items: center;
  width: 100%;
`;

export const OverViewContainerBuyHeader = styled.div<DetailsContainerProps>`
  ${OverviewContainerHeaderBaseStyles}

  ${({ onClick }) =>
    onClick &&
    `
  :hover {
    background-color: ${colors.grey};
    cursor: pointer;
  }
  `}
`;

export const OverViewContainerOverallocation = styled.div<DetailsContainerProps>`
  ${OverviewContainerHeaderBaseStyles}

  border-bottom: ${({ $showDetails }) =>
    $showDetails ? '0' : `1px solid ${colors.midGrey}`};

  ${Text} {
    color: ${colors.danger};
  }
`;

export const DetailsContainer = styled.div<DetailsContainerProps>`
  overflow: hidden;
  max-height: ${({ $showDetails }) => ($showDetails ? '46rem' : '0')};
  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
`;

export const DetailsLayout = styled.div<DetailsContainerProps>`
  box-sizing: border-box;
  display: grid;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    gap: 0.5rem 1rem;
    padding: 1.5rem;
    border: ${({ $showDetails }) =>
      $showDetails ? `1px solid ${colors.grey}` : '0'};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto auto auto auto;
  }

  overflow: hidden;
  margin-bottom: 1rem;
  padding-right: 3rem;
`;

export const EditOrderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0;
`;
