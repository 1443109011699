import { IconButton } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PortfolioBuilderRatingContainer = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  bottom: 8rem;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 380px;
    right: 3rem;
    bottom: 8rem;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  color: ${colors.darkGrey};
  top: 0.5rem;
  right: 0.5rem;
`;
