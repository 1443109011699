import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useQueryClient } from 'react-query';
import { AddressInput } from './addressSchema';

interface UseUpdateAddressesProps {
  onProceed: () => void;
}

export const useUpdateAddresses = ({ onProceed }: UseUpdateAddressesProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isError } = useUpdateUserProfileMutation();
  const updateAddresses = async (addresses: AddressInput[]) => {
    await mutateAsync(
      {
        input: {
          addresses: addresses,
        },
      },
      {
        onSuccess: (_, { input }) => {
          queryClient.setQueryData<UserProfileQuery>(
            useUserProfileQuery.getKey(),
            (oldData) => {
              return {
                userProfile: {
                  ...oldData?.userProfile!,
                  addressHistory: input.addresses!.map((addr) => ({
                    ...addr!,
                    buildingName: addr?.buildingNameOrNumber,
                  })),
                },
              };
            }
          );
        },
      }
    );

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.addressHistory,
    });

    onProceed();
  };

  return { updateAddresses, isError };
};
