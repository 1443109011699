import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  GoBackButton,
  StepActionsCar,
  StepActionsWithCar,
  StepButton,
  StepCheckboxesContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionLi,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  EligibleAssetsDialog,
  useDialog,
} from 'components/feature/Transfers/startTransferForm/informationDialogs/EligibleAssetsDialog/EligibleAssetsDialog';
import { colors } from 'constants/colors';
import { TransferMethod, WrapperType } from 'generated/graphql';
import { TransferPensionDetails, useTransfer } from 'hooks/useTransferV2';
import { tillitDarkUniversePath, tillitFAQs } from 'paths/externalPaths';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { CedingProvider } from '../../types';
import { StyledSpanLink } from '../_shared/StyledSpanLink';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

const MoreIneligibleInfo = styled.div`
  align-self: stretch;
  border: solid 1px ${colors.lightGrey};
  border-width: 1px 0;
  padding: 1rem 0;
  margin: 0 0 1.5rem;
  > * {
    margin: 0;
  }
`;

const StepCheckboxesContainerWide = styled(StepCheckboxesContainer)`
  max-width: 41rem;
`;

const transferSchema = yup.object().shape({
  authorise: yup
    .boolean()
    .oneOf([true], 'Please check the authorise checkbox')
    .required('Please check the authorise checkbox'),
  accept: yup
    .boolean()
    .oneOf([true], 'Please check the accept checkbox')
    .required('Please check the accept checkbox'),
  confirmReadAndUnderstood: yup
    .boolean()
    .oneOf([true], 'Please check the read and understood checkbox')
    .required('Please check the read and understood checkbox'),
});

interface FormValues {
  authorise: boolean;
}

export type onProceedCallback = () => void;

export interface ConfirmInspecieTransferProps {
  wrapperType: WrapperType;
  onProceed: onProceedCallback;
  onGoBack?: () => void;
  onError: () => void;
  cedingProvider: CedingProvider;
  cedingProviderAccountNumber: string;
  transferMethod: TransferMethod;
  pensionDetails?: TransferPensionDetails;
}

export function ConfirmInspecieTransfer({
  wrapperType,
  cedingProvider,
  onProceed,
  onGoBack,
  cedingProviderAccountNumber,
  transferMethod,
  onError,
  pensionDetails,
}: ConfirmInspecieTransferProps) {
  const dialogProps = useDialog();
  const { startTransfer } = useTransfer();
  const methods = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transferSchema),
    defaultValues: {
      authorise: false,
    },
  });
  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState } = methods;

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true);
    try {
      await startTransfer({
        accountType: wrapperType,
        cedingProviderId: cedingProvider.id,
        cedingProviderAccountNumber: cedingProviderAccountNumber,
        method: transferMethod,
        pensionTransferDetails: pensionDetails,
      });
      onProceed();
    } catch {
      onError();
      setSubmitting(false);
    }
  };

  const cedingProviderName = cedingProvider.name;

  return (
    <>
      <EligibleAssetsDialog {...dialogProps} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>What you should know</StepTitle>
          <ul>
            <StepIntroductionLi>
              We accept transfers of funds, investment trusts and ETFs
              (including REITs and ETCs), even if they are not currently in the
              TILLIT Universe.{' '}
              <StyledSpanLink
                href={
                  tillitFAQs + '#what-kinds-of-assets-can-i-transfer-to-tillit'
                }
                target="_blank"
              >
                Read more
              </StyledSpanLink>
              .
            </StepIntroductionLi>
            <StepIntroductionLi>
              Any transferred funds, investment trusts and ETFs not currently in
              the TILLIT Universe will be hosted in our{' '}
              <StyledSpanLink href={tillitDarkUniversePath} target="_blank">
                Dark Universe
              </StyledSpanLink>
              . You will be able to continue to trade these in your account as
              usual.
            </StepIntroductionLi>
            <StepIntroductionLi>
              Any{' '}
              <StyledSpanLink
                onClick={() => {
                  dialogProps.openDialog();
                }}
              >
                ineligible assets
              </StyledSpanLink>{' '}
              will be sold by {cedingProviderName} and transferred as cash.
            </StepIntroductionLi>
            <StepIntroductionLi>
              {cedingProviderName} may charge dealing, transfer or exit fees.{' '}
              <StyledSpanLink
                href={
                  tillitFAQs +
                  '#what-does-it-cost-to-transfer-my-holdings-in-specie'
                }
                target="_blank"
              >
                Read more
              </StyledSpanLink>
              . If you are unsure, please contact your current provider.
            </StepIntroductionLi>
            <StepIntroductionLi>
              Once a transfer has been initiated, it cannot be cancelled.
            </StepIntroductionLi>
            {wrapperType === WrapperType.Isa && (
              <StepIntroductionLi>
                This transfer does not have an impact on your ISA allowance.
              </StepIntroductionLi>
            )}
            {wrapperType === WrapperType.Gia && (
              <StepIntroductionLi>
                If your current provider has to sell any instruments down to
                cash, you may incur Capital Gains Tax.
              </StepIntroductionLi>
            )}
          </ul>
          <MoreIneligibleInfo>
            <StepIntroductionTypography>
              Find out more about ineligible assets{' '}
              <StyledSpanLink
                onClick={() => {
                  dialogProps.openDialog();
                }}
              >
                here
              </StyledSpanLink>
              .
            </StepIntroductionTypography>
          </MoreIneligibleInfo>
          <StepCheckboxesContainerWide>
            <FormControlLabel
              htmlFor="confirmReadAndUnderstood"
              control={
                <Checkbox
                  id="confirmReadAndUnderstood"
                  name="confirmReadAndUnderstood"
                  inputRef={register}
                />
              }
              label={
                <>
                  I confirm that I have read and understood the information in
                  ‘What you should know’.
                </>
              }
            />
            <FormControlLabel
              htmlFor="authorise"
              control={
                <Checkbox id="authorise" name="authorise" inputRef={register} />
              }
              label={
                <>
                  I authorise TILLIT to transfer my{' '}
                  {wrapperNameFriendly(wrapperType)} in specie from{' '}
                  {cedingProviderName}.
                </>
              }
            />
            <FormControlLabel
              htmlFor="accept"
              control={
                <Checkbox id="accept" name="accept" inputRef={register} />
              }
              label={
                <>
                  I accept that any ineligible assets will be be sold down and
                  transferred as cash.
                </>
              }
            />
          </StepCheckboxesContainerWide>
        </StepContent>
        <StepActionsWithCar>
          <StepButton
            variant="contained"
            disabled={!formState.isValid || submitting}
            type="submit"
            className="magenta"
          >
            Start Transfer
          </StepButton>
          <StepActionsCar style={{ maxWidth: '25rem' }}>
            Capital at risk.{' '}
            {wrapperType === WrapperType.Isa &&
              'Tax treatment depends on an individual’s circumstances and may be subject to change.'}
          </StepActionsCar>
          <GoBackButton onClick={onGoBack} />
        </StepActionsWithCar>
      </form>
    </>
  );
}
