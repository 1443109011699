import { Loading } from 'components/Loading';
import { FontWeight, TextLarge } from 'components/design-system/Text/Text';
import { Assumptions } from 'components/feature/MIFIDCostsAndCharges/Assumptions';
import {
  ChargeRow,
  TotalChargeRow,
} from 'components/feature/MIFIDCostsAndCharges/ChargeRow';
import { NoChargeInfo } from 'components/feature/MIFIDCostsAndCharges/NoChargeInfo';
import { getLongKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import { useGetTradeCostsAndChargesIllustrationQuery } from 'generated/graphql';
import {
  DetailsContainer,
  StyledDetailsBox,
  StyledDetailsContent,
} from './Styles/DetailsBox.style';
import { H4 } from 'components/design-system/Heading/Heading';

interface MiFIDCostsAndChargesProps {
  lumpSum: number;
  selectedIsin: string;
  instrumentType: string;
  kiidDocumentUrl: string;
  trackPopoverClick: (popover: string) => void;
}

export function MiFIDCostsAndCharges({
  lumpSum,
  selectedIsin,
  instrumentType,
  kiidDocumentUrl,
  trackPopoverClick,
}: MiFIDCostsAndChargesProps) {
  const {
    data: tradeIllustration,
    isLoading,
  } = useGetTradeCostsAndChargesIllustrationQuery(
    {
      trade: {
        amount: lumpSum,
        isin: selectedIsin!,
      },
    },
    {
      enabled: !!lumpSum,
    }
  );

  const oneOffs = tradeIllustration?.tradeCostsAndChargesIllustration?.oneOffCostsAndCharges.map(
    (charge) => (
      <ChargeRow
        charge={charge}
        trackPopoverClick={trackPopoverClick}
        oneOff
        amountEntered={lumpSum}
        variant="normal"
        showTooltip
      />
    )
  );

  const ongoing = tradeIllustration?.tradeCostsAndChargesIllustration?.ongoingCostsAndCharges.map(
    (charge) => (
      <ChargeRow
        charge={charge}
        trackPopoverClick={trackPopoverClick}
        amountEntered={lumpSum}
        variant="normal"
        showTooltip
      />
    )
  );

  const hasAnyChargeInformation = !!oneOffs?.length || !!ongoing?.length;

  const finalAmountBeforeCharges =
    lumpSum +
    (tradeIllustration?.tradeCostsAndChargesIllustration.totalGrowth ?? 0);
  const finalAmountAfterCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration.finalAmount ?? 0;
  const totalCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration
      .totalCostsAndChargesOverPeriod ?? 0;

  const kiidDocName = getLongKidDocName(instrumentType!);
  const kiidUrl = kiidDocumentUrl;

  return (
    <StyledDetailsBox>
      <H4>Costs and charges</H4>
      {isLoading ? (
        <Loading />
      ) : (
        <StyledDetailsContent>
          <DetailsContainer>
            {hasAnyChargeInformation && (
              <>
                <Assumptions
                  amount={lumpSum}
                  tradeIllustration={tradeIllustration}
                />
                {!oneOffs?.length ? null : (
                  <>
                    <TextLarge $noMargin $fontWeight={FontWeight.light}>
                      One-offs
                    </TextLarge>
                    {oneOffs}
                  </>
                )}
                {!ongoing?.length ? null : (
                  <>
                    <TextLarge
                      $noMargin
                      $fontWeight={FontWeight.light}
                      style={{ marginTop: '1rem' }}
                    >
                      Ongoing
                    </TextLarge>
                    {ongoing}
                  </>
                )}
                <>
                  <TextLarge $noMargin $fontWeight={FontWeight.light}>
                    Totals
                  </TextLarge>
                  <TotalChargeRow
                    finalAmountBeforeCharges={finalAmountBeforeCharges}
                    finalAmountAfterCharges={finalAmountAfterCharges}
                    totalCharges={totalCharges}
                    variant="normal"
                  />
                </>
              </>
            )}
            {!hasAnyChargeInformation && (
              <NoChargeInfo kiidUrl={kiidUrl} kiidDocName={kiidDocName} />
            )}
          </DetailsContainer>
        </StyledDetailsContent>
      )}
    </StyledDetailsBox>
  );
}
