import { getSellEstimateUnits } from 'components/Dialogs/Trading/helpers';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { StyledLink } from 'components/design-system/Link';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import { TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { Instrument } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { secclOrderExecutionPolicy, termsAndConditions } from 'paths';
import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp, HiExternalLink } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import { PartialDeep } from 'type-fest';
import { getKidDocName } from '../_shared/copyHelpers';
import {
  Content,
  SubSectionWrapper,
  TopBar,
} from './_shared/ImportantInformation.styles';
import { ExistingPosition } from './_shared/ImportantInformation.types';

interface ImportantSellInfoProps {
  title?: string;
  showTopBar?: boolean;
  selectedInstrument: PartialDeep<Instrument>;
  amountEntered: number;
  existingPosition: ExistingPosition;
}

export const ImportantSellInformation = ({
  title = 'Important information',
  showTopBar = true,
  selectedInstrument,
  amountEntered,
  existingPosition,
}: ImportantSellInfoProps) => {
  const [isExpanded, setIsExpanded] = useState(!showTopBar);

  // Units and Amounts
  const unitsExisting = existingPosition.quantity ?? 0;
  const unitsSold =
    getSellEstimateUnits(selectedInstrument, amountEntered) || 0;
  const unitsEstTotal = unitsExisting - unitsSold;

  const amountExisting = existingPosition.currentValue ?? 0;
  const amountEstTotal = amountExisting - (amountEntered || 0);

  // Prices
  const bidPrice = selectedInstrument.bidPrice;

  // URLs and Docs
  const kiidUrl = selectedInstrument?.kiidDocumentUrl;
  const kiidDocName = getKidDocName(selectedInstrument?.instrumentType!);
  const factsheetUrl = selectedInstrument?.factsheetUrl;

  const trackPopoverClick = (popover: string) => {
    trackGa({
      event: GaEventNames.popover,
      content_type: `sell info - ${popover}`,
    });
  };

  return (
    <div>
      {showTopBar && (
        <TopBar type="button" onClick={() => setIsExpanded(!isExpanded)}>
          {title}
          {isExpanded ? (
            <HiChevronUp size="1.5rem" />
          ) : (
            <HiChevronDown size="1.5rem" />
          )}
        </TopBar>
      )}

      <Content isExpanded={isExpanded}>
        <Tabs
          isBorderless={true}
          title={<></>}
          tabs={[
            {
              title: 'Trade details',
              content: (
                <SubSectionWrapper>
                  <KeyValueRow
                    label={'Latest sell price'}
                    description={tooltips.LatestSellPrice}
                    value={format.currencyFull(bidPrice!)}
                    onPopoverClick={() =>
                      trackPopoverClick('Latest sell price')
                    }
                    noMargin
                    stackMobile
                  />
                  {selectedInstrument.priceDate && (
                    <KeyValueRow
                      label={'Latest price update'}
                      description={tooltips.LatestSellPriceUpdate}
                      value={format.date(selectedInstrument.priceDate)}
                      onPopoverClick={() =>
                        trackPopoverClick('Latest price update')
                      }
                      noMargin
                      stackMobile
                    />
                  )}
                  <KeyValueRow
                    label={`Est. new holding (${format.currencyFull(
                      amountEstTotal
                    )})`}
                    value={`${format.units(unitsEstTotal)} ${
                      selectedInstrument?.instrumentType === 'Fund'
                        ? 'units'
                        : 'shares'
                    }`}
                    noMargin
                    stackMobile
                  />
                </SubSectionWrapper>
              ),
            },
            {
              title: 'Docs',
              content: (
                <SubSectionWrapper>
                  <TextNormal $noMargin>
                    <StyledLink href={termsAndConditions} target="_blank">
                      Terms & Conditions <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                  <TextNormal $noMargin>
                    <StyledLink
                      href={secclOrderExecutionPolicy}
                      target="_blank"
                    >
                      Order Execution Policy (Seccl) <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                  {!!kiidUrl && (
                    <TextNormal $noMargin>
                      <StyledLink href={kiidUrl!} target="_blank">
                        {kiidDocName} <HiExternalLink />
                      </StyledLink>
                    </TextNormal>
                  )}
                  {!!factsheetUrl && (
                    <TextNormal $noMargin>
                      <StyledLink href={factsheetUrl} target="_blank">
                        Factsheet <HiExternalLink />
                      </StyledLink>
                    </TextNormal>
                  )}
                </SubSectionWrapper>
              ),
            },
          ]}
        />
      </Content>
    </div>
  );
};
