import { Container } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledRecentlyViewedFundsContainer = styled.div`
  width: 100%;
  background-color: ${colors['grey-50']};
  overflow: hidden;
`;

export const RecentlyViewedFundsInnerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing(7.5, 3.75)};
`;

export const RecentlyViewedFundsHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;
