import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@material-ui/lab';
import { tillitFetch } from 'api/tillitFetch';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { PasswordStrengthBreakdownIndicator } from 'components/design-system/Passwords/PasswordStrengthCheck';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { AccountType } from 'generated/graphql';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { passwordSchema, ratePassword } from 'helpers/passwordValidation';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { BasicDeclarationsFormValues } from '../BasicDeclarations/BasicDeclarations';
import { EmailFormValues } from '../EmailAddressStep/EmailAddressStep';
import { NameStepFormValues } from '../NameStepPreAuth/NameStep';
import { PromoCodeFormValues } from '../PromoCodeStep/PromoCodeStep';

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

const passwordFormSchema = yup.object().shape({
  password: passwordSchema,
});

export interface PasswordStepProps {
  onProceed: (values: PasswordFormValues) => void;
  onGoBack: () => void;
  promoCodeFormValues?: PromoCodeFormValues;
  emailAddressFormValues: EmailFormValues;
  selectedAccountType: AccountType;
  basicDeclarationsValues: BasicDeclarationsFormValues;
  nameFormValues: NameStepFormValues;
  referralCode?: string;
  optIntoComplianceDataSharing?: boolean;
}

export interface PasswordFormValues
  extends yup.Asserts<typeof passwordFormSchema> {}

interface SignUpApi {
  title?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  confirmPassword: string;
  referralCode?: string;
  promoCode?: string;
  signupSource?: string;
  marketingOptIn: boolean;
  initialAccountType: AccountType;
  userContext: string | null;
  optIntoComplianceDataSharing?: boolean;
}

export interface SignUpApiResBody {
  success: boolean;
  message: string;
  details: Record<string, string[]>;
}

const useSignUp = () => {
  return useMutation<any, Error, SignUpApi>('/identity/register', (data) => {
    return tillitFetch('/identity/register', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  });
};

const getErrorDescription = (body: SignUpApiResBody): string => {
  return body?.details?.['']?.[0] ?? body.message;
};

export function PasswordStep({
  onProceed,
  onGoBack,
  promoCodeFormValues,
  emailAddressFormValues,
  selectedAccountType,
  basicDeclarationsValues,
  nameFormValues,
  referralCode,
  optIntoComplianceDataSharing,
}: PasswordStepProps) {
  console.log({ loc: 'PasswordStep', optIntoComplianceDataSharing });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { mutateAsync } = useSignUp();

  const methods = useForm<PasswordFormValues>({
    resolver: passwordFormSchema && yupResolver(passwordFormSchema),
  });
  const { watch, handleSubmit } = methods;

  const onSubmit = async (data: PasswordFormValues) => {
    try {
      const userContext = getCognitoUserContextData(
        emailAddressFormValues.email
      );

      const signUpResult = await mutateAsync({
        email: emailAddressFormValues.email,
        password: data.password,
        confirmPassword: data.password,
        promoCode: promoCodeFormValues?.promoCode,
        marketingOptIn: basicDeclarationsValues.marketingOptIn,
        initialAccountType: selectedAccountType,
        signupSource: 'None',
        firstName: nameFormValues.firstName,
        lastName: nameFormValues.lastName,
        title: nameFormValues.title,
        referralCode,
        userContext,
        optIntoComplianceDataSharing,
      });

      if (signUpResult.status === 200) {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.password,
          coupon: promoCodeFormValues?.promoCode,
        });
        onProceed(data);
      }

      if (signUpResult.status === 400) {
        const signUpResultBody = (await signUpResult.json()) as SignUpApiResBody;
        const errorDescription = getErrorDescription(signUpResultBody);
        setErrorMessage(errorDescription);
      }
    } catch (error) {
      setErrorMessage('Something went wrong');
    }
  };

  const handleBack = () => {
    onGoBack();
  };

  const watchPassword = watch('password');
  const passwordRating = ratePassword(watchPassword);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContainer>
          <StepContent>
            <StepTitle>Password</StepTitle>

            <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
              <StepIntroductionTypography>
                Choose a password to access your TILLIT account.
              </StepIntroductionTypography>
            </StepIntroduction>

            {errorMessage && (
              <ErrorAlert variant="outlined" severity="error">
                {errorMessage}
              </ErrorAlert>
            )}

            <StepFormContainer>
              <TextField
                type="password"
                name="password"
                label="Password"
                fullWidth
                autoFocus
              />
              <PasswordStrengthBreakdownIndicator
                breakdown={passwordRating.breakdown}
              />
            </StepFormContainer>
          </StepContent>
          <StepActions>
            <FormState>
              {({ isSubmitting }) => (
                <>
                  <StepButton
                    type="submit"
                    className="magenta"
                    disabled={isSubmitting || !passwordRating.pass}
                  >
                    Continue
                  </StepButton>
                  <GoBackButton onClick={handleBack} disabled={isSubmitting} />
                </>
              )}
            </FormState>
          </StepActions>
        </StepContainer>
      </form>
    </FormProvider>
  );
}
