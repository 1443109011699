import Cookies from 'js-cookie';

export const useFreeview = () => {
  const MAX_FREE_VIEWS = 3;
  const FREEVIEW_COOKIE_EXPIRY_DAYS = 30;

  const canFreeView = (assetId: string) => {
    const viewedIds = Cookies.getJSON('viewedAssetIds') || [];
    if (viewedIds.includes(assetId)) {
      return true;
    } else if (viewedIds.length < MAX_FREE_VIEWS) {
      viewedIds.push(assetId);
      Cookies.set('viewedAssetIds', viewedIds, {
        expires: FREEVIEW_COOKIE_EXPIRY_DAYS,
        secure: true,
        sameSite: 'lax',
      });

      return true;
    }

    return false;
  };

  return { canFreeView };
};
