import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { generateFormattedInput } from 'components/Form/FormattedInput';
import { MaskedField } from 'components/Form/MaskedField';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useQueryClient } from 'react-query';
import { UserProfileQueryUserProfile } from 'types/graphqlTypes';
import * as Yup from 'yup';
import {
  GoBackButton,
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';

const definitions = {
  '0': /[0-9]|\*/,
  a: /[a-zA-Z]|\*/,
};
const NiInputWithMask = generateFormattedInput({
  mask: 'aa-00-00-00-a',
  definitions,
});

const NiInput = generateFormattedInput({ mask: 'aa-00-00-00-a' });

// cSpell:disable-next-line
const NI_NUMBER_REGEX = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D])$/i;

const nationalInsuranceNumberSchema = Yup.object().shape({
  nationalInsuranceNumber: Yup.object().shape({
    isMasked: Yup.boolean(),
    value: Yup.string().when('isMasked', {
      is: false,
      then: (s) =>
        s
          .nullable()
          .label('National Insurance number')
          .required()
          .transform((value) => value.replaceAll('-', ''))
          .matches(
            NI_NUMBER_REGEX,
            'Must be a valid National Insurance number'
          ),
    }),
  }),
});

interface NationalInsuranceFormInputs
  extends Yup.TypeOf<typeof nationalInsuranceNumberSchema> {}
interface NationalInsuranceFormValues
  extends Yup.Asserts<typeof nationalInsuranceNumberSchema> {}

interface NationalInsuranceNumberStepProps extends StepProps {
  source:
    | 'checkoutStep'
    | 'openAccountStep'
    | 'quickOrderStep'
    | 'secondAccountStep'
    | 'transferStep';
  canSkip?: boolean;
  introduction?: JSX.Element | null;
  userProfile?: Pick<
    UserProfileQueryUserProfile,
    'nationalInsuranceNumberTokenised'
  >;
}

export function NationalInsuranceNumberStep({
  onProceed,
  onGoBack,
  source,
  canSkip,
  introduction,
  userProfile: userProfileProp,
}: NationalInsuranceNumberStepProps) {
  const queryClient = useQueryClient();

  const userProfile =
    userProfileProp ||
    queryClient.getQueryData<UserProfileQuery>(useUserProfileQuery.getKey())
      ?.userProfile!;

  const defaultValues: NationalInsuranceFormInputs = nationalInsuranceNumberSchema.cast(
    {
      nationalInsuranceNumber: {
        isMasked: !!userProfile.nationalInsuranceNumberTokenised?.maskedValue,
        value: userProfile.nationalInsuranceNumberTokenised?.maskedValue ?? '',
      },
    }
  );

  const validationSchema = nationalInsuranceNumberSchema;

  const { mutateAsync, isError } = useUpdateUserProfileMutation();

  const onSubmit = async (
    data: NationalInsuranceFormValues,
    isDirty: boolean
  ) => {
    if (isDirty) {
      try {
        await queryClient.cancelQueries({
          queryKey: useUserProfileQuery.getKey(),
        });

        await mutateAsync({
          input: {
            nationalInsuranceNumber: !data.nationalInsuranceNumber.isMasked
              ? { value: data.nationalInsuranceNumber.value! }
              : undefined,
          },
        });

        queryClient.setQueryData<UserProfileQuery>(
          useUserProfileQuery.getKey(),
          (oldData) => {
            return {
              clientSummary: oldData?.clientSummary!,
              userProfile: {
                ...oldData?.userProfile!,
                nationalInsuranceNumberTokenised: {
                  maskedValue: mask(data.nationalInsuranceNumber.value),
                },
              },
            };
          }
        );

        onProceed();
      } catch {
        // handled by state
      }
    } else {
      onProceed();
    }

    if (source === 'openAccountStep') {
      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.nationalInsuranceNumber,
      });
    }
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <StepContainer>
      <Form<NationalInsuranceFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues as any}
        schema={validationSchema}
      >
        <StepContent>
          <StepTitle>National Insurance number</StepTitle>

          {introduction ? (
            introduction
          ) : (
            <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
              <StepIntroductionTypography>
                Your National Insurance number is required for tax purposes.
              </StepIntroductionTypography>
            </StepIntroduction>
          )}
          <ServerError isVisible={isError} />

          <StepFormContainer>
            <MaskedField
              name="nationalInsuranceNumber"
              id="nationalInsuranceNumber"
              label="National Insurance number"
              defaultValue={defaultValues.nationalInsuranceNumber.value}
              placeholder="QQ-12-34-56-A"
              autoFocus
              inputComponent={
                defaultValues.nationalInsuranceNumber.value
                  ? NiInputWithMask
                  : NiInput
              }
            />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>
                <GoBackButton onClick={handleBack} disabled={isSubmitting} />
                {canSkip && (
                  <SkipButton
                    variant="outlined"
                    className="richBlue"
                    onClick={() => onProceed()}
                  >
                    Skip
                  </SkipButton>
                )}
              </>
            )}
          </FormState>
        </StepActions>
      </Form>
    </StepContainer>
  );
}

function mask(str: string | undefined, visibleChars: number = 3) {
  if (!str) {
    return '';
  }
  const offset = str.length - visibleChars;
  return '*'.repeat(offset) + str.substring(offset);
}
