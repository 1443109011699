import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Hr = styled.hr`
  background-color: ${colors.midGrey};
  height: 1px;
  border: 0;
  width: 100%;
  margin: 0;
`;

export const ArrowText = styled.div`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  margin-top: 1rem;
  p {
    color: ${colors.magenta};
  }
  svg {
    color: ${colors.magenta};
    font-size: 1.2rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    gap: 28px;
  }
  > div {
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 350px;
    }
  }
`;
