import { Tab, Tabs } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

export const StyledTabs = styled(Tabs)`
  width: 100%;
  position: relative;
  .MuiTabs-indicator {
    background-color: ${colors.magenta};
  }
`;

export const StyledTab = styled(({ size, ...otherProps }) => (
  <Tab {...otherProps} />
))`
  &.MuiTab-root {
    ${(p) =>
      p.size &&
      css`
        min-width: ${100 / p.size}%;
      `}
    background-color: ${transparentize(0.95, colors.magenta)};
    font-size: 1.375rem;
    font-weight: 300;
    text-transform: inherit;
    color: ${colors.richBlack};
    padding: ${(p) => p.theme.spacing(0.75, 2.5)};
  }

  &.Mui-selected {
    font-weight: 500;
    color: ${colors.richBlack};
  }

  &.Mui-disabled {
    color: ${transparentize(0.5, colors.richBlack)};
  }
`;
