import {
  BasketBuyOrder,
  LsBuyOrders,
  useFundsBasket,
} from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useMode } from 'components/feature/mode/useMode';
import {
  Account,
  PortfolioRebalancing,
  RebalancingBuyOrderInput,
  RebalancingSellOrderInput,
  useCreatePortfolioRebalancingMutation,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUpdatePortfolioRebalancingMutation,
} from 'generated/graphql';
import { useQueryClient } from 'react-query';
import { PartialDeep } from 'type-fest';
import { useLocalStorage } from 'usehooks-ts';

export function useUnauthBasketPlacement() {
  const queryClient = useQueryClient();
  const createPortfolioRebalancingMutationQuery = useCreatePortfolioRebalancingMutation();
  const {
    mutateAsync: createPortfolioRebalancingMutation,
  } = createPortfolioRebalancingMutationQuery;

  const updatePortfolioRebalancingMutationQuery = useUpdatePortfolioRebalancingMutation();
  const {
    mutateAsync: updatePortfolioRebalancingMutation,
  } = updatePortfolioRebalancingMutationQuery;

  const [
    nonAccountBuyOrdersLs,
    setNonAccountBuyOrdersLs,
  ] = useLocalStorage<LsBuyOrders>('nonAccountBuyOrders', {});

  const { basketSummary } = useFundsBasket({ selectedAccountId: 'unknown' });
  const [mode, setMode] = useMode();

  const createPortfolioRebalancing = async ({
    accountId,
    basketBuyOrders,
  }: {
    accountId: string;
    basketBuyOrders: Pick<BasketBuyOrder, 'isin' | 'amount'>[];
  }) =>
    createPortfolioRebalancingMutation({
      input: {
        accountId: accountId,
        buyOrders: basketBuyOrders.map((fund) => ({
          amount: fund.amount,
          isin: fund.isin,
        })),
        sellOrders: [],
      },
    });

  const updatePortfolioRebalancing = async ({
    basketBuyOrders,
    portfolioRebalancing,
  }: {
    accountId: string;
    basketBuyOrders: Pick<BasketBuyOrder, 'isin' | 'amount'>[];
    portfolioRebalancing: PartialDeep<PortfolioRebalancing>;
  }) => {
    if (!portfolioRebalancing.id) {
      return;
    }
    const unknownBasketItemsIsins = basketBuyOrders.map((buyOrder) => {
      return buyOrder.isin;
    });
    const newBuyOrders = basketBuyOrders.map((fund) => ({
      amount: fund.amount,
      isin: fund.isin,
    }));
    const existingBuyOrders =
      portfolioRebalancing?.buyOrders
        ?.filter((buyOrder) => {
          return (
            buyOrder?.isin && !unknownBasketItemsIsins.includes(buyOrder.isin)
          );
        })
        .map((buyOrder) => ({
          amount: buyOrder?.amount,
          isin: buyOrder?.isin,
        })) || [];

    const existingSellOrders = portfolioRebalancing?.sellOrders?.map(
      (sellOrder) => ({
        enteredAmount: sellOrder?.enteredAmount,
        isin: sellOrder?.isin,
        quantity: sellOrder?.quantity,
        quotedBid: sellOrder?.quotedBid,
        quotedBidDateUtc: sellOrder?.quotedBidDateUtc,
      })
    );

    return updatePortfolioRebalancingMutation({
      input: {
        portfolioRebalancingId: portfolioRebalancing.id,
        buyOrders: [
          ...existingBuyOrders,
          ...newBuyOrders,
        ] as RebalancingBuyOrderInput[],
        sellOrders: existingSellOrders as RebalancingSellOrderInput[],
      },
    });
  };

  const addUnknownBasketItemsToAccountBasket = async (
    account: PartialDeep<Account>,
    accountPortfolioRebalancing?: PartialDeep<PortfolioRebalancing>
  ) => {
    const accountId = account.id;
    const wrapperType = account.wrapperType;
    if (!accountId || !wrapperType) {
      return;
    }
    setNonAccountBuyOrdersLs({});
    setMode({
      wrapperType,
      mode: 'buy',
    });
    let selectedRebalancingId;
    try {
      if (accountPortfolioRebalancing) {
        const updatePortfolioRebalancingRes = await updatePortfolioRebalancing({
          accountId,
          basketBuyOrders: nonAccountBuyOrdersLs?.buyOrders || [],
          portfolioRebalancing: accountPortfolioRebalancing,
        });
        selectedRebalancingId =
          updatePortfolioRebalancingRes?.updatePortfolioRebalancing.id;
      } else {
        const createPortfolioRebalancingRes = await createPortfolioRebalancing({
          accountId,
          basketBuyOrders: nonAccountBuyOrdersLs?.buyOrders || [],
        });
        selectedRebalancingId =
          createPortfolioRebalancingRes?.createPortfolioRebalancing.id;
      }

      queryClient.invalidateQueries(
        usePortfolioRebalancingQuery.getKey({
          id: selectedRebalancingId!,
        })
      );
      queryClient.invalidateQueries(usePortfolioRebalancingsQuery.getKey());
      queryClient.invalidateQueries(
        usePortfolioRebalancingsQuery.getKey({ filter: { active: true } })
      );
      queryClient.invalidateQueries(
        usePortfolioRebalancingsQuery.getKey({
          filter: { active: true, accountId },
        })
      );

      return selectedRebalancingId;
    } catch (e) {
      // todo...
    }
  };

  const handleDiscard = () => {
    if (mode?.mode === 'buy' && mode.wrapperType === undefined) {
      setMode(null);
    }

    setNonAccountBuyOrdersLs({});
  };

  return {
    hasNonAccountBuyOrders: !!nonAccountBuyOrdersLs?.buyOrders?.length,
    basketSummary,
    addUnknownBasketItemsToAccountBasket,
    handleDiscard,
  };
}
