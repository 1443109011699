import {
  Box,
  Grid,
  GridProps,
  lighten,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  FontSize,
  fontSizeLookup,
  lineHeightLookup,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import React, { useMemo } from 'react';
import Styled, { css } from 'styled-components';
import { Label } from './Label';

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 'bold',
    textAlign: 'left',
    '&.normal': {
      fontWeight: 'normal',
    },
    '&.light': {
      fontWeight: '300',
    },
  },
  muted: {
    color: lighten(theme.palette.text.primary, 0.6),
  },

  noMargin: {
    margin: 0,
  },
}));

const Divider = Styled.hr`
  background: none;
  border: 1px ${(p) => (p.color ? p.color : colors.clouds)} solid;
  margin: ${(p) => p.theme.spacing(0.625, 0)};
`;

const LabelContainer = Styled(Grid)<GridProps & { $stackMobile?: boolean }>`
  max-width: 70%;
  
  ${(p) =>
    p.$stackMobile &&
    css`
      @media (max-width: 450px) {
        width: 100%;
        max-width: 100%;
      }
    `}
`;

export interface KeyValueRowProps {
  label: React.ReactNode;
  value: React.ReactNode;
  description?: React.ReactNode;
  muted?: boolean;
  divider?: boolean;
  color?: string;
  valueWeight?: 'bold' | 'normal' | 'light';
  valueColor?: string;
  className?: string;
  stackMobile?: boolean;
  noMargin?: boolean;
  fontSize?: FontSize;
  onPopoverClick?: () => void;
}

export function KeyValueRow({
  label,
  value,
  description,
  muted,
  divider,
  color,
  valueWeight,
  valueColor = colors.richBlack,
  className,
  stackMobile,
  noMargin = false,
  fontSize = FontSize.normal,
  onPopoverClick,
}: KeyValueRowProps) {
  const classes = useStyles();

  const boxProps = useMemo(() => {
    return {
      className: clsx(muted && classes.muted, noMargin && classes.noMargin),
    };
  }, [muted, classes.muted, classes.noMargin, noMargin]);

  const fontSizeStyle = useMemo(() => {
    return {
      fontSize: fontSizeLookup[fontSize],
      lineHeight: lineHeightLookup[fontSize],
    };
  }, [fontSize]);

  const valueColorStyle = useMemo(() => {
    return {
      color: valueColor,
    };
  }, [valueColor]);

  return (
    <div className={className}>
      {divider && <Divider color={color} />}
      <Box my={1} {...boxProps}>
        <Grid container justify="space-between" alignItems="flex-end">
          <LabelContainer item $stackMobile={stackMobile}>
            <Label
              description={description}
              style={fontSizeStyle}
              onPopoverClick={onPopoverClick}
            >
              {label}
            </Label>
          </LabelContainer>
          <Grid item>
            <Typography
              className={[classes.value, valueWeight].join(' ')}
              style={{ ...fontSizeStyle, ...valueColorStyle }}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
