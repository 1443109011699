import { FundDetailsContainer, Label, Value } from './Styles/FundDetails.style';

interface FundDetailsProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
  valueAlign?: 'left' | 'right';
  variant?: 'contained' | 'outlined';
}

export function FundDetails({
  label,
  value,
  valueAlign = 'left',
  variant = 'contained',
}: FundDetailsProps) {
  const extraMargin = label === 'OCF' ? true : false;
  return (
    <FundDetailsContainer
      $layout={variant}
      $bottomMargin={extraMargin}
      $align={valueAlign}
    >
      <Label $layout={variant}>{label}</Label>
      <Value $align={valueAlign} $layout={variant}>
        {value}
      </Value>
    </FundDetailsContainer>
  );
}
