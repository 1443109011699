import { useMediaQuery, useTheme } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { AutoSaveInvestConfirm } from 'components/feature/autoSaveInvest/confirm/Confirm';
import { AutoSaveInvestNewDeposit } from 'components/feature/autoSaveInvest/newDeposit/NewDeposit';
import { SelectFundsPercentages } from 'components/feature/autoSaveInvest/regularInvest/SelectFundsPercentages';
import { useAccountsQuery } from 'generated/graphql';
import { getWrapperTypeForPathSegment } from 'helpers/accountHelpers';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import {
  autoSaveInvestPathConfirmPath,
  autoSaveInvestPathCreateDepositPath,
} from 'paths';
import { Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';

const PageWrapper = styled.div`
  min-height: 100vh;
`;

const PageInner = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function AutoSaveInvestPage() {
  const theme = useTheme();
  const { action, wrapperType } = useParams<{
    action: 'setup' | 'setup-auto-invest';
    wrapperType: string;
  }>();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const accountsQuery = useAccountsQuery();
  const account = accountsQuery?.data?.accounts?.find(
    (account) =>
      account.wrapperType === getWrapperTypeForPathSegment(wrapperType)
  );

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <PageWrapper>
      <QueryState {...accountsQuery}>
        {() => {
          return (
            <PageInner>
              {account && account.id && (
                <Switch>
                  <Route path={autoSaveInvestPathCreateDepositPath}>
                    <AutoSaveInvestNewDeposit
                      action={action}
                      account={account}
                    />
                  </Route>
                  <Route path={autoSaveInvestPathConfirmPath}>
                    <AutoSaveInvestConfirm action={action} account={account} />
                  </Route>
                  <Route>
                    <SelectFundsPercentages account={account} />
                  </Route>
                </Switch>
              )}
            </PageInner>
          );
        }}
      </QueryState>
    </PageWrapper>
  );
}
