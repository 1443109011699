/**
 * Given a tag, return the appropriate exemplar tag if tag is goal based
 */
export const investmentGoalExemplarTagsMap: Record<string, string> = {
  DIFFERENT: 'DIFFERENTEX',
  INCBOOSTERS: 'INCBOOSTERSEX',
  INVFORDECADES: 'INVFORDECADESEX',
  READYMADE: 'READYMADEEX',
  RETURNSLEDBYVAL: 'RETURNSLEDBYVALEX',
  SHIELDPROTECT: 'SHIELDPROTECTEX',
};

/**
 * Given an tag, return true if it is an investment goal
 */
export const tagIsInvestmentGoal = (tag: string) => {
  return Object.keys(investmentGoalExemplarTagsMap).includes(tag);
};
