import { VideoJsPlayerOptions } from '@tillitinvest/video.js';
import { AssetIcon } from 'components/Icons';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { GaEventNames } from 'constants/gaConstants';
import { PublishedAssetContent, Tag } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { generateFundDetailsMeetManagersPath } from 'paths';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AssetQueryAsset, AssetQueryAssetVideo } from 'types/graphqlTypes';
import { HeaderActions } from './HeaderActions';
import {
  ActionsWrapper,
  Container,
  ContainerInnerNoVid,
  ContainerInnerVid,
  ContentWrapper,
  IconWrapper,
  StyledArrowDown,
  StyledFundDescription,
  StyledFundName,
  StyledFundOneLiner,
  VideoWrapper,
} from './Styles/NewHeader.styles';

import { neverDisplayTagCodes } from 'components/feature/FundsList/helpers/tagPolicies';
import { getGlossaryDataForCode } from 'components/Glossary/GlossaryData';
import { AnyAsset } from 'types/graphqlTypes';
import { BackToResults } from './BackToResults';
import { HeaderTags } from './HeaderTags';

interface TagToRender {
  name: string;
  isActive: boolean;
  display: boolean;
  categoryCode: string;
  code: string;
  isImportant: boolean;
}

interface PureHeaderProps {
  asset: AnyAsset;
  isin: string;
  fundTitle: string;
  fundDescription: JSX.Element[] | null;
  assetClass: string;
  fundOneLiner: string;
  userHolds: boolean;
  headerTags: TagToRender[];
  hideActions?: boolean;
  onSell: () => void;
  onBuy: () => void;
  handleInstrumentChange: (isin: string) => void;
}

export function PureHeader({
  asset,
  isin,
  fundDescription,
  assetClass,
  fundTitle,
  fundOneLiner,
  userHolds,
  headerTags,
  hideActions = false,
  onSell,
  onBuy,
  handleInstrumentChange,
}: PureHeaderProps) {
  const refContainer = useRef(null);
  const [showArrowDown, setShowArrowDown] = useState(false);

  const handleResizeWindow = () => {
    if (refContainer.current) {
      const containerHeight = refContainer.current!['clientHeight'];
      // doing `refContainer.current!.clientHeight` causes an error on typescript, below is some info on this
      // https://bobbyhadz.com/blog/typescript-property-does-not-exist-on-type-never
      window.innerHeight < containerHeight
        ? setShowArrowDown(false)
        : setShowArrowDown(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  });

  const handleScrollDown = () => {
    if (refContainer.current) {
      window.scrollTo(0, refContainer.current!['clientHeight'] - 80);
    }
  };

  return (
    <Container ref={refContainer}>
      <ContainerInnerNoVid>
        <IconWrapper>
          <AssetIcon name={assetClass} />
        </IconWrapper>
        <StyledFundName variant="h2">{fundTitle}</StyledFundName>
        <StyledFundOneLiner variant="h1">{fundOneLiner}</StyledFundOneLiner>
        {fundDescription && (
          <StyledFundDescription className="noVid">
            {fundDescription}
          </StyledFundDescription>
        )}
        <HeaderTags tags={headerTags} centeredOnLarge={true} />

        {!hideActions && (
          <ActionsWrapper className={'default-header'}>
            <HeaderActions
              asset={asset}
              isin={isin}
              userHolds={userHolds}
              onBuy={onBuy}
              onSell={onSell}
              handleInstrumentChange={handleInstrumentChange}
            />
          </ActionsWrapper>
        )}

        <BackToResults />
        {showArrowDown && !hideActions && (
          <StyledArrowDown onClick={handleScrollDown} />
        )}
      </ContainerInnerNoVid>
    </Container>
  );
}

interface PureVideoHeaderProps extends PureHeaderProps {
  introVideo: AssetQueryAssetVideo;
  hasFullVideo?: boolean;
  tags?: string;
}

export function PureVideoHeader({
  isin,
  asset,
  fundDescription,
  assetClass,
  fundTitle,
  fundOneLiner,
  introVideo,
  userHolds,
  hasFullVideo,
  headerTags,
  hideActions = false,
  onSell,
  onBuy,
  handleInstrumentChange,
}: PureVideoHeaderProps) {
  const refContainer = useRef(null);
  const [showArrowDown, setShowArrowDown] = useState(false);

  const videoOptions: VideoJsPlayerOptions = useMemo(() => {
    return {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      poster: introVideo.thumbnailUrl!,
      html5: {
        nativeTextTracks: false,
      },
      sources: [
        {
          src: introVideo?.videoUrl!,
        },
      ],
      tracks: [
        {
          src: introVideo.subtitlesUrl!,
          kind: 'subtitles',
          srclang: 'en',
          label: 'English',
        },
      ],
    };
  }, [introVideo.subtitlesUrl, introVideo.thumbnailUrl, introVideo.videoUrl]);

  const handleResizeWindow = () => {
    if (refContainer.current) {
      const containerHeight = refContainer.current!['clientHeight'];
      // doing `refContainer.current!.clientHeight` causes an error on typescript, below is some info on this
      // https://bobbyhadz.com/blog/typescript-property-does-not-exist-on-type-never
      window.innerHeight < containerHeight
        ? setShowArrowDown(false)
        : setShowArrowDown(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();
  });

  const handleScrollDown = () => {
    if (refContainer.current) {
      window.scrollTo(0, refContainer.current!['clientHeight'] - 80);
    }
  };

  return (
    <Container ref={refContainer}>
      <ContainerInnerVid>
        <ContentWrapper>
          <IconWrapper>
            <AssetIcon name={assetClass} />
          </IconWrapper>

          <StyledFundName variant="h2">{fundTitle}</StyledFundName>
          <StyledFundOneLiner variant="h1">{fundOneLiner}</StyledFundOneLiner>
          {fundDescription && (
            <StyledFundDescription>{fundDescription}</StyledFundDescription>
          )}
          <HeaderTags tags={headerTags} />
          {!hideActions && (
            <ActionsWrapper className={'Video-header'}>
              <HeaderActions
                asset={asset}
                isin={isin}
                userHolds={userHolds}
                onBuy={onBuy}
                onSell={onSell}
                handleInstrumentChange={handleInstrumentChange}
              />
            </ActionsWrapper>
          )}
          <BackToResults $align="flex-start" />
        </ContentWrapper>
        <VideoWrapper>
          <VideoPlayer
            key={asset.id}
            videoJsOptions={videoOptions}
            shouldShowEndCta={hasFullVideo}
            video={fundOneLiner}
            videoType="Fund Details - Intro"
            nearlyCompleteCtaLink={
              generateFundDetailsMeetManagersPath({
                id: asset.id!,
                slug: asset?.slug!,
              }) + '#meet-the-manager'
            }
            nearlyCompleteCtaText="Watch the full interview"
          />
        </VideoWrapper>
        {showArrowDown && !hideActions && (
          <StyledArrowDown onClick={handleScrollDown} />
        )}
      </ContainerInnerVid>
    </Container>
  );
}

interface HeaderProps {
  title: string;
  description: string;
  assetClass: string;
  asset: AssetQueryAsset;
  userHolds: boolean;
  assetId: string;
  mainContent: PublishedAssetContent[] | undefined;
  isin: string;
  hasFullVideo?: boolean;
  hideActions?: boolean;
  handleInstrumentChange: (isin: string) => void;
}

export function Header({
  title,
  description,
  assetClass,
  asset,
  userHolds,
  assetId,
  mainContent,
  isin,
  hasFullVideo,
  hideActions = false,
  handleInstrumentChange,
}: HeaderProps) {
  const urlParams = new URLSearchParams(window.location.search);
  const activeTagCodes = urlParams.get('tags')?.split(',') || undefined;

  const searchTags = activeTagCodes
    ? activeTagCodes?.map((tagCode) => {
        const tagData = getGlossaryDataForCode(tagCode.replace('+', ''));
        if (tagData) {
          return { ...tagData, isImportant: tagCode.startsWith('+') };
        }
        return {
          title: tagCode.replace('+', ''),
          code: tagCode,
          content: null,
          isImportant: tagCode.startsWith('+'),
        };
      })
    : [];

  const tags = asset.tags.nodes || null;

  const headerTags: TagToRender[] = tags
    ? tags
        .map((tag: Tag) => {
          const searchTag = (searchTags || []).find(
            (searchTag) => searchTag.code === tag?.code
          );
          const isActive = !!searchTag;
          const isImportant = !!searchTag?.isImportant;
          return {
            name: tag?.name,
            isActive,
            display:
              !neverDisplayTagCodes.includes(tag.code) &&
              (isActive || !!tag.display),
            categoryCode: tag.categoryCode,
            code: tag.code,
            isImportant,
          };
        })
        .filter(({ display }) => display)
        .sort((a, b) => {
          if (
            a.isImportant !== b.isImportant &&
            (a.isImportant === true || b.isImportant === true)
          ) {
            return a.isImportant ? -1 : 1;
          }

          if (a.isActive === b.isActive) {
            return (a?.name?.toUpperCase() || '') <
              (b?.name?.toUpperCase() || '')
              ? -1
              : 1;
          }
          return a.isActive ? -1 : 1;
        })
    : [];

  const fundDescription = useMemo(() => {
    if (!mainContent) return false;
    return mainContent.map((content) => {
      if (content.type === 'TEXT_HTML') {
        return (
          <div
            key={content.title}
            dangerouslySetInnerHTML={{
              __html: content.renderedContent!,
            }}
          ></div>
        );
      } else {
        return <p>{content}</p>;
      }
    });
  }, [mainContent]);

  const trackActionBuy = () => {
    const tags = asset.tags?.nodes
      ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
      : [];

    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'buy', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: title,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  };

  const trackActionSell = () => {
    const tags = asset.tags?.nodes
      ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
      : [];

    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'sell', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: title,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  };

  return (
    <>
      {asset.introVideo ? (
        <PureVideoHeader
          asset={asset}
          isin={isin}
          fundOneLiner={description}
          fundDescription={fundDescription ? fundDescription : null}
          assetClass={assetClass}
          fundTitle={title}
          userHolds={userHolds}
          onSell={() => trackActionSell()}
          onBuy={() => trackActionBuy()}
          introVideo={asset.introVideo}
          hasFullVideo={hasFullVideo}
          hideActions={hideActions}
          headerTags={headerTags}
          handleInstrumentChange={handleInstrumentChange}
        />
      ) : (
        <PureHeader
          asset={asset}
          isin={isin}
          fundOneLiner={description}
          fundDescription={fundDescription ? fundDescription : null}
          assetClass={assetClass}
          fundTitle={title}
          userHolds={userHolds}
          onSell={() => trackActionSell()}
          onBuy={() => trackActionBuy()}
          hideActions={hideActions}
          headerTags={headerTags}
          handleInstrumentChange={handleInstrumentChange}
        />
      )}
    </>
  );
}
