import {
  CustomButtonV2,
  buttonColourOptions,
  buttonSizeOptions,
} from 'components/design-system/Button/CustomButtonV2';
import { useDialog } from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketDialog';
import { AddSellOrderToBasketFullFlowDialog } from 'components/feature/PortfolioBuilder/AddToBasket/AddSellOrderToBasketFullFlowDialog';
import { ExistingPosition } from 'components/feature/PortfolioBuilder/ImportantInformation/_shared/ImportantInformation.types';
import { useAuth } from 'context/AuthContext';
import { WrapperType, useAccountsQuery } from 'generated/graphql';
import { useMemo } from 'react';
import { AnyAsset } from 'types/graphqlTypes';

export interface SellButtonProps {
  asset: AnyAsset;
  selectedPosition?: ExistingPosition;
  assetName?: string;
  isin?: string;
  accountId?: string;
  accountType?: WrapperType;
  small?: boolean;
  size?: buttonSizeOptions;
  label?: string;
  hover?: boolean;
  canSell?: boolean;
  $color?: buttonColourOptions;
  variant?: 'outlined' | 'contained';
  onClick?: () => void;
}

export function SellButton({
  asset,
  selectedPosition,
  canSell,
  accountId,
  isin,
  size = 'normal',
  label = 'Sell',
  hover = true,
  $color = 'primary',
  variant = 'contained',
}: SellButtonProps) {
  const dialogProps = useDialog();
  const { signedIn } = useAuth();

  const handleAddToBasket = () => {
    dialogProps.openDialog();
  };

  const { data: accountsData } = useAccountsQuery();
  const accountsWithMatchingPosition = accountsData?.accounts?.filter((acc) =>
    acc.positions.find((p) => p?.instrument?.isin === isin)
  );

  const selectedAccount = useMemo(() => {
    if (accountId) {
      return accountsWithMatchingPosition?.find((acc) => acc.id === accountId);
    }

    if (
      !accountsWithMatchingPosition ||
      accountsWithMatchingPosition.length > 1
    ) {
      return;
    }

    return accountsWithMatchingPosition[0];
  }, [accountId, accountsWithMatchingPosition]);

  const hasPositions =
    accountsWithMatchingPosition && accountsWithMatchingPosition?.length > 0;

  if (signedIn && !canSell) {
    return null;
  }

  return (
    <>
      {hasPositions && (
        <>
          <AddSellOrderToBasketFullFlowDialog
            {...dialogProps}
            selectedAccountId={selectedAccount?.id!}
            selectedAccountType={selectedAccount?.wrapperType!}
            asset={asset}
            selectedPosition={selectedPosition}
          />
          <CustomButtonV2
            $color={$color}
            $size={size}
            $noHover={!hover}
            variant={variant}
            onClick={handleAddToBasket}
          >
            {label}
          </CustomButtonV2>
        </>
      )}
      {!hasPositions && (
        <CustomButtonV2
          $color={$color}
          $size={size}
          $noHover={!hover}
          variant={variant}
          disabled
        >
          {label}
        </CustomButtonV2>
      )}
    </>
  );
}
