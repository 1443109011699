import { css } from 'styled-components';
import searchIconWoff from './searchicon-webfont.woff';
import searchIconWoff2 from './searchicon-webfont.woff2';

/**
 * Custom font that replaces the + sign with a magnifying glass
 * hex code: &#43;
 */
export const SearchIconCustomFont = css`
  @font-face {
    font-family: 'searchIconRegular';
    src: url(${searchIconWoff}) format('woff2'),
      url(${searchIconWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;
