export const getProviderLogo = (providerId: string) => {
  const placeholderFilename = 'placeholder.png';
  const fileName = table[providerId as keyof typeof table];
  let logo;
  try {
    logo = require(`assets/atg-logos/${fileName}`).default;
  } catch {
    logo = require(`assets/atg-logos/${placeholderFilename}`).default;
  }
  return logo;
};

/** Lookup table which was created from the ATG providers sheet  */
const table = {
  '25611c9e-5e72-4881-ad6a-c03a7ac20375': '7IM.png',
  'a5ba5567-357e-4e35-b550-2cdf05ec1b60': 'Abrdn-Elevate.png',
  '89bd5ba7-07ed-4202-b692-d3dc12c48b40': 'Advance-by-Embark.png',
  'c8e7ff11-6a04-4abd-a58d-587bafa33aa4': 'Aegon-Investment-Solutions.png',
  '5338b4b3-578f-47d5-83bf-ef2f4b892622': 'AFH-Wealth-Management.png',
  '932e647f-2cda-4587-ae66-02fa159aaa79': 'AJ-Bell-Youinvest.png',
  '28bc0711-06ad-44c8-bdb6-d7c89ccb6ce9': 'Amber-Financial-Investments.png',
  'baa19249-8835-4317-8838-8a6f6e689a3c': 'Aviva-Investors.png',
  'bb6eae4d-1d5c-4d51-b3eb-ce7659bf3bbc': 'Aviva.png',
  '227dae14d-444d-447d-b7cb-203fc86fb2ea':
    'Barclays-Investment-Solutions-Ltd.png',
  '0a217e46-be6b-4027-979f-ba70ac5d7a03': 'Barclays-Smart-Investor.png',
  '01725c3a-3410-4679-857a-9c78614f09de': 'Boolers.png',
  '0c3d3789-4a1b-4c3a-aa08-4ffb0e302aae':
    'Brooks-Macdonald-Asset-Management.png',
  '10ffbec4-d1d6-4d4c-9856-d586f9e480f4': 'Charles-Stanley.png',
  'e02708a2-a8cc-4759-b329-281332fdcf53': 'Cofunds.png',
  '285374a8-225c-4a3a-83d3-e8c7334e9b17':
    'Cofunds-Ltd-(Aegon-Institutional).png',
  '46ec58ad-cc13-401c-ae77-5273a66870a0': 'Dodl.png',
  '479b5f3c-8d06-486f-9834-4eddd87b8366': 'Embark-Investment-Services.png',
  '6ebcb12d-5ded-449e-b08a-f9299c3f59aa': 'Equiniti.png',
  '3780e261-05b1-44d7-9a4f-e51e5405b213': 'Fidelity-International.png',
  '544a8046-de63-43a4-940d-4ee5e77981a7': 'FNZ.png',
  'e195cdc3-d05c-447f-bf6d-ab71a92ff8a5': 'Freetrade.png',
  'e9acaf1d-675d-4d84-a768-d72be501b67a': 'Fundement.png',
  'd2093312-24aa-477d-96f1-907080d5fbc6': 'FundRock.png',
  'b7550f8d-d78c-4d79-ad58-0a84d67a384a': 'Fundsmith.png',
  '09d09094-a4fc-49f2-b890-649fbaa46830': 'Fusion-Wealth.png',
  '8f256ef3-c2e4-4337-8a6d-537ae1a68cc7': 'Halifax-Share-Dealing.png',
  '99319f86-a859-4af6-a3bb-80c72b12528e': 'Hargreaves-Lansdown.png',
  'ecbe6cbc-a698-4c80-8cf5-2553b7e7239f': 'Hubwise.png',
  '2a5c3baf-ed9c-4377-9ce5-7d814d463869': 'Interactive-Investor.png',
  '88ff6b7f-db05-4462-be56-223a78abc57e': 'Invesco.png',
  'dac2b7e7-24ae-4962-91b1-e083273c1fc5': 'Investment-Funds-Direct.png',
  'f8949521-7f73-4568-9feb-1d51011d6b9b': 'James-Hay.png',
  'cac1e22c-9b5e-47dd-8c10-0219e7a3ac01': 'Janus-Henderson-Investors.png',
  '0d0d2133-d86e-46cb-9b41-2b323504f610': 'Jupiter.png',
  '9dc02d4d-b6e6-4af5-a54a-799469a32875': 'M&G.png',
  '81c3deb5-a27f-4ec3-bc81-7f5f1df97890': 'MyM&G.png',
  '7ae57658-3a86-4554-96a1-0e1dbb13b2d7': 'Novia.png',
  '31ea8dd4-ae95-4bdf-ac7f-41f199011074': 'Nucleus.png',
  'ce7a3d01-1238-4ca0-b0c7-1bac0fca1044': 'Nutmeg.png',
  '63ab3e50-a84a-4bb6-9510-a10ccfbbb9d1': 'Parmenion.png',
  'd6641eab-3774-4ab3-9913-d73a5af016f3':
    'Pershing-Ltd-Boulterbowen-Wealth.png',
  'd871e56f-8cfa-4280-a8f7-0f52a696afc0': 'Pershing-Ltd-Citibank.png',
  '6b802160-9ca0-4593-9051-008580ca7b5c': 'Pershing-Ltd-Credo-Capital.png',
  '737f5907-40d3-48a3-a8ad-a10a5e61dc05': 'Pershing-Ltd-Epoch-WM.png',
  'a6660d50-80ee-40f1-9e78-f5717e2d2959': 'Pershing-Ltd-EQ-Investors.png',
  '1a989cb4-6279-4a0f-b09d-b342fc4edf3a': 'Pershing-Ltd-Fairstone-PW.png',
  'cf2099ff-61c5-406e-915e-6915856c5871': 'Pershing-Ltd-Gore-Brown.png',
  'd125c689-735e-4d70-acef-7fd463da176c': 'Pershing-Ltd-HFM-Columbus-Group.png',
  'a6ca9a42-e136-4e07-99a4-fda8e81f6626': 'Pershing-Ltd-Holden-&-Partners.png',
  '3ecca45f-7379-4989-b59f-6ba1b25ef0cb': 'Pershing-Ltd-Mattioli-Woods.png',
  'dfbfd17a-8bd8-41c5-a992-fbc89e3b59f7': 'Pershing-Ltd-Odey-WM.png',
  'a8774774-cebd-4e29-944d-596e9d17e915': 'Pershing-Ltd-Phillip-Cap-UK-Ltd.png',
  '0993915b-b471-4d39-8dc3-b0f5362e0a3b': 'Pershing-Ltd-Shore-Capital.png',
  'c1d62cc7-476b-4401-b913-8ef38f8a833d': 'Pershing-Ltd-St-Johns-AM.png',
  '385ecf61-c29b-4116-995d-872546ce6b46': 'Pershing-Ltd-Tilney-AM-(IG).png',
  '5e16b7b9-d4a8-4058-9a21-4c71b641360f': 'Pershing-Ltd-Tilney-AM-(IG).png',
  '340e5548-c397-4db9-834a-f15342bee4cf': 'Pershing-Ltd-Tilney-AM-(IG).png',
  '81eb7475-da42-41ae-a251-9b681fbcda3f': "Pershing-Ltd-Troy-AM.png'",
  'aff164b4-82a1-428b-a4e0-54862dec750a': 'Pershing-Ltd-VWM-Wealth.png',
  '0c1c1256-6fce-473c-a874-4b543917b0e0': 'Pershing-Ltd-Wise-Investments.png',
  '5bfbd699-5bd8-48b9-bd17-d544c62b461e': 'Platform-Sec-Bowmore.png',
  'cc5434b3-f3d5-4c7e-badb-e1dcbd32a315': 'Platform-Sec-Albert-E-Sharp.png',
  '2239f4d2-2a81-41f5-9842-0747b1530a3c': 'Platform-Sec-Hedley-&-Company.png',
  '8dce2de9-9099-4330-9696-65eaa16c6c47': 'Platform-Sec-Henderson-Rowe.png',
  'bcdeee28-1cb6-4b07-9b59-57a8cf1e4e17': 'Platform-Sec-Hurley-Partners.png',
  'dcb6575c-2950-43d8-aa0c-d2a8dc3f8c40': 'Platform-Sec-Irish-Life.png',
  'fdc75ef5-693e-456d-8b21-023d5aecf899': 'Platform-Sec-Julius-Baer.png',
  'dad4bb7b-703f-4b39-85c6-cca7cbbd4274': 'Platform-Sec-Killik.png',
  '5afcb677-7131-40ed-bf75-bebf919b7eae': 'Platform-Sec-Rossie-House.png',
  '7654e4ac-a079-4a76-9c38-9a77a2c423c7': 'Platform-Sec-Saunderson-House.png',
  'c6bf0779-8d8d-4a22-b413-00ce96dff829': "Quilter's-Platform.png",
  '6a628734-39bf-42f2-a5ab-31b1db03c99f': 'Rathbones.png',
  '3bf7fdd8-f4f4-43ea-aa16-2e72035a96f4': 'Raymond-James.png',
  '87a563a0-3e1f-4659-8180-37947e41020b': 'Pershing-Ltd-Tilney-AM-(IG).png',
  '9491a61c-60c9-4d60-be46-9d26323a391b': 'Standard-Life.png',
  'b197a808-52c4-4a01-8412-21b8ae4cefc9': 'Telford-Mann.png',
  'c7dcd87c-66a9-4920-ae43-393bc47e91f8': 'Transact.png',
  '6d2af08c-2f10-4e87-991d-56b06b1b0ffa': 'True-Potential.png',
  'c1aec827-dd2f-4e30-8039-cdbc45c5053a': 'Vanguard.png',
  'ac4589c0-cf17-4bc6-aa4e-72b04dfc6bec': 'WH-Ireland-Limited.png',
  'b79a89e4-0836-48b6-9934-aedfce975400': 'Wealthify.png',
  'c73f5044-a303-432f-8ee5-b3c61775e1b6': 'Wealthsimple-UK-Ltd.png',
  'a84cbab7-eccd-4817-870a-234f43c210b4': 'Wealthtime-Limited.png',
  '31e40f36-4e26-474a-bbb7-3e0d1a9913ec':
    'Winterflood-Charities-Aid-Foundation.png',
  '5e7b75b9-2678-408f-9924-2ad98bb8816d': 'Winterflood-Finhub.png',
  'f49c541f-f4cd-4961-9c10-c677e909185d': 'Winterflood-Progeny.png',
  '61d910ac-7e1c-4de7-88b3-b70bb2bdaa4b': 'Winterflood-Business-Services.png',
  'f6784a61-17bc-44fe-9e60-5d474f276d3a': 'Wombat.png',
};
