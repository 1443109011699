import { makeStyles } from '@material-ui/core';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  useOnboardingStatusQuery,
  useStartKycCheckMutation,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React from 'react';
import { useQueryClient } from 'react-query';
import { ServerError } from '../_shared/ServerError';

import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const useStyles = makeStyles({
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

export function ConfirmationStep({ onProceed, onGoBack }: StepProps) {
  const classes = useStyles();
  const { mutateAsync, isError } = useStartKycCheckMutation();
  const queryClient = useQueryClient();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await mutateAsync(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries(useOnboardingStatusQuery.getKey());
        },
      }
    );

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.verificationStarted,
    });

    onProceed();
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <StepContainer>
      <form className={classes.form} onSubmit={onSubmit}>
        <StepContent>
          <StepTitle>Start identity verification</StepTitle>

          <ServerError isVisible={isError} />

          <StepText>
            <StepIntroductionTypography>
              Now that we’ve got your details, we are ready to verify your
              identity. We will carry out a soft credit record check, but this
              will <strong>not</strong> affect your credit score.{' '}
            </StepIntroductionTypography>
          </StepText>
        </StepContent>
        <StepActions>
          <StepButton type="submit" className="magenta">
            Proceed
          </StepButton>
          <GoBackButton onClick={handleBack} />
        </StepActions>
      </form>
    </StepContainer>
  );
}
