import { useAuth } from 'context/AuthContext';
import { useUserProfileQuery } from 'generated/graphql';

export function useIsNinoRequired() {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const nationalities = [
    userProfileQuery.data?.userProfile?.nationality,
    ...(userProfileQuery.data?.userProfile?.otherNationalities || []),
  ];
  const isUserBritish = nationalities.includes('GB');

  return (
    isUserBritish &&
    !userProfileQuery.data?.userProfile?.nationalInsuranceNumberTokenised
      ?.maskedValue
  );
}
