import { Typography } from '@material-ui/core';
import cardBackground from 'assets/backgrounds/Tillit_trust_background.jpg';
import { colors } from 'constants/colors';
import { TransferStatus } from 'generated/graphql';
import styled from 'styled-components';

export const CompletedTransferCardWrapper = styled.div`
  display: grid;
  grid-template:
    'type status'
    'details details'
    'completed completed'
    / 1fr 1fr;
  row-gap: 10px;
  background-image: url(${cardBackground});
  background-color: ${colors.richBlue};
  color: white;
  align-items: center;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1.25, 1.25, 3, 1.25)};
  min-height: 190px;
  min-width: 300px;
  box-sizing: border-box;
`;

export const AccountTypePill = styled.div`
  background: white;
  grid-area: type;
  color: ${colors.black};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  justify-self: start;
  font-size: 12px;
`;

interface StatusPillProps {
  status: TransferStatus;
}

export const StatusPill = styled.div<StatusPillProps>`
  grid-area: status;
  background: ${({ status }) =>
    status === TransferStatus.Completed ? colors.magenta : colors.black};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  justify-self: end;
  font-size: 12px;
`;

interface DetailsProps {
  transferHasValue: boolean;
}
export const Details = styled.div<DetailsProps>`
  display: grid;
  grid-template-columns: ${(p) => (p.transferHasValue ? '1fr 1px 1fr' : '1fr')};
  grid-area: details;
  align-items: center;
  justify-items: center;
`;

interface ProviderLogoProp {
  logoUrl: string;
}
export const ProviderLogo = styled.div<ProviderLogoProp>`
  width: 90%;
  max-width: 150px;
  height: 45px;
  background: url(${(p) => p.logoUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-position: center;
  }
  filter: brightness(0) invert(1);
`;

export const Divider = styled.div`
  height: 50px;
  width: 0;
  border-right: 1px solid #fff;
`;

export const Value = styled(Typography)`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
`;
export const Note = styled(Typography)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 0.75rem;
  line-height: 16px;
`;

export const TransferFrom = styled(Typography)`
  color: #fff;
  font-size: 1rem;
`;

export const VerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  grid-area: completed;
`;

export const CompleteOn = styled(Typography)`
  color: #fff;
  font-size: 0.75rem;
  text-decoration: none;
  font-weight: 300;
  strong {
    font-weight: 500;
  }
`;
