import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export interface DarkUniverseProps {
  $isDarkUniverse: boolean;
}

export const StyledCard = styled(Card)<DarkUniverseProps>`
  background-color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.black : colors.white};
`;

export const BasketItemCardInner = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr 4rem 1rem;
  grid-template-areas: 'icon name name remove' 'icon description amount amount' '. details details details';
  grid-gap: 0 1rem;
  align-items: center;
  > img {
    grid-area: icon;
    align-self: center;
    width: 100%;
  }
`;

export const BasketItemHeading = styled(H6)`
  grid-area: name;
`;

export const BasketItemDescription = styled.div`
  grid-area: description;
`;

export const BasketItemAmount = styled.div`
  grid-area: amount;
`;

export interface RemoveButtonProps {
  $isActive?: boolean;
  $isDarkUniverse?: boolean;
}

export const RemoveButton = styled.button<RemoveButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  align-self: start;
  justify-self: end;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  transition: opacity 0.25s ease-in-out;
  color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.white : colors.black};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 10px;
  }
`;

export const CheckoutDetails = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${colors.midGrey};
  padding-top: 0.5rem;
  margin-top: 1rem;
`;

interface CheckoutDetailsInnerProps {
  isActive: boolean;
}
export const CheckoutDetailsInner = styled.div<CheckoutDetailsInnerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: ${({ isActive }) => (isActive ? '20rem' : '0')};
  transition: max-height 0.3s ease-in-out;
`;

export const CheckoutDetailsControls = styled.button`
  margin-top: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;
