import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroductionTypography,
  StepText,
} from 'components/design-system/StepComponents/StepComponents';

import React from 'react';

export function TrueLayerTermsOfService() {
  return (
    <StepText>
      <StepIntroductionTypography>
        By continuing you are permitting TrueLayer to initiate a payment from
        your bank account. You also agree to TrueLayer’s{' '}
        <StyledLink href="https://truelayer.com/enduser_tos" target="_blank">
          end-user terms
        </StyledLink>{' '}
        and{' '}
        <StyledLink href="https://truelayer.com/privacy" target="_blank">
          privacy policy
        </StyledLink>
        . The payee name will be Seccl Custody, our custodian.
      </StepIntroductionTypography>
    </StepText>
  );
}
