import {
  TransferStatus,
  useTransfersQuery,
  WrapperType,
} from 'generated/graphql';
import { useMemo } from 'react';

export function useCanTransfer() {
  const transferQuery = useTransfersQuery();
  let canTransferObj = {
    gia: false,
    isa: false,
  };
  const transfers = transferQuery.data?.transfers;
  const GIAActiveTransfers = useMemo(() => {
    return transfers?.filter(
      (t) =>
        t.wrapperType === WrapperType.Gia &&
        (t.status === TransferStatus.Created ||
          t.status === TransferStatus.InProgress ||
          t.status === TransferStatus.Problem)
    );
  }, [transfers]);

  const ISAActiveTransfers = useMemo(() => {
    return transfers?.filter(
      (t) =>
        t.wrapperType === WrapperType.Isa &&
        (t.status === TransferStatus.Created ||
          t.status === TransferStatus.InProgress ||
          t.status === TransferStatus.Problem)
    );
  }, [transfers]);

  if (GIAActiveTransfers && GIAActiveTransfers?.length < 5)
    canTransferObj.gia = true;
  if (ISAActiveTransfers && ISAActiveTransfers?.length < 5)
    canTransferObj.isa = true;

  return canTransferObj;
}
