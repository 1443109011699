import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { TransferMethod, WrapperType } from 'generated/graphql';
import { CedingProvider } from '../../types';
import { AboutCashTransfer } from './AboutCashTransfer';
import { AboutInspecieTransfer } from './AboutInspecieTransfer';

export type onProceedCallback = () => void;

export interface AboutTransferMethodProps {
  onProceed: onProceedCallback;
  onGoBack: () => void;
  transferMethod: TransferMethod;
  cedingProvider: CedingProvider;
  wrapperType: WrapperType;
}

export function AboutTransferMethod({
  wrapperType,
  onProceed,
  transferMethod,
  cedingProvider,
  onGoBack,
}: AboutTransferMethodProps) {
  return (
    <StepContainer>
      <StepContent width={StepContentWidth.wide}>
        {transferMethod === TransferMethod.Cash && (
          <AboutCashTransfer
            wrapperType={wrapperType}
            cedingProviderName={cedingProvider.name}
          />
        )}
        {transferMethod === TransferMethod.InSpecie && (
          <AboutInspecieTransfer
            wrapperType={wrapperType}
            cedingProviderName={cedingProvider.name}
          />
        )}
      </StepContent>
      <StepActions>
        <StepButton
          className="magenta"
          onClick={() => {
            onProceed();
          }}
        >
          Continue
        </StepButton>
        <GoBackButton onClick={onGoBack} />
      </StepActions>
    </StepContainer>
  );
}
