import { QueryState } from 'components/QueryState';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontWeight,
  TextLarge,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { currencyFull, day } from 'formatting';
import {
  WrapperType,
  useDepositDetailsByAccountQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import {
  generateAutoSaveInvestSubPath,
  generateDashboardAccountPath,
} from 'paths';
import { BiChevronRight } from 'react-icons/bi';
import * as tooltips from 'strings/tooltips';
import { LocalRecurringDeposits } from '../../AutoSaveInvestContext';
import type { ConfirmStepSuccessProps } from '../../confirm/steps/ConfirmStep';
import { deriveSuccessMsg } from '../../helpers/successMsg';
import {
  IntroCopy,
  ObscuredDetails,
  StandingOrderAmount,
  StandingOrderDay,
  StandingOrderDayAmount,
  StandingOrderDetailRow,
  StandingOrderDetailRowCopyToClipboard,
  StandingOrderDetailRowLabel,
  StandingOrderDetailRowValue,
  StandingOrderDetails,
  StandingOrderDetailsWrapper,
  StandingOrderFrom,
  YourAccountDetailsCard,
  YourAccountDetailsWrapper,
} from './InstructionsStep.styles';
import { useState } from 'react';
import { Alert, Severity } from 'components/design-system/Alert/Alert';

export interface InstructionsStepProps {
  accountId: string;
  deposits: LocalRecurringDeposits[];
  wrapperType: WrapperType;
  confirmSuccessMeta?: ConfirmStepSuccessProps;
  isDialog?: boolean;
}

export function InstructionsStep({
  accountId,
  deposits,
  wrapperType,
  confirmSuccessMeta,
  isDialog = false,
}: InstructionsStepProps) {
  const [anyIsNewOrEdited, setAnyIsNewOrEdited] = useState<boolean>(false);
  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery({
    id: accountId!,
  });
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;
  const transferDetails = depositDetailsByAccount?.transferDetails;

  return (
    <QueryState {...depositDetailsByAccountQuery}>
      {() => {
        if (!transferDetails) {
          return null;
        }
        const {
          payeeName,
          payeeAccountNumber,
          payeeSortCode,
          paymentReference,
          sourceAccountNumberMasked,
          sourceSortCodeMasked,
        } = transferDetails;
        return (
          <StepContainer>
            <StepTitle>
              {isDialog
                ? 'Your standing order details'
                : 'Create your standing order'}
            </StepTitle>
            <StepIntroduction $width={StepIntroductionWidth.extraWide}>
              {confirmSuccessMeta && (
                <StepIntroductionTypography>
                  {deriveSuccessMsg(confirmSuccessMeta)}
                </StepIntroductionTypography>
              )}
              {!isDialog && (
                <IntroCopy>
                  <span>Please note:</span> your regular deposit will not be
                  processed until you set up a standing order with your bank
                  using the details below:
                </IntroCopy>
              )}

              {isDialog && anyIsNewOrEdited && (
                <Alert severity={Severity.info}>
                  <span>
                    You have unsaved changes, you will have to confirm these
                    changes for the regular deposits to be saved.
                  </span>
                </Alert>
              )}

              <StandingOrderDetails>
                <StandingOrderFrom>
                  <TextLarge $fontWeight={FontWeight.medium} $noMargin>
                    From account
                  </TextLarge>
                  <ObscuredDetails>{sourceAccountNumberMasked}</ObscuredDetails>
                </StandingOrderFrom>

                {deposits.length >= 1 &&
                  deposits.map((deposit, index) => {
                    if (deposit.isNew || deposit.isEdited) {
                      setAnyIsNewOrEdited(true);
                    }
                    return (
                      <StandingOrderDayAmount key={index}>
                        <StandingOrderDay>
                          {day(deposit.paymentDate)}
                        </StandingOrderDay>{' '}
                        <TextNormal $noMargin>
                          of every month {deposit.isNew && `(new)`}
                          {deposit.isEdited && !deposit.isNew && `(edited)`}
                        </TextNormal>
                        <StandingOrderAmount>
                          <TextLarge $fontWeight={FontWeight.medium} $noMargin>
                            {currencyFull(deposit.amount)} <BiChevronRight />
                          </TextLarge>
                        </StandingOrderAmount>
                      </StandingOrderDayAmount>
                    );
                  })}

                <StandingOrderDetailsWrapper>
                  <StandingOrderDetailRow>
                    <StandingOrderDetailRowLabel>
                      Payee name{' '}
                      <InfoPopoverV2
                        size="small"
                        content={tooltips.PayeeName}
                      />
                    </StandingOrderDetailRowLabel>
                    <StandingOrderDetailRowCopyToClipboard
                      text={payeeName}
                      size="medium"
                    />
                    <StandingOrderDetailRowValue>
                      {payeeName}
                    </StandingOrderDetailRowValue>
                  </StandingOrderDetailRow>

                  <StandingOrderDetailRow>
                    <StandingOrderDetailRowLabel>
                      Account number
                    </StandingOrderDetailRowLabel>
                    <StandingOrderDetailRowCopyToClipboard
                      text={payeeAccountNumber}
                      size="medium"
                    />
                    <StandingOrderDetailRowValue>
                      {payeeAccountNumber}
                    </StandingOrderDetailRowValue>
                  </StandingOrderDetailRow>

                  <StandingOrderDetailRow>
                    <StandingOrderDetailRowLabel>
                      Sort code
                    </StandingOrderDetailRowLabel>
                    <StandingOrderDetailRowCopyToClipboard
                      text={payeeSortCode}
                      size="medium"
                    />
                    <StandingOrderDetailRowValue>
                      {payeeSortCode}
                    </StandingOrderDetailRowValue>
                  </StandingOrderDetailRow>

                  <StandingOrderDetailRow>
                    <StandingOrderDetailRowLabel>
                      Reference
                    </StandingOrderDetailRowLabel>
                    <StandingOrderDetailRowCopyToClipboard
                      text={paymentReference}
                      size="medium"
                    />
                    <StandingOrderDetailRowValue>
                      {paymentReference}
                    </StandingOrderDetailRowValue>
                  </StandingOrderDetailRow>
                </StandingOrderDetailsWrapper>
              </StandingOrderDetails>

              <StepIntroductionTypography>
                Please note: that the standing order must be set up from the
                bank account tied to your TILLIT account.
              </StepIntroductionTypography>

              <YourAccountDetailsWrapper>
                <YourAccountDetailsCard $paddingSize="extraSmall">
                  <ObscuredDetails>{sourceAccountNumberMasked}</ObscuredDetails>
                  <TextSmall $noMargin>Account number</TextSmall>
                </YourAccountDetailsCard>
                <YourAccountDetailsCard $paddingSize="extraSmall">
                  <ObscuredDetails>{sourceSortCodeMasked}</ObscuredDetails>
                  <TextSmall $noMargin>Sort code</TextSmall>
                </YourAccountDetailsCard>
              </YourAccountDetailsWrapper>
              {!isDialog && (
                <StepIntroductionTypography>
                  You can find these details on your Dashboard at any time.
                </StepIntroductionTypography>
              )}
            </StepIntroduction>

            {!isDialog && (
              <StepActions $isHorizontal>
                <LinkStepButton
                  className="richBlue"
                  to={generateDashboardAccountPath({
                    wrapperType: getPathSegmentForWrapperType(wrapperType),
                  })}
                >
                  Dashboard
                </LinkStepButton>
              </StepActions>
            )}

            {isDialog && anyIsNewOrEdited && (
              <StepActions $isHorizontal>
                <LinkStepButton
                  className="magenta"
                  to={generateAutoSaveInvestSubPath({
                    wrapperType: getPathSegmentForWrapperType(wrapperType),
                    action: 'confirm',
                  })}
                >
                  Confirm changes
                </LinkStepButton>
              </StepActions>
            )}
          </StepContainer>
        );
      }}
    </QueryState>
  );
}
