import { AssetSearchSortableField, SortOrder } from 'generated/graphql';
import _ from 'lodash';
import { fundListPath } from 'paths';
import { parse, stringify } from 'querystring';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface FilterVariables {
  tags?: Array<string>;
  search?: string;
  sort?: {
    field?: AssetSearchSortableField;
    order?: SortOrder;
  };
}

interface UseAssetsFilterResult {
  update: (vars: FilterVariables) => void;
  variables: FilterVariables;
}

export function useAssetsFilter(): UseAssetsFilterResult {
  const location = useLocation();
  const history = useHistory();

  const pathname = location.pathname === '/' ? fundListPath : location.pathname;

  const queryString = parse(location.search.replace(/^\?/, ''));

  const search = queryString['search'] as string;
  const sort = (queryString['sort'] as string)?.split(',');
  const tags = (queryString['tags'] as string)?.split(',');

  const update = useCallback(
    ({ tags, search, sort }: FilterVariables) => {
      const queryString = _.omitBy(
        {
          search,
          tags: tags?.join(','),
          sort:
            sort?.field !== AssetSearchSortableField.Name ||
            sort?.order !== SortOrder.Asc
              ? `${sort?.field},${sort?.order}`
              : undefined,
        },
        _.isEmpty
      );

      history.push(`${pathname}?${stringify(queryString)}`);
    },
    [history, pathname]
  );

  return {
    update: update,
    variables: {
      tags,
      search,
      sort: (sort && {
        field: sort[0] as AssetSearchSortableField,
        order: sort[1] as SortOrder,
      }) ?? {
        field: AssetSearchSortableField.Name,
        order: SortOrder.Asc,
      },
    },
  };
}
