import { Container } from 'components/Container/Container.style';
import React from 'react';
import { HeadingWrapper, Logo, Title } from './MyDetails.style';

export interface BaseProps {
  title: string;
  logoUrl?: string;
  logoAlt?: string;
  children: React.ReactNode;
}

export function Base({ title, logoUrl, logoAlt, children }: BaseProps) {
  return (
    <Container padding="medium" square={false}>
      <HeadingWrapper>
        <Title variant="h3">{title}</Title>{' '}
        {logoUrl && <Logo src={logoUrl} alt={logoAlt} />}
      </HeadingWrapper>
      {children}
    </Container>
  );
}
