import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { StyledButton } from './ArrowButton.styles';

export interface ArrowButtonProps
  extends Pick<
    ButtonProps,
    'children' | 'onClick' | 'disabled' | 'type' | 'name' | 'value'
  > {}

export const ArrowButton = React.forwardRef<
  HTMLButtonElement,
  ArrowButtonProps
>((props, ref) => {
  return (
    <StyledButton
      {...props}
      endIcon={<HiArrowNarrowRight />}
      disableRipple
      ref={ref}
    />
  );
});
