import { FormControlLabel } from '@material-ui/core';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { colors } from 'constants/colors';
import React from 'react';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  label?: string;
}

export const CustomSwitch = withStyles(() =>
  createStyles({
    root: {
      width: '2rem',
      height: 18,
      padding: 0,
      margin: '5px',
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(14px)',
        color: colors.white,
        '& + $track': {
          backgroundColor: colors.magenta,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: colors.seaBlue,
        border: '4px solid #fff',
      },
    },
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 18 / 2,
      border: 'none',
      backgroundColor: colors['magenta-100'],
      transition: 'background-color 0.3s',
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, label, ...props }: Props) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      }
    />
  );
});
