import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { css } from 'styled-components';

export const smallCopy = css`
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;
`;

export const heroBaseStyle = css`
  position: relative;
  background-color: ${colors.richBlue};
  border-bottom: 4px ${colors.magenta} solid;
  padding: ${(p) => p.theme.spacing(2.5, 3.75)};
  background-position: center;
  background-size: cover;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(5)}px;
  }
`;

export const label = css`
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 300;
`;

export const tableStyle = css`
  thead {
    th {
      font-size: 0.75rem;
      font-weight: 300;
      text-transform: uppercase;
      padding: ${(p) => p.theme.spacing(1.25, 0.65)};
    }
  }
  tbody {
    td {
      padding: ${(p) => p.theme.spacing(1.25, 0.65)};
    }
    tr:nth-child(2n-1) {
      background-color: ${(p) => transparentize(0.95, colors.magenta)};
    }
  }
`;
