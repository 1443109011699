import { useLocation } from 'react-router';

export function useDeriveRedirectPath() {
  const location = useLocation();
  const query = location.search;
  const hash = location.hash;

  const createUrl = (path: string) => `${path}${query}${hash}`;

  const currentPath = location.pathname;
  if (currentPath === '/signed-out') {
    return createUrl('/');
  }
  return createUrl(currentPath);
}
