import { useLocalStorage } from 'usehooks-ts';

interface RecentlyViewedAssets {
  recentlyViewed: string[];
  updateRecentlyViewed: (assetId: string) => void;
}

export const useRecentlyViewedAssets = (): RecentlyViewedAssets => {
  const [recentlyViewedLs, setRecentlyViewedLs] = useLocalStorage<string[]>(
    'recentlyViewedAssets',
    []
  );

  const updateRecentlyViewed = (assetId: string): void => {
    const index = recentlyViewedLs.indexOf(assetId);
    if (index === -1 || recentlyViewedLs.length === 0) {
      // If assetId is not in the array
      setRecentlyViewedLs([assetId, ...recentlyViewedLs.slice(0, 9)]);
    } else if (index > 0) {
      // If assetId is in the array but not at the start, move it to the start
      const updatedRecentlyViewed = [
        assetId,
        ...recentlyViewedLs.slice(0, index),
        ...recentlyViewedLs.slice(index + 1, 9),
      ];
      setRecentlyViewedLs(updatedRecentlyViewed);
    }
  };

  return { recentlyViewed: recentlyViewedLs, updateRecentlyViewed };
};
