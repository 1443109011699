import { useAuth } from 'context/AuthContext';
import { useGetReturnPath } from 'hooks/useGetReturnPath';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import { PostSignInForm } from './PostSignInForm';
import { PreSignInForm } from './PreSignInForm';
import {
  EmailPasswordFormValues,
  LoginApiResBody,
} from './steps/EmailPasswordStep/EmailPasswordStep';

enum MultistepFormSteps {
  PreSignIn = 'PreSignIn',
  PostSignIn = 'PostSignIn',
}

export function SignInForm() {
  const history = useHistory();
  const { signedIn } = useAuth();
  const defaultReturnPath = useGetReturnPath();

  useEffectOnce(() => {
    if (signedIn) {
      history.replace(defaultReturnPath);
    }
  });

  const [activeStep, setActiveStep] = useState<MultistepFormSteps | undefined>(
    MultistepFormSteps.PreSignIn
  );

  const [
    emailPasswordFormValuesState,
    setEmailPasswordFormValuesState,
  ] = useState<EmailPasswordFormValues | undefined>();
  const [loginResultState, setLoginResultState] = useState<
    LoginApiResBody | undefined
  >();

  return (
    <Wrapper>
      {activeStep === MultistepFormSteps.PreSignIn && (
        <PreSignInForm
          onProceed={(emailPasswordFormValues, loginResult) => {
            setEmailPasswordFormValuesState(emailPasswordFormValues);
            setLoginResultState(loginResult);
            setActiveStep(MultistepFormSteps.PostSignIn);
          }}
        />
      )}

      {activeStep === MultistepFormSteps.PostSignIn &&
        loginResultState &&
        emailPasswordFormValuesState && (
          <PostSignInForm loginResult={loginResultState} />
        )}
    </Wrapper>
  );
}
