import { ReactComponent as WildcardSVG } from 'assets/icons/Wildcard.svg';
import {
  H2,
  H4,
  TextAlign as HTextAlign,
} from 'components/design-system/Heading/Heading';
import { FontStyle, Text, TextAlign } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';

const RiskWarningOuter = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.mauve};
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid ${colors.mauve};
  box-shadow: 10px 15px 30px rgba(166, 171, 189, 0.25);
  grid-area: risk;
  margin: 2.5rem 0;
  color: white;
`;

const TitleContainer = styled.div`
  display: inline-flex;
  align-self: center;
  gap: 10px;
  margin-bottom: 12px;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 28px;
  width: 28px;
`;

const BodyCopy = styled(Text)`
  color: white;
`;

const RiskWarningCopy = styled(Text)`
  color: white;
  font-weight: 500;
`;

interface RiskWarningProps {
  keyRisksContent: string;
}

/**
 * Parse the HTML passed in and returns a concatenated string containing the
 * contents of all but the last <p> tag
 *
 * @returns
 */
function parseContent(htmlString: string) {
  const parser = new DOMParser();
  const parsedContent = parser.parseFromString(htmlString, 'text/html');

  if (!parsedContent) {
    return null;
  }

  // remove generic risk text if present
  const genericWarningPresent =
    parsedContent.body.lastChild?.textContent?.includes(
      'Your capital is at risk if you invest'
    ) ?? false;
  if (genericWarningPresent) {
    parsedContent.body.removeChild(parsedContent.body.lastChild!);
  }

  // convert to array
  const elArray = Array.from(parsedContent.body.getElementsByTagName('p'));

  // loop over and return concatenated string (lowercase first letter of first innerText)
  let text = '';

  if (Array.isArray(elArray)) {
    // if no specific risks then return empty string
    if (
      elArray[0].innerText ===
      'There are no specific key risks associated with this fund.'
    ) {
      return text;
    }

    // loop over elements and concatenate risks string
    elArray.forEach((el) => {
      text += el.innerText + ' ';
    });
  }

  return text;
}

export function RiskWarning({ keyRisksContent }: RiskWarningProps) {
  const keyRisksString = parseContent(keyRisksContent);

  return (
    <RiskWarningOuter className="dark-background">
      <TitleContainer>
        <IconContainer>
          <WildcardSVG />
        </IconContainer>
        <H2 $noMargin style={{ color: colors.white }}>
          Wildcard
        </H2>
      </TitleContainer>

      <BodyCopy
        $textAlign={TextAlign.center}
        $noMargin={!keyRisksString?.length}
      >
        This is a Wildcard. Wildcards is a collection of niche, esoteric funds
        with a higher-risk profile compared to many other funds on the platform.
        They offer exposure to an area of the market or investment style that is
        a bit off the beaten track. These funds typically carry a higher level
        of risk and should be considered in the context of a diversified
        portfolio.{' '}
      </BodyCopy>
      {!!keyRisksString?.length && (
        <>
          <H4 style={{ color: colors.white }} $textAlign={HTextAlign.center}>
            Specific risks associated with this Wildcard
          </H4>
          <RiskWarningCopy
            $fontStyle={FontStyle.italic}
            $textAlign={TextAlign.center}
            $noMargin
          >
            {keyRisksString}
          </RiskWarningCopy>
        </>
      )}
    </RiskWarningOuter>
  );
}
