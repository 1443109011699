import CardBackground from 'assets/card_background.png';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { ClosingAction } from './AccountActions';
import { AccountData, CardTitle } from './AccountCard';
import {
  ContentContainer,
  Label,
  StyledButtonsWrapper,
  Value,
  ValueContainer,
  CardDescription,
} from './AccountCard.style';
import { ClosingAccountContent } from './AccountContent';

export interface PureClosingAccountCardProps {
  accountType: WrapperType;
  accountData?: AccountData;
  onWithdraw: () => void;
  onSupport: () => void;
}

export function PureClosingAccountCard({
  accountType,
  accountData,
  onWithdraw,
  onSupport,
}: PureClosingAccountCardProps) {
  return (
    <>
      <CardTitle accountType={accountType} />
      {accountData && (
        <>
          <ContentContainer vAlign="center" imgUrl={CardBackground}>
            <ValueContainer>
              <Value size="small">
                {format.currencyFull(accountData?.uninvestedCash ?? 0)}
              </Value>
              <Label size="small">Available cash</Label>
            </ValueContainer>
            <CardDescription>
              <ClosingAccountContent />
            </CardDescription>
          </ContentContainer>
          <StyledButtonsWrapper>
            <ClosingAction
              value={accountData?.uninvestedCash ?? 0}
              accountType={accountType}
              onSupport={onSupport}
              onWithdrawCash={onWithdraw}
            />
          </StyledButtonsWrapper>
        </>
      )}
    </>
  );
}
