import { FormHelperText } from '@material-ui/core';
import type { FieldError, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from './Radio';

export type NullableRadioFormState = 'true' | 'false' | undefined;

export interface YesNoRadiosProps {
  name: string;
  label: React.ReactNode;
  error?: FieldError;
  defaultValue?: NullableRadioFormState;
  register: UseFormMethods['register'];
}

const YesNoRadiosLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  width: 100%;
  align-items: center;
`;

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export function YesNoRadios({
  name,
  label,
  register,
  defaultValue,
  error,
}: YesNoRadiosProps) {
  return (
    <YesNoRadiosLayout data-testid={`${name}-radio-group`}>
      <div id={`${name}-group-label`}>{label}</div>

      <StyledRadioGroup>
        <Radio
          label="Yes"
          name={name}
          value="true"
          ref={register}
          defaultChecked={defaultValue === 'true'}
        />
        <Radio
          label="No"
          name={name}
          value="false"
          ref={register}
          defaultChecked={defaultValue === 'false'}
        />
      </StyledRadioGroup>

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </YesNoRadiosLayout>
  );
}
