import { useAuth } from 'context/AuthContext';
import {
  RecurringDeposits,
  RecurringOrders,
  WrapperType,
  useRecurringTransactionsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getWrapperTypeForPathSegment } from 'helpers/accountHelpers';
import pickBy from 'lodash/pickBy';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
} from 'paths';
import React, { createContext, useContext, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useMode } from '../mode/useMode';

export interface LocalRecurringDeposits extends RecurringDeposits {
  isNew: boolean;
  isEdited: boolean;
  isDeleted: boolean;
}

export interface LocalRecurringOrder extends Omit<RecurringOrders, 'name'> {
  isNew: boolean;
  isEdited: boolean;
  isDeleted: boolean;
}

export interface AutoSaveInvestAccountState {
  deposits?: LocalRecurringDeposits[] | null;
  orders?: LocalRecurringOrder[] | null;
}

export type AutoSaveInvestStateContextState = {
  [key: string]: AutoSaveInvestAccountState | null;
};

export interface AutoSaveInvestBasketSummary {
  accountId: string;
  wrapperType: WrapperType;
  depositsTotal: number | undefined;
  orders: number | undefined;
}

export interface AutoSaveInvestStateContext {
  setState: (state: AutoSaveInvestAccountState, accountId: string) => void;
  getState: (accountId?: string) => AutoSaveInvestAccountState | null;
  clearState: (accountId: string) => void;
  fullState: AutoSaveInvestStateContextState | null;
}

const selectFundsPercentagesStepContextContext = createContext<AutoSaveInvestStateContext>(
  undefined!
);

interface AuthProviderProps {
  children: React.ReactNode;
}

function useAutoSaveInvestStateContextProvider(): AutoSaveInvestStateContext {
  const [
    sessionState,
    setSessionState,
  ] = useLocalStorage<AutoSaveInvestStateContextState | null>(
    'autoSaveInvest',
    null
  );

  const [
    fullState,
    setLocalState,
  ] = useState<AutoSaveInvestStateContextState | null>(sessionState);

  const setState = (
    newAccountState: AutoSaveInvestAccountState | null,
    accountId: string
  ) => {
    const activeAccountId = accountId;
    const newState = {
      ...fullState,
      [activeAccountId]: newAccountState,
    };
    setLocalState(newState);
    setSessionState(newState);
  };

  const getState = (accountId: string) => {
    if (fullState === null) {
      return null;
    }
    return fullState[accountId];
  };

  const clearState = (accountId: string) => {
    const newState = { ...fullState, [accountId]: null };
    setLocalState(newState);
    setSessionState(newState);
  };

  const { signedIn } = useAuth();
  useUserProfileQuery(undefined, {
    enabled: signedIn,
    onSuccess: (data) => {
      // Clear state for accounts that are not in the users profile
      const accountIds =
        data.clientSummary?.accounts.map((account) => account.id) ?? [];
      const newState = pickBy(fullState, (value, key) =>
        accountIds.includes(key)
      );
      setLocalState(newState);
      setSessionState(newState);
    },
  });

  return {
    setState,
    clearState,
    getState,
    fullState,
  };
}

export function SelectFundsPercentagesStepContextProvider({
  children,
}: AuthProviderProps) {
  const value = useAutoSaveInvestStateContextProvider();

  return (
    <selectFundsPercentagesStepContextContext.Provider value={value}>
      {children}
    </selectFundsPercentagesStepContextContext.Provider>
  );
}

export function useAutoSaveInvestState(selectedAccountId?: string) {
  const [mode] = useMode();

  const { getState, setState, clearState, fullState } = useContext(
    selectFundsPercentagesStepContextContext
  );

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);

  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;

  const activeWrapperType = isAutoSaveInvestPage
    ? getWrapperTypeForPathSegment(autoSaveInvestPageMatch?.params.wrapperType)
    : mode?.mode === 'resume'
    ? undefined
    : mode?.wrapperType;

  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const account = userProfileQuery.data?.clientSummary?.accounts.find(
    ({ wrapperType, id }) => {
      if (selectedAccountId) {
        return id === selectedAccountId;
      } else {
        return wrapperType === activeWrapperType;
      }
    }
  );
  const accountId = account?.id;
  const state = getState(accountId);

  const { search } = useLocation();
  const history = useHistory();

  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId as string,
    },
    {
      enabled: !!accountId,
      onSuccess: (data) => {
        if (
          getState(account?.id) === undefined ||
          getState(account?.id) === null
        ) {
          const query = new URLSearchParams(search);
          let setUpAutoInvestForDeposit: number | null = null;

          if (query.has('setUpAutoInvestForDeposit')) {
            const setUpAutoInvestForDepositQueryValue = query.get(
              'setUpAutoInvestForDeposit'
            );
            setUpAutoInvestForDeposit =
              typeof setUpAutoInvestForDepositQueryValue === 'string'
                ? parseInt(setUpAutoInvestForDepositQueryValue)
                : null;

            query.delete('setUpAutoInvestForDeposit');
            history.replace({
              search: query.toString(),
            });
          }

          setState(
            {
              deposits: data?.recurringTransactions?.deposits?.map(
                (deposit, index) => ({
                  ...deposit,
                  autoInvest:
                    setUpAutoInvestForDeposit === index
                      ? true
                      : deposit.autoInvest,
                  isNew: false,
                  isEdited: setUpAutoInvestForDeposit === index,
                  isDeleted: false,
                })
              ),
              orders: data?.recurringTransactions?.orders?.map((order) => ({
                ...order,
                isNew: false,
                isEdited: false,
                isDeleted: false,
              })),
            },
            accountId!
          );
        }
      },
    }
  );

  const getBasketsSummary = () => {
    if (fullState === null) {
      return null;
    }
    const summary: AutoSaveInvestBasketSummary[] | null = Object.keys(fullState)
      .filter((key) => {
        const accountSummary = fullState[key];
        const depositsHaveChanges = accountSummary?.deposits?.some(
          (deposit) => {
            return deposit.isNew || deposit.isEdited || deposit.isDeleted;
          }
        );
        const ordersHaveChanges = accountSummary?.orders?.some((order) => {
          return order.isNew || order.isEdited || order.isDeleted;
        });
        return depositsHaveChanges || ordersHaveChanges;
      })
      .map((key) => {
        const account = userProfileQuery.data?.clientSummary?.accounts.find(
          (account) => account.id === key
        );
        return {
          accountId: key,
          wrapperType: account?.wrapperType!,
          depositsTotal: getState(key)?.deposits?.reduce<number>(
            (total, deposit) =>
              deposit.autoInvest && !deposit.isDeleted
                ? total + deposit.amount
                : total,
            0
          ),
          orders: getState(key)?.orders?.length,
        };
      });

    return summary;
  };

  const latestEmployerContribution =
    recurringTransactionsQuery.data?.recurringTransactions
      ?.latestEmployerContribution;

  const latestEmployerContributionAmount =
    latestEmployerContribution?.amount ?? 0;

  const totalRecurringDepositsAmount =
    getState(accountId)?.deposits?.reduce<number>(
      (total, selectedRecurringDeposit) => {
        if (
          selectedRecurringDeposit.autoInvest &&
          !selectedRecurringDeposit.isDeleted
        ) {
          return total + selectedRecurringDeposit.amount;
        }
        return total;
      },
      0
    ) || 0;

  const totalDepositAmount =
    totalRecurringDepositsAmount + latestEmployerContributionAmount;

  const totalAllocationProportion =
    state?.orders?.reduce((total, field) => {
      return total + (field.proportion !== undefined ? field.proportion : 0);
    }, 0) || 0;

  const totalAllocationAmount = totalDepositAmount * totalAllocationProportion;

  return {
    state: getState(accountId!),
    setState: (state: AutoSaveInvestAccountState, setStateAccountId?: string) =>
      setState(state, setStateAccountId || accountId!),
    getState,
    clearState: (clearStateAccountId?: string) =>
      clearState(clearStateAccountId || accountId!),
    getBasketsSummary,
    latestEmployerContribution,
    totalDepositAmount,
    totalAllocationProportion,
    totalAllocationAmount,
    orderCount: state?.orders?.length,
    recurringTransactionsQuery,
    userProfileQuery,
  };
}
