import styled from 'styled-components';

export const PensionDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StepFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    max-width: 100%;
    width: 40rem;
  }
`;
