import { CookiePreferences } from '@tillitinvest/cookie-consent';
import { TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import Cookies from 'js-cookie';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { HiCheckCircle, HiThumbDown, HiThumbUp } from 'react-icons/hi';
import styled from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';

const SimpleFeedbackContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid ${transparentize(0.7, colors.magenta)};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: flex-start;
    margin-top: 2rem;
    flex-direction: row;
    width: auto;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`;

const FeedbackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    color: ${transparentize(0.5, colors.richBlack)};
    transition: color 0.3s linear;
    &:hover {
      color: ${transparentize(0.2, colors.magenta)};
      transition: color 0.3s linear;
    }
  }
`;

enum FeedbackValues {
  positive = 'positive',
  negative = 'negative',
}
type FeedBackValue = keyof typeof FeedbackValues;

interface SimpleFeedbackProps {
  feedbackSection: string;
  message?: string;
}

/**
 * Simple feedback tool which fires GA events to capture a positive negative response
 * @param feedbackSection - Feature you want feedback on
 * @param [message = 'Did you find this useful?'] - Optional custom message
 * @returns
 */
export const SimpleFeedback = ({
  feedbackSection,
  message = 'Did you find this useful?',
}: SimpleFeedbackProps) => {
  const [submitted, updateSubmitted] = useState<boolean>(false);
  const [shouldDisplay, updateShouldDisplay] = useState<boolean>(true);

  const cookie = Cookies.getJSON('cookieconsent_status_v2');
  const trackingAllowed: CookiePreferences =
    cookie?.allowPerformanceCookies ?? false;

  const handleOnClick = (value: FeedBackValue) => {
    updateSubmitted(true);
    setTimeout(() => {
      updateShouldDisplay(false);
    }, 5000);
    trackGa({
      event: GaEventNames.feedback,
      item_id: feedbackSection,
      value: value,
    });
  };

  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.feedbackOpportunity,
      item_id: feedbackSection,
    });
  });

  return (
    <>
      {shouldDisplay && trackingAllowed && (
        <SimpleFeedbackContainer>
          {!submitted ? (
            <>
              <TextNormal $noMargin>{message}</TextNormal>
              <IconsContainer>
                <FeedbackIconWrapper>
                  <HiThumbUp
                    onClick={() => handleOnClick(FeedbackValues.positive)}
                  />
                </FeedbackIconWrapper>
                <FeedbackIconWrapper>
                  <HiThumbDown
                    onClick={() => handleOnClick(FeedbackValues.negative)}
                  />
                </FeedbackIconWrapper>
              </IconsContainer>
            </>
          ) : (
            <>
              <TextNormal $noMargin>Thank you for your feedback</TextNormal>
              <FeedbackIconWrapper>
                <HiCheckCircle color={colors.magenta} />
              </FeedbackIconWrapper>
            </>
          )}
        </SimpleFeedbackContainer>
      )}
    </>
  );
};
