import { Button, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import styled from 'styled-components';

export const OnboardingFeedbackContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export const Acknowledgement = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: ${(p) => p.theme.spacing(5)}px;
  width: 100%;
  text-align: center;
`;

export const MainCopy = styled.p`
  margin: ${(p) => p.theme.spacing(2.5, 0)};
`;

const transitionSpeed = '0.3s';
const arrowOffset = '5px';
const animationFunction = 'cubic-bezier(.76,.16,.88,1.12)';

export const SkipButton = styled(Button).attrs({
  disableRipple: true,
  endIcon: <HiArrowNarrowRight />,
})`
  position: relative;
  display: inline-flex;
  color: ${colors.richBlack};
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  text-decoration: none;
  padding: 0;
  min-width: 0;
  appearance: none;
  text-transform: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    background-color: ${colors.richBlack};
    height: 1px;
    transition: width ${transitionSpeed} ${animationFunction};
  }

  .MuiButton-endIcon {
    transition: transform ${transitionSpeed} ${animationFunction};
    transform: translateX(0);
    margin: ${(p) => p.theme.spacing(0, 0, 0, 0.625)};

    & :first-child {
      font-size: 1rem;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:hover {
    background-color: unset;

    &:before {
      width: calc(100% + ${arrowOffset});
    }

    .MuiButton-endIcon {
      transform: translateX(${arrowOffset});
    }
  }
`;
