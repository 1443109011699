import { ChargesType, Instrument } from 'generated/graphql';
import { PartialDeep } from 'type-fest';

/**
 *
 * This function will calculate the units of a purchase based on the amount of the purchase
 *
 * @param instrument -   Object of type Instrument to be sold
 * @param amount -   Amount to be sold
 * @param charges  - Optional charges of type ChargesType
 * @returns
 */
export function getBuyEstimate(
  instrument: Pick<Instrument, 'askPrice' | 'minimumTradeUnit' | 'quoteUnit'>,
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  const { askPrice = 1, minimumTradeUnit = 1, quoteUnit = 1 } = instrument;
  const amountAfterPtm = amount - getPtmLevyAmount(amount, charges);
  const amountAfterFees =
    amountAfterPtm - getStampDutyAmount(amountAfterPtm, charges);
  return (
    floorToMultiple(amountAfterFees / askPrice!, minimumTradeUnit) * quoteUnit!
  );
}

/**
 *
 * Get units to be sold based on the amount to be sold
 *
 * @param instrument  - Object of type Instrument to be sold
 * @param amount -  Amount to be sold
 * @param charges - Optional charges of type ChargesType
 * @returns
 */
export function getSellEstimateUnits(
  instrument: PartialDeep<Instrument>,
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  const { bidPrice = 1, minimumTradeUnit = 1, quoteUnit = 1 } = instrument;
  const amountAfterPtm = amount - getPtmLevyAmount(amount, charges);
  const amountAfterFees =
    amountAfterPtm - getStampDutyAmount(amountAfterPtm, charges);
  return (
    floorToMultiple(amountAfterFees / bidPrice!, minimumTradeUnit) * quoteUnit!
  );
}

/**
 *
 * This function calculates (in pounds) the amount to be sold based on the units to be sold
 *
 * @param instrument -  Object of type Instrument to be sold
 * @param units - Units to be sold
 * @returns
 */
export function getSellEstimate(
  instrument: Pick<Instrument, 'bidPrice' | 'minimumTradeUnit' | 'quoteUnit'>,
  units: number
) {
  const { bidPrice, minimumTradeUnit, quoteUnit } = instrument;
  const estimate =
    floorToMultiple(units, minimumTradeUnit) * bidPrice! * quoteUnit!;
  return estimate;
}

function floorToMultiple(x: number, multiple: number) {
  return Math.floor(x / multiple) * multiple;
}

function getPtmLevyAmount(amount: number, charges?: PartialDeep<ChargesType>) {
  return charges?.ptmLevyApplicable &&
    amount > charges.ptmLevyApplicableOrderValueThreshold!
    ? charges.ptmLevyAmountWhereApplicable!
    : 0;
}

function getStampDutyAmount(
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  return charges?.stampDutyApplicable
    ? amount * charges.stampDutyProportion!
    : 0;
}

interface DeriveMinTradeableAmountProps {
  askPrice: number | undefined | null;
  minimumTradeUnit: number;
}

export function deriveMinTradeableAmount({
  askPrice,
  minimumTradeUnit,
}: DeriveMinTradeableAmountProps) {
  if (askPrice === null || askPrice === undefined) {
    return null;
  }

  return Math.round(askPrice * minimumTradeUnit * 100) / 100;
}

interface MinTradeUnitStatusProps {
  tradeAmount: number;
  askPrice: number;
  minTradeableUnit: number;
}

export type MinTradeUnitStatus = {
  belowSuggestedAmount: boolean;
  suggestedMinTradeForInstrument: number;
  minTradeableAmount: number;
};

export function deriveMinTradeUnitStatus({
  tradeAmount,
  askPrice,
  minTradeableUnit,
}: MinTradeUnitStatusProps): MinTradeUnitStatus | null {
  const minTradeableAmount = deriveMinTradeableAmount({
    askPrice,
    minimumTradeUnit: minTradeableUnit,
  });

  if (minTradeableAmount === null) {
    return null;
  }

  const suggestedMinTradeForInstrument = minTradeableAmount * 5;

  return {
    belowSuggestedAmount: tradeAmount < suggestedMinTradeForInstrument,
    suggestedMinTradeForInstrument,
    minTradeableAmount,
  };
}
