import clsx from 'clsx';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { useSwipeable } from 'react-swipeable';
import {
  CardBullets,
  CardContainer,
  CardContainerInner,
  NextNavigation,
  PrevNavigation,
} from './CardsCarousel.styles';
import { slugify } from 'formatting';

export interface Card {
  key: string;
  path?: string;
  component: React.FunctionComponent<any>;
  props: any;
  label?: string;
}

export interface CardsCarouselProps {
  activeCard: number;
  onChange: (index: number) => void;
  cards: Card[];
}

export function CardsCarousel({
  activeCard,
  onChange,
  cards,
}: CardsCarouselProps) {
  const maxSlides = cards.length;

  const carouselInteraction = (source: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'carousel changed',
    });
  };

  const nextIndex = (activeCard + 1) % maxSlides;
  const prevIndex = (((activeCard - 1) % maxSlides) + maxSlides) % maxSlides;

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      carouselInteraction('Swiped left');
      onChange(nextIndex);
    },
    onSwipedRight: () => {
      carouselInteraction('Swiped right');
      onChange(prevIndex);
    },
    preventDefaultTouchmoveEvent: true,
  });

  return (
    <CardContainer {...handlers}>
      <CardContainerInner>
        {cards.map((card, index) => {
          const Component = card.component;
          return (
            <Component
              key={card.key}
              dataTestid={
                index === activeCard
                  ? 'dashboard-carousel-card--active-card'
                  : 'dashboard-carousel-card'
              }
              className={clsx(
                {
                  'prev-slide': index === prevIndex,
                  'active-slide': index === activeCard,
                  'next-slide': index === nextIndex,
                },
                'carousel-slide',
                `card-type-${slugify(card.label || '')}`
              )}
              {...card.props}
            />
          );
        })}
        <PrevNavigation>
          <HiOutlineChevronLeft
            title="Previous slide"
            onClick={() => {
              carouselInteraction('Arrow previous');
              onChange(prevIndex);
            }}
          />
        </PrevNavigation>
        <NextNavigation>
          <HiOutlineChevronRight
            title="Next slide"
            onClick={() => {
              carouselInteraction('Arrow next');
              onChange(nextIndex);
            }}
          />
        </NextNavigation>
      </CardContainerInner>
      <CardBullets>
        {cards.map((_, index) => (
          <span
            key={index}
            className={clsx(index === activeCard && 'active')}
            onClick={() => {
              carouselInteraction('Pager blob');
              onChange(index);
            }}
          ></span>
        ))}
      </CardBullets>
    </CardContainer>
  );
}
