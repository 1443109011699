import styled from 'styled-components';

export const StatCopy = styled.p`
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

export const StatValue = styled.strong`
  font-weight: normal;
`;

export const StatLabel = styled.span`
  font-weight: 300;
`;
