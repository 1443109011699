import { Typography } from '@material-ui/core';
import { smallCopy } from 'shared.styles';
import Styled from 'styled-components';

type SmallCopy = {
  $fontSize: number;
  $fontStyle: string;
  $textAlign?: string;
};

export const SmallCopyContainer = Styled(Typography)<SmallCopy>`
  ${smallCopy}
  font-size: ${(p) => p.$fontSize}px;
  font-style: ${(p) => p.$fontStyle};
  text-align: ${(p) => p.$textAlign};
`;
