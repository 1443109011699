import { Box } from '@material-ui/core';
import { ContractNotesTable } from 'components/Tables/ContractNotesTable';
import { OtherDocumentsTable } from 'components/Tables/OtherDocumentsTable';
import { InternalTabs } from 'components/Tabs';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React, { useCallback, useMemo, useState } from 'react';
import { TabsWrapper, Title } from './../Styles/MyDashboard.styles';

export function MyDocuments() {
  const tabs = useMemo(
    () => [
      {
        label: 'Contract notes',
        value: 'contractNotes',
      },
      {
        label: 'Other documents',
        value: 'others',
      },
    ],
    []
  );

  const [activeTab, setActiveTab] = useState<string>(tabs[0].value);

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      trackGa({
        event: GaEventNames.selectContent,
        item_id: 'dashboard - my documents',
        content_type: `tab clicked - ${newValue}`,
      });
      setActiveTab(newValue);
    },
    []
  );

  return (
    <Box width={'100%'}>
      <Title lightTheme="light" textAlign="center">
        My documents
      </Title>

      <Box>
        <TabsWrapper>
          <InternalTabs
            tabsPerRow={4}
            tabs={tabs}
            value={activeTab}
            onChange={handleTabChange}
          />
        </TabsWrapper>
        {activeTab === 'contractNotes' && <ContractNotesTable />}
        {activeTab === 'others' && <OtherDocumentsTable />}
      </Box>
    </Box>
  );
}
