import { TextField } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export const CommonInput = css`
  display: block;
  font-weight: bold;
  color: ${colors.black};
  border: 2px solid ${colors.purple};
  border-radius: 0.3em;
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 6px center;
  background-size: 24px;

  &:hover {
    border-color: ${colors.magenta};
  }
  &:focus {
    border-color: ${colors.magenta};
    box-shadow: 0 0 1px 3px ${colors.purple};
    outline: none;
  }
`;

export const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    label {
      font-size: 0.75rem;
      font-weight: 500;

      &.Mui-disabled {
        color: ${colors.richBlack};
      }
    }
    .MuiInput-root {
      margin-top: 0.75rem;
      input,
      textarea {
        font-size: 0.875rem;
        font-weight: 300;
      }

      &.Mui-disabled {
        color: ${colors.richBlack};
        &:before {
          border-bottom: 1px solid ${colors.richBlack};
        }
      }
    }
  }
`;
