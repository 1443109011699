import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  .MuiFormLabel-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 1.2;

    &.MuiInputLabel-shrink {
      max-width: 130%;
    }
  }
`;
