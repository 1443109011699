import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroductionLi,
  StepIntroductionTypography,
  StepText,
  StepTitleSmall,
} from 'components/design-system/StepComponents/StepComponents';
import { tillitDarkUniversePath, tillitFAQs } from 'paths/externalPaths';

export const EligibleAssetsInformation = () => {
  return (
    <>
      <StepTitleSmall>Eligible assets</StepTitleSmall>
      <StepText>
        <StepIntroductionTypography>
          We only accept in specie transfers for eligible funds, investment
          trusts and ETFs.
        </StepIntroductionTypography>
        <StepIntroductionTypography>
          <strong>We cannot accept:</strong>
        </StepIntroductionTypography>
        <ul>
          <StepIntroductionLi>
            Individual stocks &amp; shares
          </StepIntroductionLi>
          <StepIntroductionLi>Non-GBP denominated assets</StepIntroductionLi>
          <StepIntroductionLi>Cryptocurrencies</StepIntroductionLi>
          <StepIntroductionLi>Leveraged ETFs</StepIntroductionLi>
          <StepIntroductionLi>
            Complex assets (including over-the-counter instruments, private
            assets, etc)
          </StepIntroductionLi>
          <StepIntroductionLi>Any unregulated assets</StepIntroductionLi>
        </ul>
        <StepIntroductionTypography>
          In addition, you may hold a fund in a restricted share class which we
          may not have access to. In this case we would seek to onboard the
          share class for you, but if we are unable to do so we may have to
          convert your holding into an eligible share class.{' '}
          <StyledLink
            href={
              tillitFAQs +
              '#what-happens-if-tillit-cannot-host-the-same-share-class-of-a-fund-i-want-to-transfer'
            }
            target="_blank"
          >
            Read more
          </StyledLink>
          .
        </StepIntroductionTypography>
        <StepIntroductionTypography style={{ fontStyle: 'italic' }}>
          To find out more, please go the{' '}
          <StyledLink href={tillitDarkUniversePath} target="_blank">
            Dark Universe
          </StyledLink>{' '}
          on our website or take a look at our{' '}
          <StyledLink href={tillitFAQs} target="_blank">
            FAQs
          </StyledLink>
          .
        </StepIntroductionTypography>
      </StepText>
    </>
  );
};
