import { Container } from '@material-ui/core';
import styled from 'styled-components';

export const DisclosureSliceContainer = styled.div`
  width: 100%;
`;

export const DisclosureInner = styled(Container)`
  padding: ${(p) => p.theme.spacing(7.5, 3.75)};

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(7.5, 3.75)};
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 2rem;
    padding: ${(p) => p.theme.spacing(7.5, 3.75)};
  }
`;
