import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { AccountType, useUserProfileQuery } from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { openAccountPath } from 'paths';
import {
  SkipButton,
  StepActions,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export function FinishOnboarding({
  referralCode,
  onSkip,
}: {
  referralCode?: string;
  onSkip: () => void;
}) {
  const userProfileQuery = useUserProfileQuery();
  const initialAccountType =
    userProfileQuery.data?.userProfile?.initialAccountType;

  const hasEmployer =
    (userProfileQuery.data?.userProfile?.employments || []).length > 0;

  return (
    <StepContainer>
      <QueryState {...userProfileQuery}>
        {() => (
          <>
            <StepContent>
              {initialAccountType === AccountType.Sipp && hasEmployer ? (
                <StepTitle>Your TILLIT Pension is waiting for you</StepTitle>
              ) : (
                <StepTitle>
                  Finish setting up your{' '}
                  {initialAccountType
                    ? getNameForAccountType(initialAccountType)
                    : 'account'}
                </StepTitle>
              )}
              <StepIntroduction>
                {initialAccountType === AccountType.Sipp && hasEmployer ? (
                  <StepIntroductionTypography>
                    To complete your switch to TILLIT, finish setting up your
                    pension account.
                  </StepIntroductionTypography>
                ) : (
                  <StepIntroductionTypography>
                    To start investing, browse more TILLIT Views or watch our
                    exclusive Meet the Manager video interviews, please finish
                    setting up your{' '}
                    {initialAccountType
                      ? getNameForAccountType(initialAccountType)
                      : 'account'}
                  </StepIntroductionTypography>
                )}
              </StepIntroduction>
            </StepContent>

            <StepActions>
              <ButtonsWrapper $isHorizontal>
                <CustomButtonV2
                  onClick={() => {
                    trackGa({
                      event: GaEventNames.selectContent,
                      content_type: 'cta button',
                      item_id: 'finish setup - sign in',
                    });
                    window.location.href = `${openAccountPath}${
                      referralCode ? `?referralCode=${referralCode}` : ''
                    }`;
                  }}
                  className="richBlue"
                >
                  Resume
                </CustomButtonV2>
                <SkipButton
                  className="richBlue"
                  variant="outlined"
                  onClick={() => {
                    onSkip();
                  }}
                >
                  Skip for now
                </SkipButton>
              </ButtonsWrapper>
            </StepActions>
          </>
        )}
      </QueryState>
    </StepContainer>
  );
}
