import { DialogActions, DialogContent } from '@material-ui/core';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import {
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { CashDepositTransferDetails } from 'generated/graphql';
import { useContext } from 'react';
import styled from 'styled-components';
import { PaymentSummary } from '../PaymentSummary';
import { DepositVariantContext } from '../_shared/DepositVariantContext';

const TransferringStepText = styled(StepText)`
  margin: 0 auto;
`;

export interface TransferringStepProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  onDashboard: () => void;
  onBrowseFunds: () => void;
}

export function TransferringStep({
  amount,
  transferDetails,
  onDashboard,
  onBrowseFunds,
}: TransferringStepProps) {
  const depositVariant = useContext(DepositVariantContext);

  return (
    <>
      <StepTitle>
        {depositVariant?.variant === DepositFormVariant.NewAccount &&
        depositVariant?.wrapperType
          ? `Your cash is on its way to your new ${depositVariant?.wrapperType}`
          : 'Your cash is on its way'}
      </StepTitle>
      <DialogContent>
        <TransferringStepText>
          <StepIntroductionTypography>
            It should appear on your Dashboard shortly.
          </StepIntroductionTypography>
        </TransferringStepText>
        <PaymentSummary
          amount={amount}
          transferDetails={transferDetails}
          copyEnabled={false}
          showSourceWarning={false}
        />
      </DialogContent>
      <DialogActions>
        <ButtonWrapper $align="center">
          <CustomButtonV2
            onClick={() => onDashboard()}
            $color="secondary"
            fullWidth
          >
            Dashboard
          </CustomButtonV2>
          <CustomButtonV2
            onClick={() => onBrowseFunds()}
            $color="primary"
            fullWidth
          >
            Browse funds
          </CustomButtonV2>
        </ButtonWrapper>
      </DialogActions>
    </>
  );
}
