import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${(p) => p.theme.spacing(3.75)}px;
`;

export const Copy = styled(Typography)`
  font-weight: 300;
  margin: 0;
`;

export const Logo = styled.img`
  height: 60px;
  width: auto;
  margin-left: ${(p) => p.theme.spacing(0.625)}px;
`;
