import {
  FontStyle,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { motion, useAnimation } from 'framer-motion';
import defer from 'lodash/defer';
import React, { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { TargetDateForm } from '../TargetDateForm';
import { TargetDateFormValues } from '../TargetDateForm/TargetDateForm';
import { getTargetDateInstrument } from '../helpers/getTargetDateInstrument';
import { useTargetDateInfo } from '../hooks/useTargetDateInfo';
import {
  CloseForm,
  EditDetailsButton,
  TargetDateBannerContainer,
  TargetDateBannerContent,
  TargetDateBannerInner,
} from './TargetDateBanner.styles';

export interface TargetDateBannerProps {
  assetData: AssetQueryAsset;
  onProceed?: (data: TargetDateFormValues) => void;
}

export function TargetDateBanner({
  assetData,
  onProceed,
}: TargetDateBannerProps) {
  const bannerContentContainer = useAnimation();
  const bannerFormContainer = useAnimation();
  const {
    targetDateInfo,
    savedTargetDateInfo,
    setTargetDateInfo,
  } = useTargetDateInfo();
  const [retirementYear, setRetirementYear] = React.useState(0);
  const [instrumentName, setInstrumentName] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);
  const [targetRange, setTargetRange] = React.useState('');
  const userRetirementYear =
    savedTargetDateInfo?.yearOfBirth &&
    savedTargetDateInfo?.intendedRetirementAge
      ? savedTargetDateInfo.yearOfBirth +
        savedTargetDateInfo.intendedRetirementAge
      : 0;
  const retirementYearMissMatch =
    (savedTargetDateInfo?.yearOfBirth &&
      savedTargetDateInfo?.intendedRetirementAge &&
      retirementYear !== userRetirementYear) ||
    false;

  useEffect(() => {
    if (targetDateInfo?.yearOfBirth && targetDateInfo?.intendedRetirementAge) {
      setRetirementYear(
        targetDateInfo.yearOfBirth + targetDateInfo.intendedRetirementAge
      );
    }
  }, [targetDateInfo?.yearOfBirth, targetDateInfo?.intendedRetirementAge]);

  useEffect(() => {
    const selectedInstrument = getTargetDateInstrument(
      assetData,
      retirementYear
    );
    if (selectedInstrument.instrument) {
      setInstrumentName(selectedInstrument.instrument.name);
      const targetRange = () => {
        if (selectedInstrument) {
          return !selectedInstrument.startDate
            ? `${selectedInstrument.endDate} and earlier`
            : !selectedInstrument.endDate
            ? `${selectedInstrument.startDate} and above`
            : `${selectedInstrument.startDate} - ${selectedInstrument.endDate}`;
        } else {
          return 'N/A';
        }
      };
      setTargetRange(targetRange);
    }
  }, [assetData, retirementYear]);

  const handleReset = () => {
    if (
      !savedTargetDateInfo ||
      !savedTargetDateInfo.intendedRetirementAge ||
      !savedTargetDateInfo.yearOfBirth
    ) {
      return null;
    }

    setRetirementYear(
      Number(savedTargetDateInfo.yearOfBirth) +
        Number(savedTargetDateInfo.intendedRetirementAge)
    );
    setTargetDateInfo(savedTargetDateInfo);
    onProceed?.({
      yearOfBirth: savedTargetDateInfo.yearOfBirth,
      intendedRetirementAge: savedTargetDateInfo.intendedRetirementAge,
    });
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    setEditMode(true);
    // Allow the form to render before starting the animation
    defer(() => {
      bannerContentContainer.start({
        height: '0',
        opacity: '0',
        pointerEvents: 'none',
      });
      bannerFormContainer.start({
        height: 'auto',
        opacity: '1',
        pointerEvents: 'auto',
      });
    });
  };

  const startCloseAnimation = async () => {
    bannerContentContainer.start({
      height: 'auto',
      opacity: '1',
      pointerEvents: 'auto',
    });
    await bannerFormContainer.start({
      height: '0',
      opacity: '0',
      pointerEvents: 'none',
    });
    // Wait for the animation to finish before hiding the form
    setEditMode(false);
  };

  const handleCloseForm = () => {
    startCloseAnimation();
  };

  const handleForm = (data: TargetDateFormValues) => {
    startCloseAnimation();
    setRetirementYear(
      Number(data.yearOfBirth) + Number(data.intendedRetirementAge)
    );
    onProceed?.(data);
  };

  return !retirementYear ? null : (
    <TargetDateBannerContainer>
      <TargetDateBannerInner maxWidth="lg" className="dark-background">
        <motion.div
          initial={{ height: 'auto', opacity: '1', overflow: 'hidden' }}
          animate={bannerContentContainer}
          transition={{ duration: 0.25 }}
        >
          <TargetDateBannerContent>
            {retirementYearMissMatch ? (
              <div>
                <TextNormal $isDarkBg>
                  This is the <b>{instrumentName}</b> vintage of this fund
                  because you asked us to show the best match for a retirement
                  in <b>{retirementYear}</b>.
                </TextNormal>
                <TextNormal $noMargin $isDarkBg $fontStyle={FontStyle.italic}>
                  This differs from the year <b>{userRetirementYear}</b>, that
                  you indicated you might retire when you opened your pension
                </TextNormal>
              </div>
            ) : (
              <TextNormal $noMargin $isDarkBg>
                We're showing you the <b>{instrumentName}</b> of this fund
                because the retirement dates it covers (<b>{targetRange}</b>)
                best match your planned retirement in <b>{retirementYear}</b>.
              </TextNormal>
            )}

            <TextSmall $noMargin $isDarkBg>
              Are these not correct?
              <br />
              <EditDetailsButton onClick={handleEdit}>
                Change my details
              </EditDetailsButton>
              {retirementYearMissMatch && (
                <>
                  {' '}
                  or{' '}
                  <EditDetailsButton onClick={handleReset}>
                    Reset to my planned retirement year
                  </EditDetailsButton>
                </>
              )}
            </TextSmall>
          </TargetDateBannerContent>
        </motion.div>
        <motion.div
          initial={{ height: '0', opacity: '0', overflow: 'hidden' }}
          animate={bannerFormContainer}
          transition={{ duration: 0.25 }}
        >
          <CloseForm onClick={handleCloseForm}>
            <AiOutlineClose />
          </CloseForm>
          {targetDateInfo && editMode && (
            <TargetDateForm
              $editMode={editMode}
              $theme="light"
              $canGoBack={false}
              onProceed={handleForm}
              title="About you"
            />
          )}
        </motion.div>
      </TargetDateBannerInner>
    </TargetDateBannerContainer>
  );
}
