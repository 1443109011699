import { useMediaQuery, useTheme } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { BorderStyle } from 'components/design-system/Pill/Pill';
import { PillSelect } from 'components/design-system/Pill/PillSelect';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import {
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { useAuth } from 'context/AuthContext';
import { AnimatePresence } from 'framer-motion';
import {
  AccountStatus,
  WrapperType,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
  getWrapperTypeForPathSegment,
} from 'helpers/accountHelpers';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  dynamicPortfolioConstructionPath,
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { ReactNode, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { UserProfileQueryClientSummaryAccount } from 'types/graphqlTypes';
import {
  AccountControlWrapper,
  ControlsWrapper,
  FooterIcon,
  FooterLayout,
  FooterSection,
} from './ModeFooter.styles';
import {
  ModeOrderFooter,
  ModeOrderMobileSummaryFooter,
} from './ModeOrderFooter';
import {
  ModeRecurringFooter,
  ModeRecurringMobileFooter,
  ModeRecurringMobileSummaryFooter,
} from './ModeRecurringFooter';
import { modeType, useMode } from './useMode';

interface FooterKeyValueTextProps {
  $needsAttention: boolean;
}

const FooterKeyValueText = styled(TextSmall)<FooterKeyValueTextProps>`
  color: ${({ $needsAttention }) =>
    $needsAttention ? colors['danger'] : 'inherit'};
  margin: 0;
`;

interface KeyValueProps {
  label: ReactNode;
  value: ReactNode;
  $needsAttention?: boolean;
}

export const KeyValue = ({ label, value, $needsAttention }: KeyValueProps) => {
  return (
    <FooterKeyValueText $noMargin $needsAttention={$needsAttention}>
      <strong>{value}</strong>
      <br />
      {label}
    </FooterKeyValueText>
  );
};

export interface ModeFooterContentProps {
  onDepositChange?: (amount: number) => void;
  accounts: UserProfileQueryClientSummaryAccount[];
  isMdUp: boolean;
  alwaysOpen: boolean;
  activeWrapperType?: WrapperType;
  activeMode: 'buy' | 'autoSaveInvest' | 'resume';
  onViewBasket?: () => void;
}

const directPath = (wrapperType: WrapperType, mode: modeType) => {
  if (mode === 'buy') {
    return generateDynamicPortfolioConstructionPath({
      wrapperType: getPathSegmentForWrapperType(wrapperType),
    });
  } else if (mode === 'autoSaveInvest') {
    return generateAutoSaveInvestPathPath({
      wrapperType: getPathSegmentForWrapperType(wrapperType),
    });
  }
  return null;
};

function ModeFooterContent({
  accounts,
  onDepositChange,
  isMdUp,
  alwaysOpen,
  activeMode,
  activeWrapperType,
  onViewBasket,
}: ModeFooterContentProps) {
  const history = useHistory();
  const [mode, setMode] = useMode();
  const shouldRedirect = useRouteMatch([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
    dynamicPortfolioConstructionPath,
  ]);

  const account = accounts.find(
    ({ wrapperType }) => wrapperType === activeWrapperType
  )!;

  const activeAccounts = accounts.filter((account) => {
    return account.status === AccountStatus.Active;
  });

  if (activeMode === 'resume') {
    return null;
  }

  return (
    <FooterLayout $hasControls={!!activeWrapperType}>
      {activeWrapperType && account && (
        <ControlsWrapper>
          {activeAccounts.length > 1 ? (
            <AccountControlWrapper>
              <PillSelect<WrapperType>
                menuPlacement={isMdUp ? 'top' : 'bottom'}
                $border={BorderStyle.Minimal}
                value={activeWrapperType}
                options={activeAccounts.map((account) => ({
                  value: account.wrapperType,
                  label: getShortNameForWrapperType(account.wrapperType),
                }))}
                onChange={(option) => {
                  if (mode !== null) {
                    setMode({
                      mode: activeMode,
                      wrapperType: option.value,
                    });
                  }
                  if (shouldRedirect) {
                    const newPath = directPath(option.value, activeMode);
                    if (newPath) {
                      history.push(newPath);
                    }
                  }
                }}
              />
            </AccountControlWrapper>
          ) : (
            <TextNormal $fontWeight={FontWeight.medium} $noMargin>
              {getShortNameForWrapperType(activeWrapperType)}
            </TextNormal>
          )}

          <div>
            <PillSelect<modeType>
              menuPlacement={isMdUp ? 'top' : 'bottom'}
              value={activeMode}
              $border={BorderStyle.Minimal}
              options={[
                {
                  value: 'buy',
                  label: 'One-off order',
                  labelDescription: 'Make a one-off order',
                },
                {
                  value: 'autoSaveInvest',
                  label: 'Regular order',
                  labelDescription: 'Setup a monthly regular order',
                },
              ]}
              onChange={(option) => {
                if (mode !== null) {
                  setMode({
                    mode: option.value,
                    wrapperType: activeWrapperType,
                  });
                }
                if (shouldRedirect && activeWrapperType) {
                  const newPath = directPath(activeWrapperType, option.value);
                  if (newPath) {
                    history.push(newPath);
                  }
                }
              }}
            />
          </div>
        </ControlsWrapper>
      )}
      {activeMode === 'buy' ? (
        <ModeOrderFooter
          key="buy"
          account={account}
          onViewBasket={onViewBasket}
        />
      ) : (
        <ModeRecurringFooter
          key="autoSaveInvest"
          account={account}
          onDepositChange={onDepositChange}
          alwaysOpen={alwaysOpen}
        />
      )}
    </FooterLayout>
  );
}

export interface SelectFundsPercentagesFooterProps {
  onDepositChange?: (amount: number) => void;
}

export function ActiveModeFooter({
  onDepositChange,
}: SelectFundsPercentagesFooterProps) {
  const { signedIn } = useAuth();
  const [mode] = useMode();
  const theme = useTheme();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const addCash = queryParams.has('add-cash');

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [mobileOpenState, setMobileOpenState] = useState(addCash);

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);
  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;
  const autoSaveInvestWrapperType = autoSaveInvestPageMatch?.params.wrapperType;

  const dynamicPortfolioConstructionPathMatch = useRouteMatch<{
    wrapperType: string;
  }>([dynamicPortfolioConstructionPath]);
  const isDynamicPortfolioConstructionPath =
    dynamicPortfolioConstructionPathMatch !== null;
  const dynamicPortfolioConstructionWrapperType =
    dynamicPortfolioConstructionPathMatch?.params.wrapperType;

  const alwaysOpen =
    isMdUp && (isAutoSaveInvestPage || isDynamicPortfolioConstructionPath);

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  if (
    mode === null &&
    !isAutoSaveInvestPage &&
    !isDynamicPortfolioConstructionPath
  ) {
    return null;
  }

  const activeWrapper = isAutoSaveInvestPage
    ? getWrapperTypeForPathSegment(autoSaveInvestWrapperType!)
    : isDynamicPortfolioConstructionPath
    ? getWrapperTypeForPathSegment(dynamicPortfolioConstructionWrapperType!)
    : mode === null || mode!.mode === 'resume'
    ? undefined
    : mode.wrapperType;

  const activeMode = isAutoSaveInvestPage
    ? 'autoSaveInvest'
    : isDynamicPortfolioConstructionPath
    ? 'buy'
    : mode!.mode;

  const account = userProfileQuery.data?.clientSummary?.accounts.find(
    ({ wrapperType }) => wrapperType === activeWrapper
  )!;

  const handleCloseMobileDraw = () => {
    setMobileOpenState(false);
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        if (isMdUp) {
          return (
            <FooterSection key="section">
              <ModeFooterContent
                accounts={userProfileQuery.data?.clientSummary?.accounts! ?? []}
                onDepositChange={onDepositChange}
                isMdUp={isMdUp}
                alwaysOpen={alwaysOpen}
                activeMode={activeMode}
                activeWrapperType={activeWrapper}
              />
            </FooterSection>
          );
        } else {
          return (
            <>
              <AnimatePresence>
                {activeMode === 'buy' && (
                  <FooterIcon
                    key="icon"
                    onClick={() => {
                      setMobileOpenState(true);
                    }}
                  >
                    <ModeOrderMobileSummaryFooter account={account} />{' '}
                  </FooterIcon>
                )}
                {activeMode === 'autoSaveInvest' && isAutoSaveInvestPage && (
                  <FooterSection key="section">
                    <ModeRecurringMobileFooter
                      onOpen={() => setMobileOpenState(true)}
                      account={account}
                    />
                  </FooterSection>
                )}
                {activeMode === 'autoSaveInvest' && !isAutoSaveInvestPage && (
                  <FooterIcon
                    key="icon"
                    onClick={() => {
                      setMobileOpenState(true);
                    }}
                  >
                    <ModeRecurringMobileSummaryFooter account={account} />{' '}
                  </FooterIcon>
                )}
              </AnimatePresence>

              {mobileOpenState && (
                <CustomSwipeableDrawer
                  isShort
                  onClose={() => {
                    setMobileOpenState(false);
                  }}
                  backgroundColor="purple"
                >
                  <ModeFooterContent
                    accounts={
                      userProfileQuery.data?.clientSummary?.accounts ?? []
                    }
                    key="buy"
                    onDepositChange={onDepositChange}
                    isMdUp={isMdUp}
                    alwaysOpen={false}
                    activeMode={activeMode}
                    activeWrapperType={activeWrapper}
                    onViewBasket={handleCloseMobileDraw}
                  />
                </CustomSwipeableDrawer>
              )}
            </>
          );
        }
      }}
    </QueryState>
  );
}
