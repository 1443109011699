import { StyledLink } from 'components/design-system/Link';
import { IsaRuleset, useIsaRuleset } from 'hooks/useIsaRuleset';
import {
  Container,
  DialogItalicText,
  DialogText,
  ListTitle,
  StyledList,
} from './IsaDeclarationCopy.styles';

export function IsaDeclarationCopy() {
  const { ruleset } = useIsaRuleset();

  return ruleset === IsaRuleset.TAX_YEAR_2024_25
    ? Isa202425Declaration
    : Isa202324Declaration;
}

const Isa202324Declaration = (
  <div>
    <Container>
      <DialogText>
        For your own benefit and protection you should read our Terms &amp;
        Conditions carefully before committing to an investment. If you do not
        understand any points please ask us for further information. When you
        use our services we will take this as acceptance and agreement of our
        terms, and you will be bound by them.
      </DialogText>
      <DialogText>
        I apply to subscribe for a Stocks &amp; Shares ISA for the current tax
        year (2023/24) and each successive year until further notice. If I do
        not subscribe in a tax year I will be required to agree to this
        declaration again before I can subscribe.
      </DialogText>
      <ListTitle paragraph={false}>I declare that</ListTitle>

      <StyledList>
        <li>
          <DialogText>
            All subscriptions made, and to be made, belong to me;
          </DialogText>
        </li>
        <li>
          <DialogText>I am 18 years of age or over;</DialogText>
        </li>
        <li>
          <DialogText>
            I have not subscribed/made payments, and will not subscribe/make
            payments, more than the overall subscription/payment limit in total
            to a cash ISA, a Stocks & Shares ISA, an innovative finance ISA, and
            a Lifetime ISA in the same tax year;
          </DialogText>
        </li>
        <li>
          <DialogText>
            I have not subscribed and will not subscribe to another Stocks &
            Shares ISA in the same tax year that I subscribe to this Stocks &
            Shares ISA*;
          </DialogText>
        </li>
        <li>
          <DialogText>
            I am resident in the United Kingdom for tax purposes or, if not so
            resident, either perform duties which, by virtue of Section 28 of
            Income Tax (Earnings &amp; Pensions) Act 2003 (Crown employees
            serving overseas), are treated as being performed in the United
            Kingdom, or I am married to, or in a civil partnership with, a
            person who performs such duties. I will inform my platform provider
            or Seccl Custody Limited if I cease to be so resident or to perform
            such duties or be married to, or in a civil partnership with, a
            person who performs such duties;
          </DialogText>
        </li>
        <li>
          <DialogText>
            for my own benefit and protection I have been given the opportunity
            to read the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>{' '}
            carefully and have had any questions I had concerning them answered
            to my satisfaction;
          </DialogText>
        </li>
        <li>
          <DialogText>
            I agree to the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            ; and
          </DialogText>
        </li>
        <li>
          <DialogText>
            I consent to my personal data being used in accordance with the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            .
          </DialogText>
        </li>
      </StyledList>

      <DialogItalicText>
        * If you have subscribed to another Stocks & Shares ISA in the current
        tax year, you are able to subscribe to this ISA only after you complete
        a full transfer of current year subscriptions.
      </DialogItalicText>
      <ListTitle>I authorise the ISA manager, Seccl Custody:</ListTitle>
      <StyledList>
        <li>
          <DialogText>
            to hold my cash subscription, ISA investments, interest, dividends
            and any other rights or proceeds in respect of those investments and
            any other cash;
          </DialogText>
        </li>
        <li>
          <DialogText>
            to give effect to my instructions in accordance with the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            ; and
          </DialogText>
        </li>
        <li>
          <DialogText>
            to make on my behalf any claims to relief from tax in respect of ISA
            investments.
          </DialogText>
        </li>
      </StyledList>
    </Container>
  </div>
);

const Isa202425Declaration = (
  <div>
    <Container>
      <DialogText>
        For your own benefit and protection you should read our Terms &amp;
        Conditions carefully before committing to an investment. If you do not
        understand any points please ask us for further information. When you
        use our services we will take this as acceptance and agreement of our
        terms, and you will be bound by them.
      </DialogText>
      <DialogText>
        I apply to subscribe for a Stocks &amp; Shares ISA for the current tax
        year (2024/25) and each successive year until further notice.
      </DialogText>
      <ListTitle paragraph={false}>I declare that</ListTitle>

      <StyledList>
        <li>
          <DialogText>
            All subscriptions made, and to be made, belong to me;
          </DialogText>
        </li>
        <li>
          <DialogText>I am 18 years of age or over;</DialogText>
        </li>
        <li>
          <DialogText>
            I have not subscribed/made payments, and will not subscribe/make
            payments, more than the overall subscription/payment limit in total
            to any cash ISAs, Stocks & Shares ISAs, innovative finance ISAs, and
            a Lifetime ISA in the same tax year;
          </DialogText>
        </li>
        <li>
          <DialogText>
            I am resident in the United Kingdom for tax purposes or, if not so
            resident, either perform duties which, by virtue of Section 28 of
            Income Tax (Earnings &amp; Pensions) Act 2003 (Crown employees
            serving overseas), are treated as being performed in the United
            Kingdom, or I am married to, or in a civil partnership with, a
            person who performs such duties. I will inform my platform provider
            or Seccl Custody Limited if I cease to be so resident or to perform
            such duties or be married to, or in a civil partnership with, a
            person who performs such duties;
          </DialogText>
        </li>
        <li>
          <DialogText>
            for my own benefit and protection, I have been given the opportunity
            to read the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>{' '}
            carefully and have had any questions I had concerning them answered
            to my satisfaction;
          </DialogText>
        </li>
        <li>
          <DialogText>
            I agree to the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            ; and
          </DialogText>
        </li>
        <li>
          <DialogText>
            I consent to my personal data being used in accordance with the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            .
          </DialogText>
        </li>
      </StyledList>

      <ListTitle>I authorise the ISA manager, Seccl Custody:</ListTitle>
      <StyledList>
        <li>
          <DialogText>
            to hold my cash subscription, ISA investments, interest, dividends
            and any other rights or proceeds in respect of those investments and
            any other cash;
          </DialogText>
        </li>
        <li>
          <DialogText>
            to give effect to my instructions in accordance with the{' '}
            <StyledLink
              href={
                'https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf#page=24'
              }
              target="_blank"
            >
              ISA Terms and Conditions
            </StyledLink>
            ; and
          </DialogText>
        </li>
        <li>
          <DialogText>
            to make on my behalf any claims to relief from tax in respect of ISA
            investments.
          </DialogText>
        </li>
      </StyledList>

      <DialogText>
        I declare that the application form has been completed to the best of my
        knowledge and belief. I will notify you without delay of changes to
        these particulars.
      </DialogText>
      {/* Hack - there's no margin on the last p in the sequence so the checkboxes butt up against */}
      <br />
    </Container>
  </div>
);
