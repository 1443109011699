import { StyledLink } from 'components/design-system/Link';
import { TextSmall } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { GetTradeCostsAndChargesIllustrationQuery } from 'generated/graphql';
import { HiExternalLink } from 'react-icons/hi';

export interface AssumptionsProps {
  amount: string | number;
  tradeIllustration?: GetTradeCostsAndChargesIllustrationQuery;
}

export function Assumptions({ amount, tradeIllustration }: AssumptionsProps) {
  return (
    <div>
      <TextSmall>
        We've illustrated the estimated costs and charges based on an order of{' '}
        <strong>{format.currencyFull(amount)}</strong> and holding this fund for{' '}
        <strong>
          {tradeIllustration?.tradeCostsAndChargesIllustration.periodMonths}{' '}
          months
        </strong>
        {', '}
        assuming{' '}
        <strong>
          {
            tradeIllustration?.tradeCostsAndChargesIllustration
              .assumedGrowthPercent
          }
          % growth per year
        </strong>
        . We use figures supplied by the fund manager for this purpose, and they
        may differ (up or down) from the actual costs and charges you incur over
        time.{' '}
      </TextSmall>
      <TextSmall $noMargin>
        <StyledLink href="https://tillitinvest.com/faqs#fees" target="_blank">
          Learn more about costs and charges <HiExternalLink />
        </StyledLink>
        .
      </TextSmall>
    </div>
  );
}
