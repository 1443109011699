import { animated } from 'react-spring';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Front = styled(animated.div)`
  & > div {
    min-height: 60px;
    min-width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    & > * {
      flex: 1 1 100%;
    }
  }
`;

export const Actions = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;

  & > div {
    flex: 0 0 auto;
    margin-left: ${(p) => p.theme.spacing(1)}px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }

  .MuiButton-root {
    flex: 0 0 100%;
    padding: ${(p) => p.theme.spacing(1.5)}px;

    .MuiButton-label {
      white-space: normal;
      line-height: normal;
    }
  }
`;
