import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors } from 'constants/colors';
import styled from 'styled-components';

type titleProps = {
  pdBottom?: number;
};

export const TitleContainer = styled(DialogTitle)<titleProps>`
  margin: 0;
  padding: ${(p) => p.theme.spacing(3.75)}px;
  padding-bottom: ${(p) => p.theme.spacing(2.5)}px;
  > * {
    font-size: 2rem;
  }
`;

export const ContentContainer = styled(DialogContent)`
  font-weight: 300;
  margin: 0;
  padding: 0 ${(p) => p.theme.spacing(3.75)}px;
  p {
    margin: 0;
  }
`;

export const ActionsContainer = styled(DialogActions)`
  padding: ${(p) => p.theme.spacing(3.75)}px;
  padding-top: ${(p) => p.theme.spacing(2.5)}px;
`;

export const CgiInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  ${(p) => p.theme.breakpoints.up('sm')} {
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 40px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      z-index: 1;
    }

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 40px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }
  }
`;

export const InnerDialog = styled.div`
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding-bottom: 10px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CgiBackground = styled.div`
  display: none;
  ${(p) => p.theme.breakpoints.up('sm')} {
    display: inherit;
    position: relative;
    width: 50%;
    padding-top: 50%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }
  }
`;

export const CgiContent = styled.div`
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    padding-bottom: 40px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  position: relative;
  height: 100%;

  ${(p) => p.theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const SubSection = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0 ${(p) => p.theme.spacing(3.75)}px;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SubTitle = styled.h3`
  font-weight: normal;
  color: ${colors.richBlue};
  margin-bottom: -10px;
  font-size: 1.25rem;
`;

export const CloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 0;
`;
