import styled from 'styled-components';

export const RebalancingOrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const HeadingLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
  }
`;

export const OrdersFiltersWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -1rem;
  }
`;

export const DynamicPortfolioConstructionListContainer = styled.div`
  width: 100%;
`;
