import { InfoPopover } from 'components/Popover';
import { Card } from 'components/design-system/Card/Card';
import {
  FontWeight,
  TextLarge,
  TextXS,
} from 'components/design-system/Text/Text';
import { BasketBreakdownType } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/useGetBasketBreakdown';
import * as format from 'formatting';
import styled from 'styled-components';
import { CurrentBreakdownType } from '../../../hooks/useGetBreakdowns';

/* TD: Forgive me */
const AbsoluteInfoPopoverContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const RelativeContainer = styled.div`
  position: relative;
  & > * {
    height: 100%;
  }
`;

/* TD: End forgive me */

const assetClassDisplayLookup: Record<string, string> = {
  Equities: 'equity',
  Bonds: 'bond',
  Property: 'property',
  'Alternative assets': 'property',
  'Multi-asset': 'equity',
};

const regionDisplayLookup: Record<string, string> = {
  Global: 'global',
  UK: 'UK',
  US: 'US',
  Europe: 'European',
  Japan: 'Japan',
  Asia: 'Asia',
  'Asia ex-Japan': 'Asia ex-Japan',
  'Emerging markets': 'emerging market',
  'Frontier markets': 'frontier market',
};

interface CurrentStatBoxesProps {
  activeBreakdown: CurrentBreakdownType | BasketBreakdownType;
}

export const CurrentStatBoxes = ({
  activeBreakdown,
}: CurrentStatBoxesProps) => {
  const breakDownName =
    regionDisplayLookup[activeBreakdown.name] ??
    assetClassDisplayLookup[activeBreakdown.name] ??
    activeBreakdown.name.toLocaleLowerCase();

  return (
    <>
      <RelativeContainer>
        <Card>
          <TextXS $noMargin>{activeBreakdown.name} value</TextXS>
          <TextLarge $noMargin $fontWeight={FontWeight.medium}>
            {format.currencyFull(activeBreakdown.amount)}
          </TextLarge>
          <AbsoluteInfoPopoverContainer>
            <InfoPopover
              color="primary"
              size="small"
              content={
                <>
                  <p>
                    Total value of current {breakDownName} holdings in your
                    portfolio.
                  </p>
                </>
              }
            />
          </AbsoluteInfoPopoverContainer>
        </Card>
      </RelativeContainer>
      <RelativeContainer>
        <Card>
          <TextXS $noMargin>{activeBreakdown.name} allocation</TextXS>
          <TextLarge $noMargin $fontWeight={FontWeight.medium}>
            {format.percent(activeBreakdown.percentage / 100)}
          </TextLarge>
          <AbsoluteInfoPopoverContainer>
            <InfoPopover
              color="primary"
              size="small"
              content={
                <>
                  <p>
                    Total {breakDownName} allocation relative to your overall
                    portfolio.
                  </p>
                </>
              }
            />
          </AbsoluteInfoPopoverContainer>
        </Card>
      </RelativeContainer>
    </>
  );
};
