import { TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

/**
 * PasswordStrengthCheckContainer
 *
 * flex thing horizontal center 1rem gap
 */
export const PasswordStrengthCheckContainer = styled.div`
  margin-top: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
`;

interface PasswordStrengthCheckProps {
  isActive: boolean;
}
/**
 * PasswordStrengthCheck
 * implements PasswordStrengthCheckProps
 * styled component uses TextSmall from design system
 * colors.midGrey if not active & color.magenta if active
 */
export const PasswordStrengthCheck = styled(
  TextSmall
)<PasswordStrengthCheckProps>`
  color: ${({ isActive }) => (isActive ? colors.magenta : colors.darkGrey)};
`;
