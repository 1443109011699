import { Box, DialogContent } from '@material-ui/core';
import { ArrowButton } from 'components/Button/ArrowButton/ArrowButton';
import { Loading } from 'components/Loading';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import {
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import React from 'react';
import styled from 'styled-components';
import {
  ButtonsContainer,
  DialogActionsFooter,
  StyledDialogActions,
} from '../styles/Deposit.styles';

export interface NewPaymentStepProps {
  onContactSupport: () => void;
  onTryAgain: () => void;
  onPayManually: () => void;
  waitingForCallback: boolean;
}

const NewPaymentStepStepText = styled(StepText)`
  margin: 0 auto;
`;

export function NewPaymentStep({
  onContactSupport,
  onTryAgain,
  onPayManually,
  waitingForCallback,
}: NewPaymentStepProps) {
  return (
    <>
      <StepTitle>We’re processing your payment</StepTitle>
      <DialogContent>
        <Box mb={2}>
          <Loading />
        </Box>
        <NewPaymentStepStepText>
          <StepIntroductionTypography>
            Your payment should be underway in a pop-up window.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            Not seen a popup? Make sure you’ve disabled any popup blocker for
            this site and then click to try again.
          </StepIntroductionTypography>
        </NewPaymentStepStepText>
      </DialogContent>
      <StyledDialogActions disableSpacing>
        <ButtonsContainer>
          <ButtonWrapper $align="center">
            <CustomButtonV2
              onClick={() => onContactSupport()}
              $color="secondary"
              fullWidth
            >
              Contact support
            </CustomButtonV2>
            <CustomButtonV2
              onClick={() => onTryAgain()}
              $color="secondary"
              fullWidth
              disabled={!waitingForCallback}
            >
              Try again
            </CustomButtonV2>
          </ButtonWrapper>
        </ButtonsContainer>
        <DialogActionsFooter>
          <ArrowButton onClick={() => onPayManually()}>
            Make a manual transfer
          </ArrowButton>
        </DialogActionsFooter>
      </StyledDialogActions>
    </>
  );
}
