import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { Title } from '../Styles/FundDetails.style';

interface NoDataTabProps {
  title: string;
}

export function NoDataTab({ title }: NoDataTabProps) {
  return (
    <>
      <Title>{title}</Title>
      <NoDataCopy>{title} is not available.</NoDataCopy>
    </>
  );
}
