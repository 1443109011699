import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { ResponsiveDialog } from 'components/ResponsiveDialog';
import { BasketBuyOrder } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useMode } from 'components/feature/mode/useMode';
import * as format from 'formatting';
import {
  AccountsQuery,
  PortfolioRebalancingsQuery,
  RebalancingBuyOrder,
  RebalancingSellOrder,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { useCallback, useEffect, useState } from 'react';
import {
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
  generateDynamicPortfolioConstructionFundsPath,
} from '../../../../paths/appPaths';
import { CustomButtonV2 } from '../../Button/CustomButtonV2';
import { TextAlign } from '../../Heading/Heading';
import { FontSize, FontWeight, Text } from '../../Text/Text';
import {
  AccountTab,
  AccountTabWrapper,
  EmptyBasket,
  MiniBasketDetailsRow,
  MiniBasketIndicator,
  MiniBasketItem,
  MiniBasketItemPrice,
  MiniBasketItemTitle,
  MiniBasketItemWrapper,
  MiniBasketSummaryWrapper,
  MiniBasketWrapper,
} from './_shared.styles';

type Account = Exclude<AccountsQuery['accounts'], null | undefined>[number];
type PortfolioRebalancing = PortfolioRebalancingsQuery['portfolioRebalancings'][number];

interface MiniBasketContentProps {
  account: Account;
  basket: PortfolioRebalancing | undefined;
}
const MiniBasketContent = ({ account, basket }: MiniBasketContentProps) => {
  const buyOrders = basket?.buyOrders;
  const sellOrders = basket?.sellOrders;
  const wrapperType = account.wrapperType?.toLowerCase();
  const basketSummary =
    (buyOrders?.reduce((amount, a) => amount + (a?.amount || 0), 0) || 0) -
    (sellOrders?.reduce((amount, a) => amount + (a?.enteredAmount || 0), 0) ||
      0);

  const canCheckout = () => {
    return basket && (buyOrders?.length! > 0 || sellOrders?.length! > 0);
  };

  const availableCash = account?.valuationSummary?.uninvestedCash || 0;

  const remainingAmount =
    basketSummary <= 0
      ? availableCash || 0
      : Math.round(((availableCash || 0) - basketSummary) * 100) / 100;

  if (!wrapperType) return null;

  if (!buyOrders?.length && !sellOrders?.length) {
    return (
      <MiniBasketItemWrapper>
        <EmptyBasket>Basket empty</EmptyBasket>
        <ButtonsWrapper $stretchButtons>
          <CustomButtonV2
            href={generateDynamicPortfolioConstructionFundsPath({
              wrapperType: getPathSegmentForWrapperType(account.wrapperType),
            })}
          >
            Add funds
          </CustomButtonV2>
        </ButtonsWrapper>
      </MiniBasketItemWrapper>
    );
  }
  return (
    <>
      <MiniBasketItemWrapper>
        {buyOrders?.map((order: RebalancingBuyOrder) => {
          return (
            <MiniBasketItem
              key={order.isin}
              $isDarkUniverse={order.instrument.isDarkUniverse || false}
            >
              <MiniBasketItemTitle
                $fontSize={FontSize.normal}
                $fontWeight={FontWeight.medium}
                $textAlign={TextAlign.left}
              >
                {order.instrument.name}
              </MiniBasketItemTitle>
              <MiniBasketDetailsRow>
                <MiniBasketIndicator
                  $isDarkUniverse={order.instrument.isDarkUniverse || false}
                  $type="buy"
                >
                  Buy
                </MiniBasketIndicator>
                <MiniBasketItemPrice $noMargin>
                  {format.currencyFull(order.amount)}
                </MiniBasketItemPrice>
              </MiniBasketDetailsRow>
            </MiniBasketItem>
          );
        })}
        {sellOrders?.map((order: RebalancingSellOrder) => {
          return (
            <MiniBasketItem
              key={order.isin}
              $isDarkUniverse={order.instrument.isDarkUniverse || false}
            >
              <MiniBasketItemTitle
                $fontSize={FontSize.normal}
                $fontWeight={FontWeight.medium}
                $textAlign={TextAlign.left}
              >
                {order.instrument.name}
              </MiniBasketItemTitle>
              <MiniBasketDetailsRow>
                <MiniBasketIndicator
                  $isDarkUniverse={order.instrument.isDarkUniverse || false}
                  $type="sell"
                >
                  Sell
                </MiniBasketIndicator>
                <MiniBasketItemPrice $noMargin>
                  {format.currencyFull(order.enteredAmount)}
                </MiniBasketItemPrice>
              </MiniBasketDetailsRow>
            </MiniBasketItem>
          );
        })}
      </MiniBasketItemWrapper>
      <MiniBasketSummaryWrapper>
        {basketSummary >= 0 ? (
          <div>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.light}
              $noMargin
            >
              Basket balance
            </Text>
            <Text
              $fontSize={FontSize.normal}
              $fontWeight={FontWeight.medium}
              $noMargin
            >
              {format.currencyFull(basketSummary)}
            </Text>
          </div>
        ) : (
          <div>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.light}
              $noMargin
            >
              Estimated cash raised (net)
            </Text>
            <Text
              $fontSize={FontSize.normal}
              $fontWeight={FontWeight.medium}
              $noMargin
            >
              {format.currencyFull(Math.abs(basketSummary))}
            </Text>
          </div>
        )}

        <div>
          {remainingAmount > 0 ? (
            <>
              <Text
                $fontSize={FontSize.small}
                $fontWeight={FontWeight.light}
                $noMargin
              >
                Remaining cash balance
              </Text>
              <Text
                $fontSize={FontSize.normal}
                $fontWeight={FontWeight.medium}
                $noMargin
              >
                {format.currencyFull(remainingAmount)}
              </Text>
            </>
          ) : (
            <>
              <Text
                $fontSize={FontSize.small}
                $fontWeight={FontWeight.light}
                $noMargin
              >
                Order shortfall
              </Text>
              <Text
                $fontSize={FontSize.normal}
                $fontWeight={FontWeight.medium}
                $noMargin
              >
                {format.currencyFull(remainingAmount * -1)}
              </Text>
            </>
          )}
        </div>
      </MiniBasketSummaryWrapper>
      <ButtonsWrapper $isHorizontal $stretchButtons>
        <CustomButtonV2
          $color="secondary"
          href={generateDynamicPortfolioConstructionBasketPath({
            wrapperType: getPathSegmentForWrapperType(account.wrapperType),
          })}
        >
          Edit basket
        </CustomButtonV2>
        <CustomButtonV2
          disabled={!canCheckout()}
          href={generateCheckoutPath({
            wrapperType: getPathSegmentForWrapperType(account.wrapperType),
            selectedRebalancingId: basket?.id || false,
          })}
        >
          Checkout
        </CustomButtonV2>
      </ButtonsWrapper>
    </>
  );
};

export interface MiniBasketProps {
  accounts: Account[];
  baskets: PortfolioRebalancing[];
  open?: boolean;
  onClose?: () => void;
}

export function MiniBasket({
  accounts,
  baskets,
  open = false,
  onClose,
  ...props
}: MiniBasketProps) {
  const [mode] = useMode();
  const [activeAccount, setActiveAccount] = useState<Account>();

  const getOrdersCount = (basket: PortfolioRebalancing | undefined) => {
    return (basket?.buyOrders.length ?? 0) + (basket?.sellOrders.length ?? 0);
  };

  const getBasket = useCallback(
    (accountId: string | undefined | null) =>
      baskets.find((basket) => basket.accountId === accountId),
    [baskets]
  );

  const findNonEmptyAccount = useCallback(
    () =>
      accounts.find((account) => {
        return getOrdersCount(getBasket(account.id)) > 0;
      }),
    [accounts, getBasket]
  );

  const activeBasket = getBasket(activeAccount?.id);

  const activeOrdersCount = getOrdersCount(activeBasket);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!open) {
      setActiveAccount(undefined);
    } else {
      if (mode !== null && mode.mode !== 'resume') {
        const account = accounts.find(
          ({ wrapperType }) => wrapperType === mode.wrapperType
        );
        if (account) {
          setActiveAccount(account);
          return;
        }
      }

      setActiveAccount(findNonEmptyAccount() ?? accounts[0]);
    }
  }, [accounts, findNonEmptyAccount, open, mode]);

  const BasketWrapper = () => (
    <MiniBasketWrapper
      {...props}
      style={
        open
          ? {
              opacity: '1',
              transform: 'translateY(20px)',
              pointerEvents: 'auto',
            }
          : {}
      }
    >
      {isMobile && (
        <Typography
          variant="subtitle1"
          component="h1"
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: '1.25rem',
          }}
        >
          Your basket
        </Typography>
      )}
      {activeAccount?.wrapperType && accounts?.length === 1 && (
        <AccountTabWrapper>
          <AccountTab $active>
            {getShortNameForWrapperType(activeAccount.wrapperType)}{' '}
            {activeOrdersCount > 0 && <>({activeOrdersCount})</>}
          </AccountTab>
        </AccountTabWrapper>
      )}
      {accounts?.length > 1 && (
        <AccountTabWrapper>
          {accounts.map((account) => {
            const basket = getBasket(account.id);
            const ordersCount = getOrdersCount(basket);
            return (
              <AccountTab
                $active={activeAccount?.wrapperType === account.wrapperType}
                onClick={() => setActiveAccount(account)}
                key={account.id}
              >
                {getShortNameForWrapperType(account.wrapperType)}{' '}
                {ordersCount > 0 && <>({ordersCount})</>}
              </AccountTab>
            );
          })}
        </AccountTabWrapper>
      )}
      {activeAccount && (
        <MiniBasketContent basket={activeBasket} account={activeAccount} />
      )}
    </MiniBasketWrapper>
  );

  return isMobile ? (
    <ResponsiveDialog open={open} onClose={onClose}>
      <BasketWrapper />
    </ResponsiveDialog>
  ) : (
    <BasketWrapper />
  );
}

export interface UnAuthMiniBasketProps {
  buyOrders: BasketBuyOrder[];
  open?: boolean;
  onClose?: () => void;
}

export function UnAuthMiniBasket({
  buyOrders,
  open = false,
  onClose,
  ...props
}: UnAuthMiniBasketProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const basketSummary = buyOrders.reduce((acc, order) => acc + order.amount, 0);

  const getContents = () => {
    return buyOrders.length < 1 ? (
      <>
        <EmptyBasket>Basket empty</EmptyBasket>
        <ButtonsWrapper $stretchButtons>
          <CustomButtonV2
            href={generateDynamicPortfolioConstructionFundsPath({
              wrapperType: 'unknown',
            })}
          >
            Add funds
          </CustomButtonV2>
        </ButtonsWrapper>
      </>
    ) : (
      <>
        <MiniBasketItemWrapper>
          {buyOrders.map((order) => {
            return (
              <MiniBasketItem key={order.isin} $isDarkUniverse={false}>
                <MiniBasketItemTitle
                  $fontSize={FontSize.normal}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.left}
                >
                  {order.instrumentName}
                </MiniBasketItemTitle>
                <MiniBasketDetailsRow>
                  <MiniBasketIndicator $isDarkUniverse={false} $type="buy">
                    Buy
                  </MiniBasketIndicator>
                  <MiniBasketItemPrice $noMargin>
                    {format.currencyFull(order.amount)}
                  </MiniBasketItemPrice>
                </MiniBasketDetailsRow>
              </MiniBasketItem>
            );
          })}
        </MiniBasketItemWrapper>
        <MiniBasketSummaryWrapper $isSingle>
          <div>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.light}
              $noMargin
            >
              Basket balance
            </Text>
            <Text
              $fontSize={FontSize.normal}
              $fontWeight={FontWeight.medium}
              $noMargin
            >
              {format.currencyFull(basketSummary)}
            </Text>
          </div>
        </MiniBasketSummaryWrapper>
        <ButtonsWrapper $stretchButtons>
          <CustomButtonV2
            $color="secondary"
            href={generateDynamicPortfolioConstructionBasketPath({
              wrapperType: 'unknown',
            })}
          >
            Edit basket
          </CustomButtonV2>
        </ButtonsWrapper>
      </>
    );
  };

  return (
    <MiniBasketWrapper
      {...props}
      style={
        open
          ? {
              opacity: '1',
              transform: 'translateY(20px)',
              pointerEvents: 'auto',
            }
          : {}
      }
    >
      {isMobile && (
        <Typography
          variant="subtitle1"
          component="h1"
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: '1.25rem',
          }}
        >
          Your basket
        </Typography>
      )}
      {getContents()}
    </MiniBasketWrapper>
  );
}
