import { Container } from '@material-ui/core';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PortfolioBuilderContainer = styled(Container)`
  padding: 0 1.5rem;
  overflow: hidden;
`;

export const DecisionMakingContainer = styled(Container)`
  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 1rem 1rem 0 1rem;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding-top: 3.5rem;
    padding-bottom: 0;
  }
`;

// styled component grid layout 60/40
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40%;
  grid-gap: 1.5rem;
`;

// column container
export const Column = styled.div``;

export const NoMatchCopy = styled.div`
  > p {
    color: #8c8c8c;
  }
  text-align: center;
  padding-top: 5rem;
`;

export const ButtonErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  align-items: stretch;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: auto;
    align-items: flex-end;
  }
`;

export const ErrorMessage = styled(Text)`
  color: ${colors.danger};
`;

export const PreambleWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const ClosedAccountMessageWrapper = styled.div`
  padding: 3rem;
`;
