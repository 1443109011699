import { tillitFetch } from 'api/tillitFetch';
import TillitGraphQL from 'api/TillitGraphQL';
import { GaEventNames } from 'constants/gaConstants';
import {
  UserProfileDocument,
  UserProfileQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import {
  appSignInSignUpPath,
  generateAppSignInPath,
  openAccountPath,
} from 'paths';
import { useEffect, useState } from 'react';
import { QueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';

import { trackGa } from 'helpers/track';
import { LocalFeatureToggle, useLocalFeatureToggle } from './useFeatureToggle';

const AUTH_INDICATOR_COOKIE_NAME = process.env
  .REACT_APP_AUTH_INDICATOR_COOKIE_NAME!;
const INTERCOM_INDICATOR_COOKIE_NAME = process.env
  .REACT_APP_INTERCOM_INDICATOR_COOKIE_NAME!;

export interface AuthContext {
  signedIn: boolean;
  signIn: (returnPath: string, wasAutoSignedOut?: boolean) => void;
  getSignInPath: (
    returnPath?: string,
    wasAutoSignedOut?: boolean,
    referralCode?: string
  ) => string;
  signUp: () => void;
  signInOrSignUp: (returnPath?: string) => void;
  signOut: (source: string) => void;
  handleAutoSignout: () => void;
  refreshSession: () => Promise<void>;
}

let handlingAutoSignOut = false;

/**
 * **NOTE: You most likely want to use `useAuth` hook.**
 * @param queryClient react-query client
 * @returns the value of the Auth context provider
 */
export function useProvideAuth(queryClient: QueryClient): AuthContext {
  const { hardShutdown, boot } = useIntercom();
  const useBetterSignoutHandling = useLocalFeatureToggle(
    LocalFeatureToggle.betterSignoutHandling
  );
  const [signedIn, setSignedIn] = useState(
    Cookies.get(AUTH_INDICATOR_COOKIE_NAME) === 'true'
  );

  const getSignInPath = (
    returnPath?: string,
    wasAutoSignedOut?: boolean,
    referralCode?: string
  ) => {
    return generateAppSignInPath(returnPath, wasAutoSignedOut, referralCode);
  };

  const signIn = (returnPath: string, wasAutoSignedOut?: boolean) => {
    window.location.href = getSignInPath(returnPath, wasAutoSignedOut);
  };

  const signUp = () => {
    window.location.href = openAccountPath;
  };

  const signInOrSignUp = (returnPath?: string) => {
    window.location.href = appSignInSignUpPath;
  };

  const cleanupSession = () => {
    hardShutdown();
    mixpanel.reset();
    Cookies.remove(AUTH_INDICATOR_COOKIE_NAME, {
      domain: '.tillitinvest.com',
      sameSite: 'None',
      secure: true,
    });
    Cookies.remove('userYearOfBirth');
    Cookies.remove('userIntendedRetirementAge');
    localStorage.removeItem('tillit.mode');
  };

  const signOut = (source: string) => {
    return signOutV3(source);
  };

  const signOutV3 = async (source: string) => {
    try {
      trackGa({
        event: GaEventNames.signOut,
        item_id: `signed out - ${source}`,
      });
    } catch {}

    const resp = await tillitFetch('/logout', {
      method: 'POST',
    });
    if (resp.ok) {
      setSignedIn(false);
      cleanupSession();

      window.location.href = '/signed-out';
    }
  };

  const refreshSession = async () => {
    if (!signedIn) {
      return;
    }

    try {
      await tillitFetch('/identity/refresh-session', { method: 'POST' });
    } catch (e) {
      console.error('Failed to refresh session, ignoring', e);
    }
  };

  const handleAutoSignout = () => {
    if (handlingAutoSignOut) return;

    handlingAutoSignOut = true;

    // stop unauthenticated queries as soon as possible
    setSignedIn(false);

    // no need to call logout since the session is already expired
    try {
      trackGa({
        event: GaEventNames.signOut,
        item_id: `signed out - automatic`,
      });
    } catch {}

    cleanupSession();

    if (!useBetterSignoutHandling) {
      window.location.href = '/signed-out';
    } else {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('signedOut', 'true');
      window.location.href = newUrl.toString();
    }
  };

  useEffect(() => {
    const onAuthenticated = async () => {
      try {
        const data = await queryClient.fetchQuery(
          useUserProfileQuery.getKey(),
          () => TillitGraphQL.request<UserProfileQuery>(UserProfileDocument)
        );

        boot({
          name: `${data.userProfile!.first} ${data.userProfile!.last}`,
          email: data.userProfile!.emailAddresses![0]?.address,
          userId: data.userProfile!.id!,
          userHash: data.userProfile!.intercomHash,
          sessionDuration: 30 /* minutes... */ * 60 * 1000 /* in ms */,
        });

        Cookies.set(
          process.env.REACT_APP_INTERCOM_INDICATOR_COOKIE_NAME!,
          'true',
          {
            // If intercom session is not shutdown the cookie expires after one week
            // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app#how-to-end-a-session
            expires: 7,
            domain: '.tillitinvest.com',
            sameSite: 'Lax',
            secure: true,
          }
        );

        mixpanel.identify(data.userProfile!.id!);

        trackGa({
          event: GaEventNames.userIdEvent,
          userID: data.userProfile!.id!,
        });
      } catch {
        // Most likely session expired
      }
    };

    if (signedIn) {
      onAuthenticated();
    } else {
      // if user was previously signed in shutdown the session and initialize a new one
      // leaving this as a safeguard and to help when working locally
      if (Cookies.get(INTERCOM_INDICATOR_COOKIE_NAME) === 'true') {
        // clear intercom cookies, can't use shutdown since it's not booted yet
        Object.keys(Cookies.get()).forEach((cookieName) => {
          if (cookieName.startsWith('intercom-')) {
            Cookies.remove(cookieName, {
              domain: '.tillitinvest.com',
            });
          }
        });

        Cookies.remove(INTERCOM_INDICATOR_COOKIE_NAME, {
          domain: '.tillitinvest.com',
        });
      }

      boot({
        sessionDuration: 30 /* minutes... */ * 60 * 1000 /* in ms */,
      });
    }
  }, [signedIn, boot, queryClient]);

  return {
    signedIn,
    signIn,
    getSignInPath,
    signUp,
    signOut,
    signInOrSignUp,
    handleAutoSignout,
    refreshSession,
  };
}
