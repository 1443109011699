import { ContractNote } from 'components/ContractNote';
import { InfoPopover } from 'components/Popover';
import { QueryState } from 'components/QueryState';
import {
  ColumnDefinition,
  ResponsiveTable,
} from 'components/Tables/ResponsiveTable/ResponsiveTable';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import {
  Order,
  OrderDetailsQuery,
  OrderDirection,
  OrderStatus,
  useOrderDetailsQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useCallback, useMemo } from 'react';
import { HiDownload } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import { Download, Pill } from './ContractNotesTable.style';

type Note = Exclude<
  OrderDetailsQuery['clientSummary'],
  null | undefined
>['orders'][number];

export interface PureContractNotesTableProps {
  data: Array<Note>;
}

export function PureContractNotesTable({ data }: PureContractNotesTableProps) {
  const handleClick = (title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard - contract notes table',
      item_id: `download button clicked - ${title}`,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: '',
        key: 'contractNoteUrl',
        width: '24px',
        renderValue: (value: string, data: Note) =>
          data.status === OrderStatus.Completed ? (
            <Download
              {...{
                component: 'a',
                target: '_blank',
                href: value,
              }}
              size="small"
              onClick={() => handleClick(data.instrument?.name || 'unknown')}
            >
              <HiDownload />
            </Download>
          ) : (
            <InfoPopover
              color="primary"
              content={tooltips.OrderPending}
              size="small"
              disablePadding={false}
              disableMargin
            />
          ),
      },
      {
        name: 'Order details',
        key: 'instrument.name',
        align: 'left',
        renderValue: (instrumentName: string | undefined, row: Order) => {
          const fallback = row.custodianAssetName;
          return instrumentName === 'Unknown'
            ? fallback
            : instrumentName ?? fallback;
        },
      },
      {
        name: 'Account type',
        key: 'wrapperType',
        align: 'center',
        width: '15%',
      },
      {
        name: 'Order type',
        key: 'direction',
        align: 'center',
        width: '15%',
        renderValue: (direction: OrderDirection) => (
          <Pill direction={direction}>{direction}</Pill>
        ),
      },
      {
        name: 'Date',
        key: 'settlementDate',
        align: 'right',
        width: '15%',
        renderValue: (date?: string) => (date ? format.date(date) : ''),
      },
    ] as Array<ColumnDefinition<Note>>;
  }, []);

  const mobileComponent = useCallback(
    (row: Note) => (
      <ContractNote
        downloadURL={row?.contractNoteUrl ?? undefined}
        status={row.status}
        instrumentName={row.instrument?.name || ''}
        accountType={row.wrapperType}
        direction={row.direction}
        date={row.settlementDate}
      />
    ),
    []
  );

  return (
    <ResponsiveTable
      columns={columns}
      data={data}
      noDataMessage="You have no contract notes."
      renderMobile={mobileComponent}
    />
  );
}

export function ContractNotesTable() {
  const ordersQuery = useOrderDetailsQuery();

  return (
    <QueryState {...ordersQuery}>
      {() => (
        <PureContractNotesTable
          data={ordersQuery?.data?.clientSummary?.orders ?? []}
        />
      )}
    </QueryState>
  );
}
