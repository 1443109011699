import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  GoBackButton,
  StepActionsCar,
  StepActionsWithCar,
  StepButton,
  StepCheckboxesContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionLi,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { TransferMethod, WrapperType } from 'generated/graphql';
import {
  StartTransferType,
  TransferPensionDetails,
  useTransfer,
} from 'hooks/useTransferV2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { CedingProvider } from '../../types';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

const StepCheckboxesContainerWide = styled(StepCheckboxesContainer)`
  max-width: 50rem;
`;

const transferSchema = yup.object().shape({
  authorise: yup
    .boolean()
    .oneOf([true], 'Please check the authorise checkbox')
    .required('Please check the authorise checkbox'),
});

interface FormValues {
  authorise: boolean;
}

export type onProceedCallback = () => void;

export interface ConfirmCashTransferProps {
  wrapperType: WrapperType;
  onProceed: onProceedCallback;
  onGoBack: () => void;
  onError: () => void;
  cedingProvider: CedingProvider;
  cedingProviderAccountNumber: string;
  transferMethod: TransferMethod;
  pensionDetails?: TransferPensionDetails;
}

export function ConfirmCashTransfer({
  wrapperType,
  cedingProvider,
  onProceed,
  onGoBack,
  cedingProviderAccountNumber,
  transferMethod,
  pensionDetails,
  onError,
}: ConfirmCashTransferProps) {
  const { startTransfer, getStartTransferType } = useTransfer();
  const [submitting, setSubmitting] = useState(false);

  const startTransferType = getStartTransferType(wrapperType);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transferSchema),
    defaultValues: {
      authorise: false,
    },
  });

  const { register, handleSubmit, watch } = methods;
  const authorise = watch('authorise');

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true);

    try {
      await startTransfer({
        accountType: wrapperType,
        cedingProviderId: cedingProvider.id,
        cedingProviderAccountNumber: cedingProviderAccountNumber,
        method: transferMethod,
        pensionTransferDetails: pensionDetails,
      });
      onProceed();
    } catch {
      onError();
      setSubmitting(false);
    }
  };

  const cedingProviderName = cedingProvider.name;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.xxWide}>
          <StepTitle>What you should know</StepTitle>
          <ul>
            <StepIntroductionLi>
              {cedingProviderName} may charge dealing and/or exit fees.
            </StepIntroductionLi>
            <StepIntroductionLi>
              {cedingProviderName} will be in control of when your holdings are
              sold down to cash.
            </StepIntroductionLi>
            {wrapperType === WrapperType.Gia && (
              <StepIntroductionLi>
                You may incur Capital Gains Tax.
              </StepIntroductionLi>
            )}
          </ul>
          <StepIntroductionTypography $italic>
            Once your account has been transferred to TILLIT, you will need to
            login to make your investments.
          </StepIntroductionTypography>
        </StepContent>
        <StepCheckboxesContainerWide alignItems="center">
          <FormControlLabel
            htmlFor="authorise"
            control={
              <Checkbox id="authorise" name="authorise" inputRef={register} />
            }
            label={
              <>
                I authorise TILLIT to transfer my{' '}
                {wrapperNameFriendly(wrapperType)} as cash from{' '}
                {cedingProviderName}.
              </>
            }
          />
        </StepCheckboxesContainerWide>
        <StepActionsWithCar>
          <StepButton
            disabled={!authorise || submitting}
            type="submit"
            className="magenta"
          >
            {startTransferType === StartTransferType.duringOnboarding
              ? 'Continue'
              : 'Start Transfer'}
          </StepButton>
          <StepActionsCar>
            Capital at risk.{' '}
            {(wrapperType === WrapperType.Isa ||
              wrapperType === WrapperType.Sipp) &&
              'Tax treatment depends on an individual’s circumstances and may be subject to change.'}
          </StepActionsCar>
          <GoBackButton onClick={onGoBack} />
        </StepActionsWithCar>
      </form>
    </>
  );
}
