import React, { useMemo } from 'react';
import styled from 'styled-components';

interface IconProps {
  colour: string;
}

const Icon = styled.img<IconProps>`
  filter: url(#${(p) => p.colour});
`;

const KnownAssetClasses: Record<string, string> = {
  Equities: 'Equity',
  Bonds: 'Bond',
  Property: 'Property',
  'Alternative assets': 'Alternatives',
  // cspell:disable-next-line
  'Multi-asset': 'Multiasset',
};

interface AssetIconProps {
  name: string;
  forceSVG?: boolean;
  theme?: 'default' | 'light' | 'dark';
}

export function AssetIcon({ name, forceSVG = false, theme }: AssetIconProps) {
  const iconName = useMemo(
    () =>
      Object.keys(KnownAssetClasses).includes(name)
        ? KnownAssetClasses[name]
        : 'default',
    [name]
  );

  const currentIcon2x = useMemo(
    () => require(`assets/icons/${iconName}.svg`).default,
    [iconName]
  );

  const currentIcon = useMemo(
    () =>
      forceSVG
        ? currentIcon2x
        : require(`assets/icons/${iconName}.png`).default,
    [currentIcon2x, forceSVG, iconName]
  );

  if (name === undefined) {
    return null;
  }

  const safeName = name.replace(' ', '-');

  return (
    <Icon
      src={currentIcon}
      srcSet={`${currentIcon}, ${currentIcon2x} 2x`}
      colour={theme ? theme : safeName}
      alt={`${name} icon`}
    />
  );
}
