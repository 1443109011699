import { generateFundDetailsPath } from './appPaths';

// Special Paths:
export const signUpPath = '/register?ReturnPath=/open-account';
export const signInPath = '/login';
export const signInOrSignUpPath = '/auth/begin'; // this path will redirect the user to the sign in OR sign up page in identity.

export const signInPathCustomRedirect = (redirectPath: string) =>
  `${signInPath}?ReturnPath=${redirectPath}`;

export const signInOrSignUpPathCustomRedirect = (redirectPath: string) =>
  `${signInOrSignUpPath}?ReturnPath=${redirectPath}`;

const DEFAULT_PENSION_ASSET_ID = process.env
  .REACT_APP_DEFAULT_PENSION_TDF_INVESTMENT_ASSET_ID!;

export const defaultPensionAssetPath = generateFundDetailsPath({
  id: DEFAULT_PENSION_ASSET_ID,
  slug: 'blackrock-lifepath',
});
