const purpleTints = {
  'purple-25': '#EFECF6',
  'purple-50': '#EDE6FA',
  'purple-100': '#DBCDF6',
  'purple-200': '#C9B5F1',
  'purple-300': '#B59CED',
  'purple-400': '#A184E8',
  'purple-500': '#745ABA',
  'purple-600': '#5C4891',
  'purple-700': '#46376B',
  'purple-800': '#302647',
  'purple-900': '#1C1726',
};

const magentaTints = {
  'magenta-25': '#fef4ff',
  'magenta-50': '#F7E1FB',
  'magenta-100': '#EFC2F6',
  'magenta-200': '#E6A4F1',
  'magenta-300': '#DC84ED',
  'magenta-400': '#D15FE8',
  'magenta-500': '#A22EBA',
  'magenta-600': '#802991',
  'magenta-700': '#5F226B',
  'magenta-800': '#401C47',
  'magenta-900': '#231326',
};

export const mauveTints = {
  'mauve-50': '#f4e6f4',
  'mauve-100': '#e9cde9',
  'mauve-200': '#d29cd2',
  'mauve-300': '#bc6abc',
  'mauve-400': '#a539a5',
  'mauve-500': '#8f078f',
  'mauve-600': '#720672',
  'mauve-700': '#560456',
  'mauve-800': '#390339',
  'mauve-900': '#1d011d',
};

const seaBlueTints = {
  'seaBlue-50': '#ebeef5',
  'seaBlue-100': '#B0BDD7',
  'seaBlue-200': '#899BC4',
  'seaBlue-300': '#617AB0',
  'seaBlue-400': '#3A599C',
  'seaBlue-500': '#34508C',
  'seaBlue-600': '#293E6D',
  'seaBlue-700': '#1D2D4E',
  'seaBlue-800': '#0C121F',
  'seaBlue-900': '#060910',
};

const richBlue = {
  'richBlue-50': '#e7e8ed',
  'richBlue-100': '#d0d0db',
  'richBlue-200': '#a1a1b7',
  'richBlue-300': '#717292',
  'richBlue-400': '#42436e',
  'richBlue-500': '#13144a',
  'richBlue-600': '#111243',
  'richBlue-700': '#0d0e34',
  'richBlue-800': '#0a0a25',
  'richBlue-900': '#060616',
};

const greyTints = {
  'grey-50': '#f8f8f8',
  'grey-100': '#f5f5fa',
  'grey-200': '#eeeeee',
  'grey-300': '#e8e8e8',
  'grey-400': '#e0e0e0',
  'grey-500': '#dddddd',
  'grey-600': '#d8d8d8',
  'grey-700': '#cccccc',
  'grey-800': '#bbbbbb',
  'grey-900': '#666666',
};

const greenTints = {
  'green-50': '#e7faf5',
  'green-100': '#cef5ea',
  'green-200': '#9decd5',
  'green-300': '#6ce2c1',
  'green-400': '#3bd9ac',
  'green-500': '#0acf97',
  'green-600': '#08a679',
  'green-700': '#067c5b',
  'green-800': '#04533c',
  'green-900': '#02291e',
};

const redTints = {
  'red-25': '#fef6f5',
  'red-50': '#fcedeb',
  'red-100': '#fadbd7',
  'red-200': '#f5b6af',
  'red-300': '#ef9288',
  'red-400': '#ea6d60',
  'red-500': '#e54938',
  'red-600': '#b73a2d',
  'red-700': '#892c22',
  'red-800': '#5c1d16',
  'red-900': '#2e0f0b',
};

export const colors = {
  richBlack: '#161710',
  richBlue: '#13144a',
  darkBlue: '#211f3b',
  seaBlue: '#3a599c',
  purple: '#926beb',
  magenta: '#d716eb',
  magentaSafe: '#e221f6',
  mauve: '#8f078f',
  black: '#15160f',
  white: '#ffffff',
  vapor: '#f8f8f8',
  grey: '#f5f5fa',
  clouds: '#eeeeee',
  midGrey: '#e8e8e8',
  lightGrey: '#e0e0e0',
  darkGrey: '#d8d8d8',
  success: greenTints['green-500'],
  danger: redTints['red-500'],
  ...purpleTints,
  ...magentaTints,
  ...greyTints,
  ...mauveTints,
  ...seaBlueTints,
  ...richBlue,
  ...greenTints,
  ...redTints,
};

/**
 * assetClassColors
 *
 * The keys for this object are taken from the api's funds' asset class prop.
 */
export const assetClassColors = {
  Equities: colors.purple,
  Bonds: colors.richBlue,
  Property: colors.magentaSafe,
  'Alternative assets': colors.seaBlue,
  'Multi-asset': colors.mauve,
  Cash: colors['grey-300'],
};

/**
 * Color triads
 *
 * These color tints are used to create the color triads for the main brand colors
 *  and are meant to be used as complementary colors to the main brand colors.
 */
export const magentaTriad = {
  'triad-200': '#15EBB4',
  'triad-100': '#EBB515',
  'triad+100': '#8D3F96',
  'triad+200': '#3F6B60',
};

export const mauveTriad = {
  'triad-200': '#078F7A',
  'triad-100': '#8F7207',
  'triad+100': '#3A163A',
  'triad+200': '#8CFFEE',
};

export const seaBlueTriad = {
  'triad-200': '#799C3A',
  'triad-100': '#9C4A3A',
  'triad+100': '#323847',
  'triad+200': '#31332C',
};

export const purpleTriad = {
  'triad-200': '#6CEB7B',
  'triad-100': '#EBAB6C',
  'triad+100': '#807796',
  'triad+200': '#5A6B5C',
};
