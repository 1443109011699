import { useAuth } from 'context/AuthContext';
import {
  AccountType,
  OnboardingStatus,
  useOnboardingStatusQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import Cookies from 'js-cookie';
import { useHasActiveAccount } from './useAccountStatus';

const ONBOARDING_STATUS_COOKIE_NAME = 'is_onboarding';
const ONBOARDING_STATUS_COOKIE_EXPIRY_DAYS = 30;
const ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME = 'onboarding_account_type';
const ONBOARDING_ACCOUNT_TYPE_COOKIE_EXPIRY_DAYS = 30;

export function useOnboardingStatus() {
  const { signedIn } = useAuth();
  const userQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const hasActiveAccount = useHasActiveAccount();
  const onboardingStatusQuery = useOnboardingStatusQuery(undefined, {
    enabled: signedIn && hasActiveAccount,
  });

  const status =
    onboardingStatusQuery.data?.userProfile?.onboardingDetails?.status;

  const signedInStatus =
    status === OnboardingStatus.FurtherInformationNeeded ||
    (status === OnboardingStatus.SupportNeeded &&
      onboardingStatusQuery.data?.userProfile
        ?.pendingDocumentUploadRequestCount === 1);

  const onboardingStatus =
    signedInStatus || Cookies.get(ONBOARDING_STATUS_COOKIE_NAME) === 'true';
  const onboardingAccountType =
    userQuery.data?.userProfile?.initialAccountType ||
    Cookies.get(ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME);

  const setOnboardingStatus = (status: boolean) => {
    Cookies.set(ONBOARDING_STATUS_COOKIE_NAME, status.toString(), {
      expires: ONBOARDING_STATUS_COOKIE_EXPIRY_DAYS,
      domain: '.tillitinvest.com',
    });
  };

  const setOnboardingAccountType = (accountType: AccountType) => {
    Cookies.set(ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME, accountType, {
      expires: ONBOARDING_ACCOUNT_TYPE_COOKIE_EXPIRY_DAYS,
      domain: '.tillitinvest.com',
    });
  };

  const removeOnboardingAccountType = () => {
    Cookies.remove(ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME, {
      domain: '.tillitinvest.com',
    });
  };

  /**
   * This syncs the cookie with the account status, this covers the cases
   * where the user may have signed in on a different device
   */
  if (signedIn && onboardingStatus && onboardingAccountType) {
    setOnboardingStatus(signedInStatus);
    setOnboardingAccountType(onboardingAccountType as AccountType);
  } else if (signedIn && (!onboardingStatus || !onboardingStatus)) {
    setOnboardingStatus(false);
    removeOnboardingAccountType();
  }

  return {
    onboardingStatus,
    onboardingAccountType,
    setOnboardingStatus,
    setOnboardingAccountType,
    removeOnboardingAccountType,
  };
}
