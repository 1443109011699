import styled from 'styled-components';

export const DetailsRow = styled.div`
  width: 100%;
  margin-top: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0rem;
  }

  div[role='tabpanel'] {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const DetailsGridRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, auto);
    * {
      grid-column: unset;
      flex-direction: column;
    }
  }
`;

export const CostAndChargesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;

    > div {
      width: calc(50% - 1.5rem);
    }
  }
`;

export const CostAndChargesRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  margin-bottom: 1rem;
`;
