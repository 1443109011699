import styled from 'styled-components';

interface DidntReceiveCodeProps {
  isActive: boolean;
}
export const DidntReceiveCode = styled.div<DidntReceiveCodeProps>`
  transition: max-height 0.35s;
  max-height: ${({ isActive }) => (isActive ? '5rem' : '0')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
