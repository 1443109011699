import { colors } from 'constants/colors';
import styled from 'styled-components';
import { BottomShadow } from 'theme/shared/base.styles';

export const WarningContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: ${colors['magenta-25']};
  border: 1px solid ${colors['magenta-50']};
  ${BottomShadow};
`;

export const MoreInfoWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors['grey-200']};
  background-color: ${colors['grey-50']};
  padding: 1rem;

  label {
    font-weight: 300;
  }
`;
