import { useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { percent } from 'formatting';
import max from 'lodash/max';
import min from 'lodash/min';
import { Fragment, useState } from 'react';
import { TooltipLayout, TooltipText } from '../_shared/Breakdown.styles';
import { getBubbleColourForProportion } from '../_shared/colourHelper';
import { Breakdown, BreakdownComponentProps } from '../breakdownTypes';
import {
  BubbleContainer,
  BubbleContentLayout,
  BubbleText,
  BubbleValue,
  StyledBubble,
} from './BubbleBreakdown.styles';

export const BubbleBreakdown = ({ breakdown }: BreakdownComponentProps) => {
  const [tooltipData, setTooltipData] = useState<null | Breakdown>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));
  const treemapXyDimension = isLargeUp ? 1000 : window.innerWidth - 20;

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const svgArcOffset = isSmUp ? 5 : 2;

  const bubble = d3
    .pack()
    .size([treemapXyDimension, treemapXyDimension])
    .padding(10);

  const root = d3
    .hierarchy<Breakdown>(breakdown, ({ lines }) => lines)
    .sum((d) => (d.lines ? 0 : d.proportion))
    .sort((a, b) => b.data.proportion - a.data.proportion);

  const leaves = bubble(root).leaves() as d3.HierarchyCircularNode<Breakdown>[];

  const handleMouseOver = (
    _: any,
    leaf: d3.HierarchyCircularNode<Breakdown>
  ) => {
    setTooltipData(leaf.data);
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setTooltipData(null);
    setShowTooltip(false);
  };

  /**
   * Checks how many of the non-numeric characters in passed name are upper case.
   * If it's more than the threshold percentage, return true.
   */
  const isMostlyUppercase = (name: string) => {
    // Count the uppercase letters
    const uppercaseCount = (name.match(/[A-Z]/g) || []).length;
    const totalCharacters = name.replace(/[\s\d]/g, '').length;
    const percentageUppercase = (uppercaseCount / totalCharacters) * 100;

    // Threshold to determine if it's mostly uppercase
    const threshold = 60;

    return percentageUppercase >= threshold;
  };

  const deriveSvgTextMaxLength = (name: string) => {
    const mostlyUppercase = isMostlyUppercase(name);

    if (mostlyUppercase) {
      return isSmUp ? 24 : 12;
    } else {
      return isSmUp ? 28 : 15;
    }
  };

  // The pack bubble generates svg position that could square - but often it is not square - and
  // this leaves a large bit of white space above the diagram. This code finds the min top position
  // and max bottom position - and these numbers are then used to adjust the svg view port.
  const minTop: number =
    min(
      leaves.map((leaf, index) => {
        const centerY = leaf.y;
        const radius = leaf.r;
        return centerY - radius - 10;
      })
    ) || 0;
  const maxBottom =
    max(
      leaves.map((leaf, index) => {
        const centerY = leaf.y;
        const radius = leaf.r;
        return centerY + radius + 10;
      })
    ) || treemapXyDimension;
  const height = maxBottom - minTop;

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;

    const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());

    // Get SVG dimensions
    const svgRect = svg.getBoundingClientRect();
    const svgWidth = svgRect.width;
    const svgHeight = svgRect.height;

    // Adjust tooltip position based on distance from edges
    const tooltipHasDescription = !!tooltipData?.description;
    const xThreshold = tooltipHasDescription ? 270 : 170;
    const yThreshold = tooltipHasDescription ? 200 : 100;

    // Proposed tooltip position relative to SVG
    let tooltipX = svgPoint.x + 15;
    let tooltipY = svgPoint.y + 20 - minTop;
    const distanceFromRight = svgWidth - tooltipX;
    const distanceFromLeft = svgWidth - distanceFromRight;
    const distanceFromBottom = svgHeight - tooltipY;

    if (distanceFromRight < xThreshold) {
      if (isLargeUp || distanceFromLeft > xThreshold) {
        tooltipX -= xThreshold;
      } else {
        tooltipX = treemapXyDimension - xThreshold - 20;
      }
    }

    if (distanceFromBottom < yThreshold) {
      tooltipY -= yThreshold;
    }
    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };
  const minProportion =
    min(breakdown.lines?.map(({ proportion }) => proportion)) || 0;
  const maxProportion =
    max(breakdown.lines?.map(({ proportion }) => proportion)) || 1;

  const convertAbsoluteProportionToRelativeProportion = d3
    .scaleLinear()
    .domain([minProportion, maxProportion])
    .range([0, 1]);

  return (
    <BubbleContainer>
      <svg
        viewBox={`0 ${minTop} ${treemapXyDimension} ${height}`}
        height={height}
        width={treemapXyDimension}
        style={{ width: treemapXyDimension, overflow: 'visible' }}
        onMouseMove={(e) => handleMouseMove(e)}
      >
        {leaves.map((leaf, index) => {
          const textPathId = `text-path-${index}`;

          const startAngle = 270;
          const endAngle = 135;
          const startAngleRadians = ((startAngle - 45) * Math.PI) / 180;
          const endAngleRadians = ((endAngle - 90) * Math.PI) / 180;

          const startX =
            leaf.x + (leaf.r - svgArcOffset) * Math.cos(startAngleRadians);
          const startY =
            leaf.y + (leaf.r - svgArcOffset) * Math.sin(startAngleRadians);
          const endX =
            leaf.x + (leaf.r - svgArcOffset) * Math.cos(endAngleRadians);
          const endY =
            leaf.y + (leaf.r - svgArcOffset) * Math.sin(endAngleRadians);

          const maxTextLength = deriveSvgTextMaxLength(leaf.data.name);
          const gradientTransform =
            leaf.data.proportion > 0.5
              ? `translate(-0.3 -0.3) scale(1.35, 1.25)`
              : leaf.data.proportion > 0.1
              ? `translate(-0.25 -0.25) scale(1.3, 1.2)`
              : `translate(-0.2 -0.2) scale(1.25, 1.15)`;

          return (
            <Fragment key={leaf.data.name}>
              <defs>
                <radialGradient
                  id={`gradient-${index}`}
                  gradientTransform={gradientTransform}
                >
                  <stop
                    offset="8%"
                    stopColor={getBubbleColourForProportion(
                      convertAbsoluteProportionToRelativeProportion(
                        leaf.data.proportion
                      )
                    )}
                  />
                  <stop offset="120%" stopColor={colors.richBlue} />
                </radialGradient>
              </defs>
              <g>
                <StyledBubble
                  fill={'url(#gradient-' + index + ')'}
                  {...{
                    r: leaf.r,
                    cx: leaf.x,
                    cy: leaf.y,
                  }}
                  onMouseOver={(event) => handleMouseOver(event, leaf)}
                  onMouseOut={() => handleMouseOut()}
                  pointerEvents="visible"
                />

                <foreignObject
                  height={leaf.r * 2}
                  width={leaf.r * 2}
                  x={leaf.x - leaf.r}
                  y={leaf.y - leaf.r}
                  alignmentBaseline="central"
                  style={{
                    borderRadius: '50%',
                  }}
                  pointerEvents="none"
                >
                  <BubbleContentLayout>
                    <BubbleValue
                      $bubbleWidth={leaf.r * 2}
                      $proportion={leaf.data.proportion}
                    >
                      {percent(leaf.data.proportion)}
                    </BubbleValue>
                  </BubbleContentLayout>
                </foreignObject>
                {leaf.r * 2 > 65 && (
                  <>
                    <BubbleText
                      $bubbleWidth={leaf.r * 2}
                      letterSpacing="1.5"
                      dy="0.8em"
                      fill={colors.white}
                    >
                      <textPath xlinkHref={`#${textPathId}`}>
                        {leaf.data.name.length >= maxTextLength
                          ? `${leaf.data.name.substring(0, maxTextLength)}...`
                          : leaf.data.name}
                      </textPath>
                    </BubbleText>
                    <defs>
                      <path
                        id={textPathId}
                        d={`M ${startX},${startY} A ${leaf.r - svgArcOffset},${
                          leaf.r - svgArcOffset
                        } 0 0 1 ${endX},${endY}`}
                      />
                    </defs>
                  </>
                )}
              </g>
            </Fragment>
          );
        })}
      </svg>
      {showTooltip && tooltipData && (
        <div
          style={{
            position: 'absolute',
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            pointerEvents: 'none',
          }}
        >
          <TooltipLayout $hasDescription={!!tooltipData.description}>
            <TooltipText>
              <b>{tooltipData.name}</b>
            </TooltipText>
            <TooltipText>{percent(tooltipData.proportion)}</TooltipText>
            {tooltipData.description && (
              <TooltipText>{tooltipData.description}</TooltipText>
            )}
          </TooltipLayout>
        </div>
      )}
    </BubbleContainer>
  );
};
