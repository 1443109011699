import { StylesProvider } from '@material-ui/core';
import { CombinedThemeProvider } from 'components/CombinedThemeProvider';
import { TargetDateInfoReturnContextProvider } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { ModeContextProvider } from 'components/feature/mode/useMode';
import { AuthProvider } from 'context/AuthContext';
import { IntercomContext } from 'context/Intercom';
import { ToastProvider } from 'context/ToastContext';
import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { muiTheme } from 'theme';

interface ProvidersProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

export function Providers({ children }: ProvidersProps) {
  const [isIntercomOpen, setIsIntercomOpen] = useState<boolean>(false);
  const handleIntercomOnShow = () => setIsIntercomOpen(true);
  const handleIntercomOnHide = () => setIsIntercomOpen(false);
  return (
    <HelmetProvider>
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID!}
        onShow={handleIntercomOnShow}
        onHide={handleIntercomOnHide}
      >
        <IntercomContext.Provider value={{ open: isIntercomOpen }}>
          <StylesProvider injectFirst>
            <CombinedThemeProvider theme={muiTheme}>
              <ToastProvider>
                <AuthProvider queryClient={queryClient}>
                  <QueryClientProvider client={queryClient}>
                    <ModeContextProvider>
                      <TargetDateInfoReturnContextProvider>
                        <Router>{children}</Router>
                      </TargetDateInfoReturnContextProvider>
                    </ModeContextProvider>
                  </QueryClientProvider>
                </AuthProvider>
              </ToastProvider>
            </CombinedThemeProvider>
          </StylesProvider>
        </IntercomContext.Provider>
      </IntercomProvider>
    </HelmetProvider>
  );
}
