import {
  EmailVerifyStep,
  FormLocation,
} from 'components/feature/openAccount/steps/EmailVerifyStep/EmailVerifyStep';
import { useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import {
  EmailPasswordFormValues,
  EmailPasswordStep,
  LoginApiResBody,
} from './steps/EmailPasswordStep/EmailPasswordStep';
import { MfaStep } from './steps/MfaStep/MfaStep';

enum MultistepFormSteps {
  EmailPasswordStep = 'EmailPasswordStep',
  EmailVerify = 'EmailVerify',
  MfaStep = 'MfaStep',
}

interface PreSignInFormProps {
  onProceed: (
    emailPasswordFormValues: EmailPasswordFormValues,
    loginApiResBody: LoginApiResBody
  ) => void;
}

export function PreSignInForm({ onProceed }: PreSignInFormProps) {
  const [, setLastLoginTimestampIsoString] = useSessionStorage<string | null>(
    'lastLoginTimestamp',
    null
  );

  const [activeStep, setActiveStep] = useState<MultistepFormSteps | undefined>(
    MultistepFormSteps.EmailPasswordStep
  );
  const [emailPasswordFormValues, setEmailPasswordFormValues] = useState<
    EmailPasswordFormValues | undefined
  >();
  const [loginResultState, setLoginResultState] = useState<
    LoginApiResBody | undefined
  >();

  return (
    <Wrapper>
      {activeStep === MultistepFormSteps.EmailPasswordStep && (
        <EmailPasswordStep
          onProceed={async (values, loginResult) => {
            setLastLoginTimestampIsoString(new Date().toISOString());
            setEmailPasswordFormValues(values);
            setLoginResultState(loginResult);

            if (loginResult.requireEmailVerify) {
              setActiveStep(MultistepFormSteps.EmailVerify);
            } else if (loginResult.requireMfa) {
              setActiveStep(MultistepFormSteps.MfaStep);
            } else {
              onProceed(values, loginResult);
            }
          }}
        />
      )}

      {activeStep === MultistepFormSteps.EmailVerify &&
        loginResultState &&
        emailPasswordFormValues && (
          <EmailVerifyStep
            email={emailPasswordFormValues.username}
            password={emailPasswordFormValues.password}
            formLocation={FormLocation.SignIn}
            onProceed={() => {
              onProceed(emailPasswordFormValues, loginResultState);
            }}
          />
        )}

      {activeStep === MultistepFormSteps.MfaStep &&
        loginResultState &&
        emailPasswordFormValues && (
          <MfaStep
            emailPasswordFormValues={emailPasswordFormValues}
            defaultLoginResult={loginResultState}
            onProceed={(success) => {
              if (success) {
                onProceed(emailPasswordFormValues, loginResultState);
              }
            }}
          />
        )}
    </Wrapper>
  );
}
