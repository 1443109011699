import { Link } from '@material-ui/core';
import {
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  AffirmationKind,
  WrapperType,
  useAccountsQuery,
  useCreateAnotherAccountMutation,
  useMakeAffirmationMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { useIsaRuleset } from 'hooks/useIsaRuleset';
import { useQueryClient } from 'react-query';

export interface ConfirmationStepProps {
  accountType: WrapperType;
  onCancel: () => void;
  onClose: () => void;
  onProceed: () => void;
  onError: () => void;
  madeAffirmations: AffirmationKind[];
}

export function ConfirmationStep({
  accountType,
  onCancel,
  onClose,
  onProceed,
  onError,
  madeAffirmations,
}: ConfirmationStepProps) {
  const title =
    accountType === WrapperType.Isa ? 'Stocks & Shares ISA' : accountType;

  const { kfd } = useIsaRuleset();

  const queryClient = useQueryClient();

  const {
    mutateAsync: createAnotherAccount,
    isLoading: profileMutating,
  } = useCreateAnotherAccountMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
      queryClient.invalidateQueries(useAccountsQuery.getKey());
    },
  });

  const {
    mutateAsync: makeAffirmation,
    isLoading: affirmationsMutating,
  } = useMakeAffirmationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
    },
  });

  const handleConfirmation = async () => {
    try {
      if (accountType === WrapperType.Isa) {
        await makeAffirmation({
          input: {
            affirmations: madeAffirmations,
          },
        });
      }
      await createAnotherAccount({ input: { wrapperType: accountType } });
      onProceed();
    } catch {
      onError();
    }
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>You are about to open a {title}</StepTitle>

        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            If you proceed, your new investment account will be opened and you
            will be able to add cash and start investing straight away.
          </StepIntroductionTypography>
          {accountType === WrapperType.Isa && (
            <StepIntroductionTypography>
              <Link href={kfd} target="_blank">
                Read the Key Features Document for this account
              </Link>
            </StepIntroductionTypography>
          )}
        </StepIntroduction>
      </StepContent>

      <StepActions>
        <StepButton
          className="magenta"
          onClick={handleConfirmation}
          disabled={affirmationsMutating || profileMutating}
        >
          Open account
        </StepButton>
        <SkipButton
          variant="outlined"
          className="richBlue"
          onClick={onCancel}
          disabled={affirmationsMutating || profileMutating}
        >
          Cancel
        </SkipButton>
      </StepActions>
    </StepContainer>
  );
}
