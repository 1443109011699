import { CopyToClipboardButton } from 'components/Button/CopyToClipboardButton';
import { InfoPopover } from 'components/Popover/InfoPopover/InfoPopover';
import { currencyFull } from 'formatting';
import { CashDepositTransferDetails } from 'generated/graphql';
import * as tooltips from 'strings/tooltips';
import { MonoDd } from 'components/design-system/Text/Text';
import { DetailsList, SourceAccountWarning } from './styles/Deposit.styles';

export interface PaymentSummaryProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  copyEnabled?: boolean;
  showSourceWarning?: boolean;
}

export function PaymentSummary({
  amount,
  transferDetails,
  copyEnabled = true,
  showSourceWarning = true,
}: PaymentSummaryProps) {
  const {
    payeeName,
    payeeBankName,
    payeeAccountNumber,
    payeeSortCode,
    paymentReference,
    sourceBankLogoUrl,
    sourceAccountNumberMasked,
    sourceSortCodeMasked,
  } = transferDetails;
  return (
    <>
      <DetailsList>
        <dt>Amount</dt>
        <dd>{currencyFull(amount)}</dd>

        <dt>
          Payee name <InfoPopover content={tooltips.PayeeName} />
        </dt>
        <dd>
          {payeeName}{' '}
          {copyEnabled && <CopyToClipboardButton text={payeeName} />}
        </dd>

        <dt>Bank</dt>
        <dd>{payeeBankName}</dd>

        <dt>Account number</dt>
        <MonoDd>
          {payeeAccountNumber}{' '}
          {copyEnabled && <CopyToClipboardButton text={payeeAccountNumber} />}
        </MonoDd>

        <dt>Sort code</dt>
        <MonoDd>
          {payeeSortCode}{' '}
          {copyEnabled && <CopyToClipboardButton text={payeeSortCode} />}
        </MonoDd>

        <dt>Payment reference</dt>
        <MonoDd>
          {paymentReference}{' '}
          {copyEnabled && <CopyToClipboardButton text={paymentReference} />}
        </MonoDd>

        {showSourceWarning && (
          <SourceAccountWarning>
            <strong>Important:</strong> The deposit must be made from your
            nominated bank account:
          </SourceAccountWarning>
        )}

        {sourceBankLogoUrl && (
          <div
            style={{
              gridColumn: '1 / 3',
              textAlign: 'center',
            }}
          >
            <img
              style={{
                height: '75px',
                //border: '1px solid black',
                borderRadius: '15px',
              }}
              src={sourceBankLogoUrl}
              alt="Bank logo"
            />
          </div>
        )}

        <dt>Account number</dt>
        <MonoDd>{sourceAccountNumberMasked}</MonoDd>

        <dt>Sort code</dt>
        <MonoDd>{sourceSortCodeMasked}</MonoDd>
      </DetailsList>
    </>
  );
}
