import vjs, { VideoJsPlayerOptions } from '@tillitinvest/video.js';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { useAuth } from 'context/AuthContext';
import { AssetQuery } from 'generated/graphql';
import React from 'react';
import { WantToSeeMore } from './Overlays/WantToSeeMore';
import { Overlay, VideoWrapper } from './Styles/FundDetails.style';

type Video = Exclude<AssetQuery['asset'], undefined | null>['meetManagerVideo'];

interface MeetManagerVideoProps {
  meetManagerVideo: Video;
  description: string;
  signedIn: boolean;
  hasActiveAccount: boolean;
}

// TODO: Maybe do this via module augmentation instead of introducing a 'fake'
// new type?
interface CredentialledSourceObject extends vjs.Tech.SourceObject {
  withCredentials?: boolean;
}

export function MeetManagerVideo({
  meetManagerVideo,
  description,
  signedIn,
  hasActiveAccount,
}: MeetManagerVideoProps) {
  const videoOptions: VideoJsPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    html5: {
      preloadTextTracks: false,
      nativeTextTracks: false,
    },
    poster: meetManagerVideo?.thumbnailUrl || undefined,
  };

  if (signedIn && hasActiveAccount) {
    videoOptions.sources = [
      {
        src: meetManagerVideo?.videoUrl!,
        withCredentials: true,
      } as CredentialledSourceObject,
    ];

    if (meetManagerVideo?.subtitlesUrl) {
      videoOptions.tracks = [
        {
          src: meetManagerVideo.subtitlesUrl,
          kind: 'subtitles',
          srclang: 'en',
          label: 'English',
        },
      ];
    }
  }

  return (
    <VideoWrapper
      isLarge={true}
      marginTop={true}
      hasChapters={
        (meetManagerVideo?.chapters &&
          meetManagerVideo?.chapters?.length > 0) ||
        false
      }
    >
      <VideoPlayer
        videoJsOptions={videoOptions}
        video={description}
        videoType="Fund Details - Meet the Manager"
        requiresAuthentication={true}
        BeforeStartCTA={
          <VideoOverlay
            hasActiveAccount={hasActiveAccount}
            hasChapters={
              (meetManagerVideo?.chapters &&
                meetManagerVideo?.chapters?.length > 0) ||
              false
            }
          />
        }
        chapters={meetManagerVideo?.chapters || []}
      />
    </VideoWrapper>
  );
}

interface VideoOverlayProps {
  hasActiveAccount: boolean;
  hasChapters: boolean;
}
function VideoOverlay({ hasActiveAccount, hasChapters }: VideoOverlayProps) {
  const { signedIn } = useAuth();

  if (signedIn && hasActiveAccount) {
    return null;
  }

  return (
    <Overlay isLarge={!hasChapters}>
      <WantToSeeMore isFullHeight={true} />
    </Overlay>
  );
}
