import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  getSellEstimate,
  getSellEstimateUnits,
} from 'components/Dialogs/Trading/helpers';
import { InstrumentSelectorSelect } from 'components/InstrumentSelectorSelect/InstrumentSelectorSelect';
import { QueryState } from 'components/QueryState';
import { Severity } from 'components/design-system/Alert/Alert';
import { H4 } from 'components/design-system/Heading/Heading';
import { AmountInput } from 'components/design-system/Inputs/AmountInput/AmountInput';
import {
  GoBackButton,
  StepButton,
} from 'components/design-system/StepComponents/StepComponents';
import { ExistingPosition } from 'components/feature/PortfolioBuilder/ImportantInformation/_shared/ImportantInformation.types';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { useToast } from 'context/ToastContext';
import * as format from 'formatting';
import {
  WrapperType,
  useAccountsQuery,
  useCreateQuickOrderMutation,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useSellOrderDetailsByAccountQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { trackGa, trackGaClearEcommerce } from 'helpers/track';
import { amount } from 'helpers/yupExtensions';
import { generateFundDetailsPath } from 'paths';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccountPositionInstrument,
  AccountsQueryAccountPositionInstrumentAsset,
  AnyAsset,
  AssetQueryAsset,
} from 'types/graphqlTypes';
import * as yup from 'yup';
import Reference from 'yup/lib/Reference';
import { ImportantSellInformation } from '../../ImportantInformation/ImportantSellInformation';
import { ShareClassInfoPopover } from '../../_shared/ShareClassInfoPopover';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import {
  AmountInputContainer,
  ButtonContainer,
  ErrorMessage,
  FundDoLink,
  KeyValue,
  PendingOrdersAlert,
  Pill,
  PillContainer,
  Section,
  ShareClassWrapper,
} from '../AddToBasketDialog.style';

export enum OnProceedAction {
  quickOrder = 'quickOrder',
  addToBasket = 'addToBasket',
}

interface createQuickOrderMutationProps {
  accountId: string;
  isin: string;
  enteredAmount: number;
  quantity: number;
  quotedBid: number;
  quotedBidDateUtc: string;
}
const useStartQuickSellOrder = () => {
  const queryClient = useQueryClient();
  const createQuickOrderQuery = useCreateQuickOrderMutation();
  const createQuickOrderMutation = async ({
    accountId,
    enteredAmount,
    isin,
    quantity,
    quotedBid,
    quotedBidDateUtc,
  }: createQuickOrderMutationProps) => {
    const createQuickOrderResult = await createQuickOrderQuery.mutateAsync({
      input: {
        accountId: accountId,
        buyOrders: [],
        sellOrders: [
          {
            isin,
            enteredAmount,
            quantity,
            quotedBid,
            quotedBidDateUtc,
          },
        ],
      },
    });
    queryClient.removeQueries({
      queryKey: usePortfolioRebalancingQuery.getKey({
        id: createQuickOrderResult.createQuickOrder.id,
      }),
    });
    return createQuickOrderResult;
  };
  return {
    createQuickOrderQuery,
    createQuickOrderMutation,
  };
};

const addSellOrderToBasketSchema = yup.object().shape({
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .nullable()
    .max(yup.ref('$maxAmount') as Reference<number>)
    .min(yup.ref('$minAmount') as Reference<number>),
});

export interface AddSellOrderToBasketStep {
  onProceed: (action: OnProceedAction, orderId?: string) => void;
  onGoBack?: () => void;
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
  asset: AnyAsset;
  selectedPosition?: ExistingPosition;
  flow: 'fullFlow' | 'addToBasketOnlyFlow';
}

function isSearchAssetsQueryAsset(
  asset: AnyAsset
): asset is AssetQueryAsset | AccountsQueryAccountPositionInstrumentAsset {
  if ('isDarkUniverse' in asset) {
    return true;
  }
  return false;
}

export interface DepositFormValues
  extends yup.Asserts<typeof addSellOrderToBasketSchema> {}

export interface DepositFormInputs {
  amount: number;
  sellAll: boolean;
}

export function AddSellsToBasketStep({
  onProceed,
  onGoBack,
  selectedAccountId,
  selectedAccountType,
  asset,
  selectedPosition,
  flow,
}: AddSellOrderToBasketStep) {
  const [, setMode] = useMode();
  const [hasError, setHasError] = useState(false);
  const {
    createQuickOrderQuery,
    createQuickOrderMutation,
  } = useStartQuickSellOrder();
  const queryClient = useQueryClient();

  const { openToast } = useToast();

  const [activePosition, setActivePosition] = useState<
    ExistingPosition | undefined
  >(selectedPosition);

  const accountsQuery = useAccountsQuery();

  const selectedAccount = accountsQuery?.data?.accounts?.find(
    (acc) => acc.id === selectedAccountId
  );

  const assetPositions = selectedAccount?.positions.filter(
    (p) => p?.instrument?.asset?.id === asset?.id
  );

  const {
    addSellOrder,
    updateSellOrder,
    getSellOrder,
    portfolioRebalancingsQuery,
  } = useFundsBasket({
    selectedAccountId,
  });

  const sellOrderDetailsByAccountQuery = useSellOrderDetailsByAccountQuery(
    {
      id: selectedAccountId as string, // this query is disabled if selectedAccountId is undefined
      isin: activePosition?.isin!,
    },
    { enabled: !!activePosition && !!selectedAccountId }
  );

  const activeInstrument = activePosition?.instrument;
  const activeIsin = activeInstrument?.isin || '';
  const pendingSell = activePosition?.pendingSellAmount;

  const existingSellOrder = getSellOrder(activeIsin);
  const defaultValues: PartialDeep<DepositFormInputs> = {
    amount: existingSellOrder?.amount ?? null!,
    sellAll: false,
  };

  const sellRange =
    sellOrderDetailsByAccountQuery.data?.sellOrderDetailsByAccount?.validRange
      .quantityRange;

  const maxAmount =
    activePosition &&
    getSellEstimate(activeInstrument!, sellRange?.maximumQuantity || 0).toFixed(
      2
    );

  const minAmount =
    activePosition &&
    getSellEstimate(activeInstrument!, sellRange?.minimumQuantity || 0).toFixed(
      2
    );

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
  } = useForm<DepositFormInputs>({
    defaultValues,
    resolver: yupResolver(addSellOrderToBasketSchema),
    context: { maxAmount, minAmount },
  });
  const amountWatch = watch('amount');
  const sellAll = watch('sellAll');

  const watchAmountNumber =
    typeof amountWatch === 'string' ? parseFloat(amountWatch) : amountWatch;

  const handleInvalidation = () => {
    //invalidate query for the mini basket so all data updates
    queryClient.invalidateQueries(usePortfolioRebalancingsQuery.getKey());
  };

  useEffect(() => {
    if (
      existingSellOrder?.units &&
      sellRange?.maximumQuantity &&
      existingSellOrder?.units === sellRange?.maximumQuantity
    ) {
      setValue('sellAll', true);
      setValue('amount', maxAmount);
    }
  }, [
    existingSellOrder?.units,
    maxAmount,
    sellRange?.maximumQuantity,
    setValue,
  ]);

  const onSubmit = async (data: any) => {
    if (!activeInstrument || !activeInstrument?.bidPrice) {
      return;
    }
    setHasError(false);

    const amount = parseFloat(data.amount);

    let units = amount ? getSellEstimateUnits(activeInstrument, amount) : 0;
    if (data.sellAll) {
      units = sellRange?.maximumQuantity ?? 0;
    }

    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];

    if (selectedAccountType) {
      setMode({
        wrapperType: selectedAccountType,
        mode: 'buy',
      });
    }

    try {
      if (existingSellOrder) {
        const updatedSellOrder = {
          ...existingSellOrder,
          isin: activeIsin,
          amount,
          units,
          quotedBid: activeInstrument?.bidPrice,
          quotedBidDateUtc: activeInstrument.priceDate,
        };
        await updateSellOrder(updatedSellOrder);
        flow === 'fullFlow' && openToast('Sell order has been updated');
      } else {
        trackGaClearEcommerce();
        trackGa({
          event: GaEventNames.addToCart,
          orderType: 'rebalancing',
          ecommerce: {
            items: [
              {
                item_id: asset!.id,
                item_name: asset!.name,
                affiliation: 'Tillit',
                currency: 'GBP',
                item_brand: asset!.assetManager?.name,
                item_category: tags[0],
                item_category2: tags[1],
                item_category3: tags[2],
                item_category4: tags[3],
                item_category5: tags[4],
                item_variant: selectedAccountType,
                price: amount,
                quantity: Math.round(units * 100) / 100,
              },
            ],
          },
        });

        await addSellOrder({
          type: 'sellOrder',
          id: asset?.id!,
          slug: asset?.slug!,
          name: asset?.name || '',
          instrumentName: activeInstrument?.name || '',
          instrumentType: activeInstrument?.instrumentType || '',
          amount,
          assetClass: asset?.assetClass?.name || '',
          description: asset?.description || '',
          fundName: asset?.name || '',
          isin: activeIsin,
          units,
          quotedBid: activeInstrument?.bidPrice,
          quotedBidDateUtc: activeInstrument.priceDate,
          isDarkUniverse: isSearchAssetsQueryAsset(asset)
            ? asset?.isDarkUniverse ?? false
            : false,
        });

        flow === 'fullFlow' &&
          openToast('Sell order has been added to your basket');
      }

      onProceed(OnProceedAction.addToBasket);
    } catch {
      setHasError(true);
    } finally {
      handleInvalidation();
    }
  };

  const onQuickOrderSubmit = async (data: any) => {
    if (
      !activeInstrument ||
      !activeInstrument?.isin ||
      !activeInstrument?.bidPrice ||
      !selectedAccountId
    ) {
      return;
    }

    const amount = parseFloat(data.amount);
    const isin = activeInstrument.isin;
    let units = amount ? getSellEstimateUnits(activeInstrument, amount) : 0;

    if (data.sellAll) {
      units = sellRange?.maximumQuantity ?? 0;
    }

    try {
      const createQuickOrderResult = await createQuickOrderMutation({
        accountId: selectedAccountId,
        isin,
        enteredAmount: amount,
        quantity: units,
        quotedBid: activeInstrument?.bidPrice,
        quotedBidDateUtc: new Date(activeInstrument.priceDate).toISOString(),
      });
      const orderId = createQuickOrderResult.createQuickOrder.id;

      onProceed(OnProceedAction.quickOrder, orderId);
    } catch {
      // error handled by query state
    }
  };

  useEffect(() => {
    if (
      accountsQuery.isFetched &&
      !activePosition &&
      assetPositions?.length &&
      assetPositions[0].isin
    ) {
      setActivePosition(assetPositions[0]);

      const existingPositionSellOrder = getSellOrder(assetPositions[0].isin);
      if (existingPositionSellOrder?.amount) {
        setValue('amount', existingPositionSellOrder?.amount);
      }
    }
  }, [
    accountsQuery.isFetched,
    setActivePosition,
    activePosition,
    setValue,
    assetPositions,
    getSellOrder,
  ]);

  const handleSellAllChange = (checked: boolean) => {
    if (checked) {
      setValue('amount', maxAmount, { shouldValidate: true });
    } else {
      setValue('amount', null, { shouldValidate: false });
    }
  };

  const unitTypeLabel =
    activeInstrument?.instrumentType === 'Fund' ? 'units' : 'shares';

  const hasPendingSellOrder = pendingSell && pendingSell > 0;
  const pendingSellOrderClearsPosition =
    hasPendingSellOrder &&
    activePosition?.quantity &&
    pendingSell >= activePosition.quantity;

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {({ data }) => {
        return (
          <QueryState {...accountsQuery}>
            {({ data }) => {
              const instrumentSelector = assetPositions &&
                assetPositions.length > 1 && (
                  <ShareClassWrapper>
                    <InstrumentSelectorSelect
                      instruments={assetPositions
                        .map(({ instrument }) => instrument)
                        .filter(
                          (
                            instrument
                          ): instrument is AccountsQueryAccountPositionInstrument =>
                            !!instrument
                        )}
                      onChange={(updatedValue) => {
                        const selectedPosition = assetPositions.find(
                          (position) => position?.isin === updatedValue
                        );
                        setActivePosition(selectedPosition);
                        const positionSellOrder = getSellOrder(
                          selectedPosition?.isin!
                        );
                        if (positionSellOrder?.amount) {
                          setValue('amount', positionSellOrder.amount);
                        } else {
                          setValue('amount', '');
                        }
                      }}
                      value={activePosition?.isin || undefined}
                      useShortName
                    />
                    <ShareClassInfoPopover color="primary" />
                  </ShareClassWrapper>
                );
              return (
                <QueryState {...sellOrderDetailsByAccountQuery}>
                  {({ data }) => (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <PillContainer>
                        <Pill $filled $color="sell">
                          SELL
                        </Pill>
                        <Pill>
                          {getShortNameForWrapperType(selectedAccountType)}
                        </Pill>
                      </PillContainer>
                      <H4>{activeInstrument?.name}</H4>
                      <FundDoLink
                        to={{
                          pathname: generateFundDetailsPath({
                            id: asset?.id!,
                            slug: asset?.slug!,
                          }),
                          state: {
                            shouldGoBack: true,
                          },
                        }}
                        target="_blank"
                        // onClick={() => trackFundOpen('link', asset, index)}
                      >
                        What does this fund do? <HiExternalLink />
                      </FundDoLink>

                      {pendingSellOrderClearsPosition && (
                        <>
                          <PendingOrdersAlert severity={Severity.error}>
                            You cannot start a new sell order against this
                            position because you already have pending sales that
                            will clear it completely once they complete.
                          </PendingOrdersAlert>
                          {!!instrumentSelector && (
                            <Section>{instrumentSelector}</Section>
                          )}
                        </>
                      )}

                      {!pendingSellOrderClearsPosition && (
                        <>
                          {hasPendingSellOrder && (
                            <PendingOrdersAlert severity={Severity.error}>
                              You currently have unsettled orders against this
                              asset. Did you intend to create a new order?
                            </PendingOrdersAlert>
                          )}
                          <Section>
                            {instrumentSelector}
                            <>
                              <AmountInputContainer>
                                <Controller
                                  name="sellAll"
                                  control={control}
                                  render={({
                                    value,
                                    onChange,
                                    onBlur,
                                    ref,
                                  }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onBlur={onBlur}
                                          onChange={(e) => {
                                            onChange(e.target.checked);
                                            handleSellAllChange(
                                              e.target.checked
                                            );
                                          }}
                                          inputRef={ref}
                                          checked={value}
                                        />
                                      }
                                      label="Sell entire holding"
                                    />
                                  )}
                                />
                                <AmountInput
                                  autoFocus
                                  maxLength={8}
                                  id="amount"
                                  name="amount"
                                  readOnly={sellAll}
                                  required
                                  ref={register}
                                />
                              </AmountInputContainer>
                              {errors.amount && (
                                <ErrorMessage>
                                  {errors.amount.message}
                                </ErrorMessage>
                              )}

                              {maxAmount && (
                                <KeyValue
                                  label={
                                    pendingSell && pendingSell > 0
                                      ? `Available ${unitTypeLabel} est. sell valuation`
                                      : `Current est. sell valuation`
                                  }
                                  value={`${format.currencyFull(maxAmount)}`}
                                />
                              )}
                              {sellRange?.maximumQuantity && (
                                <KeyValue
                                  label={
                                    pendingSell && pendingSell > 0
                                      ? `Available ${unitTypeLabel}`
                                      : `Current ${unitTypeLabel}`
                                  }
                                  value={`${sellRange.maximumQuantity}`}
                                />
                              )}
                            </>
                          </Section>

                          {activeInstrument && activePosition && (
                            <ImportantSellInformation
                              existingPosition={activePosition}
                              selectedInstrument={activeInstrument}
                              amountEntered={watchAmountNumber}
                            />
                          )}
                          <ButtonContainer>
                            <StepButton className="magenta" type="submit">
                              {existingSellOrder
                                ? 'Update basket'
                                : 'Add to basket'}
                            </StepButton>
                            {hasError && <div>Something went wrong</div>}
                            {flow === 'fullFlow' && (
                              <>
                                <StepButton
                                  className="richBlue"
                                  type="button"
                                  onClick={handleSubmit(onQuickOrderSubmit)}
                                >
                                  Place Order
                                </StepButton>
                                {createQuickOrderQuery.error && (
                                  <div>Something went wrong</div>
                                )}
                              </>
                            )}

                            {flow === 'fullFlow' && onGoBack && (
                              <GoBackButton onClick={onGoBack} />
                            )}
                          </ButtonContainer>
                        </>
                      )}
                    </form>
                  )}
                </QueryState>
              );
            }}
          </QueryState>
        );
      }}
    </QueryState>
  );
}
