import {
  ClickAwayListener,
  Fade,
  IconButtonProps,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Arrow, StyledIconButton, StyledPopper } from './InfoPopover.styles';

export interface InfoPopoverProps {
  content: React.ReactNode;
  popupId?: string;
  className?: string;
  color?: IconButtonProps['color'];
  size?: IconButtonProps['size'];
  onClick?: () => void;
  disablePadding?: boolean;
  disableMargin?: boolean;
  bgColour?: string;
  textColour?: string;
}

/**
 * @deprecated - use components/design-system/InfoPopoverV2/InfoPopoverV2 instead.
 */
export function InfoPopover({
  content,
  popupId,
  className,
  color = 'default',
  size = 'medium',
  onClick,
  disablePadding = true,
  disableMargin = false,
}: InfoPopoverProps) {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const popperState = usePopupState({ variant: 'popper', popupId });

  const handleClickAway = useCallback(() => popperState.close(), [popperState]);

  const modifiers = useMemo(
    () => ({
      flip: {
        enabled: true,
      },
      arrow: {
        enabled: true,
        element: arrowRef,
      },
    }),
    [arrowRef]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span
        style={{ display: 'inline-flex', verticalAlign: 'text-bottom' }}
        onClick={() => onClick?.()}
      >
        <StyledIconButton
          type="button"
          size={size}
          color={color}
          className={className}
          $disablePadding={disablePadding}
          $disableMargin={disableMargin}
          {...bindToggle(popperState)}
        >
          <HiOutlineInformationCircle />
        </StyledIconButton>
        <StyledPopper
          {...bindPopper(popperState)}
          placement="top"
          modifiers={modifiers}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps}>
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                <Paper elevation={0}>
                  <Typography>{content}</Typography>
                </Paper>
                <Slide
                  {...TransitionProps}
                  direction={placement.startsWith('bottom') ? 'up' : 'down'}
                >
                  <Arrow ref={setArrowRef} />
                </Slide>
              </div>
            </Fade>
          )}
        </StyledPopper>
      </span>
    </ClickAwayListener>
  );
}
