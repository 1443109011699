import { TransferPrompt } from 'components/TransferPrompt/TransferPrompt';
import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { generateDashboardAccountAddCashPath } from 'paths';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TransferPromptContainer } from './SecondAccount.styles';

interface SuccessStepProps {
  accountType: WrapperType;
  onClose: () => void;
}

const accountTypeFriendlyNameLookup: Partial<Record<WrapperType, string>> = {
  [WrapperType.Gia]: 'General Investment Account',
  [WrapperType.Isa]: 'Stocks & Shares ISA',
  [WrapperType.Sipp]: 'Pension',
};

export function SuccessStep({ accountType, onClose }: SuccessStepProps) {
  const history = useHistory();
  const title = accountTypeFriendlyNameLookup[accountType] ?? accountType;

  useEffect(() => {
    history.push(`/dashboard/${accountType.toLowerCase()}`);
  }, [history, accountType]);

  const handleBrowseFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'Browse funds',
    });
    onClose();
  };

  const handleAddCash = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
    onClose();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Your new {title} is ready!</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            We've sent you an email with your account details.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>

      <StepActions $isHorizontal>
        <LinkStepButton
          $minWidth="narrow"
          to="/"
          className="richBlue"
          onClick={handleBrowseFunds}
        >
          Browse funds
        </LinkStepButton>
        <LinkStepButton
          $minWidth="narrow"
          to={generateDashboardAccountAddCashPath({
            wrapperType: getPathSegmentForWrapperType(accountType),
          })}
          className="magenta"
          onClick={handleAddCash}
        >
          Add cash
        </LinkStepButton>
      </StepActions>
      <TransferPromptContainer>
        <TransferPrompt
          source="Second account dialog"
          wrapperType={accountType}
          onClose={onClose}
        />
      </TransferPromptContainer>
    </StepContainer>
  );
}
