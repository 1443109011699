import { Container } from 'components/Container/Container';
import styled from 'styled-components';

export const ContentContainer = styled(Container)`
  background-repeat: repeat;
  background-size: auto;
`;

export const InternalTabsWrapper = styled.div`
  align-self: stretch;
`;
