import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  getSellEstimate,
  getSellEstimateUnits,
} from 'components/Dialogs/Trading/helpers';
import { CombinedQueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  H3,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import {
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import {
  Table,
  Td,
  Th,
  Tr,
} from 'components/feature/PortfolioBuilder/InteractiveBreakdown/_shared/Table.styles';
import { currencyFull, date } from 'formatting';
import {
  WrapperType,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUpdatePortfolioRebalancingMutation,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { useSellOrderDetailsByAccountQueries } from 'hooks/useSellOrderDetailsByAccountQueries';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  RebalancingQueryRebalancing,
  RebalancingQuerySellOrder,
} from 'types/graphqlTypes';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import { DpFlowTypes } from '../../flowType';
import {
  StepSection,
  WideSection,
} from '../OrderConfirmation/OrderConfirmation.styles';
import {
  CurrentDataCopy,
  DataLineWrapper,
  MobileDivider,
  MobileLineWrapper,
  MobileWrapper,
  PreviousDataCopy,
  TableWrapper,
} from './UnitPriceChanges.styles';

interface RebalancingQuerySellOrderUpdated extends RebalancingQuerySellOrder {
  newAmount: number;
  newUnits: number;
  keepsOriginalAmount: boolean;
}

interface UnitPriceChangeLineProps {
  sellOrder: RebalancingQuerySellOrderUpdated;
  selectedAccountId: string;
}

const UnitPriceChangeLine = ({
  sellOrder,
  selectedAccountId,
}: UnitPriceChangeLineProps) => {
  const { newAmount, newUnits, keepsOriginalAmount } = sellOrder;

  return (
    <Tr>
      <Td $lineHeight="large">
        <TextSmall $noMargin>{sellOrder.instrument.name}</TextSmall>
      </Td>
      <Td $lineHeight="large">
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {date(sellOrder.quotedBidDateUtc)}
        </PreviousDataCopy>
      </Td>
      <Td $lineHeight="large">
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(sellOrder.quotedBid)}
        </PreviousDataCopy>
      </Td>
      <Td $lineHeight="large">
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {sellOrder.quantity}
        </PreviousDataCopy>
      </Td>
      <Td $lineHeight="large">
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(sellOrder.enteredAmount)}
        </PreviousDataCopy>
      </Td>
      <Td $lineHeight="large">
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.normal}>
          {currencyFull(sellOrder.instrument.bidPrice!)}
        </CurrentDataCopy>
      </Td>
      <Td $lineHeight="large">
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.normal}>
          {newUnits.toFixed(4)}
          {!keepsOriginalAmount && '*'}
        </CurrentDataCopy>
      </Td>
      <Td $lineHeight="large">
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.normal}>
          {currencyFull(newAmount)}
        </CurrentDataCopy>
      </Td>
    </Tr>
  );
};

interface UnitPriceChangeMobileProps {
  sellOrder: RebalancingQuerySellOrderUpdated;
}

const UnitPriceChangeMobile = ({ sellOrder }: UnitPriceChangeMobileProps) => {
  const { newAmount, newUnits, keepsOriginalAmount } = sellOrder;
  return (
    <MobileLineWrapper>
      <TextNormal $noMargin $fontWeight={FontWeight.medium}>
        {sellOrder.instrument.name}
      </TextNormal>
      <DataLineWrapper>
        <TextNormal $noMargin>Quoted date</TextNormal>
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {date(sellOrder.quotedBidDateUtc)}
        </PreviousDataCopy>
      </DataLineWrapper>

      <DataLineWrapper>
        <PreviousDataCopy $noMargin>Quoted unit price</PreviousDataCopy>
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(sellOrder.quotedBid)}
        </PreviousDataCopy>
      </DataLineWrapper>

      <DataLineWrapper>
        <PreviousDataCopy $noMargin>Quoted units to sell</PreviousDataCopy>
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {sellOrder.quantity}
        </PreviousDataCopy>
      </DataLineWrapper>

      <DataLineWrapper>
        <PreviousDataCopy $noMargin>Quoted est. amount</PreviousDataCopy>
        <PreviousDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(sellOrder.enteredAmount)}
        </PreviousDataCopy>
      </DataLineWrapper>

      <MobileDivider />

      <DataLineWrapper>
        <TextNormal $noMargin>Latest unit price</TextNormal>
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(sellOrder.instrument.bidPrice!)}
        </CurrentDataCopy>
      </DataLineWrapper>
      <DataLineWrapper>
        <TextNormal $noMargin>Latest units to sell</TextNormal>
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.light}>
          {newUnits.toFixed(4)}
          {!keepsOriginalAmount && '*'}
        </CurrentDataCopy>
      </DataLineWrapper>
      <DataLineWrapper>
        <TextNormal $noMargin>Latest Est. amount</TextNormal>
        <CurrentDataCopy $noMargin $fontWeight={FontWeight.light}>
          {currencyFull(newAmount)}
        </CurrentDataCopy>
      </DataLineWrapper>
    </MobileLineWrapper>
  );
};

interface OrderConfirmationProps {
  onProceed: () => void;
  portfolioRebalancing: RebalancingQueryRebalancing;
  activeFlow: DpFlowTypes;
  selectedAccountId: string;
  selectedWrapperType: WrapperType;
}

export function UnitPriceChanges({
  onProceed,
  portfolioRebalancing,
  activeFlow,
  selectedAccountId,
  selectedWrapperType,
}: OrderConfirmationProps) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sellOrdersWithChanges = portfolioRebalancing.sellOrders.filter(
    (sellOrder) => sellOrder.quotedBid !== sellOrder.instrument.bidPrice
  );
  const sellOrdersWithoutChanges = portfolioRebalancing.sellOrders.filter(
    (sellOrder) => sellOrder.quotedBid === sellOrder.instrument.bidPrice
  );

  const sellOrderDetailsByAccountQueries = useSellOrderDetailsByAccountQueries(
    sellOrdersWithChanges.map((sellOrder) => ({
      id: selectedAccountId,
      isin: sellOrder.isin,
    }))
  );

  const updatedSellOrders: RebalancingQuerySellOrderUpdated[] = sellOrdersWithChanges.map(
    (sellOrder, index) => {
      const sellOrderDetailsByAccountQuery =
        sellOrderDetailsByAccountQueries[index];

      const sellRange =
        sellOrderDetailsByAccountQuery.data?.sellOrderDetailsByAccount
          ?.validRange.quantityRange;

      let keepsOriginalAmount = true;
      let newUnits = getSellEstimateUnits(
        sellOrder.instrument,
        sellOrder.enteredAmount
      );
      let newAmount = sellOrder.enteredAmount;

      if (sellRange && sellRange?.maximumQuantity < newUnits) {
        newUnits = sellRange?.maximumQuantity;
        newAmount = getSellEstimate(sellOrder.instrument, newUnits);
        keepsOriginalAmount = false;
      }

      return { ...sellOrder, newAmount, newUnits, keepsOriginalAmount };
    }
  );

  const isAnySellOrderAmountChanged = updatedSellOrders.every(
    ({ keepsOriginalAmount }) => !keepsOriginalAmount
  );

  const {
    mutateAsync,
    error,
    isLoading,
  } = useUpdatePortfolioRebalancingMutation();

  const queryClient = useQueryClient();

  const updateRebalancing = async () => {
    await mutateAsync({
      input: {
        portfolioRebalancingId: portfolioRebalancing.id,

        sellOrders: [
          ...updatedSellOrders.map((sellOrder) => {
            //todo - include the unchanged sell orders
            return {
              enteredAmount: sellOrder.newAmount,
              isin: sellOrder.isin,
              quantity: sellOrder.newUnits,
              quotedBid: sellOrder.instrument.bidPrice!,
              quotedBidDateUtc: new Date().toISOString(),
            };
          }),
          ...sellOrdersWithoutChanges.map((sellOrder) => ({
            enteredAmount: sellOrder.enteredAmount,
            isin: sellOrder.isin,
            quantity: sellOrder.quantity,
            quotedBid: sellOrder.quotedBid,
            quotedBidDateUtc: sellOrder.quotedBidDateUtc,
          })),
        ],
      },
    });

    queryClient.invalidateQueries(
      usePortfolioRebalancingQuery.getKey({
        id: portfolioRebalancing.id!,
      })
    );
    queryClient.invalidateQueries(usePortfolioRebalancingsQuery.getKey());
  };

  return (
    <StepContainer>
      <StepContent width={StepContentWidth.extraWide}>
        <H3 $textAlign={HeadingAlign.center}>Before we continue</H3>
        <Text $textAlign={TextAlign.center}>
          Since you added some sells orders to your basket there have been some
          price changes.
        </Text>
        <Text $textAlign={TextAlign.center}>
          Click continue to checkout with the updated estimate.
        </Text>
      </StepContent>
      <WideSection>
        <StepSection>
          {!isMobile && (
            <TableWrapper>
              <CombinedQueryState queries={sellOrderDetailsByAccountQueries}>
                <Table>
                  <Tr>
                    <Th $noBorder></Th>
                    <Th $noBorder>
                      <PreviousDataCopy
                        $noMargin
                        $fontWeight={FontWeight.medium}
                      >
                        Quoted
                      </PreviousDataCopy>
                    </Th>
                    <Th $noBorder></Th>
                    <Th $noBorder></Th>
                    <Th $noBorder></Th>
                    <Th $noBorder>
                      <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                        Latest
                      </TextSmall>
                    </Th>
                    <Th $noBorder></Th>
                    <Th $noBorder></Th>
                  </Tr>
                  <Tr>
                    <Th>
                      <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                        Instrument
                      </TextSmall>
                    </Th>
                    <Th>
                      <PreviousDataCopy
                        $noMargin
                        $fontWeight={FontWeight.medium}
                      >
                        Date
                      </PreviousDataCopy>
                    </Th>
                    <Th>
                      <PreviousDataCopy
                        $noMargin
                        $fontWeight={FontWeight.medium}
                      >
                        Unit price
                      </PreviousDataCopy>
                    </Th>
                    <Th>
                      <PreviousDataCopy
                        $noMargin
                        $fontWeight={FontWeight.medium}
                      >
                        Units to sell
                      </PreviousDataCopy>
                    </Th>
                    <Th>
                      <PreviousDataCopy
                        $noMargin
                        $fontWeight={FontWeight.medium}
                      >
                        Est. Amount
                      </PreviousDataCopy>
                    </Th>
                    <Th>
                      <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                        Unit price
                      </TextSmall>
                    </Th>
                    <Th>
                      <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                        Units to sell
                      </TextSmall>
                    </Th>
                    <Th>
                      <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                        Est. amount
                      </TextSmall>
                    </Th>
                  </Tr>

                  {updatedSellOrders.map((sellOrder) => {
                    return (
                      <UnitPriceChangeLine
                        key={sellOrder.id}
                        sellOrder={sellOrder}
                        selectedAccountId={selectedAccountId}
                      />
                    );
                  })}
                </Table>
              </CombinedQueryState>
              {isAnySellOrderAmountChanged && (
                <TextNormal>
                  *This is the maximum amount of units you have available to
                  sell
                </TextNormal>
              )}
            </TableWrapper>
          )}
          {isMobile && (
            <MobileWrapper>
              {updatedSellOrders.map((sellOrder) => (
                <UnitPriceChangeMobile
                  key={sellOrder.id}
                  sellOrder={sellOrder}
                />
              ))}
              {isAnySellOrderAmountChanged && (
                <TextNormal>
                  *This is the maximum amount of units you have available to
                  sell
                </TextNormal>
              )}
            </MobileWrapper>
          )}
        </StepSection>
      </WideSection>
      <StepActions $isHorizontal>
        <CustomButtonV2
          $color="secondary"
          $size="normal"
          disabled={isLoading}
          onClick={async () => {
            if (!portfolioRebalancing.id) {
              return;
            }
            try {
              updateRebalancing();
              history.push(
                generateDynamicPortfolioConstructionBasketPath({
                  wrapperType: getPathSegmentForWrapperType(
                    selectedWrapperType
                  ),
                })
              );
            } catch {
              // error handed by state
            }
          }}
        >
          View basket
        </CustomButtonV2>
        <CustomButtonV2
          $color="primary"
          $size="normal"
          disabled={isLoading}
          onClick={async () => {
            if (!portfolioRebalancing.id) {
              return;
            }
            try {
              updateRebalancing();
              onProceed();
            } catch {
              // error handed by state
            }
          }}
        >
          Continue
        </CustomButtonV2>

        {error && <div>Something went wrong</div>}
      </StepActions>
    </StepContainer>
  );
}
