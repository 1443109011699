import { Typography } from '@material-ui/core';
import React from 'react';
import { Container } from './ErrorDisplay.style';

export function ErrorDisplay() {
  return (
    <Container>
      <Typography>Sorry, there was a problem loading this content.</Typography>
    </Container>
  );
}
