import { DialogActions, DialogContent } from '@material-ui/core';
import { Loading } from 'components/Loading';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import React from 'react';

export interface WaitingPaymentProps {
  onContactSupport: () => void;
}

export function WaitingPayment({ onContactSupport }: WaitingPaymentProps) {
  return (
    <>
      <StepTitle>We're waiting for your payment</StepTitle>
      <DialogContent>
        <Loading />
      </DialogContent>
      <DialogActions>
        <CustomButtonV2
          $color="secondary"
          onClick={onContactSupport}
          $isWide={false}
        >
          Contact Support
        </CustomButtonV2>
      </DialogActions>
    </>
  );
}
