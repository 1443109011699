import { yupResolver } from '@hookform/resolvers/yup';
import { CountryField } from 'components/Form/CountryField';
import { TextField } from 'components/Form/TextField';
import { FormProvider, useForm } from 'react-hook-form';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContent,
  StepFormContainer,
  StepTitle,
} from '../../../../../design-system/StepComponents/StepComponents';
import { addressSchema } from '../addressSchema';

export interface AddressInput {
  buildingNameOrNumber: string;
  line1OrStreet: string;
  townOrCity: string;
  postCode: string;
  countryIso: string;
}

interface ConfirmAddressOnSubmitProps {
  data: Partial<AddressInput>;
}

interface ConfirmAddressProps {
  title: string;
  address?: AddressInput;
  onSubmit: (props: ConfirmAddressOnSubmitProps) => void;
  onGoBack: () => void;
}

export const ConfirmAddress = ({
  title,
  address = undefined,
  onSubmit,
  onGoBack,
}: ConfirmAddressProps) => {
  const defaultValues = address
    ? {
        buildingNameOrNumber: address.buildingNameOrNumber,
        line1OrStreet: address.line1OrStreet,
        townOrCity: address.townOrCity,
        postCode: address.postCode,
        countryIso: address.countryIso,
      }
    : {
        countryIso: 'GB',
      };

  const methods = useForm<Partial<AddressInput>>({
    defaultValues,
    resolver: addressSchema && yupResolver(addressSchema),
  });
  const { register, handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit({ data });
        })}
      >
        <StepContent>
          <StepTitle>{title}</StepTitle>
          <ConfirmAddressFormFields register={register} address={address} />
        </StepContent>
        <StepActions>
          <StepButton
            className="magenta"
            onClick={handleSubmit((data) => {
              onSubmit({ data });
            })}
          >
            Next
          </StepButton>
          <GoBackButton
            onClick={() => {
              onGoBack();
            }}
          />
        </StepActions>
      </form>
    </FormProvider>
  );
};

interface ConfirmAddressFormFieldsProps {
  address?: AddressInput;
  register: () => any;
  namePrefix?: string;
}

export const ConfirmAddressFormFields = ({
  address,
  register,
  namePrefix = '',
}: ConfirmAddressFormFieldsProps) => {
  return (
    <StepFormContainer>
      <TextField
        name={`${namePrefix}buildingNameOrNumber`}
        label="Name or number"
        defaultValue={address?.buildingNameOrNumber}
        register={register()}
      />
      <TextField
        name={`${namePrefix}line1OrStreet`}
        label="Street"
        defaultValue={address?.line1OrStreet}
        register={register()}
        fullWidth
      />
      <TextField
        name={`${namePrefix}townOrCity`}
        label="Town or city"
        defaultValue={address?.townOrCity}
        register={register()}
        fullWidth
      />
      <TextField
        name={`${namePrefix}postCode`}
        label="Postcode"
        defaultValue={address?.postCode}
        register={register()}
        fullWidth
      />
      <CountryField
        name={`${namePrefix}countryIso`}
        label="Country"
        disabled
        defaultValue={address?.countryIso}
        register={register()}
        fullWidth
      />
    </StepFormContainer>
  );
};
