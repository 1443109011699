import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { H6 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  GoBackButton,
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontSize,
  FontStyle,
  FontWeight,
  Text,
  TextNormal,
} from 'components/design-system/Text/Text';
import {
  Toggle,
  ToggleColorOptions,
} from 'components/design-system/Toggle/Toggle';
import { currencyFull } from 'formatting';
import {
  IllustrationQuery,
  PensionContributionBasis,
  UpdateIllustrationPaymentsInput,
  useIllustrationQuery,
  useUpdateIllustrationPaymentsMutation,
} from 'generated/graphql';
import {
  Contribution,
  employerContribution,
} from 'helpers/employerContributions';
import { isNumberInputValid } from 'helpers/inputHelpers';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import {
  AmountPercentageError,
  AmountPercentageWrapper,
  EmployerContributionContainer,
  EmployerContributionFormContainer,
  EmployerContributionTotals,
  FullSalaryQualEarningsWrapper,
  InputAdornment,
  SalaryWrapper,
  StyledInputLabel,
  TaxReliefWrapper,
  TotalAmount,
  TotalHr,
} from './EmployerContributionStep.styles';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries(useIllustrationQuery.getKey());
  };
}

const monthlyAmountSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between £0 and £5,000')
  .min(0, 'The per month amount must be between £0 and £5,000')
  .max(5000, 'The per month amount must be less than or equal to £5,000')
  .required()
  .transform((_, value) => (value === '' ? null : Number(value)));

const monthlyPercentSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between 0 and 100')
  .min(0, 'The percentage must be between 0 and 100')
  .max(100, 'The percentage must be less than or equal to 100')
  .transform((_, value) => (value === '' ? null : Number(value)));

const employerContributionFormSchema = Yup.object({
  employerAmount: monthlyAmountSchemaRequired,
  employerPercent: monthlyPercentSchemaRequired,
  employeeAmount: monthlyAmountSchemaRequired,
  employeePercent: monthlyPercentSchemaRequired,
});

interface EmployerContributionStepFormProps
  extends EmployerContributionStepProps {
  data: IllustrationQuery;
}

interface FormValues {
  salary: string;
  employerAmount: string;
  employerPercent: string;
  employeeAmount: string;
  employeePercent: string;
  employeeTaxReliefAmount: string;
  applyQualifyingEarningsCap: string;
}

const getNumberFromFormValue = (formValue: string) => {
  if (formValue === '') {
    return 0;
  }
  return parseFloat(formValue);
};

const getContributionFromFormValues = (
  formValues: FormValues
): Contribution => {
  return {
    salary: getNumberFromFormValue(formValues.salary),
    employerAmount: getNumberFromFormValue(formValues.employerAmount),
    employerPercentage: getNumberFromFormValue(formValues.employerPercent),
    employeeAmount: getNumberFromFormValue(formValues.employeeAmount),
    employeePercentage: getNumberFromFormValue(formValues.employeePercent),
    employeeTaxReliefAmount: getNumberFromFormValue(
      formValues.employeeTaxReliefAmount
    ),
    applyQualifyingEarningsCap:
      formValues.applyQualifyingEarningsCap === 'qualifyingEarnings',
  };
};

export function EmployerContributionStepForm({
  hasEmployer,
  source,
  onProceed,
  onGoBack,
  data,
}: EmployerContributionStepFormProps) {
  const { illustration, userProfile } = data;

  const invalidateQueries = useInvalidateMutatedQueries();
  const defaultValues = {
    salary: userProfile?.pensionDetails?.salary?.toFixed() ?? '',

    employeeAmount:
      typeof illustration?.monthlyEmployeeViaPayrollContribution?.amount ===
      'number'
        ? (illustration?.monthlyEmployeeViaPayrollContribution?.amount).toFixed(
            2
          )
        : '',
    employeePercent:
      typeof userProfile?.pensionDetails
        ?.employmentEmployeeContributionPercentage === 'number'
        ? `${userProfile?.pensionDetails?.employmentEmployeeContributionPercentage}`
        : '',
    employerAmount:
      typeof illustration?.monthlyEmployerContribution?.amount === 'number'
        ? (illustration?.monthlyEmployerContribution?.amount).toFixed(2)
        : '',
    employerPercent:
      typeof userProfile?.pensionDetails
        ?.employmentEmployerContributionPercentage === 'number'
        ? `${userProfile?.pensionDetails?.employmentEmployerContributionPercentage}`
        : '',
    employeeTaxReliefAmount:
      typeof illustration?.monthlyEmployeeViaPayrollContribution?.amount ===
      'number'
        ? (
            illustration?.monthlyEmployeeViaPayrollContribution?.amount * 0.25
          ).toFixed(2)
        : '',
    applyQualifyingEarningsCap:
      userProfile?.pensionDetails?.employerContributionBasis ===
      PensionContributionBasis.QualifyingEarnings
        ? 'qualifyingEarnings'
        : 'fullSalary',
  };
  const methods = useForm<FormValues>({
    resolver: yupResolver(employerContributionFormSchema),
    defaultValues,
  });

  const defaultTotalAmount =
    parseFloat(defaultValues.employeeAmount) +
    parseFloat(defaultValues.employerAmount) +
    parseFloat(defaultValues.employeeTaxReliefAmount);

  const defaultTotalProportion =
    (userProfile?.pensionDetails?.employmentEmployeeContributionPercentage ??
      0) +
    (userProfile?.pensionDetails?.employmentEmployerContributionPercentage ??
      0);

  const [totalAmount, setTotalAmount] = useState<number | undefined>(
    defaultTotalAmount
  );
  const [totalProportion, setTotalProportion] = useState<number | undefined>(
    defaultTotalProportion
  );
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    errors,
  } = methods;
  const watchApplyQualifyingEarningsCap = watch('applyQualifyingEarningsCap');

  const updateIllustrationPaymentsMutation = useUpdateIllustrationPaymentsMutation();
  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = updateIllustrationPaymentsMutation;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const mutateData: UpdateIllustrationPaymentsInput = {
        viaEmploymentContribution: {
          annualSalary: parseFloat(data.salary),
          basis:
            data.applyQualifyingEarningsCap === 'fullSalary'
              ? PensionContributionBasis.FullSalary
              : PensionContributionBasis.QualifyingEarnings,
          employeeContribution: {
            amount: parseFloat(data.employeeAmount),
            percentage: parseFloat(data.employeePercent),
          },
          employerContribution: {
            amount: parseFloat(data.employerAmount),
            percentage: parseFloat(data.employerPercent),
          },
        },
      };

      await mutateAsync({ input: mutateData });
      await invalidateQueries();
      onProceed();
    } catch (e) {
      // Handled by state
    }
  };

  const onSkipNoEmployerFunding = async () => {
    try {
      const mutateData: UpdateIllustrationPaymentsInput = {
        viaEmploymentContribution: {
          employeeContribution: {
            amount: 0,
          },
          employerContribution: {
            amount: 0,
          },
        },
      };

      await mutateAsync({ input: mutateData });
      await invalidateQueries();
      onProceed();
    } catch (e) {
      // Handled by state
    }
  };

  return (
    <StepContainer>
      <StepTitle>Employer contributions</StepTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.extraWide}>
          <StepText>
            <StepIntroductionTypography>
              To better illustrate how your pension value might change over time
              so that you can determine if this is the right choice for you, we
              need to learn a bit more about how you want to fund your TILLIT
              Pension.
            </StepIntroductionTypography>

            <StepIntroductionTypography>
              Fill in the details as best you can.
            </StepIntroductionTypography>

            <EmployerContributionContainer>
              <EmployerContributionFormContainer>
                <SalaryWrapper>
                  <FormControl>
                    <InputLabel htmlFor="salary" shrink>
                      Salary
                    </InputLabel>
                    <Input
                      id="salary"
                      name="salary"
                      type="text"
                      inputRef={register}
                      onKeyDown={(ev) => {
                        if (!isNumberInputValid(ev)) {
                          ev.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const newValues = employerContribution(
                          getContributionFromFormValues(getValues()),
                          {
                            change: 'salary',
                            newValue: getNumberFromFormValue(e.target.value),
                          }
                        );
                        setValue('employerAmount', newValues.employerAmount);
                        setValue('employeeAmount', newValues.employeeAmount);
                        setValue(
                          'employeeTaxReliefAmount',
                          newValues.employeeTaxReliefAmount
                        );
                        setTotalAmount(newValues.totalAmount);
                        setTotalProportion(newValues.totalPercentage);
                      }}
                      startAdornment={
                        <InputAdornment $noMargin>£</InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          $noMargin
                          $fontStyle={FontStyle.italic}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          per year
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </SalaryWrapper>

                <AmountPercentageWrapper>
                  <FormControl>
                    <StyledInputLabel htmlFor="employerPercent" shrink>
                      Employer contribution
                      <InfoPopoverV2
                        size="small"
                        placement="top"
                        content="We assume that your employer is paying in gross and that there's no tax to be reclaimed."
                      />
                    </StyledInputLabel>
                    <Input
                      id="employerPercent"
                      name="employerPercent"
                      type="text"
                      inputRef={register}
                      onKeyDown={(ev) => {
                        if (!isNumberInputValid(ev)) {
                          ev.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const newValues = employerContribution(
                          getContributionFromFormValues(getValues()),
                          {
                            change: 'employerPercentage',
                            newValue: getNumberFromFormValue(e.target.value),
                          }
                        );

                        setValue('employerAmount', newValues.employerAmount);
                        setTotalAmount(newValues.totalAmount);
                        setTotalProportion(newValues.totalPercentage);
                      }}
                      endAdornment={
                        <InputAdornment $noMargin>%</InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="employerAmount" hidden>
                      Employer £
                    </InputLabel>
                    <Input
                      id="employerAmount"
                      name="employerAmount"
                      type="text"
                      inputRef={register}
                      onKeyDown={(ev) => {
                        if (!isNumberInputValid(ev)) {
                          ev.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const newValues = employerContribution(
                          getContributionFromFormValues(getValues()),
                          {
                            change: 'employerAmount',
                            newValue: getNumberFromFormValue(e.target.value),
                          }
                        );

                        setValue(
                          'employerPercent',
                          newValues.employerPercentage
                        );
                        setTotalAmount(newValues.totalAmount);
                        setTotalProportion(newValues.totalPercentage);
                      }}
                      startAdornment={
                        <InputAdornment
                          $noMargin
                          $fontWeight={FontWeight.normal}
                        >
                          £
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          $noMargin
                          $fontStyle={FontStyle.italic}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          per month
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.employerPercent && (
                    <AmountPercentageError error>
                      {errors.employerPercent.message}
                    </AmountPercentageError>
                  )}
                  {!errors.employerPercent && errors.employerAmount && (
                    <AmountPercentageError error>
                      {errors.employerAmount.message}
                    </AmountPercentageError>
                  )}
                </AmountPercentageWrapper>

                <AmountPercentageWrapper>
                  <FormControl>
                    <InputLabel htmlFor="employeePercent" shrink>
                      Your contribution
                      <InfoPopoverV2
                        size="small"
                        placement="top"
                        content="We assume that you are paying in net of tax, and that we'll reclaim basic rate tax relief at 20% on your behalf."
                      />
                    </InputLabel>
                    <Input
                      id="employeePercent"
                      name="employeePercent"
                      type="text"
                      inputRef={register}
                      onKeyDown={(ev) => {
                        if (!isNumberInputValid(ev)) {
                          ev.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const newValues = employerContribution(
                          getContributionFromFormValues(getValues()),
                          {
                            change: 'employeePercentage',
                            newValue: getNumberFromFormValue(e.target.value),
                          }
                        );

                        setValue('employeeAmount', newValues.employeeAmount);
                        setValue(
                          'employeeTaxReliefAmount',
                          newValues.employeeTaxReliefAmount
                        );
                        setTotalAmount(newValues.totalAmount);
                        setTotalProportion(newValues.totalPercentage);
                      }}
                      endAdornment={
                        <InputAdornment $noMargin>%</InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="employeeAmount" hidden>
                      Employee £
                    </InputLabel>
                    <Input
                      id="employeeAmount"
                      name="employeeAmount"
                      type="text"
                      inputRef={register}
                      onKeyDown={(ev) => {
                        if (!isNumberInputValid(ev)) {
                          ev.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const newValues = employerContribution(
                          getContributionFromFormValues(getValues()),
                          {
                            change: 'employeeAmount',
                            newValue:
                              e.target.value === ''
                                ? 0
                                : getNumberFromFormValue(e.target.value),
                          }
                        );

                        setValue(
                          'employeePercent',
                          newValues.employeePercentage
                        );
                        if (
                          typeof newValues.employeeTaxReliefAmount !==
                            'number' ||
                          !isNaN(newValues.employeeTaxReliefAmount)
                        ) {
                          setValue(
                            'employeeTaxReliefAmount',
                            newValues.employeeTaxReliefAmount
                          );
                        }
                        setTotalAmount(newValues.totalAmount);
                        setTotalProportion(newValues.totalPercentage);
                      }}
                      startAdornment={
                        <InputAdornment
                          $noMargin
                          $fontWeight={FontWeight.normal}
                        >
                          £
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          $noMargin
                          $fontStyle={FontStyle.italic}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          per month
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.employeePercent && (
                    <AmountPercentageError error>
                      {errors.employeePercent.message}
                    </AmountPercentageError>
                  )}
                  {!errors.employeePercent && errors.employeeAmount && (
                    <AmountPercentageError error>
                      {errors.employeeAmount.message}
                    </AmountPercentageError>
                  )}
                </AmountPercentageWrapper>

                <TaxReliefWrapper>
                  <FormControl>
                    <InputLabel htmlFor="employeeTaxReliefAmount" shrink>
                      Tax relief
                      <InfoPopoverV2
                        size="small"
                        placement="top"
                        content="We'll reclaim basic rate tax relief at 20% on any net contributions from your pay."
                      />
                    </InputLabel>
                    <Input
                      id="employeeTaxReliefAmount"
                      name="employeeTaxReliefAmount"
                      type="text"
                      readOnly
                      inputRef={register}
                      startAdornment={
                        <InputAdornment
                          $noMargin
                          $fontWeight={FontWeight.normal}
                        >
                          £
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          $noMargin
                          $fontStyle={FontStyle.italic}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          per month
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </TaxReliefWrapper>
              </EmployerContributionFormContainer>
              <EmployerContributionTotals>
                <H6 $noMargin>Total</H6>
                <TotalAmount $noMargin>
                  {totalProportion ? `${totalProportion}%` : '-'}
                </TotalAmount>
                <TotalHr />
                <TotalAmount $noMargin>
                  {totalAmount ? currencyFull(totalAmount) : '-'}
                </TotalAmount>
                <Text
                  $noMargin
                  $fontSize={FontSize.xs}
                  $fontStyle={FontStyle.italic}
                >
                  per month
                </Text>
              </EmployerContributionTotals>
            </EmployerContributionContainer>

            <FullSalaryQualEarningsWrapper>
              <input
                id="applyQualifyingEarningsCap"
                name="applyQualifyingEarningsCap"
                ref={register}
                type="hidden"
              />

              <Toggle
                onClick={(value) => {
                  setValue('applyQualifyingEarningsCap', value);
                  const newValues = employerContribution(
                    getContributionFromFormValues(getValues()),
                    {
                      change: 'salary',
                      newValue: parseFloat(getValues('salary')),
                    }
                  );

                  setValue('employerAmount', newValues.employerAmount);
                  setValue('employeeAmount', newValues.employeeAmount);
                  setValue(
                    'employeeTaxReliefAmount',
                    newValues.employeeTaxReliefAmount
                  );
                  setTotalAmount(newValues.totalAmount);
                  setTotalProportion(newValues.totalPercentage);
                }}
                $color={ToggleColorOptions.primaryAlt}
                options={[
                  { value: 'fullSalary', label: 'Full salary' },
                  { value: 'qualifyingEarnings', label: 'Qualifying earnings' },
                ]}
                value={watchApplyQualifyingEarningsCap}
              />
            </FullSalaryQualEarningsWrapper>

            <TextNormal $fontStyle={FontStyle.italic}>
              These values are used for the purposes of the illustration
              {hasEmployer && (
                <> &mdash; we would confirm the amount with your employer</>
              )}
              .
            </TextNormal>
          </StepText>
        </StepContent>
        <ServerError isVisible={isError} />
        <StepActions>
          <StepButton
            type="submit"
            className="magenta"
            disabled={isLoading || isSuccess}
          >
            Continue
          </StepButton>
          <SkipButton
            type="button"
            className="richBlue"
            variant="outlined"
            onClick={onSkipNoEmployerFunding}
            disabled={isLoading || isSuccess}
          >
            Skip - no employer funding
          </SkipButton>
          <GoBackButton onClick={onGoBack} />
        </StepActions>
      </form>
    </StepContainer>
  );
}

interface EmployerContributionStepProps {
  source?: 'openAccountStep';
  hasEmployer: boolean;
  onProceed: () => void;
  onGoBack: () => void;
}

export function EmployerContributionStep(props: EmployerContributionStepProps) {
  const illustrationQuery = useIllustrationQuery();

  return (
    <QueryState {...illustrationQuery}>
      {(queryResult) =>
        queryResult.data && (
          <EmployerContributionStepForm {...props} data={queryResult.data} />
        )
      }
    </QueryState>
  );
}
