import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { IntercomContext } from 'context/Intercom';
import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

export interface ResponsiveDialogProps extends DialogProps {
  pad?: boolean;
  onClose?: () => void;
}

const StyledDialog = styled(({ className, ...props }) => (
  <Dialog {...props} classes={{ paper: className }} />
))`
  &.MuiDialog-paperFullScreen .MuiDialogContent-root {
    min-height: calc(100% - 80px);
  }
  & .MuiDialogTitle-root .MuiButtonBase-root {
    z-index: 10;
  }
  &.MuiPaper-rounded {
    border-radius: 0;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      border-radius: 10px;
    }
  }
`;

type contentType = {
  $padding: boolean;
};

const TitleContainer = styled(DialogTitle)<contentType>`
  ${(p) =>
    !p.$padding &&
    `
    padding: ${p.theme.spacing(2.5, 3.75, 0, 2.5)};
  `};
  ${(p) => p.theme.breakpoints.up('sm')} {
    ${(p) =>
      !p.$padding &&
      `
      padding: ${p.theme.spacing(5, 3.75, 0, 5)};
    `};
  }
`;

const ContentContainer = styled(DialogContent)<contentType>`
  ${({ $padding }) =>
    !$padding &&
    `
    padding: 0px;
  `};
`;

/**
 * @deprecated - use src/components/Dialogs/ResponsiveDialog.tsx instead
 */
export function ResponsiveDialog({
  pad = true,
  fullScreen,
  children,
  onClose,
  open,
  ...props
}: ResponsiveDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const IntercomCtx = useContext(IntercomContext);
  const isFullScreen = isMobile || fullScreen;

  return (
    <StyledDialog
      {...props}
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={isFullScreen}
      disableEnforceFocus={IntercomCtx?.open}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <TitleContainer $padding={pad} style={{ textAlign: 'right' }}>
        <IconButton aria-label="Close dialog" onClick={() => onClose?.()}>
          <AiOutlineClose />
        </IconButton>
      </TitleContainer>
      <ContentContainer $padding={pad}>
        {open ? children : null}
      </ContentContainer>
    </StyledDialog>
  );
}
