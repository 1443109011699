import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { useAuth } from 'context/AuthContext';
import {
  Position,
  WrapperType,
  useAccountsQuery,
  useAssetQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { PartialDeep } from 'type-fest';
import { AnyAsset } from 'types/graphqlTypes';
import { AddBuyOrderToBasketStep } from './steps/AddBuyOrderToBasketStep';

export interface AddBuyOrderToBasketDialogProps extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  asset?: AnyAsset;
  assetId?: number;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
}

export function AddBuyOrderToBasketDialog({
  closeDialog,
  open,
  asset,
  assetId,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
}: AddBuyOrderToBasketDialogProps) {
  const { signedIn } = useAuth();
  const accountsQuery = useAccountsQuery(undefined, { enabled: signedIn });
  const assetQuery = useAssetQuery(
    { id: assetId! },
    { enabled: assetId !== undefined && open }
  );
  const accounts = accountsQuery.data?.accounts;

  return (
    <QueryState {...assetQuery} loadingComponent={<></>}>
      {() => (
        <>
          {(!selectedAccountId || (accounts && accounts.length > 0)) && (
            <ResponsiveDialog open={open} onClose={closeDialog}>
              {open && (
                <QueryState {...assetQuery}>
                  {() => {
                    const eitherAsset = asset || assetQuery.data?.asset;
                    if (!eitherAsset) {
                      return null;
                    }
                    return (
                      <AddBuyOrderToBasketStep
                        flow="addToBasketOnlyFlow"
                        selectedAccountType={selectedAccountType}
                        selectedPosition={selectedPosition}
                        onProceed={closeDialog}
                        asset={eitherAsset}
                        selectedAccountId={selectedAccountId}
                        selectedIsin={selectedIsin}
                        handleInstrumentChange={handleInstrumentChange}
                      />
                    );
                  }}
                </QueryState>
              )}
            </ResponsiveDialog>
          )}
        </>
      )}
    </QueryState>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
