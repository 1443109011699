import { useMediaQuery, useTheme } from '@material-ui/core';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { Card } from 'components/design-system/Card/Card';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextLarge,
  TextNormal,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import {
  PlusIcon,
  ShoppingBasketIcon,
} from 'components/feature/PortfolioBuilder/_shared/DpcIcons';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import {
  IncludeAssetType,
  getAssetNameOfAsset,
} from '../../hooks/useGetBreakdowns';
import {
  BasketBreakdownType,
  useGetBasketBreakdown,
} from '../../hooks/useGetBreakdowns/useGetBasketBreakdown';
import {
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
  IconWrapper,
} from '../_shared/DrillDownTab.styles';
import { NameTdInner, Table, Td, Th, Tr } from '../_shared/Table.styles';

interface OrdersTableProps {
  breakdowns: BasketBreakdownType[];
}

export function OrdersTable({ breakdowns }: OrdersTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <>
        {breakdowns.map((breakdown) => (
          <Card>
            <Text
              $fontSize={FontSize.normal}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.left}
            >
              {breakdown.name}
            </Text>
            <KeyValueRow
              label="Holding (%)"
              value={format.percent(breakdown.percentage / 100)}
              fontSize={FontSize.xs}
              noMargin
            />
            <KeyValueRow
              label="Value (£)"
              value={format.currencyFull(breakdown.amount)}
              fontSize={FontSize.xs}
              noMargin
              divider
              color={colors.grey}
            />
          </Card>
        ))}
      </>
    );
  } else {
    return (
      <CardTableContainer>
        <Table>
          <thead>
            <tr>
              <Th>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Name
                </TextSmall>
              </Th>
              <Th>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Holding (%)
                </Text>
              </Th>
              <Th>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Value (£)
                </Text>
              </Th>
            </tr>
          </thead>
          <tbody>
            {breakdowns.map((breakdown) => {
              return (
                <Tr status="buying">
                  <Td>
                    <NameTdInner>
                      <IconWrapper>
                        <ShoppingBasketIcon $type="buy" />
                        <PlusIcon />
                      </IconWrapper>
                      <Text
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $textAlign={TextAlign.right}
                        $noMargin
                      >
                        {breakdown.name}
                      </Text>
                    </NameTdInner>
                  </Td>
                  <Td>
                    <Text
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $textAlign={TextAlign.right}
                      $noMargin
                    >
                      {format.percent(breakdown.percentage / 100)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $textAlign={TextAlign.right}
                      $noMargin
                    >
                      {format.currencyFull(breakdown.amount)}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </CardTableContainer>
    );
  }
}

interface HoldingsTabProps {
  selectedAccountType: WrapperType;
  includeAsset: IncludeAssetType;
  activeBreakdown: BasketBreakdownType;
}

export function BasketOnlyHoldingsTab({
  selectedAccountType,
  includeAsset,
  activeBreakdown,
}: HoldingsTabProps) {
  const { basketBreakdowns: breakdowns } = useGetBasketBreakdown(
    null,
    getAssetNameOfAsset,
    includeAsset
  );

  return (
    <div>
      <TextNormal>Holdings</TextNormal>
      <HoldingsTabInner>
        <OrdersTable breakdowns={breakdowns} />

        <HoldingsTabInfo>
          <Card>
            <TextXS $noMargin>{activeBreakdown.name} value</TextXS>
            <TextLarge $noMargin $fontWeight={FontWeight.medium}>
              {format.currencyFull(activeBreakdown.amount)}
            </TextLarge>
          </Card>
          <Card>
            <TextXS $noMargin>{activeBreakdown.name} allocation</TextXS>
            <TextLarge $noMargin $fontWeight={FontWeight.medium}>
              {format.percent(activeBreakdown.percentage / 100)}
            </TextLarge>
          </Card>
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
}
