import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

const HorizontalLayout = css`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  li {
    position: relative;
    font-size: 1rem;
    padding: ${(p) => p.theme.spacing(2.5, 0)};

    &:first-child {
      text-align: right;
      padding: ${(p) => p.theme.spacing(2.5, 4, 2.5, 0)};
    }

    &:last-child {
      text-align: left;
      padding: ${(p) => p.theme.spacing(2.5, 0, 2.5, 4)};
    }

    &:after {
      content: '';
      position: absolute;
      top: ${(p) => p.theme.spacing(2.5)}px;
      right: 0;
      bottom: ${(p) => p.theme.spacing(2.5)}px;
      width: 1px;
      background-color: ${colors.magenta};
    }
    &:last-child:after {
      display: none;
    }
  }
`;

export const PillContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    list-style: none;
    text-align: center;
    padding: ${(p) => p.theme.spacing(0.625)}px;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  &.single {
    justify-content: center;
    li {
      text-align: center;
      &:first-child {
        text-align: center;
        padding: ${(p) => p.theme.spacing(2.5, 0, 2.5, 0)};
      }
    }
  }

  &.small {
    ${HorizontalLayout}
    line-height: 1;

    ${(p) => p.theme.breakpoints.up('md')} {
      line-height: 1.4;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    ${HorizontalLayout}
  }
`;

export const Value = styled.span`
  display: inline-flex;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${colors.richBlue};
  align-items: center;

  > svg {
    height: 100%;
  }

  &.small {
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
  }

  &.dark {
    color: ${colors.white};
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    &.small {
      font-size: 1rem;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 2.625rem;

    &.small {
      font-size: 1.5rem;
    }
  }
`;

export const Label = styled.span`
  font-size: 0.75rem;
  text-transform: uppercase;

  &.small {
    font-size: 0.5rem;
    font-weight: 300;
    text-align: center;
    margin: 0;

    .MuiIconButton-root {
      font-size: 0.7rem;
      margin-left: 2px;
    }
  }

  &.dark {
    color: ${colors.white};
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    &.small {
      font-size: 0.625rem;

      .MuiIconButton-root {
        font-size: 0.825rem;
      }
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1rem;

    &.small {
      font-size: 0.75rem;

      .MuiIconButton-root {
        font-size: 0.95rem;
      }
    }
  }
`;
