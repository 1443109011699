import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export interface FundsGridCardOptions {
  isMuted: boolean;
  showFilters: boolean;
}

const defaultFundCardOptions: FundsGridCardOptions = {
  isMuted: true,
  showFilters: false,
};

export const useFundListOptions: () => [
  FundsGridCardOptions,
  (options: FundsGridCardOptions) => void
] = () => {
  const [optionsLs, setOptionsLs] = useLocalStorage<FundsGridCardOptions>(
    'fundGridCardOptions',
    defaultFundCardOptions
  );
  const [options, setOptions] = useState<FundsGridCardOptions>(
    { ...optionsLs, isMuted: true } || defaultFundCardOptions
  );

  useEffect(() => {
    setOptionsLs(options);
  }, [setOptionsLs, options]);

  return [options, setOptions];
};
