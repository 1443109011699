import { Text, TextProps } from 'components/design-system/Text/Text';
import { WrapperType, useFiltersQuery } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import orderBy from 'lodash/orderBy';
import {
  dynamicPortfolioConstructionBasePath,
  fundListPath,
  generateDynamicPortfolioConstructionFundsPath,
} from 'paths';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const useFundsFilter = (
  assetClass: string,
  selectedAccountType?: WrapperType
) => {
  let fundsPath = fundListPath;
  if (
    window.location.pathname.startsWith(dynamicPortfolioConstructionBasePath) &&
    selectedAccountType
  ) {
    fundsPath = generateDynamicPortfolioConstructionFundsPath({
      wrapperType: getPathSegmentForWrapperType(selectedAccountType),
    });
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tags = searchParams.get('tags');
  const activeTags = tags ? tags.split(',') : [];
  const isActive = activeTags.includes(assetClass);
  const toggleAssetClass = isActive
    ? [...activeTags.filter((tag) => tag !== assetClass)]
    : [...activeTags, assetClass];

  return {
    activeTags,
    isActive,
    href:
      toggleAssetClass.length === 0
        ? fundsPath
        : `${fundsPath}?tags=${toggleAssetClass.join(',')}`,
  };
};

const AssetClassContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0;
  justify-content: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`;

const AssetClassItemContainer = styled(NavLink)`
  text-decoration: none;
`;

interface AssetClassNameProps extends TextProps {
  isActive: boolean;
}

const AssetClassName = styled(Text)<AssetClassNameProps>`
  ${(p) => p.isActive && `border-bottom: 1px solid;`}
  margin: 0;
`;

interface AssetClassItemProps {
  assetClass: any;
  selectedAccountType?: WrapperType;
}

const AssetClassItem = ({
  assetClass,
  selectedAccountType,
}: AssetClassItemProps) => {
  const { href, isActive } = useFundsFilter(
    assetClass.code,
    selectedAccountType
  );
  return (
    <AssetClassItemContainer
      isActive={() => isActive}
      to={href}
      key={assetClass}
    >
      <AssetClassName
        isActive={isActive}
        assetClass={assetClass.name}
        $noMargin
      >
        {assetClass.name}
      </AssetClassName>
    </AssetClassItemContainer>
  );
};

interface FundAssetFiltersProps {
  selectedAccountType?: WrapperType;
}
export const FundAssetFilters = ({
  selectedAccountType,
}: FundAssetFiltersProps) => {
  const filtersQuery = useFiltersQuery();
  const assetClassFilter = filtersQuery.data?.tagCategories?.nodes?.find(
    (cat) => cat.code === 'CLASS'
  );
  const assetClasses =
    assetClassFilter && orderBy(assetClassFilter.tags.nodes, 'order');

  return (
    <AssetClassContainer>
      {assetClasses &&
        assetClasses.map((assetClass) => (
          <AssetClassItem
            selectedAccountType={selectedAccountType}
            assetClass={assetClass}
            key={assetClass.code}
          />
        ))}
    </AssetClassContainer>
  );
};
