// Inspired by https://wattenberger.com/blog/react-and-d3

import useMeasure from 'react-use-measure';

export interface ChartDimensions {
  width: number;
  height: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
}

interface CombinedDimensions extends ChartDimensions {
  boundedHeight: number;
  boundedWidth: number;
}

const combineChartDimensions = (
  dimensions: ChartDimensions
): CombinedDimensions => {
  const parsedDimensions = {
    ...dimensions,
    marginTop: dimensions?.marginTop || 20,
    marginRight: dimensions?.marginRight || 20,
    marginBottom: dimensions?.marginBottom || 15,
    marginLeft: dimensions?.marginLeft || 50,
  };
  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height -
        parsedDimensions.marginTop -
        parsedDimensions.marginBottom,
      0
    ),
    boundedWidth: Math.max(
      parsedDimensions.width -
        parsedDimensions.marginLeft -
        parsedDimensions.marginRight,
      0
    ),
  };
};

export const useChartDimensions = (
  passedSettings?: Partial<ChartDimensions>
) => {
  const [ref, { width, height }] = useMeasure();

  const newSettings = combineChartDimensions({
    ...passedSettings,
    width: passedSettings?.width || width,
    height: passedSettings?.height || height,
  });

  return [ref, newSettings] as const;
};
