import { useMediaQuery } from '@material-ui/core';
import {
  FontSize,
  FontWeight,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { SmallCopyWrapper } from 'components/feature/PortfolioBuilder/_shared/OrderStatusTable/OrderStatusTable.styles';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components';

interface ItemDetailsProps {
  label: string;
  children: ReactNode;
  isNumber?: boolean;
  isError?: boolean;
}

export const ItemDetails = ({
  children,
  label,
  isNumber,
  isError = false,
}: ItemDetailsProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {isMdUp && (
        <TextXS
          $fontSize={FontSize.small}
          $fontWeight={FontWeight.light}
          $textAlign={isMdUp && isNumber ? TextAlign.right : undefined}
          $isError={isError}
          $noMargin
        >
          {children}
        </TextXS>
      )}
      {!isMdUp && (
        <SmallCopyWrapper>
          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
            {label}:
          </TextSmall>{' '}
          <TextSmall
            $noMargin
            $fontWeight={FontWeight.normal}
            $isError={isError}
          >
            {children}
          </TextSmall>
        </SmallCopyWrapper>
      )}
    </>
  );
};

interface DetailsContainerProps {
  $showDetails?: boolean;
  $completed?: boolean;
  $allCompleted?: boolean;
  $isError?: boolean;
}

export const RowContainer = styled.div<DetailsContainerProps>`
  width: 100%;
`;

export const OverviewContainerHeaderBaseStyles = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  gap: 0rem 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    grid-template-rows: auto;
    gap: 0rem 2%;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  padding: 0.5rem 0;
  align-items: center;
`;
interface OverviewContainerBaseStylesProps {
  $completed?: boolean;
}
export const OverviewContainerBaseStyles = css<OverviewContainerBaseStylesProps>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  gap: 0rem 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    grid-template-rows: auto;
    gap: 0rem 2%;
  }

  padding: 0.5rem 0 0.5rem 0.5rem;
  align-items: center;
  cursor: pointer;

  ${({ $completed }) =>
    $completed &&
    css`
      p {
        color: ${transparentize(0.5, colors.richBlack)};
        svg {
          color: ${transparentize(0.5, colors.richBlack)};
          &:first-of-type {
            color: ${colors.magenta};
          }
        }
      }

      &:hover {
        p {
          color: ${colors.richBlack};
          svg {
            color: ${colors.richBlack};
            &:first-of-type {
              color: ${colors.magenta};
            }
          }
        }
      }
    `}
`;

export const OverViewContainerSellHeader = styled.div<DetailsContainerProps>`
  grid-template-rows: auto auto auto auto auto auto;
  ${OverviewContainerHeaderBaseStyles}

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 35% 15% 14% 11% 10% 3%;
  }

  border-bottom: ${({ $showDetails }) =>
    $showDetails ? '0' : `1px solid ${colors.midGrey}`};
`;

export const OverviewSellContainer = styled.div<DetailsContainerProps>`
  ${OverviewContainerBaseStyles}
  padding: ${({ $showDetails }) => $showDetails && '0.5rem 0 0 0'};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0.5rem 0 0.5rem 0.5rem;
    grid-template-columns: 35% 15% 14% 11% 10% 3%;
  }

  border-bottom: ${({ $showDetails }) =>
    $showDetails ? '0' : `1px solid ${colors.midGrey}`};

  background-color: ${({ $isError }) =>
    $isError ? colors['red-25'] : 'transparent'};

  > p,
  > div a strong {
    color: ${({ $isError }) =>
      $isError ? colors['grey-900'] : colors.richBlack};
    &.error {
      color: ${colors.danger};
      font-weight: 500 !important;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.25rem;
    }
  }

  // for completed order lines

  &:hover {
    background-color: ${({ $showDetails, $isError }) =>
      $isError ? colors['red-50'] : $showDetails ? colors.white : colors.vapor};
  }
`;

export const OverViewContainerBuyHeader = styled.div<DetailsContainerProps>`
  grid-template-rows: auto auto auto auto auto auto;
  max-width: 100%;
  ${OverviewContainerHeaderBaseStyles}

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 35% 15% 14% 11% 10% 3%;
  }

  border-bottom: ${({ $showDetails }) =>
    $showDetails ? '0' : `1px solid ${colors.midGrey}`};
`;

export const OverviewBuyContainer = styled.div<DetailsContainerProps>`
  ${OverviewContainerBaseStyles}
  padding: ${({ $showDetails }) => $showDetails && '0.5rem 0 0 0'};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0.5rem 0 0.5rem 0.5rem;
    grid-template-columns: 35% 15% 14% 11% 10% 3%;
  }

  border-bottom: ${({ $showDetails }) =>
    $showDetails ? '0' : `1px solid ${colors.midGrey}`};

  background-color: transparent;

  &:hover {
    background-color: ${({ $showDetails }) =>
      $showDetails ? colors.white : colors.vapor};
  }
`;

export const DetailsContainer = styled.div<DetailsContainerProps>`
  overflow: hidden;
  max-height: ${({ $showDetails }) => ($showDetails ? '46rem' : '0')};
  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  // for completed order lines
  ${({ $completed }) =>
    $completed &&
    css`
      p,
      a {
        color: ${transparentize(0.5, colors.richBlack)};
      }
    `}
`;

export interface DetailsLayoutProps extends DetailsContainerProps {
  $mifidIllustrations: boolean;
}

export const DetailsLayout = styled.div<DetailsLayoutProps>`
  box-sizing: border-box;
  display: grid;
  border-bottom: ${({ $showDetails }) =>
    $showDetails ? `1px solid ${colors.midGrey}` : '0'};
  padding-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    gap: 0.5rem 1rem;
    padding: 1.5rem;
    border: ${({ $showDetails }) =>
      $showDetails ? `1px solid ${colors.grey}` : '0'};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-auto-flow: ${({ $mifidIllustrations }) =>
      $mifidIllustrations ? 'dense' : 'column'};
    grid-template-rows: ${({ $mifidIllustrations }) =>
      $mifidIllustrations ? 'auto' : 'auto auto'};
    grid-template-columns: ${({ $mifidIllustrations }) =>
      $mifidIllustrations ? 'auto' : 'auto auto auto auto auto auto'};
  }

  overflow: hidden;
  margin-bottom: 1rem;
`;

export const SellDetailsLayout = styled.div<DetailsContainerProps>`
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: row;
  border-bottom: ${({ $showDetails }) =>
    $showDetails ? `1px solid ${colors.midGrey}` : '0'};
  padding-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-auto-flow: row;
    gap: 0.5rem 1rem;
    padding: 1.5rem;
    border: ${({ $showDetails }) =>
      $showDetails ? `1px solid ${colors.grey}` : '0'};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto auto auto auto;
  }

  overflow: hidden;
  margin-bottom: 1rem;
`;

export const AmountExplainer = styled.span`
  width: 0.125rem;
  display: inline-block;
`;

export const ErrorContainer = styled.div`
  grid-column: 1 / -1;
  padding-top: 0.5rem;
  p.error {
    color: ${colors.danger};
  }
`;
