import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const VintageSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const VintageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${colors['magenta-100']};
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
`;

export const VintageHeader = styled(H6)`
  font-size: 1rem;
`;
