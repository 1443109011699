import { DialogActions, DialogContent } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import React from 'react';
import { ErrorIcon } from '../styles/Deposit.styles';

export interface WaitingPaymentTimeOutProps {
  onContactSupport: () => void;
}

export function WaitingPaymentTimeOut({
  onContactSupport,
}: WaitingPaymentTimeOutProps) {
  return (
    <>
      <StepTitle>
        <ErrorIcon />
        <br />
        Payment timeout
      </StepTitle>
      <DialogContent>
        <p>We will email you once the payment has been cleared.</p>
      </DialogContent>
      <DialogActions>
        <CustomButtonV2 $color="secondary" onClick={onContactSupport}>
          Contact Support
        </CustomButtonV2>
      </DialogActions>
    </>
  );
}
