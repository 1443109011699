import TillitIcon from 'assets/logo/qr_code_logo.png';
import EasyQRCode from 'easyqrcodejs';
import React, { useEffect, useRef } from 'react';

export interface QRCodeProps {
  value: string;
  size?: number;
}

export function QRCode({ value, size = 256 }: QRCodeProps) {
  const qrContainer = useRef<HTMLDivElement>(null);
  const qrCode = useRef<EasyQRCode | null>(null);

  const logoSize = Math.round(size * 0.39);

  useEffect(() => {
    qrCode.current?.clear();

    qrCode.current = new EasyQRCode(qrContainer.current, {
      text: value,
      correctLevel: EasyQRCode.CorrectLevel.H,
      logo: TillitIcon,
      logoWidth: logoSize,
      logoHeight: logoSize,
      width: size,
      height: size,
    });
  }, [value, size, logoSize]);

  return <div ref={qrContainer} />;
}
