import { colors } from 'constants/colors';
import styled from 'styled-components';

export const ComplianceTextContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  max-width: 680px;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${colors['purple-100']};
  background-color: ${colors['purple-25']};

  svg {
    font-size: 1.5rem;
    color: ${colors['purple-400']};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: auto 1fr;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
  }
`;
