import { WrapperType } from 'generated/graphql';
import {
  getAssetClassOfAsset,
  getAssetNameOfAsset,
  getRegionOfAsset,
} from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';
import { useCombinedBasketBreakdown } from './useCombinedBasketBreakdown';
import { useGetBasketBreakdown } from './useGetBasketBreakdown';
import { useGetCurrentBreakdown } from './useGetCurrentBreakdown';

export {
  getAssetClassOfAsset,
  getAssetNameOfAsset,
  getBondStyleOfAsset,
  getEquityStyleOfAsset,
  getRegionOfAsset,
} from './breakdownGroupingHelpers';
export type { GetAssetGroupString } from './breakdownGroupingHelpers';
export type { IncludeAssetType } from './includeAssetHelpers';
export { useCombinedBasketBreakdown } from './useCombinedBasketBreakdown';
export type {
  CombinedBreakdown,
  CombinedBreakdownType,
} from './useCombinedBasketBreakdown';
export { useCombinedRebalancingBreakdown } from './useCombinedRebalancingBreakdown';
export { useGetBasketBreakdown } from './useGetBasketBreakdown';
export { useGetCurrentBreakdown } from './useGetCurrentBreakdown';
export type { CurrentBreakdownType } from './useGetCurrentBreakdown';

export const useGetCurrentRegionBreakdown = (
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useGetCurrentBreakdown(accountType, getRegionOfAsset, includeAsset);
};

export const useGetCurrentAssetAllocationBreakdown = (
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useGetCurrentBreakdown(
    accountType,
    getAssetClassOfAsset,
    includeAsset
  );
};

export const useGetBasketRegionBreakdown = (selectedAccountId: string) => {
  return useGetBasketBreakdown(selectedAccountId, getRegionOfAsset);
};

export const useGetBasketAssetAllocationBreakdown = (
  selectedAccountId: string
) => {
  return useGetBasketBreakdown(selectedAccountId, getAssetClassOfAsset);
};

export const useCombinedRegionBreakdown = (
  selectedAccountId: string,
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useCombinedBasketBreakdown(
    selectedAccountId,
    accountType,
    getRegionOfAsset,
    includeAsset
  );
};

export const useCombinedAssetBreakdown = (
  selectedAccountId: string,
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useCombinedBasketBreakdown(
    selectedAccountId,
    accountType,
    getAssetClassOfAsset,
    includeAsset
  );
};

export const useCombinedFundBreakdown = (
  selectedAccountId: string,
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useCombinedBasketBreakdown(
    selectedAccountId,
    accountType,
    getAssetNameOfAsset,
    includeAsset
  );
};
