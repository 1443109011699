import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { UploadDocumentByRequestForm } from 'components/feature/uploadDocumentsByRequest/UploadDocumentByRequestForm';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { dashboardPath } from 'paths';
import { useHistory, useParams } from 'react-router-dom';

export function UploadDocumentToRequest() {
  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <StepFormWrapper>
      <StepWrapper>
        <UploadDocumentByRequestForm
          requestId={params.id}
          onCancel={() => history.push(dashboardPath)}
        />
      </StepWrapper>
    </StepFormWrapper>
  );
}
