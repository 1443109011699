import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const DialogTitle = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const ActionContainer = styled.div`
  width: 100%;
  max-width: 320px;
  margin: ${(p) => p.theme.spacing(3.75)}px auto ${(p) => p.theme.spacing(7)}px
    auto;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;
