import { AssetQueryAsset, SearchAssetsQueryAsset } from 'types/graphqlTypes';
export type targetDateInstrumentReturnType = {
  instrument:
    | AssetQueryAsset['instruments']['nodes'][0]
    | SearchAssetsQueryAsset['instruments']['nodes'][0]
    | null;
  startDate: number | null;
  endDate: number | null;
};

export const getTargetDateInstrument = (
  assetData: AssetQueryAsset | SearchAssetsQueryAsset,
  retirementYear: number
): targetDateInstrumentReturnType => {
  const targetFundInstruments = assetData.instruments.nodes.filter(
    (instrument) => instrument.isTargetDateFund
  );

  const TargetFundData: targetDateInstrumentReturnType[] = targetFundInstruments.map(
    (instrument) => {
      const startDate = instrument.targetDateRange?.start?.inclusive
        ? new Date(instrument.targetDateRange?.start?.value).getFullYear()
        : new Date(instrument.targetDateRange?.start?.value).getFullYear() + 1;
      const endDate = instrument.targetDateRange?.end?.inclusive
        ? new Date(instrument?.targetDateRange?.end?.value).getFullYear()
        : new Date(instrument?.targetDateRange?.end?.value).getFullYear() - 1;

      return {
        instrument: instrument,
        startDate: startDate || null,
        endDate: endDate || null,
      };
    }
  );

  const data: targetDateInstrumentReturnType | undefined = TargetFundData.find(
    (instrument) => {
      return (
        (!instrument.endDate &&
          instrument.startDate &&
          instrument.startDate <= retirementYear) ||
        (!instrument.startDate &&
          instrument.endDate &&
          instrument.endDate >= retirementYear) ||
        (instrument.startDate &&
          instrument.startDate <= retirementYear &&
          instrument.endDate &&
          instrument.endDate >= retirementYear)
      );
    }
  );

  return data ? data : { instrument: null, startDate: null, endDate: null };
};
