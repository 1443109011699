import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledA } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import { TillitLogo } from 'components/feature/openAccount/steps/ReferralWelcomeStep/ReferralWelcomeStep.styles';
import {
  CheckboxContainer,
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
} from 'components/feature/signIn/steps/ConfirmReferralStep/ConfirmReferralStep.styles';
import { Loading } from 'components/Loading';
import { useAuth } from 'context/AuthContext';
import {
  useApplyReferralCodeMutation,
  useReferralCodeQuery,
} from 'generated/graphql';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import {
  StepTitle,
  GoBackButton,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';

interface ConfirmReferralStepFormValues {
  optIntoComplianceDataSharing?: boolean;
}

export interface ConfirmReferralProps {
  referralCode: string;
  onProceed: (employerName?: string) => void;
  onGoBack?: () => void;
}

export function ConfirmReferral({
  referralCode,
  onProceed,
  onGoBack,
}: ConfirmReferralProps) {
  const { refreshSession } = useAuth();
  const { clearReferralCode } = useGetEmployerCode();

  const {
    mutateAsync: applyReferralCodeMutate,
    isLoading,
    isError,
  } = useApplyReferralCodeMutation();

  const {
    data: referralCodeData,
    isLoading: referralDataLoading,
  } = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        const hasEmployer = !!data.referralCode?.employer;
        const contractNoteSharing =
          data?.referralCode?.employer?.complianceConfiguration
            ?.contractNoteSharing?.enabled ?? false;

        if (hasEmployer && contractNoteSharing) {
          return;
        }

        clearReferralCode();
        // We're only handling employer referrals for pensions & contract note sharing at the moment.
        // If the referral code is not for a pension or doesn't require contract note sharing, we'll just proceed.
        onProceed();
      },
    }
  );

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;

  const complianceConfiguration =
    referralCodeData?.referralCode?.employer?.complianceConfiguration;
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: ConfirmReferralStepFormValues) => {
    try {
      await applyReferralCodeMutate({
        input: {
          code: referralCode,
          optIntoComplianceDataSharing: data.optIntoComplianceDataSharing,
        },
      });

      // Since we're now potentially connected to an employer, refresh our
      // authentication session so claims for other services contain
      // employer information
      await refreshSession();
      onProceed(employerName);
    } catch {
      // Error handled by state
    }
  };

  return referralDataLoading ? (
    <Loading />
  ) : (
    <ReferralWelcomeContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.wide}>
          <StepTitle>Link your account to {employerName}</StepTitle>
          {referralCodeData?.referralCode?.employer?.logoUrl && (
            <ReferralCoBrandingContainer>
              <TillitLogo />
              <AiOutlineClose />
              <CoBrandedLogo
                src={referralCodeData?.referralCode?.employer?.logoUrl}
                alt={employerName || ''}
              />
            </ReferralCoBrandingContainer>
          )}

          <TextNormal $textAlign={TextAlign.center}>
            <strong>{employerName}</strong> has offered to make pension
            contributions into your TILLIT Pension (a Self-Invested Personal
            Pension, or SIPP).
          </TextNormal>

          {contractNoteSharing && (
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    name="optIntoComplianceDataSharing"
                    inputRef={methods.register}
                  />
                }
                label={
                  complianceConfiguration?.policyLinkDescription ? (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their{' '}
                      {complianceConfiguration?.policyUrl ? (
                        <StyledA
                          href={complianceConfiguration?.policyUrl}
                          target="_blank"
                        >
                          {complianceConfiguration?.policyLinkDescription}{' '}
                          <HiExternalLink />
                        </StyledA>
                      ) : (
                        <>{complianceConfiguration?.policyLinkDescription}</>
                      )}
                    </>
                  ) : (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their trading policy
                    </>
                  )
                }
              />
            </CheckboxContainer>
          )}

          <ServerError isVisible={isError} />

          <ReferralActionsContainer>
            <CustomButtonV2 disabled={isLoading} type="submit" $isWide>
              Link to employer
            </CustomButtonV2>
            <GoBackButton onClick={() => onGoBack?.()} />
          </ReferralActionsContainer>
        </StepContent>
      </form>
    </ReferralWelcomeContainer>
  );
}
