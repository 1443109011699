import { CircularProgress } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const GraphContainer = styled.div`
  position: relative;
  width: auto;
  margin-right: ${(p) => p.theme.spacing(2.5)}px;
`;

export const CircularGraph = styled(CircularProgress)`
  color: ${colors.magenta};
  position: absolute;
  left: 0;
  //z-index: 1;
`;

export const CircularGraphBottom = styled(CircularProgress)`
  color: ${transparentize(0.8, colors.magenta)};
  z-index: 0;
`;

export const GraphValue = styled.div`
  font-size: 0.75rem;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(105%);
`;
