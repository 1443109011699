import { colors } from 'constants/colors';
import styled from 'styled-components';

import { Popper } from '@material-ui/core';
import { FiRefreshCcw } from 'react-icons/fi';
import { SectionShadow } from 'theme/shared/base.styles';

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
`;

export const RecurringIcon = styled(FiRefreshCcw)`
  font-size: 1rem;
  color: ${colors.richBlack};
  margin-left: 0.5rem;
`;

export const StyledPopper = styled(Popper)`
  z-index: ${(p) => p.theme.zIndex.tooltip};
  .MuiPaper-root {
    padding: ${(p) => p.theme.spacing(2)}px;
    max-width: ${(p) => p.theme.breakpoints.values.sm}px;
    background-color: ${colors.white};
    overflow: visible;
    ${SectionShadow};
    z-index: 0;
    max-width: 360px;

    .MuiTypography-root {
      color: ${colors.white};
    }
  }

  &[x-placement*='top'] {
    .MuiPaper-root {
      margin-bottom: 10px;
    }

    ${Arrow} {
      bottom: -10px;
      border-top: 10px solid ${colors.white};
      z-index: 1;
    }
  }

  &[x-placement*='bottom'] {
    .MuiPaper-root {
      margin-top: 10px;
    }

    ${Arrow} {
      top: -10px;
      border-bottom: 10px solid ${colors.white};
      z-index: 1;
    }
  }
`;
