import { colors } from 'constants/colors';
import React from 'react';
import { ChipContainer, ChipWrapperContainer } from './Chip.styles';

export enum ChipStyle {
  contained = 'contained',
  outlined = 'outlined',
}

export interface ChipProps {
  $style?: keyof typeof ChipStyle;
  $color?: string;
  children: JSX.Element | React.ReactNode | string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated - Use the <Pill> component instead. This component has a variety of options that
 * will prevent it from being accessible - the colours, onClick, $disabled - it should be avoided.
 *
 * @todo - delete this.
 */
export function Chip({
  $style = ChipStyle.contained,
  $color = colors.purple,
  disabled = false,
  active = false,
  onClick,
  children,
  ...props
}: ChipProps) {
  return (
    <ChipContainer
      $style={$style}
      $color={$color}
      $hasAction={onClick ? true : false}
      $active={disabled ? disabled : active}
      $disabled={disabled}
      onClick={() => onClick?.()}
      {...props}
    >
      {children}
    </ChipContainer>
  );
}

export interface ChipWrapperProps {
  children: JSX.Element | React.ReactNode | string;
  style?: React.CSSProperties;
}

export function ChipWrapper({ children, style, ...props }: ChipWrapperProps) {
  return (
    <ChipWrapperContainer style={style} {...props}>
      {children}
    </ChipWrapperContainer>
  );
}
