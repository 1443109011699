import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import {
  MinusIcon,
  PlusIcon,
  ShoppingBasketIcon,
} from 'components/feature/PortfolioBuilder/_shared/DpcIcons';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React, { useCallback, useMemo } from 'react';
import { AssetBreakdownComparison } from '../../helpers/deriveAssetBreakdownComparison';
import {
  CombinedBreakdown,
  useCombinedFundBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
  IconWrapper,
} from '../_shared/DrillDownTab.styles';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { SmallCopy } from 'components/Copy/SmallCopy';
import { TabTable } from 'components/TabTable/TabTable';
import { IncludeAssetType } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/includeAssetHelpers';
import { colors } from 'constants/colors';
import { NameTdInner, Table, Td, Th, Tr } from '../_shared/Table.styles';
import { EstimatedStatBoxes } from './_shared/EstimatedStatBoxes';

const regionDisplayLookup: Record<string, string> = {
  Global: 'global',
  UK: 'UK',
  US: 'US',
  Europe: 'European',
  Japan: 'Japan',
  Asia: 'Asia',
  'Asia ex-Japan': 'Asia ex-Japan',
  'Emerging markets': 'emerging market',
  'Frontier markets': 'frontier market',
};

const assetClassDisplayLookup: Record<string, string> = {
  Equities: 'equity',
  Bonds: 'bond',
  Property: 'property',
  'Alternative assets': 'property',
  'Multi-asset': 'equity',
};

type includeAsset = IncludeAssetType;

interface HoldingsTableProps {
  combinedFundBreakdown: CombinedBreakdown['combinedBreakdown'];
  footNote: React.ReactNode | JSX.Element | string;
}

export function HoldingsTable({
  combinedFundBreakdown,
  footNote,
}: HoldingsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const holdingsLabel = 'Est. holding (%)';
  const valueLabel = 'Est. value (£)';
  const changeLabel = 'Change (£)';

  const getName = useCallback(
    (breakdown: CombinedBreakdown['combinedBreakdown'][0]) => {
      const BuyIcon = (
        <IconWrapper>
          <ShoppingBasketIcon $type="buy" />
          <PlusIcon />
        </IconWrapper>
      );

      const SellIcon = (
        <IconWrapper>
          <ShoppingBasketIcon $type="sell" />
          <MinusIcon />
        </IconWrapper>
      );

      return (
        <>
          {breakdown.amount > breakdown.existingAmount && <>{BuyIcon} </>}
          {breakdown.amount < breakdown.existingAmount && <>{SellIcon} </>}
          {breakdown.name}
        </>
      );
    },
    []
  );

  const mobData = useMemo(() => {
    const getHoldingsData = () => {
      return combinedFundBreakdown.map(
        (breakdown: CombinedBreakdown['combinedBreakdown'][0]) => {
          return {
            name: getName(breakdown),
            value: format.percent(breakdown.percentage / 100),
          };
        }
      );
    };

    const getValueData = () => {
      return combinedFundBreakdown.map(
        (breakdown: CombinedBreakdown['combinedBreakdown'][0]) => {
          return {
            name: getName(breakdown),
            value: format.currencyFull(breakdown.amount),
          };
        }
      );
    };

    const getChangeData = () => {
      return combinedFundBreakdown.map(
        (breakdown: CombinedBreakdown['combinedBreakdown'][0]) => {
          return {
            name: getName(breakdown),
            value: format.currencyFull(
              breakdown.amount - breakdown.existingAmount
            ),
            valueColor:
              breakdown.amount - breakdown.existingAmount === 0
                ? colors.richBlack
                : breakdown.amount - breakdown.existingAmount > 0
                ? colors.success
                : colors.danger,
          };
        }
      );
    };

    return [
      {
        label: holdingsLabel,
        data: getHoldingsData(),
        extraInfo: footNote,
      },
      {
        label: valueLabel,
        data: getValueData(),
      },
      {
        label: changeLabel,
        data: getChangeData(),
      },
    ];
  }, [combinedFundBreakdown, footNote, getName]);

  if (isMobile) {
    return (
      <>
        <TabTable data={mobData} />
      </>
    );
  } else {
    return (
      <CardTableContainer>
        <Table>
          <thead>
            <tr>
              <Th>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Name
                </TextSmall>
              </Th>
              <Th>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Est. holding (%)
                </Text>
              </Th>
              <Th>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Est. value (£)
                </Text>
              </Th>
              <Th>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Change (£)
                </Text>
              </Th>
            </tr>
          </thead>
          <tbody>
            {combinedFundBreakdown.map((breakdown) => {
              return (
                <Tr
                  status={
                    breakdown.amount > breakdown.existingAmount
                      ? 'buying'
                      : breakdown.amount < breakdown.existingAmount
                      ? 'selling'
                      : 'neutral'
                  }
                >
                  <Td>
                    <NameTdInner>
                      {breakdown.amount > breakdown.existingAmount && (
                        <IconWrapper>
                          <ShoppingBasketIcon $type="buy" />
                          <PlusIcon />
                        </IconWrapper>
                      )}
                      {breakdown.amount < breakdown.existingAmount && (
                        <IconWrapper>
                          <ShoppingBasketIcon $type="sell" />
                          <MinusIcon />
                        </IconWrapper>
                      )}
                      <TextXS $noMargin>{breakdown.name}</TextXS>
                    </NameTdInner>
                  </Td>
                  <Td>
                    <Text
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $textAlign={TextAlign.right}
                      $noMargin
                    >
                      {format.percent(breakdown.percentage / 100)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $textAlign={TextAlign.right}
                      $noMargin
                    >
                      {format.currencyFull(breakdown.amount)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      $fontSize={FontSize.xs}
                      $fontWeight={FontWeight.light}
                      $textAlign={TextAlign.right}
                      $noMargin
                    >
                      {format.currencyFull(
                        breakdown.amount - breakdown.existingAmount
                      )}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </CardTableContainer>
    );
  }
}

interface HoldingsTabProps {
  selectedAccountType: WrapperType;
  includeAsset: includeAsset;
  activeBreakdownComparison: AssetBreakdownComparison;
  selectedAccountId: string;
}

export function HoldingsTab({
  selectedAccountId,
  selectedAccountType,
  includeAsset,
  activeBreakdownComparison,
}: HoldingsTabProps) {
  const { combinedBreakdown: combinedFundBreakdown } = useCombinedFundBreakdown(
    selectedAccountId,
    selectedAccountType,
    includeAsset
  );

  const breakDownName =
    assetClassDisplayLookup[activeBreakdownComparison.text] ??
    regionDisplayLookup[activeBreakdownComparison.text] ??
    activeBreakdownComparison.text.toLocaleLowerCase();

  const footnoteContent = `Please note that the 'Est. holding (%)' is calculated as a proportion of your estimated new ${breakDownName} allocation, not your total portfolio.`;

  return (
    <div>
      <TextNormal>{activeBreakdownComparison.text} holdings</TextNormal>
      <HoldingsTabInner>
        <HoldingsTable
          combinedFundBreakdown={combinedFundBreakdown}
          footNote={<SmallCopy $fontStyle="italic" copy={footnoteContent} />}
        />

        <HoldingsTabInfo>
          <EstimatedStatBoxes
            activeBreakdownComparison={activeBreakdownComparison}
          />
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
}
