import { yupResolver } from '@hookform/resolvers/yup';
import { tillitFetch } from 'api/tillitFetch';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { PasswordStrengthBreakdownIndicator } from 'components/design-system/Passwords/PasswordStrengthCheck';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { passwordSchema, ratePassword } from 'helpers/passwordValidation';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';

interface ResetPasswordApi {
  username: string;
  password: string;
  confirmPassword: string;
  code: string;
  userContext: string | null;
}

interface ConfirmResetPasswordResponse {
  destination: string;
  requireEmailVerify: boolean;
  requireMfa: boolean;
  session: string;
  success: boolean;
  userId: string;
  userIdIntegrity: string;
}

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

/**
 * react-query hook
 */
const useConfirmResetPassword = () => {
  return useMutation<any, Error, ResetPasswordApi>((data) => {
    return tillitFetch('identity/confirm-reset-password', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  });
};

const confirmStepSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .label('Verification code')
    .required()
    .length(6),
  newPassword: passwordSchema,
});

export interface ConfirmStepFormValues
  extends Yup.Asserts<typeof confirmStepSchema> {}

interface ConfirmStepProps {
  onProceed: (
    confirmResetPasswordRes: ConfirmResetPasswordResponse
  ) => Promise<void>;
  email: string;
}

export function ConfirmStep({ onProceed, email }: ConfirmStepProps) {
  const { mutateAsync } = useConfirmResetPassword();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const methods = useForm<ConfirmStepFormValues>({
    resolver: confirmStepSchema && yupResolver(confirmStepSchema),
  });
  const { watch, handleSubmit } = methods;

  const onSubmit = async (data: ConfirmStepFormValues) => {
    try {
      setErrorMessage(undefined);

      const userContext = getCognitoUserContextData(email);

      const res = await mutateAsync({
        username: email,
        password: data.newPassword,
        confirmPassword: data.newPassword,
        code: data.verificationCode,
        userContext,
      });
      if (res.status === 200) {
        const resData = (await res.json()) as ConfirmResetPasswordResponse;
        await onProceed(resData);
      } else {
        setErrorMessage('Something went wrong');
      }
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  const watchPassword = watch('newPassword');
  const passwordRating = ratePassword(watchPassword);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContainer>
          <StepContent>
            <StepTitle>Change password</StepTitle>
            <StepIntroduction mb={2} $width={StepIntroductionWidth.extraWide}>
              <StepIntroductionTypography>
                If the email address you entered matches a TILLIT account, an
                email with a six-digit verification code will be sent shortly.
                Please enter the verification code and your new password below:
              </StepIntroductionTypography>
            </StepIntroduction>
            {errorMessage && (
              <ErrorAlert severity={Severity.error}>{errorMessage}</ErrorAlert>
            )}
            <StepFormContainer>
              <TextField
                name="verificationCode"
                label="Verification code"
                fullWidth
              />
              <TextField
                name="newPassword"
                label="New password"
                type="password"
                fullWidth
              />
              <PasswordStrengthBreakdownIndicator
                breakdown={passwordRating.breakdown}
              />
            </StepFormContainer>
          </StepContent>
          <StepActions>
            <FormState>
              {({ isSubmitting }) => (
                <>
                  <StepButton
                    type="submit"
                    className="magenta"
                    disabled={isSubmitting || !passwordRating.pass}
                  >
                    Change password
                  </StepButton>
                </>
              )}
            </FormState>
          </StepActions>
        </StepContainer>
      </form>
    </FormProvider>
  );
}
