import { Card } from 'components/design-system/Card/Card';
import {
  FontWeight,
  TextLarge,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import React from 'react';
import styled from 'styled-components';
import { AssetBreakdownComparison } from '../../../helpers/deriveAssetBreakdownComparison';

import { InfoPopover } from 'components/Popover';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

interface EstimatedStatBoxesProps {
  activeBreakdownComparison: AssetBreakdownComparison;
}

const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

const ChangeIconUp = styled(AiOutlineArrowUp)`
  color: ${colors.success};
  width: 0.75rem;
`;

const ChangeIconDown = styled(AiOutlineArrowDown)`
  color: red;
  width: 0.75rem;
`;

interface ChangeAmountProps {
  change?: 'up' | 'down';
}
const ChangeAmount = styled(TextSmall)<ChangeAmountProps>`
  color: ${({ change }) => (change === 'up' ? colors.success : colors.danger)};
  margin: 0;
`;

/* TD: Forgive me */
const AbsoluteInfoPopoverContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const RelativeContainer = styled.div`
  position: relative;
  & > * {
    height: 100%;
  }
`;

/* TD: End forgive me */

const assetClassDisplayLookup: Record<string, string> = {
  Equities: 'equity',
  Bonds: 'bond',
  Property: 'property',
  'Alternative assets': 'property',
  'Multi-asset': 'equity',
};

const regionDisplayLookup: Record<string, string> = {
  Global: 'global',
  UK: 'UK',
  US: 'US',
  Europe: 'European',
  Japan: 'Japan',
  Asia: 'Asia',
  'Asia ex-Japan': 'Asia ex-Japan',
  'Emerging markets': 'emerging market',
  'Frontier markets': 'frontier market',
};

export const EstimatedStatBoxes = ({
  activeBreakdownComparison,
}: EstimatedStatBoxesProps) => {
  const breakDownName =
    regionDisplayLookup[activeBreakdownComparison.text] ??
    assetClassDisplayLookup[activeBreakdownComparison.text] ??
    activeBreakdownComparison.text.toLocaleLowerCase();

  return (
    <>
      <RelativeContainer>
        <Card>
          <TextXS $noMargin>{activeBreakdownComparison.text} value</TextXS>
          <TextLarge $noMargin $fontWeight={FontWeight.medium}>
            {format.currencyFull(activeBreakdownComparison.amount)}
          </TextLarge>
          {activeBreakdownComparison.amountChange !== 0 && (
            <Change>
              {activeBreakdownComparison.change > 0 ? (
                <ChangeIconUp />
              ) : (
                <ChangeIconDown />
              )}
              <ChangeAmount
                change={activeBreakdownComparison.change > 0 ? 'up' : 'down'}
              >
                {format.currencyFull(activeBreakdownComparison.amountChange)}
              </ChangeAmount>
            </Change>
          )}
          <AbsoluteInfoPopoverContainer>
            <InfoPopover
              color="primary"
              size="small"
              content={
                <>
                  <p>
                    Estimated total value of {breakDownName} holdings in your
                    projected portfolio.
                  </p>
                </>
              }
            />
          </AbsoluteInfoPopoverContainer>
        </Card>
      </RelativeContainer>
      <RelativeContainer>
        <Card>
          <TextXS $noMargin>{activeBreakdownComparison.text} allocation</TextXS>
          <TextLarge $noMargin $fontWeight={FontWeight.medium}>
            {format.percent(activeBreakdownComparison.end / 100)}
          </TextLarge>
          {activeBreakdownComparison.amountChange !== 0 && (
            <Change>
              {activeBreakdownComparison.change > 0 ? (
                <ChangeIconUp />
              ) : (
                <ChangeIconDown />
              )}
              <ChangeAmount
                change={activeBreakdownComparison.change > 0 ? 'up' : 'down'}
              >
                {format.percent(activeBreakdownComparison.change / 100)}
              </ChangeAmount>
            </Change>
          )}
          <AbsoluteInfoPopoverContainer>
            <InfoPopover
              color="primary"
              size="small"
              content={
                <>
                  <p>
                    Estimated {breakDownName} allocation relative to your
                    overall projected portfolio.
                  </p>
                </>
              }
            />
          </AbsoluteInfoPopoverContainer>
        </Card>
      </RelativeContainer>
    </>
  );
};
