import { Card } from 'components/design-system/Card/Card';
import { TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const RowContainer = styled(Card)`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 1rem;
`;

export const RowHeading = styled(TextNormal)`
  margin-right: auto;
  margin-bottom: 0;
`;

export const BoldSpanWrapper = styled.span`
  font-weight: 500;
`;

export const StandingOrderDetailsLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
  margin-top: 0.25rem;
  text-align: right;
`;
