import { useQuery, UseQueryOptions } from 'react-query';

const LOQATE_KEY = process.env.REACT_APP_LOQATE_KEY;

interface useLoqateRetrieveQueryProps {
  addressId: string | undefined | null;
}

interface AddressyRetrieveAddress {
  Id: string;
  DomesticId: string;
  Language: string;
  LanguageAlternatives: string;
  Department: string;
  Company: string;
  SubBuilding: string;
  BuildingNumber: string;
  BuildingName: string;
  SecondaryStreet: string;
  Street: string;
  Block: string;
  Neighbourhood: string;
  District: string;
  City: string;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Line5: string;
  AdminAreaName: string;
  AdminAreaCode: string;
  Province: string;
  ProvinceName: string;
  ProvinceCode: string;
  PostalCode: string;
  CountryName: string;
  CountryIso2: string;
  CountryIso3: string;
  CountryIsoNumber: string;
  SortingNumber1: string;
  SortingNumber2: string;
  Barcode: string;
  POBoxNumber: string;
  Label: string;
  Type: string;
  DataLevel: string;
  Field1: string;
  Field2: string;
  Field3: string;
  Field4: string;
  Field5: string;
  Field6: string;
  Field7: string;
  Field8: string;
  Field9: string;
  Field10: string;
  Field11: string;
  Field12: string;
  Field13: string;
  Field14: string;
  Field15: string;
  Field16: string;
  Field17: string;
  Field18: string;
  Field19: string;
  Field20: string;
}

/**
 *
 */
export function useLoqateRetrieveQuery({
  addressId,
}: useLoqateRetrieveQueryProps) {
  return useQuery<AddressyRetrieveAddress>(
    `address-${addressId}`,
    async () => {
      const addressFetch = await fetch(
        `https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Key=${LOQATE_KEY}&Id=${addressId}`,
        { method: 'post' }
      );
      const addressResponse = await addressFetch.json();
      return addressResponse.Items[0];
    },
    {
      enabled:
        addressId !== undefined && addressId !== null && addressId !== '',
    }
  );
}

interface useLoqateFindQueryProps {
  searchValue?: string | undefined;
  countries?: string | undefined;
  container?: string | undefined;
}

interface AddressyFindAddress {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
}

interface AddressyFindRes {
  Items: AddressyFindAddress[];
}

export function useLoqateFindQuery(
  { searchValue, countries, container }: useLoqateFindQueryProps,
  options: UseQueryOptions<AddressyFindRes>
) {
  return useQuery<AddressyFindRes, unknown>(
    `search-${searchValue}${container}`,
    async () => {
      const searchFetch = await fetch(
        `https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${LOQATE_KEY}${
          searchValue ? `&Text=${searchValue}` : ''
        }${countries ? `&Countries=${countries}` : ''}${
          container ? `&Container=${container}` : ''
        }`,
        { method: 'post' }
      );
      if (!searchFetch.ok) {
        throw new Error(searchFetch.statusText);
      }
      return searchFetch.json();
    },
    { enabled: searchValue !== '', ...options }
  );
}
