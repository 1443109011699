import TCurated from 'assets/backgrounds/Tillit_Curated.jpg';
import TCuratedTall from 'assets/backgrounds/Tillit_Curated_tall.jpg';
import TEmpower from 'assets/backgrounds/Tillit_Empower.jpg';
import TEmpowerTall from 'assets/backgrounds/Tillit_Empower_tall.jpg';
import TSimple from 'assets/backgrounds/Tillit_Simple.jpg';
import TSimpleTall from 'assets/backgrounds/Tillit_Simple_tall.jpg';
import TTransparent from 'assets/backgrounds/Tillit_Transparent.jpg';
import TTransparentTall from 'assets/backgrounds/Tillit_Transparent_tall.jpg';
import TTrust from 'assets/backgrounds/Tillit_Trust.jpg';
import TTrustTall from 'assets/backgrounds/Tillit_Trust_tall.jpg';
import { colors } from 'constants/colors';

export function useSelectAssetColor(name: string) {
  switch (name) {
    case 'Equities':
      return colors.purple;

    case 'Bonds':
      return colors.richBlue;

    case 'Property':
      return colors.magenta;

    case 'Alternative assets':
      return colors.seaBlue;

    case 'Multi-asset':
      return colors.mauve;

    default:
      return colors.richBlack;
  }
}

export function useSelectAssetBackground(name: string) {
  switch (name) {
    case 'Equities':
      return { landscape: TSimple, tall: TSimpleTall };

    case 'Bonds':
      return { landscape: TEmpower, tall: TEmpowerTall };

    case 'Property':
      return { landscape: TTrust, tall: TTrustTall };

    case 'Alternative assets':
      return { landscape: TTransparent, tall: TTransparentTall };

    case 'Multi-asset':
      return { landscape: TCurated, tall: TCuratedTall };

    default:
      return { landscape: TTransparent, tall: TTransparentTall };
  }
}

export function useAssetContrastColor(name: string) {
  return name === 'Multi-asset' || name === 'Alternative assets'
    ? colors.richBlack
    : colors.white;
}

export function useAssetBrandPack(name: string) {
  return {
    mainColor: useSelectAssetColor(name),
    copyColor: useAssetContrastColor(name),
    background: useSelectAssetBackground(name),
  };
}
