/**
 *   Subtract years from a date
 *   Returns a new date object with the specified number of years subtracted
 *   from the original date.
 *
 * @param date
 * @param years
 * @returns
 */
export function subtractYears(date: string | Date, years: number) {
  const dateCopy = typeof date === 'string' ? new Date(date) : date;
  dateCopy.setHours(0, 0, 0, 0);
  dateCopy.setFullYear(dateCopy.getFullYear() - years);
  return dateCopy;
}
