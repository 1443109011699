import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { useDepositDetailsByAccountQuery } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type DateFrequencyStepFormValues = {
  dayOfTheMonth: number | null;
};

const depositSchema = Yup.object().shape({
  dayOfTheMonth: Yup.number()
    .label('Day of the month')
    .typeError('Please enter a valid number')
    .integer()
    .nullable()
    .required()
    .min(1)
    .max(28),
});

export interface DayStepProps {
  accountId: string;
  onProceed: (dayOfTheMonth: number) => void;
  onGoBack: () => void;
  paymentDate?: number;
}

export function DayStep({
  accountId,
  onProceed,
  onGoBack,
  paymentDate,
}: DayStepProps) {
  const depositContextQuery = useDepositDetailsByAccountQuery({
    id: accountId,
  });
  const validRange =
    depositContextQuery.data?.depositDetailsByAccount?.validRange;

  const methods = useForm<DateFrequencyStepFormValues>({
    resolver: yupResolver(depositSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { dayOfTheMonth: paymentDate },
    context: {
      minAmount: validRange?.minimumPaymentAmount,
      maxAmount: validRange?.maximumPaymentAmount,
    },
  });

  const onSubmit = (formData: DateFrequencyStepFormValues) => {
    if (!formData.dayOfTheMonth) {
      return;
    }
    onProceed(formData.dayOfTheMonth);
  };

  return (
    <FormProvider {...methods}>
      <QueryState {...depositContextQuery}>
        {({ data }) => {
          return (
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StepContainer>
                <StepTitle>Day of the month</StepTitle>
                <StepIntroduction $width={StepIntroductionWidth.extraWide}>
                  <StepIntroductionTypography>
                    Enter the day of the month for your regular deposit.
                  </StepIntroductionTypography>
                </StepIntroduction>

                <StepFormContainer>
                  <TextField
                    name="dayOfTheMonth"
                    label="Day of the month"
                    helpText={`Please note the day of the month must be between the 1st and the 28th of every month.`}
                  />
                </StepFormContainer>

                <StepActions>
                  <StepButton type="submit" className="magenta">
                    Continue
                  </StepButton>
                  <GoBackButton
                    onClick={() => {
                      onGoBack();
                    }}
                  />
                </StepActions>
              </StepContainer>
            </form>
          );
        }}
      </QueryState>
    </FormProvider>
  );
}
