import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { OpenAccountFormPreAuth } from 'components/feature/openAccount/OpenAccountFormPreAuth';
import { useAuth } from 'context/AuthContext';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import Cookies from 'js-cookie';
import { appSignInPath } from 'paths';
import { useHistory } from 'react-router';
import { useEffectOnce } from 'usehooks-ts';

const ONBOARDING_STATUS_COOKIE_NAME = 'is_onboarding';

export function OpenAccountPreAuth() {
  const history = useHistory();
  const { signedIn } = useAuth();
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  useEffectOnce(() => {
    if (!signedIn && Cookies.get(ONBOARDING_STATUS_COOKIE_NAME) === 'true') {
      history.push(appSignInPath);
    }
  });

  return (
    <StepFormWrapper>
      <StepWrapper>
        <OpenAccountFormPreAuth />
      </StepWrapper>
    </StepFormWrapper>
  );
}
