import * as Yup from 'yup';

const UK_POSTCODE_REGEX = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;

export const addressSchema = Yup.object().shape({
  buildingNameOrNumber: Yup.string()
    .label('Name or number')
    .required()
    .default(''),
  line1OrStreet: Yup.string().label('Street').required().default(''),
  townOrCity: Yup.string().label('Town or city').required().default(''),
  postCode: Yup.string()
    .label('Postcode')
    .required()
    .when('countryIso', {
      is: 'GB',
      then: (s) => s.matches(UK_POSTCODE_REGEX, 'Must be a valid UK postcode'),
    })
    .default(''),
  countryIso: Yup.string().label('Country').default('GB').required().length(2),
});

export const addressesSchema = Yup.object().shape({
  addresses: Yup.array()
    .label('Addresses')
    .required()
    .min(1)
    .max(8)
    .of(addressSchema),
});

export interface AddressInput extends Yup.TypeOf<typeof addressSchema> {}
export interface AddressesFromInputs
  extends Yup.TypeOf<typeof addressesSchema> {}
export interface AddressesFormValues
  extends Yup.Asserts<typeof addressesSchema> {}
