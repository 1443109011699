import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${(p) => p.theme.breakpoints.down('md')} {
    white-space: nowrap;
  }
`;

interface TrProps {
  status?: 'buying' | 'selling' | 'neutral';
}

export const Tr = styled.tr<TrProps>`
  background: ${(props) => {
    if (props.status === 'buying') return '#0ACF9715';
    if (props.status === 'selling') return '#E5493815';
    return 'inherit';
  }};
`;

interface ThProps {
  $noBorder?: boolean;
}

export const Th = styled.th<ThProps>`
  ${({ $noBorder }) =>
    $noBorder ? '' : `border-bottom: 1px solid ${colors.darkGrey};`}

  padding: 0.5rem 0;
  text-align: left;
  &:not(:nth-child(1)) {
    padding-left: 1rem;
  }
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

interface TdProps {
  $lineHeight?: 'normal' | 'large';
}

export const Td = styled.td<TdProps>`
  border-bottom: 1px solid ${colors.midGrey};
  padding: 0.25rem 0;

  ${({ $lineHeight }) => $lineHeight === 'large' && `height: 3rem;`}

  &:not(:nth-child(1)) {
    padding-left: 1rem;
  }
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const NameTdInner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
