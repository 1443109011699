import { EmploymentType } from 'generated/graphql';
import { ComplianceTextContainer } from './PolicyPreTradeText.styles';
import { FontWeight, TextSmall } from 'components/design-system/Text/Text';
import { BsClipboard2Check } from 'react-icons/bs';

interface PolicyPreTradeTextProps {
  employment: EmploymentType;
}

export function PolicyPreTradeText({ employment }: PolicyPreTradeTextProps) {
  if (
    !employment.employer ||
    !employment.employer.complianceConfiguration?.policyPreTradeText
  ) {
    return null;
  }

  return (
    <ComplianceTextContainer>
      <BsClipboard2Check />
      <TextSmall $fontWeight={FontWeight.medium} $noMargin>
        {employment.employer.complianceConfiguration?.policyPreTradeText}
      </TextSmall>
    </ComplianceTextContainer>
  );
}
