import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { DepositDialog } from 'components/Dialogs/Deposit/DepositDialog';
import { StandingOrderDetails } from 'components/Dialogs/StandingOrderDetails/StandingOrderDetails';
import { QueryState } from 'components/QueryState';
import {
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType, useRecurringTransactionsQuery } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import {
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
  generateDashboardAccountAddCashPath,
  generateDashboardAccountPath,
} from 'paths';
import React, { useMemo, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { StandingOrderDetailsLink } from './AccountCard.style';
import { ActionContainer, StyledRickWarning } from './AccountSection.style';

interface RiskWarningProps {
  accountType: WrapperType;
  color?: string;
}

function RiskWarning({ accountType, color }: RiskWarningProps) {
  const copy =
    accountType === WrapperType.Isa ? (
      <>
        Capital at risk.
        <br />
        Tax treatment depends on an individual's circumstances and may be
        subject to change.
      </>
    ) : (
      <>Capital at risk.</>
    );
  return (
    <StyledRickWarning $Color={color ? color : colors.richBlack}>
      {copy}
    </StyledRickWarning>
  );
}

export interface ActionProps {
  isSection?: boolean;
  accountType: WrapperType;
  transferActive: boolean;
  regularInvestmentActive: boolean;
  onTransferAccount?: () => void;
  onRegularInvestment?: () => void;
}

export interface UnOpenActionProps extends ActionProps {
  canBeCreated?: boolean;
  isOnboarding?: boolean;
  onOpenAccount?: () => void;
  onFinishSetup?: () => void;
  onSupport?: () => void;
}

export function UnOpenAction({
  isSection = false,
  accountType,
  canBeCreated = true,
  isOnboarding = false,
  transferActive,
  onOpenAccount,
  onTransferAccount,
  onFinishSetup,
  onSupport,
}: UnOpenActionProps) {
  return (
    <>
      <SectionWrapper isSection={isSection}>
        <ButtonsWrapper $isHorizontal={isSection}>
          {isOnboarding && (
            <CustomButtonV2 $color="primary" onClick={() => onFinishSetup?.()}>
              Finish setup
            </CustomButtonV2>
          )}
          {!isOnboarding && canBeCreated && (
            <CustomButtonV2
              $color="primary"
              onClick={() => onOpenAccount?.()}
              disabled={!canBeCreated}
            >
              Open {getShortNameForWrapperType(accountType)}
            </CustomButtonV2>
          )}
          {!canBeCreated && accountType === WrapperType.Sipp && (
            <CustomButtonV2 $color="secondary" onClick={() => onSupport?.()}>
              Talk to support
            </CustomButtonV2>
          )}
          {transferActive && (
            <CustomButtonV2
              $color="purple"
              onClick={() => onTransferAccount?.()}
            >
              Transfer {accountType}
            </CustomButtonV2>
          )}
        </ButtonsWrapper>
      </SectionWrapper>
      <RiskWarning accountType={accountType} />
    </>
  );
}

export interface SupportActionProps {
  onSupport: () => void;
}

export function SupportAction({ onSupport }: SupportActionProps) {
  return (
    <ButtonsWrapper>
      <CustomButtonV2 $color="secondary" onClick={onSupport}>
        Talk to support
      </CustomButtonV2>
    </ButtonsWrapper>
  );
}

export interface OpenActionProps extends ActionProps {
  accountId: string | undefined | null;
  onRebalancing?: () => void;
}

export function OpenAction({
  accountId,
  accountType,
  regularInvestmentActive,
  transferActive,
  onTransferAccount,
  onRegularInvestment,
  onRebalancing,
}: OpenActionProps) {
  const handleAddCash = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (!accountId) {
      return;
    }
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
  };

  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId as string,
    },
    { enabled: !!accountId }
  );

  const hasRegularDeposits =
    recurringTransactionsQuery.data?.recurringTransactions?.deposits &&
    recurringTransactionsQuery.data?.recurringTransactions?.deposits?.length >
      0;

  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const returnPath =
    process.env.REACT_APP_BASE_URL +
    generateDashboardAccountAddCashPath({
      wrapperType: getPathSegmentForWrapperType(accountType),
    });

  return (
    <>
      {accountId && (
        <Route
          path={generateDashboardAccountAddCashPath({
            wrapperType: getPathSegmentForWrapperType(accountType),
          })}
        >
          <DepositDialog
            accountId={accountId}
            paymentId={paymentId}
            isPolling
            returnPath={returnPath}
            onClose={() => {
              history.push(
                generateDashboardAccountPath({
                  wrapperType: getPathSegmentForWrapperType(accountType),
                })
              );
            }}
            open
          />
        </Route>
      )}
      <ButtonsWrapper>
        <LinkCustomButton
          $color="primary"
          onClick={handleAddCash}
          to={generateDashboardAccountAddCashPath({
            wrapperType: getPathSegmentForWrapperType(accountType),
          })}
        >
          Add cash
        </LinkCustomButton>
        {transferActive && (
          <CustomButtonV2 $color="purple" onClick={() => onTransferAccount?.()}>
            Transfer {accountType}
          </CustomButtonV2>
        )}
        {regularInvestmentActive && (
          <>
            {(hasRegularDeposits || accountType === WrapperType.Sipp) && (
              <LinkCustomButton
                $color="secondary"
                to={generateAutoSaveInvestPathPath({
                  wrapperType: getPathSegmentForWrapperType(accountType),
                })}
                onClick={() => {
                  // @todo - trackGa
                }}
              >
                Manage regular deposit & order
              </LinkCustomButton>
            )}
            {!hasRegularDeposits && accountType !== WrapperType.Sipp && (
              <LinkCustomButton
                $color="secondary"
                to={{
                  pathname: generateAutoSaveInvestSubPath({
                    wrapperType: getPathSegmentForWrapperType(accountType),
                    action: 'create-deposit',
                  }),
                  state: { from: 'dashboard' },
                }}
                onClick={() => {
                  // @todo - trackGa
                }}
              >
                Set up regular deposit & order
              </LinkCustomButton>
            )}
          </>
        )}
        <CustomButtonV2 $color="seaBlue" onClick={() => onRebalancing?.()}>
          Rebalance account
        </CustomButtonV2>
      </ButtonsWrapper>
    </>
  );
}

export interface OpenSectionActionProps extends ActionProps {
  accountId?: string;
  onWithdrawCash: () => void;
}

export function OpenSectionAction({
  isSection = true,
  accountId,
  accountType,
  transferActive,
  regularInvestmentActive,
  onWithdrawCash,
  onTransferAccount,
  onRegularInvestment,
}: OpenSectionActionProps) {
  const [
    showStandingOrderDetailsDialog,
    setShowStandingOrderDetailsDialog,
  ] = useState<boolean>(false);
  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId!,
    },
    { enabled: !!accountId }
  );

  const hasRegularDeposits =
    recurringTransactionsQuery.data?.recurringTransactions?.deposits &&
    recurringTransactionsQuery.data?.recurringTransactions?.deposits?.length >
      0;

  const handleAddCash = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
  };

  const handleViewStandingOrderDetails = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setShowStandingOrderDetailsDialog(true);
  };

  const handleCloseStandingOrderDetailsDialog = () => {
    setShowStandingOrderDetailsDialog(false);
  };

  return (
    <>
      <ActionContainer>
        <ButtonsWrapper $isHorizontal={isSection}>
          {regularInvestmentActive && (
            <>
              {(hasRegularDeposits || accountType === WrapperType.Sipp) && (
                <LinkCustomButton
                  $color="secondary"
                  to={generateAutoSaveInvestPathPath({
                    wrapperType: getPathSegmentForWrapperType(accountType),
                  })}
                  onClick={() => {
                    // @todo - trackGa
                  }}
                >
                  Manage regular deposit & order
                </LinkCustomButton>
              )}
              {!hasRegularDeposits && accountType !== WrapperType.Sipp && (
                <LinkCustomButton
                  $color="secondary"
                  to={{
                    pathname: generateAutoSaveInvestSubPath({
                      wrapperType: getPathSegmentForWrapperType(accountType),
                      action: 'create-deposit',
                    }),
                    state: { from: 'dashboard' },
                  }}
                  onClick={() => {
                    // @todo - trackGa
                  }}
                >
                  Set up regular deposit & order
                </LinkCustomButton>
              )}
            </>
          )}
          {transferActive && (
            <CustomButtonV2
              $color="purple"
              onClick={() => onTransferAccount?.()}
            >
              Transfer {accountType}
            </CustomButtonV2>
          )}
          {accountType !== WrapperType.Sipp && (
            <CustomButtonV2 $color="secondary" onClick={onWithdrawCash}>
              Withdraw cash
            </CustomButtonV2>
          )}
          <LinkCustomButton
            $color="primary"
            onClick={handleAddCash}
            to={generateDashboardAccountAddCashPath({
              wrapperType: getPathSegmentForWrapperType(accountType),
            })}
          >
            Add cash
          </LinkCustomButton>
        </ButtonsWrapper>
      </ActionContainer>

      <QueryState {...recurringTransactionsQuery}>
        {(data) => {
          const hasRegularDeposits =
            (data?.data?.recurringTransactions?.deposits?.length || 0) > 0;
          return hasRegularDeposits ? (
            <>
              <StandingOrderDetails
                accountId={accountId!}
                wrapperType={accountType}
                open={showStandingOrderDetailsDialog}
                onClose={handleCloseStandingOrderDetailsDialog}
              />
              <StandingOrderDetailsLink
                onClick={handleViewStandingOrderDetails}
              >
                View standing order details
              </StandingOrderDetailsLink>
            </>
          ) : null;
        }}
      </QueryState>
      <RiskWarning accountType={accountType} />
    </>
  );
}

export interface ClosingActionProps {
  isSection?: boolean;
  value: number;
  accountType: WrapperType;
  onWithdrawCash: () => void;
  onSupport: () => void;
}

export function ClosingAction({
  isSection = false,
  value,
  accountType,
  onWithdrawCash,
  onSupport,
}: ClosingActionProps) {
  return (
    <>
      <SectionWrapper isSection={isSection}>
        <ButtonsWrapper $isHorizontal={isSection}>
          <CustomButtonV2 $color="purple" onClick={onSupport}>
            Talk to support
          </CustomButtonV2>
          <CustomButtonV2
            $color="secondary"
            disabled={value > 0 ? false : true}
            onClick={onWithdrawCash}
          >
            Withdraw
          </CustomButtonV2>
        </ButtonsWrapper>
      </SectionWrapper>
    </>
  );
}

export interface ClosedActionsProps {
  isSection?: boolean;
  accountType: WrapperType;
  value: number;
  onWithdrawCash: () => void;
  onReOpen: () => void;
}

export function ClosedActions({
  isSection = false,
  accountType,
  value,
  onWithdrawCash,
  onReOpen,
}: ClosedActionsProps) {
  return (
    <>
      <SectionWrapper isSection={isSection}>
        <ButtonsWrapper $isHorizontal={isSection}>
          <CustomButtonV2 $color="primary" onClick={onReOpen}>
            Re-open {accountType}
          </CustomButtonV2>
          {value > 0 && (
            <CustomButtonV2
              variant="outlined"
              $color="white"
              onClick={onWithdrawCash}
            >
              Withdraw cash
            </CustomButtonV2>
          )}
        </ButtonsWrapper>
      </SectionWrapper>
    </>
  );
}

interface SectionWrapperProps {
  isSection: boolean;
  children: React.ReactNode;
}

function SectionWrapper({ isSection, children }: SectionWrapperProps) {
  return isSection ? (
    <ActionContainer>{children}</ActionContainer>
  ) : (
    <>{children}</>
  );
}
