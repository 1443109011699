import { RatingProps } from '@material-ui/lab/Rating';
import React from 'react';
import { HiOutlineStar, HiStar } from 'react-icons/hi';
import { StarRatingContainer } from './StarRating.style';

export interface StarRatingProps {
  name: string;
  value: number | null;
  size?: RatingProps['size'];
  precision?: number;
  readOnly?: boolean;
  max?: number;
  onSelection: (selection: number | null) => void;
}

export function StarRating({
  name,
  value,
  size = 'large',
  precision = 1,
  readOnly = false,
  max = 5,
  onSelection,
}: StarRatingProps) {
  return (
    <StarRatingContainer
      name={name}
      value={value}
      size={size}
      precision={precision}
      icon={<HiStar />}
      emptyIcon={<HiOutlineStar />}
      readOnly={readOnly}
      max={max}
      onChange={(_, newValue) => onSelection(newValue)}
    />
  );
}
