import { getBuyEstimate } from 'components/Dialogs/Trading/helpers';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { Loading } from 'components/Loading';
import { StyledLink } from 'components/design-system/Link';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import { FontSize, TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { Instrument } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { secclOrderExecutionPolicy, termsAndConditions } from 'paths';
import { useState } from 'react';
import { HiChevronDown, HiChevronUp, HiExternalLink } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import { getKidDocName } from '../_shared/copyHelpers';
import { LegacyCostsAndChargesSection } from './LegacyCostsAndChargesSection';
import { MiFIDCostsAndChargesSection } from './MiFIDCostsAndChargesSection';
import {
  Content,
  SubSectionWrapper,
  TopBar,
} from './_shared/ImportantInformation.styles';
import {
  BuyCharges,
  ExistingPosition,
} from './_shared/ImportantInformation.types';

interface ImportantBuyInfoProps {
  title?: string;
  showTopBar?: boolean;
  selectedInstrument: Pick<
    Instrument,
    | 'askPrice'
    | 'minimumTradeUnit'
    | 'quoteUnit'
    | 'ongoingChargePercent'
    | 'kiidDocumentUrl'
    | 'instrumentType'
    | 'factsheetUrl'
    | 'priceDate'
    | 'instrumentType'
  >;
  amountEntered: number;
  existingPosition?: ExistingPosition;
  charges: BuyCharges;
}
export const ImportantBuyInformation = ({
  title = 'Important information',
  showTopBar = true,
  selectedInstrument,
  amountEntered,
  existingPosition,
  charges,
}: ImportantBuyInfoProps) => {
  const [isExpanded, setIsExpanded] = useState(!showTopBar);

  const [useMifidIllustrations, mifidIllustrationsQuery] = useToggle(
    'global-use-mifid-illustrations'
  );

  // Units and Amounts
  const unitsExisting = existingPosition?.quantity ?? 0;
  const unitsPurchased =
    (selectedInstrument &&
      charges &&
      amountEntered &&
      getBuyEstimate(selectedInstrument, amountEntered, charges)) ||
    0;

  const unitsEstTotal = unitsExisting + unitsPurchased;

  const amountExisting = existingPosition?.currentValue ?? 0;
  const amountEstTotal = amountExisting + (amountEntered || 0);

  // Fees and Price
  const askPrice = selectedInstrument?.askPrice ?? 0;

  // URLs and Docs
  const kiidUrl = selectedInstrument?.kiidDocumentUrl;
  const kiidDocName = getKidDocName(selectedInstrument?.instrumentType!);
  const factsheetUrl = selectedInstrument?.factsheetUrl;

  const trackPopoverClick = (popover: string) => {
    trackGa({
      event: GaEventNames.popover,
      content_type: `buy info - ${popover}`,
    });
  };

  const costsAndChargesTab = !mifidIllustrationsQuery.isFetched ? (
    <Loading />
  ) : useMifidIllustrations?.enabled ? (
    <MiFIDCostsAndChargesSection
      amountEntered={amountEntered}
      selectedInstrument={selectedInstrument}
      trackPopoverClick={trackPopoverClick}
    />
  ) : (
    <LegacyCostsAndChargesSection
      amountEntered={amountEntered}
      charges={charges}
      selectedInstrument={selectedInstrument}
      trackPopoverClick={trackPopoverClick}
    />
  );

  return (
    <div>
      {showTopBar && (
        <TopBar type="button" onClick={() => setIsExpanded(!isExpanded)}>
          {title}
          {isExpanded ? (
            <HiChevronUp size="1.5rem" />
          ) : (
            <HiChevronDown size="1.5rem" />
          )}
        </TopBar>
      )}

      <Content isExpanded={isExpanded}>
        <Tabs
          isBorderless={true}
          title={<></>}
          tabs={[
            {
              title: 'Trade details',
              content: (
                <>
                  <SubSectionWrapper>
                    <KeyValueRow
                      label={'Latest buy price'}
                      description={tooltips.LatestBuyPrice}
                      value={format.currencyFull(askPrice)}
                      onPopoverClick={() =>
                        trackPopoverClick('Latest buy price')
                      }
                      noMargin
                      stackMobile
                    />
                    {selectedInstrument.priceDate && (
                      <KeyValueRow
                        label={'Latest price update'}
                        description={tooltips.LatestBuyPriceUpdate}
                        value={format.date(selectedInstrument.priceDate)}
                        onPopoverClick={() =>
                          trackPopoverClick('Latest price update')
                        }
                        noMargin
                        stackMobile
                      />
                    )}
                    <KeyValueRow
                      label={`Est. new holding (${format.currencyFull(
                        amountEstTotal
                      )})`}
                      value={`${format.units(unitsEstTotal)} ${
                        selectedInstrument?.instrumentType === 'Fund'
                          ? 'units'
                          : 'shares'
                      }`}
                      noMargin
                      stackMobile
                    />
                  </SubSectionWrapper>
                </>
              ),
            },
            {
              title: 'Costs & charges',
              content: costsAndChargesTab,
            },
            {
              title: 'Docs',
              content: (
                <SubSectionWrapper>
                  <TextNormal $noMargin $fontSize={FontSize.normal}>
                    <StyledLink href={termsAndConditions} target="_blank">
                      Terms & Conditions <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                  <TextNormal $noMargin $fontSize={FontSize.normal}>
                    <StyledLink
                      href={secclOrderExecutionPolicy}
                      target="_blank"
                    >
                      Order Execution Policy (Seccl) <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                  {!!kiidUrl && (
                    <TextNormal $noMargin $fontSize={FontSize.normal}>
                      <StyledLink href={kiidUrl!} target="_blank">
                        {kiidDocName} <HiExternalLink />
                      </StyledLink>
                    </TextNormal>
                  )}
                  {!!factsheetUrl && (
                    <TextNormal $noMargin>
                      <StyledLink href={factsheetUrl} target="_blank">
                        Factsheet <HiExternalLink />
                      </StyledLink>
                    </TextNormal>
                  )}
                </SubSectionWrapper>
              ),
            },
          ]}
        />
      </Content>
    </div>
  );
};
