import {
  buttonSizeOptions,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import { AddBuyOrderToBasketDialog } from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketDialog';
import {
  AddBuyOrderToBasketFullFlowDialog,
  useDialog,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketFullFlowDialog';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { QueryState } from 'components/QueryState';
import { Position, WrapperType } from 'generated/graphql';
import { useAnyAccountCanBuy } from 'hooks/useAccountCapabilities';
import { PartialDeep } from 'type-fest';

export interface BuyButtonProps {
  assetId: string;
  selectedIsin?: string;
  small?: boolean;
  size?: buttonSizeOptions;
  label?: string;
  hover?: boolean;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedPosition?: PartialDeep<Position>;
  onClick?: () => void;
  handleInstrumentChange?: (isin: string) => void;
}

export const BuyButton = ({
  assetId,
  size = 'normal',
  label = 'Invest',
  hover = true,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  selectedIsin: isin,
  handleInstrumentChange,
}: BuyButtonProps) => {
  const dialogProps = useDialog();

  const handleAddToBasket = () => {
    dialogProps.openDialog();
  };

  const anyAccountCanBuyQuery = useAnyAccountCanBuy();

  const basketType = useWhichBasketType();

  return (
    <QueryState
      {...anyAccountCanBuyQuery}
      loadingComponent={
        <CustomButtonV2
          $color="primary"
          $size={size}
          $noHover={false}
          disabled={true}
        >
          {label}
        </CustomButtonV2>
      }
    >
      {() => {
        return (
          <>
            {basketType === 'AccountBasket' ? (
              <AddBuyOrderToBasketFullFlowDialog
                {...dialogProps}
                assetId={assetId}
                selectedAccountId={selectedAccountId}
                selectedAccountType={selectedAccountType}
                selectedPosition={selectedPosition}
                selectedIsin={isin}
                handleInstrumentChange={handleInstrumentChange}
              />
            ) : (
              <AddBuyOrderToBasketDialog
                {...dialogProps}
                assetId={parseInt(assetId)}
                selectedIsin={isin}
                handleInstrumentChange={handleInstrumentChange}
              />
            )}

            <CustomButtonV2
              $color="primary"
              $size={size}
              $noHover={!hover}
              onClick={handleAddToBasket}
            >
              {label}
            </CustomButtonV2>
          </>
        );
      }}
    </QueryState>
  );
};
