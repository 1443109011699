import { getTargetDateInstrument } from 'components/feature/FundDetails/helpers/getTargetDateInstrument';
import { useTargetDateInfo } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { AssetQueryAsset } from 'types/graphqlTypes';

interface useMatchesAllocatedVintageProps {
  isin?: string;
  asset: AssetQueryAsset;
}

/**
 * Props for the useMatchesAllocatedVintage hook.
 * @typedef {Object} useMatchesAllocatedVintageProps
 * @property {string} isin - The International Securities Identification Number.
 * @property {number} assetId - The ID of the asset.
 */

/**
 * Type for the target date instrument return value.
 * @typedef {Object} targetDateInstrumentReturnType
 * @property {Object} instrument - The instrument object.
 * @property {string} instrument.isin - The ISIN of the instrument.
 */

/**
 * Custom hook to check if the allocated vintage matches the target date instrument.
 * @param {useMatchesAllocatedVintageProps} props - The props for the hook.
 * @returns {Object} An object containing the match status and instrument information.
 * @property {boolean} matches - Indicates if the ISIN matches the target date instrument.
 * @property {targetDateInstrumentReturnType|null} targetDateInstrument - The target date instrument.
 * @property {targetDateInstrumentReturnType|null} selectedInstrument - The selected instrument.
 */

export function useMatchesAllocatedVintage({
  isin,
  asset,
}: useMatchesAllocatedVintageProps) {
  const { targetDateInfo, savedTargetDateInfo } = useTargetDateInfo();
  const savedRetirementYear =
    (savedTargetDateInfo?.yearOfBirth || 0) +
    (savedTargetDateInfo?.intendedRetirementAge || 0);
  const retirementYear =
    (targetDateInfo?.yearOfBirth || 0) +
    (targetDateInfo?.intendedRetirementAge || 0);

  const targetDateInstrument = getTargetDateInstrument(
    asset,
    savedRetirementYear
  );
  const selectedInstrument = getTargetDateInstrument(asset, retirementYear);

  return {
    matches: isin === targetDateInstrument?.instrument?.isin,
    targetDateInstrument,
    selectedInstrument,
  };
}
