import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Select, SelectProps } from './Select';

export interface SelectFieldProps extends SelectProps {
  label: string;
}

export function SelectField({ name, label, ...other }: SelectFieldProps) {
  const { watch } = useFormContext();
  const watchField = watch(name);
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor={name}
        id={`${name}-label`}
        shrink={!!watchField && !!watchField?.length}
      >
        {label}
      </InputLabel>
      <Select name={name} {...other} />
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText id={name + '-helper-text'} error={true}>
            {message}
          </FormHelperText>
        )}
      />
    </FormControl>
  );
}
