import { colors } from 'constants/colors';
import React from 'react';
import styled, { css } from 'styled-components';

const paddingLookup = {
  none: '0',
  extraSmall: '16px',
  small: '20px',
  medium: '32px',
  large: '40px',
  extraLarge: '64px',
};

type paddingOptions = keyof typeof paddingLookup;

interface CardInnerProps {
  $paddingSize?: paddingOptions;
  fullCardPointer?: boolean;
  as?: string | React.ComponentType<any> | undefined;
}

const cardInnerStyle = css<CardInnerProps>`
  overflow: hidden;
  border: solid 1px ${colors.midGrey};
  border-radius: 10px;
  background: ${colors.white};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  padding: ${paddingLookup.small};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ $paddingSize }) =>
      $paddingSize ? paddingLookup[$paddingSize] : paddingLookup.small};
  }
`;

export const CardInner = styled.div<CardInnerProps>`
  ${cardInnerStyle}
  ${({ as, fullCardPointer }) =>
    as === 'button' || fullCardPointer
      ? 'cursor: pointer;'
      : 'cursor: default;'}
`;

export const CardInnerAnchor = styled.a<CardInnerProps>`
  ${cardInnerStyle}
  text-decoration: none;
`;

type CardInnerType = typeof CardInner;
type CardInnerFullProps = Parameters<CardInnerType>[0];

export type CardProps = CardInnerFullProps & {
  children: React.ReactNode;
  onClick?: () => void;
};

/**
 *  Card component will render passed children.  Use the following properties to
 *  control padding, border, and shadow
 *
 * @prop {paddingOptions} [$paddingSize] - size of padding used
 * @prop {fullCardPointer} [boolean] - if true, cursor is set to pointer
 */
export const Card = ({
  children,
  $paddingSize,
  onClick,
  fullCardPointer = false,
  as,
  ...props
}: CardProps) => {
  return (
    <CardInner
      as={as}
      $paddingSize={$paddingSize}
      onClick={onClick}
      fullCardPointer={fullCardPointer}
      {...props}
    >
      {children}
    </CardInner>
  );
};

const gapLookup = {
  small: '0.875rem',
  normal: '1.5rem',
};

type gapSizeOptions = keyof typeof gapLookup;

interface ContainerProps {
  $gapSize?: gapSizeOptions;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gapSize }) => ($gapSize ? gapLookup[$gapSize] : gapLookup.normal)};
`;

interface CardContainerProps extends ContainerProps {
  children: React.ReactNode;
}

/**
 * Container element used to wrap around lists of Card elements
 *
 * @prop {gapSizeOptions} [$gapSize] - `'small'` 14px, `'normal'` 24px (default)
 */
export const CardContainer = ({
  children,
  $gapSize,
  ...props
}: CardContainerProps) => {
  return (
    <Container $gapSize={$gapSize} {...props}>
      {children}
    </Container>
  );
};
