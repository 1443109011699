import { colors } from 'constants/colors';
import styled from 'styled-components';

export const NavContainer = styled.nav`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  margin: ${(p) => p.theme.spacing(7.5)}px auto;
  padding: 0 ${(p) => p.theme.spacing(1.25)}px;
  display: flex;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 0 ${(p) => p.theme.spacing(2.5)}px;
  }
`;

export const NavSeparator = styled.span`
  margin-left: 2rem;
  padding-left: 1rem;
  border-left: solid 1px ${colors.black};
  height: 25px;
  align-self: center;
`;

export const NavListItem = styled.li`
  list-style: none;
  padding: ${(p) => p.theme.spacing(0.75)}px ${(p) => p.theme.spacing(2.5)}px;
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.richBlack};

  &:hover {
    cursor: pointer;
    color: ${colors.magenta};
  }

  &.active {
    color: ${colors.magenta};
    font-weight: 500;
    &:hover {
      color: ${colors.richBlack};
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1.125rem;
  }
`;

export const NavListItemCta = styled.li`
  list-style: none;
  padding: ${(p) => p.theme.spacing(0.75)}px ${(p) => p.theme.spacing(2.5)}px;
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.white};
  background-color: ${colors.magenta};

  &:hover {
    cursor: pointer;
  }

  &.active {
    font-weight: 500;
    text-decoration: underline;
    &:hover {
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1.125rem;
  }
`;
