import { Container } from '@material-ui/core';
import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/**
 * OrderBar
 *
 * sticky bottom bar semi-transparent white background
 */
export const OrderBar = styled.div`
  position: sticky;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid ${colors.midGrey};
  z-index: ${({ theme }) => theme.zIndex.other};
`;

export const OrderBarContainer = styled(Container)`
  &.MuiContainer-root {
    padding: 0 1.5rem;
  }
`;

export const OrderBarInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const OrderBarChildren = styled.div`
  display: flex;
  justify-content: stretch;
  ${(p) => p.theme.breakpoints.up('md')} {
    justify-content: flex-end;
  }
`;

interface RemainingAmountProps {
  isNegative?: boolean;
}
export const RemainingAmount = styled.span<RemainingAmountProps>`
  ${({ isNegative = false }) =>
    isNegative ? `color: ${colors.danger}` : `color: ${colors.richBlack}`};
`;

export const NoMatchCopy = styled.div`
  > p {
    color: #8c8c8c;
  }
  text-align: center;
  padding-top: 5rem;
`;

export const AddCashButton = styled(Link)`
  cursor: pointer;
  font-weight: 300;
  text-decoration: underline;
  font-size: 0.875rem;
  color: ${colors.black};
  padding: 1px 0.25rem;
`;

export const StyledLink = styled(Link)`
  a&,
  a&:visited,
  a&:hover,
  a&:focus {
    color: ${colors.magenta};
    text-decoration: underline;
    font-weight: 300;
  }

  &:hover {
    color: ${colors.magenta};
  }
`;
