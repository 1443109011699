import { Asset, AssetClass, Tag } from 'generated/graphql';

export type GetAssetGroupString = (
  asset?:
    | (Pick<Asset, 'name'> & {
        assetClass?: Pick<AssetClass, 'name'> | null;
        tags: { nodes: Array<Pick<Tag, 'categoryCode' | 'code' | 'name'>> };
      })
    | null
) => string;

const equityStyles = ['Growth', 'High growth', 'Value', 'Deep value'];
const equityStylesMap: any = {
  'High growth': 'Growth',
  'Deep value': 'Value',
};
export const getEquityStyleOfAsset: GetAssetGroupString = (asset) => {
  if (!asset) {
    return 'Unknown';
  }

  const tags = asset?.tags?.nodes;
  const equityStyle =
    tags
      .filter(
        ({ categoryCode, name }) =>
          categoryCode === 'EQSTYLE' && equityStyles.includes(name)
      )
      .map((tag) =>
        equityStylesMap[tag?.name] ? equityStylesMap[tag?.name] : tag?.name
      )
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      })
      .join(', ') || 'Unknown';

  return equityStyle;
};

export const getBondStyleOfAsset: GetAssetGroupString = (asset) => {
  if (!asset) {
    return 'Unknown';
  }

  const tags = asset?.tags?.nodes;
  const bondsStyle =
    tags
      .filter(({ categoryCode }) => categoryCode === 'BONDSTYLE')
      .map((tag) => tag?.name)
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      })
      .join(', ') || 'Unknown';

  return bondsStyle;
};

export const getRegionOfAsset: GetAssetGroupString = (asset) => {
  if (!asset) {
    return 'Unknown';
  }

  const tags = asset?.tags?.nodes;
  const geographyTags = tags.filter(
    ({ categoryCode }) => categoryCode === 'GEOG'
  );

  const emergingMarketTag = geographyTags.find(
    ({ code }) => code === 'EMERGING'
  );
  if (emergingMarketTag) {
    return emergingMarketTag.name;
  } else {
    return geographyTags.map((tag) => tag?.name).join(', ') || 'Unknown';
  }
};

export const getAssetClassOfAsset: GetAssetGroupString = (asset) => {
  if (!asset) {
    return 'Unknown';
  }
  return asset?.assetClass?.name || 'Unknown';
};

export const getAssetNameOfAsset: GetAssetGroupString = (asset) => {
  if (!asset) {
    return 'Unknown';
  }
  return asset?.name || 'Unknown';
};
