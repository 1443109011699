import { Container } from '@material-ui/core';
import Styled from 'styled-components';

export const ActionsContainer = Styled(Container)`
  display: flex;
  align-items: center;
  padding: 30px 0 0 0;
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-bottom: ${(p) => p.theme.spacing(3.75)}px;
  }
`;

export const ActionContainerInner = Styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center
  align-items: center;
  padding: 0;
  gap: 24px;

`;

export const ButtonsContainer = Styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
  button {
    width: 100%;
    height: 38px;
    border-radius: 5px !important;

    ${(p) => p.theme.breakpoints.up('sm')} {
      width: auto;
      min-width: 180px;
      height: 34px;
    }
    span {
      gap: 10px;
      svg {
        margin-left: 0;
      }
    }
  }
`;
