import {
  PortfolioRebalancingStatus,
  WrapperType,
  useCancelPortfolioRebalancingMutation,
  useCreatePortfolioRebalancingMutation,
  usePortfolioRebalancingsQuery,
  useUpdatePortfolioRebalancingMutation,
} from 'generated/graphql';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useHistory } from 'react-router-dom';
import { AnyRebalancing } from 'types/graphqlTypes';
import { useFundsBasket } from '../hooks/useFundsBasket';

/**
 * This is use for returning users to edit their order
 */
export function useEditWaitingOrder(
  selectedAccountId: string,
  selectedAccountType: WrapperType,
  portfolioRebalancing: AnyRebalancing
) {
  const history = useHistory();
  const { clear, syncFromServer } = useFundsBasket({
    selectedAccountId,
  });

  const {
    mutateAsync: cancelPortfolioRebalancingMutation,
    isLoading: cancelPortfolioRebalancingMutationIsLoading,
    isError: cancelPortfolioRebalancingMutationIsError,
  } = useCancelPortfolioRebalancingMutation();
  const portfolioRebalancingId = portfolioRebalancing.id;

  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery(
    {
      filter: { active: true, accountId: selectedAccountId },
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  );
  const draftPortfolioRebalancing = portfolioRebalancingsQuery?.data?.portfolioRebalancings?.find(
    ({ status }) => status === PortfolioRebalancingStatus.Draft
  );
  const hasDraftRebalancing = !!draftPortfolioRebalancing;

  const createPortfolioRebalancingMutationQuery = useCreatePortfolioRebalancingMutation();
  const {
    mutateAsync: createPortfolioRebalancingMutation,
    isLoading: createPortfolioRebalancingMutationIsLoading,
    isError: createPortfolioRebalancingMutationIsError,
  } = createPortfolioRebalancingMutationQuery;

  const updatePortfolioRebalancingMutationQuery = useUpdatePortfolioRebalancingMutation();
  const {
    mutateAsync: updatePortfolioRebalancingMutation,
    isLoading: updatePortfolioRebalancingMutationIsLoading,
    isError: updatePortfolioRebalancingMutationIsError,
  } = updatePortfolioRebalancingMutationQuery;

  if (!portfolioRebalancingId || !selectedAccountId) {
    return {
      mutateAsync: null,
      isLoading: false,
      isError: false,
    };
  }

  const mutateAsync = async () => {
    if (
      portfolioRebalancing &&
      portfolioRebalancing.status !== PortfolioRebalancingStatus.Waiting
    ) {
      // Only allow cancelling if the order is in the waiting state
      throw new Error();
    }

    try {
      if (hasDraftRebalancing) {
        await updatePortfolioRebalancingMutation({
          input: {
            portfolioRebalancingId: draftPortfolioRebalancing?.id,
            buyOrders: portfolioRebalancing?.buyOrders?.map((buyOrder) => ({
              isin: buyOrder.isin,
              amount: buyOrder.amount,
            })),
            sellOrders: [],
          },
        });
      } else {
        await createPortfolioRebalancingMutation({
          input: {
            accountId: selectedAccountId,
            buyOrders: portfolioRebalancing?.buyOrders?.map((buyOrder) => ({
              isin: buyOrder.isin,
              amount: buyOrder.amount,
            })),
            sellOrders: [],
          },
        });
      }

      clear();
      syncFromServer(portfolioRebalancingId);

      // cancel the portfolio rebalancing
      await cancelPortfolioRebalancingMutation({
        id: portfolioRebalancingId,
      });

      // redirect to the portfolio construction page
      history.push(
        generateDynamicPortfolioConstructionBasketPath({
          wrapperType: selectedAccountType.toLowerCase(),
        })
      );
    } catch (e) {
      // error caught by state
    }
  };

  return {
    mutateAsync,
    isLoading:
      cancelPortfolioRebalancingMutationIsLoading ||
      createPortfolioRebalancingMutationIsLoading ||
      updatePortfolioRebalancingMutationIsLoading,
    isError:
      cancelPortfolioRebalancingMutationIsError ||
      createPortfolioRebalancingMutationIsError ||
      updatePortfolioRebalancingMutationIsError,
  };
}
