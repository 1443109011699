import { currencyFull, date } from 'formatting';
import { TransferMethod, TransfersQuery } from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { getProviderLogo } from 'helpers/getProviderLogo';
import {
  AccountTypePill,
  CompletedTransferCardWrapper,
  CompleteOn,
  Details,
  Divider,
  Note,
  ProviderLogo,
  StatusPill,
  TransferFrom,
  Value,
  VerticalGroup,
} from './CompletedTransferCard.styles';

type TransferType = TransfersQuery['transfers'][number];

interface CompletedTransferCardProps {
  transfer: TransferType;
}

export default function CompletedTransferCard({
  transfer,
}: CompletedTransferCardProps) {
  const logo = getProviderLogo(transfer.cedingProviderId);

  // for in-specie try the transfer value, but fallback to summing holdings
  const getTransferValue = () => {
    if (transfer.method === TransferMethod.InSpecie) {
      let transferValue = transfer.value || 0;
      !transfer.value &&
        transfer.holdings.forEach((h) => {
          transferValue += h.value || 0;
        });
      return transferValue;
    } else {
      return transfer.value;
    }
  };

  const transferValue = getTransferValue();

  return (
    <CompletedTransferCardWrapper>
      <AccountTypePill>
        {getShortNameForWrapperType(transfer.wrapperType)}
      </AccountTypePill>
      <StatusPill status={transfer.status}>{transfer.status}</StatusPill>

      <Details transferHasValue={!!transferValue}>
        <ProviderLogo logoUrl={logo} />
        {!!transferValue && (
          <>
            <Divider />
            <div>
              <Value align="center">{currencyFull(transferValue)}</Value>
              <Note align="center">TRANSFERRED VALUE</Note>
            </div>
          </>
        )}
      </Details>
      <VerticalGroup>
        <TransferFrom variant="h3" align="center">
          {transfer.method === TransferMethod.Cash ? 'Cash' : 'In specie'}{' '}
          transfer from {transfer.cedingProviderName}
        </TransferFrom>
        <CompleteOn align="center">
          Completed on <strong>{date(transfer.lastUpdatedTimestampUtc)}</strong>
        </CompleteOn>
      </VerticalGroup>
    </CompletedTransferCardWrapper>
  );
}
