import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { colors } from 'constants/colors';
import styled from 'styled-components';

interface ContentContainerProp {
  vAlign: 'flex-start' | 'center';
  imgUrl?: string;
}

export const ContentContainer = styled.div<ContentContainerProp>`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.vAlign};
  align-items: center;
  background-image: url(${(p) => p.imgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 40px 0 20px 0;
  }

  h3,
  h4 {
    color: ${colors.richBlue};
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.25rem;

    ${(p) => p.theme.breakpoints.up('md')} {
      font-size: 1.5rem;
    }
  }

  p {
    color: ${colors.richBlack};
    margin-top: 0;
    font-size: 0.875rem;
    text-align: center;

    ${(p) => p.theme.breakpoints.up('md')} {
      font-size: 1rem;
    }
  }
`;

export const CardButton = styled(CustomButtonV2)`
  width: 100%;
  margin-top: auto;
`;
