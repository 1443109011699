import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Title = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-bottom: ${(p) => p.theme.spacing(5)}px;
  }
`;

export const SectionTitle = styled(Typography)`
  display: flex;
  gap: ${(p) => p.theme.spacing(0.5)}px;

  font-size: 1rem;
  margin-top: ${(p) => p.theme.spacing(2)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-top: ${(p) => p.theme.spacing(3)}px;
  }
`;

export const SetupMfaWrapper = styled.div`
  display: grid;
  margin-top: ${(p) => p.theme.spacing(1.5)}px;
`;

export const MfaExplanationWrapper = styled(Typography)`
  font-size: 0.875rem;
  color: white;
`;

export const HelpIconTooltip = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SingleAction = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;

  > * {
    flex-basis: 100%;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-top: ${(p) => p.theme.spacing(5)}px;
  }
`;

export const DoubleCol = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: calc(100%);
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    > * {
      flex-basis: calc(50% - 10px);
    }
  }
`;

export const ReferralLinkContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .MuiTextField-root {
    flex: 1 1 auto;

    .MuiInput-input {
      direction: rtl;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .MuiIconButton-root {
    color: ${colors.richBlack};
    margin-right: -12px;
  }
`;

export const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(0, auto);
  align-items: flex-start;
  gap: 0 1.25rem;
`;

export const Logo = styled.img`
  width: auto;
  height: 40px;
`;

export const EmployerNoteForwarding = styled.div`
  margin-top: 1.5rem;
`;
