import { datadogLogs } from '@datadog/browser-logs';
import { Alert } from '@material-ui/lab';
import { tillitFetch } from 'api/tillitFetch';
import { Form } from 'components/Form/Form';
import { TextField } from 'components/Form/TextField';
import { CooldownButton } from 'components/design-system/Button/CooldownButton/CooldownButton';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  EmailPasswordFormValues,
  LoginApiResBody,
  useLogin,
} from '../EmailPasswordStep/EmailPasswordStep';
import { useSignInTracking } from '../_shared/useSignInTracking';

const mfaFormSchema = Yup.object().shape({
  mfaCode: Yup.string()
    .label('Verification code')
    .required()
    .min(6)
    .max(6)
    .matches(/[\d]{6}/, 'Must contain 6 numbers.'),
});

interface MfaFormValues extends Yup.Asserts<typeof mfaFormSchema> {}

interface ConfirmLoginApiBody {
  success: boolean;
  userId: string;
  details: string | null;
  message: string;
}

interface ConfirmLoginValues {
  userId: string;
  userIdIntegrity: string;
  session: string;
  code: string;
  userContext: string | null;
  mfaMethod?: string;
}

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

const useConfirmLogin = () => {
  return useMutation<any, Error, ConfirmLoginValues>(
    '/identity/confirm-login',
    (data) => {
      return tillitFetch('/identity/confirm-login', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
    }
  );
};

export interface MfaStepProps {
  onProceed: (success: boolean) => void;
  defaultLoginResult: LoginApiResBody;
  emailPasswordFormValues?: EmailPasswordFormValues;
}

export function MfaStep({
  onProceed,
  defaultLoginResult,
  emailPasswordFormValues,
}: MfaStepProps) {
  const validationSchema = mfaFormSchema;
  const [errorMessage, setErrorMessage] = useState<string | boolean>();
  const [loginResult, setLoginResult] = useState<LoginApiResBody>(
    defaultLoginResult
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const {
    mutateAsync: loginMutateAsync,
    isLoading: loginIsLoading,
  } = useLogin();

  const { mutateAsync, isLoading } = useConfirmLogin();

  const trackSignIn = useSignInTracking();

  const userContext = getCognitoUserContextData(loginResult.userId);

  const onSubmit = async (data: MfaFormValues, isDirty: boolean) => {
    setIsLoggingIn(true);
    datadogLogs.logger.debug('/api/account/mfacode - Request');

    try {
      const confirmLoginResult = await mutateAsync({
        userId: loginResult.userId,
        userIdIntegrity: loginResult.userIdIntegrity,
        session: loginResult.session,
        code: data.mfaCode,
        userContext,
        mfaMethod: loginResult.mfaMethod,
      });
      datadogLogs.logger.debug('/api/account/mfacode - Response', {
        responseStatus: confirmLoginResult.status,
      });

      if (confirmLoginResult.status === 200) {
        await trackSignIn(loginResult.userId);

        const confirmLoginResultBody = (await confirmLoginResult.json()) as ConfirmLoginApiBody;
        onProceed(confirmLoginResultBody.success);
      } else if (confirmLoginResult.status === 400) {
        const confirmLoginResultBody = (await confirmLoginResult.json()) as ConfirmLoginApiBody;
        setErrorMessage(confirmLoginResultBody.message);
        setIsLoggingIn(false);
      }
    } catch (error) {
      datadogLogs.logger.error('/api/account/mfacode - Failed');
      setIsLoggingIn(false);
    }
  };

  const instructionText =
    loginResult.mfaMethod?.toLowerCase() === 'totp'
      ? 'Please enter the code from your authenticator app:'
      : 'Please enter the code sent to your mobile phone:';

  return (
    <Form<MfaFormValues> onSubmit={onSubmit} schema={validationSchema}>
      <StepContainer>
        <StepContent>
          <StepTitle>Multi-factor authentication</StepTitle>

          <StepIntroduction mb={2}>
            <StepIntroductionTypography>
              {instructionText}
            </StepIntroductionTypography>
            <StepIntroductionTypography></StepIntroductionTypography>
          </StepIntroduction>

          {errorMessage && (
            <ErrorAlert variant="outlined" severity="error">
              {errorMessage}
            </ErrorAlert>
          )}

          <StepFormContainer>
            <TextField
              name="mfaCode"
              label="Verification code"
              fullWidth
              autoFocus
            />
          </StepFormContainer>
        </StepContent>

        <StepActions>
          <>
            <StepButton
              disabled={loginIsLoading || isLoading || isLoggingIn}
              type="submit"
              className="magenta"
            >
              Continue
            </StepButton>
          </>
          {emailPasswordFormValues &&
            loginResult.mfaMethod?.toLowerCase() === 'sms' && (
              <CooldownButton
                disabled={loginIsLoading || isLoading}
                action={async () => {
                  const loginResult = await loginMutateAsync({
                    ...emailPasswordFormValues,
                    userContext: userContext,
                  });

                  if (loginResult.status === 200) {
                    const loginResultBody = (await loginResult.json()) as LoginApiResBody;
                    setLoginResult(loginResultBody);
                  }
                }}
              />
            )}
        </StepActions>
      </StepContainer>
    </Form>
  );
}
