import { colors } from 'constants/colors';
import Select, {
  OptionProps,
  SingleValueProps,
  components,
} from 'react-select';
import styled from 'styled-components';
import { TextNormal, TextSmall } from '../Text/Text';
import { BorderStyle, Pill } from './Pill';

interface OptionType<OptionValueType = string> {
  value: OptionValueType;
  label: string;
  labelDescription?: string;
}

interface OptionTextProps {
  $isSelected: boolean;
}

const OptionTitle = styled(TextNormal)<OptionTextProps>`
  margin: 0;
  font-weight: 400;
  text-wrap: nowrap;
  color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.black)};
`;

const OptionDescription = styled(TextSmall)<OptionTextProps>`
  margin: 0;
  text-wrap: nowrap;
  color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.black)};
`;

function CustomSingleValue<OptionValueType>({
  children,
  ...props
}: SingleValueProps<OptionType<OptionValueType>, false>) {
  return <components.SingleValue {...props}>{children}</components.SingleValue>;
}

function CustomOption<OptionValueType>({
  children,
  ...props
}: OptionProps<OptionType<OptionValueType>, false>) {
  const isSelected = props.isSelected;
  return (
    <components.Option {...props}>
      <OptionTitle $isSelected={isSelected}>{children}</OptionTitle>
      {props.data.labelDescription && (
        <OptionDescription $isSelected={isSelected}>
          {props.data.labelDescription}
        </OptionDescription>
      )}
    </components.Option>
  );
}

interface PillSelectProps<OptionValueType> {
  options: OptionType<OptionValueType>[];
  defaultValue?: OptionValueType;
  value?: OptionValueType;
  $border?: BorderStyle;
  onChange: (value: OptionType<OptionValueType>) => void;
  placeholder?: string;
  menuPlacement?: 'bottom' | 'auto' | 'top';
}

export function PillSelect<OptionValueType>({
  options,
  defaultValue,
  value,
  $border = BorderStyle.Minimal,
  onChange,
  placeholder,
  menuPlacement = 'auto',
}: PillSelectProps<OptionValueType>) {
  return (
    <Select<OptionType<OptionValueType>>
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={options}
      onChange={(option: OptionType<OptionValueType>) => {
        onChange(option);
      }}
      defaultValue={
        defaultValue
          ? options.find(({ value }) => value === defaultValue)
          : undefined
      }
      value={
        value ? options.find((option) => option.value === value) : undefined
      }
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '0 0.25rem',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          borderRadius: '0.5rem',
          borderColor: colors['purple-100'],
          overflow: 'hidden',
          minWidth: '100%',
          width: 'auto',
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? colors['purple-500'] : colors.white,
          cursor: 'pointer',
        }),
      }}
      components={{
        Control: ({ children, innerProps, innerRef, menuIsOpen }) => {
          return (
            <Pill
              {...innerProps}
              ref={innerRef}
              $noPadding
              $color={menuIsOpen ? 'vapor' : 'white'}
              $border={$border}
            >
              {children}
            </Pill>
          );
        },
        SingleValue: CustomSingleValue,
        Option: CustomOption,
      }}
    />
  );
}
