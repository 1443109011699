import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontSize,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { AddBuyOrderToBasketFullFlowDialog } from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketFullFlowDialog';
import { AddFundToRecurringOrderDialog } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddToFundToRecurringOrder';
import { useMode } from 'components/feature/mode/useMode';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { Asset, WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { Source } from 'pages/FundDetails/fundDetailsTypes';

import {
  generateFundDetailsMeetManagersPath,
  generateFundDetailsWithReturnPath,
} from 'paths';
import { useRef, useState } from 'react';
import { FaVideo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import { SearchAssetsQueryAsset } from 'types/graphqlTypes';
import { useIntersectionObserver } from 'usehooks-ts';
import {
  AddBuyOrderToBasketDialog,
  useDialog,
} from '../../PortfolioBuilder/AddToBasket/AddBuyOrderToBasketDialog';
import { FundsGridCardOptions } from '../hooks/useFundListOptions';
import {
  AccordionChevron,
  CartButton,
  CartIcon,
  FundCard,
  NewTag,
  NewTagWrapper,
  VideoIconLink,
} from './FundGridCard.style';
import {
  CardFundTagsContainer,
  CardFundTagsInnerContainer,
  Details,
  FundCardHeader,
  FundCardHorizontalContainerInner,
  OneLiner,
  VerticalDivider,
} from './FundGridCardHorizontal.style';
import { FundGridCardTags } from './FundGridCardTags';
import { FundGridCardVideo } from './FundGridCardVideo';

/**
 * We place a play area slither in the center of the page.
 *
 * On mobile, if *any* of the video is within this area the video plays.
 *
 * Because the slither height is less than the gap between the videos - so no two will be in the
 * play area at the same time.
 */
const playAreaSlitherHeight = 300; // The height of the play area.
const playAreaSlitherOffset = 0; // The amount we offset the play area from the center of the screen.
const halfWindowHeight = window.innerHeight / 2;
const topMargin =
  halfWindowHeight + playAreaSlitherOffset - playAreaSlitherHeight / 2; // The amount of space between the top of the screen and the play area
const bottomMargin =
  halfWindowHeight - playAreaSlitherOffset - playAreaSlitherHeight / 2; // The amount of space between the bottom of the screen and the play area

const trackFundOpen = (asset: PartialDeep<Asset>, index: number) => {
  const tags = asset.tags?.nodes
    ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
    : [];

  trackGa({
    event: GaEventNames.selectItem,
    ecommerce: {
      items: [
        {
          item_id: asset.id,
          item_name: asset.name,
          item_type: asset.assetClass?.name,
          affiliation: 'Tillit',
          item_brand: asset.assetManager?.name,
          item_category: tags[0],
          item_category2: tags[1],
          item_category3: tags[2],
          item_category4: tags[3],
          item_category5: tags[4],
          index: index,
          item_list_name: 'DPC Funds list',
        },
      ],
    },
  });
};

export type ActionOptions =
  | 'add_to_basket_only'
  | 'add_to_regular_order_only'
  | 'any';

interface TagProps {
  title: string;
  code: string;
  content: JSX.Element | string | null;
  categoryCode?: string;
  isImportant: boolean;
}

export interface FundCardProps {
  asset: SearchAssetsQueryAsset;
  forceExpanded?: boolean;
  index: number; // position in the list
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  searchTags: TagProps[];
  searchTerm?: string;
  actionsOptions: ActionOptions; // add to basket only | any - add to basket OR quick flow
  missingTags: TagProps[];
  removeTag: (tag: { code: string }) => void;
  addTag: (tag: { code: string }, important?: boolean) => void;
  options: FundsGridCardOptions;
  setOptions: (options: FundsGridCardOptions) => void;
  source?: Source;
}

export function FundGridCardHorizontal({
  asset,
  index,
  selectedAccountType,
  selectedAccountId,
  searchTags,
  searchTerm,
  actionsOptions,
  missingTags,
  removeTag,
  addTag,
  options,
  setOptions,
  source = 'funds',
}: FundCardProps) {
  const [mode] = useMode();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [isHover, setIsHover] = useState(false);
  const videoRef = useRef<HTMLDivElement>(null);
  const fundCardRef = useRef<HTMLDivElement>(null);
  const dialogProps = useDialog();

  const [isTagsActive, setTagsActive] = useState<boolean>(false);
  const tagsContainerHeight = isTagsActive ? '400px' : '28px';

  const canHover = useMediaQuery('(hover: hover)');

  const handleVideoPlay = () => {
    setIsHover(true);
  };

  const handleVideoPause = () => {
    setIsHover(false);
  };

  const handleAddToBasket = () => {
    handleVideoPause();
    dialogProps.openDialog();
  };

  const videoEntry = useIntersectionObserver(fundCardRef, {
    freezeOnceVisible: true,
    rootMargin: '0px 0px 100px 0px',
  });

  const videoInView = useIntersectionObserver(videoRef, {
    rootMargin: `-${topMargin}px 0px -${bottomMargin}px 0px`,
    threshold: 0.51,
  });

  const fundDetailsPath = generateFundDetailsWithReturnPath(
    {
      id: asset?.id!,
      slug: asset?.slug!,
    },
    {
      searchTags: searchTags.map((tag) => tag.code).join(','),
      searchTerm: searchTerm,
      source: source,
    }
  );
  const totalReturn = {
    period: '5Yr',
    value:
      asset.totalReturnOver5Years !== null &&
      asset.totalReturnOver5Years !== undefined
        ? format.percent(asset.totalReturnOver5Years / 100)
        : 'N/A',
  };

  const fundDetailsMeetManagersPath =
    generateFundDetailsMeetManagersPath({
      id: asset?.id!,
      slug: asset?.slug!,
    }) + '#meet-the-manager';

  return (
    <>
      <FundCard
        onMouseEnter={canHover ? handleVideoPlay : undefined}
        onMouseLeave={canHover ? handleVideoPause : undefined}
        ref={fundCardRef}
      >
        {mode?.mode !== 'autoSaveInvest' &&
          actionsOptions === 'add_to_basket_only' && (
            <AddBuyOrderToBasketDialog
              {...dialogProps}
              asset={asset}
              selectedAccountType={selectedAccountType}
              selectedAccountId={selectedAccountId}
            />
          )}

        {mode?.mode !== 'autoSaveInvest' && actionsOptions === 'any' && (
          <AddBuyOrderToBasketFullFlowDialog
            {...dialogProps}
            assetId={`${asset.id}`}
            selectedAccountId={selectedAccountId}
            selectedAccountType={selectedAccountType}
          />
        )}
        {mode?.mode === 'autoSaveInvest' && dialogProps.open && (
          <AddFundToRecurringOrderDialog
            {...dialogProps}
            selectedAssetId={asset.id}
          />
        )}

        <FundCardHorizontalContainerInner>
          {isSmUp && (
            <FundCardHeader>
              <FundGridCardVideo
                asset={asset}
                options={options}
                setOptions={setOptions}
                isExpanded={false}
                videoEntryIsIntersecting={videoEntry?.isIntersecting}
                canHover={canHover}
                isSmUp={isSmUp}
                videoInViewIsIntersecting={videoInView?.isIntersecting}
                isHover={isHover}
              />
            </FundCardHeader>
          )}

          <VerticalDivider />

          <Details>
            <OneLiner $noMargin>
              <Link
                to={{
                  pathname: fundDetailsPath,
                }}
                onClick={() => trackFundOpen(asset, index)}
              >
                {asset.description}
              </Link>
            </OneLiner>

            {asset.isNew && (
              <NewTagWrapper>
                <NewTag>New!</NewTag>{' '}
              </NewTagWrapper>
            )}
            <CardFundTagsContainer>
              <CardFundTagsInnerContainer
                style={{ maxHeight: isSmUp ? 'none' : tagsContainerHeight }}
              >
                {!isSmUp && asset.introVideo && (
                  <VideoIconLink
                    to={fundDetailsMeetManagersPath}
                    title="Meet the Manager video interview available."
                  >
                    <FaVideo color={colors.magenta} size={'1rem'} />
                  </VideoIconLink>
                )}
                <FundGridCardTags
                  asset={asset}
                  searchTags={searchTags}
                  missingTags={missingTags}
                  removeTag={removeTag}
                  addTag={addTag}
                  fontSize={FontSize.xs}
                  canHoverTags={true}
                />
              </CardFundTagsInnerContainer>
              {!isSmUp && (
                <AccordionChevron
                  $isActive={isTagsActive}
                  onClick={() => {
                    setTagsActive(!isTagsActive);
                  }}
                />
              )}
            </CardFundTagsContainer>
            <TextXS $noMargin>
              <strong>5Yr return:</strong> {totalReturn.value}
              {' | '}
              <strong>Fund fee:</strong>{' '}
              {asset.ongoingChargePercent !== undefined &&
              asset.ongoingChargePercent !== null
                ? format.percent(asset.ongoingChargePercent / 100)
                : 'N/A'}{' '}
            </TextXS>
            {canHover ? (
              <Popover
                popupId="add-to-basket"
                openOn="hover"
                placement="bottom"
                $padding="narrow"
                content={
                  <TextSmall $textAlign={TextAlign.center} $noMargin>
                    Add to basket
                  </TextSmall>
                }
              >
                {(popoverProps) => (
                  <CartButton
                    onClick={() => {
                      handleAddToBasket();
                    }}
                    {...popoverProps}
                  >
                    <CartIcon />
                  </CartButton>
                )}
              </Popover>
            ) : (
              <CartButton
                onClick={() => {
                  handleAddToBasket();
                }}
              >
                <CartIcon />
              </CartButton>
            )}
          </Details>
        </FundCardHorizontalContainerInner>
      </FundCard>
    </>
  );
}
