import { useMediaQuery, useTheme } from '@material-ui/core';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import {
  StepActions,
  StepFormContainer,
  StepFormWrapper,
  StepTitle,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { appSignInPath, openAccountPath } from 'paths';

export function SignInSignUp() {
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <StepFormWrapper>
      <StepWrapper>
        <StepFormContainer>
          <StepTitle>Welcome to TILLIT</StepTitle>
          <StepActions>
            <LinkCustomButton $isWide to={openAccountPath}>
              Start investing
            </LinkCustomButton>
            <LinkCustomButton $isWide $color="light" to={appSignInPath}>
              Sign in
            </LinkCustomButton>
          </StepActions>
          <Text $textAlign={TextAlign.center}>Capital at Risk</Text>
        </StepFormContainer>
      </StepWrapper>
    </StepFormWrapper>
  );
}
