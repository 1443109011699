import { AccountsQueryAccountPosition } from 'types/graphqlTypes';

export function filterPositions(
  positions: Array<AccountsQueryAccountPosition>
) {
  const inUniversePositions: Array<AccountsQueryAccountPosition> = [];
  const residualAssets: Array<AccountsQueryAccountPosition> = [];

  // separate positions of the instruments in our universe
  // and anything else which might have come from the transfer
  positions.forEach((p) => {
    if (p.isin) {
      if (p.instrument) {
        inUniversePositions.push(p);
      } else {
        residualAssets.push(p);
      }
    }
  });

  return [inUniversePositions, residualAssets] as const;
}
