import { H1 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BreakdownWrapper = styled.div`
  padding: 2rem 0;
  width: 100%;
  max-width: 1280px;
`;

export const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${transparentize(0.5, colors.magenta)};
  border-radius: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  svg {
    color: ${transparentize(0.5, colors.magenta)};
  }
`;

export const CardStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const CardStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 1rem;
  gap: 3rem;
`;

export const CardStepTitle = styled(H1)``;
export const CardStepDescription = styled.div`
  > {
    font-weight: 400;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
