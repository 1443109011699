import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import type { GetAssetGroupString } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/breakdownGroupingHelpers';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { useGetCurrentBreakdown } from '../../hooks/useGetBreakdowns';
import { GroupingOption } from '../BasketAllocation/BreakdownTab';
import {
  DrillDownContainer,
  DrillDownHeader,
  StackedBarComparisonContainer,
} from '../InteractiveBreakdown.styles';
import { Breakdown, StackedBarChart } from '../_shared/StackedBarChart';
import { CurrentBreakdown } from './CurrentBreakdown';
import { CurrentHoldingsTab } from './CurrentHoldingsTab';

interface BreakdownStackedBarComparisonProps {
  selectedAccountType: WrapperType;
  getAssetGroupString: GetAssetGroupString;
  groupings: (displayMoreInfo: string) => GroupingOption[];
  isInteractive: boolean;
  onMouseEnterBarChart?: () => void;
  onMouseLeaveBarChart?: () => void;
  isBarChartHovered?: boolean;
  onChange?: (open: boolean) => void;
  onGetColor: (name: string, key: number) => { name: string; color: string };
}

export const CurrentAllocation = ({
  selectedAccountType,
  getAssetGroupString,
  groupings,
  isInteractive,
  onMouseEnterBarChart,
  onMouseLeaveBarChart,
  isBarChartHovered,
  onChange,
  onGetColor,
}: BreakdownStackedBarComparisonProps) => {
  const [displayCurrentBreakdown, setDisplayCurrentBreakdown] = useState<
    string | null
  >(null);

  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    getAssetGroupString
  );

  const breakdownItemColors = breakdowns.map((item, key) =>
    onGetColor(item.name, key)
  );

  const activeCurrentBreakdown = breakdowns.find(
    ({ name }) => name === displayCurrentBreakdown
  );

  const handleActiveBreakDownChange = ({ name }: Breakdown) => {
    if (displayCurrentBreakdown === name) {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'interactive breakdown - current allocation - drill down',
        item_id: 'unselect',
      });
      setDisplayCurrentBreakdown(null);
      onChange?.(false);
    } else {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'interactive breakdown - current allocation - drill down',
        item_id: name,
      });
      setDisplayCurrentBreakdown(name);
      onChange?.(true);
    }
  };

  return (
    <>
      <StackedBarComparisonContainer>
        {breakdowns.length > 0 && (
          <div>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Current allocation
            </TextSmall>
            <StackedBarChart
              breakdowns={breakdowns}
              breakdownItemColors={breakdownItemColors}
              onClick={isInteractive ? handleActiveBreakDownChange : undefined}
              onMouseEnter={onMouseEnterBarChart}
              onMouseLeave={onMouseLeaveBarChart}
              isHovered={isBarChartHovered}
              activeBreakdown={activeCurrentBreakdown?.name}
            />
          </div>
        )}
      </StackedBarComparisonContainer>
      {displayCurrentBreakdown &&
        displayCurrentBreakdown === 'Cash' &&
        activeCurrentBreakdown && (
          <DrillDownContainer>
            <DrillDownHeader>
              <TextNormal $noMargin $fontWeight={FontWeight.normal}>
                {activeCurrentBreakdown.name}
              </TextNormal>
              <TextNormal $noMargin>
                {format.percent(activeCurrentBreakdown.percentage / 100)} |{' '}
                {format.currencyFull(activeCurrentBreakdown.amount)}
              </TextNormal>
            </DrillDownHeader>
          </DrillDownContainer>
        )}
      {displayCurrentBreakdown &&
        displayCurrentBreakdown !== 'Cash' &&
        activeCurrentBreakdown && (
          <DrillDownContainer>
            <Tabs
              title={
                <DrillDownHeader>
                  <TextNormal $noMargin>
                    {activeCurrentBreakdown.name}
                  </TextNormal>
                </DrillDownHeader>
              }
              onClick={(tabTitle) => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type:
                    'interactive breakdown - current allocation - drill down tab',
                  item_id: tabTitle,
                });
              }}
              tabs={[
                {
                  title: 'Holdings',
                  content: (
                    <CurrentHoldingsTab
                      activeBreakdown={activeCurrentBreakdown}
                      selectedAccountType={selectedAccountType}
                      includeAsset={(asset) =>
                        getAssetGroupString(asset) ===
                        activeCurrentBreakdown.name
                      }
                    />
                  ),
                },
                {
                  title: 'Breakdown',
                  content: (
                    <CurrentBreakdown
                      groupings={groupings(displayCurrentBreakdown)}
                      activeBreakdown={activeCurrentBreakdown}
                      selectedAccountType={selectedAccountType}
                      includeAsset={(asset) =>
                        getAssetGroupString(asset) ===
                        activeCurrentBreakdown.name
                      }
                    />
                  ),
                },
              ]}
            />
          </DrillDownContainer>
        )}
    </>
  );
};
