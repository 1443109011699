import { QueryState } from 'components/QueryState';
import { useIcReportsQuery } from 'generated/graphql';
import React from 'react';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper.min.css';
import { ItemContainer } from './InvestmentCommittee.styles';
import { InvestmentCommitteeItem } from './InvestmentCommitteeItem';

SwiperCore.use([Pagination, Keyboard, Navigation]);

export const InvestmentCommittee = () => {
  const icReportsQuery = useIcReportsQuery();

  return (
    <QueryState {...icReportsQuery}>
      {({ data }) =>
        data?.icReports && (
          <ItemContainer>
            {data?.icReports.map((report) => (
              <InvestmentCommitteeItem report={report} key={report.reportUrl} />
            ))}
          </ItemContainer>
        )
      }
    </QueryState>
  );
};
