import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';
import { shadow01, shadow02, shadow03 } from 'theme/shared/base.styles';
import {
  largePadding,
  mediumPadding,
  noPadding,
  smallPadding,
} from 'theme/shared/spacing.styles';

const getPadding = (size: ContainerProps['padding']) => {
  switch (size) {
    case 'none':
      return noPadding;
    case 'small':
      return smallPadding;
    case 'medium':
      return mediumPadding;
    case 'large':
      return largePadding;
    default:
      return '';
  }
};

const getShadow = (size: ContainerProps['$elevation']) => {
  switch (size) {
    case 1:
      return shadow01;
    case 2:
      return shadow02;
    case 3:
      return shadow03;
    default:
      return '';
  }
};

export interface ContainerProps {
  $elevation?: 0 | 1 | 2 | 3;
  square?: boolean;
  padding?: 'none' | 'small' | 'medium' | 'large';
  removeHorizontalPadding?: boolean;
  $elevationBreakpoint?: Breakpoint;
  background?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: ${colors.white};
  ${(p) =>
    p.background &&
    css`
      background-image: url(${p.background});
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
    `};
  ${(p) => getPadding(p.padding)};
  ${(p) =>
    !p.square &&
    css`
      border-radius: 10px;
    `};
  ${(p) =>
    p.removeHorizontalPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${(p) => p.theme.breakpoints.up(p.$elevationBreakpoint!)} {
    ${(p) => getShadow(p.$elevation)};
    ${(p) => getPadding(p.padding)};

    ${(p) =>
      p.removeHorizontalPadding &&
      css`
        padding-left: 0;
        padding-right: 0;
      `};
  }
`;

Container.defaultProps = {
  $elevation: 0,
  square: true,
  padding: 'none',
  removeHorizontalPadding: false,
  $elevationBreakpoint: 'xs',
  background: '',
};
