import { DialogActions, DialogContent } from '@material-ui/core';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { InfoPopover } from 'components/Popover/InfoPopover/InfoPopover';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import { MonoDd, TextNormal } from 'components/design-system/Text/Text';
import { currencyFull } from 'formatting';
import { CashDepositTransferDetails, WrapperType } from 'generated/graphql';
import { pensionTaxReliefHelpers } from 'helpers/pensionTaxReliefHelpers';
import { useContext } from 'react';
import * as tooltips from 'strings/tooltips';
import styled from 'styled-components';
import { DepositVariantContext } from '../_shared/DepositVariantContext';
import { DetailsList as DetailsListRaw } from '../styles/Deposit.styles';

const DetailsList = styled(DetailsListRaw)`
  margin: 0 auto 2rem;
  max-width: 350px;
`;

export interface CompletedStepProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  canProceed?: boolean;
  autoInvested?: boolean;
  onDashboard: () => void;
  onBrowseFunds: () => void;
  onProceed: () => void;
}

export function CompletedStep({
  amount,
  transferDetails,
  canProceed = false,
  autoInvested = false,
  onDashboard,
  onBrowseFunds,
  onProceed,
}: CompletedStepProps) {
  const depositVariant = useContext(DepositVariantContext);

  const {
    payeeName,
    payeeBankName,
    payeeAccountNumber,
    payeeSortCode,
    paymentReference,
  } = transferDetails;
  return (
    <>
      <StepTitle>
        {autoInvested
          ? 'Your cash has arrived and is now being invested according to your instructions.'
          : depositVariant?.variant === DepositFormVariant.NewAccount &&
            depositVariant?.wrapperType
          ? `Your cash is now available to invest in your new ${depositVariant?.wrapperType}!`
          : 'Your cash is now available to invest!'}
      </StepTitle>
      <DialogContent>
        <DetailsList>
          <dt>Amount</dt>
          <dd>{currencyFull(amount)}</dd>

          <dt>
            Payee name <InfoPopover content={tooltips.PayeeName} />
          </dt>
          <dd>{payeeName}</dd>

          <dt>Bank</dt>
          <dd>{payeeBankName}</dd>

          <dt>Account number</dt>
          <MonoDd>{payeeAccountNumber}</MonoDd>

          <dt>Sort code</dt>
          <MonoDd>{payeeSortCode}</MonoDd>

          <dt>Payment reference</dt>
          <MonoDd>{paymentReference}</MonoDd>

          {autoInvested && (
            <>
              <dt>Automatic investment</dt>
              <dd>Yes</dd>
            </>
          )}
        </DetailsList>
        {depositVariant?.wrapperType === WrapperType.Sipp &&
          depositVariant?.variant === DepositFormVariant.Checkout && (
            <TextNormal>
              We'll automatically claim basic rate tax relief of{' '}
              {currencyFull(pensionTaxReliefHelpers(amount))} for this cash
              deposit. This will be held as cash when it arrives.
            </TextNormal>
          )}
      </DialogContent>
      <DialogActions>
        {canProceed && (
          <CustomButtonV2
            onClick={() => onProceed()}
            $color="primary"
            $isWide={false}
            fullWidth
          >
            Continue
          </CustomButtonV2>
        )}
        {!canProceed && (
          <ButtonWrapper $align="center">
            <CustomButtonV2
              onClick={() => onDashboard()}
              $color="secondary"
              fullWidth
            >
              Dashboard
            </CustomButtonV2>
            <CustomButtonV2
              onClick={() => onBrowseFunds()}
              $color="primary"
              fullWidth
            >
              Browse funds
            </CustomButtonV2>
          </ButtonWrapper>
        )}
      </DialogActions>
    </>
  );
}
