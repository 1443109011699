import { PageContainer } from 'components/PageContainer';
import { QueryState } from 'components/QueryState';
import { AnimatePresence } from 'framer-motion';
import {
  AffirmationKind,
  useUserProfileQuery,
  WrapperType,
} from 'generated/graphql';
import { useState } from 'react';
import type { UserProfileQueryUserProfile } from 'types/graphqlTypes';
import { ConfirmationStep } from './ConfirmationStep';
import { ErrorStep } from './ErrorStep';
import { ISADeclarationStep } from './ISADeclarationStep';
import { NationalInsuranceNumberStep } from './NationalInsuranceNumberStep';
import { SuccessStep } from './SuccessStep';

interface SecondAccountProps {
  accountType: WrapperType;
  onClose: () => void;
  onCancel: () => void;
}

interface SecondAccountInnerProps extends SecondAccountProps {
  userProfile: UserProfileQueryUserProfile;
}

enum Steps {
  niNumber = 'niNumber',
  isaDeclaration = 'isaDeclaration',
  confirmation = 'confirmation',
  error = 'error',
  success = 'success',
}

export function SecondAccountInner({
  accountType,
  onClose,
  onCancel,
  userProfile,
}: SecondAccountInnerProps) {
  const hasSubmittedNiNumber = userProfile?.nationalInsuranceNumberTokenised;

  const [madeAffirmations, setMadeAffirmations] = useState<AffirmationKind[]>(
    []
  );

  const [activeStep, setActiveStep] = useState<Steps>(
    accountType === WrapperType.Gia
      ? Steps.confirmation
      : hasSubmittedNiNumber
      ? Steps.isaDeclaration
      : Steps.niNumber
  );

  return (
    <PageContainer>
      <AnimatePresence>
        {activeStep === Steps.niNumber && (
          <NationalInsuranceNumberStep
            onGoBack={() => {
              onClose();
            }}
            onProceed={() => {
              setActiveStep(Steps.isaDeclaration);
            }}
            userProfile={userProfile}
          />
        )}
        {activeStep === Steps.isaDeclaration && (
          <ISADeclarationStep
            onBack={onClose}
            onProceed={(madeAffirmations) => {
              setMadeAffirmations(madeAffirmations);
              setActiveStep(Steps.confirmation);
            }}
          />
        )}
        {activeStep === Steps.confirmation && (
          <ConfirmationStep
            accountType={accountType}
            madeAffirmations={madeAffirmations}
            onProceed={() => {
              setActiveStep(Steps.success);
            }}
            onError={() => {
              setActiveStep(Steps.error);
            }}
            onClose={onClose}
            onCancel={onCancel}
          />
        )}
        {activeStep === Steps.error && (
          <ErrorStep accountType={accountType} onClose={onClose} />
        )}
        {activeStep === Steps.success && (
          <SuccessStep accountType={accountType} onClose={onClose} />
        )}
      </AnimatePresence>
    </PageContainer>
  );
}

export function SecondAccount({ ...props }: SecondAccountProps) {
  const userProfileQuery = useUserProfileQuery({});

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <SecondAccountInner
          {...props}
          userProfile={userProfileQuery.data!.userProfile!}
        />
      )}
    </QueryState>
  );
}
