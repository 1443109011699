import { AuthRoute } from 'components/AuthRoute/AuthRoute';
import { HeaderShim } from 'components/design-system/Header';
import { DepositDialog } from 'components/Dialogs/Deposit/DepositDialog';
import { DynamicPortfolioConstructionCheckout } from 'components/feature/Checkout/Checkout';
import { useMode } from 'components/feature/mode/useMode';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { DynamicPortfolioConstructionDecisionMaking } from 'components/feature/PortfolioBuilder/PortfolioBuilder';
import { QueryState } from 'components/QueryState';
import { useAuth } from 'context/AuthContext';
import {
  Account,
  AccountStatus,
  useAccountsQuery,
  WrapperType,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { NotFound } from 'pages/NotFound/NotFound';
import {
  checkoutPath,
  dynamicPortfolioConstructionAddCashPath,
  dynamicPortfolioConstructionBasketAddCashPath,
  dynamicPortfolioConstructionBasketPath,
  dynamicPortfolioConstructionFundsAddCashPath,
  dynamicPortfolioConstructionFundsPath,
  generateDynamicPortfolioConstructionFundsPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { useMemo } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

interface WrapperTypeLookup {
  [key: string]: WrapperType;
}
const lookup: WrapperTypeLookup = {
  isa: WrapperType.Isa,
  gia: WrapperType.Gia,
  pension: WrapperType.Sipp,
  'new-isa': WrapperType.Isa,
  'new-gia': WrapperType.Gia,
  unknown: WrapperType.Unknown,
};

export function DynamicPortfolioConstructionPage() {
  const [mode] = useMode();
  const history = useHistory();
  const { signedIn } = useAuth();
  const { wrapperType } = useParams<{
    wrapperType: string;
  }>();

  const fundsOrBasketPathMatch = useRouteMatch([
    dynamicPortfolioConstructionFundsPath,
    dynamicPortfolioConstructionBasketPath,
  ]);

  const addPathPathMatch = useRouteMatch([
    dynamicPortfolioConstructionAddCashPath,
    dynamicPortfolioConstructionFundsAddCashPath,
    dynamicPortfolioConstructionBasketAddCashPath,
  ]);

  const basketType = useWhichBasketType();

  const accountsQuery = useAccountsQuery(
    {},
    {
      enabled: signedIn,
      onSuccess: (data) => {
        // If wrapper type is not defined in the url, try to redirect user to the correct place
        if (
          !wrapperType ||
          (basketType === 'AccountBasket' && wrapperType === 'unknown')
        ) {
          const activeAccounts = signedIn
            ? data?.accounts?.filter((acc) => {
                return acc.status === AccountStatus.Active;
              })
            : [];

          const path = () => {
            if (mode?.mode !== 'resume' && mode?.wrapperType) {
              return generateDynamicPortfolioConstructionPath({
                wrapperType: getPathSegmentForWrapperType(mode.wrapperType),
              });
            }

            if (activeAccounts && activeAccounts.length === 1) {
              return generateDynamicPortfolioConstructionPath({
                wrapperType: getPathSegmentForWrapperType(
                  activeAccounts[0].wrapperType
                ),
              });
            }
            if (activeAccounts && activeAccounts.length > 1) {
              const hasActiveIsa = activeAccounts.some(
                (acc) =>
                  acc.wrapperType === WrapperType.Isa &&
                  acc.status === AccountStatus.Active
              );

              return generateDynamicPortfolioConstructionPath({
                wrapperType: hasActiveIsa
                  ? getPathSegmentForWrapperType(WrapperType.Isa)
                  : getPathSegmentForWrapperType(activeAccounts[0].wrapperType),
              });
            }

            // no active accounts or not loggedIn
            return dpcUnauthFundsPath;
          };

          history.push(path());
        }
      },
    }
  );
  const selectedAccountType = lookup[wrapperType];

  const dpcUnauthPath = generateDynamicPortfolioConstructionPath({
    wrapperType: 'unknown',
  });
  const dpcUnauthFundsPath = generateDynamicPortfolioConstructionFundsPath({
    wrapperType: 'unknown',
  });

  const selectedAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === selectedAccountType
  ) as Account;
  const selectedAccountId = selectedAccount?.id;
  const selectedAccountStatus = selectedAccount?.status;

  useEffectOnce(() => {
    if (!signedIn && !fundsOrBasketPathMatch) {
      history.replace(dpcUnauthFundsPath);
    }
  });

  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];
  useUpdateRootCSSVars(rootStyling);

  const location = useLocation();
  const { search } = location;

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const returnPath = location.pathname + location.search;

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <HeaderShim />
      {addPathPathMatch && selectedAccountId && (
        <DepositDialog
          accountId={selectedAccountId}
          paymentId={paymentId}
          isPolling
          returnPath={process.env.REACT_APP_BASE_URL + returnPath}
          onClose={() => {
            history.push(returnPath.replace('/add-cash', ''));
          }}
          open
        />
      )}
      <QueryState {...accountsQuery}>
        {({ data }) => {
          const dpcAccountPaths =
            data?.accounts?.map((account) => {
              return generateDynamicPortfolioConstructionPath({
                wrapperType: getPathSegmentForWrapperType(account.wrapperType),
              });
            }) || [];
          return (
            <Switch>
              {selectedAccountId && (
                <AuthRoute path={[checkoutPath]}>
                  <DynamicPortfolioConstructionCheckout
                    selectedAccount={selectedAccount}
                    selectedAccountId={selectedAccountId}
                  />
                </AuthRoute>
              )}

              <AuthRoute path={dpcAccountPaths}>
                <DynamicPortfolioConstructionDecisionMaking
                  selectedAccountStatus={selectedAccountStatus}
                  selectedAccountType={selectedAccountType}
                  selectedAccountId={selectedAccountId}
                />
              </AuthRoute>

              {basketType === 'LocalBasket' && (
                <Route path={dpcUnauthPath}>
                  <DynamicPortfolioConstructionDecisionMaking
                    selectedAccountType={selectedAccountType}
                    selectedAccountId={null}
                  />
                </Route>
              )}

              <Route>
                <NotFound />
              </Route>
            </Switch>
          );
        }}
      </QueryState>
    </div>
  );
}
