import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const EMPLOYER_REFERRAL_COOKIE_NAME = 'employerReferralCode';
const EMPLOYER_REFERRAL_COOKIE_EXPIRY = 30;

const clearReferralCode = () =>
  Cookies.remove(EMPLOYER_REFERRAL_COOKIE_NAME, {
    domain: '.tillitinvest.com',
  });

const setReferralCode = (code: string) => {
  Cookies.set(EMPLOYER_REFERRAL_COOKIE_NAME, code, {
    expires: EMPLOYER_REFERRAL_COOKIE_EXPIRY,
    domain: '.tillitinvest.com',
  });
};

/**
 *
 * Retrieves the employer referral code from a cookie (dropped by employer hub pages)
 * or from a query parameter and returns it as a string
 *
 * @returns
 */
export function useGetEmployerCode() {
  // Get referral code from url parameter
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hasParamCode = params.has('referralCode');
  const paramCode = hasParamCode ? params.get('referralCode') : undefined;

  // Get referral cookie from cookie
  const cookieEmployerCode = Cookies.get(EMPLOYER_REFERRAL_COOKIE_NAME);
  const hasCookieEmployerCode = typeof cookieEmployerCode === 'string';

  // Clear cookie if cookie referral code is empty
  if (hasCookieEmployerCode && cookieEmployerCode === '') {
    clearReferralCode();
  }

  // Has param code and this does not match the cookie code, set the cookie to the param code
  if (hasParamCode && paramCode && paramCode !== cookieEmployerCode) {
    setReferralCode(paramCode);
  }

  let referralCode = undefined;
  if (hasParamCode && paramCode) {
    referralCode = paramCode;
  } else if (hasCookieEmployerCode) {
    referralCode = cookieEmployerCode;
  }

  return { referralCode, clearReferralCode };
}
