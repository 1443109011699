import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H3, TextAlign } from 'components/design-system/Heading/Heading';
import { FontWeight, Text } from 'components/design-system/Text/Text';
import { usePortfolioRebalancingQuery } from 'generated/graphql';
import React from 'react';
import { HiCheck } from 'react-icons/hi';
import { ButtonContainer } from '../AddToBasketDialog.style';
import { CheckIconWrapper } from './QuickOrderReceived.styles';

interface QuickOrderReceivedProps {
  type: 'buy' | 'sell';
  quickOrderId: string;
  onProceed: () => void;
  selectedAccountType: string;
}

export const QuickOrderReceived = ({
  type,
  quickOrderId,
  onProceed,
  selectedAccountType,
}: QuickOrderReceivedProps) => {
  const portfolioRebalancingsQuery = usePortfolioRebalancingQuery({
    id: quickOrderId,
  });
  const portfolioRebalancing =
    portfolioRebalancingsQuery.data?.portfolioRebalancing;
  const order =
    type === 'sell'
      ? portfolioRebalancing?.sellOrders[0]
      : portfolioRebalancing?.buyOrders[0];

  const titleCaseType = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <div>
      <QueryState {...portfolioRebalancingsQuery}>
        {() => {
          return (
            <>
              <CheckIconWrapper>
                <HiCheck size={'1.8rem'} />
              </CheckIconWrapper>
              <H3 $textAlign={TextAlign.center}>We've received your order</H3>
              <Text
                $textAlign={TextAlign.center}
                $fontWeight={FontWeight.normal}
              >
                We've received your <b>{titleCaseType}</b> order for{' '}
                <strong>{order?.instrument?.name}</strong>. Please note that it
                may take a few days to settle your order.
              </Text>
              <Text
                $textAlign={TextAlign.center}
                $fontWeight={FontWeight.normal}
              >
                Once the order has settled it will show on your Dashboard in the
                'Holdings' section. You can view and download the contract notes
                in the 'My documents' section.
              </Text>
              <ButtonContainer style={{ marginTop: '3.5rem' }}>
                <CustomButtonV2
                  onClick={() => {
                    onProceed();
                  }}
                >
                  Close
                </CustomButtonV2>
              </ButtonContainer>
            </>
          );
        }}
      </QueryState>
    </div>
  );
};
