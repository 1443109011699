import { AccountType, WrapperType } from 'generated/graphql';

type AccountTypeLookupType = Partial<{ [key in WrapperType]: string }>;
type WrapperTypeLookupType = Partial<{ [key in string]: WrapperType }>;

export const getPathSegmentForWrapperType = (wrapperType: WrapperType) => {
  const lookup: AccountTypeLookupType = {
    [WrapperType.Sipp]: 'pension',
  };

  return lookup[wrapperType] ?? wrapperType.toLowerCase();
};

export const getShortNameForWrapperType = (accountType: WrapperType) => {
  const lookup: AccountTypeLookupType = {
    [WrapperType.Sipp]: 'Pension',
  };

  return lookup[accountType] ?? accountType;
};

export const getNameForWrapperType = (accountType: WrapperType) => {
  const lookup: AccountTypeLookupType = {
    [WrapperType.Sipp]: 'Pension',
    [WrapperType.Isa]: 'Stocks & Shares ISA',
  };

  return lookup[accountType] ?? accountType;
};

export const getNameForAccountType = (accountType: AccountType) => {
  const lookup: Partial<{ [key in AccountType]: string }> = {
    [AccountType.Sipp]: 'Pension',
    [AccountType.Isa]: 'Stocks & Shares ISA',
  };

  return lookup[accountType] ?? accountType;
};

export const getWrapperTypeForPathSegment = (wrapperPathSegment: string) => {
  const lookup: WrapperTypeLookupType = {
    gia: WrapperType.Gia,
    isa: WrapperType.Isa,
    pension: WrapperType.Sipp,
  };

  return lookup[wrapperPathSegment];
};
