import { Tabs } from '@material-ui/core';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { FontSize } from 'components/design-system/Text/Text';
import React, { useState } from 'react';
import { StyledTab, TabContainer } from './TabTable.styles';
import { colors } from 'constants/colors';

export type DataType = {
  name: React.ReactNode | JSX.Element | string;
  value: string;
  valueColor?: string;
};

export type TabTableData = {
  label: string;
  data: DataType[];
  extraInfo?: React.ReactNode | JSX.Element | string;
};

export interface TabTableProps {
  data: TabTableData[];
}

export function TabTable({ data }: TabTableProps) {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  function tabProps(index: any) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <>
      <TabContainer>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Tab panel"
          variant="scrollable"
          scrollButtons="auto"
        >
          {data.map((item, index) => (
            <StyledTab
              key={`tab-${index}`}
              label={item.label}
              {...tabProps(index)}
            />
          ))}
        </Tabs>
      </TabContainer>
      <div>
        {data.map((row, index) => (
          <div
            role="tabpanel"
            key={`tabpanel-${index}`}
            hidden={activeTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
          >
            {activeTab === index &&
              row.data.map((item, i) => (
                <KeyValueRow
                  key={`tab-row-${i}`}
                  label={item.name}
                  value={item.value}
                  valueColor={item.valueColor || colors.richBlack}
                  fontSize={FontSize.xs}
                  divider={i > 0}
                />
              ))}
            {(activeTab === index && row.extraInfo) || ''}
          </div>
        ))}
      </div>
    </>
  );
}
