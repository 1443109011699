import { HTMLMotionProps, motion, Variants } from 'framer-motion';
import React from 'react';

const pageVariants: Variants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
  out: {
    opacity: 0,
    x: '-100%',
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
};

export function SlideIn(props: HTMLMotionProps<'div'>) {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      {...props}
    />
  );
}
