import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;

  .MuiTabs-indicator {
    background-color: ${colors.magenta};
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: 300;
  }

  &.Mui-selected {
    font-weight: 500;
    color: ${colors.richBlack};
  }

  &.Mui-disabled {
    color: ${transparentize(0.5, colors.richBlack)};
  }
`;
