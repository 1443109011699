import { SmallCopy } from 'components/Copy/SmallCopy';
import { InstrumentSelectorAlignment } from 'components/InstrumentSelector/InstrumentSelector';
import { QueryState } from 'components/QueryState';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import {
  FundInfoBoxQuery,
  Instrument,
  useFundInfoBoxQuery,
} from 'generated/graphql';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { ContentSection } from '../ContentSection';
import { PerformanceBox } from '../PerformanceBox';
import { ShareClassesBox } from '../ShareClassesBox';
import {
  Divider,
  KeyValueContainer,
  NoContent,
  PerformanceContainer,
  Spacer,
  Title,
} from '../Styles/FundDetails.style';

interface PremiumDiscountProps {
  selectedIsin: string;
}

type FundInfoboxType = NonNullable<FundInfoBoxQuery['fundInfobox']>;
type FundInfoboxSectionsType = NonNullable<FundInfoboxType['sections']>;
type FundInfoboxSectionType = NonNullable<FundInfoboxSectionsType[number]>;
type FundInfoboxSectionDataType = NonNullable<
  FundInfoboxSectionType['data']
>[number];
type FundInfoboxSectionDataItemType = NonNullable<FundInfoboxSectionDataType>;

const getPremiumDiscountValue = (
  premiumDiscountInfo: FundInfoboxSectionDataItemType
) => {
  if (premiumDiscountInfo?.valueRendered === null) {
    return premiumDiscountInfo?.emptyValue;
  }
  return premiumDiscountInfo?.kind === 'PERCENTAGE' &&
    premiumDiscountInfo?.valueRendered
    ? format.percent(
        parseFloat(premiumDiscountInfo.valueRendered as string) / 100
      )
    : premiumDiscountInfo?.valueRendered;
};

function PremiumDiscount({ selectedIsin }: PremiumDiscountProps) {
  const fundInfoQuery = useFundInfoBoxQuery({ isin: selectedIsin });
  const fundInfo = fundInfoQuery.data?.fundInfobox?.sections;

  if (fundInfo === null || fundInfo === undefined || fundInfo.length === 0) {
    return null;
  }

  return (
    <QueryState {...fundInfoQuery}>
      {() => {
        const feesInfo = fundInfo.find((i: any) => i.title === 'Fees');
        if (
          feesInfo === undefined ||
          feesInfo === null ||
          feesInfo.data === null ||
          feesInfo.data === undefined
        ) {
          return null;
        }

        const premiumDiscountInfo = feesInfo.data.find((i) =>
          i?.name.includes('Premium/discount')
        );

        if (premiumDiscountInfo === undefined || premiumDiscountInfo === null) {
          return null;
        }

        const value = getPremiumDiscountValue(premiumDiscountInfo);

        return (
          <KeyValueContainer>
            <TextNormal $fontWeight={FontWeight.medium} $noMargin>
              {premiumDiscountInfo?.name}:
            </TextNormal>
            <TextNormal $noMargin>{value}</TextNormal>
          </KeyValueContainer>
        );
      }}
    </QueryState>
  );
}

interface PerformanceSliceProps {
  asset: AssetQueryAsset;
  selectedIsin: string;
  handleInstrumentChange: (isin: string) => void;
}

type PerformanceUnavailableContentProps = {
  asset: AssetQueryAsset;
};

const PerformanceUnavailableContent = ({
  asset,
}: PerformanceUnavailableContentProps) => {
  const assetCustomContent = asset.conditionalContent?.nodes.find(
    (x) => x.key === 'WHY_IS_THERE_NO_PERFORMANCE_DATA'
  );

  if (assetCustomContent?.renderedContent) {
    return (
      <ContentSection
        key={assetCustomContent.key}
        title=""
        type={assetCustomContent.type || ''}
        content={assetCustomContent.renderedContent || ''}
      />
    );
  } else {
    return <NoContent>Performance is not yet available.</NoContent>;
  }
};

export function PerformanceSlice({
  asset,
  selectedIsin,
  handleInstrumentChange,
}: PerformanceSliceProps) {
  const currentInstrument = asset.instruments?.nodes?.find(
    (i) => i.isin === selectedIsin
  );

  const canShowPerformanceData =
    asset && currentInstrument?.canShowPerformanceData;

  return (
    <PerformanceContainer>
      <Title>Performance</Title>

      {!asset?.isTargetDateFund && (
        <ShareClassesBox
          $align={InstrumentSelectorAlignment.left}
          instruments={asset.instruments.nodes as Instrument[]}
          value={selectedIsin}
          onChange={handleInstrumentChange}
        />
      )}

      {canShowPerformanceData ? (
        <PerformanceBox isin={selectedIsin} asset={asset} />
      ) : (
        <PerformanceUnavailableContent asset={asset} />
      )}
      <Spacer size={30} />

      {asset.structure?.id === 'INVTRUST' && (
        <PremiumDiscount selectedIsin={selectedIsin} />
      )}

      {canShowPerformanceData && (
        <SmallCopy
          $size={16}
          $fontStyle="italic"
          copy={`Data provided by FE fundinfo, retrieved on ${format.date(
            currentInstrument?.valuationSummary?.lastUpdatedUtc
          )}. Performance figures are calculated on a bid price to bid price basis (mid to mid for OEICs), include fund charges and are updated to the most recent price date available unless stated otherwise.`}
        />
      )}
      <SmallCopy
        $size={16}
        $fontStyle="italic"
        copy="Past performance is not a reliable indicator of future results. The value of an investment, and the income from it, can fall as well as rise. You could get back less than originally invested."
      />
      <Divider />
    </PerformanceContainer>
  );
}
