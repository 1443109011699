import { IconButton, IconButtonProps, Paper, Popper } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export interface StyledIconButtonProps extends IconButtonProps {
  $disablePadding?: boolean;
  $disableMargin?: boolean;
}

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
  ${(p) => !p.$disableMargin && `margin-left: ${p.theme.spacing(1)}px;`}
  ${(p) => p.$disablePadding && 'padding: 0;'}

  &.MuiIconButton-colorPrimary {
    color: ${colors.magenta};
  }
`;

export const StyledPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)}px;
  max-width: ${(p) => p.theme.breakpoints.values.sm}px;
  background-color: ${colors.seaBlue};
  overflow: visible;

  .MuiTypography-root {
    color: ${colors.white};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
`;

export const StyledPopper = styled(Popper)`
  z-index: ${(p) => p.theme.zIndex.tooltip};
  .MuiPaper-root {
    padding: ${(p) => p.theme.spacing(2)}px;
    max-width: ${(p) => p.theme.breakpoints.values.sm}px;
    background-color: ${colors.seaBlue};
    overflow: visible;

    .MuiTypography-root {
      color: ${colors.white};
    }
  }

  &[x-placement*='top'] {
    .MuiPaper-root {
      margin-bottom: 10px;
    }

    ${Arrow} {
      bottom: 0;
      border-top: 10px solid ${colors.seaBlue};
    }
  }

  &[x-placement*='bottom'] {
    .MuiPaper-root {
      margin-top: 10px;
    }

    ${Arrow} {
      top: 0;
      border-bottom: 10px solid ${colors.seaBlue};
    }
  }
`;
