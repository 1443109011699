import * as Yup from 'yup';

const decimalRegex = /^\d+(\.\d{1,2})?$/;

export const amount = () =>
  Yup.number().test(
    'maxDigitsAfterDecimal',
    'Amount field must have 2 digits after decimal or less',
    (number) =>
      typeof number === 'number' && decimalRegex.test(number.toString())
  );
