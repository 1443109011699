import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BubbleContainer = styled.div`
  position: relative;
  pointer-events: visible;
`;

interface BubbleValueProps {
  $bubbleWidth: number;
  $proportion: number;
}
export const BubbleValue = styled.div<BubbleValueProps>`
  font-size: ${({ $bubbleWidth }) =>
    $bubbleWidth > 400
      ? '5rem'
      : $bubbleWidth > 325
      ? '4rem'
      : $bubbleWidth > 250
      ? '3rem'
      : $bubbleWidth > 200
      ? '2.25rem'
      : $bubbleWidth > 150
      ? '1.5rem'
      : '1.25rem'};
  font-family: 'GT Super', serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-weight: 300;
  color: ${colors.white};
  text-align: center;
`;

interface BubbleTextProps {
  $bubbleWidth: number;
}
export const BubbleText = styled.text<BubbleTextProps>`
  font-family: 'IBM Plex Sans';
  font-size: 0.75rem;
  pointer-events: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ $bubbleWidth }) =>
      $bubbleWidth > 400
        ? '2rem'
        : $bubbleWidth > 325
        ? '1.75rem'
        : $bubbleWidth > 250
        ? '1.5rem'
        : $bubbleWidth > 200
        ? '1.25rem'
        : $bubbleWidth > 150
        ? '1rem'
        : '0.75rem'};
  }
`;

export const BubbleContentLayout = styled.div`
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95%;
  width: 95%;
  margin: 2.5%;
  gap: 0.25rem;
`;

export const StyledBubble = styled.circle`
  filter: drop-shadow(2px 5px 10px ${transparentize(0.65, colors.richBlue)});
`;
