import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { StyledLink } from 'components/design-system/Link';
import { TabNav } from 'components/design-system/Tabs/TabNav/TabNav';
import {
  Text,
  TextAlign,
  TextNormal,
} from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { AccountStatus, WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  dynamicPortfolioConstructionAddCashPath,
  dynamicPortfolioConstructionBasketAddCashPath,
  dynamicPortfolioConstructionBasketPath,
  dynamicPortfolioConstructionFundsAddCashPath,
  dynamicPortfolioConstructionFundsPath,
  dynamicPortfolioConstructionPath,
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
  generateDynamicPortfolioConstructionFundsPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { FundsListPortfolioBuilderLayout } from '../FundsList/FundsListPortfolioBuilderLayout';
import { useMode } from '../mode/useMode';
import { FundsBasket } from './FundsBasket/FundsBasket';
import { BasketOnlyAllocation } from './InteractiveBreakdown/BasketOnlyAllocation';
import { InteractiveBreakdown } from './InteractiveBreakdown/InteractiveBreakdown';
import { MyFundsList } from './MyFundsList';
import {
  ButtonErrorWrapper,
  ClosedAccountMessageWrapper,
  DecisionMakingContainer,
  ErrorMessage,
  PortfolioBuilderContainer,
  PreambleWrapper,
} from './PortfolioBuilder.styles';
import { PortfolioBuilderRating } from './Rating/PortfolioBuilderRating';
import { ColumnHeading, ColumnHeadingTabs } from './_shared/ColumnHeading';
import { FooterBar } from './_shared/FooterBar';
import { HeaderBar } from './_shared/HeaderBar';
import { useFundsBasket } from './hooks/useFundsBasket';

interface DynamicPortfolioConstructionDecisionMakingProps {
  selectedAccountType: WrapperType;
  selectedAccountId?: string | null;
  selectedAccountStatus?: AccountStatus;
}

export const DynamicPortfolioConstructionDecisionMaking = ({
  selectedAccountType,
  selectedAccountId,
  selectedAccountStatus,
}: DynamicPortfolioConstructionDecisionMakingProps) => {
  const [modeToggle] = useToggle('global-mode');

  const {
    syncToServerQueryState,
    portfolioRebalancingsQuery,
    canCheckout,
    getRebalancingId,
    basketSummary,
  } = useFundsBasket({
    selectedAccountId,
  });

  const { showNewMessages } = useIntercom();
  const rebalancingId = getRebalancingId();
  const accountIsClosed = selectedAccountStatus === AccountStatus.Closed;

  const [mode, setMode] = useMode();

  useEffect(() => {
    if (
      mode !== null &&
      mode.mode !== 'resume' &&
      mode.wrapperType !== selectedAccountType
    ) {
      setMode({
        wrapperType: selectedAccountType,
        mode: 'buy',
      });
    }
  }, [selectedAccountType, mode, setMode]);

  const handleReopenAccountLink = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `portfolio builder - reopen my ${selectedAccountType}`,
    });
    showNewMessages(`I'd like to reopen my ${selectedAccountType}`);
  };

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {() => {
        return (
          <div>
            <DecisionMakingContainer maxWidth="lg">
              <HeaderBar selectedAccountType={selectedAccountType} />
              <PreambleWrapper>
                <TextNormal>
                  This new tool allows you to build and rebalance your portfolio
                  with asset allocation in mind, broken down by asset class or
                  region.
                </TextNormal>
                <TextNormal>
                  Please note, the asset allocation is based on the mandate of
                  the funds. The mandate is what (asset class) or where (region)
                  a fund is able to invest in. At this stage, the tool does not
                  take into account current holdings or live exposure within a
                  fund.
                </TextNormal>
              </PreambleWrapper>
              {!accountIsClosed ? (
                <Card>
                  {selectedAccountId && selectedAccountType ? (
                    <InteractiveBreakdown
                      selectedAccountType={selectedAccountType}
                      selectedAccountId={selectedAccountId}
                    />
                  ) : (
                    <BasketOnlyAllocation
                      selectedAccountType={selectedAccountType}
                      selectedAccountId={selectedAccountId || ''}
                    />
                  )}
                </Card>
              ) : (
                <Card $padding="lg">
                  <ClosedAccountMessageWrapper>
                    <Text $textAlign={TextAlign.center} $noMargin>
                      This account is closed. If you would like to reopen it,
                      please speak to our{' '}
                      <StyledLink onClick={handleReopenAccountLink}>
                        customer support team
                      </StyledLink>
                      .
                    </Text>
                  </ClosedAccountMessageWrapper>
                </Card>
              )}
            </DecisionMakingContainer>

            <ColumnHeading>
              <ColumnHeadingTabs>
                <TabNav
                  navItems={[
                    {
                      label: 'My funds',
                      href: generateDynamicPortfolioConstructionPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                    {
                      label: 'Basket',
                      href: generateDynamicPortfolioConstructionBasketPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                    {
                      label: 'Search funds',
                      href: generateDynamicPortfolioConstructionFundsPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                  ]}
                />
              </ColumnHeadingTabs>
            </ColumnHeading>

            <Route
              path={[
                dynamicPortfolioConstructionPath,
                dynamicPortfolioConstructionAddCashPath,
              ]}
              exact
            >
              <PortfolioBuilderContainer maxWidth="lg">
                <MyFundsList
                  selectedAccountType={selectedAccountType}
                  selectedAccountId={selectedAccountId || undefined}
                />
              </PortfolioBuilderContainer>
            </Route>
            <Route
              path={[
                dynamicPortfolioConstructionFundsPath,
                dynamicPortfolioConstructionFundsAddCashPath,
              ]}
              exact
            >
              <FundsListPortfolioBuilderLayout
                selectedAccountType={selectedAccountType}
                selectedAccountId={selectedAccountId || ''}
                accountIsClosed={accountIsClosed}
              />
            </Route>
            <Route
              path={[
                dynamicPortfolioConstructionBasketPath,
                dynamicPortfolioConstructionBasketAddCashPath,
              ]}
              exact
            >
              <PortfolioBuilderContainer maxWidth="lg">
                <FundsBasket
                  selectedAccountId={selectedAccountId || undefined}
                  selectedAccountType={selectedAccountType}
                />
              </PortfolioBuilderContainer>
            </Route>

            {!modeToggle && (
              <FooterBar selectedAccountId={selectedAccountId}>
                {selectedAccountId !== null && rebalancingId && (
                  <ButtonErrorWrapper>
                    <CustomButtonV2
                      disabled={
                        !canCheckout() ||
                        syncToServerQueryState.isLoading ||
                        accountIsClosed
                      }
                      href={generateCheckoutPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                        selectedRebalancingId: rebalancingId,
                      })}
                    >
                      Checkout
                    </CustomButtonV2>
                    {syncToServerQueryState.error && (
                      <ErrorMessage $noMargin>
                        Something went wrong
                      </ErrorMessage>
                    )}
                  </ButtonErrorWrapper>
                )}
              </FooterBar>
            )}

            <PortfolioBuilderRating basketCount={basketSummary.orderCount} />
          </div>
        );
      }}
    </QueryState>
  );
};
