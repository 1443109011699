import { colors } from 'constants/colors';
import { lighten, transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

export interface DefaultGradientProps {
  id?: string;
  gradientOffset: number;
}

export function DefaultGradient({ id, gradientOffset }: DefaultGradientProps) {
  const { darkUniverse } = useTheme();

  return (
    <linearGradient id={id} gradientTransform="rotate(90)">
      <stop
        offset="0"
        stopColor={
          darkUniverse ? '#555555' : transparentize(0.2, colors.purple)
        }
      />
      <stop
        offset={gradientOffset}
        stopColor={darkUniverse ? '#aaaaaa' : lighten(0.2, colors.purple)}
      />
      <stop
        offset={gradientOffset}
        stopColor={darkUniverse ? '#aaaaaa' : lighten(0.3, colors.seaBlue)}
      />
      <stop
        offset="1"
        stopColor={
          darkUniverse ? '#333333' : transparentize(0.2, colors.seaBlue)
        }
      />
    </linearGradient>
  );
}
