import { WrapperType } from 'generated/graphql';
import { DataPill } from 'components/Pills';
import * as format from 'formatting';
import React from 'react';
import { AccountData } from './AccountSection';
import {
  WhiteSectionTitle,
  PillWrapper,
  SectionInner,
} from './AccountSection.style';
import { ClosedActions } from './AccountActions';

export interface PureAccountClosedSectionProps {
  accountType: WrapperType;
  accountId?: string;
  accountData?: AccountData;
  children: React.ReactNode;
  onWithdrawCash: () => void;
  onReOpen: () => void;
}

export function PureAccountClosedSection({
  accountType,
  accountId,
  accountData,
  children,
  onWithdrawCash,
  onReOpen,
}: PureAccountClosedSectionProps) {
  const pillContent = [
    {
      value: format.currencyFull(accountData?.uninvestedCash ?? 0),
      label: 'Available cash',
    },
  ];

  return (
    <SectionInner id={accountId}>
      <WhiteSectionTitle accountType={accountType} />

      <PillWrapper>
        {accountData?.uninvestedCash! > 0 && (
          <DataPill content={pillContent} dark={true} />
        )}
      </PillWrapper>

      <ClosedActions
        isSection={true}
        accountType={accountType}
        value={accountData?.uninvestedCash ?? 0}
        onWithdrawCash={onWithdrawCash}
        onReOpen={onReOpen}
      />

      {children}
    </SectionInner>
  );
}
