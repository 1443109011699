import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@material-ui/core';
import kfd from 'assets/Pension_KFD_2024.pdf';
import { ControlledCheckbox } from 'components/Form/ControlledCheckbox';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  AffirmationKind,
  WrapperType,
  useCreateAnotherAccountMutation,
  useMakeAffirmationMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAffirmation } from 'hooks/useAffirmation';
import { transparentize } from 'polished';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HiOutlineDownload } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';
import { PensionDeclarationCopy } from './_shared/DeclarationCopy';

// Affirmations
const AffirmationsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
`;

// Download
const DownloadContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: 5rem 1fr 5rem;
  }

  justify-items: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background-color: ${transparentize(0.93, colors.richBlue)};
  margin: 37px 0;
  padding: 0.25rem 0.25rem;
`;
const DownloadLink = styled(Link)`
  font-weight: 300;
  cursor: pointer;
  color: ${colors.richBlue};
`;
const DownloadIcon = styled(HiOutlineDownload)`
  font-size: 1.5rem;
  color: ${colors.richBlue};
  justify-self: start;
`;

const declarationStepFormSchema = Yup.object({
  termsDeclaration: Yup.boolean()
    .oneOf([true], 'Please check the Terms and Conditions checkbox to continue')
    .required(),
  kfdDeclaration: Yup.boolean()
    .oneOf([true], 'Please check the KFD checkbox to continue')
    .required(),
});

type DeclarationStepFormValues = Yup.InferType<
  typeof declarationStepFormSchema
>;

export interface DeclarationStepProps {
  onProceed: () => void;
  onGoBack: () => void;
  variant: 'open-account' | 'open-second-account';
  includeTransferInDeclarations: boolean;
}

export function DeclarationStep({
  onProceed,
  onGoBack,
  variant,
  includeTransferInDeclarations,
}: DeclarationStepProps) {
  const kfdDeclarationAffirmation = useAffirmation(
    AffirmationKind.ReadAndUnderstoodSippKfd
  );

  const queryClient = useQueryClient();
  const {
    mutateAsync: affirmationMutateAsync,
    isError: affirmationIsError,
  } = useMakeAffirmationMutation();

  const {
    mutateAsync: createAnotherAccountMutateAsync,
    isError: createAnotherAccountIsError,
    reset,
  } = useCreateAnotherAccountMutation();

  const defaultValues = {
    termsDeclaration: false,
    kfdDeclaration: kfdDeclarationAffirmation,
  };

  const methods = useForm<DeclarationStepFormValues>({
    defaultValues,
    resolver: yupResolver(declarationStepFormSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<DeclarationStepFormValues> = async () => {
    try {
      reset();
      if (isDirty) {
        await affirmationMutateAsync({
          input: {
            affirmations: [AffirmationKind.ReadAndUnderstoodSippKfd],
          },
        });
        if (variant === 'open-second-account') {
          await createAnotherAccountMutateAsync({
            input: {
              wrapperType: WrapperType.Sipp,
            },
          });
        }
        queryClient.invalidateQueries(useUserProfileQuery.getKey());
      }

      if (variant === 'open-account') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.declarationSipp,
        });
      }

      onProceed();
    } catch {
      // error handled by state
    }
  };

  const declarationPdfLink = includeTransferInDeclarations
    ? 'https://d1ur1kq4xhe5pz.cloudfront.net/images/Pension-Declaration-2024-With-Transfers.pdf'
    : 'https://d1ur1kq4xhe5pz.cloudfront.net/images/Pension-Declaration-2024.pdf';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepTitle>TILLIT Pension declaration</StepTitle>
          <StepContent width={StepContentWidth.extraWide}>
            <PensionDeclarationCopy
              includeTransferInDeclarations={includeTransferInDeclarations}
            />
            <AffirmationsContainer>
              <ControlledCheckbox
                disabled={isSubmitting || defaultValues.kfdDeclaration}
                name="kfdDeclaration"
                label={
                  <>
                    I have read and understood the key features of this
                    investment product.{' '}
                    <StyledLink href={kfd} target="_blank">
                      Read the Key Features Document (KFD)
                    </StyledLink>
                    .
                  </>
                }
              />
              <ControlledCheckbox
                disabled={isSubmitting}
                name="termsDeclaration"
                label={
                  <>
                    I accept the{' '}
                    <StyledLink
                      href="https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </StyledLink>{' '}
                    of this product, which may have been updated since I last
                    opened a TILLIT investment account
                  </>
                }
              />
            </AffirmationsContainer>
            <StepContent width={StepContentWidth.wide}>
              <DownloadContainer>
                <DownloadIcon />
                <DownloadLink
                  href={declarationPdfLink}
                  target="_blank"
                  underline="none"
                >
                  Download PDF copy of my TILLIT Pension declaration
                </DownloadLink>
              </DownloadContainer>
            </StepContent>
            <ServerError
              isVisible={affirmationIsError || createAnotherAccountIsError}
            />
          </StepContent>

          <StepActions>
            <StepButton
              type="submit"
              className="magenta"
              disabled={isSubmitting}
            >
              {variant === 'open-second-account'
                ? 'Open TILLIT Pension account'
                : 'Continue'}
            </StepButton>
            <GoBackButton onClick={onGoBack} disabled={isSubmitting} />
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}
