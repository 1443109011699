import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import { GoBackButton } from 'components/design-system/StepComponents/StepComponents';
import { FontSize, Text } from 'components/design-system/Text/Text';
import { PolicyPreTradeText } from 'components/feature/Checkout/PolicyPreTradeText/PolicyPreTradeText';
import { currencyFull } from 'formatting';
import {
  WrapperType,
  useAccountsQuery,
  useExecutePortfolioRebalancingBuysMutation,
  usePortfolioRebalancingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { generateFundDetailsPath } from 'paths';
import { useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { v4 as uuid } from 'uuid';
import { ImportantBuyInformation } from '../../ImportantInformation/ImportantBuyInformation';
import {
  ButtonContainer,
  FundDoLink,
  Pill,
  PillContainer,
  Section,
} from '../AddToBasketDialog.style';
import { ActionsText } from './QuickOrderConfirm.styles';

interface QuickOrderConfirmProps {
  quickOrderId: string;
  selectedAccountId: string;
  selectedAccountType: WrapperType;
  onProceed: () => void;
  onGoBack: () => void;
}

export const QuickOrderConfirm = ({
  quickOrderId,
  selectedAccountId,
  selectedAccountType,
  onProceed,
  onGoBack,
}: QuickOrderConfirmProps) => {
  const userProfileQuery = useUserProfileQuery();
  const employment = userProfileQuery.data?.userProfile?.employments?.[0];

  const [idempotencyToken] = useState(uuid());
  const portfolioRebalancingQuery = usePortfolioRebalancingQuery({
    id: quickOrderId,
  });
  const portfolioRebalancing =
    portfolioRebalancingQuery.data?.portfolioRebalancing;
  const buyOrder = portfolioRebalancing?.buyOrders[0];

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === selectedAccountType
  );
  const existingPosition = currentAccount?.positions?.find(
    (position) => position.isin === buyOrder?.isin
  );

  const executePortfolioRebalancingBuysMutationQuery = useExecutePortfolioRebalancingBuysMutation();
  const {
    mutateAsync,
    error,
    isLoading,
  } = executePortfolioRebalancingBuysMutationQuery;
  const executePortfolioRebalancingBuys = async () => {
    try {
      await mutateAsync({
        input: {
          idempotencyToken: idempotencyToken,
          portfolioRebalancingId: quickOrderId,
        },
      });
      onProceed();
    } catch {
      // handled by state
    }
  };

  const instrument = buyOrder?.instrument;
  const charges = buyOrder?.context?.charges;
  const asset = instrument?.asset;

  return (
    <div>
      <QueryState {...portfolioRebalancingQuery}>
        {() => {
          if (
            portfolioRebalancing?.buyOrders.length !== 1 &&
            portfolioRebalancing?.sellOrders.length !== 0
          ) {
            return <div>Something went wrong</div>;
          }
          return (
            <div>
              <PillContainer>
                <Pill $filled $color="buy">
                  BUY
                </Pill>
                <Pill>{getShortNameForWrapperType(selectedAccountType)}</Pill>
              </PillContainer>
              <div>
                <H4>{buyOrder?.instrument.name}</H4>
                <FundDoLink
                  to={{
                    pathname: generateFundDetailsPath({
                      id: asset?.id!,
                      slug: asset?.slug!,
                    }),
                    state: {
                      shouldGoBack: true,
                    },
                  }}
                  target="_blank"
                  // onClick={() => trackFundOpen('link', asset, index)}
                >
                  What does this fund do? <HiExternalLink />
                </FundDoLink>
              </div>
              <Section>
                <Text $fontSize={FontSize.extraLarge} $noMargin>
                  {currencyFull(buyOrder?.amount!)}
                </Text>
              </Section>
              <ImportantBuyInformation
                showTopBar={false}
                selectedInstrument={buyOrder?.instrument!}
                amountEntered={buyOrder?.amount!}
                charges={charges}
                existingPosition={existingPosition}
              />

              <ActionsText>
                The value of investments can go up and down, so you could get
                back less than you put in.{' '}
                {selectedAccountType === WrapperType.Isa &&
                  "Tax treatment depends on an individual's circumstances and may be subject to change."}
              </ActionsText>
              {employment && <PolicyPreTradeText employment={employment} />}
              <ButtonContainer>
                <CustomButtonV2
                  onClick={() => executePortfolioRebalancingBuys()}
                  disabled={isLoading}
                >
                  Confirm Order
                </CustomButtonV2>
                {error && <div>Something went wrong</div>}
                <GoBackButton onClick={onGoBack} />
              </ButtonContainer>
            </div>
          );
        }}
      </QueryState>
    </div>
  );
};
