import { useMediaQuery, useTheme } from '@material-ui/core';
import { TabTable } from 'components/TabTable/TabTable';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { CardTableContainer } from '../_shared/DrillDownTab.styles';
import { Table, Td, Tr } from '../_shared/Table.styles';

interface RegionTableComparisonProps {
  data: any;
  name: string;
  footNote?: React.ReactNode | JSX.Element | string;
}
export const BreakdownTableComparison = ({
  data,
  name,
  footNote,
}: RegionTableComparisonProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const holdingsLabel = 'Est. holding (%)';
  const valueLabel = 'Est. value (£)';
  const changeLabel = 'Change (£)';

  const mobData = useMemo(() => {
    const orderedData = orderBy(data, 'end', 'desc');

    const getHoldingsData = () => {
      return orderedData.map((breakdown: any) => {
        return {
          name: breakdown.text,
          value: format.percent(breakdown.end / 100),
        };
      });
    };

    const getValueData = () => {
      return orderedData.map((breakdown: any) => {
        return {
          name: breakdown.text,
          value: format.currencyFull(breakdown.amount),
        };
      });
    };

    const getChangeData = () => {
      return orderedData.map((breakdown: any) => {
        return {
          name: breakdown.text,
          value: format.currencyFull(
            breakdown.amount - breakdown.existingAmount
          ),
          valueColor:
            breakdown.amount - breakdown.existingAmount === 0
              ? colors.richBlack
              : breakdown.amount - breakdown.existingAmount > 0
              ? colors.success
              : colors.danger,
        };
      });
    };

    return [
      {
        label: holdingsLabel,
        data: getHoldingsData(),
        extraInfo: footNote,
      },
      {
        label: valueLabel,
        data: getValueData(),
      },
      {
        label: changeLabel,
        data: getChangeData(),
      },
    ];
  }, [data, footNote]);

  if (isMobile) {
    return <TabTable data={mobData} />;
  } else {
    return (
      <CardTableContainer>
        <Table>
          <thead>
            <tr>
              <Td>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  {name}
                </TextSmall>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Est. holding (%)
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Est. value (£)
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Change (£)
                </Text>
              </Td>
            </tr>
          </thead>
          {orderBy(data, 'end', 'desc').map(
            ({ text, amount, end, existingAmount }) => (
              <Tr
                status={
                  amount > existingAmount
                    ? 'buying'
                    : amount < existingAmount
                    ? 'selling'
                    : 'neutral'
                }
              >
                <Td>
                  <TextXS $noMargin>{text}</TextXS>
                </Td>
                <Td>
                  <Text
                    $fontSize={FontSize.xs}
                    $fontWeight={FontWeight.light}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    {format.percent(end / 100)}
                  </Text>
                </Td>
                <Td>
                  <Text
                    $fontSize={FontSize.xs}
                    $fontWeight={FontWeight.light}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    {format.currencyFull(amount)}
                  </Text>
                </Td>
                <Td>
                  <Text
                    $fontSize={FontSize.xs}
                    $fontWeight={FontWeight.light}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    {format.currencyFull(amount - existingAmount)}
                  </Text>
                </Td>
              </Tr>
            )
          )}
        </Table>
      </CardTableContainer>
    );
  }
};
