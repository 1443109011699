import { ButtonsWrapper } from 'components/ButtonsWrapper';
import {
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { H3 } from 'components/design-system/Heading/Heading';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateDynamicPortfolioConstructionPath } from 'paths';
import {
  StepActions,
  StepContainer,
  StepContent,
} from '../../../../design-system/StepComponents/StepComponents';

interface PotentialFundingShortfallProps {
  selectedAccountType: WrapperType;
  onProceed: () => void;
}

export function PotentialFundingShortfall({
  selectedAccountType,
  onProceed,
  ...props
}: PotentialFundingShortfallProps) {
  return (
    <div>
      <StepContainer {...props}>
        <StepContent>
          <H3>Important information</H3>
          <Text $textAlign={TextAlign.center}>
            Based on your current cash balance and the value of the orders in
            your basket, if there is a significant change in the price of any of
            the funds in your order, then{' '}
            <strong>
              there may be a shortfall of cash, which would prevent the BUY
              orders from going ahead
            </strong>
            .
          </Text>
          <Text $textAlign={TextAlign.center}>
            If there is no significant change in fund prices while the order is
            being completed, then the cash raised from the SELL orders, together
            with the cash available in your account, should be sufficient to
            cover the BUY orders.
          </Text>
          <Text $textAlign={TextAlign.center}>
            You don’t have to do anything right now. Once the SELL orders have
            completed, we will send you an email confirmation to let you know if
            there is a shortfall, and if so, you can add cash to your account
            before confirming the BUY orders.
          </Text>
        </StepContent>
        <StepActions>
          <ButtonsWrapper $isHorizontal={true}>
            <LinkCustomButton
              $color="secondary"
              to={generateDynamicPortfolioConstructionPath({
                wrapperType: getPathSegmentForWrapperType(selectedAccountType),
              })}
            >
              Edit order
            </LinkCustomButton>
            <CustomButtonV2
              $size="normal"
              $color="primary"
              $isWide
              onClick={onProceed}
            >
              Continue
            </CustomButtonV2>
          </ButtonsWrapper>
        </StepActions>
      </StepContainer>
    </div>
  );
}
