import { DataPill } from 'components/Pills';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { SupportedWrapperType } from 'types/utilityTypes';
import { ClosingAction } from './AccountActions';
import { AccountData, SectionTitle } from './AccountSection';
import { DateLabel, PillWrapper, SectionInner } from './AccountSection.style';

export interface PureAccountClosingSectionProps {
  accountType: SupportedWrapperType;
  accountId?: string;
  accountData?: AccountData;
  children: React.ReactNode;
  onWithdrawCash: () => void;
  onSupport: () => void;
}

export function PureAccountClosingSection({
  accountType,
  accountId,
  accountData,
  children,
  onWithdrawCash,
  onSupport,
}: PureAccountClosingSectionProps) {
  const pillContent = [
    {
      value: format.currencyFull(accountData?.uninvestedCash ?? 0),
      label: 'Available cash',
    },
  ];

  return (
    <SectionInner id={accountId}>
      <SectionTitle accountType={accountType} />

      <DateLabel>This account is closing</DateLabel>

      <PillWrapper>
        <DataPill content={pillContent} />
      </PillWrapper>

      {accountType !== WrapperType.Sipp && (
        <ClosingAction
          isSection={true}
          accountType={accountType}
          value={accountData?.uninvestedCash ?? 0}
          onSupport={onSupport}
          onWithdrawCash={onWithdrawCash}
        />
      )}

      {children}
    </SectionInner>
  );
}
