import { useMediaQuery, useTheme } from '@material-ui/core';
import { StyledLink } from 'components/design-system/Link';
import { FundsGridListSearch } from 'components/feature/FundsList/FundsGridListSearch/FundsGridListSearch';
import { useFundListOptions } from 'components/feature/FundsList/hooks/useFundListOptions';
import { SliderLanding } from 'components/Landing/SliderLanding/SliderLanding';
import { useAuth } from 'context/AuthContext';
import { OnboardingStatus, useOnboardingStatusQuery } from 'generated/graphql';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { fundListPath, openAccountResumePath } from 'paths';
import { useHistory } from 'react-router-dom';
import { SimpleHero } from '../../components/Hero/SimpleHero';
import { PageContainerInner } from './Styles/Landing.styles';

export function Landing() {
  const { signedIn } = useAuth();

  const onboardingStatusQuery = useOnboardingStatusQuery(undefined, {
    enabled: signedIn,
  });
  const status =
    onboardingStatusQuery.data?.userProfile?.onboardingDetails?.status;

  const [options, setOptions] = useFundListOptions();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const history = useHistory();

  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <>
      {status === OnboardingStatus.FurtherInformationNeeded ? (
        <SimpleHero title="What do you want to invest in?" maxWidth="lg">
          Click{' '}
          <StyledLink href={openAccountResumePath}>‘finish setup’</StyledLink>{' '}
          to complete your account opening and get access to exclusive videos
          and insights.
        </SimpleHero>
      ) : (
        <SimpleHero
          title="What do you want to invest in?"
          maxWidth="lg"
        ></SimpleHero>
      )}
      <FundsGridListSearch
        showFilters={false}
        onFiltersClick={(newShowFilters) => {
          setOptions({
            ...options,
            showFilters: newShowFilters || isMdUp,
          });
          setTimeout(() => {
            history.push(fundListPath);
          }, 1);
        }}
      />
      <PageContainerInner maxWidth="lg">
        <SliderLanding />
      </PageContainerInner>
    </>
  );
}
