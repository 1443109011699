import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import {
  GoBackButton,
  StepActions,
} from 'components/design-system/StepComponents/StepComponents';
import React, { useState } from 'react';
import { EligibleAssetsInformation } from './EligibleAssetsInformation';

export interface EligibleAssetsDialogProps extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
}

export function EligibleAssetsDialog({
  closeDialog,
  open,
}: EligibleAssetsDialogProps) {
  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      <EligibleAssetsInformation />
      <StepActions>
        <GoBackButton onClick={closeDialog} />
      </StepActions>
    </ResponsiveDialog>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
