import { useMediaQuery, useTheme } from '@material-ui/core';
import { getGlossaryDataForCode } from 'components/Glossary/GlossaryData';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Pill } from 'components/design-system/Pill/Pill';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import {
  Asset,
  AssetSearchSortableField,
  SearchAssetsQuery,
  Tag,
  WrapperType,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import { useInfiniteSearchAssetsQuery } from 'hooks/useInfiniteSearchAssetsQuery';
import { Source } from 'pages/FundDetails/fundDetailsTypes';
import { useMemo } from 'react';
import { FaVideo } from 'react-icons/fa';
import { ActionOptions, FundGridCard } from '../FundGridCard';
import { FundGridCardHorizontal } from '../FundGridCard/FundGridCardHorizontal';
import {
  Container,
  DividerTagsWrapper,
  DividerTextWrapper,
  MatchesCount,
  NoMatchCopy,
  SecondaryGridHeader,
  ViewMoreContainer,
} from '../FundList/FundsList.style';
import { ViewCount } from '../ViewCount';
import { deriveFundsData } from '../helpers/deriveFundsData';
import { tagIsInvestmentGoal } from '../helpers/exemplarTagsHelpers';
import type { FundsGridCardOptions } from '../hooks/useFundListOptions';
import {
  FundDividerContent,
  FundsGridCardContainer,
  FundsGridCardHorizontalContainer,
} from './FundsGridList.styles';

const tagTitle = (title: string) => {
  switch (title) {
    case 'ISNEW':
      return 'New!';
    case 'HASFULLVIDEO':
      return <FaVideo color={colors.magenta} size={'1.25rem'} />;
    default:
      return title;
  }
};

interface FundsListProps {
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  accountIsClosed?: boolean;
  actionsOptions: ActionOptions;
  options: FundsGridCardOptions;
  setOptions: (options: FundsGridCardOptions) => void;
  source?: Source;
}

export function FundsGridList({
  selectedAccountType,
  selectedAccountId,
  accountIsClosed,
  actionsOptions = 'any',
  options,
  setOptions,
  source = 'funds',
}: FundsListProps) {
  const { variables, update } = useAssetsFilter();

  const isSingleGoalSearch =
    variables?.tags?.length === 1 && tagIsInvestmentGoal(variables.tags[0]);
  const filtersApplied = !!variables.tags || !!variables.search;

  const fundsPerPage = isSingleGoalSearch ? 10 : 12;
  const searchAssetsQuery = useInfiniteSearchAssetsQuery(fundsPerPage);
  const handleButtonClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'View More',
    });
    searchAssetsQuery.fetchNextPage();
  };

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const showFilters = options.showFilters && isMdUp;

  const forceExpanded = useMemo(
    () => !isLarge && variables.sort?.field !== AssetSearchSortableField.Name,
    [isLarge, variables.sort?.field]
  );

  const searchTotal = searchAssetsQuery.data?.pages[
    searchAssetsQuery.data?.pages?.length - 1
  ]?.assets?.totalCount!;

  const searchTags = variables.tags
    ? variables.tags.map((tagCode) => {
        const tagData = getGlossaryDataForCode(tagCode.replace('+', ''));
        if (tagData) {
          return { ...tagData, isImportant: tagCode.startsWith('+') };
        }
        return {
          title: tagCode.replace('+', ''),
          code: tagCode,
          content: null,
          isImportant: tagCode.startsWith('+'),
        };
      })
    : [];

  return (
    <Container>
      <QueryState {...searchAssetsQuery}>
        {({ data }) => {
          const viewing =
            data?.pages?.reduce(
              (total: number, group: SearchAssetsQuery) =>
                total + group?.assets?.edges?.length!,
              0
            ) ?? 0;

          const funds = deriveFundsData({ searchAssets: data!, searchTags });

          const fallbackExactMatchesCount =
            funds?.reduce((total, fund) => {
              const missingTags = fund?.missingTags;
              return missingTags && missingTags.length === 0
                ? total + 1
                : total;
            }, 0) || 0;

          const exactMatchesCount =
            data?.pages?.[0].assets?.exactMatchCount ??
            fallbackExactMatchesCount;

          const similarMatchesCount = searchTotal - exactMatchesCount;
          const showSimilarMatches = viewing >= exactMatchesCount;

          return (
            <>
              {filtersApplied && !!exactMatchesCount && (
                <FundDividerContent>
                  <DividerTextWrapper>
                    <Text $noMargin $fontWeight={FontWeight.normal}>
                      <MatchesCount>{exactMatchesCount}</MatchesCount> most
                      relevant funds {isLarge ? 'for ' : ''}
                      {variables.search && `"${variables.search}"`}
                    </Text>

                    {isLarge && (
                      <DividerTagsWrapper>
                        {searchTags.map((tag) => (
                          <Pill
                            key={tag.code}
                            $color="purple"
                            $fontSize={FontSize.small}
                            onClick={() => {
                              update({
                                ...variables,
                                tags: variables.tags?.filter(
                                  (t) => !t.includes(tag.code)
                                ),
                              });
                            }}
                            icon="X"
                          >
                            {tagTitle(tag.title)}
                          </Pill>
                        ))}
                      </DividerTagsWrapper>
                    )}
                  </DividerTextWrapper>
                </FundDividerContent>
              )}
              {!!exactMatchesCount && (
                <>
                  <FundsGridCardContainer $showFilters={showFilters}>
                    {funds?.slice(0, exactMatchesCount)?.map((fund, index) => {
                      if (fund === undefined) {
                        return null;
                      }
                      const { node, missingTags, missingFrontTags } = fund;
                      return (
                        <FundGridCard
                          source={source}
                          key={node.id}
                          actionsOptions={actionsOptions}
                          asset={node as Asset}
                          forceExpanded={forceExpanded}
                          index={index}
                          selectedAccountType={selectedAccountType}
                          selectedAccountId={selectedAccountId}
                          missingTags={missingTags}
                          missingFrontTags={missingFrontTags}
                          searchTags={searchTags}
                          searchTerm={variables.search}
                          options={options}
                          setOptions={setOptions}
                          missingTagsOnFrontCard={true}
                          removeTag={(tagToRemove: Tag) => {
                            update({
                              ...variables,
                              tags: variables.tags?.filter(
                                (tag) => !tag.includes(tagToRemove.code)
                              ),
                            });
                          }}
                          addTag={(
                            tagToAdd: { code: string },
                            important: boolean
                          ) => {
                            if (important) {
                              const newLocal = {
                                ...variables,
                                tags: (variables.tags || []).map((tag) => {
                                  if (tag === tagToAdd.code) {
                                    return `+${tagToAdd.code}`;
                                  }
                                  return tag;
                                }),
                              };
                              update(newLocal);
                              return;
                            }
                            update({
                              ...variables,
                              tags: [...(variables.tags || []), tagToAdd.code],
                            });
                          }}
                        />
                      );
                    })}
                  </FundsGridCardContainer>
                </>
              )}

              {similarMatchesCount > 0 &&
                showSimilarMatches &&
                (!!exactMatchesCount ? (
                  <SecondaryGridHeader>
                    <FundDividerContent>
                      <Text $noMargin $fontWeight={FontWeight.normal}>
                        <MatchesCount>{similarMatchesCount}</MatchesCount> funds
                        with a partial match, sorted by relevance
                      </Text>
                    </FundDividerContent>
                  </SecondaryGridHeader>
                ) : (
                  <FundDividerContent>
                    <Text $noMargin $fontWeight={FontWeight.normal}>
                      No exact matches found. Displaying{' '}
                      <MatchesCount>{similarMatchesCount}</MatchesCount> partial
                      matches, sorted by relevance
                    </Text>
                  </FundDividerContent>
                ))}

              <FundsGridCardHorizontalContainer>
                {funds?.slice(exactMatchesCount)?.map((fund, index) => {
                  if (fund === undefined) {
                    return null;
                  }
                  const { node, missingTags } = fund;
                  return (
                    <FundGridCardHorizontal
                      source={source}
                      key={node.id}
                      actionsOptions={actionsOptions}
                      asset={node as Asset}
                      forceExpanded={forceExpanded}
                      index={index}
                      selectedAccountType={selectedAccountType}
                      selectedAccountId={selectedAccountId}
                      missingTags={missingTags}
                      searchTags={searchTags}
                      searchTerm={variables.search}
                      options={options}
                      setOptions={setOptions}
                      removeTag={(tagToRemove: Tag) => {
                        update({
                          ...variables,
                          tags: variables.tags?.filter(
                            (tag) => !tag.includes(tagToRemove.code)
                          ),
                        });
                      }}
                      addTag={(
                        tagToAdd: { code: string },
                        important: boolean
                      ) => {
                        if (important) {
                          const newLocal = {
                            ...variables,
                            tags: (variables.tags || []).map((tag) => {
                              if (tag === tagToAdd.code) {
                                return `+${tagToAdd.code}`;
                              }
                              return tag;
                            }),
                          };
                          update(newLocal);
                          return;
                        }
                        update({
                          ...variables,
                          tags: [...(variables.tags || []), tagToAdd.code],
                        });
                      }}
                    />
                  );
                })}
              </FundsGridCardHorizontalContainer>
              <ViewMoreContainer>
                {viewing! <= 0 && (
                  <NoMatchCopy variant="h6">
                    There are no funds that match your search criteria. Please
                    try again.
                  </NoMatchCopy>
                )}
                {filtersApplied && (
                  <ViewCount viewing={viewing!} total={searchTotal!} />
                )}
                {searchAssetsQuery.hasNextPage && (
                  <CustomButtonV2
                    $color="purple"
                    $isWide
                    disabled={
                      searchAssetsQuery.isFetching ||
                      !searchAssetsQuery.hasNextPage
                    }
                    onClick={handleButtonClick}
                  >
                    View More
                  </CustomButtonV2>
                )}
              </ViewMoreContainer>
            </>
          );
        }}
      </QueryState>
      {searchAssetsQuery.isLoading && <div style={{ height: '50vh' }}></div>}
    </Container>
  );
}
