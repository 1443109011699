import { CopyToClipboardButton } from 'components/Button/CopyToClipboardButton';
import { Card } from 'components/design-system/Card/Card';
import { H5 } from 'components/design-system/Heading/Heading';
import { StepIntroductionTypography } from 'components/design-system/StepComponents/StepComponents';
import { TextLarge } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DetailsList = styled.dl`
  width: 20rem;

  align-self: center;
  margin: 1.5rem auto;
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.5rem;
`;

export const IntroCopy = styled(StepIntroductionTypography)`
  background-color: ${colors['purple-50']};
  border-radius: 5px;
  padding: 1rem 0.5rem;

  span {
    font-weight: 500;
  }
`;

export const StandingOrderDetails = styled(Card)`
  max-width: 26rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow: visible;
`;

export const StandingOrderFrom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StandingOrderDayAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const StandingOrderDay = styled(H5)`
  border-radius: 3px;
  background-color: ${colors['magenta-25']};
  padding: 0.125rem 0.25rem;
`;

export const StandingOrderAmount = styled.div`
  margin-left: auto;
  margin-right: -0.5rem;
  p {
    display: inline-flex;
    align-items: center;
    gap: 0;
    svg {
      font-size: 2rem;
      color: ${colors['grey-300']};
    }
  }
`;

export const StandingOrderDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StandingOrderDetailRow = styled.dl`
  position: relative;
  width: 100%;
  background-color: ${colors['grey-50']};
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0;

  &:after {
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    content: '';
    height: 1px;
    background-color: ${colors['grey-600']};
  }
`;

export const StandingOrderDetailRowLabel = styled.dt`
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
  margin: 0;
  font-size: 0.875rem;
  span {
    vertical-align: middle;
  }
`;

export const StandingOrderDetailRowCopyToClipboard = styled(
  CopyToClipboardButton
)`
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
  margin-right: -0.5rem;
`;

export interface StandingOrderDetailRowValueProps {
  $onlyCopy?: boolean;
}

export const StandingOrderDetailRowValue = styled.dt<StandingOrderDetailRowValueProps>`
  margin-top: 1.125rem;
  margin-bottom: 0;
  text-align: left;
  font-weight: 500;
  font-size: 1.25rem;

  font-family: ${({ theme, $onlyCopy }) =>
    $onlyCopy ? theme.typography.bodyFontFamily : theme.typography.bodyMono};
`;

export const YourAccountDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0;

  div {
    max-width: 200px;
    flex-grow: 1;
  }
`;

export const YourAccountDetailsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ObscuredDetails = styled(TextLarge)`
  font-family: ${({ theme }) => theme.typography.bodyMono};
  margin: 0;
`;
