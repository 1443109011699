import { StyledLink } from 'components/design-system/Link';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { tillitHomepage } from 'paths';
import React from 'react';
import { Description, Heading, Wrapper } from 'theme/shared/InfoPage.styles';

export function NotFound() {
  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <Wrapper>
      <Heading>Sorry.</Heading>
      <Description>
        Looks like something has gone wrong on our end. Please use the menu to
        go to a different page, or the button below to take you back to the
        homepage.
      </Description>

      <StyledLink href={tillitHomepage}>Return to homepage</StyledLink>
    </Wrapper>
  );
}
