import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.topBar};
  width: 100%;
  padding: 1.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 1.5rem 1.875rem;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 10px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 0 30px;
  }
`;

export const LogoContainer = styled(Link)`
  margin-right: auto;
  color: ${colors.black};
`;

export const CoBrandedContainer = styled.div`
  display: flex;
  img {
    max-height: 44px;
  }
`;
