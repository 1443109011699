import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type UserReportAffirmationsReturnType = {
  affirmations: string[];
  setAffirmations: (affirmations: string[]) => void;
};

/**
 * Custom hook which will read from, and store data to localStorage under the
 * `reportAffirmations` key.
 *
 * usage:
 * ```
 * import {useReportAffirmations} from ...
 * const {affirmations, setAffirmations} = useReportAffirmations();
 * ```
 */
export const useReportAffirmations = (): UserReportAffirmationsReturnType => {
  const [affirmations, setAffirmations] = useLocalStorage<string[]>(
    'reportAffirmations',
    []
  );

  useEffect(() => {
    if (affirmations === undefined) {
      // if no affirmations found in localStorage, initialize with an empty array
      setAffirmations([]);
    }
  }, [affirmations, setAffirmations]);

  return {
    affirmations,
    setAffirmations,
  };
};
