import { TextNormal } from 'components/design-system/Text/Text';
import { IncludeAssetType } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/includeAssetHelpers';
import { WrapperType } from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  AssetBreakdownComparison,
  deriveAssetBreakdownComparison,
} from '../../helpers/deriveAssetBreakdownComparison';
import {
  GetAssetGroupString,
  getAssetClassOfAsset,
  getBondStyleOfAsset,
  getEquityStyleOfAsset,
  getRegionOfAsset,
  useCombinedBasketBreakdown,
  useGetCurrentBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  BreakdownGroupingSelect,
  BreakdownTabHeading,
  BreakdownTabInfo,
  BreakdownTabInner,
  CardTableContainer,
} from '../_shared/DrillDownTab.styles';
import { BreakdownTableComparison } from './BreakdownTableComparison';
import { EstimatedStatBoxes } from './_shared/EstimatedStatBoxes';

type includeAsset = IncludeAssetType;

type grouping = 'region' | 'assetClass' | 'equityStyle' | 'bondStyle';

export interface GroupingOption {
  label: string;
  value: grouping;
}

interface BreakdownTabProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  includeAsset: includeAsset;
  activeBreakdownComparison: AssetBreakdownComparison;
  groupings: GroupingOption[];
}

interface BreakdownNameLookUp {
  [key: string]: string;
}

const breakdownNameLookUp: BreakdownNameLookUp = {
  region: 'by region',
  assetClass: 'by asset class',
  equityStyle: 'by equity style',
  bondStyle: 'by bond style',
};

const breakdownTableNameLookUp: BreakdownNameLookUp = {
  region: 'Region',
  assetClass: 'Asset class',
  equityStyle: 'Equity style',
  bondStyle: 'Bond style',
};

interface BreakdownGroupingLookUp {
  [key: string]: GetAssetGroupString;
}

const lookUp: BreakdownGroupingLookUp = {
  region: getRegionOfAsset,
  assetClass: getAssetClassOfAsset,
  equityStyle: getEquityStyleOfAsset,
  bondStyle: getBondStyleOfAsset,
};

export const BreakdownTab = ({
  selectedAccountType,
  selectedAccountId,
  includeAsset,
  groupings,
  activeBreakdownComparison,
}: BreakdownTabProps) => {
  const [activeGrouping, setActiveGrouping] = useState<string>(
    groupings[0].value
  );

  useEffect(() => {
    setActiveGrouping(groupings[0].value);
  }, [groupings, setActiveGrouping]);

  const { breakdowns: assetBreakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    lookUp[activeGrouping],
    includeAsset
  );
  const {
    combinedBreakdown: combinedAssetBreakdown,
  } = useCombinedBasketBreakdown(
    selectedAccountId,
    selectedAccountType,
    lookUp[activeGrouping],
    includeAsset
  );

  const breakdownComparison = deriveAssetBreakdownComparison(
    assetBreakdowns,
    combinedAssetBreakdown
  );

  return (
    <div>
      <BreakdownTabHeading>
        <TextNormal $noMargin>
          {activeBreakdownComparison.text} {breakdownNameLookUp[activeGrouping]}
        </TextNormal>
        {groupings.length > 1 && (
          <div>
            {groupings.map((grouping) => (
              <BreakdownGroupingSelect
                onClick={() => {
                  setActiveGrouping(grouping.value);
                }}
                $active={activeGrouping === grouping.value}
              >
                {grouping.label}
              </BreakdownGroupingSelect>
            ))}
          </div>
        )}
      </BreakdownTabHeading>

      <BreakdownTabInner>
        <CardTableContainer>
          <BreakdownTableComparison
            name={breakdownTableNameLookUp[activeGrouping]}
            data={breakdownComparison}
          />
        </CardTableContainer>
        {/* <Card $paddingSize={'extraSmall'}>
          <SlopeChart isSmallDisplay data={breakdownComparison} />
        </Card> */}
        <BreakdownTabInfo>
          <EstimatedStatBoxes
            activeBreakdownComparison={activeBreakdownComparison}
          />
        </BreakdownTabInfo>
      </BreakdownTabInner>
    </div>
  );
};
