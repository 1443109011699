import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H3 } from 'components/design-system/Heading/Heading';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { useMode } from 'components/feature/mode/useMode';
import { currencyFull } from 'formatting';
import {
  AccountStatus,
  usePortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useEffect, useRef, useState } from 'react';
import {
  UserProfileQueryClientSummaryAccount,
  UserProfileQueryClientSummaryAccounts,
} from 'types/graphqlTypes';
import {
  AccountCardInner,
  AccountSelectorContainer,
  Divider,
} from './UnauthBasketPlacement.styles';
import { useUnauthBasketPlacement } from './useUnauthBasketPlacement';

interface UnauthBasketPlacementChooseAccountStepProps {
  basketSummary: any;
  accounts: UserProfileQueryClientSummaryAccounts;
  onDiscard: () => void;
  onSelectAccount: (account: UserProfileQueryClientSummaryAccount) => void;
}

function UnauthBasketPlacementChooseAccountStep({
  basketSummary,
  accounts,
  onDiscard,
  onSelectAccount,
}: UnauthBasketPlacementChooseAccountStepProps) {
  return (
    <StepContainer>
      <StepContent width={StepContentWidth.wide}>
        <StepTitle>You have orders in your basket</StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.extraWide}>
          <StepIntroductionTypography>
            You have orders in your basket with a total value of{' '}
            {currencyFull(basketSummary.amount)}. Which of your accounts would
            you like to add these orders to?
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>

      <AccountSelectorContainer>
        {accounts.map((account) => {
          if (!account.id) {
            return null;
          }
          return (
            <Card
              as="button"
              key={account.wrapperType}
              onClick={() => onSelectAccount(account)}
            >
              <AccountCardInner>
                <H3 $noMargin>{account?.wrapperType}</H3>

                <Divider />

                {typeof account?.valuationSummary?.uninvestedCash ===
                  'number' && (
                  <>
                    <Text
                      $noMargin
                      $fontSize={FontSize.large}
                      $fontWeight={FontWeight.medium}
                    >
                      {currencyFull(account?.valuationSummary?.uninvestedCash)}
                    </Text>
                    <Text $noMargin>Available cash</Text>
                  </>
                )}
              </AccountCardInner>
            </Card>
          );
        })}
      </AccountSelectorContainer>

      <StepActions>
        <CustomButtonV2 $color="light" onClick={onDiscard}>
          Empty basket
        </CustomButtonV2>
      </StepActions>
    </StepContainer>
  );
}

interface UnauthBasketPlacementSuccessStepProps {
  account: UserProfileQueryClientSummaryAccount;
  selectedRebalancingId: string;
  actions: 'close' | 'basketDashboard';
  onProceed: () => void;
}

export function UnauthBasketPlacementSuccessStep({
  account,
  selectedRebalancingId,
  actions,
  onProceed,
}: UnauthBasketPlacementSuccessStepProps) {
  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Basket updated</StepTitle>
      </StepContent>

      {actions === 'close' && (
        <StepActions $isHorizontal>
          <StepButton
            className="magenta"
            onClick={() => {
              onProceed();
            }}
          >
            Close
          </StepButton>
        </StepActions>
      )}

      {actions === 'basketDashboard' && (
        <StepActions $isHorizontal>
          <StepButton
            className="magenta"
            onClick={() => {
              window.location.href = generateDynamicPortfolioConstructionBasketPath(
                {
                  wrapperType: `${getPathSegmentForWrapperType(
                    account.wrapperType
                  )}`,
                  selectedRebalancingId,
                }
              );
            }}
          >
            View basket
          </StepButton>
          <StepButton
            onClick={() => {
              window.location.href = 'dashboard';
            }}
            className="richBlue"
          >
            Dashboard
          </StepButton>
        </StepActions>
      )}
    </StepContainer>
  );
}

interface CheckoutStepProps {
  onProceed: (itemsAllocated: boolean) => void;
  actions: 'close' | 'basketDashboard';
}

const steps = {
  UnauthBasketPlacementChooseAccountStep,
  UnauthBasketPlacementSuccessStep,
};
type Steps = keyof typeof steps;

export function UnauthBasketPlacement({
  onProceed,
  actions,
}: CheckoutStepProps) {
  const [, setMode] = useMode();
  const [activeStep, setActiveStep] = useState<Steps | null>(null);
  const [
    activeAccount,
    setActiveAccount,
  ] = useState<UserProfileQueryClientSummaryAccount | null>(null);

  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery({
    filter: { active: true },
  });
  const portfolioRebalancings =
    portfolioRebalancingsQuery.data?.portfolioRebalancings;

  const [selectedRebalancingId, setSelectedRebalancingId] = useState<
    string | undefined
  >();

  const userProfileQuery = useUserProfileQuery();
  const { data: userProfile } = userProfileQuery;
  const accounts = userProfile?.clientSummary?.accounts.filter(
    (acc) => acc.status === AccountStatus.Active
  );

  const {
    basketSummary,
    addUnknownBasketItemsToAccountBasket,
    handleDiscard,
  } = useUnauthBasketPlacement();

  const isFetched = userProfileQuery.isFetched;
  useEffect(() => {
    if (activeStep !== null || !isFetched || !accounts) return;

    if (accounts.length > 0) {
      setActiveStep('UnauthBasketPlacementChooseAccountStep');
      return;
    }
  }, [activeStep, isFetched, accounts]);

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {() => {
        return (
          <>
            {activeStep === 'UnauthBasketPlacementChooseAccountStep' &&
              accounts && (
                <UnauthBasketPlacementChooseAccountStep
                  basketSummary={basketSummary}
                  accounts={accounts}
                  onDiscard={() => {
                    handleDiscard();
                    onProceed(true);
                  }}
                  onSelectAccount={async (account) => {
                    setActiveAccount(account);
                    const accountPortfolioRebalancing = portfolioRebalancings?.find(
                      (portfolioRebalancing) =>
                        portfolioRebalancing.accountId === account?.id
                    );
                    const id = await addUnknownBasketItemsToAccountBasket(
                      account,
                      accountPortfolioRebalancing
                    );
                    setSelectedRebalancingId(id);
                    setActiveStep('UnauthBasketPlacementSuccessStep');
                    setMode({
                      wrapperType: account.wrapperType,
                      mode: 'buy',
                    });
                  }}
                />
              )}
            {activeStep === 'UnauthBasketPlacementSuccessStep' &&
              activeAccount &&
              selectedRebalancingId && (
                <UnauthBasketPlacementSuccessStep
                  actions={actions}
                  account={activeAccount}
                  selectedRebalancingId={selectedRebalancingId}
                  onProceed={() => {
                    onProceed(true);
                  }}
                />
              )}
          </>
        );
      }}
    </QueryState>
  );
}

export interface DepositDialogProps extends ResponsiveDialogProps {
  onClose: () => void;
  onItemsAllocated: () => void;
  actions?: 'close' | 'basketDashboard';
}

export function UnauthBasketPlacementDialog({
  onClose,
  open,
  onItemsAllocated,
  actions = 'basketDashboard',
  ...props
}: DepositDialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <ResponsiveDialog {...props} open={open} onClose={onClose} ref={dialogRef}>
      {open && (
        <UnauthBasketPlacement
          actions={actions}
          onProceed={(itemsAllocated) => {
            if (itemsAllocated) {
              onItemsAllocated();
            } else {
              onClose();
            }
          }}
        />
      )}
    </ResponsiveDialog>
  );
}
