import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

const highlighted = css`
  .highlighted {
    background: ${colors['magenta-50']};
    color: ${colors['magenta-500']};
  }
`;

const StyledSpan = styled.span`
  ${highlighted}
`;

export interface HighlightWordsProps {
  text: string;
  wordsToHighlight: string[];
}

/**
 * Highlight specific words in the given text by wrapping them in a <span> with a "highlighted" class.
 *
 * @param {string} text - The text in which to highlight the words.
 * @param {string[]} wordsToHighlight - The array of words to highlight in the text.
 * @return {JSX.Element} The highlighted text wrapped in a JSX <span> element with dangerouslySetInnerHTML.
 */
export function HighlightWords({
  text,
  wordsToHighlight,
}: HighlightWordsProps) {
  let highlightedText = text;

  // Check if any array of words has been passed if not return the original text
  if (!wordsToHighlight.length) {
    return <span>{highlightedText}</span>;
  }

  // Check if any word to highlight is found in the text
  const foundWords = wordsToHighlight.filter((word) =>
    highlightedText.toLocaleLowerCase().includes(word.toLocaleLowerCase())
  );

  foundWords.forEach((word) => {
    const trimmedWord = word.trim();
    if (trimmedWord.length === 0) return;

    const regex = new RegExp(`\\b${trimmedWord}\\b`, 'ig');
    highlightedText = highlightedText.replace(
      regex,
      (match) => `<span class="highlighted">${match}</span>`
    );
  });

  return (
    <StyledSpan
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    ></StyledSpan>
  );
}
