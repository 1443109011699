import { colors } from 'constants/colors';
import styled from 'styled-components';

interface CardContainerProps {
  $showFilters: boolean;
}

export const FundsGridCardContainer = styled.div<CardContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  background-color: ${colors.white};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: ${({ $showFilters }) =>
      $showFilters ? '1fr 1fr' : '1fr 1fr 1fr '};
    gap: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: ${({ $showFilters }) =>
      $showFilters ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    gap: 1.5rem;
    margin: 0;
  }
`;

export const FundsGridCardHorizontalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  background-color: ${colors.white};
`;

export const FundDividerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid ${colors['grey-200']};
  padding-bottom: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
