import { ErrorMessage } from '@hookform/error-message';
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

const CustomFormControlLabel = styled(FormControlLabel)`
  span {
    font-weight: 300;
  }
`;

const StyledHelperText = styled(FormHelperText)`
  font-size: 1rem;
`;

interface ControlledCheckboxProps {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
}

export function ControlledCheckbox({
  name,
  label,
  disabled = false,
}: ControlledCheckboxProps) {
  return (
    <Controller
      name={name}
      render={({ name, value, onChange, onBlur }) => (
        <>
          <CustomFormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                name={name}
                checked={value}
                onChange={(_, checked) => onChange(checked)}
                onBlur={onBlur}
              />
            }
            label={label}
          />
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <StyledHelperText error={true}>{message}</StyledHelperText>
            )}
          />
        </>
      )}
    />
  );
}
