import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DocumentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.875rem;
  padding: ${(p) => p.theme.spacing(0.625, 0, 2.25, 0)};
  background-color: transparent;
  border-bottom: 1px solid ${colors['grey-400']};
`;

export const Title = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 70%;
`;

export const Download = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: transparent;
  color: ${colors.richBlue};
  border-radius: 50%;
  font-size: 1.35rem;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;

  &:hover,
  &.active {
    background-color: ${colors.richBlue};
    color: ${colors.white};
  }

  ${(p) =>
    p.theme.darkUniverse &&
    `
    color: ${colors.richBlack};
    &:hover,
    &:active {
      background-color: ${colors.richBlack};
    }
  `}
`;
