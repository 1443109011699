import '@tillitinvest/video.js/dist/video-js.css';
import { CustomButton } from 'components/Button/CustomButton';
import React from 'react';
import {
  DisclaimerContainer,
  EndCtaTitle,
  StyledClosedBtn,
} from './VideoPlayer.styles';

export interface EndCtaProps {
  onClose: () => void;
  onClick: () => void;
}

export function EndCta({ onClose, onClick }: EndCtaProps) {
  const handleCloseButton = () => {
    onClose();
  };

  const handleButtonClick = () => {
    onClick();
  };

  return (
    <DisclaimerContainer>
      <StyledClosedBtn onClick={handleCloseButton} />
      <EndCtaTitle variant="h3">Want to watch the full video?</EndCtaTitle>
      <CustomButton className="magenta" onClick={handleButtonClick}>
        WATCH NOW
      </CustomButton>
    </DisclaimerContainer>
  );
}
