import { StepContainer } from 'components/design-system/StepComponents/StepComponents';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import {
  UserProfileOnboardingQuery,
  useUserProfileOnboardingQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ServerError } from '../_shared/ServerError';
import { AddressForm, stageType } from './AddressForm/AddressForm';
import { AddressInput, addressSchema } from './addressSchema';
import { useUpdateAddresses } from './useUpdateAddresses';

export function AddressesStep({ onProceed, onGoBack }: StepProps) {
  const queryClient = useQueryClient();

  const { updateAddresses, isError } = useUpdateAddresses({ onProceed });

  const userProfile = queryClient.getQueryData<UserProfileOnboardingQuery>(
    useUserProfileOnboardingQuery.getKey()
  )?.userProfile!;

  const userProfileAddresses: AddressInput[] = userProfile.addressHistory!.map(
    (addr) =>
      addressSchema.cast({
        ...addr,
        buildingNameOrNumber: addr?.buildingName,
      })
  );

  const [activeStepStage, setActiveStepStage] = useState<stageType>('search');

  const addressTitleLookup = {
    search: 'Where do you live?',
    confirm: 'Confirm your address',
    manual: 'Where do you live?',
  };

  return (
    <StepContainer>
      <AddressForm
        title={addressTitleLookup[activeStepStage]}
        key="form"
        onGoBack={onGoBack}
        onStageChange={(stage) => {
          setActiveStepStage(stage);
        }}
        onSubmit={({ data }) => {
          updateAddresses([data]);
        }}
        address={userProfileAddresses[0]}
      />
      <ServerError isVisible={isError} />
    </StepContainer>
  );
}
