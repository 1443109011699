import { Container } from 'components/Container/Container.style';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { Address, useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { Base } from './Base';
import { DoubleCol, SingleAction } from './MyDetails.style';

export interface PureAddressDetailsProps {
  currentAddress: Address | undefined;
  onclick: () => void;
}

export function PureAddressDetails({
  currentAddress,
  onclick,
}: PureAddressDetailsProps) {
  return (
    <Base title="Address details">
      <Container padding="none">
        <DoubleCol>
          <ReadOnlyInput
            id="houseNumber-read-only-input"
            label="Name or number"
            defaultValue={
              currentAddress?.buildingName ||
              currentAddress?.flatNumber ||
              'Not provided'
            }
          />

          <ReadOnlyInput
            id="postcode-read-only-input"
            label="Post code"
            defaultValue={currentAddress?.postCode || 'Not provided'}
          />
        </DoubleCol>

        <ReadOnlyInput
          id="firstLine-read-only-input"
          label="First line of address"
          defaultValue={currentAddress?.line1OrStreet || 'Not provided'}
        />

        {currentAddress?.line2 && (
          <ReadOnlyInput
            id="secondLine-read-only-input"
            label="Second line of address"
            defaultValue={currentAddress?.line2}
          />
        )}

        {currentAddress?.line3 && (
          <ReadOnlyInput
            id="thirdLine-read-only-input"
            label="Third line of address"
            defaultValue={currentAddress?.line3}
          />
        )}

        <ReadOnlyInput
          id="city-read-only-input"
          label="Town or city"
          defaultValue={currentAddress?.townOrCity || 'Not provided'}
        />

        <SingleAction>
          <CustomButtonV2 $color="secondary" onClick={() => onclick()}>
            I have moved
          </CustomButtonV2>
        </SingleAction>
      </Container>
    </Base>
  );
}

export function AddressDetails() {
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const { showNewMessages } = useIntercom();

  const handleClick = () => {
    showNewMessages(`I would like to update my address details.`);

    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      content_type: `dashboard - my details - address- user moved clicked`,
    });
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <PureAddressDetails
          currentAddress={userProfile?.addressHistory?.[0] as Address}
          onclick={handleClick}
        />
      )}
    </QueryState>
  );
}
