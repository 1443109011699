import { SubTitle } from '../../Cards.styles';

export interface ContentProps {
  subTitle?: boolean;
}

export function PendingAccountContent() {
  return (
    <>
      <SubTitle>Pending</SubTitle>
      <p>
        We're reviewing your personal details and working on getting your
        account ready. We'll be in touch shortly.
      </p>
    </>
  );
}

export function UnOpenedGiaContent({ subTitle = false }: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>What is a GIA?</SubTitle>}
      <p>
        GIA stands for General Investment Account. A GIA is a standard
        investment account. There is no cap on how much you can put into the
        account, but you have to pay Capital Gains Tax on any gains made.
      </p>
    </>
  );
}

export function UnOpenedIsaContent({ subTitle = false }: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>What is a Stocks &amp; Shares ISA?</SubTitle>}
      <p>
        A Stocks and Shares ISA is a tax-efficient investment account. There is
        no Capital Gains Tax to pay on gains made within an ISA, but there is a
        cap on how much you can put in every year.
      </p>
    </>
  );
}

export function UnOpenedSippContent({ subTitle = false }: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>What is the TILLIT Pension?</SubTitle>}
      <p>
        The TILLIT Pension is a self-invested personal pension (or SIPP)
        intended for investors who want to make their own investment decisions.
        In short, it's a DIY pension.
      </p>
    </>
  );
}

export function CannotOpenSippAge({ subTitle = false }: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>We can't offer a TILLIT Pension yet.</SubTitle>}
      <p>
        Due to tax rules, you cannot open or contribute to a Self-Invested
        Personal Pension like the TILLIT Pension if you are 75 or older.
      </p>
    </>
  );
}

export function CannotOpenSippOther({ subTitle = false }: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>We can't offer a TILLIT Pension yet.</SubTitle>}
      <p>
        Currently based on the information you have provided us we cannot offer
        you a TILLIT Pension.
      </p>
    </>
  );
}

export function ClosingAccountContent() {
  return (
    <>
      We are processing your closure request, if you have any queries about the
      process or you did not intend to close your account contact our support
      team.
    </>
  );
}
