import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import {
  ToggleColorOptions,
  ToggleIconButtons,
} from 'components/design-system/ToggleIconButtons/ToggleIconButtons';
import { BubbleBreakdown } from 'components/feature/FundDetails/Breakdown/BubbleBreakdown/BubbleBreakdown';
import { TableBreakdown } from 'components/feature/FundDetails/Breakdown/TableBreakdown/TableBreakdown';
import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { Breakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { GaEventNames } from 'constants/gaConstants';
import { sum } from 'd3';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { AssetQueryAssetInstrument } from 'types/graphqlTypes';
import { PerformanceContainer } from '../Styles/FundDetails.style';
import {
  BreakdownLayout,
  BreakdownToggleWrapper,
  ChartContainer,
} from './_shared/BreakdownSlices.styles';

interface HoldingsBubbleChartSliceProps {
  instrument: AssetQueryAssetInstrument;
}

type DisplayOptions = 'bubble' | 'table';

export function HoldingsBubbleChartSlice({
  instrument,
}: HoldingsBubbleChartSliceProps) {
  const holdingsData: Breakdown[] = instrument?.holdings?.nodes.map(
    (holding) => {
      return {
        name: holding.name,
        description: holding.description || '',
        proportion: holding.proportion || 0,
        imgSrc: holding.logoUrl || undefined,
      };
    }
  );

  const breakdown = {
    name: 'Holding',
    proportion: sum(holdingsData.map(({ proportion }) => proportion)),
    lines: holdingsData,
  };

  const breakdownHasData = !!breakdown.lines?.length;

  const allZeroPercent = breakdown.lines?.every(
    ({ proportion }) => proportion === 0
  );

  const [activeDisplay, setActiveDisplay] = useState<DisplayOptions>(
    allZeroPercent ? 'table' : 'bubble'
  );

  return (
    <PerformanceContainer>
      <H4 $textAlign={TextAlign.center}>Top 10 holdings</H4>
      {breakdownHasData ? (
        <BreakdownLayout>
          {!allZeroPercent && (
            <BreakdownToggleWrapper>
              <ToggleIconButtons<DisplayOptions>
                onClick={(value) => {
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund details - bubble chart slice - display',
                    item_id: value,
                  });
                  setActiveDisplay(value);
                }}
                $color={ToggleColorOptions.blue}
                options={[
                  { value: 'bubble', icon: 'bubble' },
                  { value: 'table', icon: 'table' },
                ]}
                value={activeDisplay}
              />
            </BreakdownToggleWrapper>
          )}

          {activeDisplay === 'bubble' && (
            <ChartContainer>
              <BubbleBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
          {activeDisplay === 'table' && (
            <ChartContainer>
              <TableBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
        </BreakdownLayout>
      ) : (
        <NoDataCopy>{breakdown.name} breakdown is not available.</NoDataCopy>
      )}
    </PerformanceContainer>
  );
}
