import { FormHelperText } from '@material-ui/core';
import { colors } from 'constants/colors';
import type { FieldError, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { TextNormal, TextSmall } from '../Text/Text';
import { Radio } from './Radio';

export type RadioModeValues = 'buy' | 'autoSaveInvest';

export interface ModeRadiosProps {
  name: string;
  error?: FieldError;
  defaultValue?: RadioModeValues;
  selectedMode: string;
  register: UseFormMethods['register'];
}

const StyledRadioGroup = styled.div`
  display: flex;
`;

export interface ModeRadioProps {
  $selected: boolean;
}

const ModeRadio = styled(Radio)<ModeRadioProps>`
  flex-grow: 1;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0.5rem;
  border: 1px solid
    ${({ $selected }) =>
      $selected ? colors['magenta-300'] : colors['grey-300']};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export function ModeRadios({
  name,
  register,
  defaultValue,
  selectedMode,
  error,
}: ModeRadiosProps) {
  return (
    <div data-testid={`${name}-radio-group`}>
      <StyledRadioGroup>
        <ModeRadio
          label={
            <>
              <TextNormal $noMargin>One-off order</TextNormal>
              <TextSmall $noMargin>Make a one-off order today</TextSmall>
            </>
          }
          name={name}
          value="buy"
          ref={register}
          defaultChecked={defaultValue === 'buy'}
          $selected={selectedMode === 'buy'}
        />
        <ModeRadio
          label={
            <>
              <TextNormal $noMargin>Recurring order</TextNormal>
              <TextSmall $noMargin>Setup a monthly regular order</TextSmall>
            </>
          }
          name={name}
          value="autoSaveInvest"
          ref={register}
          defaultChecked={defaultValue === 'autoSaveInvest'}
          $selected={selectedMode === 'autoSaveInvest'}
        />
      </StyledRadioGroup>

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  );
}
