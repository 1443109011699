// todo fix types.
// Dashboard orders pass through a mapping of Rebalancings, where as the original
// Checkout flow passes a single Rebalancing

import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { OrderType } from 'components/SelectOrderType/SelectOrderType';
import {
  ButtonWrapper,
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import {
  Pagination,
  PaginationProps,
} from 'components/design-system/Pagination/Pagination';
import {
  TextAlign,
  TextNormal,
  TextSmall,
  TextWrapper,
  TextXS,
} from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import * as formatting from 'formatting';
import {
  Account,
  PortfolioRebalancingStatus,
  RebalancingTransactionStatus,
  WrapperType,
  usePagedPortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import _ from 'lodash';
import {
  dynamicPortfolioConstructionBasePath,
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { useEffect, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { PartialDeep } from 'type-fest';
import { AnyRebalancing } from 'types/graphqlTypes';
import { ErrorMessage } from '../PortfolioBuilder/PortfolioBuilder.styles';
import { OrderStatusTable } from '../PortfolioBuilder/_shared/OrderStatusTable/OrderStatusTable';
import { useEditWaitingOrder } from '../PortfolioBuilder/_shared/useEditWaitingOrder';
import { FundsBasketProvider } from '../PortfolioBuilder/hooks/useFundsBasket';
import {
  AccountPill,
  Action,
  ActionsWrapper,
  Close,
  CtaWrapper,
  DetailsContainer,
  DynamicPortfolioConstructionDetailLayout,
  DynamicPortfolioConstructionDetailTableContainer,
  DynamicPortfolioConstructionListWrapper,
  Expand,
  Heading,
  Info,
  LargeCard,
  MobileInfo,
  MobileOrderStatus,
  OrderStatus,
  SmallCard,
  SupportAction,
} from './OrdersList.styles';
import { RecurringOrderIcon } from './RecurringOrderIcon';

const ORDERS_PER_PAGE = 10;

interface DynamicPortfolioConstructionDetailsProps {
  portfolioRebalancing: AnyRebalancing;
  selectedAccount?: PartialDeep<Account>;
  onCancelOrder: () => void;
}

const SummaryLine = ({
  portfolioRebalancing,
}: {
  portfolioRebalancing: AnyRebalancing;
}) => {
  const handleRecurringOrderToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'recurring order popper',
    });
  };

  const { showNewMessages } = useIntercom();
  const combinedOrders = [
    ...(portfolioRebalancing.buyOrders.map((buyOrder) => ({
      assetName: buyOrder?.instrument?.asset?.name,
      amount: buyOrder.amount,
      id: buyOrder.id,
    })) || []),
    ...(portfolioRebalancing.sellOrders.map((sellOrder) => ({
      assetName: sellOrder?.instrument?.asset?.name,
      amount: sellOrder.enteredAmount,
      id: sellOrder.id,
    })) || []),
  ];
  const tradedInstrumentsByInterestingness = _.sortBy(
    combinedOrders,
    (order) => Math.abs(order.amount),
    (order) => order.assetName,
    (order) => order?.id
  );

  const referenceAssetName = tradedInstrumentsByInterestingness[0].assetName;

  let summaryLine = '';
  if (referenceAssetName) {
    summaryLine = referenceAssetName;

    if (tradedInstrumentsByInterestingness.length > 1) {
      summaryLine += ` + ${tradedInstrumentsByInterestingness.length - 1} more`;
    }
  }

  const handleSupportLink = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      reason: 'recurring orders - orders list - update auto invest',
    });
    showNewMessages("I'd like to edit/remove my regular order");
  };

  return (
    <Heading>
      <span>{formatting.date(portfolioRebalancing.createdUtc)}</span>
      <br />
      {summaryLine.length ? `${summaryLine}` : ''}
      {portfolioRebalancing.isRecurringOrder && (
        <>
          <RecurringOrderIcon
            onClick={handleRecurringOrderToggle}
            content={
              <TextWrapper $noMargin>
                <H6>Regular order</H6>
                <TextNormal>
                  This is a regular order tied to your regular deposit.
                </TextNormal>
                <TextNormal>
                  If you wish to change your investment instruction, please
                  speak to our friendly
                  <SupportAction onClick={handleSupportLink}>
                    Customer Support Team
                  </SupportAction>
                  .
                </TextNormal>
              </TextWrapper>
            }
          />
        </>
      )}
    </Heading>
  );
};

const DynamicPortfolioConstructionDetails = ({
  portfolioRebalancing,
}: DynamicPortfolioConstructionDetailsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userProfileQuery = useUserProfileQuery();

  const [isExpanded, setIsExpanded] = useState(false);

  const selectedAccount = userProfileQuery.data?.clientSummary?.accounts.find(
    ({ id }) => id === portfolioRebalancing.accountId
  );

  const buyOrderTotal =
    portfolioRebalancing?.buyOrders
      .map((buyOrder) => {
        const amount =
          (buyOrder?.status === RebalancingTransactionStatus.Completed
            ? buyOrder?.executedAmount
            : buyOrder?.amount!) ?? 0;
        return amount;
      })
      .reduce((runningTotal, amount) => {
        return runningTotal + amount;
      }, 0) ?? 0;

  const sellOrderTotal = portfolioRebalancing?.sellOrders
    .map((sellOrder) => {
      const amount =
        (sellOrder?.status === RebalancingTransactionStatus.Completed
          ? sellOrder?.executedAmount
          : sellOrder?.enteredAmount!) ?? 0;
      return amount;
    })
    .reduce((runningTotal, amount) => {
      return runningTotal + amount;
    }, 0);

  // Commented out for now as there is a bug with the cancel mutation endpoint
  // const { openToast } = useToast();
  // const {
  //   mutateAsync,
  //   isLoading: cancelIsLoading,
  //   isError: cancelIsError,
  // } = useCancelPortfolioRebalancingMutation();

  // const handleCancelOrder = async () => {
  //   try {
  //     await mutateAsync({ id: portfolioRebalancing.id! });
  //     openToast('Your order has been cancelled');
  //     onCancelOrder();
  //   } catch {
  //     // error handled by state
  //   }
  // };

  const editWaitingOrder = useEditWaitingOrder(
    selectedAccount?.id!,
    selectedAccount?.wrapperType!,
    portfolioRebalancing
  );
  const {
    mutateAsync: editOrder,
    isLoading: editWaitingOrderIsLoading,
    isError: editWaitingOrderIsError,
  } = editWaitingOrder;

  const handleEditOrder = () => {
    if (editOrder === null) {
      return;
    }
    editOrder();
  };

  let rebalancingStatusDisplay: string;
  switch (portfolioRebalancing.status) {
    case PortfolioRebalancingStatus.Funding:
    case PortfolioRebalancingStatus.Investing:
      rebalancingStatusDisplay = 'IN PROGRESS';
      break;
    default:
      rebalancingStatusDisplay =
        portfolioRebalancing.status ?? PortfolioRebalancingStatus.Unknown;
  }

  return (
    <Card>
      <QueryState {...userProfileQuery}>
        {() =>
          selectedAccount && (
            <>
              <DynamicPortfolioConstructionDetailLayout
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <AccountPill wrapperType={selectedAccount.wrapperType}>
                  {getShortNameForWrapperType(selectedAccount.wrapperType)}
                </AccountPill>
                <DetailsContainer>
                  <SummaryLine portfolioRebalancing={portfolioRebalancing} />
                  {!isMobile && (
                    <Info>
                      {portfolioRebalancing.completedUtc && (
                        <TextSmall $noMargin>
                          Completed:{' '}
                          <strong>
                            {formatting.date(portfolioRebalancing.completedUtc)}
                          </strong>
                        </TextSmall>
                      )}
                      <TextSmall $noMargin>
                        Sell orders:{' '}
                        <strong>
                          {portfolioRebalancing?.sellOrders?.length} (
                          {formatting.currencyFull(sellOrderTotal)})
                        </strong>
                      </TextSmall>
                      <TextSmall $noMargin>
                        Buy orders:{' '}
                        <strong>
                          {portfolioRebalancing?.buyOrders?.length} (
                          {formatting.currencyFull(buyOrderTotal)})
                        </strong>
                      </TextSmall>
                    </Info>
                  )}
                </DetailsContainer>

                {isMobile && (
                  <MobileInfo>
                    {portfolioRebalancing.completedUtc && (
                      <LargeCard>
                        <TextXS $noMargin>Completed: </TextXS>
                        <TextSmall $noMargin>
                          <strong>
                            {formatting.date(portfolioRebalancing.completedUtc)}
                          </strong>
                        </TextSmall>
                      </LargeCard>
                    )}
                    <SmallCard>
                      <TextXS $noMargin>Sell orders:</TextXS>
                      <TextSmall $noMargin>
                        <strong>
                          {portfolioRebalancing?.sellOrders?.length} (
                          {formatting.currencyFull(sellOrderTotal)})
                        </strong>
                      </TextSmall>
                    </SmallCard>

                    <SmallCard>
                      <TextXS $noMargin>Buy orders:</TextXS>
                      <TextSmall $noMargin>
                        <strong>
                          {portfolioRebalancing?.buyOrders?.length} (
                          {formatting.currencyFull(buyOrderTotal)})
                        </strong>
                      </TextSmall>
                    </SmallCard>
                  </MobileInfo>
                )}
                {isMobile && (
                  <MobileOrderStatus $noMargin>
                    {rebalancingStatusDisplay}
                  </MobileOrderStatus>
                )}
                {!isMobile && (
                  <OrderStatus $noMargin>
                    {rebalancingStatusDisplay}
                  </OrderStatus>
                )}

                <Action>
                  {isExpanded ? (
                    <Close
                      title="Show details"
                      onClick={() => {
                        setIsExpanded(false);
                      }}
                    />
                  ) : (
                    <Expand
                      title="Show details"
                      onClick={() => {
                        setIsExpanded(true);
                      }}
                    />
                  )}
                </Action>
              </DynamicPortfolioConstructionDetailLayout>
              {isExpanded && (
                <DynamicPortfolioConstructionDetailTableContainer>
                  <OrderStatusTable
                    portfolioRebalancing={portfolioRebalancing}
                    selectedAccount={selectedAccount!}
                    showComplectedText={false}
                  />
                  <ActionsWrapper>
                    {portfolioRebalancing.status ===
                      PortfolioRebalancingStatus.Waiting && (
                      <CustomButtonV2
                        $color="light"
                        $noHover
                        onClick={() => handleEditOrder()}
                        disabled={editWaitingOrderIsLoading}
                      >
                        Edit order
                      </CustomButtonV2>
                    )}
                    {/* 
                    Commented out for now as there is a bug with the cancel mutation endpoint
                    {[
                      PortfolioRebalancingStatus.Waiting,
                      PortfolioRebalancingStatus.Funding
                    ].includes(portfolioRebalancing.status!) && (
                      <CustomButtonV2
                        $color="light"
                        $noHover
                        onClick={() => handleCancelOrder()}
                        disabled={cancelIsLoading || cancelIsError}
                      >
                        Cancel order
                      </CustomButtonV2>
                    )} */}
                  </ActionsWrapper>
                  {editWaitingOrderIsError && (
                    <ErrorMessage $textAlign={TextAlign.right} $noMargin>
                      Something went wrong
                    </ErrorMessage>
                  )}
                  {[
                    PortfolioRebalancingStatus.Draft,
                    PortfolioRebalancingStatus.Waiting,
                  ].includes(portfolioRebalancing.status!) && (
                    <ButtonWrapper $align="center">
                      {portfolioRebalancing.status ===
                        PortfolioRebalancingStatus.Draft && (
                        <CustomButtonV2
                          $size="normal"
                          href={generateDynamicPortfolioConstructionBasketPath({
                            wrapperType: getPathSegmentForWrapperType(
                              selectedAccount?.wrapperType
                            ),
                          })}
                        >
                          Edit basket
                        </CustomButtonV2>
                      )}
                      {portfolioRebalancing.status ===
                        PortfolioRebalancingStatus.Waiting && (
                        <LinkCustomButton
                          $size="normal"
                          to={() =>
                            generateCheckoutPath({
                              wrapperType: getPathSegmentForWrapperType(
                                selectedAccount?.wrapperType
                              )!,
                              selectedRebalancingId: portfolioRebalancing.id!,
                            })
                          }
                        >
                          Review order
                        </LinkCustomButton>
                      )}
                    </ButtonWrapper>
                  )}
                </DynamicPortfolioConstructionDetailTableContainer>
              )}
            </>
          )
        }
      </QueryState>
    </Card>
  );
};

interface DynamicPortfolioConstructionListPagedProps
  extends DynamicPortfolioConstructionListProps {
  selectedOrderType: OrderType;
}

interface DynamicPortfolioConstructionListProps {
  selectedAccount?: PartialDeep<Account>;
  selectedOrderType?: OrderType;
  includeCompleteOrders?: boolean;
  activeWrapperType: WrapperType | null;
}

export function DynamicPortfolioConstructionListPaged({
  selectedAccount,
  includeCompleteOrders = false,
  activeWrapperType,
  selectedOrderType,
}: DynamicPortfolioConstructionListPagedProps) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [selectedAccount?.id, includeCompleteOrders]);

  const recurringOrderFilter =
    selectedOrderType === 'all'
      ? undefined
      : selectedOrderType === 'recurring'
      ? true
      : false;

  const portfolioRebalancingsQuery = usePagedPortfolioRebalancingsQuery({
    filter: {
      accountId: selectedAccount?.id,
      active: includeCompleteOrders ? undefined : true,
      recurringOrder: recurringOrderFilter,
    },
    paging: {
      pageNumber: page,
      pageSize: ORDERS_PER_PAGE,
    },
  });

  const rebalancings =
    portfolioRebalancingsQuery.data?.pagedPortfolioRebalancings?.records;
  const pageCount = Math.ceil(
    (portfolioRebalancingsQuery.data?.pagedPortfolioRebalancings
      ?.totalRecords ?? 0) / ORDERS_PER_PAGE
  );

  const handlePageChange: PaginationProps['onChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const rebalancingCtaUrl = useMemo(() => {
    if (!activeWrapperType) {
      return dynamicPortfolioConstructionBasePath;
    }

    return generateDynamicPortfolioConstructionPath({
      wrapperType: getPathSegmentForWrapperType(activeWrapperType),
    });
  }, [activeWrapperType]);

  return (
    <FundsBasketProvider>
      <QueryState {...portfolioRebalancingsQuery}>
        {({ data }) => {
          const filteredRebalancings = rebalancings?.filter(
            (x) => (x.buyOrders?.length ?? 0) + (x.sellOrders?.length ?? 0) > 0
          );

          return !!filteredRebalancings?.length ? (
            <DynamicPortfolioConstructionListWrapper>
              {filteredRebalancings?.map((portfolioRebalancing) => {
                return (
                  <DynamicPortfolioConstructionDetails
                    portfolioRebalancing={portfolioRebalancing}
                    key={portfolioRebalancing.id}
                    onCancelOrder={() => {
                      portfolioRebalancingsQuery.refetch();
                    }}
                  />
                );
              })}
            </DynamicPortfolioConstructionListWrapper>
          ) : (
            <>
              <Typography align="center">No orders found.</Typography>
              {activeWrapperType && (
                <CtaWrapper>
                  <LinkCustomButton $size="normal" to={rebalancingCtaUrl}>
                    Create new
                  </LinkCustomButton>
                </CtaWrapper>
              )}
            </>
          );
        }}
      </QueryState>
      {pageCount > 1 && (
        <Pagination page={page} count={pageCount} onChange={handlePageChange} />
      )}
    </FundsBasketProvider>
  );
}
