import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { ExistingPosition } from 'components/feature/PortfolioBuilder/ImportantInformation/_shared/ImportantInformation.types';
import { WrapperType, useAccountsQuery } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { AnyAsset } from 'types/graphqlTypes';
import {
  AddSellsToBasketStep,
  OnProceedAction,
} from './steps/AddSellOrderToBasketStep';
import { PreviewStep } from './steps/PreviewStep';
import { QuickOrderReceived } from './steps/QuickOrderReceived';
import { QuickSellOrderConfirm } from './steps/QuickSellOrderConfirm';
import { SelectAccountStepSell } from './steps/SelectAccountStepSell';

export interface AddSellOrderToBasketFullFlowDialogProps
  extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  selectedAccountId: string;
  selectedAccountType: WrapperType;
  selectedPosition?: ExistingPosition;
  asset: AnyAsset;
}

export interface AddSellOrderToBasketFullFlowFormProps {
  closeDialog: () => void;
  selectedAccountId: string;
  selectedAccountType: WrapperType;
  asset: AnyAsset;
  selectedPosition?: ExistingPosition; // passed if we have a position
}

interface SelectedAccount {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
}

export function AddSellOrderToBasketFullFlowForm({
  closeDialog,
  asset,
  selectedPosition,
  selectedAccountId,
  selectedAccountType,
}: AddSellOrderToBasketFullFlowFormProps) {
  const [activeStep, setActiveStep] = useState<string | null>(null);
  const [quickOrderId, setQuickOrderId] = useState<string | undefined>();
  const [activeAccount, setActiveAccount] = useState<SelectedAccount | null>(
    null
  );

  const accountsQuery = useAccountsQuery();

  // filter to select accounts that the current asset in
  const accounts = accountsQuery.data?.accounts?.filter((acc) =>
    acc.positions.find((p) => p?.instrument?.asset?.id === asset?.id)
  );

  useEffect(() => {
    if (activeStep !== null || accountsQuery.isFetched === false) {
      return;
    }

    if (selectedAccountId && selectedAccountType && selectedPosition) {
      setActiveAccount({ selectedAccountType, selectedAccountId });
      setActiveStep('selectAmount');
    } else if (accounts && accounts?.length === 1 && accounts[0].id) {
      setActiveStep('selectAmount');
      setActiveAccount({
        selectedAccountType: accounts[0].wrapperType,
        selectedAccountId: accounts[0].id,
      });
    } else if (accounts && accounts?.length > 1) {
      setActiveStep('selectAccount');
    } else {
      setActiveStep('noAccount');
    }
  }, [
    accounts,
    activeStep,
    accountsQuery.isFetched,
    selectedAccountId,
    selectedAccountType,
    selectedPosition,
  ]);

  return (
    <>
      {activeStep === 'noAccount' && <>No Account</>}
      {activeStep === 'selectAccount' && (
        <SelectAccountStepSell
          onProceed={({ selectedAccountType, selectedAccountId }) => {
            setActiveAccount({
              selectedAccountType,
              selectedAccountId,
            });
            setActiveStep('selectAmount');
          }}
          asset={asset}
        />
      )}
      {activeStep === 'selectAmount' && activeAccount && (
        <AddSellsToBasketStep
          flow="fullFlow"
          {...activeAccount}
          asset={asset}
          selectedPosition={selectedPosition}
          onGoBack={
            !selectedAccountId
              ? () => {
                  setActiveStep('selectAccount');
                }
              : undefined
          }
          onProceed={(action, orderId) => {
            if (action === OnProceedAction.addToBasket) {
              closeDialog();
            } else if (action === OnProceedAction.quickOrder) {
              setActiveStep('quickOrderConfirm');
              setQuickOrderId(orderId);
            }
          }}
        />
      )}
      {activeStep === 'quickOrderConfirm' && activeAccount && quickOrderId && (
        <QuickSellOrderConfirm
          quickOrderId={quickOrderId}
          {...activeAccount}
          onGoBack={() => {
            setActiveStep('selectAmount');
          }}
          onProceed={() => {
            setActiveStep('quickOrderReceived');
          }}
        />
      )}
      {activeStep === 'quickOrderReceived' && quickOrderId && activeAccount && (
        <QuickOrderReceived
          type="sell"
          quickOrderId={quickOrderId}
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
      {activeStep === 'preview' && activeAccount && (
        <PreviewStep
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
    </>
  );
}

export function AddSellOrderToBasketFullFlowDialog({
  closeDialog,
  open,
  asset,
  selectedAccountId,
  selectedAccountType,
  selectedPosition,
}: AddSellOrderToBasketFullFlowDialogProps) {
  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      {open && (
        <AddSellOrderToBasketFullFlowForm
          closeDialog={closeDialog}
          asset={asset}
          selectedAccountId={selectedAccountId}
          selectedAccountType={selectedAccountType}
          selectedPosition={selectedPosition}
        />
      )}
    </ResponsiveDialog>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
