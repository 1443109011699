import * as format from 'formatting';
import { Asset } from 'generated/graphql';
import React from 'react';
import { StatCopy, StatLabel, StatValue } from './Stats.style';

interface TotalReturnStatsProps {
  totalReturnOver5Years?: Asset['totalReturnOver5Years'];
}

export function TotalReturnStats({
  totalReturnOver5Years,
}: TotalReturnStatsProps) {
  const totalReturn = {
    period: '5Yr',
    value:
      totalReturnOver5Years !== null && totalReturnOver5Years !== undefined
        ? format.percent(totalReturnOver5Years / 100)
        : 'N/A',
  };

  return (
    <StatCopy>
      <StatValue>{totalReturn.value}</StatValue>
      <br />
      <StatLabel>{totalReturn.period} return</StatLabel>
    </StatCopy>
  );
}

interface OCFStatsProps {
  ongoingChargePercent?: Asset['ongoingChargePercent'];
}

export function OCFStats({ ongoingChargePercent }: OCFStatsProps) {
  return (
    <StatCopy>
      <StatValue>
        {ongoingChargePercent !== undefined && ongoingChargePercent !== null
          ? format.percent(ongoingChargePercent / 100)
          : 'N/A'}
      </StatValue>
      <br />
      <StatLabel>Fund fee</StatLabel>
    </StatCopy>
  );
}
