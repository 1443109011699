import { DialogActions, DialogContent } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import React, { useContext } from 'react';

import { DepositVariantContext } from '../_shared/DepositVariantContext';
import { ButtonWrapper } from 'components/design-system/Button/CustomButtonV2';

export interface AcknowledgmentStepProps {
  onClose: () => void;
  onBrowseFunds: () => void;
  onDashboard: () => void;
}

export function AcknowledgmentStep({
  onClose,
  onBrowseFunds,
  onDashboard,
}: AcknowledgmentStepProps) {
  const depositVariant = useContext(DepositVariantContext);
  return (
    <>
      <StepTitle>Thank you!</StepTitle>
      <DialogContent>
        <TextNormal $textAlign={TextAlign.center}>
          Once a transfer is made, in most cases cash should arrive within a few
          minutes, but sometimes it may take longer.
        </TextNormal>
        <TextNormal $textAlign={TextAlign.center}>
          Once it clears it will show on your Dashboard.
        </TextNormal>
      </DialogContent>
      <DialogActions>
        {depositVariant?.variant === DepositFormVariant.NewAccount ? (
          <ButtonWrapper>
            <CustomButton
              fullWidth
              className="richBlue"
              onClick={() => onDashboard()}
            >
              Dashboard
            </CustomButton>
            <CustomButton
              fullWidth
              className="magenta"
              onClick={() => onBrowseFunds()}
            >
              Browse funds
            </CustomButton>
          </ButtonWrapper>
        ) : (
          <CustomButton
            className="richBlue"
            onClick={() => onClose()}
            fullWidth
          >
            Close
          </CustomButton>
        )}
      </DialogActions>
    </>
  );
}
