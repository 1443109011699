import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { CustomButton } from 'components/Button/CustomButton';
import { ResponsiveDialogProps } from 'components/ResponsiveDialog';
import { GaEventNames } from 'constants/gaConstants';
import { useFiltersQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { GlossaryAccordion } from './GlossaryAccordion';
import { GlossaryData } from './GlossaryData';
import {
  ContentContainer,
  DialogContainer,
  SearchContainer,
  SearchField,
  SearchForm,
  TitleContainer,
} from './Style/GlossaryDialog.style';

interface DefsDataItem {
  code: string;
  title: string;
}

export interface GlossaryDialogProps extends ResponsiveDialogProps {
  onChangeFilter: (
    name: string,
    value: string[],
    label: string,
    selectedOptions: string[]
  ) => void;
  hiddenCategories?: string[];
}

export function Glossary({
  onChangeFilter,
  hiddenCategories,
  ...props
}: GlossaryDialogProps) {
  const searchField = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [expandedPanels, setExpandedPanels] = useState<Array<string>>([]);

  const filtersQuery = useFiltersQuery();

  const searched = !!searchTerm?.length;

  const fieldClasses = clsx([searched && 'search']);

  const orderedData = useMemo(() => {
    if (filtersQuery.data?.tagCategories) {
      return _(GlossaryData.defs)
        .filter(({ code }) => !(hiddenCategories?.includes(code) ?? false))
        .sortBy(({ code }) => {
          if (code === 'INVGOAL') {
            return -2;
          } else if (code === 'CLASS') {
            return -1;
          }
          return _.findIndex(
            filtersQuery.data?.tagCategories?.nodes,
            (cat) => cat.code === code
          );
        })
        .value();
    } else {
      return GlossaryData.defs;
    }
  }, [filtersQuery.data?.tagCategories, hiddenCategories]);

  const data = useMemo(() => {
    if (searchTerm) {
      const term = searchTerm?.toLowerCase();

      const filteredData: Array<any> = [];

      orderedData.forEach((category) => {
        const filteredDefs = category.data.filter((d) =>
          d.title.toLowerCase().includes(term)
        );

        if (
          filteredDefs.length ||
          category.title.toLowerCase().includes(term)
        ) {
          filteredData.push({
            ...category,
            data: filteredDefs || category.data,
          });
        }
      });

      trackGa({
        event: GaEventNames.viewSearchResults,
        search_term: term,
        searchEngine: 'glossary search',
        numResults: filteredData.length,
      });

      return filteredData;
    } else {
      return orderedData;
    }
  }, [orderedData, searchTerm]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (searchField.current?.value?.length) {
      trackGa({
        event: GaEventNames.submitSearch,
        search_term: searchField.current?.value,
        search_engine: 'funds list',
      });

      setSearchTerm(searchField.current.value);
    } else {
      setSearchTerm(null);
      setExpandedPanels(['']);
    }
  };

  const handleClear = () => {
    if (searchField.current?.value?.length) {
      searchField.current.value = '';
    }
    setSearchTerm(null);
    setExpandedPanels(['']);
  };

  return (
    <>
      <SearchContainer>
        <SearchForm onSubmit={(e) => handleSubmit(e)}>
          <SearchField
            inputRef={searchField}
            fullWidth
            placeholder="Search"
            className={fieldClasses}
            endAdornment={
              <IconButton type="submit" color="inherit">
                <HiSearch size="19.2px" />
              </IconButton>
            }
          />
          {searched && (
            <CustomButton variant="outlined" onClick={handleClear}>
              Clear Search
            </CustomButton>
          )}
        </SearchForm>
      </SearchContainer>
      <ContentContainer>
        {data.map((defs) => {
          return (
            <GlossaryAccordion
              key={defs.title}
              title={defs.title}
              data={defs.data}
              expanded={searched || expandedPanels.includes(defs.title)}
              onChangeFilter={(tags) => {
                onChangeFilter(
                  defs.code,
                  tags,
                  defs.title,
                  defs.data
                    .filter(({ code }: DefsDataItem) => tags.includes(code))
                    .map(({ title }: DefsDataItem) => title)
                );
              }}
              onChange={(isExpanded) => {
                if (isExpanded) {
                  setExpandedPanels([...expandedPanels, defs.title]);
                } else {
                  setExpandedPanels(
                    expandedPanels.filter((p) => p !== defs.title)
                  );
                }
              }}
            />
          );
        })}
      </ContentContainer>
    </>
  );
}

export function GlossaryDialog({
  onChangeFilter,
  hiddenCategories,
  onClose,
  ...props
}: GlossaryDialogProps) {
  const handleDialogClose = () => {
    onClose?.();
  };

  return (
    <DialogContainer
      {...props}
      onClose={handleDialogClose}
      pad={false}
      aria-labelledby={GlossaryData.title}
    >
      <TitleContainer id="alert-dialog-slide-title">
        {GlossaryData.title}
      </TitleContainer>
      <Glossary
        onChangeFilter={onChangeFilter}
        hiddenCategories={hiddenCategories}
        open
      />
    </DialogContainer>
  );
}
