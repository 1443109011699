import { useMediaQuery, useTheme } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H5 } from 'components/design-system/Heading/Heading';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import {
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { FooterIcon } from 'components/feature/mode/ModeFooter.styles';
import { ResumeBasketsList } from 'components/feature/mode/ResumeFooter/ResumeBasketsList';
import { useMode } from 'components/feature/mode/useMode';
import { colors } from 'constants/colors';
import { useAuth } from 'context/AuthContext';
import { currencyFull } from 'formatting';
import {
  AccountStatus,
  PortfolioRebalancingStatus,
  WrapperType,
  usePortfolioRebalancingsSummaryQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useState } from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAutoSaveInvestState } from '../AutoSaveInvestContext';

const FooterIconLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;
`;

const Wrapper = styled.div`
  position: fixed;
  right: 1.25rem;
  bottom: 5.25rem;
  width: 15rem;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  padding: 1rem;
`;

const ResumeAccount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
`;

const ResumeOther = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 18rem;
  margin: 0 auto;
`;

interface ResumePopoverProps {
  wrapperType: WrapperType;
}

export const ResumePopover = ({ wrapperType }: ResumePopoverProps) => {
  const { getBasketsSummary } = useAutoSaveInvestState();
  const [, setMode] = useMode();
  const history = useHistory();
  const { signedIn } = useAuth();
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [mobileOpenState, setMobileOpenState] = useState(false);

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const checkoutAccount = userProfileQuery.data?.clientSummary?.accounts.find(
    (account) => {
      return account.wrapperType === wrapperType;
    }
  );

  const activeAccountsId = userProfileQuery.data?.clientSummary?.accounts
    .filter((account) => {
      return account.status === AccountStatus.Active;
    })
    .map((account) => account.id);

  const portfolioRebalancingsSummaryQuery = usePortfolioRebalancingsSummaryQuery(
    {
      filter: { active: true },
    },
    {
      enabled: signedIn,
    }
  );
  const data = portfolioRebalancingsSummaryQuery.data;
  const draftPortfolioRebalancings = data?.portfolioRebalancings.filter(
    (rebalancings) =>
      rebalancings.status === PortfolioRebalancingStatus.Draft &&
      rebalancings.buyOrders.length + rebalancings.sellOrders.length > 0
  );
  const accountRebalancing = draftPortfolioRebalancings?.find((rebalancing) => {
    return rebalancing.accountId === checkoutAccount?.id;
  });
  const accountBuyOrderTotal = accountRebalancing?.buyOrders.reduce(
    (acc, buyOrder) => {
      return acc + buyOrder.amount;
    },
    0
  );
  const accountSellOrderTotal = accountRebalancing?.sellOrders.reduce(
    (acc, sellOrder) => {
      return acc + sellOrder.enteredAmount;
    },
    0
  );
  const accountTotalOrder =
    (accountBuyOrderTotal ?? 0) - (accountSellOrderTotal ?? 0);
  const accountOrderCount =
    (accountRebalancing?.buyOrders.length ?? 0) +
    (accountRebalancing?.sellOrders.length ?? 0);

  const otherAccountRebalancings = draftPortfolioRebalancings?.filter(
    ({ accountId }) =>
      checkoutAccount?.id !== accountId && activeAccountsId?.includes(accountId)
  );

  const basketsSummary = getBasketsSummary();
  const accountBasketsSummary = basketsSummary?.find((summary) => {
    return summary.accountId === checkoutAccount?.id;
  });
  const otherBasketsSummary = basketsSummary?.filter((summary) => {
    return (
      summary.accountId !== checkoutAccount?.id &&
      activeAccountsId?.includes(summary.accountId)
    );
  });

  const hasUnfinishedBusiness =
    accountRebalancing ||
    (otherAccountRebalancings && otherAccountRebalancings?.length > 0) ||
    accountBasketsSummary ||
    (otherBasketsSummary && otherBasketsSummary?.length > 0);

  if (!hasUnfinishedBusiness) {
    return null;
  }
  if (!isMdUp && !mobileOpenState) {
    return (
      <FooterIcon
        key="icon"
        onClick={() => {
          setMobileOpenState(true);
        }}
      >
        <FooterIconLayout>
          <FaShoppingBasket size="1.5rem" />
          <div>
            <TextSmall $noMargin $isDarkBg $fontWeight={FontWeight.normal}>
              You have some
              <br />
              unfinished orders
            </TextSmall>
          </div>
        </FooterIconLayout>
      </FooterIcon>
    );
  }

  const content = (
    <div>
      {accountRebalancing && (
        <ResumeAccount>
          <TextNormal $noMargin $isDarkBg={isMdUp}>
            You have a <strong>one-off</strong> order in progress for your{' '}
            <strong>
              {getShortNameForWrapperType(checkoutAccount?.wrapperType!)}
            </strong>
          </TextNormal>
          <TextSmall $noMargin $isDarkBg={isMdUp}>
            Order total: {currencyFull(accountTotalOrder)} ({accountOrderCount})
          </TextSmall>
          <div>
            <CustomButtonV2
              $size="extraSmall"
              onClick={() => {
                setMode({
                  mode: 'buy',
                  wrapperType: checkoutAccount?.wrapperType!,
                });
                history.push(
                  generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      checkoutAccount?.wrapperType!
                    ),
                  })
                );
              }}
            >
              View
            </CustomButtonV2>
          </div>
        </ResumeAccount>
      )}

      {accountBasketsSummary && (
        <ResumeAccount>
          <TextSmall $noMargin $isDarkBg={isMdUp}>
            You have a <strong>regular order</strong> in progress for your{' '}
            <strong>
              {getShortNameForWrapperType(checkoutAccount?.wrapperType!)}
            </strong>
          </TextSmall>
          <TextSmall $noMargin $isDarkBg={isMdUp}>
            Order total: {currencyFull(accountBasketsSummary?.depositsTotal!)} (
            {accountBasketsSummary.orders})
          </TextSmall>
          <CustomButtonV2
            $size="extraSmall"
            onClick={() => {
              setMode({
                mode: 'buy',
                wrapperType: checkoutAccount?.wrapperType!,
              });
              history.push(
                generateDynamicPortfolioConstructionBasketPath({
                  wrapperType: getPathSegmentForWrapperType(
                    checkoutAccount?.wrapperType!
                  ),
                })
              );
            }}
          >
            View
          </CustomButtonV2>
        </ResumeAccount>
      )}

      <ResumeOther>
        {((otherAccountRebalancings && otherAccountRebalancings.length > 0) ||
          (otherBasketsSummary && otherBasketsSummary.length > 0)) && (
          <TextNormal
            $noMargin
            $fontWeight={FontWeight.normal}
            $isDarkBg={isMdUp}
          >
            {accountRebalancing || accountBasketsSummary
              ? 'Other unfinished orders'
              : 'You have some unfinished orders'}
          </TextNormal>
        )}

        <ResumeBasketsList
          draftPortfolioRebalancings={otherAccountRebalancings}
          basketSummaries={otherBasketsSummary}
          variant="popover"
        />
      </ResumeOther>
    </div>
  );

  if (isMdUp) {
    return (
      <Wrapper>
        <H5 $noMargin darkUniverse>
          Psst
        </H5>
        {content}
      </Wrapper>
    );
  } else {
    return (
      <CustomSwipeableDrawer
        isShort
        onClose={() => {
          setMobileOpenState(false);
        }}
        backgroundColor="purple"
      >
        <MobileContentWrapper>{content}</MobileContentWrapper>
      </CustomSwipeableDrawer>
    );
  }
};
