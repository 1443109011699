import {
  Grow,
  IconButton,
  IconButtonProps,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { colors } from 'constants/colors';
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import styled from 'styled-components';

const PopoverPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  background-color: ${colors.richBlue};

  .MuiTypography-root {
    color: ${colors.white};
  }
`;

const popoverDuration = 1000;

export interface CopyToClipboardButtonProps {
  text: string;
  size?: IconButtonProps['size'];
  className?: string;
}

export function CopyToClipboardButton({
  text,
  size = 'small',
  className,
}: CopyToClipboardButtonProps) {
  const popupState = usePopupState({ variant: 'popper', popupId: null });
  const handleEntered = useCallback(
    () => setTimeout(popupState.close, popoverDuration),
    [popupState.close]
  );
  return (
    <>
      <CopyToClipboard text={text}>
        <IconButton
          className={className}
          size={size}
          {...bindTrigger(popupState)}
        >
          <HiOutlineClipboardCopy />
        </IconButton>
      </CopyToClipboard>
      <Popper
        {...bindPopper(popupState)}
        transition
        placement={'right'}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,5',
          },
        }}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} onEntered={handleEntered} timeout={350}>
            <PopoverPaper>
              <Typography>Copied!</Typography>
            </PopoverPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
