import { Box, Typography } from '@material-ui/core';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { trackGa } from 'helpers/track';
import React from 'react';
import { HiDownload } from 'react-icons/hi';
import { ComponentContainer, Date, Download } from './OtherDocument.style';

export interface OtherDocumentsProps {
  url: string;
  title: string;
  date: string;
}

export function OtherDocuments({ url, title, date }: OtherDocumentsProps) {
  const handleClick = (url: string, title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'other document',
      content_type: `download clicked - ${title}`,
    });
    window.open(url, '_blank');
  };

  return (
    <ComponentContainer onClick={() => handleClick(url, title)}>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Download
          {...{
            component: 'a',
            target: '_blank',
            href: url,
          }}
          size="small"
        >
          <HiDownload />
        </Download>
        <Typography>{title}</Typography>
        <Date>{format.date(date)}</Date>
      </Box>
    </ComponentContainer>
  );
}
