import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { ChangePassword } from './ChangePassword';

export function ChangePasswordDialog({
  onClose,
  ...props
}: ResponsiveDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <ChangePassword onContinue={() => onClose?.()} />
    </ResponsiveDialog>
  );
}
