import { GaEventNames } from 'constants/gaConstants';
import { IntercomEventNames } from 'constants/intercomConstants';
import { useAuth } from 'context/AuthContext';
import { OnboardingStatus, useOnboardingStatusQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useDeriveRedirectPath } from 'hooks/useDeriveRedirectPath';
import { openAccountResumePath } from 'paths';
import { useHistory, useLocation } from 'react-router';
import { LinkProps, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  CustomButtonV2,
  CustomButtonV2Props,
  LinkCustomButton,
} from '../Button/CustomButtonV2';

const ResponsiveButton = (props: CustomButtonV2Props) => {
  const { ...rest } = props;

  return <CustomButtonV2 $size="header" {...rest} />;
};

const LinkResponsiveButton = (props: CustomButtonV2Props & LinkProps) => {
  const { to, ...rest } = props;

  return <LinkCustomButton $size="header" to={to} {...rest} />;
};

export function ContextualButton() {
  const redirectPath = useDeriveRedirectPath();
  const { signedIn, signIn, signUp, signOut } = useAuth();

  if (signedIn) {
    return (
      <>
        <AuthContextualButton />
        <ResponsiveButton
          $color="secondary"
          onClick={() => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'cta button',
              item_id: 'log out - header',
            });
            signOut('Main header');
          }}
        >
          Log out
        </ResponsiveButton>
      </>
    );
  }

  return (
    <>
      <ResponsiveButton
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: 'cta button',
            item_id: 'start investing - header',
            eventCallback: () => {
              signUp();
            },
            eventTimeout: 350,
          });
        }}
      >
        Start Investing
      </ResponsiveButton>
      <ResponsiveButton
        $color="secondary"
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: 'cta button',
            item_id: 'sign in - header',
            eventCallback: () => {
              signIn(redirectPath);
            },
            eventTimeout: 350,
          });
        }}
      >
        Sign in
      </ResponsiveButton>
    </>
  );
}

export function AuthContextualButton() {
  const { signedIn } = useAuth();
  const history = useHistory();
  const { trackEvent } = useIntercom();
  const onboardingStatusQuery = useOnboardingStatusQuery(undefined, {
    enabled: signedIn,
  });
  const location = useLocation();

  const status =
    onboardingStatusQuery.data?.userProfile?.onboardingDetails?.status;

  if (
    location.pathname.startsWith('/open-account') &&
    status !== OnboardingStatus.Completed
  ) {
    return (
      <ResponsiveButton
        variant="outlined"
        $color="secondary"
        onClick={() => {
          trackEvent(IntercomEventNames.exitOnboarding);
          trackGa({
            event: GaEventNames.selectContent,
            content_type: 'contextual button',
            item_id: `finish setup later button clicked`,
          });
          history.push('/');
        }}
      >
        Finish setup later
      </ResponsiveButton>
    );
  }

  switch (status) {
    case OnboardingStatus.NotStarted:
      return <OpenAccountButton />;
    case OnboardingStatus.FurtherInformationNeeded:
      return <FinishSetupButton />;
    case OnboardingStatus.SupportNeeded:
      return onboardingStatusQuery.data?.userProfile
        ?.pendingDocumentUploadRequestCount === 1 ? (
        <FinishSetupButton />
      ) : (
        <DashboardButton />
      );
    default:
      return <DashboardButton />;
  }
}

function OpenAccountButton() {
  return (
    <LinkResponsiveButton
      onClick={() => {
        trackGa({
          event: GaEventNames.selectContent,
          content_type: 'cta button',
          item_id: 'start investing - header',
        });
      }}
      to="/open-account"
    >
      Start Investing
    </LinkResponsiveButton>
  );
}

function FinishSetupButton() {
  return (
    <LinkResponsiveButton
      onClick={() => {
        trackGa({
          event: GaEventNames.selectContent,
          content_type: 'cta button',
          item_id: 'finish setup - header',
        });
      }}
      to={openAccountResumePath}
    >
      Finish setup
    </LinkResponsiveButton>
  );
}

function DashboardButton() {
  return (
    <Switch>
      <Route path="/dashboard">
        <LinkResponsiveButton
          onClick={() => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'cta button',
              item_id: 'search funds - header',
            });
          }}
          to="/funds"
        >
          Search funds
        </LinkResponsiveButton>
      </Route>
      <Route path="/">
        <LinkResponsiveButton
          onClick={() => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'cta button',
              item_id: 'my dashboard - header',
            });
          }}
          to="/dashboard"
        >
          My Dashboard
        </LinkResponsiveButton>
      </Route>
    </Switch>
  );
}
