import { css } from 'styled-components';
import GTSuperDisplayBoldItalicWoff from './GT-Super-Display-Bold-Italic.woff';
import GTSuperDisplayBoldItalicWoff2 from './GT-Super-Display-Bold-Italic.woff2';
import GTSuperDisplayBoldWoff from './GT-Super-Display-Bold.woff';
import GTSuperDisplayBoldWoff2 from './GT-Super-Display-Bold.woff2';
import GTSuperDisplayLightItalicWoff from './GT-Super-Display-Light-Italic.woff';
import GTSuperDisplayLightItalicWoff2 from './GT-Super-Display-Light-Italic.woff2';
import GTSuperDisplayLightWoff from './GT-Super-Display-Light.woff';
import GTSuperDisplayLightWoff2 from './GT-Super-Display-Light.woff2';
import GTSuperDisplayMediumItalicWoff from './GT-Super-Display-Medium-Italic.woff';
import GTSuperDisplayMediumItalicWoff2 from './GT-Super-Display-Medium-Italic.woff2';
import GTSuperDisplayMediumWoff from './GT-Super-Display-Medium.woff';
import GTSuperDisplayMediumWoff2 from './GT-Super-Display-Medium.woff2';
import GTSuperDisplayRegularItalicWoff from './GT-Super-Display-Regular-Italic.woff';
import GTSuperDisplayRegularItalicWoff2 from './GT-Super-Display-Regular-Italic.woff2';
import GTSuperDisplayRegularWoff from './GT-Super-Display-Regular.woff';
import GTSuperDisplayRegularWoff2 from './GT-Super-Display-Regular.woff2';
import GTSuperDisplaySuperItalicWoff from './GT-Super-Display-Super-Italic.woff';
import GTSuperDisplaySuperItalicWoff2 from './GT-Super-Display-Super-Italic.woff2';
import GTSuperDisplaySuperWoff from './GT-Super-Display-Super.woff';
import GTSuperDisplaySuperWoff2 from './GT-Super-Display-Super.woff2';

import IBMPlexMonoRegularWoff from './IBMPlexMono-Regular.woff';
import IBMPlexMonoRegularWoff2 from './IBMPlexMono-Regular.woff2';

import IBMPlexSansBoldCyrillicWoff from './IBMPlexSans-Bold-Cyrillic.woff';
import IBMPlexSansBoldCyrillicWoff2 from './IBMPlexSans-Bold-Cyrillic.woff2';
import IBMPlexSansBoldGreekWoff from './IBMPlexSans-Bold-Greek.woff';
import IBMPlexSansBoldGreekWoff2 from './IBMPlexSans-Bold-Greek.woff2';
import IBMPlexSansBoldLatin1Woff from './IBMPlexSans-Bold-Latin1.woff';
import IBMPlexSansBoldLatin1Woff2 from './IBMPlexSans-Bold-Latin1.woff2';
import IBMPlexSansBoldLatin2Woff from './IBMPlexSans-Bold-Latin2.woff';
import IBMPlexSansBoldLatin2Woff2 from './IBMPlexSans-Bold-Latin2.woff2';
import IBMPlexSansBoldLatin3Woff from './IBMPlexSans-Bold-Latin3.woff';
import IBMPlexSansBoldLatin3Woff2 from './IBMPlexSans-Bold-Latin3.woff2';
import IBMPlexSansBoldPiWoff from './IBMPlexSans-Bold-Pi.woff';
import IBMPlexSansBoldPiWoff2 from './IBMPlexSans-Bold-Pi.woff2';
import IBMPlexSansBoldWoff from './IBMPlexSans-Bold.woff';
import IBMPlexSansBoldWoff2 from './IBMPlexSans-Bold.woff2';
import IBMPlexSansBoldItalicCyrillicWoff from './IBMPlexSans-BoldItalic-Cyrillic.woff';
import IBMPlexSansBoldItalicCyrillicWoff2 from './IBMPlexSans-BoldItalic-Cyrillic.woff2';
import IBMPlexSansBoldItalicGreekWoff from './IBMPlexSans-BoldItalic-Greek.woff';
import IBMPlexSansBoldItalicGreekWoff2 from './IBMPlexSans-BoldItalic-Greek.woff2';
import IBMPlexSansBoldItalicLatin1Woff from './IBMPlexSans-BoldItalic-Latin1.woff';
import IBMPlexSansBoldItalicLatin1Woff2 from './IBMPlexSans-BoldItalic-Latin1.woff2';
import IBMPlexSansBoldItalicLatin2Woff from './IBMPlexSans-BoldItalic-Latin2.woff';
import IBMPlexSansBoldItalicLatin2Woff2 from './IBMPlexSans-BoldItalic-Latin2.woff2';
import IBMPlexSansBoldItalicLatin3Woff from './IBMPlexSans-BoldItalic-Latin3.woff';
import IBMPlexSansBoldItalicLatin3Woff2 from './IBMPlexSans-BoldItalic-Latin3.woff2';
import IBMPlexSansBoldItalicPiWoff from './IBMPlexSans-BoldItalic-Pi.woff';
import IBMPlexSansBoldItalicPiWoff2 from './IBMPlexSans-BoldItalic-Pi.woff2';
import IBMPlexSansBoldItalicWoff from './IBMPlexSans-BoldItalic.woff';
import IBMPlexSansBoldItalicWoff2 from './IBMPlexSans-BoldItalic.woff2';
import IBMPlexSansExtraLightCyrillicWoff from './IBMPlexSans-ExtraLight-Cyrillic.woff';
import IBMPlexSansExtraLightCyrillicWoff2 from './IBMPlexSans-ExtraLight-Cyrillic.woff2';
import IBMPlexSansExtraLightGreekWoff from './IBMPlexSans-ExtraLight-Greek.woff';
import IBMPlexSansExtraLightGreekWoff2 from './IBMPlexSans-ExtraLight-Greek.woff2';
import IBMPlexSansExtraLightLatin1Woff from './IBMPlexSans-ExtraLight-Latin1.woff';
import IBMPlexSansExtraLightLatin1Woff2 from './IBMPlexSans-ExtraLight-Latin1.woff2';
import IBMPlexSansExtraLightLatin2Woff from './IBMPlexSans-ExtraLight-Latin2.woff';
import IBMPlexSansExtraLightLatin2Woff2 from './IBMPlexSans-ExtraLight-Latin2.woff2';
import IBMPlexSansExtraLightLatin3Woff from './IBMPlexSans-ExtraLight-Latin3.woff';
import IBMPlexSansExtraLightLatin3Woff2 from './IBMPlexSans-ExtraLight-Latin3.woff2';
import IBMPlexSansExtraLightPiWoff from './IBMPlexSans-ExtraLight-Pi.woff';
import IBMPlexSansExtraLightPiWoff2 from './IBMPlexSans-ExtraLight-Pi.woff2';
import IBMPlexSansExtraLightWoff from './IBMPlexSans-ExtraLight.woff';
import IBMPlexSansExtraLightWoff2 from './IBMPlexSans-ExtraLight.woff2';
import IBMPlexSansExtraLightItalicCyrillicWoff from './IBMPlexSans-ExtraLightItalic-Cyrillic.woff';
import IBMPlexSansExtraLightItalicCyrillicWoff2 from './IBMPlexSans-ExtraLightItalic-Cyrillic.woff2';
import IBMPlexSansExtraLightItalicGreekWoff from './IBMPlexSans-ExtraLightItalic-Greek.woff';
import IBMPlexSansExtraLightItalicGreekWoff2 from './IBMPlexSans-ExtraLightItalic-Greek.woff2';
import IBMPlexSansExtraLightItalicLatin1Woff from './IBMPlexSans-ExtraLightItalic-Latin1.woff';
import IBMPlexSansExtraLightItalicLatin1Woff2 from './IBMPlexSans-ExtraLightItalic-Latin1.woff2';
import IBMPlexSansExtraLightItalicLatin2Woff from './IBMPlexSans-ExtraLightItalic-Latin2.woff';
import IBMPlexSansExtraLightItalicLatin2Woff2 from './IBMPlexSans-ExtraLightItalic-Latin2.woff2';
import IBMPlexSansExtraLightItalicLatin3Woff from './IBMPlexSans-ExtraLightItalic-Latin3.woff';
import IBMPlexSansExtraLightItalicLatin3Woff2 from './IBMPlexSans-ExtraLightItalic-Latin3.woff2';
import IBMPlexSansExtraLightItalicPiWoff from './IBMPlexSans-ExtraLightItalic-Pi.woff';
import IBMPlexSansExtraLightItalicPiWoff2 from './IBMPlexSans-ExtraLightItalic-Pi.woff2';
import IBMPlexSansExtraLightItalicWoff from './IBMPlexSans-ExtraLightItalic.woff';
import IBMPlexSansExtraLightItalicWoff2 from './IBMPlexSans-ExtraLightItalic.woff2';
import IBMPlexSansItalicCyrillicWoff from './IBMPlexSans-Italic-Cyrillic.woff';
import IBMPlexSansItalicCyrillicWoff2 from './IBMPlexSans-Italic-Cyrillic.woff2';
import IBMPlexSansItalicGreekWoff from './IBMPlexSans-Italic-Greek.woff';
import IBMPlexSansItalicGreekWoff2 from './IBMPlexSans-Italic-Greek.woff2';
import IBMPlexSansItalicLatin1Woff from './IBMPlexSans-Italic-Latin1.woff';
import IBMPlexSansItalicLatin1Woff2 from './IBMPlexSans-Italic-Latin1.woff2';
import IBMPlexSansItalicLatin2Woff from './IBMPlexSans-Italic-Latin2.woff';
import IBMPlexSansItalicLatin2Woff2 from './IBMPlexSans-Italic-Latin2.woff2';
import IBMPlexSansItalicLatin3Woff from './IBMPlexSans-Italic-Latin3.woff';
import IBMPlexSansItalicLatin3Woff2 from './IBMPlexSans-Italic-Latin3.woff2';
import IBMPlexSansItalicPiWoff from './IBMPlexSans-Italic-Pi.woff';
import IBMPlexSansItalicPiWoff2 from './IBMPlexSans-Italic-Pi.woff2';
import IBMPlexSansItalicWoff from './IBMPlexSans-Italic.woff';
import IBMPlexSansItalicWoff2 from './IBMPlexSans-Italic.woff2';
import IBMPlexSansLightCyrillicWoff from './IBMPlexSans-Light-Cyrillic.woff';
import IBMPlexSansLightCyrillicWoff2 from './IBMPlexSans-Light-Cyrillic.woff2';
import IBMPlexSansLightGreekWoff from './IBMPlexSans-Light-Greek.woff';
import IBMPlexSansLightGreekWoff2 from './IBMPlexSans-Light-Greek.woff2';
import IBMPlexSansLightLatin1Woff from './IBMPlexSans-Light-Latin1.woff';
import IBMPlexSansLightLatin1Woff2 from './IBMPlexSans-Light-Latin1.woff2';
import IBMPlexSansLightLatin2Woff from './IBMPlexSans-Light-Latin2.woff';
import IBMPlexSansLightLatin2Woff2 from './IBMPlexSans-Light-Latin2.woff2';
import IBMPlexSansLightLatin3Woff from './IBMPlexSans-Light-Latin3.woff';
import IBMPlexSansLightLatin3Woff2 from './IBMPlexSans-Light-Latin3.woff2';
import IBMPlexSansLightPiWoff from './IBMPlexSans-Light-Pi.woff';
import IBMPlexSansLightPiWoff2 from './IBMPlexSans-Light-Pi.woff2';
import IBMPlexSansLightWoff from './IBMPlexSans-Light.woff';
import IBMPlexSansLightWoff2 from './IBMPlexSans-Light.woff2';
import IBMPlexSansLightItalicCyrillicWoff from './IBMPlexSans-LightItalic-Cyrillic.woff';
import IBMPlexSansLightItalicCyrillicWoff2 from './IBMPlexSans-LightItalic-Cyrillic.woff2';
import IBMPlexSansLightItalicGreekWoff from './IBMPlexSans-LightItalic-Greek.woff';
import IBMPlexSansLightItalicGreekWoff2 from './IBMPlexSans-LightItalic-Greek.woff2';
import IBMPlexSansLightItalicLatin1Woff from './IBMPlexSans-LightItalic-Latin1.woff';
import IBMPlexSansLightItalicLatin1Woff2 from './IBMPlexSans-LightItalic-Latin1.woff2';
import IBMPlexSansLightItalicLatin2Woff from './IBMPlexSans-LightItalic-Latin2.woff';
import IBMPlexSansLightItalicLatin2Woff2 from './IBMPlexSans-LightItalic-Latin2.woff2';
import IBMPlexSansLightItalicLatin3Woff from './IBMPlexSans-LightItalic-Latin3.woff';
import IBMPlexSansLightItalicLatin3Woff2 from './IBMPlexSans-LightItalic-Latin3.woff2';
import IBMPlexSansLightItalicPiWoff from './IBMPlexSans-LightItalic-Pi.woff';
import IBMPlexSansLightItalicPiWoff2 from './IBMPlexSans-LightItalic-Pi.woff2';
import IBMPlexSansLightItalicWoff from './IBMPlexSans-LightItalic.woff';
import IBMPlexSansLightItalicWoff2 from './IBMPlexSans-LightItalic.woff2';
import IBMPlexSansMediumCyrillicWoff from './IBMPlexSans-Medium-Cyrillic.woff';
import IBMPlexSansMediumCyrillicWoff2 from './IBMPlexSans-Medium-Cyrillic.woff2';
import IBMPlexSansMediumGreekWoff from './IBMPlexSans-Medium-Greek.woff';
import IBMPlexSansMediumGreekWoff2 from './IBMPlexSans-Medium-Greek.woff2';
import IBMPlexSansMediumLatin1Woff from './IBMPlexSans-Medium-Latin1.woff';
import IBMPlexSansMediumLatin1Woff2 from './IBMPlexSans-Medium-Latin1.woff2';
import IBMPlexSansMediumLatin2Woff from './IBMPlexSans-Medium-Latin2.woff';
import IBMPlexSansMediumLatin2Woff2 from './IBMPlexSans-Medium-Latin2.woff2';
import IBMPlexSansMediumLatin3Woff from './IBMPlexSans-Medium-Latin3.woff';
import IBMPlexSansMediumLatin3Woff2 from './IBMPlexSans-Medium-Latin3.woff2';
import IBMPlexSansMediumPiWoff from './IBMPlexSans-Medium-Pi.woff';
import IBMPlexSansMediumPiWoff2 from './IBMPlexSans-Medium-Pi.woff2';
import IBMPlexSansMediumWoff from './IBMPlexSans-Medium.woff';
import IBMPlexSansMediumWoff2 from './IBMPlexSans-Medium.woff2';
import IBMPlexSansMediumItalicCyrillicWoff from './IBMPlexSans-MediumItalic-Cyrillic.woff';
import IBMPlexSansMediumItalicCyrillicWoff2 from './IBMPlexSans-MediumItalic-Cyrillic.woff2';
import IBMPlexSansMediumItalicGreekWoff from './IBMPlexSans-MediumItalic-Greek.woff';
import IBMPlexSansMediumItalicGreekWoff2 from './IBMPlexSans-MediumItalic-Greek.woff2';
import IBMPlexSansMediumItalicLatin1Woff from './IBMPlexSans-MediumItalic-Latin1.woff';
import IBMPlexSansMediumItalicLatin1Woff2 from './IBMPlexSans-MediumItalic-Latin1.woff2';
import IBMPlexSansMediumItalicLatin2Woff from './IBMPlexSans-MediumItalic-Latin2.woff';
import IBMPlexSansMediumItalicLatin2Woff2 from './IBMPlexSans-MediumItalic-Latin2.woff2';
import IBMPlexSansMediumItalicLatin3Woff from './IBMPlexSans-MediumItalic-Latin3.woff';
import IBMPlexSansMediumItalicLatin3Woff2 from './IBMPlexSans-MediumItalic-Latin3.woff2';
import IBMPlexSansMediumItalicPiWoff from './IBMPlexSans-MediumItalic-Pi.woff';
import IBMPlexSansMediumItalicPiWoff2 from './IBMPlexSans-MediumItalic-Pi.woff2';
import IBMPlexSansMediumItalicWoff from './IBMPlexSans-MediumItalic.woff';
import IBMPlexSansMediumItalicWoff2 from './IBMPlexSans-MediumItalic.woff2';
import IBMPlexSansRegularCyrillicWoff from './IBMPlexSans-Regular-Cyrillic.woff';
import IBMPlexSansRegularCyrillicWoff2 from './IBMPlexSans-Regular-Cyrillic.woff2';
import IBMPlexSansRegularGreekWoff from './IBMPlexSans-Regular-Greek.woff';
import IBMPlexSansRegularGreekWoff2 from './IBMPlexSans-Regular-Greek.woff2';
import IBMPlexSansRegularLatin1Woff from './IBMPlexSans-Regular-Latin1.woff';
import IBMPlexSansRegularLatin1Woff2 from './IBMPlexSans-Regular-Latin1.woff2';
import IBMPlexSansRegularLatin2Woff from './IBMPlexSans-Regular-Latin2.woff';
import IBMPlexSansRegularLatin2Woff2 from './IBMPlexSans-Regular-Latin2.woff2';
import IBMPlexSansRegularLatin3Woff from './IBMPlexSans-Regular-Latin3.woff';
import IBMPlexSansRegularLatin3Woff2 from './IBMPlexSans-Regular-Latin3.woff2';
import IBMPlexSansRegularPiWoff from './IBMPlexSans-Regular-Pi.woff';
import IBMPlexSansRegularPiWoff2 from './IBMPlexSans-Regular-Pi.woff2';
import IBMPlexSansRegularWoff from './IBMPlexSans-Regular.woff';
import IBMPlexSansRegularWoff2 from './IBMPlexSans-Regular.woff2';
import IBMPlexSansSemiBoldCyrillicWoff from './IBMPlexSans-SemiBold-Cyrillic.woff';
import IBMPlexSansSemiBoldCyrillicWoff2 from './IBMPlexSans-SemiBold-Cyrillic.woff2';
import IBMPlexSansSemiBoldGreekWoff from './IBMPlexSans-SemiBold-Greek.woff';
import IBMPlexSansSemiBoldGreekWoff2 from './IBMPlexSans-SemiBold-Greek.woff2';
import IBMPlexSansSemiBoldLatin1Woff from './IBMPlexSans-SemiBold-Latin1.woff';
import IBMPlexSansSemiBoldLatin1Woff2 from './IBMPlexSans-SemiBold-Latin1.woff2';
import IBMPlexSansSemiBoldLatin2Woff from './IBMPlexSans-SemiBold-Latin2.woff';
import IBMPlexSansSemiBoldLatin2Woff2 from './IBMPlexSans-SemiBold-Latin2.woff2';
import IBMPlexSansSemiBoldLatin3Woff from './IBMPlexSans-SemiBold-Latin3.woff';
import IBMPlexSansSemiBoldLatin3Woff2 from './IBMPlexSans-SemiBold-Latin3.woff2';
import IBMPlexSansSemiBoldPiWoff from './IBMPlexSans-SemiBold-Pi.woff';
import IBMPlexSansSemiBoldPiWoff2 from './IBMPlexSans-SemiBold-Pi.woff2';
import IBMPlexSansSemiBoldWoff from './IBMPlexSans-SemiBold.woff';
import IBMPlexSansSemiBoldWoff2 from './IBMPlexSans-SemiBold.woff2';
import IBMPlexSansSemiBoldItalicCyrillicWoff from './IBMPlexSans-SemiBoldItalic-Cyrillic.woff';
import IBMPlexSansSemiBoldItalicCyrillicWoff2 from './IBMPlexSans-SemiBoldItalic-Cyrillic.woff2';
import IBMPlexSansSemiBoldItalicGreekWoff from './IBMPlexSans-SemiBoldItalic-Greek.woff';
import IBMPlexSansSemiBoldItalicGreekWoff2 from './IBMPlexSans-SemiBoldItalic-Greek.woff2';
import IBMPlexSansSemiBoldItalicLatin1Woff from './IBMPlexSans-SemiBoldItalic-Latin1.woff';
import IBMPlexSansSemiBoldItalicLatin1Woff2 from './IBMPlexSans-SemiBoldItalic-Latin1.woff2';
import IBMPlexSansSemiBoldItalicLatin2Woff from './IBMPlexSans-SemiBoldItalic-Latin2.woff';
import IBMPlexSansSemiBoldItalicLatin2Woff2 from './IBMPlexSans-SemiBoldItalic-Latin2.woff2';
import IBMPlexSansSemiBoldItalicLatin3Woff from './IBMPlexSans-SemiBoldItalic-Latin3.woff';
import IBMPlexSansSemiBoldItalicLatin3Woff2 from './IBMPlexSans-SemiBoldItalic-Latin3.woff2';
import IBMPlexSansSemiBoldItalicPiWoff from './IBMPlexSans-SemiBoldItalic-Pi.woff';
import IBMPlexSansSemiBoldItalicPiWoff2 from './IBMPlexSans-SemiBoldItalic-Pi.woff2';
import IBMPlexSansSemiBoldItalicWoff from './IBMPlexSans-SemiBoldItalic.woff';
import IBMPlexSansSemiBoldItalicWoff2 from './IBMPlexSans-SemiBoldItalic.woff2';
import IBMPlexSansTextCyrillicWoff from './IBMPlexSans-Text-Cyrillic.woff';
import IBMPlexSansTextCyrillicWoff2 from './IBMPlexSans-Text-Cyrillic.woff2';
import IBMPlexSansTextGreekWoff from './IBMPlexSans-Text-Greek.woff';
import IBMPlexSansTextGreekWoff2 from './IBMPlexSans-Text-Greek.woff2';
import IBMPlexSansTextLatin1Woff from './IBMPlexSans-Text-Latin1.woff';
import IBMPlexSansTextLatin1Woff2 from './IBMPlexSans-Text-Latin1.woff2';
import IBMPlexSansTextLatin2Woff from './IBMPlexSans-Text-Latin2.woff';
import IBMPlexSansTextLatin2Woff2 from './IBMPlexSans-Text-Latin2.woff2';
import IBMPlexSansTextLatin3Woff from './IBMPlexSans-Text-Latin3.woff';
import IBMPlexSansTextLatin3Woff2 from './IBMPlexSans-Text-Latin3.woff2';
import IBMPlexSansTextPiWoff from './IBMPlexSans-Text-Pi.woff';
import IBMPlexSansTextPiWoff2 from './IBMPlexSans-Text-Pi.woff2';
import IBMPlexSansTextWoff from './IBMPlexSans-Text.woff';
import IBMPlexSansTextWoff2 from './IBMPlexSans-Text.woff2';
import IBMPlexSansTextItalicCyrillicWoff from './IBMPlexSans-TextItalic-Cyrillic.woff';
import IBMPlexSansTextItalicCyrillicWoff2 from './IBMPlexSans-TextItalic-Cyrillic.woff2';
import IBMPlexSansTextItalicGreekWoff from './IBMPlexSans-TextItalic-Greek.woff';
import IBMPlexSansTextItalicGreekWoff2 from './IBMPlexSans-TextItalic-Greek.woff2';
import IBMPlexSansTextItalicLatin1Woff from './IBMPlexSans-TextItalic-Latin1.woff';
import IBMPlexSansTextItalicLatin1Woff2 from './IBMPlexSans-TextItalic-Latin1.woff2';
import IBMPlexSansTextItalicLatin2Woff from './IBMPlexSans-TextItalic-Latin2.woff';
import IBMPlexSansTextItalicLatin2Woff2 from './IBMPlexSans-TextItalic-Latin2.woff2';
import IBMPlexSansTextItalicLatin3Woff from './IBMPlexSans-TextItalic-Latin3.woff';
import IBMPlexSansTextItalicLatin3Woff2 from './IBMPlexSans-TextItalic-Latin3.woff2';
import IBMPlexSansTextItalicPiWoff from './IBMPlexSans-TextItalic-Pi.woff';
import IBMPlexSansTextItalicPiWoff2 from './IBMPlexSans-TextItalic-Pi.woff2';
import IBMPlexSansTextItalicWoff from './IBMPlexSans-TextItalic.woff';
import IBMPlexSansTextItalicWoff2 from './IBMPlexSans-TextItalic.woff2';
import IBMPlexSansThinCyrillicWoff from './IBMPlexSans-Thin-Cyrillic.woff';
import IBMPlexSansThinCyrillicWoff2 from './IBMPlexSans-Thin-Cyrillic.woff2';
import IBMPlexSansThinGreekWoff from './IBMPlexSans-Thin-Greek.woff';
import IBMPlexSansThinGreekWoff2 from './IBMPlexSans-Thin-Greek.woff2';
import IBMPlexSansThinLatin1Woff from './IBMPlexSans-Thin-Latin1.woff';
import IBMPlexSansThinLatin1Woff2 from './IBMPlexSans-Thin-Latin1.woff2';
import IBMPlexSansThinLatin2Woff from './IBMPlexSans-Thin-Latin2.woff';
import IBMPlexSansThinLatin2Woff2 from './IBMPlexSans-Thin-Latin2.woff2';
import IBMPlexSansThinLatin3Woff from './IBMPlexSans-Thin-Latin3.woff';
import IBMPlexSansThinLatin3Woff2 from './IBMPlexSans-Thin-Latin3.woff2';
import IBMPlexSansThinPiWoff from './IBMPlexSans-Thin-Pi.woff';
import IBMPlexSansThinPiWoff2 from './IBMPlexSans-Thin-Pi.woff2';
import IBMPlexSansThinWoff from './IBMPlexSans-Thin.woff';
import IBMPlexSansThinWoff2 from './IBMPlexSans-Thin.woff2';
import IBMPlexSansThinItalicCyrillicWoff from './IBMPlexSans-ThinItalic-Cyrillic.woff';
import IBMPlexSansThinItalicCyrillicWoff2 from './IBMPlexSans-ThinItalic-Cyrillic.woff2';
import IBMPlexSansThinItalicGreekWoff from './IBMPlexSans-ThinItalic-Greek.woff';
import IBMPlexSansThinItalicGreekWoff2 from './IBMPlexSans-ThinItalic-Greek.woff2';
import IBMPlexSansThinItalicLatin1Woff from './IBMPlexSans-ThinItalic-Latin1.woff';
import IBMPlexSansThinItalicLatin1Woff2 from './IBMPlexSans-ThinItalic-Latin1.woff2';
import IBMPlexSansThinItalicLatin2Woff from './IBMPlexSans-ThinItalic-Latin2.woff';
import IBMPlexSansThinItalicLatin2Woff2 from './IBMPlexSans-ThinItalic-Latin2.woff2';
import IBMPlexSansThinItalicLatin3Woff from './IBMPlexSans-ThinItalic-Latin3.woff';
import IBMPlexSansThinItalicLatin3Woff2 from './IBMPlexSans-ThinItalic-Latin3.woff2';
import IBMPlexSansThinItalicPiWoff from './IBMPlexSans-ThinItalic-Pi.woff';
import IBMPlexSansThinItalicPiWoff2 from './IBMPlexSans-ThinItalic-Pi.woff2';
import IBMPlexSansThinItalicWoff from './IBMPlexSans-ThinItalic.woff';
import IBMPlexSansThinItalicWoff2 from './IBMPlexSans-ThinItalic.woff2';

const typography = css`
  @font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Mono'), local('IBMPlexMono'),
      url(${IBMPlexMonoRegularWoff2}) format('woff2'),
      url(${IBMPlexMonoRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldWoff2}) format('woff2'),
      url(${IBMPlexSansBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansBoldCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldPiWoff2}) format('woff2'),
      url(${IBMPlexSansBoldPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansBoldLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansBoldLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansBoldLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url(${IBMPlexSansBoldGreekWoff2}) format('woff2'),
      url(${IBMPlexSansBoldGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicWoff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
      url(${IBMPlexSansBoldItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansBoldItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightPiWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
      url(${IBMPlexSansExtraLightGreekWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local('IBM Plex Sans ExtraLight Italic'),
      local('IBMPlexSans-ExtraLightItalic'),
      url(${IBMPlexSansExtraLightItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansExtraLightItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicWoff2}) format('woff2'),
      url(${IBMPlexSansItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
      url(${IBMPlexSansItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightWoff2}) format('woff2'),
      url(${IBMPlexSansLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansLightCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightPiWoff2}) format('woff2'),
      url(${IBMPlexSansLightPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansLightLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansLightLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansLightLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
      url(${IBMPlexSansLightGreekWoff2}) format('woff2'),
      url(${IBMPlexSansLightGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicWoff2}) format('woff2'),
      url(${IBMPlexSansLightItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansLightItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansLightItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansLightItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansLightItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansLightItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
      url(${IBMPlexSansLightItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansLightItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumWoff2}) format('woff2'),
      url(${IBMPlexSansMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansMediumCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumPiWoff2}) format('woff2'),
      url(${IBMPlexSansMediumPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansMediumLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansMediumLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansMediumLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${IBMPlexSansMediumGreekWoff2}) format('woff2'),
      url(${IBMPlexSansMediumGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicWoff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
      url(${IBMPlexSansMediumItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularWoff2}) format('woff2'),
      url(${IBMPlexSansRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansRegularCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularPiWoff2}) format('woff2'),
      url(${IBMPlexSansRegularPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansRegularLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansRegularLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansRegularLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularGreekWoff2}) format('woff2'),
      url(${IBMPlexSansRegularGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldPiWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url(${IBMPlexSansSemiBoldGreekWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'),
      local('IBMPlexSans-SemiBoldItalic'),
      url(${IBMPlexSansSemiBoldItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextWoff2}) format('woff2'),
      url(${IBMPlexSansTextWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansTextCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextPiWoff2}) format('woff2'),
      url(${IBMPlexSansTextPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansTextLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansTextLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansTextLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local('IBM Plex Sans Text'), local('IBMPlexSans-Text'),
      url(${IBMPlexSansTextGreekWoff2}) format('woff2'),
      url(${IBMPlexSansTextGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicWoff2}) format('woff2'),
      url(${IBMPlexSansTextItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansTextItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansTextItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansTextItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansTextItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansTextItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local('IBM Plex Sans Text Italic'), local('IBMPlexSans-TextItalic'),
      url(${IBMPlexSansTextItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansTextItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinWoff2}) format('woff2'),
      url(${IBMPlexSansThinWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansThinCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinPiWoff2}) format('woff2'),
      url(${IBMPlexSansThinPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansThinLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansThinLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansThinLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
      url(${IBMPlexSansThinGreekWoff2}) format('woff2'),
      url(${IBMPlexSansThinGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicWoff2}) format('woff2'),
      url(${IBMPlexSansThinItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicCyrillicWoff2}) format('woff2'),
      url(${IBMPlexSansThinItalicCyrillicWoff}) format('woff');
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5,
      U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9,
      U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicPiWoff2}) format('woff2'),
      url(${IBMPlexSansThinItalicPiWoff}) format('woff');
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
      U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
      U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
      U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
      U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
      U+ECE0, U+EFCC;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicLatin3Woff2}) format('woff2'),
      url(${IBMPlexSansThinItalicLatin3Woff}) format('woff');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicLatin2Woff2}) format('woff2'),
      url(${IBMPlexSansThinItalicLatin2Woff}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
      U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicLatin1Woff2}) format('woff2'),
      url(${IBMPlexSansThinItalicLatin1Woff}) format('woff');
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
      U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
      U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
      U+2212, U+FB01-FB02;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
      url(${IBMPlexSansThinItalicGreekWoff2}) format('woff2'),
      url(${IBMPlexSansThinItalicGreekWoff}) format('woff');
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
  }

  @font-face {
    font-family: 'GT Super';
    font-style: italic;
    font-weight: 700;
    src: url(${GTSuperDisplayBoldItalicWoff2}) format('woff2'),
      url(${GTSuperDisplayBoldItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: normal;
    font-weight: 700;
    src: url(${GTSuperDisplayBoldWoff2}) format('woff2'),
      url(${GTSuperDisplayBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: italic;
    font-weight: 300;
    src: url(${GTSuperDisplayLightItalicWoff2}) format('woff2'),
      url(${GTSuperDisplayLightItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: normal;
    font-weight: 300;
    src: url(${GTSuperDisplayLightWoff2}) format('woff2'),
      url(${GTSuperDisplayLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: italic;
    font-weight: 500;
    src: url(${GTSuperDisplayMediumItalicWoff2}) format('woff2'),
      url(${GTSuperDisplayMediumItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: normal;
    font-weight: 500;
    src: url(${GTSuperDisplayMediumWoff2}) format('woff2'),
      url(${GTSuperDisplayMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: italic;
    font-weight: 400;
    src: url(${GTSuperDisplayRegularItalicWoff2}) format('woff2'),
      url(${GTSuperDisplayRegularItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: normal;
    font-weight: 400;
    src: url(${GTSuperDisplayRegularWoff2}) format('woff2'),
      url(${GTSuperDisplayRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: italic;
    font-weight: 900;
    src: url(${GTSuperDisplaySuperItalicWoff2}) format('woff2'),
      url(${GTSuperDisplaySuperItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'GT Super';
    font-style: normal;
    font-weight: 900;
    src: url(${GTSuperDisplaySuperWoff2}) format('woff2'),
      url(${GTSuperDisplaySuperWoff}) format('woff');
  }
`;

export { typography };
