import React from 'react';
import countries from '../../assets/countries.json';
import { SelectField, SelectFieldProps } from './SelectField';

const countryOptions = countries.map((c) => ({
  value: c.code,
  label: c.name,
}));

interface CountryFieldProps extends Omit<SelectFieldProps, 'options'> {}

export function CountryField(props: CountryFieldProps) {
  return <SelectField {...props} options={countryOptions} />;
}
