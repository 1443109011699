import { Box } from '@material-ui/core';
import { InfoPopover } from 'components/Popover';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { OrderDirection, OrderStatus, WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React from 'react';
import { HiDownload } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import { Content, Download, NoteContainer, Pill } from './ContractNote.style';

export interface ContractNoteProps {
  downloadURL?: string;
  instrumentName: string;
  accountType: WrapperType;
  direction: OrderDirection;
  date?: string;
  status: OrderStatus;
}

export function ContractNote({
  downloadURL,
  instrumentName,
  accountType,
  direction,
  date,
  status,
}: ContractNoteProps) {
  const handleClick = (title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `contract note - ${title}`,
    });
  };

  return (
    <NoteContainer direction={direction}>
      <Content>
        {instrumentName}
        <br />
        <span>
          {status === OrderStatus.Completed ? format.date(date!) : status}
          <br />
          {accountType}
        </span>
      </Content>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Pill direction={direction}>{direction}</Pill>
        {status === OrderStatus.Completed ? (
          <Download
            {...{
              component: 'a',
              target: '_blank',
              href: downloadURL,
            }}
            onClick={() => handleClick(instrumentName)}
          >
            <HiDownload />
          </Download>
        ) : (
          <InfoPopover
            color="inherit"
            content={tooltips.OrderPending}
            disablePadding={false}
            disableMargin
          />
        )}
      </Box>
    </NoteContainer>
  );
}
