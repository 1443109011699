import { TextFieldProps } from '@material-ui/core';
import React from 'react';
import { StyledTextField } from './Input.style';

export function ReadOnlyInput(props: TextFieldProps) {
  return (
    <StyledTextField
      {...props}
      disabled={true}
      fullWidth={true}
      margin="normal"
      InputProps={{
        readOnly: true,
      }}
    />
  );
}
