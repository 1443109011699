import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css, keyframes } from 'styled-components';
import { horHexagon } from 'theme/shared/hexa.styles';

type hexTypeProps = {
  delay: number;
};

const BaseHexa = css`
  stroke: ${colors.magenta};
  stroke-width: 2;
  fill: ${colors.white};
  transition: fill 0.4s ease-in-out;
`;

const BaseText = css`
  color: ${colors.richBlack};
  transition: color 0.2s ease-in-out;
`;

const hexAnimation = keyframes`
  0% {
    transform: scale(1) translateX(0px) translateY(0px);
  }
  50% {
    transform: scale(1.1) translateX(-5px) translateY(-5px);
  }
  0% {
    transform: scale(1) translateX(0px) translateY(0px);
  }
`;

const entranceBHex = keyframes`
  from { 
    opacity: 0;
    transform: scale(0.9) translateX(10px) translateY(10px);
    }
  to {
    opacity: 0.8;
    transform: scale(1) translateX(0px) translateY(0px);
    }
`;

const entrance = keyframes`
  from {
    opacity: 0;
    }
  to {
    opacity: 1;
    }
`;

export const Container = styled.div`
  padding: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(3.75, 2.5)};
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    padding: ${(p) => p.theme.spacing(3.75, 3.75)};
  }
`;

const inactiveStatus = css`
  transition: opacity 0.4s ease-in-out;
  &.inactive {
    opacity: 0.25 !important;
  }
`;

export const FocusAsset = styled.g<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.6s ease-in ${(p) => p.delay}s forwards;
  cursor: pointer;
  polygon,
  #hexagon {
    ${BaseHexa}
  }
  foreignObject {
    div {
      ${BaseText}
    }
  }

  &:hover,
  &.active {
    opacity: 1;
    polygon,
    #hexagon {
      fill: ${colors.richBlue};
    }
    foreignObject {
      div {
        color: ${colors.white};
      }
    }
  }
  ${inactiveStatus}
`;

export const RegionAsset = styled.g<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.6s ease-in ${(p) => p.delay}s forwards;
  cursor: pointer;
  polygon,
  #hexagon {
    ${BaseHexa}
  }
  foreignObject {
    div {
      ${BaseText}
    }
  }

  &:hover,
  &.active {
    opacity: 1;
    polygon,
    #hexagon {
      fill: ${colors.richBlue};
    }
    foreignObject {
      div {
        color: ${colors.white};
      }
    }
  }
  ${inactiveStatus}
`;

export const StructureAsset = styled.g<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.6s ease-in ${(p) => p.delay}s forwards;
  cursor: pointer;
  polygon,
  #hexagon {
    ${BaseHexa}
  }
  foreignObject {
    div {
      ${BaseText}
    }
  }

  &:hover,
  &.active {
    opacity: 1;
    polygon,
    #hexagon {
      fill: ${colors.richBlue};
    }
    foreignObject {
      div {
        color: ${colors.white};
      }
    }
  }
  ${inactiveStatus}
`;

export const ClassAsset = styled.g<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.6s ease-in ${(p) => p.delay}s forwards;
  cursor: pointer;
  polygon,
  #hexagon {
    ${BaseHexa}
  }
  foreignObject {
    div {
      ${BaseText}
    }
  }

  &:hover,
  &.active {
    opacity: 1;
    polygon,
    #hexagon {
      fill: ${colors.richBlue};
    }
    foreignObject {
      div {
        color: ${colors.white};
      }
    }
  }
  ${inactiveStatus}
`;

export const CtaHexagon = styled.g<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.6s ease-in ${(p) => p.delay}s forwards;
  cursor: pointer;
  polygon,
  #hexagon {
    ${BaseHexa}
    fill: ${colors.magenta};
    color: ${colors.white};
  }
  foreignObject {
    div {
      ${BaseText}
      color: ${colors.white}
    }
  }
  ${inactiveStatus}
`;

export const SmallHex = styled.image<hexTypeProps>`
  opacity: 0;
  animation: ${entrance} 0.4s ease-in ${(p) => p.delay + 3.6}s 1 forwards,
    ${hexAnimation} 10s ease-in-out ${(p) => p.delay + 4}s infinite;
  transition: opacity 0.4s ease-in-out;

  &.inactive {
    opacity: 0.5 !important;
  }
`;

export const BigHex = styled.image<hexTypeProps>`
  position: relative;
  opacity: 0;
  animation: ${entranceBHex} 0.4s ease-in ${(p) => p.delay + 3.6}s 1 forwards;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1 !important;
  }

  &.inactive {
    opacity: 0.5 !important;
  }
`;

export const MobileCatContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${colors.clouds};
  border: none;
`;

export const Hexagon = styled.div`
  position: relative;
  width: 100%;
  padding-top: 80%;
  cursor: pointer;
  color: ${colors.black};
  background-color: ${colors.black};
  ${horHexagon};
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;

  &.style {
    color: ${colors.seaBlue};
    background-color: ${colors.seaBlue};
  }
  &.region {
    color: ${colors.purple};
    background-color: ${colors.purple};
  }
  &.structure {
    color: ${colors.magenta};
    background-color: ${colors.magenta};
  }
  &.assetClass {
    color: ${colors.mauve};
    background-color: ${colors.mauve};
  }

  > * {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    opacity: 1;
    padding: ${(p) => p.theme.spacing(5)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${colors.white};
    ${horHexagon};
  }
`;

export const CatTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  margin: ${(p) => p.theme.spacing(2.5, 0)};
`;

const grow = keyframes`
  0% { 
    transform: scale(0.9);
  }
  50% { 
    transform: scale(1);
  }
  100% { 
    transform: scale(0.9);
  }
`;

const baseMHex = css`
  position: absolute;
  width: 30%;
  transform: scale(0.9);
  transform-origin: center;
  z-index: 1;
`;
export const MHex1 = styled.img`
  ${baseMHex}
  width: 20%;
  top: 10%;
  right: 9%;
`;
export const MHex2 = styled.img`
  ${baseMHex}
  bottom: -6%;
  left: 4%;
  animation: ${grow} 10s ease-in-out 2s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    bottom: -8%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    bottom: 12%;
    left: 6%;
  }
`;
export const MHex3 = styled.img`
  ${baseMHex}
  bottom: -6%;
  left: 4%;
  animation: ${grow} 10s ease-in-out 1s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    left: 6%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    bottom: 8%;
    left: 6%;
  }
`;
export const MHex4 = styled.img`
  ${baseMHex}
  top: -6%;
  right: 4%;
  animation: ${grow} 20s ease-in-out 3s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    top: -4%;
    right: 6%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    top: 12%;
    right: 6%;
  }
`;

export const MHex5 = styled.img`
  ${baseMHex}
  right: 4%;
  top: -6%;
  animation: ${grow} 10s ease-in-out 1s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    right: 6%;
    top: -4%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    top: 12%;
    right: 6%;
  }
`;
export const MHex6 = styled.img`
  ${baseMHex}
  width: 20%;
  bottom: 10%;
  left: 9%;
  animation: ${grow} 10s ease-in-out 2s infinite forwards;
`;

export const MHex7 = styled.img`
  ${baseMHex}
  top: -6%;
  right: 4%;
  animation: ${grow} 20s ease-in-out 3s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    top: -4%;
    right: 6%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    top: 12%;
    right: 6%;
  }
`;
export const MHex8 = styled.img`
  ${baseMHex}
  bottom: -6%;
  left: 4%;
  animation: ${grow} 10s ease-in-out 1s infinite forwards;

  ${(p) => p.theme.breakpoints.down(460)} {
    width: 25%;
    bottom: -8%;
    left: 6%;
  }

  ${(p) => p.theme.breakpoints.down(400)} {
    width: 25%;
    bottom: 8%;
    left: 6%;
  }
`;

export interface HexaCopyContainerProps {
  $isActive: boolean;
}

export const HexaCopyContainer = styled.div<HexaCopyContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(p) => p.theme.typography.headingFontFamily};
  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;
  color: ${({ $isActive }) => ($isActive ? colors.white : 'inherit')};
`;
