import { useMediaQuery } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H5, H6 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
} from 'components/design-system/Text/Text';
import { useAuth } from 'context/AuthContext';
import { openAccountPath } from 'paths';
import React from 'react';
import { useTheme } from 'styled-components';
import { Overlay, OverlayContent } from './Overlay.styles';

interface WantToSeeMoreProps {
  isFullHeight?: boolean;
}
export const WantToSeeMore = ({ isFullHeight = false }: WantToSeeMoreProps) => {
  const { signIn, signUp, signedIn } = useAuth();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Overlay>
      <OverlayContent isFullHeight={isFullHeight}>
        {isMdUp ? <H5>Want to see more?</H5> : <H6>Want to see more?</H6>}
        {signedIn ? (
          <>
            <Text
              $fontSize={isMdUp ? FontSize.normal : FontSize.small}
              $fontWeight={FontWeight.light}
              $textAlign={TextAlign.center}
            >
              Finish setting up your investment account to read more TILLIT
              Views, access exclusive video content and more.
            </Text>
            <CustomButtonV2 $color="primary" onClick={() => signUp()}>
              Finish setup
            </CustomButtonV2>
          </>
        ) : (
          <>
            <Text
              $fontSize={isMdUp ? FontSize.normal : FontSize.small}
              $fontWeight={FontWeight.light}
              $textAlign={TextAlign.center}
            >
              <StyledLink href={openAccountPath}>Open an account</StyledLink> in{' '}
              <b style={{ fontWeight: 500 }}>5 minutes</b> to see the rest.
            </Text>
            <CustomButtonV2 $color="primary" onClick={() => signUp()}>
              Open account
            </CustomButtonV2>
            <Text
              $fontSize={isMdUp ? FontSize.normal : FontSize.small}
              $fontWeight={FontWeight.light}
              $textAlign={TextAlign.center}
              $noMargin
              style={{ marginTop: '1rem' }}
            >
              Have an account?{' '}
              <StyledLink
                onClick={() => {
                  signIn(window.location.pathname);
                }}
              >
                Sign in
              </StyledLink>
              .
            </Text>
          </>
        )}
      </OverlayContent>
    </Overlay>
  );
};
