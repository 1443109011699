import { ErrorMessage } from '@hookform/error-message';
import {
  InputLabelProps,
  StandardTextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledTextField } from './Form.styles';

export interface TextFieldProps extends MuiTextFieldProps {
  name: string;
  label: string;
}

export function TextField({
  inputRef,
  name,
  helperText,
  fullWidth = true,
  ...other
}: TextFieldProps) {
  const { register, errors, watch } = useFormContext();

  const hasError = !!_.get(errors, name);

  const currentHelperText = useMemo(
    () => (hasError ? <ErrorMessage name={name} /> : helperText),
    [helperText, hasError, name]
  );

  const value = watch(name);
  const notEmpty = !!value?.length;

  const inputLabelProps = useMemo(
    () =>
      ({
        error: false,
        shrink: notEmpty || undefined,
      } as InputLabelProps),
    [notEmpty]
  );

  return (
    <StyledTextField
      name={name}
      helperText={currentHelperText}
      error={hasError}
      fullWidth={fullWidth}
      InputLabelProps={inputLabelProps}
      inputRef={inputRef || register}
      {...other}
    />
  );
}
