import { IconButtonProps } from '@material-ui/core';
import { InfoPopover } from 'components/Popover';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React from 'react';

interface ShareClassInfoPopoverProps {
  color?: IconButtonProps['color'];
  size?: IconButtonProps['size'];
  disableMargin?: boolean;
}

export function ShareClassInfoPopover({
  color = 'default',
  size = 'medium',
  disableMargin,
}: ShareClassInfoPopoverProps) {
  return (
    <InfoPopover
      color={color}
      size={size}
      disableMargin={disableMargin}
      onClick={() => {
        trackGa({
          event: GaEventNames.popover,
          content_type: 'share class selector - trading screen',
        });
      }}
      content={
        <>
          <p>
            With accumulation share classes (Acc) income generated by the fund
            (e.g. dividends or interest) will be automatically reinvested.{' '}
          </p>
          <p>
            With income share classes (Inc), income generated by the fund will
            be paid out as cash and will accrue in your investment account.{' '}
          </p>
          <p>
            Hedged share classes (Acc or Inc) aim to reduce the impact of
            foreign exchange fluctuations between the currency of the share
            class and the currency of the underlying investment. They are often
            more expensive than unhedged share classes.
          </p>
        </>
      }
    />
  );
}
