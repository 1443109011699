import { Button } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const TextButton = styled(Button)`
  background-color: none;
  border: none;
  text-decoration: underline;
  text-transform: none;
  /* font-weight: 400; */
  font-size: 1rem;
  color: ${colors.magenta};

  &:hover {
    text-decoration: underline;
  }
  & svg {
    margin: 0 0.25rem;
  }
`;
