import { QueryState } from 'components/QueryState';
import { useUnauthBasketPlacement } from 'components/feature/PortfolioBuilder/UnauthBasketPlacement/useUnauthBasketPlacement';
import {
  AccountStatus,
  AccountType,
  UserProfileQuery,
  usePortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useGetReturnPath } from 'hooks/useGetReturnPath';
import { useOnboardingStatus } from 'hooks/useOnboardingStatus';
import { useState } from 'react';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import { SetupMfaMethodForm } from '../setupMfa/SetupMfaMethodForm';
import { ConfirmReferralStep } from './steps/ConfirmReferralStep/ConfirmReferralStep';
import { LoginApiResBody } from './steps/EmailPasswordStep/EmailPasswordStep';
import { FinishOnboarding } from './steps/FinishOnboarding/FinishOnboarding';

export interface PasswordStepProps {
  onProceed: () => void;
  onGoBack: () => void;
}

enum MultistepFormSteps {
  EmailVerify = 'EmailVerify',
  FinishOnboarding = 'FinishOnboarding', // post login
  ConfirmReferralStep = 'ConfirmReferralStep', // post login
  MfaSetup = 'MfaSetup', // post login
  Finish = 'Finish', // post login
}

interface StepDef {
  step: MultistepFormSteps;
  showStep: (
    userId: string,
    loginRes: LoginApiResBody,
    accounts: NonNullable<UserProfileQuery['clientSummary']>['accounts'],
    refCode?: string | null,
    hasEmployer?: boolean
  ) => boolean;
  action?: (path: string) => void;
}

const steps: StepDef[] = [
  {
    step: MultistepFormSteps.ConfirmReferralStep,
    showStep: (userId, loginResult, accounts, refCode, hasEmployer) => {
      return !hasEmployer && typeof refCode === 'string';
    },
  },
  {
    step: MultistepFormSteps.FinishOnboarding,
    showStep: (userId, loginResult, accounts, refCode) => {
      const activeAccounts = accounts.filter(
        (acc) => acc.status === AccountStatus.Active
      );
      return activeAccounts.length === 0;
    },
  },
  {
    step: MultistepFormSteps.MfaSetup,
    showStep: (userId, loginResult, accounts, refCode) => {
      return !loginResult.requireMfa;
    },
  },
  {
    step: MultistepFormSteps.Finish,
    action: (path) => {
      window.location.href = path;
    },
    showStep: () => true,
  },
];

type showStepParams = Parameters<StepDef['showStep']>;

const getNextStep = (
  currentStep: MultistepFormSteps | undefined,
  showStepArgs: showStepParams,
  returnPath: string
) => {
  const currentStepIndex =
    currentStep === undefined
      ? -1
      : steps.findIndex((step) => step.step === currentStep);

  const nextStep = steps.slice(currentStepIndex + 1).find((step) => {
    return step.showStep(...showStepArgs);
  });

  if (nextStep?.action) {
    nextStep.action(returnPath);
  }

  return nextStep?.step;
};

interface PostSignInFormProps {
  loginResult: LoginApiResBody;
}

export function PostSignInForm({ loginResult }: PostSignInFormProps) {
  const returnPath = useGetReturnPath();

  const {
    setOnboardingAccountType,
    setOnboardingStatus,
    removeOnboardingAccountType,
  } = useOnboardingStatus();

  const [activeStep, setActiveStep] = useState<
    MultistepFormSteps | undefined
  >();
  const { referralCode, clearReferralCode } = useGetEmployerCode();

  const userProfileQuery = useUserProfileQuery(undefined, {
    onSuccess: (data) => {
      if (activeStep !== undefined) {
        return;
      }
      const accounts = data.clientSummary?.accounts!;
      const userId = data.userProfile?.id!;

      const hasEmployer = !!data.userProfile?.employments?.[0];

      const showStepArgs: showStepParams = [
        userId,
        loginResult,
        accounts,
        referralCode,
        hasEmployer,
      ];

      if (hasEmployer && referralCode) {
        clearReferralCode();
      }

      const nextStep = getNextStep(activeStep, showStepArgs, returnPath);

      if (nextStep) {
        const nextStepDef = steps.find((step) => step.step === nextStep);
        if (nextStepDef?.action) {
          nextStepDef.action(returnPath);
        }
        setActiveStep(nextStep);
      }

      const activeAccounts = accounts.filter(
        (acc) => acc.status === AccountStatus.Active
      );

      if (activeAccounts.length === 0) {
        setOnboardingAccountType(
          userProfileQuery?.data?.userProfile?.initialAccountType ||
            AccountType.NotSpecified
        );
        setOnboardingStatus(true);
      } else {
        removeOnboardingAccountType();
        setOnboardingStatus(false);
      }
    },
  });

  const activeAccounts = userProfileQuery?.data?.clientSummary?.accounts;
  const activeAccount = activeAccounts?.[0];

  const {
    hasNonAccountBuyOrders,
    addUnknownBasketItemsToAccountBasket,
  } = useUnauthBasketPlacement();
  usePortfolioRebalancingsQuery(
    {
      filter: { active: true, accountId: activeAccount?.id },
    },
    {
      enabled: activeAccounts?.length === 1 && hasNonAccountBuyOrders,
      onSuccess: async (data) => {
        if (!hasNonAccountBuyOrders) {
          return;
        }

        const portfolioRebalancing = data.portfolioRebalancings.find(
          (portfolioRebalancing) =>
            portfolioRebalancing.accountId === activeAccount?.id
        );

        await addUnknownBasketItemsToAccountBasket(
          activeAccount!,
          portfolioRebalancing
        );
      },
    }
  );

  const onProceed = () => {
    const accounts = userProfileQuery!.data!.clientSummary?.accounts!;
    const userId = userProfileQuery!.data!.userProfile?.id!;

    const showStepArgs: showStepParams = [
      userId,
      loginResult,
      accounts,
      referralCode,
    ];

    setActiveStep(getNextStep(activeStep, showStepArgs, returnPath));
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <Wrapper>
          {activeStep === MultistepFormSteps.FinishOnboarding && (
            <FinishOnboarding referralCode={referralCode!} onSkip={onProceed} />
          )}

          {activeStep === MultistepFormSteps.ConfirmReferralStep && (
            <ConfirmReferralStep
              referralCode={referralCode!}
              onProceed={onProceed}
            />
          )}

          {activeStep === MultistepFormSteps.MfaSetup && (
            <SetupMfaMethodForm
              proceedBtnText="Continue"
              onProceed={onProceed}
              onSkip={onProceed}
            />
          )}
        </Wrapper>
      )}
    </QueryState>
  );
}
