import { Box } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

const container = css`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing(0, 2.5)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5)};
  }
`;

export const HeroContainer = styled.div`
  ${container};
  padding: ${(p) => p.theme.spacing(3.75, 0, 0, 0)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(5, 0, 0, 0)};
  }
`;

export const PageContainer = styled.section`
  ${container};
  max-width: ${(p) => p.theme.breakpoints.values.xl}px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: ${(p) => p.theme.spacing(7.5)}px;
`;

export const HeroInner = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing(0, 2.5)};
  text-align: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5)};
  }
`;

interface BackgroundContainerProps {
  backgroundImg: string;
}

export const BackgroundContainer = styled.div<BackgroundContainerProps>`
  width: 100%;
  min-height: min(45vh, 400px);
  position: absolute;
  background-color: ${colors.seaBlue};
  background-image: url(${(p) => p.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const UserName = styled.h1`
  font-size: 1.875rem;
  font-weight: normal;
  color: ${colors.white};

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`;

interface TitleProps {
  textAlign: 'left' | 'center' | 'right';
  lightTheme: 'dark' | 'light';
}

export const Title = styled.h1<TitleProps>`
  color: ${(p) => (p.lightTheme === 'light' && colors.magenta) || colors.white};
  font-weight: normal;
  font-size: 1.75rem;
  text-align: ${(p) => p.textAlign};

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`;

export const TabsWrapper = styled(Box)`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2.5, 0)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${(p) => p.theme.spacing(7.5, 0, 3.75, 0)};
  }
`;
