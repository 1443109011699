import { useAuth } from 'context/AuthContext';
import { usePositionsByAccountQuery } from 'generated/graphql';
import { instrumentHeld } from 'helpers/instrumentHeld';

interface InstrumentLike {
  isin: string;
  isDarkUniverse?: boolean | null;
  isCustomUniverse?: boolean | null;
  status?: string;
}

export function useFilterInstrumentsUserCanTrade<T extends InstrumentLike>(
  instruments: T[],
  accountId?: string | null
) {
  const { signedIn } = useAuth();

  const { data } = usePositionsByAccountQuery(undefined, {
    enabled: signedIn,
  });

  const accounts = data?.accounts;

  const userPositions =
    (!!accountId
      ? accounts?.find((acc) => acc.id === accountId)?.positions
      : accounts?.flatMap((acc) => acc.positions)) ?? [];

  return instruments.filter(
    (i) =>
      !i.isDarkUniverse ||
      i.isCustomUniverse ||
      instrumentHeld(i, userPositions)
  );
}
