import { tillitFetch } from 'api/tillitFetch';
import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { IcReportsQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { ArrowText, Hr } from './InvestmentCommittee.styles';
import { IcReportDialog } from './dialog/IcReportDialog';
import { useReportAffirmations } from './hooks/useReportAffirmations';

export type IcReport = Exclude<
  IcReportsQuery['icReports'],
  null | undefined
>[number];

interface ItemProps {
  report: IcReport;
}

export const InvestmentCommitteeItem = ({ report }: ItemProps) => {
  const { title, publishDateFormatted, reportUrl } = report;
  const { showModal } = useModal();
  const { affirmations } = useReportAffirmations();

  /**
   * Does HEAD check for auth, and then GETs if not ok.  Check localStorage
   * before either displaying modal, or opening report
   *
   */
  const handleClick = async (reportUrl: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'ic report card',
      item_id: 'ic report card clicked',
      link_url: reportUrl,
    });

    try {
      if (process.env.NODE_ENV === 'development') {
        reportUrl = new URL(reportUrl).pathname;
      }
      const response = await tillitFetch(reportUrl, {
        method: 'HEAD',
      });
      if (!response.ok) {
        await tillitFetch('/auth/status');
      }
      const affirmationsStored = affirmations?.some(
        (entry) => entry === reportUrl
      );
      if (!affirmationsStored) {
        showModal(IcReportDialog, {
          reportUrl: reportUrl,
        });
      } else {
        window.open(reportUrl, '_blank');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      $paddingSize="small"
      onClick={() => handleClick(reportUrl)}
      fullCardPointer={true}
    >
      <H6>
        <b>{title}</b>
      </H6>
      <Text $fontSize={FontSize.small}>{publishDateFormatted}</Text>
      <Hr />
      <ArrowText>
        <Text
          $fontSize={FontSize.small}
          $fontWeight={FontWeight.normal}
          $noMargin
        >
          Read
        </Text>{' '}
        <HiArrowNarrowRight />
      </ArrowText>
    </Card>
  );
};
