import { ClickAwayListener, Grow } from '@material-ui/core';
import clsx from 'clsx';
import { CustomMobileNav } from 'components/Button/CustomButton';
import { bindToggle } from 'material-ui-popup-state';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import {
  Arrow,
  MobileNavContainer,
  MobileNavListItem,
  StyledPopper,
} from './DropDownNav.style';

export interface ItemProps {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface DropDownNavProps {
  items: Array<ItemProps>;
  value: string;
  onChange: (event: React.SyntheticEvent, value: string) => void;
}

export function DropDownNav({ items, value, onChange }: DropDownNavProps) {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const popperState = usePopupState({ variant: 'popper', popupId: undefined });
  const modifiers = useMemo(
    () => ({
      flip: {
        enabled: true,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: 'scrollParent',
      },
      arrow: {
        enabled: true,
        element: arrowRef,
      },
      sameWidth: {
        order: 825,
        enabled: true,
        fn: (data: any) => {
          const {
            styles,
            offsets: { reference },
          } = data;
          styles.width = reference.width;
          return data;
        },
      },
    }),
    [arrowRef]
  );

  const label = useMemo(() => items.find((i) => i.value === value)?.label, [
    items,
    value,
  ]);

  return (
    <ClickAwayListener onClickAway={popperState.close}>
      <div>
        <CustomMobileNav {...bindToggle(popperState)}>
          {label}
          {popperState.isOpen ? <HiChevronUp /> : <HiChevronDown />}
        </CustomMobileNav>
        <StyledPopper
          {...bindPopper(popperState)}
          placement="bottom-start"
          modifiers={modifiers}
          transition
          data-testid="dropdown-nav--popper"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <div style={{ position: 'relative' }}>
                <Arrow ref={setArrowRef} />
                <MobileNavContainer>
                  {items.map((item) => (
                    <MobileNavListItem
                      key={item.value}
                      className={clsx(
                        { active: item.value === value },
                        { disabled: item.disabled }
                      )}
                      onClick={(e) => {
                        popperState.close();
                        onChange(e, item.value);
                      }}
                    >
                      {item.label}
                    </MobileNavListItem>
                  ))}
                </MobileNavContainer>
              </div>
            </Grow>
          )}
        </StyledPopper>
      </div>
    </ClickAwayListener>
  );
}
