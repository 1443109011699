import { Card } from 'components/design-system/Card/Card';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const HoldingsTabInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'info' 'table';
  gap: 0.75rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: 'table info';
    grid-template-columns: 1fr auto;
  }
`;

export const HoldingsTabInfo = styled.div`
  display: flex;
  gap: 0.75rem;
  grid-area: info;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: column;
  }
`;

export const CardTableContainer = styled(Card)`
  max-width: 100%;
  overflow-x: auto;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  margin-left: 0rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 0.5rem;
  }
`;

export const BreakdownTabHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const BreakdownTabInner = styled.div`
  display: grid;
  grid-template-areas: 'info' 'table';
  gap: 0.75rem;

  /* ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: 'table chart info';
    grid-template-columns: 1fr 1fr auto;
  } */

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: 'table info';
    grid-template-columns: 1fr auto;
  }
`;

export const BreakdownTabInfo = styled.div`
  display: flex;
  grid-area: info;
  gap: 0.75rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: column;
  }
`;

interface BreakdownGroupingSelectProps {
  $active: boolean;
}

export const BreakdownGroupingSelect = styled.button<BreakdownGroupingSelectProps>`
  font-family: 'IBM Plex Sans';
  background: none;
  border: none;
  color: ${({ $active }) => ($active ? colors.magenta : colors.darkGrey)};
  border-bottom: 1px solid
    ${({ $active }) => ($active ? colors.magenta : colors.darkGrey)};
  padding: 0.25rem 0.75rem 0.75rem;
  min-width: 4rem;
  cursor: pointer;
  font-size: 0.75rem;
`;
