import { makeStyles } from '@material-ui/core';
import { HTMLMotionProps, motion, Variants } from 'framer-motion';
import React from 'react';

const pageVariants: Variants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
  out: {
    opacity: 0,
    x: '-100%',
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .stepContent': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& .stepActions': {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexWrap: 'wrap',
      '&.vertical': {
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          flex: 0,
        },
        '& > :first-child': {
          marginBottom: theme.spacing(1),
        },
        '& > * + *': {
          marginTop: theme.spacing(1),
        },
      },
      '&.horizontal': {
        '& > .MuiButton-root': {
          flex: 1,
        },
        '& > .MuiButton-root:first-child': {
          marginRight: theme.spacing(0.5),
        },
        '& > .MuiButton-root + .MuiButton-root': {
          marginLeft: theme.spacing(0.5),
        },
      },
    },
  },
}));

export function StepContainer(props: HTMLMotionProps<'div'>) {
  const classes = useStyles();
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className={classes.root}
      {...props}
    />
  );
}

export const StepContent = (
  props: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => <div className="stepContent" {...props} />;

export const StepActions = (
  props: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => <div className="stepActions horizontal" {...props} />;

export const VerticalStepActions = (
  props: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => <div className="stepActions vertical" {...props} />;
