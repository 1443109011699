import React from 'react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { SwiperCardContainer } from './CardsSwiper.styles';

SwiperCore.use([Virtual, Pagination, Autoplay]);

export interface CardsSwiperProps {
  slides?: React.ReactNode[] | JSX.Element[];
  pagination?: boolean;
  autoplay?: number;
}

/**
 * Renders a swiper component for a list of funds.
 *
 * @param {CardsSwiperProps} slides - The list of slides to be rendered, these can be cards or any other component as the FundSwiper will render a provided component as a slide.
 * @return {JSX.Element} The swiper component for the list of funds.
 */
export function CardsSwiper({
  slides,
  pagination,
  autoplay,
}: CardsSwiperProps) {
  return (
    <SwiperCardContainer
      spaceBetween={16}
      slidesPerView={1.2}
      centeredSlides={false}
      $pagination={pagination}
      pagination={
        pagination
          ? {
              clickable: true,
              dynamicBullets: true,
            }
          : false
      }
      autoplay={
        autoplay
          ? {
              delay: autoplay,
              disableOnInteraction: true,
            }
          : false
      }
      breakpoints={{
        0: {
          slidesPerView: 1.2,
          spaceBetween: 16,
        },
        600: {
          slidesPerView: 2.2,
          spaceBetween: 24,
        },
        960: {
          slidesPerView: 3.2,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3.6,
          spaceBetween: 24,
        },
      }}
    >
      {slides &&
        slides.map((slide, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slide}
          </SwiperSlide>
        ))}
    </SwiperCardContainer>
  );
}
