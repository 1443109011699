import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { ChartDimensions } from 'hooks/useChartDimensions';
import {
  AreaSeries,
  BaseGraph,
  ExtrasRenderFunction,
  LineSeries,
  Series,
} from '../BaseGraph/BaseGraph';
import { TooltipHandle } from '../BaseGraph/Tooltip';

interface AccountContributionsGraphProps {
  accountValue: LineSeries;
  contributions: AreaSeries;
  eventsByDay: {
    [x: string]: {
      [x: string]: number;
    };
  };
  graphSettings: Partial<ChartDimensions>;
  growth: Series;
  labels: number[];
  renderTooltipContent: (tooltip: TooltipHandle, index: number) => void;
  tickFormat: (value: number, numDecimals: number) => string;
  tooltipReference: LineSeries;
}

export function AccountContributionsGraph({
  contributions,
  accountValue,
  growth,
  eventsByDay,
  labels,
  tickFormat,
  graphSettings,
  renderTooltipContent,
}: AccountContributionsGraphProps) {
  const renderEvents: ExtrasRenderFunction = (x, y) => {
    return Object.keys(eventsByDay)
      ?.filter(
        (eventDay) => new Date(parseInt(eventDay)).getTime() >= labels[0]
      )
      ?.map((eventDay) => {
        const settlementDate = new Date(parseInt(eventDay)).setHours(
          0,
          0,
          0,
          0
        );
        const index = d3.bisectCenter(labels, settlementDate);

        return (
          <circle
            key={settlementDate}
            fill={colors.magenta}
            fillOpacity={0.3}
            cx={x(labels[index]!)}
            cy={y(contributions.data[index]!)}
            r="5"
          />
        );
      });
  };

  return (
    <BaseGraph
      labels={labels}
      area={contributions}
      lines={[accountValue]}
      tickFormat={tickFormat}
      renderTooltipContent={renderTooltipContent}
      graphSettings={graphSettings}
      renderExtras={renderEvents}
      tooltipReference={accountValue}
      legendAlignment={'center'}
      YAxisFontSize={'large'}
      YAxisMaxTicksHints={4}
      showMobileSpecificTooltip={true}
      graphLocation="Dashboard"
      graphType="Account Contributions"
    />
  );
}
