import { css } from 'styled-components';

export const noPadding = css`
  padding: 0;
`;

export const smallPadding = css`
  padding: ${(p) => p.theme.spacing(2.5)}px;
`;

export const mediumPadding = css`
  padding: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(5, 2.5)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(5)}px;
  }
`;

export const largePadding = css`
  padding: ${(p) => p.theme.spacing(5, 2.5)};

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(7.5, 5)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(7.5, 5)};
  }
`;

export const legacyBasePadding = css`
  padding: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    //padding: ${(p) => p.theme.spacing(3.75)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(3.75)}px;
  }
`;

export const cardPadding = css`
  padding: ${(p) => p.theme.spacing(5, 2.5, 5, 2.5)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(7.5, 5, 5, 5)};
  }
`;
