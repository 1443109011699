import {
  BuyOrderDetailsByAssetDocument,
  BuyOrderDetailsByAssetQuery,
  BuyOrderDetailsByAssetQueryVariables,
} from 'generated/graphql';
import { UseQueryOptions, useQueries } from 'react-query';
import { useFetcher } from '../api/TillitGraphQL';

interface Context {
  assetId: string;
}

export const useBuyOrderDetailsByAssetQueries = <
  TData = BuyOrderDetailsByAssetQuery & { context: Context },
  TError = unknown
>(
  variables: BuyOrderDetailsByAssetQueryVariables[],
  options?: UseQueryOptions<BuyOrderDetailsByAssetQuery, TError, TData>
) => {
  const fetcher = useFetcher<
    BuyOrderDetailsByAssetQuery,
    BuyOrderDetailsByAssetQueryVariables,
    { assetId: string }
  >(BuyOrderDetailsByAssetDocument);
  return useQueries(
    variables.map((variable) => ({
      queryKey: ['BuyOrderDetailsByAsset', variable],
      queryFn: fetcher.bind(null, variable, { assetId: variable.assetId }),
      ...options,
    }))
  );
};
