import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

interface trackGaPropertiesBase {
  event: GaEventNames;
  [key: string]: any;
}
interface trackGaPropertiesOnboarding {
  event: GaEventNames.onboarding;
  onboardingStep: OnboardingStepNames;
  [key: string]: any;
}
export type trackGaProperties =
  | trackGaPropertiesBase
  | trackGaPropertiesOnboarding;

/**
 * Track google analytics events
 */
export function trackGa(properties: trackGaProperties) {
  if (process.env.NODE_ENV === 'development') {
    if (properties.eventCallback) {
      console.log(JSON.stringify({ action: 'track ga', properties }));
    } else {
      console.log({ action: 'track ga', properties });
    }
  }

  if (window?.dataLayer) {
    window?.dataLayer?.push(properties);
  } else if (properties.eventCallback) {
    properties.eventCallback();
  }
}

export function trackGaClearEcommerce() {
  window?.dataLayer?.push({ ecommerce: null });
}
