import {
  TransferMethod,
  TransferStatus,
  useTransfersQuery,
  WrapperType,
} from 'generated/graphql';
import { useMemo } from 'react';
import { TransfersQueryTransfer } from 'types/graphqlTypes';

const TEN_DAYS = 1000 * 60 * 60 * 24 * 10;

/**
 * filterStatus
 *
 * Check the transfer status AND check the transfer was updated in the last ten days.
 */
const filterStatus = (t: TransfersQueryTransfer, status: TransferStatus) => {
  const currentDate = new Date().getTime();
  const lastUpdated = new Date(t.lastUpdatedTimestampUtc).getTime();
  const expiry = lastUpdated + TEN_DAYS;
  return t.status === status && expiry >= currentDate;
};

declare type TransferSummary = {
  status: string;
  providerName?: string;
  method?: TransferMethod;
  wrapperType?: WrapperType;
};

/**
 * deriveSummaryStatus
 *
 * Takes an array of transfers and returns a single summary status.
 */
export const deriveSummaryStatus = (
  transfers: TransfersQueryTransfer[] | undefined
): TransferSummary => {
  if (!transfers?.length) {
    return { status: 'default' };
  }

  const onHold = transfers.find((t) => t.status === TransferStatus.Problem);
  if (onHold) return { status: 'onHold' };

  const cancelled = transfers.filter((t) =>
    filterStatus(t, TransferStatus.Cancelled)
  );
  if (cancelled.length > 0)
    return {
      status: 'cancelled',
      providerName: cancelled[0].cedingProviderName,
      method: cancelled[0].method,
    };

  const completed = transfers.filter((t) =>
    filterStatus(t, TransferStatus.Completed)
  );
  if (completed.length > 0)
    return {
      status: 'completed',
      providerName: completed[0].cedingProviderName,
      method: completed[0].method,
      wrapperType: completed[0].wrapperType,
    };

  const active = transfers.filter(
    (t) =>
      t.status === TransferStatus.Created ||
      t.status === TransferStatus.InProgress
  );
  if (active && active.length > 1) {
    return { status: 'multi-active' };
  }
  if (
    active &&
    active.length === 1 &&
    active[0].status === TransferStatus.Created
  ) {
    return {
      status: 'requested',
      providerName: active[0].cedingProviderName,
    };
  }
  if (
    active &&
    active.length === 1 &&
    active[0].status === TransferStatus.InProgress
  ) {
    return {
      status: 'single-active',
      providerName: active[0].cedingProviderName,
    };
  }

  return { status: 'default' };
};

export function useTransferStatusSummary() {
  const transferQuery = useTransfersQuery();
  const summary = useMemo(
    () => deriveSummaryStatus(transferQuery.data?.transfers),
    [transferQuery.data?.transfers]
  );
  return { transferQuery, summary };
}
