import { QueryState } from 'components/QueryState';
import { H5 } from 'components/design-system/Heading/Heading';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import type { GetAssetGroupString } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/breakdownGroupingHelpers';
import { assetClassColors, colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { deriveAssetBreakdownComparison } from '../../helpers/deriveAssetBreakdownComparison';
import {
  useCombinedRebalancingBreakdown,
  useGetCurrentBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  DrillDownContainer,
  DrillDownHeader,
  NoBreakdownMessageEl,
  StackedBarComparisonContainer,
} from '../InteractiveBreakdown.styles';
import {
  StackedBarChart,
  StackedBarChartKey,
} from '../_shared/StackedBarChart';
import { BreakdownTab, GroupingOption } from './BreakdownTab';
import { HoldingsTab } from './HoldingsTab';

const colorLookup = [
  colors['purple-600'],
  colors['purple-400'],
  colors['purple-200'],
  colors['purple-700'],
  colors['purple-500'],
  colors['purple-300'],
  colors['purple-100'],
];

export const nameColorLookup: { [key: string]: string } = {
  ...assetClassColors,
  'Pending buys': colors.lightGrey,
};

const NoBreakdownMessage = () => {
  return (
    <NoBreakdownMessageEl>
      <H5 $noMargin>Want to visualise your portfolio?</H5>
      <TextSmall>
        Add funds to your account to see a breakdown of your portfolio.
      </TextSmall>
    </NoBreakdownMessageEl>
  );
};

interface BreakdownStackedBarComparisonProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  getAssetGroupString: GetAssetGroupString;
  groupings: (displayMoreInfo: string) => GroupingOption[];
  portfolioRebalancingId: string;
  isInteractive: boolean;
}

export const RebalancingAllocation = ({
  selectedAccountType,
  selectedAccountId,
  getAssetGroupString,
  groupings,
  portfolioRebalancingId,
  isInteractive,
}: BreakdownStackedBarComparisonProps) => {
  const [displayCombinedBreakdown, setDisplayCombinedBreakdown] = useState<
    string | null
  >(null);

  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    getAssetGroupString
  );
  const {
    combinedBreakdown,
    portfolioRebalancingsQuery,
  } = useCombinedRebalancingBreakdown(
    selectedAccountType,
    portfolioRebalancingId,
    getAssetGroupString
  );

  const breakdownItemColors = combinedBreakdown.map((item, key) => ({
    name: item.name,
    color: nameColorLookup[item.name] || colorLookup[key % colorLookup.length],
  }));

  const breakdownComparison = deriveAssetBreakdownComparison(
    breakdowns,
    combinedBreakdown
  );
  const activeCombinedBreakdownComparison = breakdownComparison.find(
    ({ text }) => text === displayCombinedBreakdown
  );

  if (breakdowns.length === 0 && combinedBreakdown.length === 0) {
    return <NoBreakdownMessage />;
  }

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {() => (
        <>
          <StackedBarComparisonContainer>
            {combinedBreakdown.length > 0 && (
              <div>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Allocation based on changes made
                </TextSmall>
                <StackedBarChart
                  breakdowns={combinedBreakdown}
                  breakdownItemColors={breakdownItemColors}
                  onClick={
                    isInteractive
                      ? ({ name }) => {
                          if (displayCombinedBreakdown === name) {
                            trackGa({
                              event: GaEventNames.selectContent,
                              content_type:
                                'interactive breakdown - rebalancing allocation',
                              item_id: 'unselect',
                            });
                            setDisplayCombinedBreakdown(null);
                          } else {
                            trackGa({
                              event: GaEventNames.selectContent,
                              content_type:
                                'interactive breakdown - rebalancing allocation',
                              item_id: name,
                            });
                            setDisplayCombinedBreakdown(name);
                          }
                        }
                      : undefined
                  }
                />
                <StackedBarChartKey breakdownItemColors={breakdownItemColors} />
              </div>
            )}
          </StackedBarComparisonContainer>
          {displayCombinedBreakdown &&
            displayCombinedBreakdown === 'Cash' &&
            activeCombinedBreakdownComparison && (
              <DrillDownContainer>
                <DrillDownHeader>
                  <TextNormal $noMargin $fontWeight={FontWeight.normal}>
                    {activeCombinedBreakdownComparison.text}
                  </TextNormal>
                  <TextNormal $noMargin>
                    {Math.round(activeCombinedBreakdownComparison.start)}% |{' '}
                    {format.currencyFull(
                      activeCombinedBreakdownComparison.amount
                    )}
                  </TextNormal>
                </DrillDownHeader>
              </DrillDownContainer>
            )}
          {displayCombinedBreakdown &&
            displayCombinedBreakdown !== 'Cash' &&
            activeCombinedBreakdownComparison && (
              <DrillDownContainer>
                <Tabs
                  title={
                    <DrillDownHeader>
                      <TextNormal $noMargin>
                        {activeCombinedBreakdownComparison.text}
                      </TextNormal>
                    </DrillDownHeader>
                  }
                  onClick={(tabTitle) => {
                    trackGa({
                      event: GaEventNames.selectContent,
                      content_type:
                        'interactive breakdown - rebalancing allocation',
                      item_id: tabTitle,
                    });
                  }}
                  tabs={[
                    {
                      title: 'Holdings',
                      content: (
                        <HoldingsTab
                          activeBreakdownComparison={
                            activeCombinedBreakdownComparison
                          }
                          selectedAccountId={selectedAccountId}
                          selectedAccountType={selectedAccountType}
                          includeAsset={(asset) =>
                            getAssetGroupString(asset) ===
                            displayCombinedBreakdown
                          }
                        />
                      ),
                    },
                    {
                      title: 'Breakdown',
                      content: (
                        <BreakdownTab
                          groupings={groupings(displayCombinedBreakdown)}
                          activeBreakdownComparison={
                            activeCombinedBreakdownComparison
                          }
                          selectedAccountType={selectedAccountType}
                          selectedAccountId={selectedAccountId}
                          includeAsset={(asset) =>
                            getAssetGroupString(asset) ===
                            displayCombinedBreakdown
                          }
                        />
                      ),
                    },
                  ]}
                />
              </DrillDownContainer>
            )}
        </>
      )}
    </QueryState>
  );
};
