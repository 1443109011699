import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const TransferPromptContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const PromptCopy = styled(Typography)`
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;
