import {
  GoBackButton,
  StepButton,
} from 'components/design-system/StepComponents/StepComponents';

export interface FormActionsProps {
  onProceed?: () => void; //TODO: This should be removed when all forms are implemented
  onGoBack: () => void;
  disabled?: boolean;
}

export function FormActions({
  onProceed,
  onGoBack,
  disabled = false,
}: FormActionsProps) {
  return (
    <>
      <StepButton
        type="submit"
        className="magenta"
        onClick={onProceed}
        disabled={disabled}
      >
        Continue
      </StepButton>
      <GoBackButton onClick={onGoBack} disabled={disabled} />
    </>
  );
}
