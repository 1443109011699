import MeetTheManagerPlaceholder from 'assets/meet-the-manager_large.jpg';
import { SmallCopy } from 'components/Copy/SmallCopy';
import * as format from 'formatting';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { MeetManagerVideo } from '../MeetManagerVideo';
import {
  MeetManagerContainer,
  MeetManagerPlaceholder,
  SectionIntro,
  Spacer,
  Title,
} from '../Styles/FundDetails.style';

interface MeetManagerSliceProps {
  asset: AssetQueryAsset;
  hasActiveAccount: boolean;
  signedIn: boolean;
}

export function MeetManagerSlice({
  asset,
  hasActiveAccount,
  signedIn,
}: MeetManagerSliceProps) {
  const meetManagerVideo = asset?.meetManagerVideo;

  return (
    <MeetManagerContainer id="meet-the-manager">
      <Title>Meet the Manager</Title>
      {!!meetManagerVideo ? (
        <>
          <SectionIntro>
            To help you get to know the fund manager making the decisions in the
            fund, we have interviewed them for our unique ‘TILLIT Meet the
            Manager’ video. In this special interview, we ask them 20 questions
            which they haven’t seen before, on a range of topics so that you can
            get to know who they are as an investor and as a person. The final
            cut of the interview features the 10 best answers, which means that
            some questions may vary between funds.
          </SectionIntro>
          <SectionIntro style={{ fontStyle: 'italic' }}>
            Please note that if there is more than one named fund manager on the
            fund/investment trust, only one of them has been filmed for the
            interview.
          </SectionIntro>
          <MeetManagerVideo
            meetManagerVideo={meetManagerVideo}
            description={asset?.description!}
            signedIn={signedIn}
            hasActiveAccount={!!hasActiveAccount}
          />
          {meetManagerVideo.filmedDate && (
            <>
              <Spacer size={30} />
              <SmallCopy
                $size={16}
                $fontStyle="italic"
                copy={`Filmed on ${format.date(meetManagerVideo.filmedDate!)}`}
              />
            </>
          )}
        </>
      ) : (
        <>
          <SectionIntro>
            To help you get to know the fund manager making the decisions in the
            fund, we aim to interview them for our unique ‘TILLIT Meet the
            Manager’ video. However, at this point there is no interview
            available for this fund but we hope to change this in the future.
          </SectionIntro>
          <MeetManagerPlaceholder src={MeetTheManagerPlaceholder} />
        </>
      )}
    </MeetManagerContainer>
  );
}
