import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterMobileSummaryLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;
`;

export const FooterMobileLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  padding-right: 3.5rem;
`;

export const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    max-width: 1280px;
    padding-right: 3.5rem;
  }

  ${({ theme }) => theme.breakpoints.up(1400)} {
    max-width: 1280px;
    padding-right: 0;
  }
`;

export const AddCashLink = styled(Link)`
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-weight: 300;
  text-decoration: underline;
  font-size: 0.875rem;
  color: ${colors.black};
  padding: 1px 0.25rem;
`;

export const AllocateButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
`;
