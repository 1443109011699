import { isTypeSource } from 'pages/FundDetails/fundDetailsTypes';
import { ExtractRouteParams, generatePath } from 'react-router';

// Internal Paths:
export const rootPath = '/';

export const hexagonPagePath = '/';

/**
 * Dashboard paths
 */

export const dashboardPath = '/dashboard';
export const dashboardAccountPath = '/dashboard/:wrapperType(gia|isa|pension)';
export const dashboardAccountAddCashPath =
  '/dashboard/:wrapperType(gia|isa|pension)/add-cash';
export const dashboardAccountRegularOrderPath =
  '/dashboard/:wrapperType(gia|isa|pension)/#regular-deposits';
export const dashboardSubPath =
  '/dashboard/:page(gia|isa|transfers|pension|my-documents|my-details|ic-reports|orders|referrals)';
export const dashboardGiaPath = `${dashboardPath}/gia`;
export const dashboardIsaPath = `${dashboardPath}/isa`;
export const dashboardTransfersPath = `${dashboardPath}/transfers`;
export const dashboardPensionPath = `${dashboardPath}/pension`;
export const dashboardReferralsPath = `${dashboardPath}/referrals`;
export const dashboardMyDocumentsPath = `${dashboardPath}/my-documents`;
export const dashboardMyDetailsPath = `${dashboardPath}/my-details`;
export const dashboardOrders = `${dashboardPath}/orders`;
export const dashBoardInvestmentCommitteePath = `${dashboardPath}/ic-reports`;

export const generateDashboardAccountPath = (
  args: ExtractRouteParams<typeof dashboardAccountPath>
) => generatePath(dashboardAccountPath, args);

export type dashboardAccountAddCashParamType = ExtractRouteParams<
  typeof dashboardAccountAddCashPath
>;
export const generateDashboardAccountAddCashPath = (
  args: ExtractRouteParams<typeof dashboardAccountAddCashPath>
) => generatePath(dashboardAccountAddCashPath, args);

export const generateDashboardAccountRegularOrderPath = (
  args: ExtractRouteParams<typeof dashboardAccountRegularOrderPath>
) => generatePath(dashboardAccountRegularOrderPath, args);

/**
 * Fund paths
 */

export const fundListPath = '/funds';

export const fundDetailsPath = '/funds/:id/:slug';
export const generateFundDetailsPath = (
  args: ExtractRouteParams<typeof fundDetailsPath>
) => generatePath(fundDetailsPath, args);

interface returnPathParams {
  searchTags?: string;
  searchTerm?: string;
  source?: string;
}

export const generateFundDetailsReturnQueryPath = ({
  searchTags,
  searchTerm,
  source,
}: returnPathParams) => {
  const searchParams: { [key: string]: string } = {};
  if (searchTags) {
    searchParams.tags = searchTags;
  }
  if (searchTerm) {
    searchParams.search = searchTerm;
  }
  if (isTypeSource(source)) {
    searchParams.source = source;
  }
  const urlParams = new URLSearchParams(searchParams);

  return searchTags || searchTerm || source ? `?${urlParams.toString()}` : '';
};

export const generateFundDetailsWithReturnPath = (
  params: ExtractRouteParams<typeof fundDetailsPath>,
  { searchTags, searchTerm, source }: returnPathParams
) => {
  return (
    generateFundDetailsPath(params) +
    generateFundDetailsReturnQueryPath({ searchTags, searchTerm, source })
  );
};

export const fundDetailsSubPath =
  '/funds/:id/:slug/:route(meet-the-managers|performance|breakdown|key-information)';
export const generateFundDetailsSunPath = (
  args: ExtractRouteParams<typeof fundDetailsSubPath>
) => generatePath(fundDetailsSubPath, args);

export const fundDetailsMeetManagersPath = '/funds/:id/:slug/meet-the-managers';
export const generateFundDetailsMeetManagersPath = (
  args: ExtractRouteParams<typeof fundDetailsMeetManagersPath>
) => generatePath(fundDetailsMeetManagersPath, args);

export const fundDetailsPerformancePath = '/funds/:id/:slug/performance';
export const generateFundDetailsPerformancePath = (
  args: ExtractRouteParams<typeof fundDetailsPerformancePath>
) => generatePath(fundDetailsPerformancePath, args);

export const fundDetailsBreakdownPath = '/funds/:id/:slug/breakdown';
export const generateFundDetailsBreakdownPath = (
  args: ExtractRouteParams<typeof fundDetailsBreakdownPath>
) => generatePath(fundDetailsBreakdownPath, args);

export const fundDetailsInformationPath = '/funds/:id/:slug/key-information';
export const generateFundDetailsInformationPath = (
  args: ExtractRouteParams<typeof fundDetailsInformationPath>
) => generatePath(fundDetailsInformationPath, args);

/**
 * @deprecated - use fundDetailsPath instead
 */
export const fundDetailsOldPath = '/funds/:id';

/**
 * @deprecated - use generateFundDetailsPath instead
 */
export const generateFundDetailsOldPath = (
  args: ExtractRouteParams<typeof fundDetailsOldPath>
) => generatePath(fundDetailsOldPath, args);

/**
 * Open accounts paths
 */
export const openAccountPath = '/open-account';
export const openAccountResumePath = '/open-account/resume';
export const openAccountTransferPath = '/open-account/transfer';
export const openAccountResumeAccountPath =
  '/open-account/resume/:type(isa|gia|pension)';
export const openAccountResumeTypePath =
  '/open-account/:resume(resume)/:type(isa|gia|pension|transfer)?';
export const openAccountTypePath =
  '/open-account/:type(isa|gia|pension|transfer|switch)';
export const generateOpenAccountTypePath = (
  args: ExtractRouteParams<typeof openAccountTypePath>
) => generatePath(openAccountTypePath, args);

export const openPensionPath = '/open-account/pension';

export const startTransferBasePath = '/start-transfer';
export const startTransferPath =
  '/start-transfer/:accountType(gia|isa|pension)';
export const generateStartTransferPath = (
  args: ExtractRouteParams<typeof startTransferPath>
) => generatePath(startTransferPath, args);

export const appSignInPath = '/sign-in';
export const generateAppSignInPath = (
  returnPath?: string,
  wasAutoSignedOut?: boolean,
  referralCode?: string
) => {
  const params = new URLSearchParams();
  if (returnPath && !['/', '/sign-in'].includes(returnPath)) {
    params.set('return-path', returnPath);
  }
  if (wasAutoSignedOut) {
    params.set('signedOut', 'true');
  }
  if (referralCode) {
    params.set('referralCode', referralCode);
  }
  const paramsString = params.toString();
  return appSignInPath + (paramsString ? `?${paramsString}` : '');
};

export const appResetPasswordPath = '/reset-password';

export const appSignInSignUpPath = '/sign-in-sign-up';

/**
 * Portfolio builder paths
 */

export const dynamicPortfolioConstructionBasePath =
  '/dynamic-portfolio-construction';

export const dynamicPortfolioConstructionPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)`;
export const generateDynamicPortfolioConstructionPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionPath>
) => generatePath(dynamicPortfolioConstructionPath, args);

export const dynamicPortfolioConstructionAddCashPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)/add-cash`;
export const generateDynamicPortfolioConstructionAddCashPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionAddCashPath>
) => generatePath(dynamicPortfolioConstructionAddCashPath, args);

export const dynamicPortfolioConstructionFundsPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)/funds`;
export const generateDynamicPortfolioConstructionFundsPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionFundsPath>
) => generatePath(dynamicPortfolioConstructionFundsPath, args);

export const dynamicPortfolioConstructionFundsAddCashPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)/funds/add-cash`;
export const generateDynamicPortfolioConstructionFundsAddCashPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionFundsAddCashPath>
) => generatePath(dynamicPortfolioConstructionFundsAddCashPath, args);

export const dynamicPortfolioConstructionBasketPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)/basket`;
export const generateDynamicPortfolioConstructionBasketPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionBasketPath>
) => generatePath(dynamicPortfolioConstructionBasketPath, args);

export const dynamicPortfolioConstructionBasketAddCashPath = `${dynamicPortfolioConstructionBasePath}/:wrapperType(gia|isa|pension|unknown)/basket/add-cash`;
export const generateDynamicPortfolioConstructionBasketAddCashPath = (
  args: ExtractRouteParams<typeof dynamicPortfolioConstructionBasketAddCashPath>
) => generatePath(dynamicPortfolioConstructionBasketAddCashPath, args);

export const checkoutBasePath = '/checkout';
export const checkoutPath = `${checkoutBasePath}/:wrapperType(gia|isa|pension|new-gia|new-isa)/:selectedRebalancingId`;
export const generateCheckoutPath = (
  args: ExtractRouteParams<typeof checkoutPath>
) => generatePath(checkoutPath, args);

export const uploadToRequestPath = '/uploadrequests/:requestId';
export const generateUploadToRequestPath = (
  args: ExtractRouteParams<typeof uploadToRequestPath>
) => generatePath(uploadToRequestPath, args);

export const autoSaveInvestBasePath = '/auto-save-invest';
export const autoSaveInvestPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)';

export const generateAutoSaveInvestPathPath = (
  args: ExtractRouteParams<typeof autoSaveInvestPath>
) => generatePath(autoSaveInvestPath, args);

export const autoSaveInvestSubPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)/:action(create-deposit|confirm|add-funds|my-funds)';
export const generateAutoSaveInvestSubPath = (
  args: ExtractRouteParams<typeof autoSaveInvestSubPath>
) => generatePath(autoSaveInvestSubPath, args);

export const autoSaveInvestPathCreateDepositPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)/create-deposit';

export const autoSaveInvestPathConfirmPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)/confirm';

export const autoSaveInvestPathAddFundsPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)/add-funds';

export const autoSaveInvestPathMyFundsPath =
  '/auto-save-invest/:wrapperType(gia|isa|pension)/my-funds';

export const invitationPath = '/invitation/:code';
export const generateInvitationPath = (
  args: ExtractRouteParams<typeof invitationPath>
) => generatePath(invitationPath, args);

export const xrayBetaPath = '/x-ray-beta';

export const generateXrayBetaPath = (
  args: ExtractRouteParams<typeof xrayBetaPath>
) => generatePath(xrayBetaPath, args);

export const actionsPath = '/actions';
export const actionsAddCashPath = '/actions/add-cash';
export const actionsSetupMfa = '/actions/setup-mfa';
export const actionsTransferPath = '/actions/transfer';
