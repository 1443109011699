import { Typography } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useUpdateSippInterestMutation } from 'hooks/useUpdateSippInterestMutation';
import {
  SIPPInterestContentRegistered,
  SIPPInterestContentUnregisteredSection,
} from './SIPPInterestContent';
import {
  SectionInner,
  SippInterestActionWrapper,
  SippInterestContentWrapper,
} from './SIPPInterestSection.style';

export interface PureSippInterestSectionProps {
  interestRegistered: boolean;
  isLoading: boolean;
  onInterest: () => void;
}

export function PureSippInterestSection({
  interestRegistered,
  isLoading,
  onInterest,
}: PureSippInterestSectionProps) {
  return (
    <SectionInner>
      <Typography variant="subtitle1">Pension</Typography>
      {interestRegistered && (
        <SippInterestContentWrapper>
          <SIPPInterestContentRegistered />
        </SippInterestContentWrapper>
      )}
      {!interestRegistered && (
        <SippInterestContentWrapper>
          <SIPPInterestContentUnregisteredSection />
          <SippInterestActionWrapper>
            <CustomButtonV2
              $color="primary"
              onClick={() => onInterest()}
              disabled={isLoading}
            >
              I'm interested
            </CustomButtonV2>
          </SippInterestActionWrapper>
        </SippInterestContentWrapper>
      )}
    </SectionInner>
  );
}

export function SippInterestSection() {
  const userProfileQuery = useUserProfileQuery();
  const { mutateAsync, isLoading } = useUpdateSippInterestMutation();
  const hasRegistered =
    userProfileQuery.data?.userProfile?.interestedInSipp ?? false;

  const handleRegisterInterest = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `register interest - sipp dashboard section`,
    });
    await mutateAsync({});
  };

  return (
    <>
      <QueryState {...userProfileQuery}>
        {() => (
          <PureSippInterestSection
            interestRegistered={hasRegistered}
            isLoading={isLoading}
            onInterest={handleRegisterInterest}
          />
        )}
      </QueryState>
    </>
  );
}
