import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { mediumPadding } from 'theme/shared/spacing.styles';

export const CheckoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - ${({ theme }) => theme.header.height});
`;

export const CheckoutContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 4.5rem 0;
  }
`;

export const AddCashWrapper = styled.div`
  margin: 0 auto;
  max-width: 40rem;
  .MuiDialogContent-root {
    padding: 0.5rem 0;
    text-align: center;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 0.5rem 0;
    flex-direction: column;
  }
  .trueLayer__selectProviderStep.MuiDialogContent-root {
    padding: 0.5rem 1rem;
  }
`;

export const CheckoutWrapperContainer = styled(Container)`
  ${mediumPadding}
`;
