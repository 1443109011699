import { LinkCustomButton } from 'components/Button/CustomButton';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useCanTransfer } from 'hooks/useCanTransfer';
import { generateStartTransferPath } from 'paths';
import { useMemo } from 'react';
import { PromptCopy, TransferPromptContainer } from './TransferPrompt.styles';

export interface TransferPromptProps {
  wrapperType: WrapperType;
  source?: string;
  onClose: () => void;
}

export function TransferPrompt({ wrapperType, onClose }: TransferPromptProps) {
  const canTransfer = useCanTransfer();

  const ButtonDisabled = useMemo(() => {
    return !canTransfer.gia || !canTransfer.isa ? true : false;
  }, [canTransfer]);

  const handleOpenTransferForm = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - ISA',
    });
    onClose();
  };

  return (
    <TransferPromptContainer>
      <PromptCopy>Have accounts elsewhere?</PromptCopy>
      <LinkCustomButton
        to={generateStartTransferPath({
          accountType: getPathSegmentForWrapperType(wrapperType),
        })}
        className="purple"
        disabled={ButtonDisabled}
        style={{
          whiteSpace: 'break-spaces',
        }}
        onClick={handleOpenTransferForm}
      >
        Transfer to TILLIT
      </LinkCustomButton>
    </TransferPromptContainer>
  );
}
