import { colors } from 'constants/colors';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Text } from '../Text/Text';

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioInputWrapper = styled.span`
  line-height: 1;

  input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
  }

  /** Outer circle */
  input + span {
    display: inline-block;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }

  /** hover background */
  input + span:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${colors['grey-100']};
    transform: scale(0);
    transition: transform 0.33s;
  }

  &:hover input + span:before,
  input:focus + span:before {
    transform: scale(1);
  }

  /** hover click background */
  input + span:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 50%;
    background-color: ${colors['magenta-100']};
    transform: scale(0);
    transition: transform 0.66s;
  }

  &:hover:active input + span:after {
    transform: scale(1.05);
  }

  /** Outer circle */
  input + span span {
    z-index: 1;
    position: absolute;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    border: 2px solid ${colors.magenta};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /** Inner circle */
  input + span span:after {
    z-index: 1;
    content: '';
    position: absolute;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: ${colors.magenta};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.33s;
  }

  input:checked + span span:after {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export interface RadioProps {
  name: string;
  label: string | React.ReactNode;
  value: string;
  defaultChecked?: boolean;
  checked?: boolean;
  className?: string;
  wrapLabel?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, any>(function Radio(
  {
    name,
    label,
    value,
    defaultChecked,
    checked,
    className,
    wrapLabel = true,
  }: RadioProps,
  ref
) {
  return (
    <Label htmlFor={`${name}-${value}`} className={className}>
      <RadioInputWrapper>
        <input
          type="radio"
          value={value}
          name={name}
          id={`${name}-${value}`}
          ref={ref}
          checked={checked}
          defaultChecked={defaultChecked}
        />
        <span>
          <span></span>
        </span>
      </RadioInputWrapper>
      {typeof label === 'string' ? (
        <Text $noMargin>{label}</Text>
      ) : (
        <>{wrapLabel ? <div>{label}</div> : label}</>
      )}
    </Label>
  );
});
