import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface PillContainerProps {
  color?: string;
  filled?: boolean;
}

export const SinglePill = styled.div<PillContainerProps>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  padding: ${(p) => p.theme.spacing(2, 2.5)};
  ${(p) =>
    p.filled
      ? css`
          background-color: ${transparentize(0.95, p.color!)};
        `
      : css`
          background-color: transparent;
          border: 1px solid ${transparentize(0.9, p.color!)};
        `};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(2, 5)};
  }
`;

SinglePill.defaultProps = {
  color: colors.richBlue,
  filled: true,
};
