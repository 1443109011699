import { Alert } from '@material-ui/lab';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import { CooldownButton } from 'components/design-system/Button/CooldownButton/CooldownButton';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  useResendPhoneVerificationCodeMutation,
  useUserProfileQuery,
  useVerifyLastPhoneNumberMutation,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const verificationCodeFormSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .label('Verification code')
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .matches(/^\d{6}$/, '${path} must be 6 digits'),
});

type VerificationCodeFormValues = Yup.Asserts<
  typeof verificationCodeFormSchema
>;

const StepActionsVerificationCode = styled(StepActions)`
  gap: 0.75rem;
`;

export interface VerifyMobileNumberStepProps {
  onProceed: () => void;
  phoneNumber: string;
}

export function VerifyMobileNumberStep({
  onProceed,
  phoneNumber,
}: VerifyMobileNumberStepProps) {
  const {
    mutateAsync,
    isError,
    error,
  } = useVerifyLastPhoneNumberMutation<any>();
  const queryClient = useQueryClient();

  const onSubmit = async (data: VerificationCodeFormValues) => {
    await mutateAsync(
      { code: data.verificationCode },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(useUserProfileQuery.getKey());
        },
      }
    );

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
      mfaStatus: 'success',
    });

    onProceed();
  };

  return (
    <StepContainer>
      <Form<VerificationCodeFormValues>
        onSubmit={onSubmit}
        schema={verificationCodeFormSchema}
      >
        <StepContent>
          <StepTitle>Verification code</StepTitle>

          <StepIntroduction>
            <StepIntroductionTypography>
              Please enter the code sent to {phoneNumber}
            </StepIntroductionTypography>
          </StepIntroduction>

          {isError ? (
            error?.[0]?.extensions?.code === 'INVALID_MFA_CODE' ||
            error?.[0]?.extensions?.code === 'EXPIRED_MFA_CODE' ? (
              <Alert severity="error">{error[0].message}</Alert>
            ) : (
              <ServerError isVisible={true} />
            )
          ) : null}
          <StepFormContainer>
            <TextField
              name="verificationCode"
              label="Verification code"
              inputProps={{ inputMode: 'decimal' }}
              autoFocus
            />
          </StepFormContainer>
        </StepContent>
        <StepActionsVerificationCode>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>

                <ResendCodeButton disabled={isSubmitting} />
              </>
            )}
          </FormState>
        </StepActionsVerificationCode>
      </Form>
    </StepContainer>
  );
}

interface ResendCodeButtonProps {
  disabled?: boolean;
}

function ResendCodeButton({ disabled = false }: ResendCodeButtonProps) {
  const [cooldown, setCooldown] = useState(30);

  const { mutateAsync } = useResendPhoneVerificationCodeMutation();

  const handleClick = useCallback(async () => {
    setCooldown(30);
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
      mfaStatus: 'resent',
    });
    await mutateAsync({});
  }, [mutateAsync]);

  useEffect(() => {
    let timer: null | number = null;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown((prev) => prev - 1), 1000);
    }
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [cooldown]);

  return <CooldownButton disabled={disabled} action={handleClick} />;
}
