import { Alert, AlertTitle } from '@material-ui/lab';
import {
  LinkStepButton,
  StepActions,
  StepButton,
  StepContainer,
  StepIntroduction,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { dashboardPath } from 'paths';
import { useIntercom } from 'react-use-intercom';

export interface CantDepositProps {
  reason: string;
}

export function CantDeposit({ reason }: CantDepositProps) {
  const { showNewMessages } = useIntercom();

  const handleContactSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `regular deposit - can't deposit step - ${reason}`,
    });
    showNewMessages('I’m having trouble setting up a regular deposit');
  };

  return (
    <StepContainer>
      <StepTitle>Set up a regular deposit</StepTitle>
      <StepIntroduction $width={StepIntroductionWidth.extraWide}>
        <Alert icon={false} severity="info">
          <AlertTitle>Deposits disabled</AlertTitle>
          {reason}
        </Alert>
      </StepIntroduction>

      <StepActions $isHorizontal>
        <LinkStepButton className="magenta" to={dashboardPath}>
          Dashboard
        </LinkStepButton>
        <StepButton className="richBlue" onClick={handleContactSupport}>
          Contact support
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
