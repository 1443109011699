import { Button } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

const transitionSpeed = '0.3s';
const arrowOffset = '5px';
const animationFunction = 'cubic-bezier(.76,.16,.88,1.12)';

export const StyledButton = styled(Button)`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: ${colors.magenta};
    transition: width ${transitionSpeed} ${animationFunction};
  }

  &:hover {
    background-color: transparent;

    &:before {
      width: calc(100% + ${arrowOffset});
    }

    .MuiButton-endIcon {
      transform: translateX(${arrowOffset});
    }
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  .MuiButton-label {
    text-transform: none;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4;
    color: ${colors.magenta};
  }

  .MuiButton-endIcon {
    transition: transform ${transitionSpeed} ${animationFunction};
    transform: translateX(0);
    margin-left: ${(p) => p.theme.spacing(0.625)}px;
    margin-right: 0;

    & > :first-child {
      font-size: 1rem;
    }
  }
`;
