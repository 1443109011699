import {
  getBuyEstimate,
  getSellEstimate,
} from 'components/Dialogs/Trading/helpers';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import {
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import {
  H3,
  H4,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { StyledA } from 'components/design-system/Link';
import { Text, TextAlign, TextSmall } from 'components/design-system/Text/Text';
import { OrderStatusTable } from 'components/feature/PortfolioBuilder/_shared/OrderStatusTable/OrderStatusTable';
import { useFundsBasket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import {
  PortfolioRebalancingStatus,
  RebalancingTransactionStatus,
  WrapperType,
  useExecutePortfolioRebalancingBuysMutation,
  useExecutePortfolioRebalancingSellsMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AccountsQueryAccount,
  RebalancingQueryRebalancing,
} from 'types/graphqlTypes';
import { v4 as uuid } from 'uuid';
import {
  GoBackButton,
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import { useEditWaitingOrder } from '../../../PortfolioBuilder/_shared/useEditWaitingOrder';
import { DPCheckoutSteps } from '../../Checkout';
import { PolicyPreTradeText } from '../../PolicyPreTradeText/PolicyPreTradeText';
import { DpFlowTypes } from '../../flowType';
import {
  ActionsText,
  AlertContainer,
  EditOrderContainer,
  StepSection,
  WideSection,
} from './OrderConfirmation.styles';

interface OrderConfirmationProps {
  onProceed: () => void;
  onBack?: () => void;
  availableCash: number;
  depositedAmount: number | null;
  portfolioRebalancing: RebalancingQueryRebalancing;
  selectedAccountType: WrapperType;
  selectedAccount: AccountsQueryAccount;
  activeFlow: DpFlowTypes;
}

enum ConfirmActions {
  sell = 'sell',
  buy = 'buy',
}

const ItEtfEtcCopy = () => {
  return (
    <Text $textAlign={TextAlign.center}>
      If your order includes any investment trusts or ETFs or ETCs, please note
      that these orders will be placed as part of a daily block deal at 15.00.
    </Text>
  );
};

const StandardIntroText = () => {
  return (
    <>
      <H3 $textAlign={HeadingAlign.center}>Confirm your order</H3>
      <Text $textAlign={TextAlign.center}>
        Please review your orders below before proceeding. To find out more
        about each order, click on the arrow. If you need to make any changes,
        just click ‘Edit order’.
      </Text>
      <ItEtfEtcCopy />
    </>
  );
};

const ResumingCheckoutSufficientCashIntroText = () => {
  return (
    <>
      <H3 $textAlign={HeadingAlign.center}>Reconfirm your BUY orders</H3>
      <Text $textAlign={TextAlign.center}>
        Your SELL orders have completed and we now need you to review and
        reconfirm your BUY orders. If you need to make any changes, just click
        ‘Edit order’.
      </Text>
      <ItEtfEtcCopy />
    </>
  );
};

const ResumingCheckoutInsufficientCashIntroText = () => {
  return (
    <>
      <H3 $textAlign={HeadingAlign.center}>Reconfirm your BUY orders </H3>
      <Text $textAlign={TextAlign.center}>
        Please review your BUY orders below before proceeding. To find out more
        about each order, click on the arrow. If you need to make any changes,
        just click ‘Edit order’.
      </Text>
      <ItEtfEtcCopy />
    </>
  );
};

const IntroTextLookup: { [k in string]: () => JSX.Element } = {
  [DpFlowTypes.returningToBuys]: ResumingCheckoutSufficientCashIntroText,
  [DpFlowTypes.returningSellsLessThanExpected]: ResumingCheckoutInsufficientCashIntroText,
  [DpFlowTypes.returningToBuysInsufficientCash]: ResumingCheckoutInsufficientCashIntroText,
};

export function OrderConfirmation({
  onProceed,
  onBack,
  availableCash,
  depositedAmount,
  portfolioRebalancing,
  selectedAccountType,
  selectedAccount,
  activeFlow,
}: OrderConfirmationProps) {
  const userProfileQuery = useUserProfileQuery();
  const employment = userProfileQuery.data?.userProfile?.employments?.[0];
  const [, setMode] = useMode();
  const [idempotencyToken] = useState(uuid());

  const editWaitingOrder = useEditWaitingOrder(
    selectedAccount.id!,
    selectedAccountType,
    portfolioRebalancing
  );

  const {
    mutateAsync: editOrder,
    isLoading: editWaitingOrderIsLoading,
    isError: editWaitingOrderIsError,
  } = editWaitingOrder;

  const handleEditOrder = () => {
    if (editOrder === null) {
      return;
    }
    editOrder();
  };

  const ConfirmAction =
    portfolioRebalancing?.sellOrders &&
    portfolioRebalancing?.sellOrders.filter(
      (sellOrder) =>
        sellOrder?.status !== RebalancingTransactionStatus.Completed
    )?.length
      ? ConfirmActions.sell
      : ConfirmActions.buy;

  const { clear } = useFundsBasket({ selectedAccountId: selectedAccount.id! });

  const executePortfolioRebalancingBuysMutationQuery = useExecutePortfolioRebalancingBuysMutation();
  const executePortfolioRebalancingSellsMutationQuery = useExecutePortfolioRebalancingSellsMutation();

  const mutateExecutePortfolioRebalancing =
    ConfirmAction === ConfirmActions.sell
      ? executePortfolioRebalancingSellsMutationQuery
      : executePortfolioRebalancingBuysMutationQuery;

  const { mutateAsync, error, isLoading } = mutateExecutePortfolioRebalancing;

  const IntroText = IntroTextLookup[activeFlow] || StandardIntroText;

  const getRebalancingEcommerceItems = () => {
    const buyOrders = portfolioRebalancing?.buyOrders || [];
    const sellOrders = portfolioRebalancing?.sellOrders || [];

    const totalBuys = buyOrders.reduce((acc, bo) => acc + bo?.amount!, 0);
    const totalSells = sellOrders.reduce(
      (acc, so) => acc + so?.enteredAmount!,
      0
    );
    const totalValue = totalBuys - totalSells;

    const buyItems = buyOrders.map((bo) => {
      const units = getBuyEstimate(bo?.instrument!, bo?.amount!);
      return {
        item_id: bo?.instrument?.asset?.id!,
        item_name: bo?.instrument?.asset!.name,
        affiliation: 'Tillit',
        currency: 'GBP',
        item_brand: bo?.instrument?.asset!.assetManager?.name,
        item_category: bo?.instrument?.asset!.tags!.nodes![0],
        item_category2: bo?.instrument?.asset!.tags!.nodes![1],
        item_category3: bo?.instrument?.asset!.tags!.nodes![2],
        item_category4: bo?.instrument?.asset!.tags!.nodes![3],
        item_category5: bo?.instrument?.asset!.tags!.nodes![4],
        item_variant: selectedAccountType,
        price: bo?.amount,
        quantity: Math.round(units * 100) / 100,
      };
    });
    const sellItems = sellOrders.map((si) => {
      const units = getSellEstimate(si?.instrument!, si?.enteredAmount!);
      return {
        item_id: si?.instrument?.asset?.id!,
        item_name: si?.instrument?.asset!.name,
        affiliation: 'Tillit',
        currency: 'GBP',
        item_brand: si?.instrument?.asset!.assetManager?.name,
        item_category: si?.instrument?.asset!.tags!.nodes![0],
        item_category2: si?.instrument?.asset!.tags!.nodes![1],
        item_category3: si?.instrument?.asset!.tags!.nodes![2],
        item_category4: si?.instrument?.asset!.tags!.nodes![3],
        item_category5: si?.instrument?.asset!.tags!.nodes![4],
        item_variant: selectedAccountType,
        price: si?.enteredAmount,
        quantity: Math.round(units * 100) / 100,
      };
    });

    return { buyItems, sellItems, totalValue };
  };

  const isSellOrderIssue = portfolioRebalancing.sellOrders?.some(
    (sellOrder) => {
      if (sellOrder.status === RebalancingTransactionStatus.Completed) {
        return false;
      }

      const instrumentPosition = selectedAccount.positions.find(
        (position) => position.instrument?.isin === sellOrder.instrument.isin
      );

      if (
        instrumentPosition === undefined ||
        instrumentPosition === null ||
        !instrumentPosition.quantity
      ) {
        return true;
      }

      return instrumentPosition.quantity < sellOrder.quantity;
    }
  );

  const handleConfirm = async () => {
    if (!portfolioRebalancing.id) {
      return;
    }

    const { buyItems, sellItems, totalValue } = getRebalancingEcommerceItems();

    try {
      await mutateAsync({
        input: {
          idempotencyToken: idempotencyToken,
          portfolioRebalancingId: portfolioRebalancing.id,
        },
      });

      setMode({ mode: 'resume' });

      trackGa({
        event: GaEventNames.checkoutEnd,
        flowType: activeFlow,
      });
      trackGa({
        event: GaEventNames.purchase,
        orderType: 'rebalancing',
        transaction_id: portfolioRebalancing.id!,
        affiliation: 'Tillit',
        value: totalValue,
        tax: 0,
        shipping: 0,
        currency: 'GBP', // USD, GBP etc
        ecommerce: {
          items: [...buyItems, ...sellItems],
        },
      });
      clear();
      onProceed();
    } catch {
      // error handed by state
    }
  };

  return (
    <StepContainer>
      <StepContent width={StepContentWidth.extraWide}>
        <IntroText />
      </StepContent>
      <WideSection>
        <StepSection>
          <H4>Cash</H4>
          {depositedAmount && (
            <TextSmall $noMargin>
              <b>Deposits made:</b> {format.currencyFull(depositedAmount)}
            </TextSmall>
          )}
          <TextSmall $noMargin>
            <b>Cash balance:</b> {format.currencyFull(availableCash)}
          </TextSmall>
        </StepSection>
        <StepSection>
          <OrderStatusTable
            portfolioRebalancing={portfolioRebalancing}
            selectedAccount={selectedAccount}
          />

          <EditOrderContainer>
            {!portfolioRebalancing.isQuickOrder &&
              portfolioRebalancing.status ===
                PortfolioRebalancingStatus.Waiting && (
                <CustomButtonV2
                  $size="normal"
                  $color="light"
                  $isWide={false}
                  disabled={editWaitingOrderIsLoading}
                  onClick={() => {
                    trackGa({
                      event: GaEventNames.checkoutEdit,
                      content_type: 'cta button',
                      item_id: 'edit order - order confirmation',
                      checkoutStep: DPCheckoutSteps.orderConfirmation,
                      checkoutFlow: activeFlow,
                    });
                    handleEditOrder();
                  }}
                >
                  Edit order
                </CustomButtonV2>
              )}
            {!portfolioRebalancing.isQuickOrder &&
              portfolioRebalancing.status !==
                PortfolioRebalancingStatus.Waiting && (
                <LinkCustomButton
                  to={generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      selectedAccountType
                    ),
                  })}
                  $color="light"
                  disabled={isLoading}
                  onClick={() => {
                    trackGa({
                      event: GaEventNames.checkoutEdit,
                      content_type: 'cta button',
                      item_id: 'edit order - order confirmation',
                      checkoutStep: DPCheckoutSteps.orderConfirmation,
                      checkoutFlow: activeFlow,
                    });
                  }}
                >
                  Edit order
                </LinkCustomButton>
              )}
          </EditOrderContainer>
        </StepSection>
      </WideSection>
      <StepActions>
        <ActionsText $textAlign={TextAlign.center}>
          The value of investments can go up and down, so you could get back
          less than you put in.{' '}
          {selectedAccountType === WrapperType.Isa &&
            "Tax treatment depends on an individual's circumstances and may be subject to change."}
        </ActionsText>
        {employment && <PolicyPreTradeText employment={employment} />}
        {editWaitingOrderIsError && (
          <AlertContainer>
            <Alert severity={Severity.error} padding="small">
              <Text $noMargin>
                Something went wrong, please wait and then try again.
              </Text>
            </Alert>
          </AlertContainer>
        )}
        {isSellOrderIssue && (
          <AlertContainer>
            <Alert severity={Severity.error} padding="small">
              <Text $noMargin>
                You have an invalid sell order, you need to{' '}
                <StyledA
                  as={Link}
                  to={generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      selectedAccountType
                    ),
                  })}
                >
                  edit your order
                </StyledA>{' '}
                to continue
              </Text>
            </Alert>
          </AlertContainer>
        )}
        <CustomButtonV2
          $color="primary"
          $size="normal"
          disabled={isLoading || isSellOrderIssue}
          onClick={async () => await handleConfirm()}
        >
          Confirm order
        </CustomButtonV2>
        {error && <div>Something went wrong</div>}
        {onBack &&
          [
            DpFlowTypes.buysAndSells,
            DpFlowTypes.buysOnly,
            DpFlowTypes.sellsOnly,
          ].includes(activeFlow) && <GoBackButton onClick={() => onBack()} />}
      </StepActions>
    </StepContainer>
  );
}
