import IconButton from '@material-ui/core/IconButton';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

const baseHeaderStyling = css`
  width: 100%;
  height: ${({ theme }) => theme.header.height};
  padding: 0 1.5rem;
`;

const baseShadow = css`
  box-shadow: 0px 6px 20px -10px rgba(0, 0, 0, 0.06);
`;

export const CloseIcon = styled.svg`
  margin-top: auto;
  width: 100%;
  height: calc(56px + 2rem);
  padding-top: 2rem;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: calc(72px + 2rem);
  }
`;

export const HeaderShimContainer = styled.div`
  ${baseHeaderStyling};
  span {
    width: 100%;
    display: block;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  opacity: 1;
  transform: translateY(0);
  background-color: ${colors.white};
  z-index: ${({ theme }) => theme.zIndex.topBar};
  transition: transform 0.25s ease-in-out 0s, opacity 0.25s ease-in-out 0s;
  ${baseHeaderStyling};
  ${baseShadow}

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 1.875rem;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 10px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 0 30px;
  }
`;

export const LogoContainer = styled.a`
  margin-right: auto;
  color: ${colors.black};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 8px;

  // TODO: potentially move this style to the main button styles as website buttons are now all like this
  button.small,
  a.small {
    padding: ${(p) => p.theme.spacing(0.875, 1.875)};
    border-radius: 5px;
    margin: 0 !important;
    font-size: 0.625rem;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      padding: ${(p) => p.theme.spacing(0.5, 3.75)};
      min-width: 160px;
      font-size: 0.875rem;
    }
  }
`;

export const NavToggle = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export interface MobileNavContainerProps {
  $open: boolean;
}

export const MobileNavContainer = styled.div<MobileNavContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7rem 1.5rem 5.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: ${transparentize(0.5, colors.richBlack)};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.topBar + 1};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 8rem 1.875rem 5.5rem 1.875rem;
  }

  ${(p) =>
    p.$open &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

MobileNavContainer.defaultProps = {
  $open: false,
};
