import { TimeHorizonTabs } from 'components/design-system/Tabs/TimeHorizonTabs';
import { AccountPerformanceGraph } from 'components/Graphs/AccountPerformance/AccountPerformanceGraph';
import { PlaceholderGraph } from 'components/Graphs/PlaceholderGraph';
import {
  PerformanceComparisonPeriod,
  useAccountsQuery,
} from 'generated/graphql';
import React, { useMemo, useState } from 'react';
import { SupportedWrapperType } from 'types/utilityTypes';
import {
  BlurredContainer,
  NoDataMessage,
  Overlay,
  PerformanceContainer,
} from './PerformanceSection.styles';

export interface PerformanceSectionProps {
  accountType: SupportedWrapperType;
}

export function PerformanceSection({ accountType }: PerformanceSectionProps) {
  const [performanceTimeHorizon, setPerformanceTimeHorizon] = useState(
    PerformanceComparisonPeriod.AllTime
  );

  const accountsQuery = useAccountsQuery();

  const currentAccount = useMemo(
    () =>
      accountsQuery.data?.accounts?.find(
        (acc) => acc.wrapperType === accountType
      ),
    [accountType, accountsQuery.data?.accounts]
  );

  const reportableTimeHorizons = currentAccount?.reportableTimeHorizons;

  // Don't use placeholder while still loading
  const hasData = (reportableTimeHorizons?.length ?? 1) > 0;

  const placeholderHorizons = useMemo(
    () => [
      {
        label: 'All time',
        value: PerformanceComparisonPeriod.AllTime,
      },
      {
        label: '5 years',
        value: PerformanceComparisonPeriod.FiveYears,
      },
      {
        label: '3 years',
        value: PerformanceComparisonPeriod.ThreeYears,
      },
      {
        label: '1 year',
        value: PerformanceComparisonPeriod.OneYear,
      },
      {
        label: '6 months',
        value: PerformanceComparisonPeriod.SixMonths,
      },
      {
        label: '3 months',
        value: PerformanceComparisonPeriod.ThreeMonths,
      },
    ],
    []
  );

  const handleChangeHorizon = (event: React.ChangeEvent<{}>, newValue: any) => {
    setPerformanceTimeHorizon(newValue);
  };

  return (
    <PerformanceContainer>
      {hasData ? (
        <>
          {reportableTimeHorizons && reportableTimeHorizons.length > 1 && (
            <TimeHorizonTabs
              trackingContext="dashboard - overview"
              activeValue={performanceTimeHorizon}
              onChange={handleChangeHorizon}
              tabs={reportableTimeHorizons || []}
            />
          )}

          <AccountPerformanceGraph
            accountId={currentAccount?.id!}
            period={performanceTimeHorizon}
          />
        </>
      ) : (
        <>
          <BlurredContainer>
            <TimeHorizonTabs
              trackingContext="dashboard - overview"
              activeValue={performanceTimeHorizon}
              onChange={() => {}}
              tabs={placeholderHorizons}
            />
            <PlaceholderGraph />
          </BlurredContainer>
          <Overlay>
            <NoDataMessage>
              There is not sufficient performance data available at this time.
              Please come back later.
            </NoDataMessage>
          </Overlay>
        </>
      )}
    </PerformanceContainer>
  );
}
