import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const FundCardHorizontalContainerInner = styled.div`
  position: relative;
  display: grid;
  align-items: start;
  height: 100%;
  padding: 1rem;
  gap: 0.5rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    position: relative;
    display: grid;
    align-items: start;
    height: 100%;
    grid-template-columns: 12rem auto 1fr;
    padding: 1rem;
    gap: 0.75rem;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: 0.25rem;
  text-align: left;
  background-color: ${colors.white};
  z-index: 1;

  ${(p) => p.theme.breakpoints.up('sm')} {
    gap: 0.5rem;
  }
`;

export const CardFundTagsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: solid ${colors['grey-100']};
  border-width: 1px 0;
  margin: 0 0 0.25rem 0;

  ${(p) => p.theme.breakpoints.up('sm')} {
    border: none;
    margin: 0;
  }
`;

export const CardFundTagsInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s max-height;
  padding: 0.125rem 0;

  & > * {
    margin: 0.25rem 0.25rem 0.25rem 0;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: flex;
    gap: 0.5rem;
    border-bottom: none;
    padding-bottom: 0;
    margin: 0;
    white-space: auto;
    flex-wrap: wrap;
    & > button {
      margin: 0;
    }
  }
`;

export const FundCardHeader = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const VerticalDivider = styled.hr`
  display: none;

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: block;
    height: 100%;
    border: none;
    border-left: solid 1px ${colors.grey};
    margin: 0;
  }
`;

export const OneLiner = styled(H6)`
  font-size: 1rem;
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1.25rem;
  }
`;
