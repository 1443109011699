import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { ChartDimensions } from 'hooks/useChartDimensions';
import _ from 'lodash';
import React, { useMemo } from 'react';

export type YAxisSize = 'normal' | 'large';

export interface YAxisProps {
  scale: d3.ScaleLinear<number, number>;
  graphDms: ChartDimensions;
  tickFormat?: (value: number, maxNumDecimals: number) => string;
  maxTicksHint?: number;
  textSize?: YAxisSize;
}

const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export function YAxis({
  scale,
  graphDms,
  tickFormat = (value) => value.toString(),
  maxTicksHint,
  textSize,
}: YAxisProps) {
  const ticks = useMemo(() => {
    const result = scale.ticks(maxTicksHint ? maxTicksHint : 7);
    const maxDecimals = _.max(result.map((x) => countDecimals(x))) ?? 0;

    return result.map((value) => ({
      value: tickFormat(value, maxDecimals),
      yOffset: scale(value),
    }));
  }, [maxTicksHint, scale, tickFormat]);

  return (
    <g transform={`translate(0, ${graphDms.marginTop})`}>
      {ticks.map(({ value, yOffset }) => (
        <g
          key={value}
          transform={`translate(${
            graphDms.marginLeft ? graphDms.marginLeft - 5 : 5
          }, ${yOffset})`}
        >
          <text
            style={{
              fontSize: textSize === 'large' ? '12px' : '8px',
              fontWeight: 300,
              color: colors.richBlack,
              textAnchor: 'end',
            }}
          >
            {value}
          </text>
        </g>
      ))}
    </g>
  );
}
