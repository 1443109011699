import { Grid, Typography } from '@material-ui/core';
import { MifidReportingNationalityQuery } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NationalIdentifierInput } from './NationalIdentifierInput';

export interface NationalIdentifierCollectionProps {
  name: string;
  reportingNationality?: MifidReportingNationalityQuery['mifidReportingNationality'];
  disabled?: boolean;
}

export function NationalIdentifierCollection({
  name,
  reportingNationality,
  disabled = false,
}: NationalIdentifierCollectionProps) {
  const [noMoreOptions, setNoMoreOptions] = useState(false);

  const { control, register, setValue, clearErrors } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const identifiers = useMemo(() => reportingNationality?.identifiers ?? [], [
    reportingNationality?.identifiers,
  ]);

  useEffect(() => {
    setNoMoreOptions(false);
  }, [identifiers]);

  const handleSkipChange = (itemName: string, skip: boolean, index: number) => {
    if (skip) {
      setValue(itemName + '.value', '');
      clearErrors(itemName);

      if (identifiers.length - 1 > index) {
        append({
          name: identifiers[index + 1].name,
          value: '',
        });
      } else {
        setNoMoreOptions(true);
      }
    } else {
      setNoMoreOptions(false);
      remove(_.range(index + 1, fields.length));
    }
  };

  return (
    <>
      {identifiers &&
        fields.map((item, index) =>
          item.name !== 'CONCAT' ? (
            <Grid key={item.id} item xs={12}>
              <NationalIdentifierInput
                itemName={name + `[${index}]`}
                label={identifiers[index].localeName}
                onSkipChange={(name, skip) =>
                  handleSkipChange(name, skip, index)
                }
                identifierName={item.name}
                isSkipped={index < fields.length - 1 || noMoreOptions}
                defaultValue={item.value}
                control={control}
                disabled={disabled}
              />
            </Grid>
          ) : (
            <input
              key={item.id}
              type="hidden"
              name={name + `[${index}].name`}
              ref={register()}
              defaultValue={item.name}
            />
          )
        )}
      {noMoreOptions && (
        <Grid item>
          <Typography>
            We can't open an investment account without one of the identifiers
            above.
          </Typography>
        </Grid>
      )}
    </>
  );
}
