/**
 * Define the different flows that users can go through
 *
 * These are all documented in notion.
 */
export enum DpFlowTypes {
  sellsOnly = 'sellOnly',
  buysOnly = 'buysOnly',
  buysOnlyInsufficientCash = 'buysOnlyInsufficientCash',
  buysAndSells = 'buysAndSells',
  buysAndSellsExpectedSufficientCash = 'buysAndSellsExpectedSufficientCash',
  buysAndSellsInsufficientCash = 'buysAndSellsInsufficientCash',
  returningToBuys = 'returningToBuys',
  returningSellsLessThanExpected = 'returningSellsLessThanExpected',
  returningToBuysInsufficientCash = 'returningToBuysInsufficientCash',
  isaLimitExceededWithBasket = 'isaLimitExceededWithBasket',
  invalidState = 'invalidState',
  openAccount = 'openAccount',
}
