import { Container } from '@material-ui/core';
import { StepContainer } from 'components/design-system/StepComponents/StepComponents';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ListContainerProps {
  $smallGap?: boolean;
}

export const ListContainer = styled.div<ListContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0;
    gap: ${({ $smallGap }) => ($smallGap ? '0.5rem' : '1.25rem')};
  }
`;

export const StepLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
`;

export const Section = styled.div`
  width: 100%;
  padding: 0 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 1280px;
    padding: 0 1.5rem;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: white;
  border-top: solid 1px ${colors['grey-800']};
  padding: 1rem 0;
`;

export const FooterLayout = styled.div`
  display: grid;

  grid-template-areas: 'values actions' 'goBack goBack';
  grid-template-columns: 1fr auto;
  padding: 0 1.5rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 1280px;
    padding: 0 1.5rem 0;
  }
`;

export const SummaryNumbers = styled.div``;

export const SelectFundsPercentagesStepContainer = styled(StepContainer)`
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  padding: 7rem 0 0;
  min-height: 100vh;
  gap: 1rem;
  align-items: stretch;
  justify-items: center;
  justify-content: stretch;
  overflow: hidden;
  form {
    display: contents;
  }
`;

export const OverAllocatedMsg = styled.div`
  ${Text} {
    color: ${colors.danger};
  }
`;

export const NoFundsSelectedWrapper = styled.div`
  margin-top: 1rem;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

export const FundsActions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

interface ManageDepositButtonProps {
  $needsAttention?: boolean;
}

export const ManageDepositButton = styled.button<ManageDepositButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
  color: ${({ $needsAttention }) =>
    $needsAttention ? colors['danger'] : 'inherit'};
`;

interface ManageDepositLinkProps {
  $needsAttention?: boolean;
}

export const ManageDepositLink = styled(Link)<ManageDepositLinkProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 300;

  ${Text} &, ${Text} &:visited, ${Text} &:hover, ${Text} &:focus {
    text-decoration: underline;
    color: ${({ $needsAttention }) =>
      $needsAttention ? colors['danger'] : 'inherit'};
  }
`;

export const HeaderWrapper = styled.div`
  padding: 0 1rem;
`;

export const TabsWrapper = styled.div`
  width: 100%;
`;

export const MyFundsContainer = styled(Container)`
  padding: 0 1.5rem;
`;
