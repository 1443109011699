import * as format from 'formatting';
import { HoldingsByIsin, Instrument } from 'generated/graphql';

export function processInstrumentHoldings(instrument?: Instrument) {
  const source = getHoldingSource(instrument);
  if (!source) {
    return {
      isValid: false,
      holdingsAttributionText: '',
    };
  }
  let holdingsAttributionText;
  let sectorAndRegionAttributionText;

  const date = getAttributionDate(source, instrument);
  const isValid = isDefined(date);
  const attributionDate = format.date(date);

  switch (source) {
    case 'tumelo':
      holdingsAttributionText = `Holding data supplied by Tumelo as of ${attributionDate}.`;
      sectorAndRegionAttributionText = `Geographic and sector breakdown supplied by FE fundinfo, retrieved on ${attributionDate}.`;
      break;
    case 'tumelofeblend':
      holdingsAttributionText = `Holding data provided by FE fundinfo and Tumelo, retrieved on ${attributionDate}.`;
      sectorAndRegionAttributionText = `Geographic and sector breakdown supplied by FE fundinfo, retrieved on ${attributionDate}.`;
      break;
    case 'tillit': {
      const supplementedByTumelo = instrument?.holdings?.nodes?.some(
        (x) => x.logoUrl
      );

      const tumeloAttribution = supplementedByTumelo
        ? 'supplemented by Tumelo, '
        : '';

      holdingsAttributionText = `Holding data provided via the asset manager's own reports and TILLIT's analysis, ${tumeloAttribution} as of ${attributionDate}.`;
      break;
    }
    default:
      holdingsAttributionText = `Data provided by FE fundinfo, retrieved on ${attributionDate}.`;
      break;
  }

  return {
    holdingsAttributionText,
    sectorAndRegionAttributionText,
    isValid,
  };
}

function getAttributionDate(source: string, instrument?: Instrument) {
  switch (source) {
    case 'tumelo':
    case 'tillit':
      return getMaximumDate(instrument);
    default:
      return instrument?.lastFeSyncUtc;
  }
}

function getMaximumDate(instrument?: Instrument) {
  const dates =
    instrument?.holdings?.nodes
      ?.filter((x) => x.asOfDate)
      .map((x: HoldingsByIsin) => new Date(x.asOfDate).getTime()) ?? [];

  const maxDate = Math.max(...dates);

  if (Number.isNaN(maxDate)) {
    return undefined;
  }

  return new Date(maxDate);
}

function getHoldingSource(instrument?: Instrument) {
  return instrument?.holdings?.nodes?.[0]?.source;
}

function isDefined(date: any) {
  return date !== undefined && date !== null;
}
