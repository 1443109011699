import styled, { css } from 'styled-components';

export interface ButtonsWrapperProps {
  $isHorizontal?: boolean;
  $stretchButtons?: boolean;
}

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-row-gap: ${(p) => p.theme.spacing(1.25)}px;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    ${(p) =>
      p.$isHorizontal &&
      css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: ${(p) => p.theme.spacing(1.25)}px;

        ${p.$stretchButtons &&
        css`
          a,
          button {
            flex-basis: calc(50% - ${(p) => p.theme.spacing(1.25)}px);
          }
        `}
      `}
  }
`;

ButtonsWrapper.defaultProps = {
  $isHorizontal: false,
  $stretchButtons: true,
};
