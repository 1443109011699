import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { H5 } from 'components/design-system/Heading/Heading';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import type { GetAssetGroupString } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/breakdownGroupingHelpers';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { generateDynamicPortfolioConstructionPath } from 'paths';
import { useEffect, useState } from 'react';
import { deriveAssetBreakdownComparison } from '../../helpers/deriveAssetBreakdownComparison';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import {
  useCombinedBasketBreakdown,
  useGetCurrentBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  DrillDownContainer,
  DrillDownHeader,
  NoBreakdownMessageEl,
  StackedBarChartKeyContainer,
  StackedBarComparisonContainer,
} from '../InteractiveBreakdown.styles';
import {
  Breakdown,
  StackedBarChart,
  StackedBarChartKey,
} from '../_shared/StackedBarChart';
import { BreakdownTab, GroupingOption } from './BreakdownTab';
import { HoldingsTab } from './HoldingsTab';

const NoBreakdownMessage = () => {
  return (
    <NoBreakdownMessageEl>
      <H5 $noMargin>Want to visualise your portfolio?</H5>
      <TextSmall>
        Add funds to your account to see a breakdown of your portfolio.
      </TextSmall>
    </NoBreakdownMessageEl>
  );
};

interface BreakdownStackedBarComparisonProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  getAssetGroupString: GetAssetGroupString;
  groupings: (displayMoreInfo: string) => GroupingOption[];
  isInteractive: boolean;
  variant?: 'full' | 'compact';
  onMouseEnterBarChart?: () => void;
  onMouseLeaveBarChart?: () => void;
  onClickBarChart?: (breakdown: string | null) => void;
  isBarChartHovered?: boolean;
  onChange?: (open: boolean) => void;
  onGetColor: (name: string, key: number) => { name: string; color: string };
}

export const BasketAllocation = ({
  selectedAccountType,
  selectedAccountId,
  getAssetGroupString,
  groupings,
  isInteractive,
  variant = 'full',
  onMouseEnterBarChart,
  onMouseLeaveBarChart,
  isBarChartHovered,
  onChange,
  onGetColor,
}: BreakdownStackedBarComparisonProps) => {
  const [displayCombinedBreakdown, setDisplayCombinedBreakdown] = useState<
    string | null
  >(null);

  const { basketSummary } = useFundsBasket({ selectedAccountId });

  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    getAssetGroupString
  );
  const { combinedBreakdown } = useCombinedBasketBreakdown(
    selectedAccountId,
    selectedAccountType,
    getAssetGroupString
  );

  const breakdownItemColors = combinedBreakdown.map((item, key) => {
    return onGetColor(item.name, key);
  });

  const breakdownComparison = deriveAssetBreakdownComparison(
    breakdowns,
    combinedBreakdown
  );
  const activeCombinedBreakdownComparison = breakdownComparison.find(
    ({ text }) => text === displayCombinedBreakdown
  );

  useEffect(() => {
    if (basketSummary.orderCount === 0) {
      setDisplayCombinedBreakdown(null);
      onChange?.(false);
    }
  }, [basketSummary.orderCount, onChange]);

  if (breakdowns.length === 0 && combinedBreakdown.length === 0) {
    return <NoBreakdownMessage />;
  }

  const handleActiveBreakDownChange = ({ name }: Breakdown) => {
    if (displayCombinedBreakdown === name) {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'interactive breakdown - basket allocation',
        item_id: 'unselect',
      });
      setDisplayCombinedBreakdown(null);
      onChange?.(false);
    } else {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'interactive breakdown - basket allocation',
        item_id: name,
      });
      setDisplayCombinedBreakdown(name);
      onChange?.(true);
    }
  };

  return (
    <>
      <StackedBarComparisonContainer>
        {combinedBreakdown.length > 0 && (
          <div>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Estimated new allocation
            </TextSmall>
            <StackedBarChart
              breakdowns={combinedBreakdown}
              breakdownItemColors={breakdownItemColors}
              disabled={basketSummary.orderCount === 0}
              onClick={isInteractive ? handleActiveBreakDownChange : undefined}
              onMouseEnter={onMouseEnterBarChart}
              onMouseLeave={onMouseLeaveBarChart}
              isHovered={isBarChartHovered}
              activeBreakdown={displayCombinedBreakdown}
            />
            {variant === 'full' ? (
              <StackedBarChartKey
                variant={variant}
                breakdownItemColors={breakdownItemColors}
              />
            ) : (
              <StackedBarChartKeyContainer>
                <StackedBarChartKey
                  variant={variant}
                  breakdownItemColors={breakdownItemColors}
                />
                <LinkCustomButton
                  $size="extraSmall"
                  $color="light"
                  $isWide={false}
                  to={generateDynamicPortfolioConstructionPath({
                    wrapperType: getPathSegmentForWrapperType(
                      selectedAccountType
                    ),
                  })}
                >
                  See more details
                </LinkCustomButton>
              </StackedBarChartKeyContainer>
            )}
          </div>
        )}
      </StackedBarComparisonContainer>
      {displayCombinedBreakdown &&
        displayCombinedBreakdown === 'Cash' &&
        activeCombinedBreakdownComparison && (
          <DrillDownContainer>
            <DrillDownHeader>
              <TextNormal $noMargin $fontWeight={FontWeight.normal}>
                {activeCombinedBreakdownComparison.text}
              </TextNormal>
              <TextNormal $noMargin>
                {format.percent(activeCombinedBreakdownComparison.end / 100)} |{' '}
                {format.currencyFull(activeCombinedBreakdownComparison.amount)}
              </TextNormal>
            </DrillDownHeader>
          </DrillDownContainer>
        )}
      {displayCombinedBreakdown &&
        displayCombinedBreakdown !== 'Cash' &&
        activeCombinedBreakdownComparison && (
          <DrillDownContainer>
            <Tabs
              title={
                <DrillDownHeader>
                  <TextNormal $noMargin>
                    {activeCombinedBreakdownComparison.text}
                  </TextNormal>
                </DrillDownHeader>
              }
              onClick={(tabTitle) => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type:
                    'interactive breakdown - basket allocation - drill down tab',
                  item_id: tabTitle,
                });
              }}
              tabs={[
                {
                  title: 'Holdings',
                  content: (
                    <HoldingsTab
                      activeBreakdownComparison={
                        activeCombinedBreakdownComparison
                      }
                      selectedAccountId={selectedAccountId}
                      selectedAccountType={selectedAccountType}
                      includeAsset={(asset) =>
                        getAssetGroupString(asset) === displayCombinedBreakdown
                      }
                    />
                  ),
                },
                {
                  title: 'Breakdown',
                  content: (
                    <BreakdownTab
                      groupings={groupings(displayCombinedBreakdown)}
                      activeBreakdownComparison={
                        activeCombinedBreakdownComparison
                      }
                      selectedAccountType={selectedAccountType}
                      selectedAccountId={selectedAccountId}
                      includeAsset={(asset) =>
                        getAssetGroupString(asset) === displayCombinedBreakdown
                      }
                    />
                  ),
                },
              ]}
            />
          </DrillDownContainer>
        )}
    </>
  );
};
