import { StyledLink } from 'components/design-system/Link';
import { useAuth } from 'context/AuthContext';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { rootPath } from 'paths';
import { Redirect } from 'react-router-dom';
import { Description, Heading, Wrapper } from 'theme/shared/InfoPage.styles';

export function SignedOut() {
  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);

  const { signedIn } = useAuth();

  if (signedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Heading>Signed out</Heading>
      <Description>You have been signed out successfully.</Description>
      <StyledLink href={rootPath}>Return to homepage</StyledLink>
    </Wrapper>
  );
}
