import { DepositForm } from 'components/Dialogs/Deposit/DepositForm';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { QueryState } from 'components/QueryState';
import { AccountType, useIllustrationQuery } from 'generated/graphql';
import { openAccountPath } from 'paths';
import styled from 'styled-components';

interface AddCashStepProps {
  accountId: string;
  onSkipStep: () => void;
  paymentId?: string;
  accountType?: AccountType;
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 40rem;
  .MuiDialogContent-root {
    padding: 0.5rem 0;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 0.5rem 0;
    flex-direction: column;
  }
  .trueLayer__selectProviderStep.MuiDialogContent-root {
    padding: 0.5rem 1rem;
  }
`;

export function AddCashStep({
  accountId,
  onSkipStep,
  paymentId,
  accountType,
}: AddCashStepProps) {
  const returnPath = process.env.REACT_APP_BASE_URL + openAccountPath;

  const IllustrationQuery = useIllustrationQuery(undefined, {
    enabled: accountType === AccountType.Sipp,
  });

  return (
    <QueryState {...IllustrationQuery}>
      {(Illustration) => {
        return (
          <Wrapper>
            {accountId && (
              <DepositForm
                accountId={accountId}
                variant={DepositFormVariant.NewAccount}
                returnPath={returnPath}
                onSkipStep={onSkipStep}
                urlPaymentId={paymentId}
                defaultValue={
                  Illustration.data?.illustration?.initialPayment?.amount ||
                  null
                }
              />
            )}
          </Wrapper>
        );
      }}
    </QueryState>
  );
}
