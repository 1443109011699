import {
  TextLarge,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { WrapperType } from 'generated/graphql';
import styled from 'styled-components';

import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import { GrClose, GrFormDown } from 'react-icons/gr';

export const Heading = styled(H6)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.3rem;
  }

  span {
    font-family: ${({ theme }) => theme.typography.bodyFontFamily};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const Expand = styled(GrFormDown)`
  cursor: pointer;
  path {
    stroke: ${colors.richBlue};
  }
  height: 24px;
  width: 24px;
`;

export const Close = styled(GrClose)`
  cursor: pointer;
  path {
    stroke: ${colors.richBlue};
  }
  height: 16px;
  width: 26px;
`;

export const DynamicPortfolioConstructionListWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const DynamicPortfolioConstructionDetailLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto 24px;
  grid-template-areas: 'account orderStatus orderStatus' 'mainDetails mainDetails action' 'orderCounts orderCounts orderCounts';
  gap: 0.5rem;
  flex-direction: row;
  text-align: left;
  align-items: center;
  cursor: pointer;

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: 'account mainDetails orderStatus action';
    grid-template-columns: 5rem 1fr auto auto;
    gap: 1.5rem;
  }
`;

export const DynamicPortfolioConstructionDetailTableContainer = styled.div`
  padding: 3rem 0 2rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  ${(p) => p.theme.breakpoints.down('sm')} {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    > * {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -0.5rem;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: ${colors['grey-400']};
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
`;

export const MobileInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 0.5rem;
  grid-area: orderCounts;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }

  div:nth-last-child(1),
  div:nth-last-child(2) {
    flex-basis: calc(50% - 0.25rem);
  }
`;

export const OrderStatus = styled(TextLarge)`
  font-weight: 500;
  color: ${colors.magenta};
  margin: 0;
  grid-area: orderStatus;
`;

export const MobileOrderStatus = styled(TextNormal)`
  margin: 0;
  grid-area: orderStatus;
  text-align: right;
  font-weight: 500;
  color: ${colors.magenta};
`;

interface AccountPillProps {
  wrapperType: WrapperType;
}
export const AccountPill = styled(TextSmall)<AccountPillProps>`
  background: ${({ wrapperType }) =>
    wrapperType === WrapperType.Isa
      ? colors['magenta-50']
      : colors['purple-50']};
  color: ${({ wrapperType }) =>
    wrapperType === WrapperType.Isa ? colors.magenta : colors.purple};
  font-weight: 500;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  margin: 0;
  grid-area: account;
  justify-self: center;
`;

export const DetailsContainer = styled.div`
  grid-area: mainDetails;
`;

export const Action = styled.div`
  grid-area: action;

  ${(p) => p.theme.breakpoints.down('sm')} {
    align-self: baseline;
  }
`;

export const ActionsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  max-width: 40rem;
  margin-top: 2rem;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 2rem auto 0;
  }
`;

export const LargeCard = styled(Card)`
  flex-basis: 100%;
  text-align: center;
  padding: 0.5rem;
`;

export const SmallCard = styled(Card)`
  flex-grow: 1;
  text-align: center;
  padding: 0.5rem;
`;

export const SupportAction = styled.button`
  display: inline;
  background: none;
  border: none;
  color: ${colors.magenta};
  text-decoration: none;
  padding: 0;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: underline;
  }
`;
