import {
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { Controller, UseFormMethods, useFormContext } from 'react-hook-form';
import { UnionToIntersection } from 'type-fest';

type RegisterFunction = UnionToIntersection<UseFormMethods['register']>;

export interface Option {
  value: string;
  label: string;
}

export interface SelectProps extends MuiSelectProps {
  name: string;
  options: Option[];
  register?: RegisterFunction;
}

export function Select({
  name,
  options,
  inputProps,
  register,
  defaultValue,
  ...other
}: SelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value, name, ref }, { invalid }) => (
        <MuiSelect
          id={name}
          name={name}
          labelId={`${name}-label`}
          error={!!invalid}
          fullWidth
          inputRef={ref}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          {...other}
          MenuProps={{
            PaperProps: {
              elevation: 4,
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    />
  );
}
