import { ReactComponent as TillitLogoSVGnoPad } from 'assets/logo/TillitLogo_nopad.svg';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';
import { StepContainer } from '../../../../design-system/StepComponents/StepComponents';

export const ReferralWelcomeContainer = styled(StepContainer)`
  gap: 3rem;
`;

export const ReferralCoBrandingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 0.8fr auto;
  background-color: ${colors.white};
  border-radius: 5px;
  align-items: center;
  justify-items: center;
  font-size: 3rem;
  padding: 1rem 2rem;
  gap: 1rem;
`;

const logoCss = css`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 81px;
    width: auto;
  }
`;

export const TillitLogo = styled(TillitLogoSVGnoPad)`
  ${logoCss}
`;

export const CoBrandedLogo = styled.img`
  ${logoCss}
`;

export const ReferralActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 1.25rem;
`;
