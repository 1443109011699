import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import React from 'react';
import { StyledTitle } from '../styles/Deposit.styles';
import {
  CustomButtonV2,
  ButtonWrapper,
} from 'components/design-system/Button/CustomButtonV2';

export interface CancelledStepProps {
  onContactSupport: () => void;
  onStartAgain: () => void;
}

export function CancelledStep({
  onContactSupport,
  onStartAgain,
}: CancelledStepProps) {
  return (
    <>
      <StyledTitle disableTypography>
        <Typography variant="subtitle1">
          Your payment has been cancelled
        </Typography>
      </StyledTitle>
      <DialogContent>
        <Typography>No money has left your account.</Typography>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper $align="center">
          <CustomButtonV2
            onClick={() => onContactSupport()}
            $color="secondary"
            fullWidth
          >
            Contact support
          </CustomButtonV2>
          <CustomButtonV2
            onClick={() => onStartAgain()}
            $color="primary"
            fullWidth
          >
            Start again
          </CustomButtonV2>
        </ButtonWrapper>
      </DialogActions>
    </>
  );
}
