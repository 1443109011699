import { FormHelperText } from '@material-ui/core';
import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import type { FieldError } from 'react-hook-form';
import {
  AmountInputContainer,
  AmountInputInput,
  AmountInputLabel,
} from './AmountInput.styles';

export interface AmountInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  ref?: React.ForwardedRef<HTMLInputElement>;
  id: string;
  helpText?: string;
  isLarge?: boolean;
  error?: FieldError;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, AmountInputProps> = (
  { helpText, isLarge, error, name, disabled, ...props },
  ref
) => {
  return (
    <>
      <AmountInputContainer>
        <AmountInputLabel htmlFor={props.id} $isLarge={isLarge}>
          £
        </AmountInputLabel>
        <AmountInputInput
          type="text"
          inputMode="numeric"
          pattern="[\d\.]*"
          ref={ref}
          {...props}
          id={props.id}
          name={name}
          disabled={disabled}
          $isLarge={isLarge}
        />
      </AmountInputContainer>

      {error && (
        <FormHelperText
          id={name + '-helper-text'}
          error={true}
          disabled={disabled}
        >
          {error.message}
        </FormHelperText>
      )}
      {helpText && (
        <FormHelperText id={name + '-helper-text'} disabled={disabled}>
          {helpText}
        </FormHelperText>
      )}
    </>
  );
};

export const AmountInput = React.forwardRef(Input);
