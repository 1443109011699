import { H5, H6Bold } from 'components/design-system/Heading/Heading';
import {
  FontStyle,
  TextAlign,
  TextSmall,
} from 'components/design-system/Text/Text';
import {
  Toggle,
  ToggleColorOptions,
} from 'components/design-system/Toggle/Toggle';
import type { GetAssetGroupString } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/breakdownGroupingHelpers';
import { assetClassColors, colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import {
  getAssetClassOfAsset,
  getRegionOfAsset,
  useCombinedBasketBreakdown,
  useGetCurrentBreakdown,
} from '../hooks/useGetBreakdowns';
import { BasketAllocation } from './BasketAllocation';
import { GroupingOption } from './BasketAllocation/BreakdownTab';
import { CurrentAllocation } from './CurrentAllocation';
import {
  ChartTypeSelectorContainer,
  HeadingCardInner,
  NoBreakdownMessageEl,
} from './InteractiveBreakdown.styles';
import { RebalancingAllocation } from './RebalancingAllocation';

const colorLookup = [
  colors['magenta-600'],
  colors['magenta-400'],
  colors['magenta-200'],
  colors['magenta-700'],
  colors['magenta-500'],
  colors['magenta-300'],
  colors['magenta-100'],
  colors['magenta-800'],
  colors['magenta-50'],
  colors.magenta,
];

const regionSortOrder: string[] = [
  'Global',
  'UK',
  'US',
  'Europe',
  'Japan',
  'Asia',
  'Asia ex-Japan',
  'Emerging markets',
  'Frontier markets',
  'UK, Europe',
];

export const nameColorLookup: Record<string, string> = {
  ...assetClassColors,
  Unknown: colors.richBlack,
};

const NoBreakdownMessage = () => {
  return (
    <NoBreakdownMessageEl>
      <H5 $noMargin>Want to visualise your portfolio?</H5>
      <TextSmall>
        Add funds to your account to see a breakdown of your portfolio.
      </TextSmall>
    </NoBreakdownMessageEl>
  );
};

interface BreakdownStackedBarComparisonProps {
  selectedAccountType: WrapperType;
  getAssetGroupString: GetAssetGroupString;
  groupings: (displayMoreInfo: string) => GroupingOption[];
  isCheckout: boolean;
  selectedAccountId: string;
  portfolioRebalancingId?: string;
  isInteractive: boolean;
  variant?: 'full' | 'compact';
}

export const BreakdownStackedBarComparison = ({
  selectedAccountType,
  getAssetGroupString,
  groupings,
  isCheckout,
  selectedAccountId,
  portfolioRebalancingId,
  isInteractive,
  variant = 'full',
}: BreakdownStackedBarComparisonProps) => {
  const [barChartHovered, setBarChartHovered] = useState(false);
  const [currentBreakDownOpen, setCurrentBreakDownOpen] = useState(false);
  const [basketBreakDownOpen, setBasketBreakDownOpen] = useState(false);

  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    getAssetGroupString
  );
  const { combinedBreakdown } = useCombinedBasketBreakdown(
    selectedAccountId,
    selectedAccountType,
    getAssetGroupString
  );

  const handleMouseEnterBarChart = () => {
    setBarChartHovered(true);
  };

  const handleMouseLeaveBarChart = () => {
    setBarChartHovered(false);
  };

  const handleCurrentBreakdownChange = (open: boolean) => {
    setCurrentBreakDownOpen(open);
  };

  const handleBasketBreakdownChange = (open: boolean) => {
    setBasketBreakDownOpen(open);
  };

  const regionColorLookup = useMemo(() => {
    if (breakdowns.length && combinedBreakdown.length) {
      return _(breakdowns)
        .map((b) => b.name)
        .unionBy(combinedBreakdown.map((b) => b.name))
        .filter((b) => b !== 'Cash' && b !== 'Unknown')
        .sortBy((name) => regionSortOrder.indexOf(name))
        .map((name, index) => ({
          name,
          color: colorLookup[index % colorLookup.length],
        }))
        .keyBy(({ name }) => name)
        .mapValues(({ color }) => color)
        .value();
    }
    return {};
  }, [breakdowns, combinedBreakdown]);

  if (breakdowns.length === 0 && combinedBreakdown.length === 0) {
    return <NoBreakdownMessage />;
  }

  const handleGetColor = (name: string, index: number) => {
    return {
      name,
      color:
        nameColorLookup[name] ||
        regionColorLookup[name] ||
        colorLookup[index % colorLookup.length],
    };
  };

  return (
    <>
      <CurrentAllocation
        selectedAccountType={selectedAccountType}
        getAssetGroupString={getAssetGroupString}
        groupings={groupings}
        isInteractive={isInteractive}
        onMouseEnterBarChart={handleMouseEnterBarChart}
        onMouseLeaveBarChart={handleMouseLeaveBarChart}
        isBarChartHovered={barChartHovered || basketBreakDownOpen}
        onChange={handleCurrentBreakdownChange}
        onGetColor={handleGetColor}
      />
      {isCheckout ? (
        <RebalancingAllocation
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
          getAssetGroupString={getAssetGroupString}
          groupings={groupings}
          portfolioRebalancingId={portfolioRebalancingId!}
          isInteractive={isInteractive}
        />
      ) : (
        <BasketAllocation
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
          getAssetGroupString={getAssetGroupString}
          groupings={groupings}
          isInteractive={isInteractive}
          variant={variant}
          onMouseEnterBarChart={handleMouseEnterBarChart}
          onMouseLeaveBarChart={handleMouseLeaveBarChart}
          isBarChartHovered={barChartHovered || currentBreakDownOpen}
          onChange={handleBasketBreakdownChange}
          onGetColor={handleGetColor}
        />
      )}
      <TextSmall
        $noMargin
        $textAlign={TextAlign.center}
        $fontStyle={FontStyle.italic}
      >
        Please note that the 'Current allocation' does not take into account
        pending orders or cash movements.
      </TextSmall>
    </>
  );
};

interface InteractiveBreakdownProps {
  selectedAccountId: string;
  selectedAccountType: WrapperType;
  isCheckout?: boolean;
  portfolioRebalancingId?: string;
  isInteractive?: boolean;
  variant?: 'full' | 'compact';
}

export const InteractiveBreakdown = ({
  selectedAccountType,
  isCheckout = false,
  selectedAccountId,
  portfolioRebalancingId,
  isInteractive = true,
  variant = 'full',
}: InteractiveBreakdownProps) => {
  const [activeBreakdown, setActiveBreakdown] = useState('assetClass');

  return (
    <>
      <ChartTypeSelectorContainer $variant={variant}>
        <Toggle
          onClick={(value) => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'interactive breakdown - change breakdown',
              item_id: value,
            });
            setActiveBreakdown(value);
          }}
          $color={ToggleColorOptions.secondary}
          options={[
            { value: 'assetClass', label: 'Asset class' },
            { value: 'region', label: 'Region' },
          ]}
          value={activeBreakdown}
        />
      </ChartTypeSelectorContainer>

      <HeadingCardInner $variant={variant}>
        {variant === 'compact' && (
          <H6Bold $noMargin>Your {selectedAccountType} allocation</H6Bold>
        )}
        {activeBreakdown === 'assetClass' && (
          <BreakdownStackedBarComparison
            isInteractive={isInteractive}
            selectedAccountType={selectedAccountType}
            getAssetGroupString={getAssetClassOfAsset}
            selectedAccountId={selectedAccountId}
            variant={variant}
            groupings={(displayMoreInfo: string) => {
              const groupings: GroupingOption[] = [
                { value: 'region', label: 'Region' },
              ];
              // if (displayMoreInfo === 'Equities') {
              //   groupings.push({ value: 'equityStyle', label: 'Equity Style' });
              // }
              // if (displayMoreInfo === 'Bonds') {
              //   groupings.push({ value: 'bondStyle', label: 'Bond Style' });
              // }
              return groupings;
            }}
            isCheckout={isCheckout}
            portfolioRebalancingId={portfolioRebalancingId}
          />
        )}
        {activeBreakdown === 'region' && (
          <BreakdownStackedBarComparison
            isInteractive={isInteractive}
            selectedAccountType={selectedAccountType}
            getAssetGroupString={getRegionOfAsset}
            selectedAccountId={selectedAccountId}
            groupings={() => [{ value: 'assetClass', label: 'Asset class' }]}
            isCheckout={isCheckout}
            portfolioRebalancingId={portfolioRebalancingId}
            variant={variant}
          />
        )}
      </HeadingCardInner>
    </>
  );
};
