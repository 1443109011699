import { Option } from 'components/Form/Select';
import { SelectField, SelectFieldProps } from 'components/Form/SelectField';
import { BeneficiaryRelationship } from 'generated/graphql';

export interface RelationshipFieldProps
  extends Omit<SelectFieldProps, 'options'> {}

export function RelationshipField(props: RelationshipFieldProps) {
  const relationshipOptions: Option[] = [
    { value: BeneficiaryRelationship.Husband, label: 'Husband' },
    { value: BeneficiaryRelationship.Wife, label: 'Wife' },
    { value: BeneficiaryRelationship.Partner, label: 'Partner' },
    { value: BeneficiaryRelationship.Daughter, label: 'Daughter' },
    { value: BeneficiaryRelationship.Son, label: 'Son' },
    { value: BeneficiaryRelationship.Father, label: 'Father' },
    { value: BeneficiaryRelationship.Mother, label: 'Mother' },
    { value: BeneficiaryRelationship.Charity, label: 'Charity' },
    { value: BeneficiaryRelationship.Other, label: 'Other' },
  ];

  return <SelectField {...props} options={relationshipOptions} />;
}
