import { TextNormal } from 'components/design-system/Text/Text';
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const ActionsText = styled(TextNormal)`
  max-width: 640px;
  text-align: center;
`;
