import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { MaskedField } from 'components/Form/MaskedField';

import { generateFormattedInput } from 'components/Form/FormattedInput';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { mask } from 'formatting';
import {
  AccountType,
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';

const definitions = {
  '0': /[0-9]|\*/,
};
const SortCodeFormattedInputWithMask = generateFormattedInput({
  mask: '00-00-00',
  definitions,
});

const SortCodeFormattedInput = generateFormattedInput({ mask: '00-00-00' });

const BankAccountFormattedInputWithMask = generateFormattedInput({
  mask: '00000000',
  definitions,
});

const BankAccountFormattedInput = generateFormattedInput({ mask: '00000000' });

const bankAccountSchema = Yup.object().shape({
  accountNumber: Yup.object().shape({
    isMasked: Yup.boolean(),
    value: Yup.string().when('isMasked', {
      is: false,
      then: (s) =>
        s
          .label('Account number')
          .required()
          .matches(/^\d{8}$/, 'Must be a valid bank account number'),
    }),
  }),

  sortCode: Yup.object().shape({
    isMasked: Yup.boolean(),
    value: Yup.string().when('isMasked', {
      is: false,
      then: (s) =>
        s
          .label('Sort code')
          .required()
          .matches(/^\d{2}-?\d{2}-?\d{2}$/, 'Must be a valid sort code'),
    }),
  }),
});

type BankAccountFormInputs = Yup.TypeOf<typeof bankAccountSchema>;
type BankAccountFormValues = Yup.Asserts<typeof bankAccountSchema>;

interface BankAccountStepProps extends StepProps {
  accountType: AccountType;
}

export function BankAccountStep({
  onProceed,
  onGoBack,
  accountType,
}: BankAccountStepProps) {
  const userProfileQuery = useUserProfileQuery();
  const queryClient = useQueryClient();
  const userProfile = userProfileQuery.data?.userProfile;

  const hasEmployer =
    (userProfileQuery.data?.userProfile?.employments || []).length > 0;

  const defaultValues: BankAccountFormInputs = bankAccountSchema.cast({
    accountNumber: {
      isMasked: !!userProfile?.bankAccountTokenised?.accountNumberToken
        ?.maskedValue,
      value:
        userProfile?.bankAccountTokenised?.accountNumberToken?.maskedValue ??
        '',
    },
    sortCode: {
      isMasked: !!userProfile?.bankAccountTokenised?.sortCodeToken?.maskedValue,
      value:
        userProfile?.bankAccountTokenised?.sortCodeToken?.maskedValue ?? '',
    },
  });

  const validationSchema = bankAccountSchema;

  const { mutateAsync, isError } = useUpdateUserProfileMutation();

  const onSubmit = async (data: BankAccountFormValues, isDirty: boolean) => {
    if (isDirty) {
      await mutateAsync(
        {
          input: {
            bankAccount: {
              accountNumber: data.accountNumber.value!,
              sortCode: data.sortCode.value!.replace(/-/g, ''),
            },
          },
        },
        {
          onSuccess: (_, { input }) => {
            queryClient.setQueryData<UserProfileQuery>(
              useUserProfileQuery.getKey(),
              (oldData) => {
                return {
                  userProfile: {
                    ...oldData?.userProfile!,
                    bankAccountTokenised: {
                      accountNumberToken: {
                        maskedValue: mask(input.bankAccount?.accountNumber!),
                      },
                      sortCodeToken: {
                        maskedValue: mask(input.bankAccount?.sortCode!),
                      },
                    },
                  },
                };
              }
            );
          },
        }
      );

      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.bankDetails,
      });
    }

    onProceed();
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <StepContainer>
      <Form<BankAccountFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={validationSchema}
      >
        <StepContent width={StepContentWidth.wide}>
          <StepTitle>Bank details</StepTitle>

          <StepIntroduction mb={2} $width={StepIntroductionWidth.xxWide}>
            {accountType === AccountType.Sipp && hasEmployer && (
              <StepIntroductionTypography>
                Please add your personal bank details for any ad hoc deposits.
              </StepIntroductionTypography>
            )}
            {accountType === AccountType.Sipp && !hasEmployer && (
              <StepIntroductionTypography>
                Enter the details for the bank account you will use to fund your
                TILLIT Pension.
              </StepIntroductionTypography>
            )}
            {accountType !== AccountType.Sipp && (
              <StepIntroductionTypography>
                Enter the account number and sort code for the bank account you
                wish to use to add cash to your TILLIT{' '}
                {getNameForAccountType(accountType)}. Please note that for
                regulatory and security reasons, any withdrawals will also be
                made to this account.
              </StepIntroductionTypography>
            )}
          </StepIntroduction>

          <ServerError isVisible={isError} />

          <StepFormContainer>
            <MaskedField
              id="accountNumber"
              name="accountNumber"
              label="Account number"
              defaultValue={defaultValues.accountNumber.value}
              autoFocus
              inputComponent={
                defaultValues.sortCode.value
                  ? BankAccountFormattedInputWithMask
                  : BankAccountFormattedInput
              }
            />
            <MaskedField
              id="sortCode"
              name="sortCode"
              label="Sort code"
              defaultValue={defaultValues.sortCode.value}
              inputComponent={
                defaultValues.sortCode.value
                  ? SortCodeFormattedInputWithMask
                  : SortCodeFormattedInput
              }
            />
          </StepFormContainer>
        </StepContent>

        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>
                <GoBackButton onClick={handleBack} disabled={isSubmitting} />
              </>
            )}
          </FormState>
        </StepActions>
      </Form>
    </StepContainer>
  );
}
