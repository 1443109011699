import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  H3,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import {
  StepActions,
  StepContainer,
  StepContent,
} from '../../../../design-system/StepComponents/StepComponents';

interface GeneralErrorProps {}

export function GeneralError({ ...props }: GeneralErrorProps) {
  const { showNewMessages } = useIntercom();

  const handleOnSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'dynamic portfolio construction - something is not right',
    });
    showNewMessages(
      "I'm having trouble placing an order from the dynamic portfolio construction tool."
    );
  };

  return (
    <div>
      <StepContainer {...props}>
        <StepContent>
          <H3 $textAlign={HeadingAlign.center}>Something is not right</H3>
          <Text $textAlign={TextAlign.center} $noMargin={true}>
            Some text that manages the user's expectations around what they can
            do{' '}
          </Text>
        </StepContent>
        <StepActions>
          <CustomButtonV2
            $size="normal"
            $color="secondary"
            $isWide
            onClick={handleOnSupport}
          >
            Talk to support
          </CustomButtonV2>
        </StepActions>
      </StepContainer>
    </div>
  );
}
