import { StyledLink } from 'components/design-system/Link';
import styled from 'styled-components';

export const SetupAutoInvestButton = styled(StyledLink)`
  background: none;
  border: none;
  padding: 0;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;
