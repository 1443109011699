import { datadogLogs } from '@datadog/browser-logs';
import { CookiePreferences } from '@tillitinvest/cookie-consent';
import { StarRating } from 'components/Rating/StarRating/StarRating';
import { Card } from 'components/design-system/Card/Card';
import { H5 } from 'components/design-system/Heading/Heading';
import { FontWeight, TextSmall } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { useRateInteractionMutation } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import {
  CloseButton,
  PortfolioBuilderRatingContainer,
} from './PortfolioBuilderRating.style';

interface PortfolioBuilderRatingProps {
  basketCount: number;
}

export const PortfolioBuilderRating = ({
  basketCount,
}: PortfolioBuilderRatingProps) => {
  const { signedIn } = useAuth();
  const { mutate, error } = useRateInteractionMutation({
    onError: () => {
      datadogLogs.logger.error('Rating submission failed', { error, rating });
    },
  });

  const cookie = Cookies.getJSON('cookieconsent_status_v2');
  const trackingAllowed: CookiePreferences =
    cookie?.allowPerformanceCookies ?? false;
  const hasRatedPortfolioBuilder =
    Cookies.get('has-rated-portfolio-builder') === 'true';

  const isEligible =
    basketCount >= 2 && trackingAllowed && !hasRatedPortfolioBuilder;

  const [rating, setRating] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(isEligible);
  const [
    hasTrackedFeedbackOpportunity,
    setHasTrackedFeedbackOpportunity,
  ] = useState<boolean>(isEligible);

  useEffect(() => {
    const isEligible =
      basketCount >= 2 && trackingAllowed && !hasRatedPortfolioBuilder;
    setIsOpen(isEligible);
  }, [basketCount, trackingAllowed, hasRatedPortfolioBuilder]);

  useEffect(() => {
    if (isOpen && !hasTrackedFeedbackOpportunity) {
      setHasTrackedFeedbackOpportunity(true);
      trackGa({
        event: GaEventNames.feedbackOpportunity,
        item_id: 'portfolio-builder',
      });
    }
  }, [isOpen, hasTrackedFeedbackOpportunity, hasRatedPortfolioBuilder]);

  if (!isOpen) {
    return null;
  }

  return (
    <PortfolioBuilderRatingContainer>
      <Card>
        {rating === null && (
          <>
            <H5>We’d love your feedback</H5>
            <TextSmall $fontWeight={FontWeight.normal}>
              On a scale of 1-5, how would you rate your experience using our
              portfolio builder tool?
            </TextSmall>
            <StarRating
              name="portfolio-builder"
              value={rating}
              readOnly={!!rating}
              onSelection={(newRating) => {
                if (newRating === null) {
                  return;
                }
                setRating(newRating);
                trackGa({
                  event: GaEventNames.feedback,
                  item_id: 'portfolio-builder',
                  value: newRating,
                });
                if (signedIn) {
                  mutate({
                    input: {
                      interactionName: 'portfolio-builder',
                      rating: newRating,
                    },
                  });
                }
                setTimeout(() => {
                  Cookies.set('has-rated-portfolio-builder', 'true', {
                    expires: 365,
                  });
                  setIsOpen(false);
                }, 2000);
              }}
            />
            <CloseButton
              aria-label="Close dialog"
              onClick={() => {
                setIsOpen(false);
                Cookies.set('has-rated-portfolio-builder', 'true', {
                  expires: 7,
                });
              }}
            >
              <HiX />
            </CloseButton>
          </>
        )}
        {rating !== null && (
          <>
            <H5>Thanks for the feedback</H5>
            <TextSmall $fontWeight={FontWeight.normal} $noMargin>
              If you have anymore feedback please get in touch via chat
            </TextSmall>
          </>
        )}
      </Card>
    </PortfolioBuilderRatingContainer>
  );
};
