import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { TextNormal } from 'components/design-system/Text/Text';
import { Basket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import {
  AccountType,
  useCreatePortfolioRebalancingMutation,
} from 'generated/graphql';
import {
  dashboardGiaPath,
  dashboardIsaPath,
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import {
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

interface CheckoutStepProps {
  accountId: string;
  accountType: AccountType;
}

export function CheckoutStep({ accountId, accountType }: CheckoutStepProps) {
  const history = useHistory();
  const createPortfolioRebalancingMutationQuery = useCreatePortfolioRebalancingMutation();
  const {
    mutateAsync: createPortfolioRebalancingMutation,
    error: createPortfolioRebalancingError,
  } = createPortfolioRebalancingMutationQuery;
  const [basketLs, setBasketLs] = useLocalStorage<Basket>(
    'nonAccountBasket',
    {}
  );

  const createPortfolioRebalancing = async () => {
    try {
      const basketBuyOrders = basketLs.unknown?.basketBuyOrders || [];
      const res = await createPortfolioRebalancingMutation({
        input: {
          accountId: accountId,
          buyOrders: basketBuyOrders.map((fund) => ({
            amount: fund.amount,
            isin: fund.isin,
          })),
          sellOrders: [],
        },
      });
      setBasketLs({
        ...basketLs,
        unknown: undefined,
      });

      return res.createPortfolioRebalancing.id;
    } catch {
      // error handled by state
    }
  };

  const handleCheckout = async () => {
    try {
      const selectedRebalancingId = await createPortfolioRebalancing();
      if (!selectedRebalancingId) {
        return;
      }
      const path = generateCheckoutPath({
        wrapperType: `new-${accountType.toLowerCase()}`,
        selectedRebalancingId,
      });
      history.push(path);
    } catch {
      // error handled by state
    }
  };

  const handleViewBasket = async () => {
    try {
      const selectedRebalancingId = await createPortfolioRebalancing();
      if (!selectedRebalancingId) {
        return;
      }
      const path = generateDynamicPortfolioConstructionBasketPath({
        wrapperType: accountType.toLowerCase(),
        selectedRebalancingId,
      });
      history.push(path);
    } catch {
      // error handled by state
    }
  };

  const handleViewDashboard = () => {
    const path =
      accountType === AccountType.Isa ? dashboardIsaPath : dashboardGiaPath;
    history.push(path);
  };

  return (
    <StepContainer>
      <StepContent width={StepContentWidth.wide}>
        <StepTitle>You have orders in your basket</StepTitle>

        <StepIntroduction mb={2}>
          <StepIntroductionTypography>
            Your orders are waiting for you in your basket. Why not check out
            now?
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepActions $isHorizontal>
        <StepButton
          onClick={() => {
            handleCheckout();
          }}
          className="richBlue"
        >
          Checkout
        </StepButton>
        <StepButton
          onClick={() => {
            handleViewBasket();
          }}
          className="magenta"
        >
          View basket
        </StepButton>
      </StepActions>
      {createPortfolioRebalancingError && (
        <div>
          <Alert severity={Severity.error}>
            <TextNormal $noMargin>Something went wrong</TextNormal>
          </Alert>
        </div>
      )}
      <StepActions>
        <SkipButton
          variant="outlined"
          className="richBlue"
          onClick={() => {
            handleViewDashboard();
          }}
        >
          Dashboard
        </SkipButton>
      </StepActions>
    </StepContainer>
  );
}
