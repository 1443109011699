import { QueryState } from 'components/QueryState';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import {
  H6,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import {
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text } from 'components/design-system/Text/Text';
import {
  ActionStatus,
  useActionsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  ActionsQueryAction,
  UserProfileQueryClientSummaryAccounts,
} from 'types/graphqlTypes';
import { actionSpecLookUp } from '../actionSpecLookUp';
import {
  CardLayout,
  CheckedIcon,
  ListLayout,
  UncheckedIcon,
} from './ActionsList.styles';

export interface ActionsListProps {
  actions: ActionsQueryAction[];
  accounts: UserProfileQueryClientSummaryAccounts;
}

export function ActionsList({ actions, accounts }: ActionsListProps) {
  return (
    <ListLayout>
      {actions.map((action) => {
        const actionSpec = actionSpecLookUp[action.type];
        if (!actionSpec || actionSpec === undefined) {
          return null;
        }
        const account = accounts.find(({ id }) => id === action.accountId);

        const { title, description, prompt, path } = actionSpec;
        return (
          <Card>
            <CardLayout>
              {action.status === ActionStatus.Active ? (
                <UncheckedIcon />
              ) : (
                <CheckedIcon />
              )}
              <H6 $noMargin>{title}</H6>
              <Text $noMargin>{description}</Text>
              {action.status === ActionStatus.Active && path && (
                <LinkCustomButton
                  to={
                    typeof path === 'function'
                      ? path(account!.wrapperType)
                      : path
                  }
                  $size="small"
                >
                  {prompt}
                </LinkCustomButton>
              )}
            </CardLayout>
          </Card>
        );
      })}
    </ListLayout>
  );
}

export function ActionsListStep() {
  const actionsQuery = useActionsQuery();
  const userProfile = useUserProfileQuery();
  return (
    <StepContainer>
      <StepContent>
        <StepTitle $textAlign={HeadingAlign.center}>
          Congratulations,
          <br />
          your TILLIT Pension is now ready!
        </StepTitle>
        <StepIntroduction mb={2}>
          <StepIntroductionTypography>
            Choose how to fund your new account or browse the TILLIT Universe of
            funds.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepContent width={StepContentWidth.extraWide}>
        <QueryState {...userProfile}>
          {() => (
            <QueryState {...actionsQuery}>
              {() =>
                userProfile.data?.clientSummary?.accounts &&
                actionsQuery.data?.actions && (
                  <ActionsList
                    actions={actionsQuery.data.actions}
                    accounts={userProfile.data.clientSummary.accounts}
                  />
                )
              }
            </QueryState>
          )}
        </QueryState>
      </StepContent>
    </StepContainer>
  );
}
