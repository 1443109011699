import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { StartTransferForm } from 'components/feature/Transfers/startTransferForm';
import { WrapperType } from 'generated/graphql';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { useParams } from 'react-router-dom';

export function StartTransferPage() {
  const theme = useTheme();
  const params = useParams<{ accountType: 'gia' | 'isa' | 'pension' }>();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  const lookup = {
    gia: WrapperType.Gia,
    isa: WrapperType.Isa,
    pension: WrapperType.Sipp,
  };

  return (
    <StepFormWrapper>
      <StepWrapper>
        <StartTransferForm
          wrapperType={lookup[params.accountType]}
          source="startTransferStep"
        />
      </StepWrapper>
    </StepFormWrapper>
  );
}
