import { ReactComponent as TillitIconSVG } from 'assets/logo/TillitIcon.svg';
import { ReactComponent as TillitLogoSVG } from 'assets/logo/TillitLogo.svg';
import { ReactComponent as TillitLogoSVGCompact } from 'assets/logo/TillitLogo_compact.svg';
import { ReactComponent as TillitLogoSVGnoPad } from 'assets/logo/TillitLogo_nopad.svg';

export interface LogoHeaderProps {
  size?: number;
  pad?: boolean;
  icon?: boolean;
}

export interface LogoProps {
  color?: string;
  size?: number;
  pad?: boolean;
  icon?: boolean;
  compact?: boolean;
  tooltip?: string;
}

export function HeaderLogo({
  size = 1,
  pad = true,
  icon = false,
  compact = false,
  tooltip,
}: LogoProps) {
  const defaultTitle = `TILLIT ${icon ? 'Icon' : 'Logo'}`;
  const props = {
    title: `${tooltip ? tooltip : defaultTitle}`,
    style: {
      maxWidth: size * 70,
      width: '100%',
      height: 'auto',
      fill: 'var(--main-logo-color)',
    },
  };

  return icon ? (
    <TillitIconSVG {...props} />
  ) : pad ? (
    <TillitLogoSVG {...props} />
  ) : compact ? (
    <TillitLogoSVGCompact {...props} />
  ) : (
    <TillitLogoSVGnoPad {...props} />
  );
}

export function Logo({
  color = 'currentColor',
  size = 1,
  pad = true,
  icon = false,
}: LogoProps) {
  const props = {
    title: `TILLIT ${icon ? 'Icon' : 'Logo'}`,
    style: {
      maxWidth: size * 70,
      width: '100%',
      height: 'auto',
      fill: color,
    },
  };

  return icon ? (
    <TillitIconSVG {...props} />
  ) : pad ? (
    <TillitLogoSVG {...props} />
  ) : (
    <TillitLogoSVGnoPad {...props} />
  );
}
