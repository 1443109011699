import { Pagination as MuiPagination } from '@material-ui/lab';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { PaginationAlignment, PaginationAlignmentType } from './Pagination';

export const StyledPagination = styled(MuiPagination)`
  padding-top: 1rem;

  .MuiPaginationItem-page.Mui-selected {
    background-color: ${colors.white};
    border: 1px solid ${colors['magenta-200']};
    color: magenta;
  }

  .MuiPaginationItem-page:hover,
  .MuiPaginationItem-page.Mui-focusVisible {
    background-color: ${colors['magenta-50']};
  }
`;

export interface StyledPaginationWrapperProps {
  $align: PaginationAlignmentType;
}

export const StyledPaginationWrapper = styled.div<StyledPaginationWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => PaginationAlignment[$align]};
`;
