import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { MyFundsList } from 'components/feature/PortfolioBuilder/MyFundsList/MyFundsList';
import { AddFundToRecurringOrderDialog } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddToFundToRecurringOrder';
import { useUserProfileQuery } from 'generated/graphql';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import type {
  AccountsQueryAccount,
  AccountsQueryAccountPositionInstrument,
} from 'types/graphqlTypes';
import * as Yup from 'yup';
import { useAutoSaveInvestState } from '../../AutoSaveInvestContext';
import { ControlsLayout } from '../OrderTab/SelectFundsPercentagesCard.styles';
import type { RegularOrderLineFormValues } from '../types';

export const MyFundsContainer = styled(Container)`
  padding: 0;
`;

interface MyFundsActionProps {
  instrument: AccountsQueryAccountPositionInstrument;
}

const regularOrderLineSchema = Yup.object().shape({
  amount: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Amount')
    .when('included', {
      is: 'true',
      then: Yup.number().min(0.01, 'The amount must be more than 0').required(),
    }),
  percentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Percentage')
    .when('included', {
      is: 'true',
      then: Yup.number()
        .min(0.001, 'The amount must be more than 0.001')
        .required(),
    }),
});

const MyFundsAction = ({ instrument }: MyFundsActionProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { state, setState, totalDepositAmount } = useAutoSaveInvestState();
  const existingOrder = state?.orders?.find(
    (order) => order.isin === instrument.isin
  );
  const { handleSubmit } = useForm<RegularOrderLineFormValues>({
    resolver: yupResolver(regularOrderLineSchema),
    mode: 'onBlur',
    defaultValues: {
      isin: instrument.isin,
      amount: existingOrder?.proportion
        ? `${totalDepositAmount * (existingOrder?.proportion ?? 0)}`
        : '',
      percentage: existingOrder?.proportion
        ? `${existingOrder?.proportion * 100}`
        : '',
    },
  });

  const onSubmit = (data: RegularOrderLineFormValues) => {
    const newLine = {
      isin: instrument.isin,
      proportion: parseFloat(data.percentage) / 100,
      name: instrument.name,
      isDeleted: false,
      isEdited: !!existingOrder,
      isNew: !existingOrder,
    };

    const newRecurringTransactionsOrders =
      state?.orders?.filter(({ isin }) => isin !== instrument.isin) ?? [];

    setState({
      ...state,
      orders: [newLine, ...newRecurringTransactionsOrders],
    });
  };

  return (
    <>
      {dialogOpen && instrument.asset?.id && (
        <AddFundToRecurringOrderDialog
          selectedAssetId={instrument.asset?.id}
          selectedIsin={instrument.isin}
          open
          closeDialog={() => setDialogOpen(false)}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlsLayout>
          <CustomButtonV2
            $size="extraSmall"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {existingOrder ? 'Update order' : 'Add to order'}
          </CustomButtonV2>
        </ControlsLayout>
      </form>
    </>
  );
};

export interface MyFundsTabProps {
  account: AccountsQueryAccount;
}

export function MyFundsTab({ account }: MyFundsTabProps) {
  const userProfileQuery = useUserProfileQuery();

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        return (
          <MyFundsContainer>
            <MyFundsList
              selectedAccountType={account.wrapperType}
              selectedAccountId={account.id || undefined}
              action={MyFundsAction}
            />
          </MyFundsContainer>
        );
      }}
    </QueryState>
  );
}
