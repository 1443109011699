import React from 'react';
import FELogo from 'assets/FE_Logo.png';
import { Container, Copy, Logo } from './Styles/PoweredBy.style';

export function PoweredBy() {
  return (
    <Container>
      <Copy>Powered by FE fundinfo </Copy>
      <Logo src={FELogo} />
    </Container>
  );
}
