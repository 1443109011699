import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import {
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import styled from 'styled-components';

export interface UsPersonsDefinitionDialogProps extends ResponsiveDialogProps {}

const Content = styled.div``;

export function UsPersonsDefinitionDialog({
  onClose,
  ...props
}: UsPersonsDefinitionDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <Content>
        <StepTitle>US Persons</StepTitle>

        <StepIntroductionTypography textAlign="left">
          TILLIT cannot serve customers who are covered by FATCA reporting
          requirements.
        </StepIntroductionTypography>
        <StepIntroductionTypography textAlign="left">
          The US Internal Revenue Service (IRS) places regulatory reporting
          requirements on financial institutions serving 'US Persons' through
          the Foreign Account Tax Compliance Act, or FATCA.
        </StepIntroductionTypography>
        <StepIntroductionTypography textAlign="left">
          'US Persons' include all US citizens, permanent legal residents or
          'green card' holders, and persons born in the US. This also includes
          dual nationals where one of the nationalities is the US as well as
          people who have had a 'substantial presence' in the country over the
          past three years.
        </StepIntroductionTypography>
        <StepIntroductionTypography textAlign="left">
          If you are unsure if you may be covered you should seek independent
          tax advice.
        </StepIntroductionTypography>
        <ButtonWrapper>
          <CustomButtonV2
            type="button"
            $color="secondary"
            onClick={() => onClose?.()}
            fullWidth
          >
            Close
          </CustomButtonV2>
        </ButtonWrapper>
      </Content>
    </ResponsiveDialog>
  );
}
