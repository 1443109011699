import { Typography, TypographyProps } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

interface ContentWrapperProps {
  $featured: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${(p) =>
      p.$featured &&
      css`
        grid-column: 1 / -1;
      `}
    display: grid;
    grid-template-rows: min-content auto;
  }
`;

export const CopyContent = styled.div`
  position: relative;
  color: ${colors.richBlack};
  font-weight: 300;
  font-size: 1rem;

  p {
    margin: 0 0 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;
    margin: 0;
    padding: 0;
    font-style: italic;
  }
`;

interface TitleProps extends TypographyProps {
  $featured?: boolean;
}

export const Title = styled(Typography)<TitleProps>`
  position: relative;
  align-self: flex-end;
  font-weight: 300;
  font-family: ${(p) => p.theme.typography.headingFontFamily};
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: ${colors.darkBlue};
  ${(p) =>
    p.$featured &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 32px;
        height: 1px;
        bottom: -5px;
        background-color: ${colors.magenta};
        opacity: 0.3;
      }
    `}
`;

Title.defaultProps = {
  $featured: false,
};

export const BlurredCopy = styled(Typography).attrs({
  component: 'div',
})`
  filter: blur(4px);
  font-size: 1rem;
  opacity: 0.4;
  margin: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 8px 0 32px 0;
  }
`;
