import {
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  EligibleAssetsDialog,
  useDialog,
} from 'components/feature/Transfers/startTransferForm/informationDialogs/EligibleAssetsDialog/EligibleAssetsDialog';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { StyledSpanLink } from '../_shared/StyledSpanLink';

interface TransferHoldingsCopyProps {
  wrapperType: WrapperType;
  cedingProviderName: string | null;
}

export const AboutInspecieTransfer = ({
  cedingProviderName,
  wrapperType,
}: TransferHoldingsCopyProps) => {
  const dialogProps = useDialog();

  return (
    <>
      <EligibleAssetsDialog {...dialogProps} />
      <StepTitle>Transfer my holdings</StepTitle>
      <StepText>
        <StepIntroductionTypography>
          Transferring your existing holdings without selling them down to cash
          is called an ‘in specie’ transfer. By transferring in specie,{' '}
          {cedingProviderName} will transfer all{' '}
          <StyledSpanLink onClick={() => dialogProps.openDialog()}>
            eligible holdings
          </StyledSpanLink>{' '}
          and any uninvested cash to TILLIT. Any ineligible holdings will be
          sold down to cash and will be transferred along with your eligible
          holdings.
        </StepIntroductionTypography>
        <StepIntroductionTypography>
          An in specie transfer typically takes 4-8 weeks to complete, depending
          on your current provider and the complexity of your account. Our
          Transfer Concierge team will keep you in the loop every step of the
          way.
        </StepIntroductionTypography>
      </StepText>
    </>
  );
};
