import { SubTitle } from '../../Cards.styles';

export interface ContentProps {
  subTitle?: boolean;
}

export function SIPPInterestContentRegistered() {
  return (
    <>
      <SubTitle>Registered</SubTitle>
      <p>Thank you for registering your interest in a TILLIT Pension.</p>
      <p>
        We have added you to the list and will make sure you are one of the
        first to get access when they are ready!
      </p>
    </>
  );
}

export function SIPPInterestContentUnregisteredCard({
  subTitle = false,
}: ContentProps) {
  return (
    <>
      {subTitle && <SubTitle>What is a SIPP?</SubTitle>}
      <p>
        A SIPP is a Self-Invested Personal Pension. It's a type of pension plan
        which gives the holder full control to pick and manage the investments.
        In short, it's a DIY pension.
      </p>
    </>
  );
}

export function SIPPInterestContentUnregisteredSection() {
  return (
    <>
      <p>
        A SIPP is a Self-Invested Personal Pension. It's a type of pension plan
        which gives the holder full control to pick and manage the investments.
        In short, it's a DIY pension.
      </p>
      <p>
        We're working on getting the TILLIT Pension ready. If you'd like to get
        updates on our progress (and early access when they're live!), let us
        know by clicking this button.
      </p>
    </>
  );
}
