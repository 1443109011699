import useMediaQuery from '@material-ui/core/useMediaQuery';
import lHex1 from 'assets/hexagon-assets/large_hex_1.png';
import lHex2 from 'assets/hexagon-assets/large_hex_2.png';
import lHex3 from 'assets/hexagon-assets/large_hex_3.png';
import lHex4 from 'assets/hexagon-assets/large_hex_4.png';
import lHex5 from 'assets/hexagon-assets/large_hex_5.png';
import lHex6 from 'assets/hexagon-assets/large_hex_6.png';
import sHex1 from 'assets/hexagon-assets/small_hex_1.png';
import sHex2 from 'assets/hexagon-assets/small_hex_2.png';
import sHex3 from 'assets/hexagon-assets/small_hex_3.png';
import sHex4 from 'assets/hexagon-assets/small_hex_4.png';
import sHex5 from 'assets/hexagon-assets/small_hex_5.png';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import { dynamicPortfolioConstructionBasePath } from 'paths';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LandingCgiDialog, LandingMenuDialog } from './LandingMenuDialog';
import { LandingMenuCGIData, LandingMenuData } from './MenuData';
import { MobileSliders } from './MobileSlider';
import {
  BigHex,
  CatTitle,
  ClassAsset,
  Container,
  CtaHexagon,
  FocusAsset,
  HexaCopyContainer,
  MHex1,
  MHex2,
  MHex3,
  MHex4,
  MHex5,
  MHex6,
  MHex7,
  MHex8,
  MobileCatContainer,
  RegionAsset,
  SmallHex,
  StructureAsset,
} from './Style/LandingMenu.style';

type ClickData = {
  title: string;
  content: JSX.Element;
  url: string;
  tags: string;
  cat: string;
};

type DataType = {
  subtitle: string;
  content: JSX.Element;
};

type ImgClickData = {
  title: string;
  background: string;
  data: Array<DataType>;
};

export interface LandingMenuProps {
  activeCat: string;
  customPath?: string;
  handleSetFocus?: (once?: boolean) => void;
}

interface HexaCopyProps {
  content: string;
  isActive?: boolean;
  width?: number;
  height?: number;
}

function HexaCopy({
  content,
  isActive = false,
  width = 150,
  height = 77,
}: HexaCopyProps) {
  const ID = content.replaceAll(' ', '-').toLowerCase();
  const left = (200 - width) / 2;
  const top = (177 - height) / 2;

  return (
    <foreignObject id={ID} x={left} y={top} width={width} height={height}>
      <HexaCopyContainer $isActive={isActive}>{content}</HexaCopyContainer>
    </foreignObject>
  );
}

export function LandingMenu({
  handleSetFocus,
  activeCat,
  customPath,
}: LandingMenuProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();

  const { showModal } = useModal();

  const getClass = (cat: string) => {
    if (activeCat === '') {
      return '';
    } else {
      return activeCat === cat ? 'active' : 'inactive';
    }
  };

  const isActive = (cat: string) => activeCat === cat;

  function handleHover() {
    handleSetFocus?.(true);
  }

  function handleClick(data: ClickData) {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'tillit universe',
      item_id: `hexagon clicked - ${data.title}`,
    });

    showModal(LandingMenuDialog, {
      title: data.title,
      body: data.content,
      url: customPath ? customPath + data.tags : data.url,
    });
  }

  function handleAssetAllocationCtaClick() {
    history.push(dynamicPortfolioConstructionBasePath);
  }

  function handleImgClick(data: ImgClickData) {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'landing page',
      item_id: `hexagon image clicked - ${data.title}`,
    });

    showModal(LandingCgiDialog, {
      title: data.title,
      background: data.background,
      data: data.data,
    });
  }

  function handleEnter(cat: string, event: any) {
    if (cat !== activeCat) {
      event?.target.parentNode.classList.remove('inactive');
    }
  }

  function handleExit(cat: string, event: any) {
    if (cat !== activeCat && activeCat !== '') {
      event?.target.parentNode.classList.add('inactive');
    }
  }

  return (
    <Container>
      {(() => {
        if (matches) {
          return (
            <svg
              viewBox="0 0 1243 1034"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={handleHover}
            >
              <defs></defs>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="fund-landing" transform="translate(0.577350, 0.897460)">
                  <g id="images" transform="translate(30.422650, 15.102540)">
                    <g
                      id="hexa-img-large"
                      transform="translate(416.000000, 58.000000)"
                    >
                      <BigHex
                        id="large_hex_1"
                        delay={0}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex1}
                        onClick={() =>
                          handleImgClick(LandingMenuCGIData.empower)
                        }
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-large"
                      transform="translate(118.000000, 401.000000)"
                    >
                      <BigHex
                        id="large_hex_2"
                        delay={0.2}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex2}
                        onClick={() => handleImgClick(LandingMenuCGIData.trust)}
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-large"
                      transform="translate(416.000000, 573.000000)"
                    >
                      <BigHex
                        id="large_hex_3"
                        delay={0.4}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex3}
                        onClick={() => handleImgClick(LandingMenuCGIData.open)}
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-large"
                      transform="translate(714.000000, 401.000000)"
                    >
                      <BigHex
                        id="large_hex_4"
                        delay={0.6}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex4}
                        onClick={() =>
                          handleImgClick(LandingMenuCGIData.simple)
                        }
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-large"
                      transform="translate(1012.000000, 229.000000)"
                    >
                      <BigHex
                        id="large_hex_5"
                        delay={0.8}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex5}
                        onClick={() =>
                          handleImgClick(LandingMenuCGIData.curated)
                        }
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-large"
                      transform="translate(864.000000, 660.000000)"
                    >
                      <BigHex
                        id="large_hex_6"
                        delay={1}
                        x="0"
                        y="0"
                        width="198.85"
                        height="198.85"
                        xlinkHref={lHex6}
                        onClick={() =>
                          handleImgClick(LandingMenuCGIData.transparent)
                        }
                        className={getClass('largeHex')}
                      ></BigHex>
                    </g>
                    <g
                      id="hexa-img-small"
                      transform="translate(298.000000, 0.000000)"
                    >
                      <SmallHex
                        id="small_hex_1"
                        delay={0}
                        x="1.23236532e-10"
                        y="5.68434189e-14"
                        width="140"
                        height="140"
                        xlinkHref={sHex1}
                        className={getClass('smallHex')}
                      ></SmallHex>
                    </g>
                    <g
                      id="hexa-img-small"
                      transform="translate(893.000000, 0.000000)"
                    >
                      <SmallHex
                        id="small_hex_2"
                        delay={0.2}
                        x="0"
                        y="-5.68434189e-14"
                        width="140"
                        height="140"
                        xlinkHref={sHex2}
                        className={getClass('smallHex')}
                      ></SmallHex>
                    </g>
                    <g
                      id="hexa-img-small"
                      transform="translate(0.000000, 173.000000)"
                    >
                      <SmallHex
                        id="small_hex_3"
                        delay={0.4}
                        x="1.23236532e-10"
                        y="-5.68434189e-14"
                        width="140"
                        height="140"
                        xlinkHref={sHex3}
                        className={getClass('smallHex')}
                      ></SmallHex>
                    </g>
                    <g
                      id="hexa-img-small"
                      transform="translate(149.000000, 775.000000)"
                    >
                      <SmallHex
                        id="small_hex_4"
                        delay={0.6}
                        x="2.84217094e-14"
                        y="0"
                        width="140"
                        height="140"
                        xlinkHref={sHex4}
                        className={getClass('smallHex')}
                      ></SmallHex>
                    </g>
                    <g
                      id="hexa-img-small"
                      transform="translate(593.000000, 861.000000)"
                    >
                      <SmallHex
                        id="small_hex_5"
                        delay={0.8}
                        x="0"
                        y="-1.13686838e-13"
                        width="140"
                        height="140"
                        xlinkHref={sHex5}
                        className={getClass('smallHex')}
                      ></SmallHex>
                    </g>
                  </g>
                </g>
                <g id="menu" fillRule="nonzero" transform="translate(0, 1)">
                  <StructureAsset
                    id="etf"
                    transform="translate(298.000000, 688.000000)"
                    delay={3}
                    className={getClass(LandingMenuData.etf.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.etf);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.etf.cat, e)
                    }
                    onMouseLeave={(e) => handleExit(LandingMenuData.etf.cat, e)}
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.etf.cat)}
                      content={'ETF'}
                    />
                  </StructureAsset>
                  <FocusAsset
                    id="technology"
                    transform="translate(596.000000, 344.000000)"
                    delay={2.4}
                    className={getClass(LandingMenuData.technology.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.technology);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.technology.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.technology.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.technology.cat)}
                      content={'Technology'}
                    />
                  </FocusAsset>
                  <FocusAsset
                    id="value"
                    transform="translate(745.000000, 86.000000)"
                    delay={2.4}
                    className={getClass(LandingMenuData.value.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.value);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.value.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.value.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.value.cat)}
                      content={'Value'}
                    />
                  </FocusAsset>
                  <CtaHexagon
                    id="cta-portfolio-builder"
                    transform="translate(0.000000, -0.000000)"
                    delay={0.4}
                    className={getClass(LandingMenuData.multiAsset.cat)}
                    onClick={() => {
                      handleAssetAllocationCtaClick();
                    }}
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.multiAsset.cat)}
                      content={'Try our Portfolio builder'}
                      height={90}
                      width={130}
                    />
                  </CtaHexagon>
                  <RegionAsset
                    id="asia"
                    transform="translate(894.000000, 344.000000)"
                    delay={1.6}
                    className={getClass(LandingMenuData.asia.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.asia);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.asia.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.asia.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.asia.cat)}
                      content={'Asia'}
                    />
                  </RegionAsset>
                  <RegionAsset
                    id="wildcards"
                    transform="translate(149.000000, 86.000000)"
                    delay={0.8}
                    className={getClass(LandingMenuData.wildcards.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.wildcards);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.wildcards.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.wildcards.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.wildcards.cat)}
                      content={'Wildcards'}
                    />
                  </RegionAsset>
                  <FocusAsset
                    id="healthcare"
                    transform="translate(-0.000000, 344.000000)"
                    delay={1.4}
                    className={getClass(LandingMenuData.healthcare.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.healthcare);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.healthcare.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.healthcare.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.healthcare.cat)}
                      content={'Healthcare'}
                    />
                  </FocusAsset>
                  <FocusAsset
                    id="growth"
                    transform="translate(1043.000000, 86.000000)"
                    delay={2.2}
                    className={getClass(LandingMenuData.growth.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.growth);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.growth.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.growth.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.growth.cat)}
                      content={'Growth'}
                    />
                  </FocusAsset>
                  <RegionAsset
                    id="emerging-markets"
                    transform="translate(298.000000, 516.000000)"
                    delay={2}
                    className={getClass(LandingMenuData.emergingMarkets.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.emergingMarkets);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.emergingMarkets.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.emergingMarkets.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.emergingMarkets.cat)}
                      content={'Emerging markets'}
                    />
                  </RegionAsset>
                  <FocusAsset
                    id="concentrated"
                    transform="translate(596.000000, 516.000000)"
                    delay={2.8}
                    className={getClass(LandingMenuData.concentrated.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.concentrated);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.concentrated.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.concentrated.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.concentrated.cat)}
                      content={'Concentrated'}
                    />
                  </FocusAsset>
                  <FocusAsset
                    id="Active"
                    transform="translate(596.000000, 0.000000)"
                    delay={2.0}
                    className={getClass(LandingMenuData.active.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.active);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.active.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.active.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.active.cat)}
                      content={'Active'}
                    />
                  </FocusAsset>
                  <FocusAsset
                    id="Passive"
                    transform="translate(0.000000, 860.000000)"
                    delay={3.4}
                    className={getClass(LandingMenuData.passive.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.passive);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.passive.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.passive.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.passive.cat)}
                      content={'Passive'}
                    />
                  </FocusAsset>
                  <FocusAsset
                    id="government-bonds"
                    transform="translate(0.000000, 688.000000)"
                    delay={3}
                    className={getClass(LandingMenuData.governmentBonds.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.governmentBonds);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.governmentBonds.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.governmentBonds.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.governmentBonds.cat)}
                      content={'Government bonds'}
                    />
                  </FocusAsset>
                  <RegionAsset
                    id="uk"
                    transform="translate(298.000000, 172.000000)"
                    delay={1.2}
                    className={getClass(LandingMenuData.uk.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.uk);
                    }}
                    onMouseEnter={(e) => handleEnter(LandingMenuData.uk.cat, e)}
                    onMouseLeave={(e) => handleExit(LandingMenuData.uk.cat, e)}
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.uk.cat)}
                      content={'UK'}
                    />
                  </RegionAsset>
                  <ClassAsset
                    id="multi-asset"
                    transform="translate(1043.000000, 430.000000)"
                    delay={1.2}
                    className={getClass(LandingMenuData.multiAsset.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.multiAsset);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.multiAsset.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.multiAsset.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.multiAsset.cat)}
                      content={'Multi-asset'}
                    />
                  </ClassAsset>

                  <FocusAsset
                    id="corporate-bonds"
                    transform="translate(1043.000000, 602.000000)"
                    delay={0.8}
                    className={getClass(LandingMenuData.corporateBonds.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.corporateBonds);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.corporateBonds.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.corporateBonds.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.corporateBonds.cat)}
                      content={'Corporate bonds'}
                    />
                  </FocusAsset>
                  <StructureAsset
                    id="investment-trust"
                    transform="translate(894.000000, 172.000000)"
                    delay={2.4}
                    className={getClass(LandingMenuData.investmentTrust.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.investmentTrust);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.investmentTrust.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.investmentTrust.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.investmentTrust.cat)}
                      content={'Investment trust'}
                    />
                  </StructureAsset>
                  <ClassAsset
                    id="equities"
                    transform="translate(149.000000, 258.000000)"
                    delay={1.3}
                    className={getClass(LandingMenuData.equities.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.equities);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.equities.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.equities.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.equities.cat)}
                      content={'Equities'}
                    />
                  </ClassAsset>
                  <ClassAsset
                    id="alternatives"
                    transform="translate(298.000000, 344.000000)"
                    delay={1.6}
                    className={getClass(LandingMenuData.alternatives.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.alternatives);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.alternatives.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.alternatives.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.alternatives.cat)}
                      content={'Alternative assets'}
                    />
                  </ClassAsset>
                  <ClassAsset
                    id="bonds"
                    transform="translate(149.000000, 602.000000)"
                    delay={2.1}
                    className={getClass(LandingMenuData.bonds.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.bonds);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.bonds.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.bonds.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.bonds.cat)}
                      content={'Bonds'}
                    />
                  </ClassAsset>
                  <ClassAsset
                    id="property"
                    transform="translate(745.000000, 258.000000)"
                    delay={2}
                    className={getClass(LandingMenuData.property.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.property);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.property.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.property.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.property.cat)}
                      content={'Property'}
                    />
                  </ClassAsset>
                  <RegionAsset
                    id="us"
                    transform="translate(1043.000000, 774.000000)"
                    delay={0.4}
                    className={getClass(LandingMenuData.us.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.us);
                    }}
                    onMouseEnter={(e) => handleEnter(LandingMenuData.us.cat, e)}
                    onMouseLeave={(e) => handleExit(LandingMenuData.us.cat, e)}
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.us.cat)}
                      content={'US'}
                    />
                  </RegionAsset>
                  <RegionAsset
                    id="europe"
                    transform="translate(447.000000, 774.000000)"
                    delay={3.4}
                    className={getClass(LandingMenuData.europe.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.europe);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.europe.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.europe.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.europe.cat)}
                      content={'Europe'}
                    />
                  </RegionAsset>
                  <FocusAsset
                    id="sustainability"
                    transform="translate(447.000000, 258.000000)"
                    delay={1.6}
                    className={getClass(LandingMenuData.sustainability.cat)}
                    onClick={() => {
                      handleClick(LandingMenuData.sustainability);
                    }}
                    onMouseEnter={(e) =>
                      handleEnter(LandingMenuData.sustainability.cat, e)
                    }
                    onMouseLeave={(e) =>
                      handleExit(LandingMenuData.sustainability.cat, e)
                    }
                  >
                    <polygon
                      id="hexagon"
                      stroke="#161710"
                      fill="#FFFFFF"
                      transform="translate(99.422650, 86.102540) rotate(90.000000) translate(-99.422650, -86.102540) "
                      points="99.4226501 -13.3201097 185.52519 36.3912152 185.52519 135.813865 99.4226501 185.52519 13.3201097 135.813865 13.3201097 36.3912152"
                    ></polygon>
                    <HexaCopy
                      isActive={isActive(LandingMenuData.sustainability.cat)}
                      content={'Sustainability'}
                    />
                  </FocusAsset>
                </g>
              </g>
            </svg>
          );
        } else {
          const mobileData = Object.values(LandingMenuData);
          const focusData = mobileData.filter((data) => {
            return data.cat === 'focus' ? data : null;
          });
          const regionData = mobileData.filter((data) => {
            return data.cat === 'region' ? data : null;
          });
          const structureData = mobileData.filter((data) => {
            return data.cat === 'structure' ? data : null;
          });
          const assetClassData = mobileData.filter((data) => {
            return data.cat === 'assetClass' ? data : null;
          });

          return (
            <div>
              <MobileCatContainer>
                <CatTitle variant="h3">Focus</CatTitle>
                <MobileSliders
                  slides={focusData}
                  category="style"
                  onClick={handleClick}
                />
                <MHex1 src={sHex4} alt="small hex" />
                <MHex2
                  src={lHex1}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.empower)}
                />
              </MobileCatContainer>
              <MobileCatContainer>
                <CatTitle variant="h3">Asset class</CatTitle>
                <MobileSliders
                  slides={assetClassData}
                  category="assetClass"
                  onClick={handleClick}
                />
                <MHex7
                  src={lHex2}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.trust)}
                />
                <MHex8
                  src={lHex3}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.open)}
                />
              </MobileCatContainer>
              <MobileCatContainer>
                <CatTitle variant="h3">Region</CatTitle>
                <MobileSliders
                  slides={regionData}
                  category="region"
                  onClick={handleClick}
                />
                <MHex3
                  src={lHex4}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.curated)}
                />
                <MHex4
                  src={lHex5}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.simple)}
                />
              </MobileCatContainer>
              <MobileCatContainer>
                <CatTitle variant="h3">Structure</CatTitle>
                <MobileSliders
                  slides={structureData}
                  category="structure"
                  onClick={handleClick}
                />
                <MHex5
                  src={lHex6}
                  alt="large hex"
                  onClick={() => handleImgClick(LandingMenuCGIData.transparent)}
                />
                <MHex6 src={sHex5} alt="small hex" />
              </MobileCatContainer>
            </div>
          );
        }
      })()}
    </Container>
  );
}
