import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { TextField } from 'components/Form/TextField';
import { colors } from 'constants/colors';
import { GrCheckmark, GrClose, GrFormDown } from 'react-icons/gr';
import { default as styled } from 'styled-components';

export const ActiveTransferCardWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1.25)}px;
  display: grid;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(2.5, 3)};
    grid-template-rows: min-content 1fr;
  }
`;

export const Main = styled.div`
  display: grid;
  align-items: center;
  grid-template:
    'type status'
    'logo .'
    'requested requested'
    / 1fr 1fr;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template: 'type logo requested status' / 60px 150px 3fr 1fr;
    column-gap: ${({ theme }) => theme.spacing(3.75)}px;
    row-gap: 0;
  }
`;

export const AccountTypePill = styled.div`
  background: ${colors.seaBlue};
  color: white;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  justify-self: start;
  font-size: 0.75rem;
`;

interface ProviderLogoProp {
  logoUrl: string;
}
export const ProviderLogo = styled.div<ProviderLogoProp>`
  width: 100%;
  height: 40px;
  grid-area: logo;
  background: url(${(p) => p.logoUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-position: left;
  }
`;

export const ActiveCardTitle = styled(Typography)`
  color: ${colors.magenta};
  font-size: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1.125rem;
  }
  &.expanded {
    display: none;
    ${({ theme }) => theme.breakpoints.up('md')} {
      display: inherit;
    }
  }
  &.in-specie-title {
    display: flex;
  }
`;

export const VerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  grid-area: requested;
`;
export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
  }
`;

export const LabelText = styled(Typography)`
  font-size: inherit;
  font-weight: 300;
  gap: 5px;
  display: flex;
`;

export const LabelValue = styled(Typography)`
  font-size: inherit;
  font-weight: 400;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-weight: 500;
  }
`;

export const LabelDivider = styled.div`
  display: none;
  width: 0;
  border-right: 1px solid rgba(215, 22, 235, 0.2);
  height: 20px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: inherit;
  }
`;

export const HoldingsLabelDivider = styled.div`
  width: 0;
  border-right: 1px solid rgba(215, 22, 235, 0.2);
  height: 20px;
  &:nth-child(4) {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: inherit !important;
  }
`;

export const TransferStatusLabel = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  color: ${colors.magenta};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.75rem;
  font-weight: 500;
  justify-self: end;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1rem;
    grid-area: status;
  }
`;

export const TransferStatusButton = styled(CustomButton)`
  justify-self: end;
  grid-area: status;
  padding: 5px 20px;
  border-radius: 5px;
`;

export const Expand = styled(GrFormDown)`
  cursor: pointer;
  path {
    stroke: ${colors.richBlue};
  }
  height: 24px;
  width: 24px;
`;

export const Close = styled(GrClose)`
  cursor: pointer;
  path {
    stroke: ${colors.richBlue};
  }
  height: 16px;
  width: 26px;
`;

export const ExtraInfo = styled.div`
  max-height: 0px;
  opacity: 0;

  transition: max-height 0.2s ease-in-out 0.3s, opacity 0.4s ease-in-out 0s,
    padding-top 0.2s ease-in-out 0.3s;

  &.expanded {
    transition: max-height 0.2s ease-in-out, opacity 0.4s ease-in-out 0.2s,
      padding-top 0.2s ease-in-out;
    max-height: 250px;
    opacity: 1;
    padding-top: ${({ theme }) => theme.spacing(2)}px;
  }
  &.expanded.in-specie {
    max-height: 2000px;
  }
  &.in-specie {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.closed {
    pointer-events: none;
  }
`;

export const Info = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 300;
  grid-area: extra;
  color: ${colors.black};
`;

export const SomethingWentWrong = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 300;
`;

export const ProblemDetailsFormWrapper = styled.div`
  display: grid;
  grid-template: 'extra' 'input' 'buttons';
  column-gap: ${({ theme }) => theme.spacing(3.75)}px;
  row-gap: ${({ theme }) => theme.spacing(2.25)}px;
  > form {
    display: contents;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: 90px;
  }
`;

export const ProblemDetailsIntercomWrapper = styled.div`
  display: grid;
  grid-template: 'extra' 'buttons';
  column-gap: ${({ theme }) => theme.spacing(3.75)}px;
  row-gap: ${({ theme }) => theme.spacing(2.25)}px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: 90px;
  }
`;

export const CardButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

export const CardButton = styled(CustomButton)`
  justify-self: end;
  align-self: end;
  grid-area: button;
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

export const AccountNumberField = styled(TextField)`
  &.MuiFormControl-root {
    max-width: 500px;
    grid-area: input;

    label.MuiInputLabel-root {
      color: ${colors.black};
    }
    .MuiFormHelperText-root {
      font-size: 0.75rem;
      color: ${colors.richBlue};
      font-weight: 300;
      font-style: italic;
    }
  }
`;

export const ThankYouMsg = styled(Typography)`
  padding: 0 190px 0 90px;
  font-size: 0.875rem;
  font-weight: 300;
`;

export const InSpecieDivider = styled.div`
  display: block;
  margin: 10px 0;
  border-bottom: 1px solid #e6e6e6;
  width: 90%;
`;

export const CompletedTransfersLabel = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.richBlue};
  margin: 20px 0 15px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1.125rem;
  }
`;

export const HoldingsFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  margin-bottom: 30px;
`;

export const HoldingsFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 24px;
  min-width: 70px;
  border-radius: 15px;
  border: 0.75px solid rgba(22, 23, 16, 0.07);
  padding: 0 6px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0 12px;
  }

  &.active {
    border: 0.75px solid ${colors.magenta};
  }

  span {
    font-size: 0.625rem;
    font-weight: 400;
    color: ${colors.richBlack};
  }
`;

export const MagentaTick = styled(GrCheckmark)`
  height: 12px;
  width: 12px;
  polyline {
    stroke: ${colors.magenta};
  }
`;

export const HoldingContainer = styled.div.attrs(
  (props: { $isDark: boolean }) => props
)`
  background: ${({ $isDark }) => ($isDark ? colors.richBlack : colors.white)};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 14px;
  width: 95%;
  display: grid;
  grid-template:
    'logo status'
    'content content'
    / 1fr 1fr;
  margin-bottom: 14px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template: 'logo content status' / 60px 1fr 80px;
    margin-bottom: 11px;
    width: 80%;
  }
`;

export const AssetClassLogo = styled.div`
  height: 100%;
  width: 100%;
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;

  svg {
    height: 24px;
    width: 24px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 38px;
      width: 38px;
      margin-left: 4px;
    }
  }
`;

export const HoldingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
  row-gap: 5px;
  align-items: flex-start;
  margin-top: 5px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0;
  }
`;
export const HoldingTitle = styled(Typography).attrs(
  (props: { $isDark: boolean }) => props
)`
  font-size: 1rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.richBlue)};
`;

export const HoldingDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 10px;
  padding-right: 10px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: space-evenly;
    flex-wrap: nowrap;
    column-gap: 25px;
    padding-right: 0;
  }
  align-items: center;
`;

export const HoldingDetail = styled.div.attrs(
  (props: { $isDark: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.richBlack)};
  font-size: 0.6875rem;
  font-weight: 300;

  .holding-detail-value {
    font-size: 0.75rem;
    font-weight: 500;
  }
`;

export const HoldingStatus = styled.div`
  display: flex;
  grid-area: status;
  align-items: center;
  justify-self: flex-end;
  flex-direction: row-reverse;
  column-gap: 8px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
    row-gap: 4px;
  }
`;

export const HoldingStatusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 18px;
  border-radius: 15px;
  font-size: 0.5625rem;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 75px;
  }
`;
export const HoldingStatusDate = styled.div.attrs(
  (props: { $isDark: boolean }) => props
)`
  font-size: 0.5rem;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.richBlack)};
  font-weight: 400;
`;
