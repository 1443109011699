import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const DialogTitle = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const ActionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: ${(p) => p.theme.spacing(1)}px;

  & > *:last-child {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

export const TransferPromptContainer = styled.div`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3.75)}px;
  button {
    width: auto;
  }
`;
