import { WrapperType } from 'generated/graphql';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import numeral from 'numeral';
import { GetAssetGroupString } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';
import { sortingSelector } from './sortingHelpers';
import { useGetBasketBreakdown } from './useGetBasketBreakdown';
import { useGetCurrentBreakdown } from './useGetCurrentBreakdown';

export interface CombinedBreakdownType {
  name: string;
  existingAmount: number;
  amount: number;
  existingPercentage?: number;
  percentage: number;
  percentageChange?: number;
}

export const useCombinedBasketBreakdown = (
  selectedAccountId: string,
  accountType: WrapperType,
  getAssetGroupString: GetAssetGroupString,
  includeAsset?: IncludeAssetType
) => {
  const { accountsQuery, breakdownsObj } = useGetCurrentBreakdown(
    accountType,
    getAssetGroupString,
    includeAsset
  );
  const { basketBreakdownObj, totalBasketAmount } = useGetBasketBreakdown(
    selectedAccountId,
    getAssetGroupString,
    includeAsset
  );

  const activeGroups = union(
    Object.keys(breakdownsObj || {}),
    Object.keys(basketBreakdownObj)
  );

  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === accountType
  );
  const currentMarketValue = currentAccount?.valuationSummary?.marketValue || 0;
  const availableCash = currentAccount?.valuationSummary?.uninvestedCash || 0;

  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;

  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;

  const groupTotalValue = activeGroups.reduce((total, groupName) => {
    const currentAmount = breakdownsObj?.[groupName]?.amount ?? 0;
    const amountChange = basketBreakdownObj?.[groupName]?.amount ?? 0;

    return total + currentAmount + amountChange;
  }, 0);

  /** TD: ¯\_(ツ)_/¯ */
  const calculatePercentageRelativeToGroup = (groupName: string) => {
    const currentAmount = breakdownsObj?.[groupName]?.amount ?? 0;
    const amountChange = basketBreakdownObj?.[groupName]?.amount ?? 0;

    return numeral(currentAmount)
      .add(amountChange)
      .divide(groupTotalValue)
      .multiply(100)
      .value();
  };

  const combinedBreakdownUnsorted: CombinedBreakdownType[] = activeGroups.map(
    (activeGroup) => {
      const existingAmount = breakdownsObj
        ? breakdownsObj[activeGroup]?.amount ?? 0
        : 0;

      const currentPercentage = breakdownsObj
        ? breakdownsObj[activeGroup]?.percentage ?? 0
        : 0;

      if (activeGroup === 'Cash') {
        const updateCashAmount = existingAmount - totalBasketAmount;
        return {
          name: activeGroup,
          amount: updateCashAmount > 0 ? updateCashAmount : 0,
          percentage:
            updateCashAmount > 0
              ? numeral(updateCashAmount)
                  .divide(currentMarketValue)
                  .multiply(100)
                  .value()
              : 0,
          existingAmount: existingAmount,
        };
      }

      const newAmount = basketBreakdownObj[activeGroup]?.amount ?? 0;
      const amount = existingAmount + newAmount;
      const percentage = includeAsset
        ? calculatePercentageRelativeToGroup(activeGroup)
        : numeral(amount).divide(percentageOf).multiply(100).value();

      const breakdownLine = {
        name: activeGroup,
        existingAmount,
        amount,
        existingPercentage: currentPercentage,
        percentage,
        percentageChange: numeral(percentage)
          .subtract(currentPercentage)
          .value(),
      };
      return breakdownLine;
    }
  );

  const combinedBreakdown: CombinedBreakdownType[] = sortBy<CombinedBreakdownType>(
    combinedBreakdownUnsorted,
    sortingSelector
  );

  return { combinedBreakdown, totalBasketAmount };
};

export type CombinedBreakdown = ReturnType<typeof useCombinedBasketBreakdown>;
