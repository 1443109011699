import styled from 'styled-components';

export const HoldingsContainer = styled.div`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2.5, 0)};
  text-align: left;

  & > * + * {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${(p) => p.theme.spacing(5, 0)};
  }
`;
