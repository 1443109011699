import { assetClassColors } from 'constants/colors';
import { css } from 'styled-components';

export type assetClasses = keyof typeof assetClassColors;
interface AssetColouringProps {
  assetClass?: assetClasses;
}

export const assetColouring = css<AssetColouringProps>`
  ${({ assetClass }) =>
    assetClass ? `color: ${assetClassColors[assetClass]};` : ''}
  a, a:visited, a:hover, a:focus {
    ${({ assetClass }) =>
      assetClass ? `color: ${assetClassColors[assetClass]};` : ''}
  }
`;
