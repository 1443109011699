import { Typography } from '@material-ui/core';
import { GrClose } from 'react-icons/gr';
import styled from 'styled-components';

export const TransferBannerEl = styled.div`
  background: linear-gradient(
    131.75deg,
    rgba(146, 107, 235, 0.75) 12.65%,
    #926beb 82.49%
  );
  z-index: ${(p) => p.theme.zIndex.banner};
  width: 100%;
  height: 5rem;
  padding: 0.25rem 2.75rem 0.25rem 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  backdrop-filter: none;
  position: fixed;
  bottom: 0;

  ${(p) => p.theme.breakpoints.down('sm')} {
    backdrop-filter: blur(30px);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    backdrop-filter: blur(30px);
    top: 0;
    height: 50px;
  }
  transition: transform 0.4s linear 0.2s;
  /* &.scroll-down {
    transform: translateY(50px);
    ${(p) => p.theme.breakpoints.up('md')} {
      transform: translateY(-50px);
    }
  }
  &.scroll-up {
    transform: translateY(0px);
  } */
`;

export const Text = styled(Typography)`
  color: #fff;
  a {
    font-weight: 500;
  }
`;

export const Close = styled(GrClose)`
  position: absolute;
  right: 19px;
  cursor: pointer;
  path {
    stroke: rgba(255, 255, 255, 0.5);
    stroke-width: 3px;
  }
  height: 16px;
  width: 16px;
`;
