import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import React from 'react';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const promoCodeFormSchema = Yup.object().shape({
  promoCode: Yup.string().label('Promo code').required().min(3),
});

export interface PromoCodeStepProps {
  onProceed: (values: PromoCodeFormValues) => void;
  onGoBack: () => void;
}

export interface PromoCodeFormValues
  extends Yup.Asserts<typeof promoCodeFormSchema> {}

export function PromoCodeStep({ onProceed, onGoBack }: PromoCodeStepProps) {
  const validationSchema = promoCodeFormSchema;

  const onSubmit = async (data: PromoCodeFormValues) => {
    onProceed(data);
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <Form<PromoCodeFormValues> onSubmit={onSubmit} schema={validationSchema}>
      <StepContainer>
        <StepContent>
          <StepTitle>Lucky you!</StepTitle>

          <StepIntroduction mb={2}>
            <StepIntroductionTypography>
              Enter your promotional code below.
            </StepIntroductionTypography>
          </StepIntroduction>

          <StepFormContainer>
            <TextField name="promoCode" label="Promo code" fullWidth />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton type="submit" className="magenta">
                  Continue
                </StepButton>
                <GoBackButton onClick={handleBack} disabled={isSubmitting} />
              </>
            )}
          </FormState>
        </StepActions>
      </StepContainer>
    </Form>
  );
}
