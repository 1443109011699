import { colors } from 'constants/colors';
import styled from 'styled-components';
import { FontSize, Text } from '../Text/Text';

type lookupType = { [key: string]: string };

/**
 * Pre-selected tints for all the colours.
 */
const lookup: lookupType = {
  warning: colors['red-100'],
  green: colors['green-100'],
  purple: colors['purple-50'],
  magenta: colors['magenta-50'],
  richBlue: colors['richBlue-50'],
  vapor: colors.vapor,
  white: colors.white,
};

/**
 * Pre-selected tints for all the colours.
 */
const copyLookup: lookupType = {
  warning: colors['red-700'],
  purple: colors['purple-600'],
  magenta: colors['magenta-600'],
  vapor: colors['grey-900'],
  white: colors.richBlack,
};

/**
 * Pre-selected tints for all the colours.
 */
const hoverLookup: lookupType = {
  purple: colors['purple-100'],
  magenta: colors['magenta-100'],
  vapor: colors.lightGrey,
};

export const PillAction = styled.span`
  display: inline-flex;
  position: absolute;

  overflow: hidden;
  width: 1rem;
  height: 1rem;
  padding: 0;

  font-size: 0.75rem;

  transition: transform 0.1s ease-in-out;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${colors['purple-500']};
  color: white;
  border-radius: 50%;
  border: none;
  transform: translateX(20px);
  right: 2px;
  &:focus {
    width: 1rem;
  }
`;

const borderLookup = {
  none: '0px',
  default: '1.5rem',
  minimal: '0.5rem',
};

export interface PillProps {
  $color?: keyof typeof lookup;
  $border: 'none' | 'default' | 'minimal';
  $strikeThrough?: boolean;
  $hoverColor?: keyof typeof lookup;
  $noPadding?: boolean;
}

export const PillContainer = styled(Text)<PillProps>`
  display: inline-flex;
  position: relative;
  border: none;
  background-color: ${({ $color }) => ($color ? lookup[$color] : lookup.vapor)};
  padding: ${({ $fontSize, $noPadding }) =>
    $noPadding
      ? '0 0.25rem;'
      : $fontSize === FontSize.xs
      ? '0 0.65rem;'
      : $fontSize === FontSize.small
      ? '0 0.8rem;'
      : '0.25rem 1rem'};
  border-radius: ${({ $border }) => borderLookup[$border]};
  align-items: center;
  box-sizing: border-box;
  ${({ $strikeThrough }) =>
    $strikeThrough &&
    `
      text-decoration: line-through;
      border: 1px solid rgba(0, 0, 0, 0.30);
      color: #777;
      > span {
        line-height: 1.125rem;
      }
    `}
  ${({ $strikeThrough, $hoverColor }) =>
    $strikeThrough &&
    `
    transition: background-color 0.1s ease-in-out;
  &:hover {
    background-color: ${$hoverColor ? lookup[$hoverColor] : lookup.vapor};
  }`}

  overflow: hidden;

  button& > span {
    transition: transform 0.1s ease-in-out;
  }

  button&:focus,
  button&:hover {
    background-color: ${({ $color }) =>
      $color ? hoverLookup[$color] : lookup.vapor};

    & > span {
      transform: translateX(-7px);
    }
    & > ${PillAction} {
      transform: translateX(0px);
    }
  }

  button& {
    cursor: pointer;
  }
`;

export interface ExclusivePillContainerProps {
  $color: keyof typeof lookup;
  $canHover: boolean;
}

export const ExclusivePillContainer = styled(Text)<ExclusivePillContainerProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  border: none;
  background-color: ${({ $color }) => lookup[$color]};
  color: ${({ $color }) => copyLookup[$color]};
  padding: ${({ $fontSize }) =>
    $fontSize === FontSize.xs
      ? '0 0.65rem;'
      : $fontSize === FontSize.small
      ? '0.25rem 0.65rem;'
      : '0.25rem 1rem'};
  border-radius: 1.5rem;
  align-items: center;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;

  span {
    white-space: nowrap;
    overflow: hidden;
  }

  button&:focus,
  button&:hover {
    background-color: ${({ $color }) => hoverLookup[$color]};
    & > span {
      transform: translateX(-7px);
    }
    & > ${PillAction} {
      transform: translateX(0px);
    }
  }

  button& {
    cursor: pointer;
  }
`;

export const PillWrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const PillTextWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0;
`;
