import { DialogProps, useMediaQuery, useTheme } from '@material-ui/core';
import { IntercomContext } from 'context/Intercom';
import React, { useContext } from 'react';
import { HiX } from 'react-icons/hi';
import { CloseButton, StyledDialog } from './styles/Dialog.styles';

export interface ResponsiveDialogProps extends DialogProps {
  onClose?: () => void;
}

export const ResponsiveDialog = React.forwardRef<
  unknown,
  ResponsiveDialogProps
>(({ fullScreen, children, onClose, ...props }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const IntercomCtx = useContext(IntercomContext);

  const isFullScreen = isMobile || fullScreen;

  return (
    <StyledDialog
      ref={ref}
      {...props}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={isFullScreen}
      disableEnforceFocus={IntercomCtx?.open}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <CloseButton onClick={() => onClose?.()}>
        <HiX />
      </CloseButton>
      {children}
    </StyledDialog>
  );
});
