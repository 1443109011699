import { useAssetQuery } from 'generated/graphql';
import { AssetQueryAsset, SearchAssetsQueryAsset } from 'types/graphqlTypes';

export const deriveIsAssetAvailableToBuy = (
  asset?: AssetQueryAsset | SearchAssetsQueryAsset
): boolean => {
  const assetHasInstruments =
    asset?.instruments?.nodes && asset.instruments.nodes.length >= 1;

  return !!assetHasInstruments;
};

export const useIsAssetAvailableToBuyById = (assetId: string): boolean => {
  const { data: assetData } = useAssetQuery({
    id: parseInt(assetId!),
  });
  const asset = assetData?.asset;
  return !!asset && deriveIsAssetAvailableToBuy(asset);
};
