import { colors } from 'constants/colors';
import styled from 'styled-components';

export const AccountSelectorContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 1rem;
`;

export const AccountCardInner = styled.div`
  max-width: 15rem;
`;

export const Divider = styled.hr`
  border-bottom-color: ${colors.lightGrey};
  border-width: 0 0 1px 0;
`;
