import styled from 'styled-components';

export const SectionInner = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const SippInterestContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 640px;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;
`;

export const SippInterestActionWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing(5)}px;
`;
