import { useMediaQuery } from '@material-ui/core';
import { getSellEstimate } from 'components/Dialogs/Trading/helpers';
import { StyledLink } from 'components/design-system/Link';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { getKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import { useCombinedFundBreakdown } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns';
import { useAuth } from 'context/AuthContext';
import * as format from 'formatting';
import {
  Account,
  Position,
  RebalancingTransactionStatus,
  useAccountsQuery,
} from 'generated/graphql';
import { getOrderInstrumentShareClass } from 'helpers/instrumentNaming';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { generateFundDetailsPath } from 'paths';
import { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { PartialDeep } from 'type-fest';
import {
  AnyRebalancing,
  RebalancingQuerySellOrder,
  RebalancingsQuerySellOrder,
} from 'types/graphqlTypes';
import {
  CompletedCheck,
  FundNameWrapper,
  InProgressHourglass,
  OpenInNew,
  StyledChevronButton,
} from '../../../Checkout/steps/OrderConfirmation/OrderConfirmation.styles';
import {
  AmountExplainer,
  DetailsContainer,
  ErrorContainer,
  ItemDetails,
  OverViewContainerSellHeader,
  OverviewSellContainer,
  RowContainer,
  SellDetailsLayout,
} from '../../../Checkout/steps/OrderConfirmation/OrderDetails.styles';
import { ShareClassInfoPopover } from '../ShareClassInfoPopover';
import { SmallCopyWrapper } from './OrderStatusTable.styles';

interface SellOrderDetailsProps {
  portfolioRebalancing: AnyRebalancing;
  selectedAccount: PartialDeep<Account>;
}

export function SellOrderDetails({
  portfolioRebalancing,
  selectedAccount,
}: SellOrderDetailsProps) {
  const { signedIn } = useAuth();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const accountsQuery = useAccountsQuery(undefined, { enabled: signedIn });

  const positionsData = accountsQuery?.data?.accounts?.filter(
    (a) => a.wrapperType === selectedAccount.wrapperType
  )[0].positions;
  const { totalBasketAmount } = useCombinedFundBreakdown(
    selectedAccount.id!,
    selectedAccount.wrapperType!
  );

  const sortedSellOrders = orderBy(portfolioRebalancing.sellOrders, [
    (bo) => bo?.instrument?.asset?.assetClass,
    (bo) => bo?.instrument?.asset?.name,
  ]);

  const allEstAmount =
    portfolioRebalancing.sellOrders &&
    portfolioRebalancing.sellOrders.every((sellOrder) => {
      return (
        sellOrder?.status &&
        [
          RebalancingTransactionStatus.Pending,
          RebalancingTransactionStatus.Waiting,
        ].includes(sellOrder.status)
      );
    });

  const allExeAmount =
    portfolioRebalancing.sellOrders &&
    portfolioRebalancing.sellOrders.every((sellOrder) => {
      return (
        sellOrder?.status &&
        [RebalancingTransactionStatus.Completed].includes(sellOrder.status)
      );
    });

  return (
    <div>
      {isMdUp && (
        <RowContainer>
          <OverViewContainerSellHeader $allCompleted={allExeAmount}>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Instrument
            </TextSmall>

            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Asset class
            </TextSmall>

            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Share class <ShareClassInfoPopover size="small" disableMargin />
            </TextSmall>

            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              {allEstAmount ? 'Est. amount' : 'Amount'}
            </Text>

            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Quantity
            </Text>
          </OverViewContainerSellHeader>
        </RowContainer>
      )}
      <div>
        {sortedSellOrders.map((sellOrder) => {
          const position = positionsData?.find(
            (p) => p?.instrument?.isin! === sellOrder?.isin!
          );

          return (
            <SellOrderItemDetails
              key={sellOrder.id}
              sellOrder={sellOrder!}
              position={position}
              allEstAmount={allEstAmount}
              allExeAmount={allExeAmount}
              totalBasketAmount={totalBasketAmount}
              selectedAccount={selectedAccount}
            />
          );
        })}
      </div>
      {!allEstAmount && !allExeAmount && (
        <TextSmall $textAlign={TextAlign.right}>(* estimated value)</TextSmall>
      )}
    </div>
  );
}

export interface SellOrderItemDetailsProps {
  sellOrder: RebalancingQuerySellOrder | RebalancingsQuerySellOrder;
  position: PartialDeep<Position> | undefined;
  allEstAmount?: boolean;
  allExeAmount?: boolean;
  totalBasketAmount: number;
  selectedAccount: PartialDeep<Account>;
}

export function SellOrderItemDetails({
  sellOrder,
  position,
  selectedAccount,
  allEstAmount,
  allExeAmount,
  totalBasketAmount,
}: SellOrderItemDetailsProps) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [showDetails, setShowDetails] = useState(false);

  const instrument = sellOrder.instrument;
  const existingUnits = position?.quantity ?? 0;
  const estimatedUnits = existingUnits - sellOrder.quantity!;
  const currentValue = getSellEstimate(instrument!, existingUnits);
  const currentPositionSize = position?.allocation;

  const currentMarketValue =
    selectedAccount?.valuationSummary?.marketValue || 0;
  const availableCash = selectedAccount?.valuationSummary?.uninvestedCash || 0;

  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;

  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;
  const estimatedPositionSize =
    (currentValue - sellOrder.enteredAmount!) / percentageOf;

  const kiidUrl = position?.instrument?.kiidDocumentUrl;
  const factsheetUrl = position?.instrument?.factsheetUrl;
  const contractNoteUrl = sellOrder?.contractNoteUrl;

  const sellOrderComplete =
    sellOrder.status === RebalancingTransactionStatus.Completed;
  const sellOrderInProgress = [
    RebalancingTransactionStatus.Pending,
    RebalancingTransactionStatus.Waiting,
  ].includes(sellOrder.status!);

  const shareClassRaw = instrument
    ? getOrderInstrumentShareClass(instrument, [], true)
    : null;
  const shareClass = shareClassRaw ? shareClassRaw : 'NA';

  const isError =
    sellOrder.status === RebalancingTransactionStatus.Waiting &&
    existingUnits < sellOrder.quantity;

  return (
    <div>
      <OverviewSellContainer
        $completed={sellOrderComplete}
        $showDetails={showDetails}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
        $allCompleted={allExeAmount}
        $isError={isError}
      >
        <FundNameWrapper $noMargin>
          {sellOrderComplete && <CompletedCheck size="1rem" />}
          {sellOrder.status === RebalancingTransactionStatus.Pending && (
            <InProgressHourglass size="1rem" />
          )}
          <div>
            {isMdUp && (
              <TextXS
                $noMargin
                as={Link}
                to={generateFundDetailsPath({
                  id: instrument?.asset?.id!,
                  slug: instrument?.asset?.slug!,
                })}
              >
                <strong>{instrument?.name}</strong> <OpenInNew />
              </TextXS>
            )}
            {!isMdUp && (
              <TextSmall $noMargin as="span">
                <strong>{instrument?.name}</strong>
              </TextSmall>
            )}
          </div>
        </FundNameWrapper>
        <ItemDetails label="Asset class">
          {sellOrder?.instrument?.asset?.assetClass?.name}
        </ItemDetails>
        <ItemDetails label="Share class">{shareClass}</ItemDetails>
        <ItemDetails label="Amount" isNumber isError={isError}>
          {sellOrder.executedAmount ? (
            <>
              {format.currencyFull(sellOrder.executedAmount!)}{' '}
              {!allExeAmount && <AmountExplainer> </AmountExplainer>}
            </>
          ) : (
            <>
              {format.currencyFull(sellOrder.enteredAmount!)}{' '}
              {!allEstAmount && <AmountExplainer>*</AmountExplainer>}
            </>
          )}
        </ItemDetails>
        <ItemDetails
          label={instrument?.instrumentType === 'Fund' ? 'Units' : 'Shares'}
          isNumber
        >
          {sellOrder.quantity}
        </ItemDetails>

        <StyledChevronButton $completed={sellOrderComplete}>
          {showDetails ? (
            <HiChevronUp size="2rem" />
          ) : (
            <HiChevronDown size="2rem" />
          )}
        </StyledChevronButton>
        {isError && (
          <ErrorContainer>
            <TextSmall
              $noMargin
              $textAlign={isMdUp ? TextAlign.center : TextAlign.left}
              $fontWeight={FontWeight.normal}
              $isError
            >
              {existingUnits === 0 ? (
                "You don't hold any of this fund to sell"
              ) : (
                <>
                  You only have{' '}
                  <strong>{format.currencyFull(currentValue)}</strong> of this{' '}
                  to sell
                </>
              )}
            </TextSmall>
          </ErrorContainer>
        )}
      </OverviewSellContainer>
      <DetailsContainer
        $showDetails={showDetails}
        $completed={sellOrderComplete}
      >
        <SellDetailsLayout $showDetails={showDetails}>
          {sellOrder.completedUtc && (
            <SmallCopyWrapper>
              <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                Completed date:
              </TextSmall>{' '}
              <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                {format.date(sellOrder.completedUtc)}
              </TextSmall>
            </SmallCopyWrapper>
          )}
          {sellOrderInProgress && (
            <>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current position size:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.units(existingUnits)}{' '}
                  {position?.instrument?.instrumentType === 'Fund'
                    ? 'units'
                    : 'shares'}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current value:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.currencyFull(currentValue)}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Est. new position:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.percent(estimatedPositionSize)}{' '}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Latest price update:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.date(instrument?.priceDate)}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Est. new{' '}
                  {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}{' '}
                  total:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.units(estimatedUnits)}{' '}
                  {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current position size:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.percent(currentPositionSize!)}
                </TextSmall>
              </SmallCopyWrapper>
            </>
          )}
          {!!kiidUrl && (
            <SmallCopyWrapper>
              <StyledLink href={kiidUrl} target="_blank">
                {getKidDocName(instrument?.instrumentType!)}
              </StyledLink>
            </SmallCopyWrapper>
          )}
          {!!factsheetUrl && (
            <TextXS $noMargin>
              <StyledLink href={factsheetUrl} target="_blank">
                Factsheet
              </StyledLink>
            </TextXS>
          )}
          {!!contractNoteUrl && (
            <TextXS $noMargin>
              <StyledLink href={contractNoteUrl} target="_blank">
                Contract note
              </StyledLink>
            </TextXS>
          )}
        </SellDetailsLayout>
      </DetailsContainer>
    </div>
  );
}
