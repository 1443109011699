import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Input, InputProps } from './Input';
import { colors } from 'constants/colors';

const LargeInput = styled(Input)`
  font-size: 2.5rem;

  &.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${colors.darkGrey};
  }

  > span {
    font-size: 2.5rem;
  }

  &:before {
    border-bottom: 1px solid ${colors.darkGrey};
  }

  &:after {
    border: none;
  }
`;

export interface TextFieldProps extends InputProps {
  label: React.ReactNode | JSX.Element | string;
  helpText?: string;
  hideLabel?: boolean;
  isLarge?: boolean; //this will enable to feature toggle the new field style just in the add cash step of DPC
}

export function TextField({
  name,
  label,
  helpText,
  hideLabel = false,
  isLarge = false,
  disabled,
  className,
  ...other
}: TextFieldProps) {
  return (
    <FormControl className={className} fullWidth>
      {!hideLabel && (
        <InputLabel
          disabled={disabled}
          htmlFor={name}
          shrink={other.type === 'file' || other.type === 'date' || undefined}
        >
          {label}
        </InputLabel>
      )}
      {isLarge ? (
        <LargeInput name={name} disabled={disabled} {...other} />
      ) : (
        <Input name={name} disabled={disabled} {...other} />
      )}

      {ErrorMessage({
        name,
        render: ({ message }) => (
          <FormHelperText
            id={name + '-helper-text'}
            error={true}
            disabled={disabled}
          >
            {message}
          </FormHelperText>
        ),
      }) ||
        (helpText && (
          <FormHelperText id={name + '-helper-text'} disabled={disabled}>
            {helpText}
          </FormHelperText>
        ))}
    </FormControl>
  );
}
