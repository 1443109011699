import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import { trackGa } from 'helpers/track';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export interface SetSmsMfaMethodSuccessStepProps {
  onProceed?: () => void;
  proceedBtnText: string;
}

export function SetSmsMfaMethodSuccessStep({
  onProceed,
  proceedBtnText,
}: SetSmsMfaMethodSuccessStepProps) {
  const handleContinue = () => {
    trackGa({
      event: GaEventNames.mfa,
      method: 'SMS',
    });
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
      mfaStatus: 'complete',
    });
    onProceed?.();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Phone number verified</StepTitle>
        <StepIntroduction>
          <StepIntroductionTypography>
            From now on, to log in you'll use your username, password and a
            one-time code sent to your mobile phone.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepActions>
        <StepButton className="magenta" onClick={handleContinue}>
          {proceedBtnText}
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
