import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const StyledSpanLink = styled.a`
  position: relative;
  color: ${colors.magenta};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${transparentize(0.75, colors.magenta)};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: rotateY(90deg);
    background-color: ${colors.magenta};
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    &:after {
      transform: rotateY(0deg);
    }
  }
`;
