import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { styledLinkStyleOnly } from 'components/design-system/Link/Link.styles';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

// Accordion
export const AccordionWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  margin: 1rem 0;

  > .MuiAccordion-root {
    border: 1px solid ${colors.midGrey};
    border-radius: 10px;
    padding: 0 1rem;
    &:before {
      display: none;
    }
  }
  .Mui-expanded {
    margin: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  justify-content: space-between !important;
  .Mui-expanded {
    margin-right: -12px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    padding: 0.75rem 0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 0 1rem 0;
  flex-direction: column;

  ul {
    padding-left: 20px;
  }
`;

// Downloads
export const DownloadLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  align-items: center;
  margin: 2rem 0;
`;

export const DownloadTextLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClickHereButton = styled.a`
  ${styledLinkStyleOnly};
  border: none;
  background: white;
  padding: 0;
  display: inline-block;
  ${Text} &, ${Text} &:visited, ${Text} &:hover, ${Text} &:focus {
    color: ${colors.magenta};
  }

  svg {
    vertical-align: middle;
  }
`;

// Affirmations
export const AffirmationsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
`;
