import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateDashboardAccountPath } from 'paths';
import { transparentize } from 'polished';
import { HiCheck } from 'react-icons/hi';
import styled from 'styled-components';
import { deriveSuccessMsg } from '../../helpers/successMsg';
import type { ConfirmStepSuccessProps } from './ConfirmStep';

export const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${transparentize(0.5, colors.magenta)};
  border-radius: 100%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  svg {
    color: ${transparentize(0.5, colors.magenta)};
  }
`;

interface SetupAutoInvestSuccessStepProps {
  wrapperType: WrapperType;
  confirmSuccessMeta: ConfirmStepSuccessProps;
}

export function SetupAutoInvestSuccessStep({
  wrapperType,
  confirmSuccessMeta,
}: SetupAutoInvestSuccessStepProps) {
  return (
    <StepContainer>
      <CheckIconWrapper>
        <HiCheck size="1.8rem" />
      </CheckIconWrapper>
      <StepTitle>Success</StepTitle>
      <StepText>
        <StepIntroductionTypography>
          {deriveSuccessMsg(confirmSuccessMeta)}
        </StepIntroductionTypography>
      </StepText>
      <StepActions>
        <LinkStepButton
          to={generateDashboardAccountPath({
            wrapperType: getPathSegmentForWrapperType(wrapperType),
          })}
          className="magenta"
          fullWidth
        >
          Dashboard
        </LinkStepButton>
      </StepActions>
    </StepContainer>
  );
}
