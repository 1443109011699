import { useAuth } from 'context/AuthContext';
import { useAccountsQuery } from 'generated/graphql';

export function useAnyAccountCanBuy() {
  const { signedIn } = useAuth();
  const accountsQuery = useAccountsQuery(undefined, { enabled: signedIn });

  const anyAccountCanBuy = !signedIn
    ? false
    : !!accountsQuery.data?.accounts?.some(
        (account) => account.capabilities.buy.enabled
      );
  return { ...accountsQuery, anyAccountCanBuy };
}
