import { colors } from 'constants/colors';
import styled from 'styled-components';

export const AlertLink = styled.a`
  color: ${colors.richBlack};
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 300;

  &:visited,
  &:focus {
    color: ${colors.richBlack};
  }
`;
