import { getTargetDateInstrument } from 'components/feature/FundDetails/helpers/getTargetDateInstrument';
import { useTargetDateInfo } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { BasketBuyOrder } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import type { LocalRecurringOrder } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { Mode } from 'components/feature/mode/useMode';
import { HedgingStatus } from 'generated/graphql';
import orderBy from 'lodash/orderBy';
import {
  AccountsQueryAccount,
  AssetQueryAsset,
  SearchAssetsQueryAsset,
  UserProfileQueryUserProfile,
} from 'types/graphqlTypes';

/**
 * Get the default selected instrument for an asset.
 *
 * This initial is for use in the following components:
 *   * the fund details page,
 *   * the add to basket dialog,
 *   * the add to regular order dialog.
 *
 * @param asset
 * @param userProfile
 * @param mode
 * @param accounts
 * @param searchTerm
 * @param buyOrders
 * @param autoSaveInvestOrders
 * @param preSelectedIsin
 *          If this is set it will be returned if the asset is already in the basket.
 *          This is important when the user has the inc class in their cart, and then switches to
 *          the acc on the fund details page, and then loads the add to basket dialog
 * @returns the isin to be selected.
 */
export const useGetDefaultSelectedInstrumentIsin = (
  asset: AssetQueryAsset | SearchAssetsQueryAsset | undefined | null,
  mode: Mode | null,
  userProfile?: UserProfileQueryUserProfile,
  accounts?: AccountsQueryAccount[],
  searchTerm?: string,
  buyOrders?: BasketBuyOrder[],
  autoSaveInvestOrders?: LocalRecurringOrder[],
  preSelectedIsin?: string
): string | null => {
  const userPositionIsins = accounts?.flatMap(({ positions }) =>
    positions
      .filter((position) => position.quantity && position.quantity > 0)
      .map(({ isin }) => isin)
  );

  const { targetDateInfo, savedTargetDateInfo } = useTargetDateInfo();

  if (asset === undefined || asset === null) {
    return null;
  }

  const sortedInstruments = orderBy(asset.instruments.nodes, [
    (i) => i.isDarkUniverse,
    (i) => i.hedging === HedgingStatus.Hedged,
    (i) => i.incomeAccumulationFlag,
  ]);

  const filteredSortedInstruments = sortedInstruments.filter((instrument) => {
    return (
      !instrument.isDarkUniverse || userPositionIsins?.includes(instrument.isin)
    );
  });

  // If this is a target date fund, return the appropriate target date fund isin
  if (asset.isTargetDateFund) {
    const userRetirementYear =
      Number(targetDateInfo?.yearOfBirth) +
      Number(targetDateInfo?.intendedRetirementAge);

    const savedRetirementYear =
      Number(savedTargetDateInfo?.yearOfBirth) +
      Number(savedTargetDateInfo?.intendedRetirementAge);

    if (!savedRetirementYear && !userRetirementYear) {
      return null;
    }

    const retirementYear = userRetirementYear || savedRetirementYear;
    const targetDateInstrument = getTargetDateInstrument(asset, retirementYear);

    if (targetDateInstrument.instrument) {
      return targetDateInstrument.instrument.isin;
    } else {
      return null;
    }
  }

  // If the asset only has 1 instrument, return that isin
  if (filteredSortedInstruments.length === 1) {
    return filteredSortedInstruments[0].isin;
  }

  // If the user has an active search term that matches an instrument, return that isin
  if (searchTerm && !preSelectedIsin) {
    const searchTermInstrument = filteredSortedInstruments.find(
      (instrument) => instrument.isin === searchTerm
    );
    if (searchTermInstrument) {
      return searchTermInstrument.isin;
    }
  }

  // If the user is in buy mode, and has a buy order in their basket for this asset, return that isin
  if (mode?.mode === 'buy' && buyOrders) {
    const assetBuyOrder = buyOrders.find((order) => order.id === asset.id);
    if (assetBuyOrder) {
      return assetBuyOrder.isin;
    }
  }

  // If the user is in autoSaveInvest mode, and has an order in their regular order basket for this asset return that isin
  if (mode?.mode === 'autoSaveInvest' && autoSaveInvestOrders) {
    const assetInstrumentIsin = asset.instruments.nodes.map(({ isin }) => isin);
    const assetBuyOrder = autoSaveInvestOrders.find((order) =>
      assetInstrumentIsin.includes(order.isin)
    );
    if (assetBuyOrder) {
      return assetBuyOrder.isin;
    }
  }

  if (preSelectedIsin) {
    return preSelectedIsin;
  }

  // If the user has an existing position in this asset, for a selected account, return that isin
  if (accounts && mode?.mode !== 'resume' && mode?.wrapperType) {
    const account = accounts.find(
      (account) => account.wrapperType === mode.wrapperType
    );

    if (account) {
      const userAccountPositionIsins = account.positions
        .filter((position) => position.quantity && position.quantity > 0)
        .map(({ isin }) => isin);

      const instrument = filteredSortedInstruments.find((instrument) => {
        return userAccountPositionIsins.includes(instrument.isin);
      });

      if (instrument) {
        return instrument.isin;
      }
    }
  }

  // If the user has an existing position in this asset, for any account, return that isin
  const instrument = filteredSortedInstruments.find((instrument) => {
    return userPositionIsins?.includes(instrument.isin);
  });
  if (instrument) {
    return instrument.isin;
  }

  // If there is a 'isCustomUniverse' instrument in the asset, return that isin
  if (sortedInstruments.some((instrument) => instrument.isCustomUniverse)) {
    const customUniverseInstrument = sortedInstruments.find(
      (instrument) => instrument.isCustomUniverse
    );
    if (customUniverseInstrument) {
      return customUniverseInstrument.isin;
    }
  }

  // Else just return the first one from the sorted list
  if (filteredSortedInstruments[0]) {
    return filteredSortedInstruments[0].isin;
  }

  return null;
};
