import { colors } from 'constants/colors';
import { css } from 'styled-components';

/**
 * @deprecated - use one of the named shadows
 */
export const shadow00 = css`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
`;

/**
 * @deprecated - use one of the named shadows
 */
export const shadow01 = css`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
`;

/**
 * @deprecated - use one of the named shadows
 */
export const shadow02 = css`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`;

/**
 * @deprecated - use one of the named shadows
 */
export const shadow03 = css`
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.25);
`;

export const ElementSmallShadow = css`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
`;

export const SectionShadow = css`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
`;

export const BottomShadow = css`
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`;

export const SmallShadow = css`
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
`;

export const ExtraSmallNeumorphicShadow = css`
  box-shadow: 10px 15px 30px 0px rgba(166, 171, 189, 0.25);
`;

export const ExtraSmallStraitNeumorphicShadow = css`
  box-shadow: 0px 15px 30px 0px rgba(166, 171, 189, 0.25);
`;

export const CustomScrollBar = css`
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.darkGrey} ${colors.vapor};

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.vapor};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.darkGrey};
    border-radius: 0px;
    border: none;
  }
`;
