import { StyledLink } from 'components/design-system/Link';
import { TextNormal } from 'components/design-system/Text/Text';

export interface NoChargeInfoProps {
  kiidUrl?: string | null;
  kiidDocName?: string;
}

export function NoChargeInfo({ kiidUrl, kiidDocName }: NoChargeInfoProps) {
  return (
    <>
      <TextNormal>
        We do not yet have the required data to illustrate the costs and charges
        of this trade &mdash; you should read the{' '}
        {kiidUrl ? (
          <StyledLink
            href={kiidUrl!}
            target="_blank"
            style={{ whiteSpace: 'normal' }}
          >
            {kiidDocName}
          </StyledLink>
        ) : (
          kiidDocName
        )}{' '}
        to find out more about how much this investment may cost over time.
      </TextNormal>
    </>
  );
}
