import {
  H1Large,
  TextAlign as HeadingTextAlign,
} from 'components/design-system/Heading/Heading';
import React from 'react';
import { ChildrenContainer, SimpleHeroContainer } from './Styles/Hero.styles';

export interface ButtonProps {}

export interface HeroProps {
  title: string;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
}

export function SimpleHero({ title, children }: HeroProps) {
  return (
    <>
      <SimpleHeroContainer>
        <H1Large $textAlign={HeadingTextAlign.center}>{title}</H1Large>
        {children && <ChildrenContainer>{children}</ChildrenContainer>}
      </SimpleHeroContainer>
    </>
  );
}
