import kfd from 'assets/Pension_KFD_2024.pdf';
import { H5 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  FontWeight,
  Text,
  TextNormal,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

const CopyWrapper = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.lightGrey};
  }
  ::-webkit-scrollbar-track {
    background: ${colors.vapor};
  }
`;

const StyledList = styled.ul`
  padding-left: 25px;
  p {
    margin: 5px 0;
  }
  ol,
  ul {
    margin-top: 1rem;
  }
`;

const ListTitle = styled(Text)`
  padding-top: 10px;
`;

interface PensionDeclarationCopyProps {
  includeTransferInDeclarations: boolean;
}

export const PensionDeclarationCopy = ({
  includeTransferInDeclarations,
}: PensionDeclarationCopyProps) => {
  return (
    <div>
      <CopyWrapper>
        <TextNormal>
          I apply to become a member of the Scheme and agree to be bound by the
          rules of the Scheme as set out in the{' '}
          <StyledLink
            href="https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf"
            target="_blank"
          >
            Terms and Conditions
          </StyledLink>{' '}
          which may be amended from time to time.
        </TextNormal>
        <TextNormal>
          I have read and understood the{' '}
          <StyledLink
            href="https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf"
            target="_blank"
          >
            Terms and Conditions
          </StyledLink>{' '}
          and{' '}
          <StyledLink href={kfd} target="_blank">
            Key Features Document
          </StyledLink>{' '}
          that together form the legally binding agreement to open my pension
          account.
        </TextNormal>
        <TextNormal>
          I understand a trustee is appointed for the SIPP and owns the cash and
          investments within my SIPP but holds them for my benefit. I
          acknowledge that the trustee has appointed Seccl Custody Limited to
          have custody of my cash and investments.
        </TextNormal>
        <TextNormal>
          If I do not understand any point within the linked documents and
          declarations, or have any questions, I will get in touch with my
          adviser before proceeding.
        </TextNormal>
        <ListTitle $fontWeight={FontWeight.normal}>I authorise:</ListTitle>
        <StyledList>
          <TextNormal as="li">
            Seccl Custody Limited to administer and arrange my investments held
            within my pension that I or my financial adviser may request on my
            behalf. I also authorise Seccl Custody Limited to transfer money to
            and from bank accounts for the purposes of facilitating my pension.
          </TextNormal>
          <TextNormal as="li">
            Any correspondence or documentation to be sent to the message hub in
            respect of my pension instead of by post (unless legally required to
            do so).
          </TextNormal>
        </StyledList>
        <ListTitle $fontWeight={FontWeight.normal}>I confirm that:</ListTitle>
        <StyledList>
          <TextNormal as="li">
            Seccl Custody Limited has not provided me with any advice under the
            terms of the Financial Services and Markets Act 2000.
          </TextNormal>
          <TextNormal as="li">
            I have read the Privacy statement contained in the{' '}
            <StyledLink
              href="https://d1ur1kq4xhe5pz.cloudfront.net/images/Tillit-Terms-Conditions.pdf"
              target="_blank"
            >
              Terms and Conditions
            </StyledLink>{' '}
            Document and give my permission for my personal data to be processed
            as described in this statement and the General Data Protection
            Regulations.
          </TextNormal>{' '}
        </StyledList>

        <ListTitle $fontWeight={FontWeight.normal}>
          I will tell you in writing within 30 days if:
        </ListTitle>
        <StyledList>
          <TextNormal as="li">
            There is a change to my name, permanent residential address or
            residency status;
          </TextNormal>

          <TextNormal as="li">I am made bankrupt; or</TextNormal>
          <TextNormal as="li">
            There are any changes to the information provided in my application
            as soon as I am aware that what is stated is no longer true and
            complete.
          </TextNormal>
        </StyledList>
        {includeTransferInDeclarations && (
          <>
            <H5>Transfer-in declarations</H5>

            <TextNormal>
              I authorise and instruct you to transfer sums and assets from the
              plan(s), directly to the Seccl Personal Pension Plan and to
              provide any instructions and/or discharge required by any relevant
              third party to do so.
            </TextNormal>
            <TextNormal>
              I accept that in order to comply with regulatory obligations,
              Seccl Custody Limited and the current provider(s) may need to
              verify my identity and residential address and may use credit
              reference agency searches and ask for my documents to verify my
              identity and address.
            </TextNormal>
            <TextNormal>
              Until this application is accepted and complete, Seccl Custody
              Limited’s responsibility is limited to the return of total
              payment(s) to the current provider(s).
            </TextNormal>
            <TextNormal>
              When payment is made to Seccl Custody Limited as instructed, this
              means that I shall no longer be entitled to receive pension
              benefits from the whole of the plan(s) listed where the whole of
              the plan(s) is transferring, or that part of the plan(s)
              represented by the payment(s) if only part of the plan(s) is
              transferring.
            </TextNormal>
            <TextNormal>
              I have read the information provided or made available to me by
              the current provider in connection with this transfer.
            </TextNormal>
            <TextNormal>
              I accept responsibility in respect of any claims, losses,
              expenses, additional tax charges or any penalties that Seccl
              Custody Limited and the current provider(s) may incur as a result
              of any incorrect, untrue, or misleading information in this
              application or given to me, or on my behalf, or of any failure on
              my part to comply with any aspect of this application.
            </TextNormal>
            <TextNormal>
              I authorise Seccl Custody Limited, the current provider, any
              contributing Employer and any Financial Adviser named in this
              application to obtain from each other, and release to each other,
              any information that may be required to enable the transfer of
              sums and assets to Seccl Custody Limited.
            </TextNormal>
            <TextNormal>
              I accept that the benefits to be provided by the Seccl Personal
              Pension Plan may be in a different form and of a different amount
              from those which would have been payable by the current provider,
              and there is no statutory requirement on the Seccl Personal
              Pension Plan to provide for survivor’s benefits out of the
              transfer payment.
            </TextNormal>
            <TextNormal>
              I understand that where any benefits being transferred are in
              capped drawdown they will only be accepted on the basis that they
              will be immediately converted to flexi-access drawdown. I also
              understand that if I draw any income via flexi-access drawdown I
              will become subject to the Money Purchase Annual Allowance, if I
              am not already subject to it. I hereby instruct that any capped
              drawdown benefits are converted to flexi- access drawdown upon
              receipt.
            </TextNormal>
          </>
        )}
        <H5>Contribution declarations</H5>
        <ListTitle $fontWeight={FontWeight.normal}>
          If contributions are to be paid I declare that:
        </ListTitle>
        <StyledList as="ol">
          <TextNormal as="li">
            I am under age 75 and a relevant UK individual under section 189 of
            the finance act 2004.
          </TextNormal>
          <TextNormal as="li">
            The total of the client contributions paid to this Scheme and to
            other registered pension schemes, in respect of which I am entitled
            to tax relief, under section 188 of Finance Act 2004, will not
            exceed, in any tax year, the higher of:
            <StyledList>
              <TextNormal as="li">
                The basic amount in that tax year (currently £3,600 gross); or
              </TextNormal>
              <TextNormal as="li">
                100% of my relevant UK earnings (as defined in section 189 of
                Finance Act 2004 in that tax year).
              </TextNormal>
            </StyledList>
          </TextNormal>
        </StyledList>

        <ListTitle $fontWeight={FontWeight.normal}>
          I will give notice to Seccl Custody Limited as scheme administrator if
          an event occurs, as a result of which I will no longer be entitled to
          relief on contributions, under section 188 of Finance Act 2004. I will
          give this notice by the later of:
        </ListTitle>
        <StyledList as="ol">
          <TextNormal as="li">
            End of the tax year in which the event occurs; and
          </TextNormal>
          <TextNormal as="li">30 days after the event.</TextNormal>
        </StyledList>
        <ListTitle $fontWeight={FontWeight.normal}>
          I understand and acknowledge that:
        </ListTitle>
        <StyledList as="ol">
          <TextNormal as="li">
            If I have Enhanced or Fixed Lifetime Allowance protection this will
            be lost if a contribution is made to this pension plan or any other
            pension plan and it is my responsibility if this occurs.
          </TextNormal>

          <TextNormal as="li">
            I understand that it is my responsibility to inform HMRC through my
            self-assessment tax return of the pension contributions made to all
            pension schemes and that contributions in excess of the annual
            allowance or money purchase annual allowance (MPAA) will normally
            mean I will be subject to a tax charge at my marginal rate of tax.
          </TextNormal>

          <TextNormal as="li">
            Once contributions have been received into my pension plan, they can
            only be refunded in line with HMRC rules or if I cancel my pension
            plan within the 30 day cancellation period.
          </TextNormal>
        </StyledList>
        <ListTitle $fontWeight={FontWeight.normal}>
          I understand that:
        </ListTitle>
        <StyledList>
          <TextNormal as="li">
            Checks will be carried out to establish proof of my identity and
            residence, and those of a third party or my employer, where
            contributions are to be paid by either of them. Should these checks
            prove unsatisfactory, I will be required to provide proof of
            identity that is deemed satisfactory before my pension account
            opening can be accepted.
          </TextNormal>

          <TextNormal as="li">
            If I enter into an adviser charges agreement, I authorise and
            request Seccl Custody Limited to pay adviser charges on my behalf
            from the funds held in my account, until otherwise instructed.
          </TextNormal>
        </StyledList>
        <TextNormal>
          I declare that, to the best of my knowledge and belief, all the
          information I have supplied including any other documents completed in
          relation to my application, and the statements made in this
          declaration are, to the best of my knowledge and belief, correct,
          complete and not misleading.
        </TextNormal>
        <TextNormal>
          If you have any questions or see incorrect details displayed, please
          do not hesitate to get in touch
        </TextNormal>
      </CopyWrapper>
    </div>
  );
};
