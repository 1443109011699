import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const NoMatchCopy = styled(Typography)`
  text-align: center;
  color: ${colors.richBlack};
  margin-bottom: ${(p) => p.theme.spacing(3.75)}px;
`;

export const ViewMoreContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SecondaryGridHeader = styled.div`
  width: 100%;
  padding-top: 1.25rem;
`;

export const MatchesCount = styled.span`
  color: ${colors.magentaSafe};
`;

export const DividerTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  > p {
    flex-shrink: 0;
  }
`;
export const DividerTagsWrapper = styled.div`
  display: flex;
  align-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
