import { WrapperType } from 'generated/graphql';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import { BasketOrderWrapper } from '../FundsBasket.styles';
import { FundBasketSellOrder } from './FundBasketSellOrder';

interface SellOrderContentProps {
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
}

export const SellOrderContent = ({
  selectedAccountId,
  selectedAccountType,
}: SellOrderContentProps) => {
  const { basketSellOrders } = useFundsBasket({
    selectedAccountId,
  });

  return (
    <BasketOrderWrapper>
      {basketSellOrders.map((sellOrder) => {
        return (
          <div key={sellOrder.id}>
            <FundBasketSellOrder
              selectedAccountId={selectedAccountId}
              sellOrder={sellOrder}
              selectedAccountType={selectedAccountType}
            />
          </div>
        );
      })}
    </BasketOrderWrapper>
  );
};
