import CuratedIMG from 'assets/backgrounds/Tillit_Curated_largeSquare.jpg';
import EmpowerIMG from 'assets/backgrounds/Tillit_Empower_largeSquare.jpg';
import OpenIMG from 'assets/backgrounds/Tillit_Open_largeSquare.jpg';
import SimpleIMG from 'assets/backgrounds/Tillit_Simple_largeSquare.jpg';
import TransparentIMG from 'assets/backgrounds/Tillit_Transparent_largeSquare.jpg';
import TrustIMG from 'assets/backgrounds/Tillit_Trust_largeSquare.jpg';
import { StyledLink } from 'components/design-system/Link';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React from 'react';

const trackLink = (hexagonName: string) => (
  event: React.MouseEvent<EventTarget>
) => {
  trackGa({
    event: GaEventNames.selectContent,
    content_type: 'tillit universe',
    item_id: `hexagon modal link clicked - ${hexagonName}`,
  });
};

export const LandingMenuCGIData = {
  open: {
    title: 'Open',
    background: OpenIMG,
    data: [
      {
        subtitle: 'There is no one-size-fits-all',
        content: (
          <p>
            Funds come in many shapes and structures. We consider open-ended
            funds, investment trusts and exchange-traded funds (ETFs). We
            believe that each structure has its pros and cons, but all funds on
            our platform have to be regulated.
          </p>
        ),
      },
      {
        subtitle: 'The best of both worlds',
        content: (
          <p>
            Opinion is divided on whether active funds (those run by a fund
            manager) or passive funds (those that track an index) are the better
            way to invest. We believe there is a place for both.
          </p>
        ),
      },
    ],
  },
  simple: {
    title: 'Simple',
    background: SimpleIMG,
    data: [
      {
        subtitle: 'Passive funds should be simple and cheap',
        content: (
          <p>
            We believe that the purpose of passive funds is to give the investor
            broad market access at low cost. We look for passive funds that
            track large markets, replicate the index closely and are easy to
            trade. In the case of commodities, we prefer those that are secured
            by physical bars of the metal they track, not the whims of the
            futures market.
          </p>
        ),
      },
      {
        subtitle: 'We prefer focused asset managers',
        content: (
          <p>
            For active funds, we have a preference for focused and privately
            held asset managers who encourage their fund managers to concentrate
            on their craft without pressure of short-term performance targets.
          </p>
        ),
      },
    ],
  },
  transparent: {
    title: 'Transparent',
    background: TransparentIMG,
    data: [
      {
        subtitle: 'Active has to be genuinely active',
        content: (
          <p>
            We believe that the purpose of active funds is to be significantly
            different from the benchmarks they aim to outperform. This is
            measured by active share. The higher the active share, the more
            different the fund is compared to its benchmark. We believe that by
            being different, the fund manager demonstrates independence of
            thought and conviction in their approach.
          </p>
        ),
      },
      {
        subtitle:
          'The ‘what’, the ‘why’ and the ‘how’ should be clear and consistent',
        content: (
          <p>
            The raison d'être of an active fund has to be clearly articulated in
            its philosophy. It should describe what the fund is striving to
            achieve and why. We look for funds with a repeatable process and a
            sense of purpose.
          </p>
        ),
      },
    ],
  },
  curated: {
    title: 'Curated',
    background: CuratedIMG,
    data: [
      {
        subtitle: 'Breadth and diversification is necessary',
        content: (
          <p>
            Our investment universe stretches across asset classes, countries,
            investment styles, themes, management structure and return profiles
            so that DIY investors can build portfolios that meet their needs and
            preferences. We also offer a range of multi-asset funds for those
            investors who want to take a more hands-off, ready-meal approach.
          </p>
        ),
      },
    ],
  },
  trust: {
    title: 'Trust',
    background: TrustIMG,
    data: [
      {
        subtitle: 'Value for money',
        content: (
          <p>
            Fees play a key part in total returns. In the case of actively
            managed funds, we look for those that are fairly priced and have a
            track record of delivering value after fees. In the case of
            passively managed funds, we look for those that offer market
            exposure at minimal cost.
          </p>
        ),
      },
      {
        subtitle: 'Experience and expertise make great fund managers',
        content: (
          <p>
            Whether they operate as a team, a duo or independently, we look for
            fund managers with experience and expertise in their field. We look
            for integrity, track record and conviction.
          </p>
        ),
      },
    ],
  },
  empower: {
    title: 'Empower',
    background: EmpowerIMG,
    data: [
      {
        subtitle: 'Tolerance of risk varies',
        content: (
          <p>
            We know that tolerance of risk varies from person to person, over
            time and with changing circumstances. Ultimately, personal goals,
            individual preferences and time horizon will influence how much risk
            to take. We therefore offer a range of funds suitable for all tastes
            and risk appetites.
          </p>
        ),
      },
      {
        subtitle: 'Sustainability is complex',
        content: (
          <p>
            There is a growing number of investors who want to have a positive
            impact on the planet and our society, or at least cause no harm.
            Sustainability, responsibility and the ethics of investment are
            complex issues and can be open to interpretation. We believe they
            require fundamental analysis and ongoing engagement and that active
            funds are therefore a better way to invest sustainably.
          </p>
        ),
      },
    ],
  },
};

export const LandingMenuData = {
  active: {
    title: 'Active',
    tag: 'ACTIVE',
    content: (
      <>
        <p>
          Funds that are managed by one or more fund managers who use their
          judgement to pick which investments to hold in the fund. An active
          fund should be judged against a benchmark and aims to deliver a higher
          return than that benchmark after fees.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about active investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/active-or-passive-investing-which-one-should-i-pick"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Active')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=ACTIVE',
    // cspell:disable-next-line
    tags: '?tags=ACTIVE',
    cat: 'focus',
  },
  passive: {
    title: 'Passive',
    tag: 'PASSIVE',
    content: (
      <>
        <p>
          Funds that aim to replicate an index or otherwise predefined group of
          assets. Passive funds are usually cheaper than active funds as there
          are no research and management costs involved.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about passive investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/active-or-passive-investing-which-one-should-i-pick"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Passive')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=PASSIVE',
    // cspell:disable-next-line
    tags: '?tags=PASSIVE',
    cat: 'focus',
  },
  growth: {
    title: 'Growth',
    content: (
      <>
        <p>
          Funds that invest in line with Growth style investing. In simple
          terms, this means that the manager or team focuses on buying stocks of
          companies which have the potential to grow their sales or profits much
          faster than their competitors or the overall market, regardless of the
          economic backdrop. Notable Growth style investors include Thomas Rowe
          Price and Philip Fisher.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about Growth investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/growth-or-value-which-investing-style-suits-you"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Growth')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=HIGROWTH%2CGROWTH',
    // cspell:disable-next-line
    tags: '?tags=HIGROWTH%2CGROWTH',
    cat: 'focus',
  },
  value: {
    title: 'Value',
    tag: 'VALUE',
    content: (
      <>
        <p>
          Funds that invest in line with Value style investing. In simple terms,
          this means that the manager or team typically focuses on buying stocks
          whose share price appears to be below the intrinsic value of the
          company, as calculated from its profits or its assets and liabilities.
          Notable Value style investors include Benjamin Graham and Warren
          Buffett.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about Value investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/growth-or-value-which-investing-style-suits-you"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Value')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=VALUE',
    tags: '?tags=VALUE',
    cat: 'focus',
  },
  corporateBonds: {
    title: 'Corporate bonds',
    tag: 'CORPBOND',
    content: (
      <>
        <p>
          Funds that invest in bonds issued by companies, either private or
          public. Most bonds are fixed income assets and are similar to a loan
          between a borrower (in this case, a company) and a lender (the
          investor). Just like a loan, the bond typically pays the investor
          interest, which is called a coupon. Bonds are also categorised as
          investment-grade or high-yield bonds depending on the ability and
          willingness of the borrower to repay the debt in full.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about bonds as an asset class{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Corporate bonds')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=CORPBOND',
    tags: '?tags=CORPBOND',
    cat: 'focus',
  },
  governmentBonds: {
    title: 'Government bonds',
    tag: 'GOVBOND',
    content: (
      <>
        <p>
          Funds that invest in bonds issued by governments, either by one
          specific government or a mixture. Most bonds are fixed income assets
          and are similar to a loan between a borrower (in this case, a
          government) and a lender (the investor). Just like a loan, the bond
          typically pays the investor interest, which is called a coupon. Bonds
          are also categorised as investment-grade or high-yield bonds depending
          on the ability and willingness of the borrower to repay the debt in
          full.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about bonds as an asset class{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Government bonds')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=GOVBOND',
    tags: '?tags=GOVBOND',
    cat: 'focus',
  },
  technology: {
    title: 'Technology',
    tag: 'TECH',
    content: (
      <>
        <p>
          Funds that invest in stocks of technology companies, potentially all
          over the world. These are specialist funds focused only on the
          technology sector, or technology as a theme.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about specialist funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/whats-so-special-about-specialist-funds"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Technology')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=TECH',
    tags: '?tags=TECH',
    cat: 'focus',
  },
  healthcare: {
    title: 'Healthcare',
    tag: 'HEALTH',
    content: (
      <>
        <p>
          Funds that invest in stocks of healthcare companies, potentially all
          over the world. These are specialist funds focused only on the
          healthcare sector and sometimes even specific categories of that
          sector, such as biotech.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about specialist funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/whats-so-special-about-specialist-funds"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Healthcare')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=HEALTH',
    tags: '?tags=HEALTH',
    cat: 'focus',
  },
  sustainability: {
    title: 'Sustainability',
    content: (
      <>
        <p>
          Funds that invest with sustainability in mind. There is a growing
          number of investors who want to have a positive impact on the planet
          and our society, or at least cause no harm. This style of investing
          tends to focus on three core areas - Environment, Social and
          Governance (ESG). These funds all take a different approach to
          sustainable investing.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about sustainable investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/sustainable-investing#articles"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Sustainability')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=EXCLUDES%2CPOSCHANGE%2CIMPACT',
    // cspell:disable-next-line
    tags: '?tags=EXCLUDES%2CPOSCHANGE%2CIMPACT',
    cat: 'focus',
  },
  concentrated: {
    title: 'Concentrated',
    tag: 'CONC',
    content: (
      <>
        <p>
          Funds that invest in a concentrated way. At TILLIT, we categorise
          equity funds with a maximum of 45 holdings, and bond funds with a
          maximum of 100 holdings, as concentrated.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about diversification and concentration{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/sustainable-investing#articles"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Concentrated')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=CONC',
    tags: '?tags=CONC',
    cat: 'focus',
  },
  wildcards: {
    title: 'Wildcards',
    tag: 'WILDCARDS',
    content: (
      <>
        <p>
          Wildcards is a collection of niche, esoteric funds with a higher-risk
          profile compared to many other funds on the platform.
        </p>
        <p>&nbsp;</p>
        <p>
          These funds may invest in different asset classes, but the common
          denominator is that they offer exposure to an area of the market or
          investment style that is a bit off the beaten track. These funds
          typically carry a higher level of risk and should be considered in the
          context of a diversified portfolio.
        </p>
        <p>&nbsp;</p>
        <p>
          For more detail of the risks involved, please read the fund’s
          information page which contains the TILLIT View and any specific risks
          associated with a particular Wildcard fund.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about Wildcards{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/take-a-walk-on-the-wild-side"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Wildcards')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=WILDCARD',
    tags: '?tags=WILDCARD',
    cat: 'focus',
  },
  global: {
    title: 'Global',
    tag: 'GLOBAL',
    content: (
      <>
        <p>
          Funds that invest in assets across the globe. These funds can be
          focused on any kind of asset class, but the common denominator is that
          they can hunt for ideas and invest all over the world.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about investing in different geographic markets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Global')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=GLOBAL',
    tags: '?tags=GLOBAL',
    cat: 'region',
  },
  uk: {
    title: 'UK',
    tag: 'UK',
    content: (
      <>
        <p>
          Funds that invest predominantly in UK assets. These funds can be
          focused on any kind of asset class, but the common denominator is that
          they invest in assets predominantly listed in, or exposed to, the UK.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about investing in different geographic markets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('UK')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=UK',
    tags: '?tags=UK',
    cat: 'region',
  },
  us: {
    title: 'US',
    tag: 'US',
    content: (
      <>
        <p>
          Funds that invest predominantly in US assets. These funds can be
          focused on any kind of asset class, but the common denominator is that
          they invest in assets predominantly listed in, or exposed to, the US.{' '}
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about investing in different geographic markets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('US')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=US',
    tags: '?tags=US',
    cat: 'region',
  },
  europe: {
    title: 'Europe',
    tag: 'EUROPE',
    content: (
      <>
        <p>
          Funds that invest in assets in Europe. These funds can be focused on
          any kind of asset class, but the common denominator is that they hunt
          for ideas and invest all over Europe. Some European funds exclude the
          UK.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about investing in different geographic markets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Europe')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=EUROPE',
    tags: '?tags=EUROPE',
    cat: 'region',
  },
  asia: {
    title: 'Asia',
    content: (
      <>
        <p>
          Funds that invest in assets in Asia. These funds can be focused on any
          kind of asset class, but the common denominator is that they hunt for
          ideas and invest all over Asia. Some Asian funds exclude Japan.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about investing in different geographic markets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Asia')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=JAPAN%2CASIAEXJAP%2CASIA',
    // cspell:disable-next-line
    tags: '?tags=JAPAN%2CASIAEXJAP%2CASIA',
    cat: 'region',
  },
  emergingMarkets: {
    title: 'Emerging markets',
    content: (
      <>
        <p>
          Funds that invest in assets in emerging markets. An emerging market is
          a country that is like a developed market (such as the US and UK) but
          is less advanced. Usually, regulation and market liquidity tend to be
          less developed than in developed markets. But, as emerging markets
          tend to grow faster than developed markets, they have the potential to
          deliver higher returns (and risk) for investors. Current examples
          include China and India.
        </p>
        <p>&nbsp;</p>
        <p>
          Some emerging market funds also specialise in frontier markets. A
          frontier market is a country that is like an emerging market (such as
          China and India) but less advanced and is sometimes called a
          ‘pre-emerging market’. It tends to be smaller, less liquid and less
          accessible for investors and normally riskier than an emerging market.
          Growth and development in these countries tend to be volatile so if
          you are thinking about investing in these countries you should take a
          very long-term view (sometimes several decades) to have time to ride
          out any volatility. These funds can be focused on any kind of asset
          class, but the common denominator is that they hunt for ideas and
          invest across frontier markets. Current examples include Vietnam and
          Kenya.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about emerging and frontier market investing{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/make-your-money-travel-explore-developed-emerging-frontier-markets"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Emerging markets')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    // cspell:disable-next-line
    url: '/funds?tags=EMERGING%2CFRONTIER',
    // cspell:disable-next-line
    tags: '?tags=EMERGING%2CFRONTIER',
    cat: 'region',
  },
  oeic: {
    title: 'Open-ended funds',
    tag: 'OPEN',
    content: (
      <>
        <p>
          This includes Open Ended Investment Companies (OEICs) and Unit Trusts.
          This is a common structure for funds based in the UK and it allows
          investors to pool their money in order to invest in a particular
          country, sector or style. The price an investor pays reflects the
          price of the underlying investments at a fixed point every day. These
          funds can provide exposure to a wide range of different markets and
          asset classes.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about the differences between ‘open-ended’ and
          ‘closed-ended’ funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/trust-in-the-investment-trust"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Open-ended funds')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=OPEN',
    tags: '?tags=OPEN',
    cat: 'structure',
  },
  investmentTrust: {
    title: 'Investment trust',
    tag: 'INVTRUST',
    content: (
      <>
        <p>
          Funds that are structured in what is often referred to as
          ‘closed-ended’. An investment trust is a company whose shares are
          listed on the stock exchange and which can be bought and sold like
          those of any other company. It can invest in different assets, private
          or publicly listed, and can take on debt, which is expressed as
          gearing. The value of the underlying investments minus the debt is
          called the net asset value (NAV). The share price of the trust is
          determined by the supply and demand of the trust’s shares and can
          either trade at a premium or discount to the NAV.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about the differences between ‘open-ended’ and
          ‘closed-ended’ funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/trust-in-the-investment-trust"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Investment trust')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=INVTRUST',
    tags: '?tags=INVTRUST',
    cat: 'structure',
  },
  etf: {
    title: 'ETF',
    tag: 'ETC',
    content: (
      <>
        <p>
          Funds that are structured as an Exchange-Traded Fund (ETF). This type
          of fund trades on an exchange, just like equities. They are passive
          investments which automatically track an index or a pre-set group of
          assets. Exchange-Traded Commodities (ETCs) is a common type of ETF for
          investors who want exposure to commodities such as gold and silver.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about ETFs{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/etfs-investment-must-have-or-the-emperors-new-clothes"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('ETF')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=ETC',
    tags: '?tags=ETC',
    cat: 'structure',
  },
  equities: {
    title: 'Equities',
    tag: 'EQUITIES',
    content: (
      <>
        <p>
          Funds that invest in companies whose shares are listed on an exchange.
          Equities is another term commonly used for stocks and shares. Equities
          tend to be higher risk and with the potential for higher returns than
          assets such as bonds. They are generally considered appropriate for
          long-term investing.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about equities as an asset class{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Equities')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=EQUITIES',
    tags: '?tags=EQUITIES',
    cat: 'assetClass',
  },
  bonds: {
    title: 'Bonds',
    tag: 'BONDS',
    content: (
      <>
        <p>
          Funds that invest in bonds issued by companies or governments, or
          both. A bond is similar to a loan between a borrower (a company or
          government) and a lender (the investor). Just like a loan, the bond
          typically pays the investor interest, which is called a coupon. Bonds
          are also categorised as investment-grade or high-yield bonds depending
          on the ability and willingness of the borrower to repay the debt in
          full. Bonds are often referred to as fixed income investments.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about bonds as an asset class{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Bonds')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=BONDS',
    tags: '?tags=BONDS',
    cat: 'assetClass',
  },
  property: {
    title: 'Property',
    tag: 'PROPERTY',
    content: (
      <>
        <p>
          Funds that invest in property. Property funds can come in both
          ‘open-ended’ (typically OEIC) and closed-ended (typically REITs)
          structures and can invest in different types of properties, either in
          one specific country or across different regions. They may be able to
          invest either directly in property and/or through listed property
          companies.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about property funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Property')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=PROPERTY',
    tags: '?tags=PROPERTY',
    cat: 'assetClass',
  },
  alternatives: {
    title: 'Alternative assets',
    tag: 'ALTS',
    content: (
      <>
        <p>
          Funds that invest in areas outside of the mainstream asset classes
          such as equities, bonds and property. Examples include commodities,
          infrastructure and private equity.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about alternative assets{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/an-introduction-to-asset-classes-2"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Alternative assets')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=ALTS',
    tags: '?tags=ALTS',
    cat: 'assetClass',
  },
  multiAsset: {
    title: 'Multi-asset',
    tag: 'MULTI',
    content: (
      <>
        <p>
          Funds that invest in more than one asset class. Most funds are
          dedicated to investing in one type of asset class. Equity funds invest
          in equities/stocks/shares, bond funds invest in bonds and so on. This
          combination of various asset classes can increase diversification and
          reduce volatility because different asset classes tend to perform
          differently.
        </p>
        <p>&nbsp;</p>
        <p>
          You can find out more about multi-asset funds{' '}
          <StyledLink
            href="https://tillitinvest.com/insights/multi-assets-funds-the-ready-meal-approach-to-investing"
            target="_blank"
            rel="noreferrer"
            onClick={trackLink('Multi-asset')}
          >
            here
          </StyledLink>
          .
        </p>
      </>
    ),
    url: '/funds?tags=MULTI',
    tags: '?tags=MULTI',
    cat: 'assetClass',
  },
};
