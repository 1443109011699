import { Typography } from '@material-ui/core';
import React from 'react';
import { InfoPopover } from '../Popover/InfoPopover/InfoPopover';

export interface LabelProps {
  children: React.ReactNode;
  style: { fontSize: string; lineHeight: string };
  description?: React.ReactNode;
  className?: string;
  onPopoverClick?: () => void;
}

export function Label({
  children,
  description,
  className,
  onPopoverClick,
  ...rest
}: LabelProps) {
  return (
    <Typography className={className} {...rest}>
      {children}
      {description && (
        <InfoPopover
          content={description}
          size="small"
          disablePadding
          onClick={() => onPopoverClick?.()}
        />
      )}
    </Typography>
  );
}
