import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PerformanceContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoDataMessage = styled(Typography)`
  background-color: ${colors.seaBlue};
  border-radius: 10px;
  color: ${colors.white};
  text-align: center;
  padding: ${(p) => p.theme.spacing(2, 4)};
  max-width: 75%;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 50%;
  }
`;

export const BlurredContainer = styled.div`
  filter: blur(3px);
`;
