export interface Action {
  change:
    | 'salary'
    | 'employerAmount'
    | 'employerPercentage'
    | 'employeeAmount'
    | 'employeePercentage';
  newValue: number;
}

export interface Contribution {
  applyQualifyingEarningsCap?: boolean;
  salary?: number;
  employerAmount?: number;
  employerPercentage?: number;
  employeeAmount?: number;
  employeePercentage?: number;
  employeeTaxReliefAmount?: number;
  totalAmount?: number;
  totalPercentage?: number;
}

const qualifyingEarningsFrom = 6240;
const qualifyingEarningsTo = 50270;

const roundToDp = (value: number, decimalPlaces: number) => {
  return Number(
    Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
  );
};

const getQualifyingEarningsFromSalary = (
  salary: number,
  applyQualifyingEarningsCap: boolean
) => {
  if (!applyQualifyingEarningsCap) {
    return salary;
  }

  if (salary < qualifyingEarningsFrom) {
    return 0;
  }

  const salaryCappedByQualifyingEarningsTo =
    salary < qualifyingEarningsTo ? salary : qualifyingEarningsTo;
  return salaryCappedByQualifyingEarningsTo - qualifyingEarningsFrom;
};

export const employerContribution = (
  currentValue: Contribution,
  action: Action
): Contribution => {
  const qualifyingEarnings = getQualifyingEarningsFromSalary(
    action.change === 'salary' ? action.newValue : currentValue.salary ?? 0,
    currentValue.applyQualifyingEarningsCap ?? false
  );

  const employeeProportion = currentValue.employeePercentage
    ? currentValue.employeePercentage / 100
    : undefined;
  const employerProportion = currentValue.employerPercentage
    ? currentValue.employerPercentage / 100
    : undefined;

  if (action.change === 'salary') {
    const employeeAmountGross = employeeProportion
      ? (qualifyingEarnings * employeeProportion) / 12
      : currentValue.employeeAmount || currentValue.employeeTaxReliefAmount
      ? (currentValue.employeeAmount ?? 0) +
        (currentValue.employeeTaxReliefAmount ?? 0)
      : undefined;

    const employeeAmount = employeeAmountGross
      ? roundToDp(employeeAmountGross * 0.8, 2)
      : undefined;

    const employeeTaxReliefAmount = employeeAmountGross
      ? roundToDp(employeeAmountGross * 0.2, 2)
      : undefined;

    const employerAmount = employerProportion
      ? roundToDp((qualifyingEarnings * employerProportion) / 12, 2)
      : currentValue.employerAmount
      ? currentValue.employerAmount
      : undefined;

    const newEmployeeProportion = employeeProportion
      ? employeeProportion
      : employeeAmountGross
      ? (employeeAmountGross * 12) / qualifyingEarnings
      : undefined;

    const newEmployerProportion = employerProportion
      ? employerProportion
      : currentValue.employerAmount
      ? (currentValue.employerAmount * 12) / qualifyingEarnings
      : undefined;

    return {
      ...currentValue,
      salary: action.newValue,
      employeeAmount,
      employeePercentage: newEmployeeProportion
        ? roundToDp(newEmployeeProportion * 100, 3)
        : undefined,
      employerAmount,
      employerPercentage: newEmployerProportion
        ? roundToDp(newEmployerProportion * 100, 3)
        : undefined,
      employeeTaxReliefAmount,
      totalAmount: roundToDp(
        (employeeAmount ?? 0) +
          (employerAmount ?? 0) +
          (employeeTaxReliefAmount ?? 0),
        2
      ),
      totalPercentage: roundToDp(
        ((newEmployeeProportion ?? 0) + (newEmployerProportion ?? 0)) * 100,
        3
      ),
    };
  }

  if (action.change === 'employerAmount') {
    const employerProportion = qualifyingEarnings
      ? roundToDp((action.newValue * 12) / qualifyingEarnings, 5)
      : undefined;

    const employerPercentage = employerProportion
      ? roundToDp(employerProportion * 100, 3)
      : undefined;

    return {
      ...currentValue,
      employerAmount: action.newValue,
      employerPercentage,
      totalAmount: roundToDp(
        (currentValue.employeeAmount ?? 0) +
          (action.newValue ?? 0) +
          (currentValue.employeeTaxReliefAmount ?? 0),
        2
      ),
      totalPercentage: roundToDp(
        (currentValue.employeePercentage ?? 0) + (employerPercentage ?? 0),
        3
      ),
    };
  }

  if (action.change === 'employerPercentage') {
    const employerProportion = action.newValue / 100;
    const employerAmount = qualifyingEarnings
      ? roundToDp((qualifyingEarnings * employerProportion) / 12, 2)
      : undefined;

    return {
      ...currentValue,
      employerAmount,
      employerPercentage: action.newValue,
      totalAmount: roundToDp(
        (currentValue.employeeAmount ?? 0) +
          (employerAmount ?? 0) +
          (currentValue.employeeTaxReliefAmount ?? 0),
        2
      ),
      totalPercentage: roundToDp(
        (currentValue.employeePercentage ?? 0) + (action.newValue ?? 0),
        3
      ),
    };
  }

  if (action.change === 'employeeAmount') {
    const employeeProportion = qualifyingEarnings
      ? roundToDp((action.newValue * 12 * 1.25) / qualifyingEarnings, 5)
      : undefined;

    const employeePercentage = employeeProportion
      ? roundToDp(employeeProportion * 100, 3)
      : undefined;

    const employeeTaxReliefAmount =
      action.newValue === 0 ? undefined : roundToDp(action.newValue * 0.25, 2);

    return {
      ...currentValue,
      employeeAmount: action.newValue,
      employeePercentage,
      employeeTaxReliefAmount,
      totalAmount: roundToDp(
        (action.newValue ?? 0) +
          (currentValue.employerAmount ?? 0) +
          (employeeTaxReliefAmount ?? 0),
        2
      ),
      totalPercentage: roundToDp(
        (employeePercentage ?? 0) + (currentValue.employerPercentage ?? 0),
        3
      ),
    };
  }

  if (action.change === 'employeePercentage') {
    const employeeProportion = action.newValue / 100;
    const employeeAmountGross = qualifyingEarnings
      ? roundToDp((qualifyingEarnings * employeeProportion) / 12, 2)
      : undefined;

    return {
      ...currentValue,
      employeeAmount: employeeAmountGross
        ? roundToDp(employeeAmountGross * 0.8, 2)
        : 0,
      employeePercentage: action.newValue,
      employeeTaxReliefAmount: employeeAmountGross
        ? roundToDp(employeeAmountGross * 0.2, 2)
        : 0,
      totalAmount: roundToDp(
        (currentValue.employerAmount ?? 0) + (employeeAmountGross ?? 0),
        2
      ),
      totalPercentage: roundToDp(
        (action.newValue ?? 0) + (currentValue.employerPercentage ?? 0),
        3
      ),
    };
  }

  return currentValue;
};
