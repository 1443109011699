import { Popper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { shadow01 } from 'theme/shared/base.styles';

export const StyledComboBox = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    label {
      top: -4px;
      font-size: 1rem;
      font-weight: 300;
    }

    .MuiAutocomplete-popupIndicator {
      color: ${colors.richBlue};
    }

    .MuiInput-root {
      margin-top: 0.75rem;
      input,
      textarea {
        font-size: 1rem;
        font-weight: 300;
        color: ${colors.richBlue};
      }
    }
  }
`;

export const StyledPopper = styled(Popper)`
  &.MuiAutocomplete-popper {
    margin-top: ${(p) => p.theme.spacing(1)}px;

    .MuiAutocomplete-paper {
      ${shadow01}
      border-radius: 5px;
    }

    .MuiAutocomplete-option {
      padding: ${(p) => p.theme.spacing(1, 2)};
      font-size: 1rem;
      font-weight: 300;
      color: ${colors.richBlack};
      &[data-focus='true'] {
        color: ${colors.white};
        background-color: ${colors.seaBlue};
      }
    }
  }
`;
