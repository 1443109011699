import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import { FontStyle } from 'components/design-system/Text/Text';
import {
  ToggleColorOptions,
  ToggleIconButtons,
} from 'components/design-system/ToggleIconButtons/ToggleIconButtons';
import {
  RegionBreakdown,
  breakdownSupported,
} from 'components/feature/FundDetails/Breakdown/RegionBreakdown/RegionBreakdown';
import worldData from 'components/feature/FundDetails/Breakdown/RegionBreakdown/world';
import { TableBreakdown } from 'components/feature/FundDetails/Breakdown/TableBreakdown/TableBreakdown';
import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { Breakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { GaEventNames } from 'constants/gaConstants';
import { sum } from 'd3';
import { date as formatDate } from 'formatting';
import { trackGa } from 'helpers/track';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { useState } from 'react';
import { AssetQueryAssetInstrument } from 'types/graphqlTypes';
import { PerformanceContainer } from '../Styles/FundDetails.style';
import { Disclaimer } from './RegionMapSlice.styles';
import {
  BreakdownLayout,
  BreakdownToggleWrapper,
  ChartContainer,
} from './_shared/BreakdownSlices.styles';

const getCountryNameFromCode = (code: string) => {
  const country = worldData.features.find(
    (feature) => feature.properties?.code === code
  );

  return country?.properties?.name;
};

const parentIsoCountryCodeLookUp: { [key: string]: string } = {
  IM: 'GB',
  JE: 'GB',
  GG: 'GB',
};

const parentCountryNames: { [key: string]: string } = {
  GB: 'United Kingdom & Crown Dependencies',
};

/**
 * This function takes a regional breakdown and groups the lines by country code.
 */
function deriveMapBreakdown(regionalBreakdown: Breakdown): Breakdown {
  const linesGroupedByType = groupBy(
    regionalBreakdown.lines,
    (line) => line.type
  );

  const linesGroupedByCountryCode = groupBy(
    linesGroupedByType['Country'],
    (line) => {
      if (line.type)
        return (
          parentIsoCountryCodeLookUp[line.isoCountryCode || ''] ??
          line.isoCountryCode
        );
    }
  );

  const lines = map(linesGroupedByCountryCode, (value, key) => {
    if (value.length === 1) {
      return value[0];
    }
    return {
      name: parentCountryNames[key] ?? getCountryNameFromCode(key),
      isoCountryCode: key,
      code: key,
      proportion: sum(value.map(({ proportion }) => proportion)),
      lines: value,
    };
  });

  return {
    name: regionalBreakdown.name,
    proportion: regionalBreakdown.proportion,
    lines: [
      ...lines,
      ...(linesGroupedByType['Other'] || []),
      ...(linesGroupedByType['Cash'] || []),
      ...(linesGroupedByType['Region'] || []),
    ],
  };
}

interface RegionMapSliceProps {
  instrument: AssetQueryAssetInstrument;
}

type DisplayOptions = 'map' | 'table';

export function RegionMapSlice({ instrument }: RegionMapSliceProps) {
  const breakdown = {
    name: 'Region',
    proportion: sum(
      instrument?.regionalBreakdown?.nodes.map(({ proportion }) => proportion)
    ),
    lines: instrument?.regionalBreakdown?.nodes
      .map((region) => {
        return {
          name: region.name,
          proportion: region.proportion,
          isoCountryCode: region.isoCountryCode || '',
          code: region.code || '',
          type: region.type || '',
        };
      })
      .filter((region) => region.proportion !== 0),
  };

  const showMap = breakdownSupported(breakdown);

  const [activeDisplay, setActiveDisplay] = useState<DisplayOptions>(
    showMap ? 'map' : 'table'
  );

  const breakdownHasData = !!breakdown.lines?.length;

  return (
    <PerformanceContainer>
      <H4 $textAlign={TextAlign.center}>Where does it invest?</H4>
      {breakdownHasData ? (
        <BreakdownLayout>
          {showMap && (
            <BreakdownToggleWrapper>
              <ToggleIconButtons<DisplayOptions>
                onClick={(value) => {
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund details - bubble chart slice - display',
                    item_id: value,
                  });
                  setActiveDisplay(value);
                }}
                $color={ToggleColorOptions.blue}
                options={[
                  { value: 'map', icon: 'map' },
                  { value: 'table', icon: 'table' },
                ]}
                value={activeDisplay}
              />
            </BreakdownToggleWrapper>
          )}

          {activeDisplay === 'map' && (
            <>
              <ChartContainer>
                <RegionBreakdown breakdown={deriveMapBreakdown(breakdown)} />
              </ChartContainer>
              <Disclaimer $fontStyle={FontStyle.italic}>
                Map view based upon analysing regional breakdown data supplied
                by FE fundinfo, retrieved on{' '}
                {formatDate(instrument?.lastFeSyncUtc)}, and is indicative only.
              </Disclaimer>
            </>
          )}
          {activeDisplay === 'table' && (
            <ChartContainer>
              <TableBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
        </BreakdownLayout>
      ) : (
        <NoDataCopy>{breakdown.name} breakdown is not available.</NoDataCopy>
      )}
    </PerformanceContainer>
  );
}
