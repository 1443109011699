import { Button, ButtonProps } from '@material-ui/core';
import { colors } from 'constants/colors';
import { Link, LinkProps } from 'react-router-dom';
import Styled, { css } from 'styled-components';

const buttonSizeLookup = {
  header: {
    desktop: {
      height: '32px',
      fontSize: '0.75rem', // 12px
    },
    mobile: {
      height: '28px',
      fontSize: '0.75rem',
    },
  },
  extraSmall: {
    desktop: {
      height: '24px',
      fontSize: '0.625rem', // 10px
    },
    mobile: {
      height: '28px',
      fontSize: '0.75rem',
    },
  },
  small: {
    desktop: {
      height: '32px',
      fontSize: '0.75rem', // 12px
    },
    mobile: {
      height: '34px',
      fontSize: '0.75rem',
    },
  },
  normal: {
    desktop: {
      height: '38px',
      fontSize: '0.8125rem', // 13px
    },
    mobile: {
      height: '40px',
      fontSize: '0.8125rem',
    },
  },
  large: {
    desktop: {
      height: '44px',
      fontSize: '1rem', // 16px
    },
    mobile: {
      height: '46px',
      fontSize: '1rem',
    },
  },
};

export type buttonSizeOptions = keyof typeof buttonSizeLookup;

const buttonColourLookup = {
  primary: {
    colour: colors.white,
    background: colors.magentaSafe,
  },
  secondary: {
    colour: colors.white,
    background: colors.richBlue,
  },
  seaBlue: { colour: colors.white, background: colors.seaBlue },
  purple: { colour: colors.white, background: colors.purple },
  mauve: { colour: colors.white, background: colors.mauve },
  richBlack: {
    colour: colors.white,
    background: colors.richBlack,
  },
  white: {
    colour: colors.richBlack,
    background: colors.white,
  },
  light: {
    colour: colors.richBlack,
    background: colors.grey,
  },
};

export type buttonColourOptions = keyof typeof buttonColourLookup;

export type CustomButtonV2Props = {
  $size?: buttonSizeOptions;
  $isWide?: boolean;
  $noHover?: boolean;
  $color?: buttonColourOptions;
} & ButtonProps;

/**
 *  Custom Button V2 Styles
 *  How to use?
 *
 * Import this file instead of the Button from MUI and pass all usual
 * props to it, to change the look of the button just add in a className
 * to it to extend it's appearance.
 * @property {buttonSizeOptions} $size - controls the height and padding ('extraSmall', 'small', 'normal', 'large')
 * @property {boolean} $isWide - sets width to 280px on desktop
 * @property {boolean} $noHover - controls whether the y axis transform fires on desktop
 * @property {buttonColourOptions} $color - controls colour of button ('primary', 'secondary', 'purple', etc)
 */
export const CustomButtonV2 = Styled(Button)<CustomButtonV2Props>`
  padding: 0 1.75rem;
  width: 100%;
  border-radius: 5px;
  transform: translateY(0);
  transition: transform 0.25s ease-in-out;
  box-shadow: none;
  white-space: nowrap;

  ${({ $size }) =>
    $size === 'header' &&
    css`
      min-width: initial;
      ${({ theme }) => theme.breakpoints.down('md')} {
        padding: 0 0.875rem;
      }
    `}

  &:hover {
      ${({ theme }) => theme.breakpoints.up('lg')} {
        transform: ${({ $noHover }) => ($noHover ? 'none' : 'translateY(-5px)')}
      };
    }

  ${({ $size }) =>
    $size
      ? css`
          height: ${buttonSizeLookup[$size].mobile.height};
          font-size: ${buttonSizeLookup[$size].mobile.fontSize};
        `
      : css`
          height: ${buttonSizeLookup.normal.mobile.height};
          font-size: ${buttonSizeLookup.normal.mobile.fontSize};
        `};
  
  ${(p) => p.theme.breakpoints.up('sm')} {
    width: ${({ $isWide }) => ($isWide ? '280px' : 'auto')};
    ${({ $size }) =>
      $size
        ? css`
            height: ${buttonSizeLookup[$size].desktop.height};
            font-size: ${buttonSizeLookup[$size].desktop.fontSize};
          `
        : css`
            height: ${buttonSizeLookup.normal.desktop.height};
            font-size: ${buttonSizeLookup.normal.desktop.fontSize};
          `};
          };
          
  ${({ $color, variant }) =>
    $color
      ? css`
          &:hover,
          &.active {
            color: ${buttonColourLookup[$color].colour};
            background-color: ${buttonColourLookup[$color].background};
            box-shadow: none;
          }

          ${variant === 'outlined'
            ? css`
                background-color: transparent;
                color: ${buttonColourLookup[$color].background};
                border-color: ${buttonColourLookup[$color].background};
                &:hover,
                &.active {
                  background-color: transparent;
                  color: ${buttonColourLookup[$color].background};
                  border-color: ${buttonColourLookup[$color].background};
                }
                &[disabled] {
                  color: ${buttonColourLookup[$color].background};
                  border-color: ${buttonColourLookup[$color].background};
                }
              `
            : css`
                color: ${buttonColourLookup[$color].colour};
                background-color: ${buttonColourLookup[$color].background};
                &[disabled],
                &.Mui-disabled {
                  color: ${buttonColourLookup[$color].colour};
                  background-color: ${buttonColourLookup[$color].background};
                  opacity: 0.6;
                }
              `}
        `
      : css`
          &:hover,
          &.active {
            color: ${buttonColourLookup.primary.colour};
            background-color: ${buttonColourLookup['primary'].background};
            box-shadow: none;
          }

          ${variant === 'outlined'
            ? css`
                background-color: transparent;
                color: ${buttonColourLookup.richBlack.background};
                border-color: ${buttonColourLookup.richBlack.background};
              `
            : css`
                color: ${buttonColourLookup.primary.colour};
                background-color: ${buttonColourLookup.primary.background};
              `}
        `};

  // TODO: Split in to separate component?
  &.remove {
    background-color: ${(p) => p.theme.palette.error.main};
    color: ${(p) => p.theme.palette.error.contrastText};
    &:hover, &.active: {
      background-color: ${(p) => p.theme.palette.error.dark};
    }
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }

  &[disabled] {
    opacity: 0.6;
  }

  span { align-items: center; line-height: 0;}
`;

/**
 * LinkCustomButton
 *
 * Combines the CustomButton with react-router-dom's Link Component.
 *
 * This should be used for internal links when a link needs to be styled as a button.
 *
 * Implementations notes -
 *   1 - The react-router-dom's Link component is used - this is for links within the app.
 *   2 - MUI & styled-components both have a prop that allows you to change the type base element
 *        - styled-components has an 'as' prop (https://styled-components.com/docs/api#as-polymorphic-prop)
 *        - MUI has a 'component' prop (https://mui.com/material-ui/react-button/#upload-button)
 *       Here we need to use the MUI component prop - we still want styled-components to use the
 *       MUI Button as the base component.
 *
 * @example ```
 *    *   // ...
 *   <LinkCustomButton className="magenta" to={openAccountPath} />
 * ```
 *
 * @todo - add example that allows tracking on click.
 *
 * @param props - CustomButtonV2Props & LinkProps
 * @returns
 */
export const LinkCustomButton = (props: CustomButtonV2Props & LinkProps) => (
  <CustomButtonV2 component={Link} {...props} />
);

export interface ButtonWrapperProps {
  $align?: 'left' | 'center';
  $direction?: 'row' | 'column';
  $mdUpAlign?: 'left' | 'center';
  $mdUpDirection?: 'row' | 'column';
  $noMargin?: boolean;
}
export const ButtonWrapper = Styled.div<ButtonWrapperProps>`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: ${({ $align }) => $align || 'center'};
  flex-direction: ${({ $direction }) => $direction || 'column'};
  margin: ${({ $noMargin }) => ($noMargin ? '' : '2.5rem 0 0.75rem 0')};

  ${({ theme }) => theme.breakpoints.up('md')} {
      flex-direction: ${({ $mdUpDirection }) => $mdUpDirection || 'column'};
      justify-content: ${({ $align, $mdUpAlign }) =>
        $mdUpAlign ? $mdUpAlign : $align || 'center'};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
      flex-direction: row;
  }
`;

ButtonWrapper.defaultProps = {
  $direction: 'column',
  $mdUpDirection: 'column',
};
