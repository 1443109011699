import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import {
  InSpecieSupport,
  TransferMethod,
  WrapperType,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { CedingProvider } from '../../types';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

export interface HowToTransferOnProceedValues {
  transferMethod: TransferMethod;
}

export type onProceedCallback = (values: HowToTransferOnProceedValues) => void;

export interface HowToTransferProps {
  wrapperType: WrapperType;
  onProceed: onProceedCallback;
  cedingProvider: CedingProvider;
  onGoBack?: () => void;
}

const HowToTransferStepContent = styled(StepContent)`
  max-width: 32rem;
`;

export function HowToTransfer({
  wrapperType,
  cedingProvider,
  onProceed,
  onGoBack,
}: HowToTransferProps) {
  const { showNewMessages } = useIntercom();

  const cedingProviderInSpecieSupport =
    cedingProvider?.inSpecieSupport ?? InSpecieSupport.MaybeSupported;

  const handleIntercomClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support ',
      item_id: `how to transfer - transferring my ${wrapperType} holdings`,
    });
    showNewMessages(
      `I am interested in transferring my ${wrapperNameFriendly(
        wrapperType
      )} holdings from ${cedingProvider.name}.`
    );
  };

  return (
    <StepContainer>
      <HowToTransferStepContent>
        <StepTitle>How would you like to transfer your account?</StepTitle>
        <StepText>
          <StepIntroductionTypography>
            Select an option to find out more.
          </StepIntroductionTypography>
        </StepText>
      </HowToTransferStepContent>
      <StepActions $isHorizontal>
        <StepButton
          className="richBlue"
          onClick={() => onProceed({ transferMethod: TransferMethod.Cash })}
        >
          Transfer as cash
        </StepButton>
        {cedingProviderInSpecieSupport !== InSpecieSupport.NotSupported ? (
          <StepButton
            className="richBlue"
            onClick={() =>
              onProceed({ transferMethod: TransferMethod.InSpecie })
            }
          >
            Transfer my holdings
          </StepButton>
        ) : (
          <StepButton
            className="richBlue"
            onClick={() => handleIntercomClick()}
          >
            Transfer my holdings
          </StepButton>
        )}
      </StepActions>
      <StepActions>
        <GoBackButton onClick={onGoBack} />
      </StepActions>
    </StepContainer>
  );
}
