import {
  LinkStepButton,
  StepActions,
  StepButton,
  StepContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { dashboardPath } from 'paths';
import { useIntercom } from 'react-use-intercom';

export interface AbandonedStepProps {
  typeFriendly: string;
  ctaLabel?: string;
  ctaUrl?: string;
}

export function AbandonedStep({
  typeFriendly,
  ctaLabel,
  ctaUrl,
}: AbandonedStepProps) {
  const { showNewMessages } = useIntercom();

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support ',
      item_id: `upload documents by request - request expired`,
    });
    showNewMessages(
      'I was trying to upload a document but it says the request has expired'
    );
  };

  return (
    <StepContainer>
      <StepTitle>
        This request to upload your {typeFriendly} has expired
      </StepTitle>
      <StepText>
        <StepIntroductionTypography>
          We requested that you upload your {typeFriendly} but that request has
          since expired and there's nothing further to do.
        </StepIntroductionTypography>
      </StepText>

      <StepActions>
        <LinkStepButton
          to={ctaUrl ?? dashboardPath}
          className="magenta"
          fullWidth
        >
          {ctaLabel ?? 'My Dashboard'}
        </LinkStepButton>
        <StepButton className="richBlue" fullWidth onClick={handleSupport}>
          Talk to support
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
