import FallbackIcon from 'assets/icons/Property.png';
import React from 'react';

interface IconProps {
  color?: string;
  fallback?: boolean;
}

export function ICProperty({ color = '#000', fallback = false }: IconProps) {
  if (fallback) return <img src={FallbackIcon} alt="Properties" />;

  return (
    <>
      <svg
        viewBox="0 0 500 500"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Property</title>
        <g
          id="Property"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group" fill={color} fillRule="nonzero">
            <path
              d="M250,0 L0,250 L250,500 L500,250 L250,0 Z M245.707699,250 L190.256628,305.451071 L134.805557,250 L190.256628,194.548929 L245.707699,250 Z M194.548929,190.256628 L250,134.805557 L305.451071,190.256628 L250,245.707699 L194.548929,190.256628 Z M250,254.292301 L305.451071,309.743372 L250,365.270278 L194.548929,309.819208 L250,254.292301 Z M254.292301,250 L309.743372,194.548929 L365.270278,250 L309.819208,305.451071 L254.292301,250 Z M373.779045,250 L314.035673,190.256628 L372.853849,131.438452 L491.430565,250 L372.853849,368.576715 L314.035673,309.743372 L373.779045,250 Z M368.576715,127.146151 L309.743372,185.964327 L250,126.220955 L190.256628,185.964327 L131.438452,127.146151 L250,8.56943518 L368.576715,127.146151 Z M127.146151,131.438452 L185.964327,190.256628 L126.220955,250 L185.964327,309.743372 L127.146151,368.561548 L8.56943518,250 L127.146151,131.438452 Z M131.438452,372.853849 L190.256628,314.035673 L250,373.779045 L309.743372,314.035673 L368.576715,372.853849 L250,491.430565 L131.438452,372.853849 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </svg>
    </>
  );
}
