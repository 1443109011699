import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { HiX } from 'react-icons/hi';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 10px;
`;

interface UploadWrapperProps {
  fileSelected?: boolean;
}
export const UploadWrapper = styled.div<UploadWrapperProps>`
  display: flex;
  align-items: center;
  label {
    ${({ fileSelected }) => fileSelected && `pointer-events: none;`}
  }
  .upload-image-wrapper,
  .upload-button {
    margin-right: 2rem;
  }
`;

export const UploadLabel = styled(Text)`
  color: ${colors.darkBlue};
`;

export const UploadedImage = styled.div`
  width: 80px;
  height: auto;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    border-radius: 5px;
  }
`;

export const ClearIcon = styled(HiX)`
  color: rgba(22, 23, 16, 0.25);
  cursor: pointer;
  margin-left: 1rem;
`;
