import { colors } from 'constants/colors';
import { InfoPopover } from 'components/Popover';
import { Typography, IconButton } from '@material-ui/core';
import { OrderDirection } from 'generated/graphql';
import styled, { css } from 'styled-components';

const fontSize = css`
  font-size: 0.875;
`;
export interface DirectionProp {
  direction: OrderDirection;
}

export const OrderContainer = styled.div<DirectionProp>`
  width: 100%;
  position: relative;
  background-color: ${(p) =>
    p.direction === OrderDirection.Buy ? colors.magenta : colors.richBlue};
  color: ${colors.white};
  padding: ${(p) => p.theme.spacing(1.25)}px;
  border-radius: 5px;
`;

export const Content = styled(Typography)`
  ${fontSize}
  color: ${colors.white};
  font-weight: 500;
  padding-right: ${(p) => p.theme.spacing(1.25)}px;
  margin: 0;
`;

export const OrderDate = styled.span`
  color: ${colors.white};
  ${fontSize}
  font-weight: 500;
`;

export const OrderValue = styled.div`
  ${fontSize}
  padding: 0;
  white-space: nowrap;
  font-weight: 500;
  color: white;
`;

export const Download = styled(IconButton)`
  color: ${colors.white};
  ${fontSize}
  padding: 0px;
  padding-right: ${(p) => p.theme.spacing(1.25)}px;
`;

export const InfoIcon = styled(InfoPopover)`
  ${fontSize}
  padding: 0px;
  padding-right: ${(p) => p.theme.spacing(1.25)}px;
`;
