import {
  Input as MuiInput,
  InputProps as MuiInputProps,
} from '@material-ui/core';
import React from 'react';
import { get, useFormContext, UseFormMethods } from 'react-hook-form';
import { UnionToIntersection } from 'type-fest';

type RegisterFunction = UnionToIntersection<UseFormMethods['register']>;

export interface InputProps extends MuiInputProps {
  name: string;
  register?: RegisterFunction;
}

export function Input({ name, type = 'text', register, ...other }: InputProps) {
  const methods = useFormContext();

  const isError = !!get(methods.errors, name);

  return (
    <MuiInput
      name={name}
      id={name}
      type={type}
      inputRef={register || methods.register}
      error={isError}
      fullWidth={true}
      {...other}
    />
  );
}
