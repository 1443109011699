import {
  UserProfileQuery,
  useRegisterSippInterestMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { useQueryClient } from 'react-query';

export function useUpdateSippInterestMutation() {
  const queryClient = useQueryClient();

  return useRegisterSippInterestMutation({
    onSuccess: () => {
      queryClient.setQueryData<UserProfileQuery>(
        useUserProfileQuery.getKey(),
        (data) => {
          if (data?.userProfile) {
            data.userProfile.interestedInSipp = true;
          }

          return data!;
        }
      );
    },
  });
}
