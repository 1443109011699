import _ from 'lodash';

export const getInvestmentChangeLikelyEffectiveDate = (
  paymentDays: number[],
  hasPendingRegularExpectations: boolean,
  referenceDate: Date = new Date()
): Date | null => {
  if (!hasPendingRegularExpectations) {
    return null;
  } else if (!paymentDays.length) {
    return null;
  }

  const MINIMUM_SECCL_NOTICE_DAYS = 5;

  // For each payment day...
  const upcomingPaymentDatesProbablyWithoutExpectations = paymentDays.map(
    (paymentDay) => {
      // Get the date in this calendar month that represents this payment day...
      const thisMonthPaymentDate = new Date(
        referenceDate.getFullYear(),
        referenceDate.getMonth(),
        paymentDay
      );

      // ...and based on Seccl's typical notice period, wind it back to the point we expect Seccl to have created
      // the corresponding expectation
      let anticipatedExpectationCreationDate = new Date(thisMonthPaymentDate);
      anticipatedExpectationCreationDate.setDate(
        anticipatedExpectationCreationDate.getDate() - MINIMUM_SECCL_NOTICE_DAYS
      );

      // If that date's in the future then it's still valid, because we probably haven't created
      // the expectation yet (so: changes to an investment instruction would be effective). If it's
      // in the past then advance it by a month to get the next date at which we'd expect Seccl
      // to create an expectation.
      if (anticipatedExpectationCreationDate <= referenceDate) {
        anticipatedExpectationCreationDate = new Date(
          referenceDate.getFullYear(),
          referenceDate.getMonth() + 1,
          paymentDay
        );
        anticipatedExpectationCreationDate.setDate(
          anticipatedExpectationCreationDate.getDate() -
            MINIMUM_SECCL_NOTICE_DAYS
        );
      }

      return anticipatedExpectationCreationDate;
    }
  );

  // Find the earliest upcoming expectation creation date that we can still modify
  const earliestUpcomingModifiableExpectationCreationDate = _.min(
    upcomingPaymentDatesProbablyWithoutExpectations
  )!;

  // Wind forward the notice period to get back to a payment day
  const anticipatedExpectationCreationDate = new Date(
    earliestUpcomingModifiableExpectationCreationDate
  );

  anticipatedExpectationCreationDate.setDate(
    earliestUpcomingModifiableExpectationCreationDate.getDate() +
      MINIMUM_SECCL_NOTICE_DAYS
  );

  return anticipatedExpectationCreationDate;
};
