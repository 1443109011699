import { useMediaQuery, useTheme } from '@material-ui/core';
import { TextSmall, TextXS } from 'components/design-system/Text/Text';
import type { AutoSaveInvestBasketSummary } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { colors } from 'constants/colors';
import { useAuth } from 'context/AuthContext';
import { currencyFull } from 'formatting';
import { useUserProfileQuery } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import {
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { PortfolioRebalancingsSummaryQueryRebalancingsSummary } from 'types/graphqlTypes';
import { useMode } from '../useMode';

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    margin: 0;
    text-align: left;
  }
`;

const horizontalLayout = css`
  border-top: none;
  border-left: solid 1px;
  border-left-color: ${colors['purple-100']};
  padding-top: 0;
  padding-left: 1.5rem;
  margin-top: 0;
  margin-left: 1.5rem;
`;

interface BasketDetailsProps {
  $variant?: 'footer' | 'popover';
}

const BasketDetails = styled.div<BasketDetailsProps>`
  border-top: solid 1px ${colors['purple-100']};
  padding-top: 0.25rem;
  margin-top: 0.25rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ $variant }) => $variant === 'footer' && horizontalLayout}
  }

  a {
    color: ${colors['purple-900']};
    text-decoration: underline;
  }
`;

interface ResumeBasketsListProps {
  draftPortfolioRebalancings?: PortfolioRebalancingsSummaryQueryRebalancingsSummary[];
  basketSummaries?: AutoSaveInvestBasketSummary[] | null;
  variant: 'footer' | 'popover';
}

export const ResumeBasketsList = ({
  draftPortfolioRebalancings,
  basketSummaries,
  variant,
}: ResumeBasketsListProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [, setMode] = useMode();
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const isDarkBg = variant === 'popover' && isMdUp;

  return (
    <>
      {draftPortfolioRebalancings &&
        draftPortfolioRebalancings.map((portfolioRebalancing) => {
          const buyOrderTotal = portfolioRebalancing.buyOrders.reduce(
            (acc, buyOrder) => {
              return acc + buyOrder.amount;
            },
            0
          );
          const sellOrderTotal = portfolioRebalancing.sellOrders.reduce(
            (acc, sellOrder) => {
              return acc + sellOrder.enteredAmount;
            },
            0
          );
          const totalOrder = buyOrderTotal - sellOrderTotal;

          const account = userProfileQuery.data?.clientSummary?.accounts.find(
            (account) => {
              return account.id === portfolioRebalancing.accountId;
            }
          );

          return (
            <BasketDetails
              onClick={() => {
                setMode({ mode: 'buy', wrapperType: account?.wrapperType });
              }}
              $variant={variant}
            >
              <TextSmall $noMargin $isDarkBg={isDarkBg}>
                <strong>
                  {getShortNameForWrapperType(account?.wrapperType!)}
                </strong>{' '}
                - One off order
              </TextSmall>
              <TextXS $noMargin $isDarkBg={isDarkBg}>
                Order total {currencyFull(totalOrder)}{' '}
                <Link
                  onClick={() => {
                    setMode({
                      mode: 'buy',
                      wrapperType: account?.wrapperType!,
                    });
                  }}
                  to={generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      account?.wrapperType!
                    ),
                  })}
                >
                  View
                </Link>
              </TextXS>
            </BasketDetails>
          );
        })}

      {basketSummaries?.map((summary) => {
        return (
          <BasketDetails
            onClick={() => {
              setMode({
                mode: 'autoSaveInvest',
                wrapperType: summary.wrapperType,
              });
            }}
            $variant={variant}
          >
            <TextSmall $noMargin $isDarkBg={isDarkBg}>
              <strong>{summary.wrapperType}</strong> - Regular order
            </TextSmall>
            <TextXS $noMargin $isDarkBg={isDarkBg}>
              Order total{' '}
              {summary.depositsTotal && currencyFull(summary.depositsTotal)}{' '}
              <Link
                onClick={() => {
                  setMode({
                    mode: 'autoSaveInvest',
                    wrapperType: summary.wrapperType!,
                  });
                }}
                to={generateAutoSaveInvestPathPath({
                  wrapperType: getPathSegmentForWrapperType(
                    summary.wrapperType!
                  ),
                })}
              >
                View
              </Link>
            </TextXS>
          </BasketDetails>
        );
      })}
    </>
  );
};
