import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Loading } from 'components/Loading';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledA, StyledLink } from 'components/design-system/Link';
import {
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { AccountType, useReferralCodeQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import {
  StepContent,
  StepContentWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CheckboxContainer,
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ReferralWelcomeStep.styles';

interface ReferralWelcomeFormValues {
  optIntoComplianceDataSharing?: boolean;
}

interface ReferralWelcomeStepValues {
  optIntoComplianceDataSharing?: boolean;
  accountType: AccountType;
}

export interface ReferralWelcomeStepProps {
  referralCode: string;
  onProceed: (formValues: ReferralWelcomeStepValues) => void;
}

export function ReferralWelcomeStep({
  referralCode,
  onProceed,
}: ReferralWelcomeStepProps) {
  const { getSignInPath } = useAuth();
  const {
    data: referralCodeData,
    isLoading: referralDataLoading,
  } = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        const hasEmployer = !!data.referralCode?.employer;
        const contractNoteSharing =
          data?.referralCode?.employer?.complianceConfiguration
            ?.contractNoteSharing?.enabled ?? false;
        const initialAccountTypeSipp =
          data.referralCode?.initialAccountType === AccountType.Sipp;

        if (hasEmployer && (contractNoteSharing || initialAccountTypeSipp)) {
          return;
        }

        // We're only handling employer referrals for pensions & contract note sharing at the moment.
        // If the referral code is not for a pension or doesn't require contract note sharing, we'll just proceed.
        onProceed({ accountType: AccountType.NotSpecified });
      },
    }
  );

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;
  const initialAccountType = referralCodeData?.referralCode?.initialAccountType;
  const initialAccountTypeSipp = initialAccountType === AccountType.Sipp;

  const complianceConfiguration =
    referralCodeData?.referralCode?.employer?.complianceConfiguration;
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: ReferralWelcomeFormValues) => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.referralCode,
    });
    onProceed({
      accountType: initialAccountType || AccountType.NotSpecified,
      optIntoComplianceDataSharing: data.optIntoComplianceDataSharing,
    });
  };

  return referralDataLoading ? (
    <Loading />
  ) : (
    <ReferralWelcomeContainer>
      <StepContent>
        {initialAccountTypeSipp ? (
          <StepTitle $noMargin>
            Let's start your TILLIT Pension journey
          </StepTitle>
        ) : (
          <StepTitle $noMargin>Let's start your TILLIT journey</StepTitle>
        )}
      </StepContent>

      {referralCodeData?.referralCode?.employer?.logoUrl && (
        <ReferralCoBrandingContainer>
          <TillitLogo />
          <AiOutlineClose />
          <CoBrandedLogo
            src={referralCodeData?.referralCode?.employer?.logoUrl}
            alt={employerName || ''}
          />
        </ReferralCoBrandingContainer>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.extraWide}>
          {initialAccountTypeSipp && (
            <>
              <TextNormal $textAlign={TextAlign.center}>
                To switch your <strong>{employerName}</strong> pension to
                TILLIT, follow the steps on the screens. It should take 5-7
                minutes.
              </TextNormal>
            </>
          )}
          {contractNoteSharing && (
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    name="optIntoComplianceDataSharing"
                    inputRef={methods.register}
                  />
                }
                label={
                  complianceConfiguration?.policyLinkDescription ? (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their{' '}
                      {complianceConfiguration?.policyUrl ? (
                        <StyledA
                          href={complianceConfiguration?.policyUrl}
                          target="_blank"
                        >
                          {complianceConfiguration?.policyLinkDescription}{' '}
                          <HiExternalLink />
                        </StyledA>
                      ) : (
                        <>{complianceConfiguration?.policyLinkDescription}</>
                      )}
                    </>
                  ) : (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their trading policy
                    </>
                  )
                }
              />
            </CheckboxContainer>
          )}

          <TextNormal $textAlign={TextAlign.center}>
            If you have any questions, just click on the blue icon in the bottom
            right corner to speak to our team on live chat.
          </TextNormal>

          <ReferralActionsContainer>
            <CustomButtonV2 type="submit">Begin</CustomButtonV2>

            <TextSmall
              $textAlign={TextAlign.center}
              style={{ fontWeight: 300 }}
              $noMargin
            >
              Already have an account?
              <br />{' '}
              <StyledLink
                href={getSignInPath(undefined, undefined, referralCode)}
              >
                Sign in
              </StyledLink>
            </TextSmall>
          </ReferralActionsContainer>
        </StepContent>
      </form>
    </ReferralWelcomeContainer>
  );
}
