import React from 'react';
import { StyledSVG } from './SVGColorFilter.styles';

export function SVGColorFilter() {
  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg">
      <filter id="default">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0
                      0 0 0 0 0 
                      0 0 0 0 0 
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="dark">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0
                        0 0 0 0 0 
                        0 0 0 0 0 
                        0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="light">
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 1
                      0 1 0 0 1 
                      0 0 1 0 1 
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="Equities">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.57
                      0 0 0 0 0.42 
                      0 0 0 0 0.92 
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="Bonds">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.07
                      0 0 0 0 0.08
                      0 0 0 0 0.29
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="Property">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.84
                      0 0 0 0 0.09
                      0 0 0 0 0.92
                      0 0 0 1 0 "
        ></feColorMatrix>
      </filter>

      <filter id="Alternative-assets">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.23
                      0 0 0 0 0.35
                      0 0 0 0 0.61
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>

      <filter id="Multi-asset">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.56
                      0 0 0 0 0.03
                      0 0 0 0 0.56
                      0 0 0 1 0"
        ></feColorMatrix>
      </filter>
    </StyledSVG>
  );
}
