import { ActionType, WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import {
  actionsAddCashPath,
  actionsSetupMfa,
  actionsTransferPath,
  generateAutoSaveInvestSubPath,
} from 'paths';

export const actionSpecLookUp: Partial<
  {
    [key in ActionType]: {
      title: string;
      description: string;
      prompt: string;
      path?: string | ((wrapperType: WrapperType) => string);
    };
  }
> = {
  [ActionType.AddressUpload]: {
    title: 'Address Upload',
    description: 'We need you to upload some documents to verify your address.',
    prompt: 'Verify your address',
  },
  [ActionType.AddCash]: {
    title: 'Add Cash',
    description: 'Easily deposit cash via your banking app.',
    prompt: 'Add Cash',
    path: actionsAddCashPath,
  },
  [ActionType.FundAccount]: {
    title: 'Fund Account',
    description:
      'Now your account is ready how would you like to add funds to it?',
    prompt: 'fund account',
  },
  [ActionType.SetupMfa]: {
    title: 'Protect your account',
    description: 'Set up two-step verification to helps secure your account.',
    prompt: 'Protect you account',
    path: actionsSetupMfa,
  },
  [ActionType.SetupRegularDeposit]: {
    title: 'Start a regular deposit',
    description:
      'Set up a regular deposit from your banking app to top up your account each month.',
    prompt: 'Set up a regular deposit',
    path: (wrapperType) =>
      generateAutoSaveInvestSubPath({
        wrapperType: getPathSegmentForWrapperType(wrapperType),
        action: 'create-deposit',
      }),
  },
  [ActionType.StartTransfer]: {
    title: 'Consolidate your pensions',
    description: 'Transfer from other providers into your TILLIT Pension.',
    prompt: 'Transfer a pension',
    path: actionsTransferPath,
  },
  [ActionType.ReviewAmountCashYoureHolding]: {
    title: "You're holding a lot of cash",
    description:
      'You are holding a lot of cash in your account - and that is fine - but...',
    prompt: 'Read more',
  },
};
