import { TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import Select, {
  OptionProps,
  SingleValueProps,
  components,
} from 'react-select';
import styled from 'styled-components';

const options: OptionType[] = [
  { label: 'All', value: 'all' },
  { label: 'Recurring', value: 'recurring' },
  { label: 'One-offs', value: 'one-offs' },
];
export type OrderType = 'all' | 'recurring' | 'one-offs';

interface OptionType {
  label: string;
  value: OrderType;
}

interface CustomOptionContainerProps {
  isSelected: boolean;
}

const CustomOptionContainer = styled.div<CustomOptionContainerProps>`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? `background:${colors.magenta}; > p { color: ${colors.white}; }`
      : ''}
  &:hover {
    background: ${colors.purple};
    > p {
      color: ${colors.white};
    }
  }
`;

const CustomOption = ({
  innerProps,
  isDisabled,
  label,
  isSelected,
}: OptionProps<OptionType>) => {
  const { ref, ...rest } = innerProps;
  return !isDisabled ? (
    <CustomOptionContainer {...rest} isSelected={isSelected}>
      <TextSmall $noMargin>{label}</TextSmall>
    </CustomOptionContainer>
  ) : null;
};

const CustomSingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props}>
    <TextSmall $noMargin>Order type: {children}</TextSmall>
  </components.SingleValue>
);

interface SelectOrderTypeProps {
  selectedOrderType: OrderType;
  onChange: (orderType: OrderType) => void;
}

export const SelectOrderType = ({
  selectedOrderType,
  onChange,
}: SelectOrderTypeProps) => {
  return (
    <Select<OptionType>
      placeholder="Order Type"
      options={options}
      defaultValue={options.find((opt) => opt.value === selectedOrderType)}
      onChange={({ value }: OptionType) => onChange(value)}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
    />
  );
};
