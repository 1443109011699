import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { colors } from 'constants/colors';
import { lighten, transparentize } from 'polished';
import { FaShoppingBasket } from 'react-icons/fa';
import styled, { css, keyframes } from 'styled-components';
import { Text } from '../../Text/Text';

const fadeAnimation = keyframes`
  0% {opacity: 0.5;}
  50% {opacity: 1;}
  100% {opacity: 0.5;}
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
  }
`;

export const LoadingBasketIcon = styled(FaShoppingBasket)`
  opacity: 0.5;
  animation: ${fadeAnimation} 1s ease-in-out infinite;
`;

export const UnavailableBasketIcon = styled(FaShoppingBasket)`
  opacity: 0.5;
`;

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.65rem;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  width: 1rem;
  height: 1rem;
  color: ${colors.white};
  background-color: ${colors.magenta};
  border-radius: 0.5rem;
  pointer-events: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    top: -0.5rem;
    right: -0.75rem;
  }
`;

export const MiniBasketWrapper = styled.div`
  position: fixed;
  top: calc(${({ theme }) => theme.header.height} - 20px);
  left: 0;
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.header.height});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-start;
  gap: 0.5rem 0;
  pointer-events: none;
  opacity: 0;
  padding: 0 1rem 1rem 1rem;
  border-radius: 10px;
  background-color: ${colors.white};
  transition: transform 0.15s ease-in-out, max-height 0.15s ease-in-out,
    opacity 0.15s ease-in-out;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 1rem;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(9px);
    transform: translateY(40px);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: auto;
    max-height: 65vh;
    position: absolute;
    top: 2.6rem;
    left: -12.5rem;
    width: 25rem;
  }
`;

export const AccountTabWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px ${colors.lightGrey} solid;
`;

export interface AccountTabProps {
  $active: boolean;
}

export const AccountTab = styled.div<AccountTabProps>`
  flex: 1;
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: ${({ $active }) =>
    $active ? `2px ${colors.magenta} solid` : 'none'};
  font-weight: ${({ $active }) => ($active ? '500' : '400')};
  cursor: pointer;
`;

export const MiniBasketItemWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-height: 60vh;
  }
`;

export const MiniBasketNoAccount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;

  a.MuiButton-root {
    width: 100%;
  }
`;

export interface BasketItemProps {
  $isDarkUniverse: boolean;
}

export const MiniBasketItem = styled.div<BasketItemProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  border-top: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? 'none' : `1px ${colors.midGrey} solid`};
  padding: 1.25rem;
  background-color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.richBlack : 'transparent'};
  border-radius: ${({ $isDarkUniverse }) => ($isDarkUniverse ? '10px' : '0')};
  margin-bottom: ${({ $isDarkUniverse }) => ($isDarkUniverse ? '0.5rem' : '0')};

  p {
    color: ${({ $isDarkUniverse }) =>
      $isDarkUniverse ? colors.white : colors.richBlack};
  }

  &:first-child {
    border-top: none;
  }
`;

export const MiniBasketItemTitle = styled(Text)`
  width: 100%;
  margin: 0;
`;

export interface MiniBasketIndicatorProps {
  $type: 'buy' | 'sell';
  $isDarkUniverse: boolean;
}

const getPillBackgroundColor = (
  type: MiniBasketIndicatorProps['$type'],
  isDarkUniverse: boolean
) => {
  if (isDarkUniverse) {
    return lighten(0.5, type === 'buy' ? colors.success : colors.danger);
  } else {
    return transparentize(0.9, type === 'buy' ? colors.success : colors.danger);
  }
};

export const MiniBasketIndicator = styled.div<MiniBasketIndicatorProps>`
  background-color: ${({ $type, $isDarkUniverse }) =>
    getPillBackgroundColor($type, $isDarkUniverse)};
  color: ${({ $type }) => ($type === 'buy' ? colors.success : colors.danger)};
  padding: 0.25rem 1rem;
  border-radius: 1.5rem;
  text-align: center;
`;

export const MiniBasketItemPrice = styled(Text)`
  align-self: center;
  text-align: right;
`;

export const MiniBasketDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
`;

export const EmptyBasket = styled.p`
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: ${colors.lightGrey};
`;

export interface MiniBasketSummaryProps {
  $isSingle?: boolean;
}

export const MiniBasketSummaryWrapper = styled.div<MiniBasketSummaryProps>`
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 0 1rem;
  padding: 2rem 0 0.5rem 0;
  border-top: 1px ${colors.midGrey} solid;

  div:first-child {
    position: relative;
    ${({ $isSingle }) =>
      $isSingle &&
      css`
        grid-column: 2 / -1;
        text-align: right;
        padding-right: 1.25rem;
      `}

    ${({ $isSingle }) =>
      !$isSingle &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -0.5rem;
          width: 1px;
          height: 100%;
          background-color: ${colors.lightGrey};
          display: block;
        }
      `}
  }
`;

MiniBasketSummaryWrapper.defaultProps = {
  $isSingle: false,
};

export const MiniBasketActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  > * {
    flex: 1;
  }
`;

export const StyledButtonsWrapper = styled(ButtonsWrapper)`
  * {
    flex-grow: 1;
  }
`;
