import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  UserProfileQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useQueryClient } from 'react-query';

export type BasketType = 'AccountBasket' | 'LocalBasket';

export function useWhichBasketType(): BasketType {
  const { signedIn } = useAuth();
  const queryClient = useQueryClient();

  const clientSummary = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileQuery.getKey()
  )?.clientSummary!;

  const hasActiveAccount = clientSummary?.accounts.some(
    (acc) => acc.status === AccountStatus.Active
  );

  return signedIn && hasActiveAccount ? 'AccountBasket' : 'LocalBasket';
}
