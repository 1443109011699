import { QueryState } from 'components/QueryState';
import { AnimatePresence } from 'framer-motion';
import {
  DocumentUploadRequestStatus,
  useDocumentUploadRequestsQuery,
} from 'generated/graphql';
import { NotFound } from 'pages/NotFound/NotFound';
import { useMemo, useState } from 'react';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import { AbandonedStep } from './steps/AbandonedStep';
import { FilePickerStep } from './steps/FilePickerStep';
import { NoActionNeededStep } from './steps/NoActionNeededStep';
import { SuccessStep } from './steps/SuccessStep';

export interface UploadDocumentByRequestFormProps {
  /** The ID of the document upload request for which to show the upload form. */
  requestId: string;
  /** If true, suppresses the 'Reason' headline and promotes the reason text to the first paragraph of text on the form. Intended for use when the form appears as part of a flow, and where the context for the request is already likely to be obvious. */
  promoteReason?: boolean;
  /** Callback invoked when the flow is aborted by clicking 'I'll do this later' or similar. */
  onCancel: () => void;
  /** Callback invoked when the flow is completed successfully by uploading a document. */
  onComplete?: () => void;
  /** If true, skips the confirmation step and immediately invokes the `onComplete` callback. Intended for scenarios where the form is hosted within a larger flow. */
  skipConfirmation?: boolean;
}

enum UploadDocumentByRequestFormSteps {
  FilePicker = 'FilePicker',
  Success = 'Success',
  Error = 'Error',
  NotFound = 'NotFound',
  NoActionNeeded = 'NoActionNeeded',
  Abandoned = 'Abandoned',
}

export function UploadDocumentByRequestForm({
  requestId,
  promoteReason,
  onComplete,
  onCancel,
  skipConfirmation,
}: UploadDocumentByRequestFormProps) {
  const [activeStep, setActiveStep] = useState<
    UploadDocumentByRequestFormSteps | undefined
  >(undefined);

  const documentUploadRequestsQuery = useDocumentUploadRequestsQuery(
    undefined,
    {
      onSuccess: (data) => {
        if (activeStep !== undefined) {
          return;
        }

        const foundRequest = data.documentUploadRequests.find(
          (x) => x.id === requestId
        );

        if (!foundRequest) {
          setActiveStep(UploadDocumentByRequestFormSteps.NotFound);
        } else if (
          foundRequest.status === DocumentUploadRequestStatus.Abandoned
        ) {
          setActiveStep(UploadDocumentByRequestFormSteps.Abandoned);
        } else if (
          foundRequest.status === DocumentUploadRequestStatus.Complete
        ) {
          setActiveStep(UploadDocumentByRequestFormSteps.NoActionNeeded);
        } else if (
          foundRequest.status === DocumentUploadRequestStatus.Pending
        ) {
          setActiveStep(UploadDocumentByRequestFormSteps.FilePicker);
        }
      },
    }
  );

  const upload = useMemo(
    () =>
      documentUploadRequestsQuery.data?.documentUploadRequests.find(
        (x) => x.id === requestId
      ),
    [documentUploadRequestsQuery.data, requestId]
  );

  return (
    <Wrapper isTallForm={true}>
      <QueryState {...documentUploadRequestsQuery}>
        {() => {
          return (
            <>
              {(activeStep === UploadDocumentByRequestFormSteps.NotFound && (
                <NotFound />
              )) || (
                <AnimatePresence>
                  {activeStep ===
                    UploadDocumentByRequestFormSteps.FilePicker && (
                    <FilePickerStep
                      typeFriendly={upload!.typeFriendly.toLowerCase()}
                      reason={promoteReason ? undefined : upload!.reason}
                      welcomePromptMessage={
                        promoteReason ? upload!.reason : undefined
                      }
                      requestId={upload!.id!}
                      guidance={upload!.message}
                      onComplete={() => {
                        if (skipConfirmation) {
                          onComplete?.();
                        } else {
                          setActiveStep(
                            UploadDocumentByRequestFormSteps.Success
                          );
                        }
                      }}
                      onCancel={onCancel}
                    />
                  )}
                  {activeStep === UploadDocumentByRequestFormSteps.Success && (
                    <SuccessStep
                      typeFriendly={upload!.typeFriendly.toLowerCase()}
                    />
                  )}
                  {activeStep ===
                    UploadDocumentByRequestFormSteps.NoActionNeeded && (
                    <NoActionNeededStep
                      typeFriendly={upload!.typeFriendly.toLowerCase()}
                    />
                  )}
                  {activeStep ===
                    UploadDocumentByRequestFormSteps.Abandoned && (
                    <AbandonedStep
                      typeFriendly={upload!.typeFriendly.toLowerCase()}
                    />
                  )}
                </AnimatePresence>
              )}
            </>
          );
        }}
      </QueryState>
    </Wrapper>
  );
}
