import { yupResolver } from '@hookform/resolvers/yup';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  ModeRadios,
  RadioModeValues,
} from 'components/design-system/FormElements/ModeRadios';
import {
  H3,
  H5,
  H6,
  TextAlign,
} from 'components/design-system/Heading/Heading';
import {
  FontSize,
  FontWeight,
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import * as formatting from 'formatting';
import {
  AccountStatus,
  WrapperType,
  useAccountsQuery,
  useRecurringTransactionsQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AssetQueryAsset } from 'types/graphqlTypes';
import * as Yup from 'yup';
import {
  Pill,
  PillContainer,
} from '../../PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import {
  AccountCard,
  NewCardContainer,
  SelectAccountCardInner,
  SelectModeAccountWrapper,
  SelectedAccountAlert,
} from '../../PortfolioBuilder/AddToBasket/steps/SelectAccountStepBuy.styles';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';

interface ModeFormValues {
  selectedMode: RadioModeValues;
}

const modeFormSchema = Yup.object({
  selectedMode: Yup.string().required(),
});

type modeFormSubmittedValues = Yup.InferType<typeof modeFormSchema>;

interface onProceedProps {
  selectedMode: 'buy' | 'autoSaveInvest';
  accountType: WrapperType;
  accountId: string;
}

interface SelectModeAccountStepBuyProps {
  onProceed: ({
    selectedMode,
    selectedAccountType,
    selectedAccountId,
  }: {
    selectedMode: 'buy' | 'autoSaveInvest';
    selectedAccountType: WrapperType;
    selectedAccountId: string;
  }) => void;
  asset: AssetQueryAsset;
  defaultSelectedMode: 'buy' | 'autoSaveInvest';
}

export function SelectModeAccountStepBuy({
  onProceed,
  asset,
  defaultSelectedMode = 'buy',
}: SelectModeAccountStepBuyProps) {
  const [accountNoFunds, setAccountNoFunds] = useState<WrapperType | null>(
    null
  );
  const accountsQuery = useAccountsQuery();
  const accounts = accountsQuery.data?.accounts;
  const activeAccounts = accounts?.filter((account) => {
    return account.status === AccountStatus.Active;
  });

  const defaultValues: ModeFormValues = {
    selectedMode: defaultSelectedMode,
  };

  const methods = useForm<ModeFormValues>({
    defaultValues,
    resolver: yupResolver(modeFormSchema),
    mode: 'onChange',
  });

  const { handleSubmit, formState, register, errors, watch } = methods;

  const onSubmit: SubmitHandler<modeFormSubmittedValues> = async (data) => {
    if (formState.isDirty) {
      return;
    }
  };

  const selectedMode = watch('selectedMode');

  useEffect(() => {
    if (formState.isValid && !formState.isValidating) {
      setAccountNoFunds(null);
    }
  }, [formState.isValid, formState.isValidating]);

  const handleCardOnProceed = ({
    accountType,
    selectedMode,
    accountId,
  }: onProceedProps) => {
    onProceed({
      selectedMode: selectedMode,
      selectedAccountType: accountType,
      selectedAccountId: accountId,
    });
  };

  const handleCardOnNoFunds = (accountType: WrapperType) => {
    setAccountNoFunds(accountType);
  };

  return (
    <SelectModeAccountWrapper>
      <PillContainer>
        <Pill $filled $color="buy">
          BUY
        </Pill>
      </PillContainer>
      <H3>{asset.name}</H3>

      <H6 $noMargin>How you want to buy?</H6>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ModeRadios
          name="selectedMode"
          register={register}
          selectedMode={selectedMode}
          error={errors.selectedMode}
          defaultValue={defaultValues.selectedMode}
        />
      </form>

      <QueryState {...accountsQuery}>
        {({ data }) => (
          <>
            <H6 $noMargin>Pick an account</H6>
            <NewCardContainer>
              {activeAccounts?.map((account) => {
                return (
                  <>
                    {selectedMode === 'buy' && (
                      <AccountAvailableCashCard
                        accountId={account.id}
                        selectedMode={selectedMode}
                        accountType={account.wrapperType}
                        availableCash={
                          account.valuationSummary?.uninvestedCash || 0
                        }
                        onProceed={handleCardOnProceed}
                      />
                    )}
                    {selectedMode === 'autoSaveInvest' && (
                      <AccountRecurringCard
                        accountId={account.id}
                        selectedMode={selectedMode}
                        accountType={account.wrapperType}
                        accountNoFunds={accountNoFunds}
                        onProceed={handleCardOnProceed}
                        onNoFunds={handleCardOnNoFunds}
                      />
                    )}
                  </>
                );
              })}
            </NewCardContainer>
          </>
        )}
      </QueryState>

      {accountNoFunds && (
        <>
          <SelectedAccountAlert>
            <TextNormal $noMargin $textAlign={TextAlign.center}>
              You can add this fund to your draft regular order, but you will
              need to setup a monthly deposit before you confirm the changes.
            </TextNormal>
          </SelectedAccountAlert>

          <CustomButtonV2
            onClick={() => {
              onProceed({
                selectedMode: selectedMode,
                selectedAccountType: accountNoFunds,
                selectedAccountId: activeAccounts?.find(
                  (account) => account.wrapperType === accountNoFunds
                )?.id!,
              });
            }}
          >
            Continue
          </CustomButtonV2>
        </>
      )}
    </SelectModeAccountWrapper>
  );
}

interface AccountAvailableCashCardProps {
  accountId: string;
  selectedMode: 'buy' | 'autoSaveInvest';
  accountType: WrapperType;
  availableCash: number;
  onProceed: ({ accountType, selectedMode, accountId }: onProceedProps) => void;
}

function AccountAvailableCashCard({
  accountId,
  selectedMode,
  accountType,
  availableCash,
  onProceed,
}: AccountAvailableCashCardProps) {
  return (
    <AccountCard
      key={accountId}
      onClick={() => {
        if (!accountId) {
          return;
        }

        onProceed({
          selectedMode,
          accountType,
          accountId,
        });
      }}
      fullCardPointer
    >
      <SelectAccountCardInner>
        <H5 $textAlign={TextAlign.center} $noMargin>
          {getShortNameForWrapperType(accountType)}
        </H5>
        <div>
          <Text
            $fontSize={FontSize.large}
            $fontWeight={FontWeight.medium}
            $textAlign={TextAlign.center}
            $noMargin
          >
            {formatting.currencyFull(availableCash)}
          </Text>
          <TextSmall $textAlign={TextAlign.center} $noMargin>
            Available cash
          </TextSmall>
        </div>
      </SelectAccountCardInner>
    </AccountCard>
  );
}

interface AccountRecurringCardProps {
  accountId: string;
  selectedMode: 'buy' | 'autoSaveInvest';
  accountType: WrapperType;
  accountNoFunds: WrapperType | null;
  onProceed: ({ accountType, selectedMode, accountId }: onProceedProps) => void;
  onNoFunds: (accountType: WrapperType) => void;
}

function AccountRecurringCard({
  accountId,
  selectedMode,
  accountType,
  accountNoFunds,
  onProceed,
  onNoFunds,
}: AccountRecurringCardProps) {
  const recurringTransactionQuery = useRecurringTransactionsQuery(
    { accountId },
    { enabled: !!accountId }
  );

  return (
    <QueryState {...recurringTransactionQuery}>
      {({ data }) => {
        const totalEmployee =
          data?.recurringTransactions?.deposits?.reduce(
            (acc, curr) => curr.autoInvest && acc + curr.amount,
            0
          ) || 0;

        const totalEmployer =
          data?.recurringTransactions?.latestEmployerContribution?.amount || 0;
        const total = totalEmployee + totalEmployer;

        return (
          <AccountCard
            key={accountId}
            onClick={() => {
              if (!accountId) {
                return;
              }
              if (total === 0) {
                onNoFunds(accountType);
              } else {
                onProceed({
                  selectedMode,
                  accountType,
                  accountId,
                });
              }
            }}
            $selected={accountType === accountNoFunds && !!accountNoFunds}
            fullCardPointer
          >
            <SelectAccountCardInner>
              <H5 $textAlign={TextAlign.center} $noMargin>
                {getShortNameForWrapperType(accountType)}
              </H5>
              <div>
                <Text
                  $fontSize={FontSize.large}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.center}
                  $noMargin
                >
                  {formatting.currencyFull(total)}
                </Text>
                <TextSmall $textAlign={TextAlign.center} $noMargin>
                  {totalEmployer > 0 ? (
                    <>
                      Est. monthly deposit
                      <InfoPopoverV2
                        color="default"
                        $width="wide"
                        placement={'top'}
                        size="small"
                        content={
                          <>
                            <TextSmall $noMargin>
                              This includes an estimate of how much your
                              employer pays in each month, based on the last
                              payment they made
                            </TextSmall>
                          </>
                        }
                      />
                    </>
                  ) : (
                    'Monthly deposit'
                  )}
                </TextSmall>
              </div>
            </SelectAccountCardInner>
          </AccountCard>
        );
      }}
    </QueryState>
  );
}
