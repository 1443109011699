import { FundDetails } from 'components/FundDetails/FundDetails';
import { H4 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { TextNormal } from 'components/design-system/Text/Text';
import { useAuth } from 'context/AuthContext';
import * as format from 'formatting';
import { useUserProfileQuery } from 'generated/graphql';
import {
  EstimatedFundFee,
  EstimatedTillitFee,
  EstimatedTotalFee,
} from 'strings/tooltips';
import { FundInfoBoxSection } from 'types/graphqlTypes';
import {
  DetailsContainer,
  StyledB,
  StyledDetailsBox,
  StyledDetailsContent,
} from './Styles/DetailsBox.style';

interface FundsCostsAndChargesLegacyProps {
  fundInfo: NonNullable<FundInfoBoxSection>;
  lumpSum: number;
}

export function FundsCostsAndChargesLegacy({
  fundInfo,
  lumpSum,
}: FundsCostsAndChargesLegacyProps) {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const ocfFundInfo = fundInfo?.data?.find(
    (x) => (x !== null && x.name) === 'OCF'
  );

  if (
    ocfFundInfo === undefined ||
    ocfFundInfo === null ||
    typeof ocfFundInfo.valueRendered !== 'string'
  ) {
    return null;
  }
  const ocfFundValue = Number(ocfFundInfo.valueRendered);
  const platformFeeProportion =
    userProfileQuery.data?.clientSummary?.platformFeeProportion || 0.004;

  const platformFee = format.percent(platformFeeProportion);

  const ocfValue = format.currencyFull(lumpSum * (ocfFundValue / 100));
  const platformFeeValue = format.currencyFull(
    lumpSum *
      (userProfileQuery.data?.clientSummary?.platformFeeProportion || 0.004)
  );
  const totalFeesPercent = format.percent(
    (userProfileQuery.data?.clientSummary?.platformFeeProportion || 0.004) +
      ocfFundValue / 100
  );
  const totalFeesValue = format.currencyFull(
    lumpSum *
      ((userProfileQuery.data?.clientSummary?.platformFeeProportion || 0.004) +
        ocfFundValue / 100)
  );

  const totalReturn = format.currencyFull(
    lumpSum -
      lumpSum *
        ((userProfileQuery.data?.clientSummary?.platformFeeProportion ||
          0.004) +
          ocfFundValue / 100)
  );

  return (
    <StyledDetailsBox>
      <H4>Costs and charges</H4>
      <TextNormal>
        These are the annual costs based on an initial investment amount of{' '}
        <StyledB>{format.currencyFull(lumpSum)}</StyledB> over{' '}
        <StyledB>1 year</StyledB> assuming a net{' '}
        <StyledB>0% growth rate</StyledB>.
      </TextNormal>
      <StyledDetailsContent>
        <DetailsContainer>
          <FundDetails
            label={
              <>
                Fund OCF{' '}
                <InfoPopoverV2
                  color={'primary'}
                  $width="wide"
                  placement={'top-end'}
                  content={<EstimatedFundFee />}
                />
              </>
            }
            value={`${ocfValue} / ${ocfFundInfo?.valueRendered}% pa`}
            variant="outlined"
          />
          <FundDetails
            label={
              <>
                TILLIT platform fee{' '}
                <InfoPopoverV2
                  color={'primary'}
                  $width="wide"
                  placement={'top-end'}
                  content={<EstimatedTillitFee />}
                />
              </>
            }
            value={`${platformFeeValue} / ${platformFee} pa`}
            variant="outlined"
          />
          <FundDetails
            label={
              <>
                Total est. ongoing cost{' '}
                <InfoPopoverV2
                  color={'primary'}
                  $width="wide"
                  placement={'top-end'}
                  content={
                    <EstimatedTotalFee value={format.currencyFull(lumpSum)} />
                  }
                />
              </>
            }
            value={`${totalFeesValue} / ${totalFeesPercent} pa`}
            variant="outlined"
          />
          <FundDetails
            label={<>Total est. return</>}
            value={totalReturn}
            variant="outlined"
          />
        </DetailsContainer>
      </StyledDetailsContent>
    </StyledDetailsBox>
  );
}
