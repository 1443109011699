import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Container } from 'components/Container/Container.style';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledA } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import * as formatting from 'formatting';
import {
  EmployerComplianceConfigurationType,
  useUpdateComplianceDataSharingOptInMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { useIntercom } from 'react-use-intercom';
import { Base } from './Base';
import { ConfirmOptOutDialog } from './ConfirmOptOutDialog';
import { useDialog } from 'hooks/useDialog';
import {
  DoubleCol,
  EmployerNoteForwarding,
  SingleAction,
} from './MyDetails.style';

interface ComplianceDataSharingProps {
  complianceConfiguration: EmployerComplianceConfigurationType;
}

function ComplianceDataSharing({
  complianceConfiguration,
}: ComplianceDataSharingProps) {
  const dialogProps = useDialog();
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const employer = userProfile?.employments?.[0];
  const [isOptedIn, setOptedIn] = useState(
    employer?.optedIntoComplianceDataSharing || false
  );
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const {
    mutateAsync: updateComplianceDataSharingOptIn,
    isLoading,
    isError,
  } = useUpdateComplianceDataSharingOptInMutation();

  const updatePreference = async () => {
    try {
      await updateComplianceDataSharingOptIn({
        input: {
          employerId: employer?.employer.id!,
          optIntoComplianceDataSharing: !isOptedIn,
        },
      });
      setOptedIn(!isOptedIn);
      dialogProps.closeDialog();
    } catch {
      // Error handled by state
    }
  };

  const handleChange = () => {
    isOptedIn ? dialogProps.openDialog() : updatePreference();
  };

  return (
    <>
      {employer && contractNoteSharing && (
        <>
          <ConfirmOptOutDialog
            employerName={
              employer.employer.displayName || employer.employer.companyName
            }
            onConfirm={updatePreference}
            isLoading={isLoading}
            {...dialogProps}
          />
          <EmployerNoteForwarding>
            <FormControlLabel
              control={
                <Checkbox
                  name="optIntoComplianceDataSharing"
                  checked={isOptedIn}
                  onChange={handleChange}
                  disabled={isLoading}
                />
              }
              label={
                complianceConfiguration?.policyLinkDescription ? (
                  <>
                    Forward contract notes to{' '}
                    <strong>
                      {employer.employer.displayName ||
                        employer.employer.companyName}
                    </strong>
                    , as per their{' '}
                    {complianceConfiguration?.policyUrl ? (
                      <StyledA
                        href={complianceConfiguration?.policyUrl}
                        target="_blank"
                      >
                        {complianceConfiguration?.policyLinkDescription}{' '}
                        <HiExternalLink />
                      </StyledA>
                    ) : (
                      <>{complianceConfiguration?.policyLinkDescription}</>
                    )}
                  </>
                ) : (
                  <>
                    Forward contract notes to{' '}
                    <strong>
                      {employer.employer.displayName ||
                        employer.employer.companyName}
                    </strong>
                    , as per their trading policy
                  </>
                )
              }
            />
            {isError && <ServerError isVisible={isError} />}
          </EmployerNoteForwarding>
        </>
      )}
    </>
  );
}

export function EmployerDetails() {
  const { showNewMessages } = useIntercom();
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const employer = userProfile?.employments?.[0];

  if (!employer) return null;

  const complianceConfiguration = employer.employer.complianceConfiguration;
  const handleClick = () => {
    showNewMessages(`I no longer work at the employer showed on my details.`);
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'dashboard - my details - employer',
      content_type: `dashboard - my details - employer- user no longer works there clicked`,
    });
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <Base
          title="Employer details"
          logoUrl={employer.employer.logoUrl || undefined}
          logoAlt={employer.employer.companyName}
        >
          <Container padding="none">
            <ReadOnlyInput
              id="employerName-read-only-input"
              label="Employer name"
              defaultValue={
                employer.employer.displayName || employer.employer.companyName
              }
            />

            <DoubleCol>
              <ReadOnlyInput
                id="employerJoinDate-read-only-input"
                label="Employment start date"
                defaultValue={formatting.date(employer.startDate)}
              />

              {employer.endDate && (
                <ReadOnlyInput
                  id="employerEndDate-read-only-input"
                  label="Employment end date"
                  defaultValue={formatting.date(employer.endDate)}
                />
              )}
            </DoubleCol>

            {complianceConfiguration && (
              <ComplianceDataSharing
                complianceConfiguration={complianceConfiguration}
              />
            )}

            <SingleAction>
              <CustomButtonV2 $color="secondary" onClick={handleClick}>
                I no longer work here
              </CustomButtonV2>
            </SingleAction>
          </Container>
        </Base>
      )}
    </QueryState>
  );
}
