import { colors } from 'constants/colors';
import { lighten } from 'polished';
import React from 'react';

export interface ZeroLineProps {
  y: number;
  length: number;
}

export function ZeroLine({ y, length }: ZeroLineProps) {
  return (
    <line
      x1={0}
      x2={length}
      y1={y}
      y2={y}
      strokeWidth="1px"
      stroke={lighten(0.8, colors.richBlack)}
    />
  );
}
