import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  GoBackButton,
  StepActions,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { TextNormal } from 'components/design-system/Text/Text';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';

export interface ConfirmOptOutDialogProps extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  employerName: string;
}

export function ConfirmOptOutDialog({
  closeDialog,
  open,
  onConfirm,
  isLoading = false,
  employerName,
}: ConfirmOptOutDialogProps) {
  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      <StepTitle>Confirm your change</StepTitle>
      <TextNormal>
        If you opt out of contract note forwarding for{' '}
        <strong>{employerName}</strong> you may have to download and forward
        them manually depending on their compliance policies.
      </TextNormal>
      <TextNormal>
        Are you sure you want to stop forwarding contract notes to{' '}
        <strong>{employerName}</strong> automatically?
      </TextNormal>
      <StepActions>
        <CustomButtonV2 $isWide onClick={onConfirm} disabled={isLoading}>
          Confirm
        </CustomButtonV2>
        <GoBackButton onClick={closeDialog} />
      </StepActions>
    </ResponsiveDialog>
  );
}
