/**
 * @file - InfoPage styles.
 *
 * Styles for a page with a big title, and a short description like a 404 page and fund not fund page.
 */

import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  margin: 8rem auto;
  max-width: 900px;
`;

export const Heading = styled(Typography).attrs({ variant: 'h1' })`
  color: ${colors.magenta};
  font-weight: 300;
  font-size: 3.5rem;
  margin: 2rem 0;
`;

export const Description = styled(Typography)`
  font-weight: 300;
  margin: 1rem 0;
  max-width: 660px;
  margin: 0 0 60px 0;
`;
