import { InvestmentCommittee } from 'components/InvestmentCommittee/InvestmentCommittee';
import { H3Bold, TextAlign } from 'components/design-system/Heading/Heading';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';

import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

export function ICReports() {
  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'ic reports tab opened',
    });
  });

  return (
    <>
      <H3Bold $textAlign={TextAlign.center}>IC reports</H3Bold>
      <InvestmentCommittee />
    </>
  );
}
