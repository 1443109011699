import { useAuth } from 'context/AuthContext';
import { GraphQLClient } from 'graphql-request';
import { tillitFetch } from './tillitFetch';

const client = new GraphQLClient('/graphql', {
  fetch: tillitFetch,
});

export default client;

/**
 * This hook is used by the hooks generated by GraphQL code generator
 * @see https://www.the-guild.dev/graphql/codegen/plugins/typescript-react-query#usage-example-isreacthook-true
 * @param query GraphQL query
 * @param options Query variables
 * @returns
 */
export const useFetcher = <TData, TVariables, TContext = unknown>(
  query: string,
  options?: RequestInit['headers']
): ((
  variables?: TVariables,
  context?: TContext
) => Promise<TData | (TData & { context: TContext })>) => {
  const { handleAutoSignout } = useAuth();

  return async (variables?: TVariables, context?: TContext) => {
    try {
      client.setHeaders(options);
      const res = await client.request<TData, TVariables>(query, variables);
      if (context) {
        return { ...res, context };
      }
      return res;
    } catch (err: any) {
      const anyAuthError = err?.response?.errors?.some(
        (e: any) =>
          e.extensions?.code?.toLowerCase() === 'authorization' ||
          e.extensions?.code?.toLowerCase() === 'access_denied' ||
          (!!e.message && e.message.match(/AssertionRequirement/gi))
      );

      if (anyAuthError) {
        console.log('Authorization error', { query, variables, options });
        handleAutoSignout();
      }

      throw err?.response?.errors || new Error(err?.response?.errors);
    }
  };
};
