import { colors } from 'constants/colors';
import React from 'react';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import styled from 'styled-components';

export enum Severity {
  success = 'SUCCESS',
  error = 'error',
  info = 'info',
}

export type Padding = 'small' | 'normal';

const colourLookup = {
  [Severity.success]: '#0acf97',
  [Severity.error]: '#E54938',
  [Severity.info]: colors['purple-200'],
};

const bgColourLookup = {
  [Severity.success]: 'transparent',
  [Severity.error]: 'transparent',
  [Severity.info]: colors['purple-50'],
};

type IconLookupType = {
  [key in Severity]: JSX.Element;
};

const iconLookup: IconLookupType = {
  [Severity.success]: (
    <HiOutlineCheckCircle fontSize="1.5rem" color="#0acf97" />
  ),
  [Severity.error]: (
    <HiOutlineExclamationCircle fontSize="1.5rem" color="#E54938" />
  ),
  [Severity.info]: (
    <HiOutlineExclamationCircle
      fontSize="1.5rem"
      color={colors['purple-500']}
    />
  ),
};

interface AlertContainerProps {
  $severity: Severity;
  children: React.ReactNode;
  $padding: Padding;
}

const AlertContainer = styled.div<AlertContainerProps>`
  padding: ${({ $padding }) =>
    $padding === 'normal' ? '1rem' : '0.5rem 1rem'};
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 0.75rem;
  border: 1px solid ${({ $severity }) => colourLookup[$severity]};
  border-radius: 0.25rem;
  justify-content: center;
  background-color: ${({ $severity }) => bgColourLookup[$severity]};
`;

type AlertArgs = Parameters<typeof AlertContainer>;
type AlertProps = Omit<AlertArgs[0], '$severity'> & {
  severity: Severity;
  padding?: Padding;
};

export const Alert = ({
  children,
  severity,
  padding = 'normal',
  ...props
}: AlertProps) => {
  const icon = iconLookup[severity];
  return (
    <AlertContainer $severity={severity} $padding={padding} {...props}>
      {icon}
      {children}
    </AlertContainer>
  );
};
