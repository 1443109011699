import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import CardBackground from 'assets/referrals_card_background.jpg';
import { Container } from 'components/Container/Container.style';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledTextField } from 'components/Input';
import { colors } from 'constants/colors';
import React from 'material-ui-popup-state/node_modules/@types/react';
import styled from 'styled-components';
import { Title } from '../Cards.styles';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 20px 0;
  }

  p {
    margin: ${(p) => p.theme.spacing(1, 0)};
    color: ${colors.white};
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;

    span {
      font-weight: 500;
    }
  }
`;

export const CardContainer = styled(Container).attrs({
  $elevation: 1,
  padding: 'medium',
})`
  background-image: url(${CardBackground});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${Title} {
    color: ${colors.white};
  }

  ${StyledTextField} {
    margin-top: 0;

    label {
      &.Mui-disabled {
        color: ${colors.white};
      }
    }
    .MuiInput-root {
      .MuiInput-input {
        direction: rtl;
        text-overflow: ellipsis;
        text-align: left;
      }
      &.Mui-disabled {
        color: ${colors.white};
        &:before {
          border-bottom: 1px solid ${colors.white};
        }
      }
    }
  }
`;

export const CodeContainer = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: ${colors.white};
  padding: ${(p) => p.theme.spacing(1)}px;

  canvas {
    display: block;
  }
`;

export const DialogContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const QRCodeWrapper = styled.div`
  padding: 10px;
  background-color: ${colors.richBlue};
  border-radius: 5px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  flex: 1 1 auto;
  max-width: 235px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px 75px;
  gap: 5px;

  margin: 20px 0 0;

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: 0;
  }
`;

export const TileButton = styled(ButtonBase)<
  ButtonBaseProps<
    React.ElementType<any>,
    { component?: React.ElementType<any> }
  >
>`
  color: ${colors.richBlue};
  background-color: rgba(226, 33, 246, 0.05);
  font-size: 36px;
  width: 100%;
  max-width: 75px;

  &:hover {
    background-color: rgba(226, 33, 246, 0.15);
  }
`;

export const CardButton = styled(CustomButtonV2)`
  width: 100%;
`;
