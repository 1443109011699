import styled from 'styled-components';
import { colors } from 'constants/colors';
import { Tabs } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export const StyledTavNav = styled.div`
  position: sticky;
  width: 100%;
  top: 5rem;
  z-index: ${({ theme }) => theme.zIndex.other};
  display: flex;
  align-content: center;
  padding: 0 1rem;
  justify-content: center;
  gap: 1rem;
  background: ${colors['magenta-25']};
  flex-wrap: wrap;
  body.banner--open & {
    top: 8rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: auto;
  .MuiTabs-indicator {
    background-color: transparent;
  }

  .MuiButtonBase-root {
    padding: 0.5rem 1rem;
    height: auto;
    width: auto;
    min-width: auto;
  }

  .MuiButtonBase-root {
    padding: 0;
    .MuiTab-wrapper {
      flex-grow: 1;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${colors.richBlack};
  text-decoration: none;
  padding: 0.875rem 0.5rem;
  text-transform: none;
  font-size: 1rem;
  font-weight: 400;
  a&,
  a&:visited,
  a&:hover,
  a&:focus {
    outline: none;
  }

  &.active {
    color: ${colors['magenta']};
  }

  &:hover,
  &:focus {
    color: ${colors['magenta']};
  }
`;
