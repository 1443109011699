import { Card } from 'components/design-system/Card/Card';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const CardButtonWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 45rem;
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const CardButton = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  justify-items: center;
  text-align: center;

  ${Text} {
    align-self: end;
  }
`;

export const Divider = styled.hr`
  display: inline-block;
  width: 1.5rem;
  border-color: rgba(215, 22, 235, 0.25);
  border-width: 0 0 1px 0;
  margin: 0.5rem 0;
`;

export const IllustrationLink = styled.a`
  max-width: 23rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 1rem;
  color: ${colors.richBlack};
  background-color: ${colors['grey-100']};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: left;
  margin: 0 auto;
`;
