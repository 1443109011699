import { GaEventNames } from 'constants/gaConstants';
import {
  AccountType,
  useOnboardingStatusQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useQueryClient } from 'react-query';

type OnProceed = (accType: AccountType) => void;

interface useOpenAccountOptions {
  onProceed?: OnProceed;
}

export function useOpenAccount(options?: useOpenAccountOptions) {
  const onProceed = options?.onProceed;
  const { mutateAsync } = useUpdateUserProfileMutation();
  const queryClient = useQueryClient();

  const openAccount = async (accType: AccountType, source: string) => {
    await mutateAsync(
      {
        input: {
          initialAccount: {
            value: accType,
          },
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(useUserProfileQuery.getKey());
          queryClient.invalidateQueries(useOnboardingStatusQuery.getKey());
        },
      }
    );

    trackGa({
      event: GaEventNames.onboardingStart,
      item_id: `open account - ${accType}`,
    });

    if (onProceed) {
      onProceed(accType);
    }
  };

  return { openAccount };
}
