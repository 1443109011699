import { AccountType } from 'generated/graphql';
import { useUpdateInitialAccountTypeMutation } from 'hooks/useUpdateInitialAccountTypeMutation';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';
import { StepProps } from '../_shared/StepProps';

export interface CannotOpenSippProps extends StepProps {}

export function CannotOpenSipp({ onProceed, onGoBack }: CannotOpenSippProps) {
  const {
    mutateAsync: updateInitialAccountTypeAsync,
    isError,
    isLoading,
  } = useUpdateInitialAccountTypeMutation();

  const handleContinue = async () => {
    await updateInitialAccountTypeAsync({
      input: {
        initialAccount: { value: AccountType.NotSpecified },
      },
    });
    onProceed();
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>We can't offer you a TILLIT Pension yet</StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            Due to tax rules, you cannot open or contribute to a Self-Invested
            Personal Pension like the TILLIT Pension if you are 75 or older. If
            you made a mistake entering your date of birth you can go back and
            correct it, or you can continue to open a different kind of TILLIT
            investment account
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      {isError && <ServerError isVisible />}
      <StepActions>
        <StepButton
          type="submit"
          className="magenta"
          onClick={handleContinue}
          disableElevation={isLoading}
        >
          Continue
        </StepButton>
        <GoBackButton onClick={handleBack} />
      </StepActions>
    </StepContainer>
  );
}
