import { PasswordStrengthAssessmentBreakdown } from 'helpers/passwordValidation';
import React from 'react';
import {
  PasswordStrengthCheck,
  PasswordStrengthCheckContainer,
} from './PasswordStrengthCheck.styles';

export type PasswordStrengthIndicatorProps = {
  breakdown: PasswordStrengthAssessmentBreakdown;
};

export const PasswordStrengthBreakdownIndicator = ({
  breakdown,
}: PasswordStrengthIndicatorProps) => (
  <PasswordStrengthCheckContainer>
    <PasswordStrengthCheck isActive={breakdown.containsLowerCheck}>
      Lower case
    </PasswordStrengthCheck>
    <PasswordStrengthCheck isActive={breakdown.containsUpperCheck}>
      Upper case
    </PasswordStrengthCheck>
    <PasswordStrengthCheck isActive={breakdown.lengthCheck}>
      8 characters
    </PasswordStrengthCheck>
    <PasswordStrengthCheck isActive={breakdown.containsNumberCheck}>
      Number
    </PasswordStrengthCheck>
    <PasswordStrengthCheck isActive={breakdown.containsSpecialCheck}>
      Symbol
    </PasswordStrengthCheck>
  </PasswordStrengthCheckContainer>
);
