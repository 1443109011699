import { SimpleFeedback } from 'components/Feedback/Simple/SimpleFeedback';
import { FontStyle, TextNormal } from 'components/design-system/Text/Text';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { ContentSection } from '../ContentSection';
import { WantToSeeMore } from '../Overlays/WantToSeeMore';
import {
  NoContent,
  StyledContentContainer,
  StyledContentSubContainer,
  Title,
  ViewContainer,
} from '../Styles/FundDetails.style';
import { RiskWarning } from '../Wildcards/RiskWarning';

interface TillitViewSliceProps {
  asset: AssetQueryAsset;
  canSeeTillitView: boolean;
}

export function TillitViewSlice({
  asset,
  canSeeTillitView,
}: TillitViewSliceProps) {
  const isWildcardFund = asset?.tags?.nodes.some((t) => t.code === 'WILDCARD');
  const keyRisksContent = asset?.featured?.nodes.find(
    (n) => n.key === 'KEY_RISKS'
  );

  return (
    <>
      <ViewContainer>
        <Title>The TILLIT View</Title>
        {isWildcardFund && keyRisksContent && (
          <RiskWarning keyRisksContent={keyRisksContent.renderedContent} />
        )}
        <StyledContentContainer>
          {(() => {
            if (
              !asset?.main?.nodes.length ||
              !asset?.secondary?.nodes.length ||
              !asset?.featured?.nodes.length
            )
              return <NoContent>TILLIT view is not available</NoContent>;
          })()}
          {asset?.main?.nodes.map((main, i) => (
            <ContentSection
              key={i}
              title={main.title || ''}
              type={main.type || ''}
              content={main.renderedContent || ''}
              featured={true}
              featuredTitle={true}
            />
          ))}
          <StyledContentSubContainer>
            {!canSeeTillitView && <WantToSeeMore />}
            {asset?.featured?.nodes.map((featured, i) => (
              <ContentSection
                key={i}
                title={featured.title || ''}
                content={featured.renderedContent || ''}
                type={featured.type || ''}
                featuredTitle={true}
                hideContent={!canSeeTillitView}
              />
            ))}
          </StyledContentSubContainer>
          {asset?.secondary?.nodes.map((secondary, i) => (
            <ContentSection
              key={i}
              title={secondary.title || ''}
              type={secondary.type || ''}
              content={secondary.renderedContent || ''}
              hideContent={!canSeeTillitView}
              featured={true}
              featuredTitle={true}
            />
          ))}
        </StyledContentContainer>

        <TextNormal $fontStyle={FontStyle.italic}>
          Source: TILLIT's analysis based on information from, and meetings
          with, the asset manager.
        </TextNormal>
        {canSeeTillitView && (
          <SimpleFeedback
            feedbackSection="TILLIT view"
            message="Did you find this TILLIT View helpful?"
          />
        )}
      </ViewContainer>
    </>
  );
}
