import { Tab } from '@material-ui/core';
import {
  generateFundDetailsBreakdownPath,
  generateFundDetailsInformationPath,
  generateFundDetailsMeetManagersPath,
  generateFundDetailsPath,
  generateFundDetailsPerformancePath,
  generateFundDetailsReturnQueryPath,
} from 'paths';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FundPageNav,
  StyledNavLink,
  StyledTabs,
} from './Styles/FundDetails.style';

const navLookup = (
  slug: string,
  id: string,
  isActivelyManaged: boolean,
  isDarkUniverse: boolean
) => [
  {
    index: 0,
    url: generateFundDetailsPath({ slug, id }),
    title: isDarkUniverse ? 'Dark Universe' : 'The TILLIT View',
  },
  ...(isDarkUniverse
    ? []
    : [
        {
          index: 1,
          url: generateFundDetailsMeetManagersPath({ slug, id }),
          title: isActivelyManaged ? 'Meet the Manager' : 'The Team',
        },
      ]),
  {
    index: 2,
    url: generateFundDetailsPerformancePath({ slug, id }),
    title: 'Performance',
  },
  {
    index: 3,
    url: generateFundDetailsBreakdownPath({ slug, id }),
    title: 'Breakdown',
  },
  {
    index: 4,
    url: generateFundDetailsInformationPath({ slug, id }),
    title: 'Key information',
  },
];

export interface FundNavProps {
  slug: string;
  id: string;
  isActivelyManaged: boolean;
  isDarkUniverse: boolean;
}

export function FundNav({
  slug,
  id,
  isActivelyManaged,
  isDarkUniverse,
}: FundNavProps) {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const refFundPageNav = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navData = navLookup(slug, id, isActivelyManaged, isDarkUniverse);

  const urlParams = new URLSearchParams(location.search);
  const tags = urlParams.get('tags') || '';
  const search = urlParams.get('search') || '';
  const source = urlParams.get('source') || '';

  useEffect(() => {
    if (activeTab === null) return;
    const index = navData.findIndex((nav) => nav.url === location.pathname);
    setActiveTab(index > -1 ? index : 0);
  }, [activeTab, id, isActivelyManaged, location.pathname, slug, navData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const scrollToTopOfSection = () => {
    const sibling = refFundPageNav.current?.nextSibling as HTMLElement;
    if (sibling !== null && sibling !== undefined) {
      const headerOffset = 128; // the header is always 80px height and the sub nav has a fixed height of 48px, so we no longer need different calculations
      const y = sibling.offsetTop - headerOffset;
      window.scrollTo(0, y);
    }
  };

  return (
    <FundPageNav ref={refFundPageNav} $isDarkUniverse={isDarkUniverse}>
      <StyledTabs
        value={activeTab || 0}
        onChange={handleChange}
        variant="scrollable"
      >
        {navData.map(({ index, url, title }) => (
          <Tab
            key={index}
            label={
              <StyledNavLink
                to={`${url}${generateFundDetailsReturnQueryPath({
                  searchTags: tags,
                  searchTerm: search,
                  source,
                })}`}
                exact={index === 0}
                onClick={scrollToTopOfSection}
                $isDarkUniverse={isDarkUniverse}
              >
                {title}
              </StyledNavLink>
            }
          />
        ))}
      </StyledTabs>
    </FundPageNav>
  );
}
