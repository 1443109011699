import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { colors } from 'constants/colors';
import { HiChevronDown } from 'react-icons/hi';
import styled from 'styled-components';

export const AccordionContainer = styled(Accordion)`
  padding: 0;
  box-shadow: none;
  border-top-color: white;
  border-bottom: 1px solid ${colors.midGrey};
  box-sizing: border-box;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5)};
  }

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
    border-bottom: 1px solid ${colors.midGrey};
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    background-color: ${colors['grey-50']};
    color: white;
    width: 116%;
    margin-left: -40px;
    padding: 0 40px;
  }
`;

export const SummaryContainer = styled(AccordionSummary)`
  padding: 0;
`;

export const Title = styled.button`
  color: ${colors.richBlack};
  font-weight: 400;
  font-size: 1rem;
  border: none;
  background: none;
  margin: ${(p) => p.theme.spacing(1.25, 0)};
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const FilterToggle = styled.span`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  color: ${colors.richBlue};
  padding-left: ${(p) => p.theme.spacing(2.5)}px;
  cursor: pointer;
  transition: transform 0.15s linear;
  transform: translateX(0px);

  &.remove {
    transform: translateX(76px);
  }
  &.add {
    transform: translateX(56px);
  }

  label {
    display: block;
    opacity: 0;
    margin-left: ${(p) => p.theme.spacing(1.25)}px;
    transition: opacity 0.15s linear;
    cursor: pointer;
    font-size: 0.75rem;
  }

  &.open {
    label {
      opacity: 1;
    }
    &.remove {
      transform: translateX(0px);
    }
    &.add {
      transform: translateX(0px);
    }
  }
`;

export const SubTitle = styled.h2`
  color: ${colors.magenta};
  font-family: ${(p) => p.theme.typography.bodyFontFamily};
  font-weight: normal;
  font-size: 1rem;
`;

export const ContentCopy = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  color: ${colors.richBlack};
  font-family: ${(p) => p.theme.typography.bodyFontFamily};
  font-size: 0.85rem;
  font-weight: 300;
  overflow: hidden;
  padding: 0 0.375rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1rem;
  }

  p:nth-of-type(1) {
    margin-top: ${(p) => p.theme.spacing(-0.625)}px;
  }
`;

export const Icon = styled(HiChevronDown)`
  font-size: 1rem;
  color: ${colors.richBlack};
`;

export const DetailsContainer = styled(AccordionDetails)`
  padding: ${(p) => p.theme.spacing(0, 0, 5, 0)};
  display: flex;
  flex-direction: column;
`;
