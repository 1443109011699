import { DateField } from 'components/Form/DateField';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { CUserYearOfBirth } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { date as formatDate } from 'formatting';
import {
  AccountType,
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { subtractYears } from 'helpers/dateHelpers';
import { trackGa } from 'helpers/track';
import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';

const date = new Date().toJSON();
const dobMax = subtractYears(date, 18);
const dobMin = subtractYears(date, 100);

const dateOfBirthSchema = Yup.object().shape({
  dateOfBirth: Yup.date()
    .nullable()
    .label('Date of birth')
    .typeError(
      `Please enter a valid year of birth between ${formatDate(
        dobMin
      )} and ${formatDate(dobMax)}`
    )
    .min(dobMin, `Date of birth needs to be after ${formatDate(dobMin)}`)
    .max(dobMax, `Date of birth needs to be before ${formatDate(dobMax)}`)
    .required(),
});

interface DateOfBirthFormValues extends Yup.Asserts<typeof dateOfBirthSchema> {}

interface DateOfBirthStepProps {
  onProceed: (dob: string) => void;
  onGoBack: () => void;
  isResuming: boolean | undefined;
  initialAccountType: AccountType | undefined;
}

export function DateOfBirthStep({
  onProceed,
  onGoBack,
  isResuming,
  initialAccountType,
}: DateOfBirthStepProps) {
  const queryClient = useQueryClient();

  const userProfile = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileQuery.getKey()
  )?.userProfile!;

  const validationSchema = dateOfBirthSchema;

  const { mutateAsync, isError } = useUpdateUserProfileMutation();

  const onSubmit = async (data: DateOfBirthFormValues, isDirty: boolean) => {
    // dateOfBirth is entered in the format 'YYYY-MM-DD'.
    // Normally JS would parse a date string as UTC if there is no time - however yup will parse a
    // date with no time as being in the local timezone - this could be in BST or GMT.
    const dob = data.dateOfBirth;

    // NOTE: if you use toISOString() on a date with no time, it will return the date in UTC.
    // IE if you have a local date of 2021-06-06 BST, it will return 2021-06-05T23:00:00.000Z.
    // This is why we need to use toLocaleDateString() to get the date in the local timezone.
    // We use the 'sv' locale to get the date in the format 'YYYY-MM-DD'.
    const dobString = dob.toLocaleDateString('sv');

    if (isDirty) {
      await mutateAsync(
        {
          input: {
            dateOfBirth: {
              value: dobString,
            },
          },
        },
        {
          onSuccess: (_, { input }) => {
            queryClient.setQueryData<UserProfileQuery>(
              useUserProfileQuery.getKey(),
              (oldData) => {
                return {
                  userProfile: {
                    ...oldData?.userProfile!,
                    dateOfBirth: input.dateOfBirth?.value!,
                  },
                };
              }
            );
            Cookies.remove(CUserYearOfBirth);
          },
        }
      );
    }
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.dob,
      dateOfBirth: dobString,
    });

    onProceed(dobString);
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <StepContainer>
      <Form<DateOfBirthFormValues>
        onSubmit={onSubmit}
        defaultValues={{ dateOfBirth: userProfile.dateOfBirth }}
        schema={validationSchema}
      >
        <StepContent>
          <StepTitle>
            {isResuming
              ? `Continue setting up your ${initialAccountType}`
              : 'When were you born?'}
          </StepTitle>

          <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              We need to know how old you are in order to verify your identity
              and confirm that you are old enough to invest.
            </StepIntroductionTypography>
          </StepIntroduction>

          <ServerError isVisible={isError} />

          <StepFormContainer>
            <DateField
              name="dateOfBirth"
              label="Date of birth"
              inputProps={{
                min: dobMin.toISOString().split('T')[0],
                max: dobMax.toISOString().split('T')[0],
              }}
            />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>
                <GoBackButton onClick={handleBack} disabled={isSubmitting} />
              </>
            )}
          </FormState>
        </StepActions>
      </Form>
    </StepContainer>
  );
}
