import { Typography, useTheme } from '@material-ui/core';
/* cspell:disable */
import IdGood from 'assets/id-samples/New-British-passport-desi-003.jpg';
import IdBadCrop from 'assets/id-samples/New-British-passport-desi-003_bad_crop.jpg';
import IdGlare from 'assets/id-samples/New-British-passport-desi-003_glare.jpg';
import { CustomButton } from 'components/Button/CustomButton';
import {
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { colors } from 'constants/colors';
import React from 'react';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import styled from 'styled-components';

export interface IdSampleDialogProps extends ResponsiveDialogProps {}

// const StyledHiCheckCircle = styled(HiCheckCircle)`
//   height: 1rem;
//   width: 1rem;
// `;

const Content = styled.div`
  padding: ${(p) => p.theme.spacing(0, 3)};
  padding-bottom: ${(p) => p.theme.spacing(7)}px;
  & > * + * {
    margin-top: ${(p) => p.theme.spacing(2)}px;
  }
`;

const SampleHeading = styled(Typography).attrs({ variant: 'h2' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.black};
  font-size: 1.25rem;

  svg {
    margin-right: 0.5rem;
    flex: 0 0 1.5rem;
    font-size: 1.5rem;
  }
`;

const SampleImage = styled.img`
  max-width: 100%;
  margin: ${(p) => p.theme.spacing(2, 0)};
`;

export function IdSampleDialog({ onClose, ...props }: IdSampleDialogProps) {
  const theme = useTheme();

  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <Content>
        <StepTitle>Examples</StepTitle>
        <div>
          <SampleHeading>
            <HiCheckCircle color={theme.palette.success.main} /> Aim for a
            clear, in-focus image
          </SampleHeading>
          <SampleImage src={IdGood} alt="Example of a good ID" />
          <StepIntroductionTypography textAlign="left">
            The image is clear and in focus, well-lit with no glare. Also make
            sure that the image fills the frame.
          </StepIntroductionTypography>
        </div>

        <div style={{ marginTop: '48px' }}>
          <SampleHeading>
            <HiXCircle color={theme.palette.error.main} /> Avoid glare and
            reflection
          </SampleHeading>
          <SampleImage src={IdGlare} alt="Example of an ID with glare" />
          <StepIntroductionTypography textAlign="left">
            Avoid glare and do not use a flash. The whole image must be
            readable.
          </StepIntroductionTypography>
        </div>

        <div style={{ marginTop: '24px' }}>
          <SampleHeading>
            <HiXCircle color={theme.palette.error.main} />
            Avoid cropping out any of the document
          </SampleHeading>
          <SampleImage src={IdBadCrop} alt="Example of a badly cropped ID" />
          <StepIntroductionTypography textAlign="left">
            Make sure the ID is square in the frame and that the whole document
            is visible.
          </StepIntroductionTypography>
        </div>

        <CustomButton
          className="richBlue"
          onClick={() => onClose?.()}
          fullWidth
        >
          Close
        </CustomButton>
      </Content>
    </ResponsiveDialog>
  );
}
