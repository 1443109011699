import { H6 } from 'components/design-system/Heading/Heading';
import {
  MinusIcon,
  PlusIcon,
  ShoppingBasketIcon,
} from 'components/feature/PortfolioBuilder/_shared/DpcIcons';
import {
  Account,
  PortfolioRebalancingStatus,
  RebalancingTransactionStatus,
} from 'generated/graphql';
import { PartialDeep } from 'type-fest';
import { AnyRebalancing } from 'types/graphqlTypes';
import { BuyOrderDetails } from './BuyOrderDetails';
import {
  IconTitleWrapper,
  IconWrapper,
  OrderWrapper,
} from './OrderStatusTable.styles';
import { SellOrderDetails } from './SellOrderDetails';

interface OrderStatusTableProps {
  portfolioRebalancing: AnyRebalancing;
  selectedAccount: PartialDeep<Account>;
  showComplectedText?: boolean;
}

export function OrderStatusTable({
  portfolioRebalancing,
  selectedAccount,
  showComplectedText = true,
}: OrderStatusTableProps) {
  const isPortfolioRebalancingCancelled =
    portfolioRebalancing.status === PortfolioRebalancingStatus.Cancelled;
  const sellOrdersCompleted = portfolioRebalancing.sellOrders?.every(
    (sellOrder) => sellOrder?.status === RebalancingTransactionStatus.Completed
  );
  const buyOrdersCompleted = portfolioRebalancing.buyOrders?.every(
    (buyOrder) => buyOrder?.status === RebalancingTransactionStatus.Completed
  );
  return (
    <OrderWrapper>
      {portfolioRebalancing.sellOrders &&
        portfolioRebalancing.sellOrders.length > 0 && (
          <div>
            <IconTitleWrapper $type="sell">
              <H6>
                SELL orders
                {sellOrdersCompleted && showComplectedText && <> (completed)</>}
              </H6>
              <IconWrapper $type="sell">
                <ShoppingBasketIcon $type="sell" size={'1.2rem'} />
                <MinusIcon size={'1.2rem'} />
              </IconWrapper>
            </IconTitleWrapper>
            <SellOrderDetails
              portfolioRebalancing={portfolioRebalancing}
              selectedAccount={selectedAccount}
            />
          </div>
        )}
      {portfolioRebalancing.buyOrders &&
        portfolioRebalancing.buyOrders.length > 0 && (
          <div>
            <IconTitleWrapper $type="buy">
              <H6>
                BUY orders
                {buyOrdersCompleted && showComplectedText && <> (completed)</>}
                {!buyOrdersCompleted && isPortfolioRebalancingCancelled && (
                  <> (cancelled)</>
                )}
              </H6>
              <IconWrapper $type="buy">
                <ShoppingBasketIcon $type="buy" size={'1.2rem'} />
                <PlusIcon size={'1.2rem'} />
              </IconWrapper>
            </IconTitleWrapper>
            <BuyOrderDetails
              portfolioRebalancing={portfolioRebalancing}
              selectedAccount={selectedAccount}
            />
          </div>
        )}
    </OrderWrapper>
  );
}
