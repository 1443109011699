import { colors } from 'constants/colors';
import styled from 'styled-components';

export const ViewCountContainer = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 300;
`;

export const TotalViewCountContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 300;
`;

export const Highlighted = styled.span`
  font-weight: 400;
  color: ${colors.magenta};
`;
