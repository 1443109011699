import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepTitle,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { useAuth } from 'context/AuthContext';
import { AccountType } from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { useOnboardingStatus } from 'hooks/useOnboardingStatus';
import { appSignInPath, openAccountPath } from 'paths';

export function CheckoutDialog({ onClose, ...props }: ResponsiveDialogProps) {
  const { signedIn } = useAuth();
  const { onboardingStatus, onboardingAccountType } = useOnboardingStatus();
  const initialAccountName = getNameForAccountType(
    onboardingAccountType as AccountType
  );

  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <StepWrapper>
        <StepContainer>
          <StepContent>
            <StepTitle>Checkout</StepTitle>
            <StepIntroductionTypography $noMargin>
              {signedIn && onboardingStatus
                ? `To checkout, you need to finish setup your ${initialAccountName} account.`
                : 'To checkout, you need to open an account to start investing or sign in.'}
            </StepIntroductionTypography>
          </StepContent>
          {signedIn && onboardingStatus ? (
            <StepActions>
              <LinkCustomButton to={openAccountPath} $color="primary">
                Finish setup
              </LinkCustomButton>
            </StepActions>
          ) : (
            <StepActions $isHorizontal>
              <LinkCustomButton to={openAccountPath} $color="primary">
                Start investing
              </LinkCustomButton>
              <LinkCustomButton to={appSignInPath} $color="secondary">
                Sign in
              </LinkCustomButton>
            </StepActions>
          )}
        </StepContainer>
      </StepWrapper>
    </ResponsiveDialog>
  );
}
