import { Alert } from 'components/design-system/Alert/Alert';
import { colors } from 'constants/colors';
import { FaRegCircle, FaRegCircleCheck } from 'react-icons/fa6';
import styled from 'styled-components';

export const UncheckedIcon = styled(FaRegCircle)`
  width: 2rem;
  height: 2rem;
  color: ${colors['magenta-50']};
  grid-area: icon;
`;

export const CheckedIcon = styled(FaRegCircleCheck)`
  width: 2rem;
  height: 2rem;
  color: ${colors['magenta-200']};
  grid-area: icon;
`;

export const ListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'icon h2 action' 'icon text action';
  gap: 0 1rem;
  align-items: center;
  > button {
    grid-area: action;
  }
  > h2 {
    grid-area: h2;
  }
  > p {
    grid-area: text;
  }
`;

export const ErrorAlert = styled(Alert)`
  margin: 1rem 0;
`;
