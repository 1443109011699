import { StyledLink } from 'components/design-system/Link';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { hexagonPagePath } from 'paths';
import { useIntercom } from 'react-use-intercom';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export function PendingAccount() {
  const { showNewMessages } = useIntercom();

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Nearly there!</StepTitle>
        <StepText>
          <StepIntroductionTypography>
            We're still verifying your identity and setting up your account.
            Until then you can keep browsing our{' '}
            <StyledLink href={hexagonPagePath}>fund universe</StyledLink>, but
            you can't start making investments yet. When we're done your new
            account will appear on your Dashboard.{' '}
          </StepIntroductionTypography>
        </StepText>
      </StepContent>
      <StepActions>
        <StepButton
          variant="contained"
          className="richBlue"
          onClick={() => {
            trackGa({
              event: GaEventNames.selectContent,
              item_id: 'talk to support',
              reason: 'open-account - pending account',
            });
            showNewMessages('I have a pending account');
          }}
        >
          Talk to support
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
