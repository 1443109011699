import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  AddFundToRecurringOrderDialog,
  useDialog,
} from './AddToFundToRecurringOrder';

interface AddFundToRecurringOrderButtonProps {
  selectedAssetId: number;
  selectedIsin: string;
}

export const AddFundToRecurringOrderButton = ({
  selectedAssetId,
  selectedIsin,
}: AddFundToRecurringOrderButtonProps) => {
  const dialogProps = useDialog();
  return (
    <>
      {dialogProps.open && (
        <AddFundToRecurringOrderDialog
          {...dialogProps}
          selectedAssetId={selectedAssetId}
          selectedIsin={selectedIsin}
        />
      )}
      <CustomButtonV2
        onClick={() => {
          dialogProps.openDialog();
        }}
      >
        Invest
      </CustomButtonV2>
    </>
  );
};
