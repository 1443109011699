import { TextNormal, TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';
import { ExtraSmallNeumorphicShadow } from 'theme/shared/base.styles';

export const NoDataCopy = styled(TextNormal)`
  margin-top: 2rem;
  text-align: center;
`;

interface TooltipLayoutProps {
  $hasDescription?: boolean;
  $hasDrillDown?: boolean;
}
export const TooltipLayout = styled.div<TooltipLayoutProps>`
  width: 280px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: white;
  border-radius: 10px;
  padding: 0.5rem;
  width: ${({ $hasDescription, $hasDrillDown }) =>
    $hasDescription ? '300px' : $hasDrillDown ? '180px' : '150px'};
  ${ExtraSmallNeumorphicShadow};
`;

export interface TooltipTextProps {
  $hasDrillDown?: boolean;
}

export const TooltipText = styled(TextSmall)<TooltipTextProps>`
  margin: 0;
  b {
    font-weight: 500;
  }

  ${({ $hasDrillDown }) =>
    $hasDrillDown &&
    css`
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 1rem;
      text-align: center;
      line-height: 1;

      &:nth-child(2n) {
        background-color: ${colors['grey-50']};
      }
    `}
`;

TooltipText.defaultProps = {
  $hasDrillDown: false,
};

export const TooltipBreakdownContainer = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  border-top: 1px solid ${colors['grey-200']};
`;
