import { yupResolver } from '@hookform/resolvers/yup';
import {
  NullableRadioFormState,
  YesNoRadios,
} from 'components/design-system/FormElements/YesNoRadios';
import { StyledLink } from 'components/design-system/Link';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextNormal } from 'components/design-system/Text/Text';
import { DetailsFieldLabelWrapper } from 'components/feature/openSipp/steps/FundingChoices/FundingChoicesStep.styles';
import { MoreInfoWrapper } from 'components/feature/openSipp/steps/_shared/steps.styles';
import { tillitFAQs } from 'paths';
import { useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import * as yup from 'yup';
import {
  PensionDetailsContainer,
  StepFormContainer,
} from './PensionDetails.styles';

const schema = yup.object().shape({
  anyDrawdown: yup
    .boolean()
    .required('Please provide the drawdown state of your existing pension'),
  safeguardedBenefits: yup
    .boolean()
    .required(
      'Please identify whether your existing pension has any safeguarded benefits'
    ),
});

export interface PensionDetailsFormValues {
  safeguardedBenefits: boolean | null;
  anyDrawdown: boolean | null;
}

interface PensionDetailsFormBoundValues {
  safeguardedBenefits: NullableRadioFormState;
  anyDrawdown: NullableRadioFormState;
}

export interface PensionDetailsOnProceedValues {
  anyDrawdown: boolean;
  safeguardedBenefits: boolean;
}

export type OnProceedCallback = (values: PensionDetailsOnProceedValues) => void;

export interface PensionDetailsProps {
  onProceed: OnProceedCallback;
  onGoBack: () => void;
  pensionDetails?: PensionDetailsFormValues;
}

function getBooleanFormValueString(
  anyFieldHasData: boolean | null | undefined
): NullableRadioFormState {
  if (anyFieldHasData === true) {
    return 'true';
  } else if (anyFieldHasData === false) {
    return 'false';
  } else {
    return undefined;
  }
}

export function PensionDetails({
  onProceed,
  onGoBack,
  pensionDetails,
}: PensionDetailsProps) {
  const defaultValues = {
    anyDrawdown: getBooleanFormValueString(pensionDetails?.anyDrawdown),
    safeguardedBenefits: getBooleanFormValueString(
      pensionDetails?.safeguardedBenefits
    ),
  };

  const methods = useForm<PensionDetailsFormBoundValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { register, handleSubmit, watch, errors } = methods;

  const onSubmit = async (data: PensionDetailsFormValues) => {
    if (data.anyDrawdown !== false || data.safeguardedBenefits !== false) {
      return;
    }

    onProceed({
      anyDrawdown: data.anyDrawdown,
      safeguardedBenefits: data.safeguardedBenefits,
    });
  };

  const anyDrawdown = watch('anyDrawdown');
  const safeguardedBenefits = watch('safeguardedBenefits');

  const transferDetailsValid =
    anyDrawdown === 'false' && safeguardedBenefits === 'false';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepContainer>
        <StepTitle>About your pension</StepTitle>{' '}
        <StepIntroduction $width={StepIntroductionWidth.extraWide}>
          <StepIntroductionTypography>
            We can typically transfer most defined contribution pensions from
            other providers, but there are some exceptions. Let's check that
            there isn't anything that might prevent you transferring in.
          </StepIntroductionTypography>
        </StepIntroduction>
        <br />
        <StepFormContainer>
          <PensionDetailsContainer>
            <YesNoRadios
              name="anyDrawdown"
              register={register}
              error={errors.anyDrawdown}
              defaultValue={defaultValues.anyDrawdown}
              label={
                <Text $noMargin>
                  Have you crystallised (cashed in via drawdown, or bought an
                  annuity with) any portion of the pension being transferred?{' '}
                  <StyledLink
                    href={`${tillitFAQs}#can-i-consolidate-my-other-pension-pots-into-a-tillit-pension`}
                    target="_blank"
                    $icon
                  >
                    Help me to answer this <HiExternalLink />
                  </StyledLink>
                </Text>
              }
            />

            {anyDrawdown === 'true' && (
              <MoreInfoWrapper>
                <DetailsFieldLabelWrapper>
                  <TextNormal>
                    We're sorry, but we are unable to accept transfers of
                    pensions that have been crystallised.{' '}
                    <StyledLink
                      href={`${tillitFAQs}#can-i-consolidate-my-other-pension-pots-into-a-tillit-pension`}
                      target="_blank"
                      $icon
                    >
                      Learn more about the kinds of pensions we can transfer{' '}
                      <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                </DetailsFieldLabelWrapper>
              </MoreInfoWrapper>
            )}

            <YesNoRadios
              name="safeguardedBenefits"
              register={register}
              error={errors.safeguardedBenefits}
              defaultValue={defaultValues.safeguardedBenefits}
              label={
                <Text $noMargin>
                  <p>
                    Is it a final-salary (or 'Defined Benefit') pension or does
                    it otherwise contain Safeguarded Rights (as defined in
                    Section 48(8) of the Pension Schemes Act 2015)?{' '}
                    <StyledLink
                      href={`${tillitFAQs}#what-are-safeguarded-rights`}
                      target="_blank"
                      $icon
                    >
                      Help me to answer this <HiExternalLink />
                    </StyledLink>
                  </p>
                </Text>
              }
            />

            {safeguardedBenefits === 'true' && (
              <MoreInfoWrapper>
                <DetailsFieldLabelWrapper>
                  <TextNormal>
                    We're sorry, but pensions that have safeguarded benefits or
                    guarantees cannot currently be transferred to TILLIT (but
                    we're working on making it possible in some situations).{' '}
                    <StyledLink
                      href={`${tillitFAQs}#what-kinds-of-pensions-can-i-transfer`}
                      target="_blank"
                      $icon
                    >
                      Learn more about the kinds of pensions we can transfer{' '}
                      <HiExternalLink />
                    </StyledLink>
                  </TextNormal>
                </DetailsFieldLabelWrapper>
              </MoreInfoWrapper>
            )}
          </PensionDetailsContainer>
        </StepFormContainer>
        <StepActions>
          <StepButton
            type="submit"
            className="magenta"
            disabled={!transferDetailsValid}
          >
            Continue
          </StepButton>
        </StepActions>
        <StepActions>
          <GoBackButton onClick={onGoBack} />
        </StepActions>
      </StepContainer>
    </form>
  );
}
