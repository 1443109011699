import { StyledLink } from 'components/design-system/Link';
import { Text } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { ContentSection } from '../ContentSection';
import {
  ContentContainerInner,
  RationaleContainer,
  Title,
  ViewContainer,
} from '../Styles/FundDetails.style';

interface DarkFundDetailsProps {
  assetData: AssetQueryAsset;
}

export function DarkUniverseExplainerSlice({
  assetData,
}: DarkFundDetailsProps) {
  const mainContent = assetData?.main?.nodes;

  const viewCopy = (
    <>
      {assetData.offboardedPdfUrl && (
        <Text>
          You can view the original case and TILLIT View for this holding{' '}
          <StyledLink href={assetData.offboardedPdfUrl} target="_blank">
            here
          </StyledLink>
          .{' '}
          <span style={{ fontStyle: 'italic' }}>
            Please note that the information in this document was correct as of{' '}
            {format.date(assetData.offboardedTimestampUtc)}
          </span>
          .
        </Text>
      )}
      <Text>
        We don’t perform any of our standard TILLIT due diligence on these
        assets and the information available is limited to performance, top
        holdings, fees and other standard quantitative data points.
      </Text>
      <Text>
        If you have any questions about Dark Universe assets, please{' '}
        <StyledLink onClick={() => handleIntercom()}>speak to us</StyledLink>.
      </Text>
    </>
  );

  const { showNewMessages } = useIntercom();
  const handleIntercom = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: `talk to support`,
      item_id: 'fund details - dark universe',
    });
    showNewMessages(`I have a question about a Dark Universe asset`);
  };

  return (
    <ContentContainerInner maxWidth="lg">
      <ViewContainer>
        <Title>Dark Universe Holding</Title>
        {/* TODO: Add link to faqs about the dark universe */}
        <DarkUniverseCopy
          assetData={assetData}
          onSupportClicked={handleIntercom}
        />

        {assetData.offboardedTimestampUtc &&
          mainContent &&
          mainContent.map((content) => {
            if (content.key === 'WHY_WAS_IT_REMOVED') {
              return (
                <>
                  <RationaleContainer>
                    <ContentSection
                      title={content.title!}
                      type={content.type!}
                      content={content.renderedContent}
                    />
                  </RationaleContainer>
                  {viewCopy}
                </>
              );
            } else {
              return null;
            }
          })}
      </ViewContainer>
    </ContentContainerInner>
  );
}

interface DarkUniverseCopyProps {
  assetData: AssetQueryAsset;
  onSupportClicked: () => void;
}

function DarkUniverseCopy({
  assetData,
  onSupportClicked,
}: DarkUniverseCopyProps) {
  if (assetData.offboardedTimestampUtc) {
    return (
      <>
        <Text>
          This holding is part of TILLIT’s Dark Universe. It used to be in the
          main TILLIT Universe but was removed on{' '}
          {format.date(assetData.offboardedTimestampUtc)} with the below
          rationale.
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text>
          This holding is part of TILLIT’s Dark Universe. The Dark Universe is
          where we host any funds, investment trusts or ETFs that have been
          transferred to TILLIT and are not currently part of our main Universe
          (which is selected by our team of experts). We don’t perform any of
          our standard TILLIT due diligence on these assets and the information
          available is limited to performance, top holdings, fees and other
          standard quantitative data points.
        </Text>
        <Text>
          If you have any questions about Dark Universe assets, please{' '}
          <StyledLink onClick={onSupportClicked}>speak to us</StyledLink>.
        </Text>
      </>
    );
  }
}
