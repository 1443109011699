import { MenuItem, Select } from '@material-ui/core';
import countries from 'assets/countries.json';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumber,
} from 'libphonenumber-js/mobile';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { Input, InputProps } from './Input';

export interface MobileNumberInputProps
  extends Omit<InputProps, 'type' | 'register'> {}

export function MobileNumberInput({ name, ...other }: MobileNumberInputProps) {
  const methods = useFormContext();

  const [originalValue, setOriginalValue] = useState('');
  const [countryCode, setCountryCode] = useState<CountryCode>('GB');

  const isError = !!get(methods.errors, name);

  const examples = useMemo(() => {
    return require('libphonenumber-js/examples.mobile.json');
  }, []);

  return (
    <Controller
      control={methods.control}
      defaultValue={''}
      name={name}
      render={({ onChange, onBlur, name, ref }) => (
        <Input
          name={name}
          id={name}
          type="text"
          inputRef={ref}
          error={isError}
          fullWidth={true}
          onChange={(e) => {
            setOriginalValue(e.target.value);
            try {
              onChange(parsePhoneNumber(e.target.value, countryCode));
            } catch {
              onChange(null);
            }
          }}
          value={originalValue}
          placeholder={getExampleNumber(
            countryCode,
            examples
          )?.nationalNumber.toString()}
          onBlur={onBlur}
          startAdornment={
            <CountrySelect value={countryCode} onChange={setCountryCode} />
          }
          inputProps={{
            inputMode: 'tel',
          }}
          {...other}
        />
      )}
    />
  );
}

interface CountrySelectProps {
  onChange: (code: CountryCode) => void;
  value: CountryCode;
}

function CountrySelect({ onChange, value }: CountrySelectProps) {
  const items = useMemo(() => {
    const result: Array<{ value: CountryCode; label: string }> = [];

    getCountries().forEach((code) => {
      const country = countries.find((c) => c.code === code);

      if (country) {
        result.push({
          value: code,
          label: `${country.name} (+${getCountryCallingCode(code)})`,
        });
      }
    });

    return _.orderBy(result, (i) => i.label);
  }, []);

  return (
    <Select
      value={value}
      renderValue={(value: CountryCode) => '+' + getCountryCallingCode(value)}
      onChange={(event) => onChange(event.target.value as CountryCode)}
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
}
