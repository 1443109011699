import { Breakdown } from '../../InteractiveBreakdown/_shared/StackedBarChart';

const assetClassSortOrder: string[] = [
  'Equities',
  'Bonds',
  'Property',
  'Alternative assets',
  'Multi-asset',
];

const regionSortOrder: string[] = [
  'Global',
  'UK',
  'US',
  'Europe',
  'Japan',
  'Asia',
  'Asia ex-Japan',
  'Emerging markets',
  'Frontier markets',
  'UK, Europe',
  'Unknown',
];

export const sortingSelector = ({ name, percentage }: Breakdown) => {
  if (name === 'Cash') {
    return Number.MAX_SAFE_INTEGER;
  } else if (assetClassSortOrder.includes(name)) {
    return assetClassSortOrder.indexOf(name);
  } else if (regionSortOrder.includes(name)) {
    return regionSortOrder.indexOf(name);
  } else {
    return -percentage; // To sort DESC;
  }
};
