import { AccountType, WrapperType } from 'generated/graphql';

export function deriveWrapperTypeFromAccountType(accountType: AccountType) {
  const lookup = {
    [AccountType.Gia]: WrapperType.Gia,
    [AccountType.Isa]: WrapperType.Isa,
    [AccountType.Sipp]: WrapperType.Sipp,
    [AccountType.Unknown]: WrapperType.Unknown,
    [AccountType.NotSpecified]: WrapperType.Unknown,
  };
  return lookup[accountType];
}

export function deriveAccountTypeFromWrapperType(
  accountType: WrapperType
): AccountType {
  const lookup = {
    [WrapperType.Gia]: AccountType.Gia,
    [WrapperType.Isa]: AccountType.Isa,
    [WrapperType.Sipp]: AccountType.Sipp,
    [WrapperType.Unknown]: AccountType.Unknown,
  };
  return lookup[accountType];
}
