import { useMediaQuery, useTheme } from '@material-ui/core';
import { getSellEstimate } from 'components/Dialogs/Trading/helpers';
import { Loading } from 'components/Loading';
import { StyledLink } from 'components/design-system/Link';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontStyle,
  FontWeight,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { Assumptions } from 'components/feature/MIFIDCostsAndCharges/Assumptions';
import {
  ChargeRow,
  TotalChargeRow,
} from 'components/feature/MIFIDCostsAndCharges/ChargeRow';
import { NoChargeInfo } from 'components/feature/MIFIDCostsAndCharges/NoChargeInfo';
import * as format from 'formatting';
import {
  Account,
  GetTradeCostsAndChargesIllustrationQuery,
  Position,
  RebalancingTransactionStatus,
  useGetTradeCostsAndChargesIllustrationQuery,
} from 'generated/graphql';
import { max } from 'lodash';
import { secclOrderExecutionPolicy } from 'paths';
import { useState } from 'react';
import { HiChevronDown, HiChevronUp, HiExternalLink } from 'react-icons/hi';
import { PartialDeep } from 'type-fest';
import { AnyRebalancingBuyOrder } from 'types/graphqlTypes';
import {
  Content,
  TopBar,
  TotalsDisclaimer,
} from '../../ImportantInformation/_shared/ImportantInformation.styles';
import { getKidDocName, getLongKidDocName } from '../copyHelpers';
import {
  CostAndChargesRow,
  CostAndChargesWrapper,
  DetailsGridRow,
  DetailsRow,
} from './BuyOrderDetails.styles';
import { SmallCopyWrapper, TotalHeading } from './OrderStatusTable.styles';

export interface BuyOrderInProgressProps {
  buyOrder: AnyRebalancingBuyOrder;
  position: PartialDeep<Position> | undefined;
  selectedAccount: PartialDeep<Account>;
  totalBasketAmount: number;
  kiidUrl?: string | null;
  factsheetUrl?: string | null;
  contractNoteUrl?: string | null;
}

export function BuyOrderInProgress({
  buyOrder,
  position,
  selectedAccount,
  totalBasketAmount,
  kiidUrl,
  factsheetUrl,
  contractNoteUrl,
}: BuyOrderInProgressProps) {
  const instrument = buyOrder.instrument;

  return (
    <DetailsRow>
      <Tabs
        isBorderless={true}
        headingAlign="left"
        title={<></>}
        tabs={[
          {
            title: 'Trade details',
            content: (
              <TradeDetails
                position={position}
                buyOrder={buyOrder}
                selectedAccount={selectedAccount}
                totalBasketAmount={totalBasketAmount}
              />
            ),
          },
          {
            title: 'Cost & charges',
            content: <CostsAndCharges buyOrder={buyOrder} />,
          },
          {
            title: 'Docs',
            content: (
              <DetailsGridRow>
                <TextSmall $noMargin>
                  <StyledLink href={secclOrderExecutionPolicy} target="_blank">
                    Order Execution Policy (Seccl) <HiExternalLink />
                  </StyledLink>
                </TextSmall>
                {!!kiidUrl && (
                  <TextSmall $noMargin>
                    <StyledLink href={kiidUrl} target="_blank">
                      {getKidDocName(instrument?.instrumentType!)}{' '}
                      <HiExternalLink />
                    </StyledLink>
                  </TextSmall>
                )}
                {!!factsheetUrl && (
                  <TextSmall $noMargin>
                    <StyledLink href={factsheetUrl} target="_blank">
                      Factsheet <HiExternalLink />
                    </StyledLink>
                  </TextSmall>
                )}
                {!!contractNoteUrl && (
                  <TextSmall $noMargin>
                    <StyledLink href={contractNoteUrl} target="_blank">
                      Contract note <HiExternalLink />
                    </StyledLink>
                  </TextSmall>
                )}
              </DetailsGridRow>
            ),
          },
        ]}
      />
    </DetailsRow>
  );
}

interface TradeDetailsProps {
  position: PartialDeep<Position> | undefined;
  buyOrder: AnyRebalancingBuyOrder;
  selectedAccount: PartialDeep<Account>;
  totalBasketAmount: number;
}

function TradeDetails({
  position,
  buyOrder,
  selectedAccount,
  totalBasketAmount,
}: TradeDetailsProps) {
  const instrument = buyOrder.instrument;
  const existingUnits = position?.quantity ?? 0;

  const currentValue = getSellEstimate(instrument!, existingUnits);

  const currentMarketValue =
    selectedAccount?.valuationSummary?.marketValue || 0;
  const availableCash = selectedAccount?.valuationSummary?.uninvestedCash || 0;
  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;
  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;
  const estimatedPositionSize =
    (currentValue + buyOrder.amount!) / percentageOf;

  return (
    <DetailsGridRow>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Current position size
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {existingUnits}{' '}
          {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Current value{' '}
        </TextSmall>
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.currencyFull(currentValue)}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Est. new position
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.percent(estimatedPositionSize)}{' '}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Last price update
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.date(instrument?.priceDate)}
        </TextSmall>
      </SmallCopyWrapper>
    </DetailsGridRow>
  );
}

interface CostsAndChargesProps {
  buyOrder: AnyRebalancingBuyOrder;
}

function CostsAndCharges({ buyOrder }: CostsAndChargesProps) {
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));
  const buyOrderComplete =
    buyOrder.status === RebalancingTransactionStatus.Completed;
  const instrument = buyOrder.instrument;
  const unformattedAmount = buyOrderComplete
    ? buyOrder.executedAmount!
    : buyOrder.amount!;

  const {
    data: tradeIllustration,
    isLoading,
  } = useGetTradeCostsAndChargesIllustrationQuery(
    {
      trade: {
        amount: unformattedAmount,
        isin: instrument.isin!,
      },
    },
    {
      enabled: !!unformattedAmount,
    }
  );

  const oneOffs = tradeIllustration?.tradeCostsAndChargesIllustration?.oneOffCostsAndCharges.map(
    (charge) => (
      <ChargeRow
        charge={charge}
        trackPopoverClick={() => {}}
        oneOff
        amountEntered={buyOrder.amount}
        showTooltip
      />
    )
  );
  const ongoing = tradeIllustration?.tradeCostsAndChargesIllustration?.ongoingCostsAndCharges.map(
    (charge) => (
      <ChargeRow
        charge={charge}
        trackPopoverClick={() => {}}
        amountEntered={buyOrder.amount}
        showTooltip
      />
    )
  );
  const hasAnyChargeInformation = !!oneOffs?.length || !!ongoing?.length;
  const finalAmountBeforeCharges =
    unformattedAmount +
    (tradeIllustration?.tradeCostsAndChargesIllustration.totalGrowth ?? 0);
  const finalAmountAfterCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration.finalAmount ?? 0;
  const totalCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration
      .totalCostsAndChargesOverPeriod ?? 0;
  const kiidDocName = getLongKidDocName(instrument.instrumentType!);
  const kiidUrl = instrument.kiidDocumentUrl;

  return isLoading ? (
    <Loading />
  ) : (
    <CostAndChargesWrapper>
      <div>
        {hasAnyChargeInformation && (
          <>
            <TextNormal $noMargin $fontWeight={FontWeight.medium}>
              One-offs
            </TextNormal>
            <CostAndChargesRow>
              {!oneOffs?.length ? null : oneOffs}
            </CostAndChargesRow>
            <TextNormal $noMargin $fontWeight={FontWeight.medium}>
              Ongoing
            </TextNormal>
            <CostAndChargesRow>
              {!ongoing?.length ? null : ongoing}
            </CostAndChargesRow>

            <TotalHeading $noMargin $fontWeight={FontWeight.medium}>
              Totals
            </TotalHeading>
            {!isMobileUp && (
              <TotalsDisclaimer $noMargin $fontStyle={FontStyle.italic}>
                (assuming 5% annual growth over 1 year)
              </TotalsDisclaimer>
            )}
            <CostAndChargesRow>
              <TotalChargeRow
                finalAmountBeforeCharges={finalAmountBeforeCharges}
                finalAmountAfterCharges={finalAmountAfterCharges}
                totalCharges={totalCharges}
                variant="compact"
              />
            </CostAndChargesRow>
          </>
        )}
      </div>

      <div>
        <CostsAndChargesExtras
          hasAnyChargeInformation={hasAnyChargeInformation}
          tradeIllustration={tradeIllustration}
          kiidUrl={kiidUrl}
          kiidDocName={kiidDocName}
          amount={buyOrder.amount}
        />
      </div>
    </CostAndChargesWrapper>
  );
}

interface CostsAndChargesExtrasProps {
  hasAnyChargeInformation: boolean;
  tradeIllustration: GetTradeCostsAndChargesIllustrationQuery | undefined;
  kiidUrl?: string | null;
  kiidDocName?: string;
  amount: number;
}

function CostsAndChargesExtras({
  hasAnyChargeInformation,
  tradeIllustration,
  kiidUrl,
  kiidDocName,
  amount,
}: CostsAndChargesExtrasProps) {
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      {hasAnyChargeInformation && !isMobileUp && (
        <>
          <TopBar type="button" onClick={() => setIsExpanded(!isExpanded)}>
            <strong>What assumptions are being used?</strong>{' '}
            {isExpanded ? (
              <HiChevronUp size="1.5rem" />
            ) : (
              <HiChevronDown size="1.5rem" />
            )}
          </TopBar>
          <Content isExpanded={isExpanded}>
            <Assumptions
              amount={amount}
              tradeIllustration={tradeIllustration}
            />
          </Content>
        </>
      )}

      {hasAnyChargeInformation && isMobileUp && (
        <Assumptions amount={amount} tradeIllustration={tradeIllustration} />
      )}

      {!hasAnyChargeInformation && (
        <SmallCopyWrapper>
          <NoChargeInfo kiidUrl={kiidUrl} kiidDocName={kiidDocName} />
        </SmallCopyWrapper>
      )}
    </>
  );
}
