import {
  AssetDocument,
  AssetQuery,
  AssetQueryVariables,
} from 'generated/graphql';
import { UseQueryOptions, useQueries } from 'react-query';
import { useFetcher } from '../api/TillitGraphQL';

export const useAssetsQuery = <TData = AssetQuery, TError = unknown>(
  variables: AssetQueryVariables[],
  options?: UseQueryOptions<AssetQuery, TError, TData>
) => {
  const fetcher = useFetcher<AssetQuery, AssetQueryVariables>(AssetDocument);
  return useQueries(
    variables.map((variable) => ({
      queryKey: ['Asset', variable],
      queryFn: fetcher.bind(null, variable),
      ...options,
    }))
  );
};
