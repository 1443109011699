import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { StyledLink } from 'components/design-system/Link';
import { Form } from 'components/Form/Form';
import { QueryState } from 'components/QueryState';
import * as format from 'formatting';
import {
  TransferMethod,
  TransferNextStepsKind,
  TransfersQuery,
  TransferStatus,
  useRetryTransferMutation,
  useTransferProvidersQuery,
  useTransfersQuery,
} from 'generated/graphql';
import { getProviderLogo } from 'helpers/getProviderLogo';
import get from 'lodash/get';
import { Dispatch, SetStateAction, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import * as Yup from 'yup';

import { GaEventNames } from 'constants/gaConstants';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import {
  filterSortHoldings,
  HoldingsFilter,
  InSpecieHolding,
} from './ActiveInSpecieHoldings';
import {
  AccountNumberField,
  AccountTypePill,
  ActiveCardTitle,
  ActiveTransferCardWrapper,
  CardButton,
  CardButtonsContainer,
  Close,
  CompletedTransfersLabel,
  Expand,
  ExtraInfo,
  Info,
  InSpecieDivider,
  LabelDivider,
  LabelText,
  LabelValue,
  LabelWrapper,
  Main,
  ProblemDetailsFormWrapper,
  ProblemDetailsIntercomWrapper,
  ProviderLogo,
  SomethingWentWrong,
  ThankYouMsg,
  TransferStatusButton,
  TransferStatusLabel,
  VerticalGroup,
} from './ActiveTransferCard.styles';

type TransferType = TransfersQuery['transfers'][number];

interface ProblemDetailsProps {
  transfer: TransferType;
}

interface RetryTransferInputValues {
  cedingProviderAccountNumber: string;
}

const retryTransferInputValuesSchema = Yup.object().shape({
  cedingProviderAccountNumber: Yup.string()
    .label('Account Number')
    .required()
    .min(2)
    .max(20),
});

function ProblemDetailsCedingProviderAccountNumber({
  transfer,
}: ProblemDetailsProps) {
  const transferProvidersQuery = useTransferProvidersQuery({
    wrapperType: transfer.wrapperType,
  });
  const retryTransferMutationQuery = useRetryTransferMutation();
  const { mutateAsync: retryTransferMutation } = retryTransferMutationQuery;
  const queryClient = useQueryClient();

  const { showNewMessages } = useIntercom();

  const handleSupportButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `active transfer card - account number`,
    });
    showNewMessages('I need help with my transfer.');
  };

  if (retryTransferMutationQuery.isSuccess) {
    return (
      <ThankYouMsg variant="body1">
        Thank you, your transfer has been resumed.
      </ThankYouMsg>
    );
  }

  const transferProviderDetails = transferProvidersQuery.data?.transferProviders.find(
    (transferProviderDetails) =>
      transferProviderDetails.name === transfer.cedingProviderName
  );

  const helpText = transferProviderDetails?.helpText
    ? transferProviderDetails.helpText
    : undefined;

  return (
    <QueryState {...transferProvidersQuery}>
      {() => {
        return (
          <ProblemDetailsFormWrapper>
            <Info variant="body1">{transfer.nextStepsDescription}</Info>
            <Form<RetryTransferInputValues>
              defaultValues={{
                cedingProviderAccountNumber:
                  transfer.cedingProviderAccountNumber,
              }}
              onSubmit={async (values) => {
                try {
                  await retryTransferMutation({
                    input: {
                      transferId: transfer.id,
                      cedingProviderAccountNumber:
                        values.cedingProviderAccountNumber,
                    },
                  });
                  queryClient.invalidateQueries(useTransfersQuery.getKey());
                } catch {}
              }}
              schema={retryTransferInputValuesSchema}
            >
              <AccountNumberField
                name="cedingProviderAccountNumber"
                label="Account Number"
                helpText={helpText}
              />
              <CardButtonsContainer>
                <CardButton onClick={handleSupportButton} className="richBlue">
                  Contact Support
                </CardButton>
                <CardButton
                  type="submit"
                  variant="contained"
                  className="magenta sm"
                  fullWidth
                >
                  Resume transfer
                </CardButton>
              </CardButtonsContainer>
              {retryTransferMutationQuery.error && (
                <SomethingWentWrong>Something went wrong</SomethingWentWrong>
              )}
            </Form>
          </ProblemDetailsFormWrapper>
        );
      }}
    </QueryState>
  );
}

function ProblemDetailsGenericIntercom({ transfer }: ProblemDetailsProps) {
  const retryTransferMutationQuery = useRetryTransferMutation();
  const { mutateAsync: retryTransferMutation } = retryTransferMutationQuery;
  const queryClient = useQueryClient();

  const { showNewMessages } = useIntercom();
  const handleSupportButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `active transfer card - generic`,
    });
    showNewMessages('I need help with my transfer.');
  };

  if (retryTransferMutationQuery.isSuccess) {
    return (
      <ThankYouMsg variant="body1">
        Thank you, your transfer has been resumed.
      </ThankYouMsg>
    );
  }

  return (
    <ProblemDetailsIntercomWrapper>
      {transfer.nextStepsDescription ? (
        <Info variant="body1">{transfer.nextStepsDescription}</Info>
      ) : (
        <Info variant="body1">
          In order to progress your transfer to TILLIT, we need your help.
          Please click the button below to contact support.
        </Info>
      )}
      <CardButtonsContainer>
        <CardButton onClick={handleSupportButton} className="richBlue">
          Contact Support
        </CardButton>
        {transfer.nextStepsDescription &&
          transfer.nextStepsKind !== TransferNextStepsKind.ContactSupport && (
            <CardButton
              onClick={async () => {
                try {
                  await retryTransferMutation({
                    input: {
                      transferId: transfer.id,
                    },
                  });
                  queryClient.invalidateQueries(useTransfersQuery.getKey());
                } catch {}
              }}
              className="magenta"
            >
              Resume transfer
            </CardButton>
          )}
      </CardButtonsContainer>
      {retryTransferMutationQuery.error && (
        <SomethingWentWrong>Something went wrong</SomethingWentWrong>
      )}
    </ProblemDetailsIntercomWrapper>
  );
}

const ProblemDetailsLookup = {
  [TransferNextStepsKind.UpdateCedingProviderAccountNumber]: ProblemDetailsCedingProviderAccountNumber,
  [TransferNextStepsKind.ContactSupport]: ProblemDetailsGenericIntercom,
  [TransferNextStepsKind.Custom]: ProblemDetailsGenericIntercom,
};

function ProblemDetails({ transfer }: ProblemDetailsProps) {
  const [originalNextStepsKind] = useState<TransferNextStepsKind>(
    transfer.nextStepsKind
  );
  const ProblemDetailsComponent = get(
    ProblemDetailsLookup,
    originalNextStepsKind,
    ProblemDetailsGenericIntercom
  );
  return <ProblemDetailsComponent transfer={transfer} />;
}

type Interaction = 'closed' | 'expanded';

interface HoldingsFilterValues {
  complete: boolean;
  pending: boolean;
  delayed: boolean;
}
interface ActiveCashTransferProps {
  transfer: TransferType;
  interaction: string;
  setInteraction: Dispatch<SetStateAction<Interaction>>;
  logoUrl: string;
  statusLabel: string;
}

const ActiveCashTransferCard = ({
  transfer,
  interaction,
  setInteraction,
  logoUrl,
  statusLabel,
}: ActiveCashTransferProps) => {
  const [originalStatus] = useState<TransferStatus>(transfer.status);

  const [filters, setFilters] = useState<HoldingsFilterValues>({
    complete: true,
    pending: true,
    delayed: true,
  });

  const filteredHoldings = filterSortHoldings(filters, transfer.holdings);

  const holdingsValue = transfer.holdings?.reduce(
    (acc, h) => acc + (h.value || 0),
    0
  );

  const hasTransferValue =
    transfer.method === TransferMethod.Cash
      ? !!transfer.value
      : !!holdingsValue;

  const { showNewMessages } = useIntercom();
  const handleTransferConciergeLink = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `active transfer card - transfer concierge link`,
    });
    showNewMessages();
  };

  return (
    <>
      <Main>
        <AccountTypePill>
          {getShortNameForWrapperType(transfer.wrapperType)}
        </AccountTypePill>
        <ProviderLogo logoUrl={logoUrl} />
        <VerticalGroup>
          <ActiveCardTitle variant="h3" className={interaction}>
            {transfer.method === TransferMethod.Cash ? 'Cash' : 'In specie'}{' '}
            transfer from {transfer.cedingProviderName}
          </ActiveCardTitle>
          <LabelWrapper>
            <LabelText>
              Requested on{' '}
              <LabelValue as="strong">
                {format.date(transfer.createdTimestampUtc)}
              </LabelValue>
            </LabelText>
            {hasTransferValue && (
              <>
                <LabelDivider />
                <LabelText>
                  Expected value:
                  <LabelValue as="strong">
                    {transfer.method === TransferMethod.Cash
                      ? format.currencyFull(transfer.value!)
                      : format.currencyFull(holdingsValue)}
                  </LabelValue>
                </LabelText>
              </>
            )}
          </LabelWrapper>
        </VerticalGroup>
        {originalStatus === TransferStatus.Problem ? (
          interaction === 'closed' ? (
            <TransferStatusButton
              variant="contained"
              className="magenta"
              size="small"
              onClick={() => {
                setInteraction('expanded');
              }}
            >
              {statusLabel}
            </TransferStatusButton>
          ) : (
            <TransferStatusLabel variant="h3">
              {statusLabel}
              <Close
                onClick={() => {
                  setInteraction('closed');
                }}
              />
            </TransferStatusLabel>
          )
        ) : (
          <TransferStatusLabel variant="h3">
            {statusLabel}
            {transfer.method === TransferMethod.InSpecie &&
              !!transfer.holdings.length &&
              (interaction === 'closed' ? (
                <Expand
                  onClick={() => {
                    setInteraction('expanded');
                  }}
                />
              ) : (
                <Close
                  onClick={() => {
                    setInteraction('closed');
                  }}
                />
              ))}
          </TransferStatusLabel>
        )}
      </Main>
      {originalStatus === TransferStatus.Problem ? (
        <ExtraInfo className={interaction}>
          <ProblemDetails transfer={transfer} />
        </ExtraInfo>
      ) : (
        transfer.method === TransferMethod.InSpecie && (
          <ExtraInfo className={`${interaction} in-specie`}>
            <InSpecieDivider />
            <CompletedTransfersLabel align="center">
              Onboarding assets{' '}
              <InfoPopoverV2
                color="primary"
                size="small"
                content={
                  <>
                    <span>
                      The list of funds being transferred may be incomplete, so
                      your expected value may differ from your actual value.
                    </span>
                    <span>
                      For more information,{' '}
                      <StyledLink
                        href="https://staging.tillitinvest.com/faqs#transfers"
                        target="_blank"
                      >
                        check out our FAQs
                      </StyledLink>{' '}
                      or speak to our{' '}
                      <StyledLink onClick={handleTransferConciergeLink}>
                        Transfer Concierge
                      </StyledLink>
                      .
                    </span>
                  </>
                }
              />
            </CompletedTransfersLabel>
            <HoldingsFilter filters={filters} updateFilters={setFilters} />
            {filteredHoldings.map((h) => (
              <InSpecieHolding key={h.id} holding={h} />
            ))}
          </ExtraInfo>
        )
      )}
    </>
  );
};

interface ActiveTransferCardProps {
  transfer: TransferType;
}

export default function ActiveTransferCard({
  transfer,
}: ActiveTransferCardProps) {
  const [interaction, setInteraction] = useState<Interaction>('closed');
  const logo = getProviderLogo(transfer.cedingProviderId);

  let friendlyStatusLabel;
  switch (transfer.status) {
    case TransferStatus.Created:
      friendlyStatusLabel = 'Requested';
      break;
    case TransferStatus.InProgress:
      friendlyStatusLabel = 'In progress';
      break;
    case TransferStatus.Problem:
      friendlyStatusLabel = 'Action Required';
      break;
    case TransferStatus.Unknown:
    default:
      friendlyStatusLabel = 'Unknown';
      break;
  }

  return (
    <ActiveTransferCardWrapper className={interaction}>
      <ActiveCashTransferCard
        transfer={transfer}
        setInteraction={setInteraction}
        interaction={interaction}
        logoUrl={logo}
        statusLabel={friendlyStatusLabel}
      />
    </ActiveTransferCardWrapper>
  );
}
