interface InstrumentLike {
  isin?: string;
  isDarkUniverse?: boolean | null;
}

interface PositionLike {
  isin?: string | null;
  quantity?: number | null;
}

export function instrumentHeld(
  instrument: InstrumentLike,
  positions: PositionLike[]
) {
  return positions.some((p) => p.quantity! > 0 && p.isin === instrument.isin);
}
