import { Container, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import Styled, { css } from 'styled-components';

export const PageFooter = Styled.section`
  width: 100%;
  background-color: ${colors.richBlack};
`;

export const ContentContainer = Styled(Container)`
  width: 100%;
  padding: ${(p) => p.theme.spacing(2.5)}px;
  display: grid;

  grid-template-areas: "logo logo" "menu1 menu2" "social social" "form form";
  grid-template-columns: auto auto;
  gap: 1rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-areas: "logo menu1 menu2 social" ". form form form" ;
    grid-template-columns: auto 1fr 1fr 1fr;
    gap: 1rem 2.5rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: "logo menu1 menu2 social" "logo menu1 form form" ;
    grid-template-columns: auto 1fr 1fr 1fr;
    gap: 1rem 2.5rem;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    > div { padding-top: 3rem; }

    grid-template-areas:  "logo menu1 menu2 form social";
    grid-template-columns: auto auto auto 1fr auto;
    gap: 4rem;
  }

`;

export const LogoContainer = Styled.div`
  grid-area: logo;
`;

const MenuStyles = css`
  padding: 0;
  li {
    list-style: none;
    a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      color: ${colors.white};
      text-decoration: none;
      padding-bottom: ${(p) => p.theme.spacing(1.25)}px;
      font-weight: 300;
      &:hover {
        color: ${colors.white};
      }

      .icon {
        font-size: 1.5rem;
        margin-right: 5px;
      }
    }
    &.highlighted {
      a {
        color: ${colors.magenta};
        &:hover {
          color: ${colors.magenta};
        }
      }
    }
  }
`;

export const Menu1 = Styled.div`
  grid-area: menu1;
  ${MenuStyles};
`;

export const Menu2 = Styled.div`
  grid-area: menu2;
  ${MenuStyles};
`;

export const EmailSignup = Styled.div`
  grid-area: form;
  padding-top: 0 !important;
  height: 315px;
 
  display:flex;

  margin: 0 -1rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin: 0 0 0 -2.5rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 420px;
  }  
`;

export const SocialMenu = Styled.div`
  grid-area: social;
  min-width: 150px;

  ${MenuStyles};


  ${(p) => p.theme.breakpoints.up('sm')} {
    display: block;
    margin-top: 0;
    li {
      flex-basis: 100%;
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr;

`;

export const CopyRightsContainer = Styled(Container)`
  width: 100%;
  padding: ${(p) => p.theme.spacing(8, 2.5, 10, 2.5)};
  border-top: 1px ${transparentize(0.8, colors.white)} solid;

  ${(p) => p.theme.breakpoints.up('sm')} {
    //padding: ${(p) => p.theme.spacing(3.75)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(8, 3.75, 10, 3.75)}px;
  }

`;

export const CopyBlock = Styled(Typography)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;
`;
