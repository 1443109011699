import { H3 } from 'components/design-system/Heading/Heading';
import { TextSmall } from 'components/design-system/Text/Text';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import { useAccountsQuery, WrapperType } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { generateDynamicPortfolioConstructionPath } from 'paths';
import { useHistory } from 'react-router-dom';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import styled from 'styled-components';

const HeaderBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 1rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

interface CustomOptionContainerProps {
  isSelected: boolean;
}

const CustomOptionContainer = styled.div<CustomOptionContainerProps>`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? `background:${colors.magenta}; > p { color: ${colors.white}; }`
      : ''}
  &:hover {
    background: ${colors.purple};
    > p {
      color: ${colors.white};
    }
  }
`;

interface OptionType {
  value: WrapperType;
  label: string;
}

const CustomOption = ({
  innerProps,
  isDisabled,
  label,
  isSelected,
}: OptionProps<OptionType>) => {
  const { ref, ...rest } = innerProps;
  return !isDisabled ? (
    <CustomOptionContainer {...rest} isSelected={isSelected}>
      <TextSmall $noMargin>Account Type: {label}</TextSmall>
    </CustomOptionContainer>
  ) : null;
};

const CustomSingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props}>
    <TextSmall $noMargin>Account Type: {children}</TextSmall>
  </components.SingleValue>
);

interface HeaderBarProps {
  selectedAccountType: WrapperType;
}

export const HeaderBar = ({ selectedAccountType }: HeaderBarProps) => {
  const accountsQuery = useAccountsQuery(
    {},
    { enabled: selectedAccountType !== WrapperType.Unknown }
  );
  const history = useHistory();

  return (
    <HeaderBarContainer>
      {selectedAccountType === WrapperType.Unknown ? (
        <H3>Portfolio builder</H3>
      ) : (
        <H3>
          Portfolio builder - {getShortNameForWrapperType(selectedAccountType)}
        </H3>
      )}

      <QueryState {...accountsQuery}>
        {({ data }) => {
          if (!data || !data?.accounts || data?.accounts?.length === 1) {
            return null;
          }
          const options =
            data?.accounts.map((account) => {
              return {
                value: account.wrapperType,
                label: getShortNameForWrapperType(account.wrapperType),
              };
            }) || [];

          return (
            <Select<OptionType>
              placeholder="Account Type"
              options={options}
              onChange={({ value }: OptionType) => {
                history.push(
                  generateDynamicPortfolioConstructionPath({
                    wrapperType: getPathSegmentForWrapperType(value),
                  })
                );
              }}
              defaultValue={options.find(
                ({ value }) => value === selectedAccountType
              )}
              components={{
                Option: CustomOption,
                SingleValue: CustomSingleValue,
              }}
            />
          );
        }}
      </QueryState>
    </HeaderBarContainer>
  );
};
