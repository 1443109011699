import { colors } from 'constants/colors';
import React from 'react';
import Styled from 'styled-components';

const PillContainer = Styled.ul`
  background-color: ${colors.clouds};
  padding: ${(p) => p.theme.spacing(1.25)}px;
  border-radius: 10px;
  margin: ${(p) => p.theme.spacing(1.25)}px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  li {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    list-style: none;
    text-align: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 300;
    padding-left: ${(p) => p.theme.spacing(1.875)}px;
    span {
      font-size: 1.5rem;
      font-weight: 400;
    }
    &:first-child {
      padding-right: ${(p) => p.theme.spacing(1.875)}px;
      padding-left: 0;
    }
    &:after {
      content: "";
      position: absolute;
      top 25%;
      right: 0;
      bottom: 25%;
      width: 2px;
      background-color: ${colors.magenta};
    }
    &:last-child:after {
      display: none;
    }
  }
`;

export interface PillProps {
  content: Array<any>;
}

export function ContentPill({ content }: PillProps) {
  return (
    <PillContainer>
      {content?.map((item) => (
        <li key={item.label}>
          <span>{item.value}</span>
          <br />
          {item.label}
        </li>
      ))}
    </PillContainer>
  );
}
