import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { ConfirmSwitchToSmsStep } from './steps/ConfirmSwitchToSmsStep';
import { EnterMobileNumberStep } from './steps/EnterMobileNumberStep';
import { SetSmsMfaMethodSuccessStep } from './steps/SetSmsMfaMethodSuccessStep';
import { VerifyMobileNumberStep } from './steps/VerifyMobileNumberStep';

export interface SetupSmsMfaFormProps {
  onProceed: () => void;
  onCancel?: () => void;
  onSkip?: () => void;
  title?: string;
  switchingToExistingSms: boolean;
  proceedBtnText?: string;
}

enum SetupSmsMfaFormSteps {
  EnterMobileNumber = 'MobileNumber',
  VerifyMobileNumber = 'VerificationCode',
  ConfirmSwitch = 'ConfirmSwitch',
  Success = 'Success',
}

export function SetupSmsMfaForm({
  onProceed,
  onCancel,
  onSkip,
  switchingToExistingSms,
  title = 'Secure your account',
  proceedBtnText = 'Continue',
}: SetupSmsMfaFormProps) {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(
    switchingToExistingSms
      ? SetupSmsMfaFormSteps.ConfirmSwitch
      : SetupSmsMfaFormSteps.EnterMobileNumber
  );

  const onVerificationCodeSent = (number: string) => {
    setPhoneNumber(number);
    setActiveStep(SetupSmsMfaFormSteps.VerifyMobileNumber);
  };

  return (
    <AnimatePresence>
      {activeStep === SetupSmsMfaFormSteps.EnterMobileNumber && (
        <EnterMobileNumberStep
          title={title}
          onSkip={onSkip}
          onCodeSent={onVerificationCodeSent}
        />
      )}
      {activeStep === SetupSmsMfaFormSteps.VerifyMobileNumber && (
        <VerifyMobileNumberStep
          phoneNumber={phoneNumber!}
          onProceed={() => setActiveStep(SetupSmsMfaFormSteps.Success)}
        />
      )}
      {activeStep === SetupSmsMfaFormSteps.ConfirmSwitch && (
        <ConfirmSwitchToSmsStep
          onCancel={onCancel}
          onProceed={() => setActiveStep(SetupSmsMfaFormSteps.Success)}
        />
      )}
      {activeStep === SetupSmsMfaFormSteps.Success && (
        <SetSmsMfaMethodSuccessStep
          onProceed={onProceed}
          proceedBtnText={proceedBtnText}
        />
      )}
    </AnimatePresence>
  );
}
