import { useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'constants/colors';
import { TransfersQuery } from 'generated/graphql';
import styled from 'styled-components';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CompletedTransferCard from './CompletedTransferCard';

type TransferType = TransfersQuery['transfers'][number];

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const SwiperContainer = styled(Swiper)`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;

  .swiper-pagination {
    position: static;
    margin: 20px 0 8px 0;
    align-self: center;
    display: flex;
    width: auto;

    &-lock {
      display: none;
    }

    .swiper-pagination-bullet {
      cursor: pointer;
      border-radius: 50%;
      width: 6px !important;
      height: 6px !important;
      margin: 0 5px !important;
      background-color: ${colors.richBlue};
      transition: transform 0.3s, background-color 0.3s;

      &:focus {
        transform: scale(3);
      }

      &:hover {
        transform: scale(1.33);
      }

      &.swiper-pagination-bullet-active {
        transform: scale(2);
        background-color: ${colors.magenta};
      }
    }
  }

  .swiper-wrapper {
    display: flex;
  }
`;

SwiperCore.use([Pagination, Keyboard, Navigation]);

interface CompletedTransferListProps {
  transfers: TransferType[];
}
export default function CompletedTransferList({
  transfers,
}: CompletedTransferListProps) {
  const { breakpoints } = useTheme();
  const isSwiper = useMediaQuery(breakpoints.up(300));

  if (transfers.length === 0) {
    return null;
  }

  if (!isSwiper) {
    return (
      <Container>
        {transfers.map((transfer) => {
          return (
            <CompletedTransferCard key={transfer.id} transfer={transfer} />
          );
        })}
      </Container>
    );
  }

  return (
    <SwiperContainer
      watchOverflow
      spaceBetween={16}
      slidesPerView={1}
      breakpoints={{
        600: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        960: { slidesPerView: 2.5 },
        1280: { slidesPerView: 3.5 },
        1920: { slidesPerView: 4 },
      }}
      pagination={{ clickable: true }}
      keyboard={{
        enabled: true,
      }}
    >
      {transfers.map((transfer) => {
        return (
          <SwiperSlide key={transfer.id}>
            <CompletedTransferCard key={transfer.id} transfer={transfer} />
          </SwiperSlide>
        );
      })}
    </SwiperContainer>
  );
}
