import { tillitFetch } from 'api/tillitFetch';
import { TextButton } from 'components/Button/TextButton';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { TextNormal } from 'components/design-system/Text/Text';
import { useSignInTracking } from 'components/feature/signIn/steps/_shared/useSignInTracking';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { AffirmationKind, useMakeAffirmationMutation } from 'generated/graphql';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import * as Yup from 'yup';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { BasicDeclarationsFormValues } from '../BasicDeclarations/BasicDeclarations';
import { DidntReceiveCode } from './EmailVerifyStep.styles';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';

const emailVerifyFormSchema = Yup.object().shape({
  verifyCode: Yup.string()
    .label('Verification code')
    .required()
    .min(6)
    .max(6)
    .matches(/[\d]{6}/, 'Must contain 6 numbers.'),
});

export enum FormLocation {
  SignIn = 'SignIn',
  OpenAccount = 'OpenAccount',
}

export interface EmailVerifyStepProps {
  onProceed: () => void;
  email: string;
  password: string;
  basicDeclarationsValues?: BasicDeclarationsFormValues;
  formLocation: FormLocation;
}

interface EmailVerifyFormValues
  extends Yup.Asserts<typeof emailVerifyFormSchema> {}

interface VerifyEmailApi {
  username: string;
  password: string;
  code: string;
  userContext: string | null;
}

const useVerifyEmail = () => {
  return useMutation<any, Error, VerifyEmailApi>(
    '/identity/verify-email',
    (data) => {
      return tillitFetch('/identity/verify-email', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
    }
  );
};

interface ResendVerificationCodeApi {
  username: string;
  userContext: string | null;
}

const useResendVerificationCode = (
  options: UseMutationOptions<any, Error, ResendVerificationCodeApi, unknown>
) => {
  return useMutation<any, Error, ResendVerificationCodeApi>(
    '/identity/resend-verification-code',
    (data) => {
      return tillitFetch('/identity/resend-verification-code', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    options
  );
};

export function EmailVerifyStep({
  onProceed,
  email,
  password,
  basicDeclarationsValues,
  formLocation,
}: EmailVerifyStepProps) {
  const { showNewMessages } = useIntercom();
  const validationSchema = emailVerifyFormSchema;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [didntReceiveCodeActive, setDidntReceiveCodeActive] = useState<boolean>(
    false
  );
  const trackSignIn = useSignInTracking();
  const { clearReferralCode } = useGetEmployerCode();
  const { mutateAsync } = useVerifyEmail();
  const { mutateAsync: affirmationMutateAsync } = useMakeAffirmationMutation();
  const { mutate: resendVerificationCode } = useResendVerificationCode({
    onError: () => {
      setErrorMessage('Something went wrong');
    },
    onMutate: () => {
      setErrorMessage(undefined);
    },
  });

  const onSubmit = async (data: EmailVerifyFormValues, isDirty: boolean) => {
    try {
      setErrorMessage(undefined);

      const userContext = getCognitoUserContextData(email);

      const verifyEmailResults = await mutateAsync({
        username: email,
        password: password,
        code: data.verifyCode,
        userContext,
      });

      if (verifyEmailResults.status === 200) {
        if (formLocation === FormLocation.OpenAccount) {
          await affirmationMutateAsync({
            input: {
              affirmations: [
                AffirmationKind.IsUkTaxPayer,
                AffirmationKind.IsOver_18YearsOld,
                AffirmationKind.NotUsPersonForFatca,
              ],
            },
          });
        }

        await trackSignIn(verifyEmailResults.userId);

        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.emailVerification,
        });
        clearReferralCode();
        onProceed();
      }

      if (verifyEmailResults.status === 400) {
        setErrorMessage('Something went wrong');
      }
    } catch (error) {
      setErrorMessage('Something went wrong');
    }
  };

  return (
    <Form<EmailVerifyFormValues> onSubmit={onSubmit} schema={validationSchema}>
      <StepContainer>
        <StepContent>
          <StepTitle>Verify your email</StepTitle>
          <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              For security, we need to confirm that it’s really you. Please
              enter the six-digit verification code sent to{' '}
              <strong>{email}</strong> - it may take a few minutes to arrive in
              your inbox.
            </StepIntroductionTypography>
          </StepIntroduction>

          {errorMessage && (
            <Alert severity={Severity.error}>
              <TextNormal $noMargin>{errorMessage}</TextNormal>
            </Alert>
          )}

          <StepFormContainer>
            <TextField
              type="verifyCode"
              name="verifyCode"
              label="Verification code"
              fullWidth
              autoFocus
            />
          </StepFormContainer>
        </StepContent>
        <div>
          <StepActions $isHorizontal>
            <FormState>
              {({ isSubmitting }) => (
                <>
                  <CustomButtonV2
                    onClick={() => {
                      const userContext = getCognitoUserContextData(email);
                      resendVerificationCode({
                        username: email,
                        userContext,
                      });
                    }}
                    $color="richBlack"
                    variant="outlined"
                    disabled={isSubmitting}
                    $isWide
                  >
                    Send me another code
                  </CustomButtonV2>
                  <CustomButtonV2
                    type="submit"
                    $color="primary"
                    disabled={isSubmitting}
                    $isWide
                  >
                    Continue
                  </CustomButtonV2>
                </>
              )}
            </FormState>
          </StepActions>
          <StepActions>
            {' '}
            <TextButton
              onClick={() => {
                setDidntReceiveCodeActive(true);
              }}
            >
              Didn't receive the code?
            </TextButton>
          </StepActions>
        </div>
        <DidntReceiveCode isActive={didntReceiveCodeActive}>
          <TextNormal>Have you checked your spam/junk folder?</TextNormal>
          <CustomButtonV2
            onClick={() => {
              trackGa({
                event: GaEventNames.selectContent,
                item_id: 'talk to support',
                reason: 'open account - email verify step',
              });
              showNewMessages(`I am having trouble verifying my email address`);
            }}
          >
            Contact support
          </CustomButtonV2>
        </DidntReceiveCode>
      </StepContainer>
    </Form>
  );
}
