import { FormHelperText, InputLabel } from '@material-ui/core';
import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const EmployerContributionContainer = styled(Card)`
  padding: 0;
  display: grid;
  max-width: 35.75rem;
  margin: 1rem auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 1rem;
    grid-template-columns: auto 10rem;
  }
`;

export const EmployerContributionFormContainer = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 25.75rem;
  }
`;

export const SalaryWrapper = styled.div`
  display: grid;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 23.25rem;
  }
`;

export const AmountPercentageWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 5fr;
  gap: 0 1.25rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 10rem 12rem;
  }
`;

export const AmountPercentageError = styled(FormHelperText)`
  grid-column: 1 / -1;
`;

export const TaxReliefWrapper = styled(AmountPercentageWrapper)`
  > div {
    grid-column-start: 2;
  }
`;

export const EmployerContributionTotals = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${colors['magenta-50']};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0;
  }
`;

export const TotalHr = styled.hr`
  margin: 0.125rem 0;
  width: 2rem;
  border: none;
  border-bottom: 1px solid ${colors['magenta-100']};
`;

export const TotalAmount = styled(H6)`
  color: ${colors['magenta']};
  font-weight: 400;
`;

export const FullSalaryQualEarningsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const InputAdornment = styled(Text)``;

export const StyledInputLabel = styled(InputLabel)`
  min-width: 280px;
  text-align: left;
`;
