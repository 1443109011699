import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { OrderDirection, OrderStatus } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React from 'react';
import { HiDownload } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import {
  Content,
  Download,
  InfoIcon,
  OrderContainer,
  OrderDate,
  OrderValue,
} from './OrderNote.style';

export interface OrderNoteProps {
  downloadURL?: string;
  instrumentName: string;
  direction: OrderDirection;
  date?: string;
  value?: number;
  status: OrderStatus;
}

export function OrderNote({
  downloadURL,
  instrumentName,
  direction,
  date,
  value,
  status,
}: OrderNoteProps) {
  const getConvertedAmount = (amount: number, direction: OrderDirection) => {
    return direction === OrderDirection.Buy
      ? `+ ${format.currencyFull(amount)}`
      : `- ${format.currencyFull(amount)}`;
  };

  const handleClick = (title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'contract note',
      content_type: `download clicked - ${title}`,
    });
  };

  return (
    <OrderContainer
      direction={direction}
      className={clsx(direction === OrderDirection.Sell && 'dark-background')}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box flexGrow="0">
          {status === OrderStatus.Completed ? (
            <Download
              {...{
                component: 'a',
                target: '_blank',
                href: downloadURL,
              }}
              onClick={() => handleClick(instrumentName)}
            >
              <HiDownload />
            </Download>
          ) : (
            <InfoIcon
              color="inherit"
              content={tooltips.OrderPending}
              disablePadding
              disableMargin
            />
          )}
        </Box>
        <Box flexGrow="1">
          <Content>{instrumentName}</Content>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <OrderDate>
              {status === OrderStatus.Completed ? format.date(date!) : status}
            </OrderDate>
            {value && (
              <OrderValue>{getConvertedAmount(value, direction)}</OrderValue>
            )}
          </Box>
        </Box>
      </Box>
    </OrderContainer>
  );
}
