import { GoBackLinkProps } from 'components/design-system/StepComponents/StepComponents';
import {
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { useAuth } from 'context/AuthContext';
import * as format from 'formatting';
import { useAccountsQuery } from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import {
  generateAppSignInPath,
  openAccountPath,
  openAccountResumePath,
} from 'paths';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFundsBasket } from '../hooks/useFundsBasket';
import { useWhichBasketType } from '../hooks/useWhichBasketType';
import {
  AddCashButton,
  OrderBar,
  OrderBarChildren,
  OrderBarContainer,
  OrderBarInner,
  RemainingAmount,
  StyledLink,
} from './FooterBar.styles';

interface FooterBarProps {
  children: React.ReactNode;
  goBackProps?: GoBackLinkProps;
  selectedAccountId?: null | string;
}

const LocalBasketFooterBar = () => {
  const { signedIn } = useAuth();
  const { pathname } = useLocation();

  return (
    <OrderBar>
      <OrderBarContainer maxWidth="lg">
        {signedIn && (
          <TextNormal $textAlign={TextAlign.center}>
            To place orders you need to{' '}
            <StyledLink to={openAccountResumePath}>
              finish setting up your account
            </StyledLink>
            .
          </TextNormal>
        )}
        {!signedIn && (
          <TextNormal $textAlign={TextAlign.center}>
            {' '}
            To place orders you need to{' '}
            <StyledLink to={openAccountPath}>
              open an account
            </StyledLink> or{' '}
            <StyledLink to={generateAppSignInPath(pathname)}>
              sign in
            </StyledLink>
          </TextNormal>
        )}
      </OrderBarContainer>
    </OrderBar>
  );
};

export const AccountFooterBar = ({
  children,
  goBackProps,
  selectedAccountId,
}: FooterBarProps) => {
  const basketType = useWhichBasketType();
  const { basketSummary } = useFundsBasket({
    selectedAccountId,
  });

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.id === selectedAccountId
  );
  const currentMarketValue = currentAccount?.valuationSummary?.marketValue;
  const availableCash = currentAccount?.valuationSummary?.uninvestedCash;
  const remainingAmount =
    Math.round(((availableCash || 0) - basketSummary.amount) * 100) / 100;

  const location = useLocation();

  const addCashPath = location.pathname + '/add-cash' + location.search;

  if (basketType === 'LocalBasket') {
    return <LocalBasketFooterBar />;
  }

  return (
    <OrderBar>
      <OrderBarContainer maxWidth="lg">
        <OrderBarInner>
          <div>
            <div>
              {availableCash !== undefined && (
                <TextNormal as="span" $noMargin>
                  Available cash:{' '}
                  <strong>{format.currencyFull(availableCash)}</strong>{' '}
                  {basketSummary.amount > 0 && (
                    <>
                      (
                      {remainingAmount > 0 ? (
                        <>
                          Remaining:{' '}
                          <RemainingAmount>
                            {format.currencyFull(remainingAmount)}
                          </RemainingAmount>
                        </>
                      ) : (
                        <>
                          Order shortfall:{' '}
                          <RemainingAmount isNegative>
                            {format.currencyFull(remainingAmount * -1)}
                          </RemainingAmount>
                        </>
                      )}
                      )
                    </>
                  )}
                </TextNormal>
              )}

              {currentAccount && (
                <AddCashButton
                  onClick={() => {
                    // @todo - track add cash click
                  }}
                  to={addCashPath}
                >
                  Add cash
                </AddCashButton>
              )}
            </div>
            {currentAccount && currentMarketValue !== undefined && (
              <TextSmall $noMargin>
                Current portfolio value (
                {getShortNameForWrapperType(currentAccount.wrapperType)}):{' '}
                <strong>{format.currencyFull(currentMarketValue)}</strong>
              </TextSmall>
            )}
          </div>
          <OrderBarChildren>{children}</OrderBarChildren>
        </OrderBarInner>
      </OrderBarContainer>
    </OrderBar>
  );
};

export const FooterBar = ({
  children,
  goBackProps,
  selectedAccountId,
}: FooterBarProps) => {
  const basketType = useWhichBasketType();

  if (basketType === 'LocalBasket') {
    return <LocalBasketFooterBar />;
  } else if (basketType === 'AccountBasket') {
    return (
      <AccountFooterBar
        goBackProps={goBackProps}
        selectedAccountId={selectedAccountId}
      >
        {children}
      </AccountFooterBar>
    );
  }
  return null;
};
