import { StyledLink } from 'components/design-system/Link';
import { QueryState } from 'components/QueryState';
import { useAssetQuery } from 'generated/graphql';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { generateFundDetailsPath } from 'paths';
import { useHistory, useParams } from 'react-router-dom';
import { Description, Heading, Wrapper } from 'theme/shared/InfoPage.styles';

export function RedirectFundDetails() {
  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const assetQuery = useAssetQuery(
    { id: parseInt(id!) },
    {
      onSuccess: (data) => {
        const slug = data?.asset?.slug!;
        if (slug) {
          history.replace(
            generateFundDetailsPath({ id: data?.asset?.id!, slug })
          );
        }
      },
    }
  );

  /**
   * The below should never be seen by the user unless something goes wrong or the query times out,
   * there for offering the user a way to navigate away
   */
  return (
    <>
      <QueryState {...assetQuery}>
        {() => (
          <>
            <Wrapper>
              <Heading>Sorry.</Heading>
              <Description>
                {assetQuery.data?.asset && <>This fund has moved permanently</>}
                {!assetQuery.data?.asset && (
                  <>Sorry, we could not find the fund you're looking for. .</>
                )}
              </Description>
              <StyledLink href="/funds">View all funds</StyledLink>
            </Wrapper>
          </>
        )}
      </QueryState>
    </>
  );
}
