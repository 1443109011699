import { Switch } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
  padding: 8px;
  height: 44px;
  width: 95px;

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(39px);
  }

  .MuiSwitch-track {
    border-radius: 16px;
    opacity: 1;
    background-color: ${colors.mauve};
    z-index: auto;

    &:before,
    &:after {
      position: absolute;
      z-index: 3;
      line-height: 28px;
      font-weight: 500;
      transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }

    &:before {
      content: '£';
      left: 23px;
      color: ${colors.mauve};
    }

    &:after {
      content: '%';
      right: 20px;
      color: ${colors.white};
    }
  }

  .MuiSwitch-colorSecondary {
    color: ${colors.mauve};

    &.Mui-checked {
      color: ${colors.mauve};

      & + .MuiSwitch-track {
        background-color: ${colors.mauve};
        opacity: 1;

        &:before {
          color: ${colors.white};
        }

        &:after {
          color: ${colors.mauve};
        }
      }
    }
  }

  .MuiSwitch-thumb {
    height: 26px;
    width: 38px;
    border-radius: 16px;
    background-color: ${colors.white};
  }
`;
