import { Tab } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0rem;
  overflow-x: scroll;
  margin-bottom: 0.5rem;

  .MuiTabs-indicator {
    background-color: ${colors.magenta};
  }
`;

export const StyledTab = styled(Tab)`
  color: ${colors.darkGrey};
  border-bottom: 1px solid ${colors.grey};
  font-size: 0.75rem;

  &.Mui-selected {
    color: ${colors.richBlack};
  }
`;
