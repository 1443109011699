import {
  RebalancingTransactionStatus,
  usePortfolioRebalancingsQuery,
} from 'generated/graphql';
import { GetAssetGroupString } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';

interface IBreakdownObj {
  [key: string]: {
    amount: number;
  };
}

export const useGetRebalancingBreakdown = (
  portfolioRebalancingId: string,
  getAssetGroupString: GetAssetGroupString,
  includeAsset?: IncludeAssetType
) => {
  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery({
    filter: { active: true },
  });

  const selectedRebalancing = portfolioRebalancingsQuery?.data?.portfolioRebalancings?.find(
    (r) => r.id === portfolioRebalancingId
  );

  const buyOrders = selectedRebalancing?.buyOrders;
  const rebalancingBreakdownBuysOnlyObj = buyOrders?.reduce<IBreakdownObj>(
    (value, buyOrderItem) => {
      if (
        buyOrderItem.status === RebalancingTransactionStatus.Completed ||
        buyOrderItem.status === RebalancingTransactionStatus.Deleted
      ) {
        return value;
      }

      if (
        !buyOrderItem?.instrument?.asset ||
        (includeAsset && !includeAsset(buyOrderItem?.instrument?.asset))
      ) {
        return value;
      }

      const region = getAssetGroupString(buyOrderItem?.instrument?.asset!);

      return {
        ...value,
        [region]: {
          amount: buyOrderItem.amount + (value[region]?.amount || 0),
        },
      };
    },
    {}
  );

  const sellOrders = selectedRebalancing?.sellOrders;
  const rebalancingBreakdownObj = sellOrders?.reduce<IBreakdownObj>(
    (value, sellOrderItem) => {
      if (
        sellOrderItem.status === RebalancingTransactionStatus.Completed ||
        sellOrderItem.status === RebalancingTransactionStatus.Deleted
      ) {
        return value;
      }

      if (
        !sellOrderItem?.instrument?.asset ||
        (includeAsset && !includeAsset(sellOrderItem?.instrument?.asset))
      ) {
        return value;
      }

      const groupByString = getAssetGroupString(
        sellOrderItem?.instrument?.asset
      );

      return {
        ...value,
        [groupByString]: {
          amount:
            (value[groupByString]?.amount || 0) - sellOrderItem.enteredAmount,
        },
      };
    },
    rebalancingBreakdownBuysOnlyObj || {}
  );

  const rebalancingBreakdowns = rebalancingBreakdownObj
    ? Object.keys(rebalancingBreakdownObj).map((region) => ({
        name: region,
        amount: rebalancingBreakdownObj[region].amount,
      }))
    : [];

  const totalBasketAmount = rebalancingBreakdowns.reduce(
    (value, item) => value + item.amount,
    0
  );

  return {
    rebalancingBreakdownObj,
    rebalancingBreakdowns,
    totalBasketAmount,
    portfolioRebalancingsQuery,
  };
};
