import { InfoPopover } from 'components/Popover/InfoPopover/InfoPopover';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PillGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(p) => p.theme.spacing(2.5)}px auto;

  > * {
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
    &::last-child() {
      margin-bottom: 0;
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: ${(p) => p.theme.spacing(2)}px;
    margin: ${(p) => p.theme.spacing(2.5)}px auto
      ${(p) => p.theme.spacing(7.5)}px auto;

    > * {
      margin-bottom: 0;
    }
  }
`;

export const PillValue = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
`;
export const PillLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  text-transform: uppercase;
`;

export const DataContainerGraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: center;
  }
`;

export const Info = styled(InfoPopover)`
  position: relative;
  font-size: 0.75rem;
  margin-top: ${(p) => p.theme.spacing(-0.25)}px;
  margin-left: ${(p) => p.theme.spacing(0.25)}px;
  color: ${colors.magenta};
`;

export const Link = styled.a`
  color: ${colors.magenta};
  text-decoration: underline;

  &:hover {
    color: ${colors.white};
    text-decoration: underline;
  }
`;
