import { QueryState } from 'components/QueryState';
import { StartTransferForm } from 'components/feature/Transfers/startTransferForm';
import { AccountType, ActionType, useActionsQuery } from 'generated/graphql';
import { deriveWrapperTypeFromAccountType } from 'helpers/AccountTypeWrapperTypeHelpers';

export interface TransferStepProps {
  accountType: AccountType;
  onProceed: () => void;
}

export function TransferStep({ accountType, onProceed }: TransferStepProps) {
  const actionsQuery = useActionsQuery();
  const startTransferAction = actionsQuery.data?.actions?.find(
    (action) => action.type === ActionType.StartTransfer
  );

  return (
    <QueryState {...actionsQuery}>
      {() => (
        <StartTransferForm
          wrapperType={deriveWrapperTypeFromAccountType(accountType)}
          onProceed={() => {
            onProceed();
          }}
          source="openAccountStep"
          defaultValue={startTransferAction?.amount ?? undefined}
        />
      )}
    </QueryState>
  );
}
