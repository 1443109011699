import { IconButton, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const ComponentContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  padding: ${(p) => p.theme.spacing(1.25)}px;
  background-color: ${transparentize(0.95, colors.seaBlue)};
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;

  &:nth-child(2n) {
    background-color: ${transparentize(0.95, colors.magenta)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Download = styled(IconButton)`
  color: ${colors.magenta};
`;

export const Date = styled(Typography)`
  margin-left: auto;
  padding-left: ${(p) => p.theme.spacing(1)}px;
`;
