import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { WrapperType } from 'generated/graphql';
import { createContext } from 'react';

export interface DepositVariantContextInterface {
  variant: DepositFormVariant;
  wrapperType?: WrapperType;
}
export const DepositVariantContext = createContext<DepositVariantContextInterface | null>(
  null
);
