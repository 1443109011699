import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { cardPadding } from 'theme/shared/spacing.styles';

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing(2.5, 2.5, 0, 2.5)};
  overflow-x: hidden;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(7.5, 5, 0, 5)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(7.5, 7.5, 0, 7.5)};
  }
`;

const CarouselNavigation = css`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  svg {
    color: ${colors.richBlack};
    font-size: 3.5rem;
    transition: transform 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: flex;
    align-items: center;
  }
`;

export const PrevNavigation = styled.div`
  left: 0;
  ${CarouselNavigation}
`;

export const NextNavigation = styled.div`
  right: 0;
  ${CarouselNavigation}
`;

export const CardBullets = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing(5)}px;

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-top: ${(p) => p.theme.spacing(10)}px;
  }

  span {
    width: 14px;
    height: 14px;
    background-color: ${colors.richBlue};
    border-radius: 50%;
    margin: 0 3px;
    transition: background-color 0.4s ease-in-out;
    cursor: pointer;

    &.active {
      background-color: ${colors.magenta};
    }
  }
`;

const CardContent = css`
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  transform: translate3d(0%, 0, -30px);
  transition: transform 0.8s ease-in-out, box-shadow 0.8s ease-in-out;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: transform 0.8s ease-in-out;
    z-index: 1;
    border-radius: 10px;
    ${(p) => p.theme.breakpoints.up('md')} {
      position: inherit;
      top: unset;
      left: unset;
      bottom: unset;
      right: unset;
      background: none;
      border-radius: unset;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &.prev-slide {
    transform: translate3d(-100%, 0, -30px);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    &:before {
      background-color: ${transparentize(0.2, colors.richBlue)};
      transition: transform 0.8s ease-in-out;
    }
  }
  &.active-slide {
    transform: translate3d(0, 0, 0);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    &:before {
      pointer-events: none;
    }
  }
  &.next-slide {
    transform: translate3d(100%, 0, -30px);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    &:before {
      background-color: ${transparentize(0.2, colors.richBlue)};
      transition: transform 0.8s ease-in-out;
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    left: 20%;
    width: 60%;
    &.prev {
      transform: translate3d(-45%, 0, -30px);
    }
    &.next {
      transform: translate3d(45%, 0, -30px);
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    left: calc(50% - (100% / 3 / 2));
    width: calc(100% / 3);
    &.prev {
      transform: translate3d(-100%, 0, -30px);
    }
    &.next {
      transform: translate3d(100%, 0, -30px);
    }
  }
`;

export const CardContainerInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: max(50vh, 550px);
  perspective: 200px;
  backface-visibility: hidden;
  perspective-origin: center;
  transform-origin: center;
  transform-style: preserve-3d;
  z-index: 2;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  & .carousel-slide {
    ${CardContent}
    ${cardPadding}
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    min-height: max(40vh, 665px);
  }
`;
