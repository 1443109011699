import { AffirmationKind, useUserProfileQuery } from 'generated/graphql';
import { useEffect, useState } from 'react';

export function useAffirmation(kind: AffirmationKind) {
  const userProfileQuery = useUserProfileQuery();

  const affirmation = userProfileQuery.data?.userProfile?.affirmations?.find(
    (a) => a!.kind === kind
  );

  const [affirmed, setAffirmed] = useState(!!affirmation);

  useEffect(() => {
    if (affirmation) {
      setAffirmed(true);
    }
  }, [affirmation]);

  return affirmed;
}
