import { Text } from 'components/design-system/Text/Text';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { StyledButton } from './DocumentUploadStep.styles';

function getUploadedType(documentType: string) {
  switch (documentType) {
    case 'PASSPORT':
      return 'passport';
    case 'DRIVERS_LICENCE':
      return 'driving licence';
    case 'NATIONAL_ID_CARD':
      return 'national photo identity card';
  }
}

interface DocumentPreviouslyUploadedProps {
  documentsUploadedType: string | undefined;
  handleUploadNew: () => void;
  onProceed: () => void;
  onGoBack: () => void;
}

export function DocumentPreviouslyUploaded({
  documentsUploadedType,
  handleUploadNew,
  onProceed,
  onGoBack,
}: DocumentPreviouslyUploadedProps) {
  return (
    <>
      <StepContent>
        <StepTitle>Upload your ID</StepTitle>
        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            In order to verify your identity, we need a copy of your ID. This
            can be your passport or driving licence.
          </StepIntroductionTypography>
          <Text $noMargin>
            You previously uploaded your{' '}
            {getUploadedType(documentsUploadedType!)} image - if you need to you
            can{' '}
            <StyledButton type="button" onClick={() => handleUploadNew()}>
              upload a new image
            </StyledButton>
            .
          </Text>
        </StepIntroduction>
      </StepContent>

      <StepActions>
        <>
          <StepButton
            type="submit"
            className="magenta"
            onClick={() => onProceed()}
          >
            Continue
          </StepButton>
          <GoBackButton onClick={() => onGoBack()} />
        </>
      </StepActions>
    </>
  );
}
