export function useUpdateRootCSSVars(data: Array<any>) {
  try {
    const root = document.documentElement;
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      root.style.setProperty(element.property, element.value);
    }
    return true;
  } catch (error) {
    console.warn(error);
    return false;
  }
}
