import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { AccountType } from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useOnboardingStatus } from 'hooks/useOnboardingStatus';
import { appSignInPath, openAccountPath, openAccountResumePath } from 'paths';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import {
  DismissButton,
  ResumeOnboardingNudgeContainer,
  ResumeOnboardingNudgeHead,
  ResumeOnboardingNudgeHeading,
} from './ResumeOnboardingNudge.styles';

export function ResumeOnboardingNudge() {
  const [dismissed, setDismissed] = useSessionStorage(
    'dismissed_resume_onboarding_nudge',
    false
  );
  const { onboardingStatus, onboardingAccountType } = useOnboardingStatus();
  const location = useLocation();
  const hideOnboardingNudge =
    location.pathname.includes(openAccountPath) ||
    location.pathname.includes(appSignInPath);

  if (hideOnboardingNudge || !onboardingAccountType || !onboardingStatus) {
    return null;
  }

  const initialAccountName = getNameForAccountType(
    onboardingAccountType as AccountType
  );

  const handleDismiss = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'finish setup dismissed - nudge',
    });
    setDismissed(true);
  };

  const handleResume = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'finish setup - nudge',
    });
    setDismissed(true);
  };

  return onboardingStatus && !dismissed ? (
    <ResumeOnboardingNudgeContainer>
      <ResumeOnboardingNudgeHead>
        <ResumeOnboardingNudgeHeading $noMargin>
          Finish setting up your account
        </ResumeOnboardingNudgeHeading>
        <DismissButton aria-label="Close dialog" onClick={handleDismiss}>
          <AiOutlineClose />
        </DismissButton>
      </ResumeOnboardingNudgeHead>

      <TextNormal $isDarkBg>
        To start investing, browse more TILLIT Views or watch our exclusive Meet
        the Manager video interviews, please finish setting up your{' '}
        {initialAccountName}.
      </TextNormal>
      <LinkCustomButton to={openAccountResumePath} onClick={handleResume}>
        Resume
      </LinkCustomButton>
    </ResumeOnboardingNudgeContainer>
  ) : null;
}
