import { colors } from 'constants/colors';
import { GrClose } from 'react-icons/gr';
import styled from 'styled-components';

export interface WrapperProps {
  isTallForm?: boolean | undefined;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  position: relative;
  padding: ${(p) => (p.isTallForm ? '6rem 0 4rem 0' : '6rem 0')};
`;

export const Close = styled(GrClose)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  path {
    stroke-width: 3px;
    stroke: ${colors.richBlue};
  }
`;
