import { DialogActions, DialogContent } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CustomButton, LinkCustomButton } from 'components/Button/CustomButton';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { useContext } from 'react';
import { DepositVariantContext } from './_shared/DepositVariantContext';

export interface DepositsDisabledProps {
  reason: string;
  onClose: () => void;
}

export function DepositsDisabled({ reason, onClose }: DepositsDisabledProps) {
  const depositVariant = useContext(DepositVariantContext);
  return (
    <>
      <DialogContent>
        <Alert severity="info">
          <AlertTitle>Deposits disabled</AlertTitle>
          {reason}
        </Alert>
      </DialogContent>
      <DialogActions>
        {depositVariant?.variant === DepositFormVariant.NewAccount ? (
          <>
            <LinkCustomButton fullWidth className="richBlue" to="/dashboard">
              Dashboard
            </LinkCustomButton>
            <LinkCustomButton fullWidth className="magenta" to="/funds">
              Browse funds
            </LinkCustomButton>
          </>
        ) : (
          <CustomButton
            className="richBlue"
            onClick={() => onClose()}
            fullWidth
          >
            Close
          </CustomButton>
        )}
      </DialogActions>
    </>
  );
}
