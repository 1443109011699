import { QueryState } from 'components/QueryState';
import { Card } from 'components/design-system/Card/Card';
import { H3, H5, TextAlign } from 'components/design-system/Heading/Heading';
import {
  FontSize,
  FontWeight,
  Text,
  TextSmall,
} from 'components/design-system/Text/Text';
import * as formatting from 'formatting';
import { WrapperType, useAccountsQuery } from 'generated/graphql';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { Pill, PillContainer } from '../AddToBasketDialog.style';
import {
  CardContainer,
  SelectAccountCardInner,
} from './SelectAccountStepBuy.styles';

interface SelectAccountStepBuyProps {
  onProceed: ({
    selectedAccountType,
  }: {
    selectedAccountType: WrapperType;
    selectedAccountId: string;
  }) => void;
  asset: AssetQueryAsset;
}

export function SelectAccountStepBuy({
  onProceed,
  asset,
}: SelectAccountStepBuyProps) {
  const accountsQuery = useAccountsQuery();
  const accounts = accountsQuery.data?.accounts;

  return (
    <div>
      <PillContainer>
        <Pill $filled $color="buy">
          BUY
        </Pill>
      </PillContainer>
      <H3>{asset.name}</H3>
      <QueryState {...accountsQuery}>
        {({ data }) => (
          <CardContainer>
            {accounts?.map((account) => {
              return (
                <Card
                  key={account.id}
                  onClick={() => {
                    if (!account.id) {
                      return;
                    }

                    onProceed({
                      selectedAccountType: account.wrapperType,
                      selectedAccountId: account.id,
                    });
                  }}
                  fullCardPointer
                >
                  <SelectAccountCardInner>
                    <H5 $textAlign={TextAlign.center} $noMargin>
                      {account.wrapperType}
                    </H5>
                    {account.valuationSummary?.uninvestedCash !== undefined && (
                      <div>
                        <Text
                          $fontSize={FontSize.large}
                          $fontWeight={FontWeight.medium}
                          $textAlign={TextAlign.center}
                          $noMargin
                        >
                          {formatting.currencyFull(
                            account.valuationSummary?.uninvestedCash
                          )}
                        </Text>
                        <TextSmall $textAlign={TextAlign.center} $noMargin>
                          Available cash
                        </TextSmall>
                      </div>
                    )}
                  </SelectAccountCardInner>
                </Card>
              );
            })}
          </CardContainer>
        )}
      </QueryState>
    </div>
  );
}
