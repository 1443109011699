import { CustomButton } from 'components/Button/CustomButton';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { CardTitle } from './AccountCard';

export const WhiteCardTitle = styled(CardTitle)`
  color: ${colors.white};
`;

interface ContentContainerProp {
  vAlign: 'flex-start' | 'center';
  imgUrl?: string;
}

export const ContentContainer = styled.div<ContentContainerProp>`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.vAlign};
  align-items: center;
  background-image: url(${(p) => p.imgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 40px 0;
  }

  p {
    margin-top: 0;
    font-size: 0.875rem;
    text-align: center;

    ${(p) => p.theme.breakpoints.up('sm')} {
      font-size: 1rem;
    }
  }
`;

export const ValueContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const TotalValue = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 400;
  color: ${colors.richBlue};
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 2rem;
  }
`;

export const CardDescription = styled.p`
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.richBlue};
`;

export const WhiteCardDescription = styled(CardDescription)`
  color: ${colors.white};
`;

export const GrowthPopover = styled.div`
  .label-popover {
    color: ${colors.richBlack} !important;
    &:before,
    &:after {
      height: 0;
    }
    svg {
      color: ${colors['grey-900']};
      width: 0.8rem;
    }
  }
`;

export const GrowthLabel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
`;

export const WhyHasThisChangedTriggerWrapper = styled.div`
  text-transform: none;
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

interface SizeProp {
  size?: 'small' | null;
}

export const Value = styled.div<SizeProp>`
  width: 100%;
  text-align: center;
  font-size: ${(p) => (p.size === 'small' && '1.375rem') || '1.75rem'};
  font-weight: 400;
  color: ${colors.richBlue};
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: ${(p) => (p.size === 'small' && '1.5rem') || '2rem'};
  }
`;

export const WhiteValue = styled(Value)`
  color: ${colors.white};
`;

export const Label = styled.p<SizeProp>`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: ${(p) => (p.size === 'small' && '0.75rem') || '0.875rem'};
  font-weight: 300;
  color: ${colors.richBlack};
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: ${(p) => (p.size === 'small' && '0.875rem') || '1rem'};
  }
`;

export const WhiteLabel = styled(Label)`
  color: ${colors.white};
`;

export const WideButton = styled(CustomButton)`
  width: 100%;
`;

export const DoubleButton = styled(CustomButton)`
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: calc(50% - 5px);
  }
`;

export const StyledButtonsWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const StandingOrderDetailsLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
  margin-top: 1rem;
`;
