import { Popper } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
`;

export const StyledPopper = styled(Popper)`
  z-index: 9;
  &[x-placement*='top'] {
    ${Arrow} {
      bottom: -10px;
      border-top: 10px solid ${colors.white};
    }
  }

  &[x-placement*='bottom'] {
    ${Arrow} {
      top: -10px;
      border-bottom: 10px solid ${colors.white};
    }
  }
`;

export const MobileNavContainer = styled.ul`
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  padding: ${(p) => p.theme.spacing(1.25, 0)};
  margin-top: ${(p) => p.theme.spacing(2.5)}px;
  border-radius: 10px;

  ${(p) => p.theme.breakpoints.up('md')} {
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const MobileNavListItem = styled.li`
  list-style: none;
  padding: ${(p) => p.theme.spacing(1.25, 2.5)};
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.richBlack};
  cursor: pointer;

  &.active {
    background-color: ${colors.purple};
    color: ${colors.white};
    font-weight: 500;
    cursor: initial;
  }

  &.disabled {
    color: ${transparentize(0.5, colors.richBlack)};
    cursor: inherit;
  }

  &:hover {
    &:not(.active) &:not(.disabled) {
      background-color: ${transparentize(0.5, colors.purple)};
      color: ${colors.richBlack};
    }
  }
`;
