import { useMediaQuery } from '@material-ui/core';
import { H6 } from 'components/design-system/Heading/Heading';
import { FontSize, Pill } from 'components/design-system/Pill/Pill';
import { TextNormal, TextSmall } from 'components/design-system/Text/Text';
import { OpenInNew } from 'components/feature/PortfolioBuilder/FundsBasket/_shared/FundsBasketShared.styles';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import { number3dp } from 'formatting';
import { Asset, Instrument, WrapperType } from 'generated/graphql';
import { isNumberInputValid } from 'helpers/inputHelpers';
import { generateFundDetailsWithReturnPath } from 'paths';
import { ArrayField } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import type { AccountsQueryAccountPositionInstrument } from 'types/graphqlTypes';
import { linePercentageHelper } from '../helpers/linePercentageHelper';
import type { RegularOrderLineFormValues } from '../types';
import {
  AmountOr,
  CardLayout,
  ControlsLayout,
  DetailsWrapper,
  HeadingWrapper,
  IconWrapper,
  InputLayout,
  InputWrapper,
  MobileContainer,
  RemoveIcon,
  SelectFundsPercentagesStepCardWrapper,
  StyledInput,
  TitleWrapper,
} from './SelectFundsPercentagesCard.styles';

interface SelectFundsPercentagesStepCardInstrumentAsset
  extends Pick<Asset, 'name' | 'slug' | 'description' | 'isDarkUniverse'> {
  assetClass?: { name: string } | null;
}

interface SelectFundsPercentagesStepCardInstrument
  extends Pick<Instrument, 'isin' | 'name' | 'askPrice' | 'minimumTradeUnit'> {
  asset?: SelectFundsPercentagesStepCardInstrumentAsset | null;
}

interface SelectFundsPercentagesStepCardProps {
  index: number;
  field: Partial<ArrayField<RegularOrderLineFormValues, 'id'>>;
  instrument: SelectFundsPercentagesStepCardInstrument;
  register: any;
  wrapperType: WrapperType;
  accountPositions: AccountsQueryAccountPositionInstrument[];
  setValue: (name: string, value: string | number) => void;
  remove: (index: number) => void;
  totalDepositAmount: number;
  errors: any;
  linesError: any;
  getLines: () => RegularOrderLineFormValues[];
  onChange: () => void;
  onMarkAsDeleted: (index: number) => void;
  onDeleted: (index: number) => void;
  watchIsDeleted: 'true' | 'false';
  watchIsEdited: 'true' | 'false';
  watchIsNew: 'true' | 'false';
  watchAmount: number;
}

export const SelectFundsPercentagesStepCard = ({
  index,
  field,
  instrument,
  register,
  setValue,
  remove,
  totalDepositAmount,
  errors,
  linesError,
  getLines,
  onChange,
  onMarkAsDeleted,
  onDeleted,
  wrapperType,
  watchIsDeleted,
  watchIsEdited,
  watchIsNew,
  watchAmount,
}: SelectFundsPercentagesStepCardProps) => {
  const {
    assetId,
    assetName,
    instrumentName,
    isin,
    amount,
    percentage,
    currentValue,
    isDeleted,
    isEdited,
    isNew,
  } = field;

  const isDarkUniverse = instrument?.asset?.isDarkUniverse || false;
  const fundDetailsPath = instrument
    ? generateFundDetailsWithReturnPath(
        {
          id: assetId!,
          slug: instrument?.asset?.slug || '',
        },
        {
          source: 'funds',
        }
      )
    : '';
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const Container = isSmUp
    ? SelectFundsPercentagesStepCardWrapper
    : MobileContainer;

  return (
    <Container
      state={
        watchIsDeleted === 'true'
          ? 'isDeleted'
          : watchIsEdited === 'true' || watchIsNew === 'true'
          ? 'isEdited'
          : ''
      }
      $isDarkUniverse={isDarkUniverse}
    >
      <CardLayout key={field.id}>
        <input
          type="hidden"
          name={`lines[${index}].instrumentName`}
          id={`lines[${index}].instrumentName`}
          ref={register()}
          defaultValue={instrumentName}
        />
        <input
          type="hidden"
          name={`lines[${index}].isin`}
          id={`lines[${index}].isin`}
          ref={register()}
          defaultValue={isin}
        />
        <input
          type="hidden"
          name={`lines[${index}].assetId`}
          id={`lines[${index}].assetId`}
          ref={register()}
          defaultValue={assetId}
        />
        <input
          type="hidden"
          name={`lines[${index}].assetName`}
          id={`lines[${index}].assetName`}
          ref={register()}
          defaultValue={assetName}
        />
        <input
          type="hidden"
          name={`lines[${index}].currentValue`}
          id={`lines[${index}].currentValue`}
          ref={register()}
          defaultValue={currentValue}
        />
        <input
          // type="hidden"
          name={`lines[${index}].isDeleted`}
          id={`lines[${index}].isDeleted`}
          ref={register()}
          defaultValue={isDeleted}
          style={{ display: 'none' }}
        />
        <input
          // type="hidden"
          name={`lines[${index}].isEdited`}
          id={`lines[${index}].isEdited`}
          ref={register()}
          defaultValue={isEdited}
          style={{ display: 'none' }}
        />
        <input
          // type="hidden"
          name={`lines[${index}].isNew`}
          id={`lines[${index}].isNew`}
          ref={register()}
          defaultValue={isNew}
          style={{ display: 'none' }}
        />
        {wrapperType === WrapperType.Sipp && (
          <input
            name={`lines[${index}].amount`}
            id={`lines[${index}].amount`}
            ref={register()}
            defaultValue={amount}
            style={{ display: 'none' }}
          />
        )}
        <HeadingWrapper>
          <TitleWrapper>
            <Link
              to={{
                pathname: fundDetailsPath,
              }}
              target="_blank"
            >
              <H6 $noMargin darkUniverse={isDarkUniverse}>
                {instrument?.name}{' '}
                <OpenInNew $isDarkUniverse={isDarkUniverse} />{' '}
              </H6>
            </Link>
            {watchIsDeleted === 'true' && (
              <Pill $color={'warning'} $fontSize={FontSize.xs}>
                Removed
              </Pill>
            )}
            {watchIsNew === 'true' && (
              <Pill $color={'green'} $fontSize={FontSize.xs}>
                Added
              </Pill>
            )}
            {watchIsDeleted !== 'true' &&
              watchIsNew !== 'true' &&
              watchIsEdited === 'true' && (
                <Pill $color={'green'} $fontSize={FontSize.xs}>
                  Changed
                </Pill>
              )}
          </TitleWrapper>
          <TextNormal $noMargin $isDarkBg={isDarkUniverse}>
            {instrument?.asset?.description}
          </TextNormal>
          {instrument && instrument.askPrice && totalDepositAmount > 0 && (
            <MinTradeUnitStatus
              variant="popover"
              amount={watchAmount}
              askPrice={instrument.askPrice}
              minimumTradeUnit={instrument.minimumTradeUnit}
            />
          )}
        </HeadingWrapper>
        <DetailsWrapper>
          <Pill $fontSize={FontSize.small}>
            {instrument?.asset?.assetClass?.name}
          </Pill>
        </DetailsWrapper>
        <ControlsLayout>
          <InputLayout
            wrapperType={wrapperType}
            $hasError={!!errors?.percentage || !!errors?.amount || !!linesError}
          >
            <InputWrapper>
              <StyledInput
                name={`lines[${index}].percentage`}
                id={`lines[${index}].percentage`}
                ref={register()}
                defaultValue={percentage}
                $hasError={!!errors?.percentage}
                style={{ textAlign: 'right' }}
                inputMode="decimal"
                onKeyDown={(ev) => {
                  if (!isNumberInputValid(ev)) {
                    ev.preventDefault();
                  }
                }}
                onKeyUp={(ev) => {
                  const newValue = parseFloat(ev.currentTarget.value);
                  setValue(
                    `lines[${index}].amount`,
                    Number.isNaN(newValue) || newValue < 0
                      ? ''
                      : (
                          Math.round(totalDepositAmount * newValue) / 100
                        ).toFixed(2)
                  );
                  setValue(
                    `lines[${index}].isDeleted`,
                    (newValue === 0).toString()
                  );
                  setValue(`lines[${index}].isEdited`, 'true');
                }}
                onBlur={onChange}
              />
              <TextSmall $noMargin>%</TextSmall>
            </InputWrapper>

            {wrapperType !== WrapperType.Sipp && (
              <>
                <AmountOr $noMargin>|</AmountOr>

                <InputWrapper>
                  <TextSmall $noMargin>£</TextSmall>
                  <StyledInput
                    name={`lines[${index}].amount`}
                    id={`lines[${index}].amount`}
                    ref={register()}
                    defaultValue={amount}
                    $hasError={!!errors?.amount}
                    inputMode="decimal"
                    onKeyDown={(ev) => {
                      if (!isNumberInputValid(ev)) {
                        ev.preventDefault();
                      }
                    }}
                    onKeyUp={(ev: any) => {
                      const lines = getLines();
                      const newValue = parseFloat(ev.currentTarget.value);
                      const newPercentage = linePercentageHelper(
                        {
                          isin: isin || '',
                          newAmount: newValue,
                        },
                        totalDepositAmount,
                        lines
                      );
                      setValue(
                        `lines[${index}].percentage`,
                        number3dp(newPercentage, undefined, false)
                      );
                      setValue(
                        `lines[${index}].isDeleted`,
                        (newValue === 0).toString()
                      );
                      setValue(`lines[${index}].isEdited`, 'true');
                    }}
                    onBlur={() => {
                      onChange();
                    }}
                  />
                </InputWrapper>
              </>
            )}
          </InputLayout>
        </ControlsLayout>
        {watchIsDeleted !== 'true' && (
          <IconWrapper>
            <RemoveIcon
              size="small"
              color="inherit"
              aria-label="Remove fund"
              $isDarkUniverse={isDarkUniverse}
              onClick={() => {
                if (watchIsNew === 'true') {
                  onDeleted(index);
                } else {
                  setValue(`lines[${index}].percentage`, 0);
                  setValue(`lines[${index}].amount`, 0);
                  setValue(`lines[${index}].isDeleted`, 'true');
                  onMarkAsDeleted(index);
                }
              }}
            >
              <AiOutlineClose />
            </RemoveIcon>
          </IconWrapper>
        )}
      </CardLayout>
    </Container>
  );
};
