import { colors } from 'constants/colors';
import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
`;

const SpinningSpan = styled.span`
  width: 48px;
  height: 48px;
  border: 5px dotted ${colors.magenta};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: ${rotation} 2s linear infinite;
`;

export const Spinner = () => {
  return <SpinningSpan />;
};
