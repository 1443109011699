import sum from 'lodash/sum';
import type { RegularOrderLineFormValues } from '../types';

/**
 * linePercentageHelper
 *
 * This function calculates the percentage of a new line based on the total deposits amount and the existing lines.
 *
 * This should be simpler.
 *
 * @param newLine
 * @param totalDepositsAmount
 * @param existingLines
 * @returns
 */
export function linePercentageHelper(
  newLine: {
    isin: string;
    newAmount?: number;
    newPercentage?: number;
  },
  totalDepositsAmount: number,
  existingLines: Pick<
    RegularOrderLineFormValues,
    'isin' | 'amount' | 'percentage'
  >[]
): number {
  const missing: 'amount' | 'percentage' =
    newLine.newAmount === undefined ? 'amount' : 'percentage';

  if (totalDepositsAmount === 0) {
    return 0;
  }

  if (missing === 'percentage' && newLine.newAmount !== undefined) {
    const calculatedPercentage = parseFloat(
      (newLine.newAmount / totalDepositsAmount).toFixed(5)
    );

    const existingLinesExcludingNewLine = existingLines.filter(
      (line) => line.isin !== newLine.isin
    );

    const totalAmount =
      sum(
        existingLinesExcludingNewLine.map((line) => parseFloat(line.amount))
      ) + newLine.newAmount;

    const calculatedTotalPercentage = parseFloat(
      (
        sum(
          existingLinesExcludingNewLine.map(
            (line) => parseFloat(line.percentage) / 100
          )
        ) + calculatedPercentage
      ).toFixed(5)
    );

    if (
      totalAmount === totalDepositsAmount &&
      calculatedTotalPercentage === 1.00001
    ) {
      return parseFloat((calculatedPercentage - 0.00001).toFixed(5)) * 100;
    }

    if (
      totalAmount === totalDepositsAmount &&
      calculatedTotalPercentage === 0.99999
    ) {
      return parseFloat((calculatedPercentage + 0.00001).toFixed(5)) * 100;
    }

    return calculatedPercentage * 100;
  }

  return 0;
}
