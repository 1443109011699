import { QueryState } from 'components/QueryState';
import { AccountType, useReferralCodeQuery } from 'generated/graphql';
import { openAccountPath, openPensionPath } from 'paths';
import { Redirect, useParams } from 'react-router-dom';

/**
 * UnAuthInvitationRedirect
 *
 * If the user is not logged in, then redirect the user to the open account which will take care of
 * the referral code.
 */
export const UnAuthInvitationRedirect = () => {
  const { code } = useParams<{ code: string }>();
  const urlParams = new URLSearchParams({ referralCode: code });
  return <Redirect to={`${openAccountPath}?${urlParams.toString()}`} />;
};

/**
 * AuthInvitationRedirect
 *
 * If the user is logged in, then redirect the user to the open pension path if the referral code
 * is for a SIPP account. Otherwise, redirect the user to the home page.
 */
export const AuthInvitationRedirect = () => {
  const { code } = useParams<{ code: string }>();
  const urlParams = new URLSearchParams({ referralCode: code });
  const referralCodeQuery = useReferralCodeQuery({
    code,
  });

  return (
    <QueryState {...referralCodeQuery}>
      {() => {
        if (
          referralCodeQuery.data?.referralCode?.initialAccountType ===
          AccountType.Sipp
        ) {
          return <Redirect to={`${openPensionPath}?${urlParams.toString()}`} />;
        }

        return <Redirect to="/" />;
      }}
    </QueryState>
  );
};
