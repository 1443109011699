import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import {
  ToggleColorOptions,
  ToggleIconButtons,
} from 'components/design-system/ToggleIconButtons/ToggleIconButtons';
import { TableBreakdown } from 'components/feature/FundDetails/Breakdown/TableBreakdown/TableBreakdown';
import { TreeBreakdown } from 'components/feature/FundDetails/Breakdown/TreeBreakdown/TreeBreakdown';
import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { GaEventNames } from 'constants/gaConstants';
import { sum } from 'd3';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { AssetQueryAssetInstrument } from 'types/graphqlTypes';
import { PerformanceContainer } from '../Styles/FundDetails.style';
import {
  BreakdownLayout,
  BreakdownToggleWrapper,
  ChartContainer,
} from './_shared/BreakdownSlices.styles';

interface SectorsTreeMapSliceProps {
  instrument: AssetQueryAssetInstrument;
}

type DisplayOptions = 'tree' | 'table';

export function SectorsTreeMapSlice({ instrument }: SectorsTreeMapSliceProps) {
  const sectorData = instrument?.sectorBreakdown?.nodes.map((sector) => {
    return {
      name: sector.name,
      proportion: sector.proportion || 0,
    };
  });

  const breakdown = {
    name: 'Sector',
    proportion: sum(sectorData.map(({ proportion }) => proportion)),
    lines: sectorData,
  };

  const breakdownHasData = !!breakdown.lines?.length;

  const treemapSupportsData = breakdown.lines.every(
    ({ proportion }) => proportion >= 0
  );

  const [activeDisplay, setActiveDisplay] = useState<DisplayOptions>(
    treemapSupportsData ? 'tree' : 'table'
  );

  return (
    <PerformanceContainer>
      <H4 $textAlign={TextAlign.center}>Top 10 sectors</H4>
      {breakdownHasData ? (
        <BreakdownLayout>
          {treemapSupportsData && (
            <BreakdownToggleWrapper>
              <ToggleIconButtons<DisplayOptions>
                onClick={(value) => {
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund details - tree map slice - display',
                    item_id: value,
                  });
                  setActiveDisplay(value);
                }}
                $color={ToggleColorOptions.mauve}
                options={[
                  { value: 'tree', icon: 'tree' },
                  { value: 'table', icon: 'table' },
                ]}
                value={activeDisplay}
              />
            </BreakdownToggleWrapper>
          )}

          {activeDisplay === 'tree' && (
            <ChartContainer>
              <TreeBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
          {activeDisplay === 'table' && (
            <ChartContainer>
              <TableBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
        </BreakdownLayout>
      ) : (
        <NoDataCopy>{breakdown.name} breakdown is not available.</NoDataCopy>
      )}
    </PerformanceContainer>
  );
}
