//import CardBackground from 'assets/transfers_card_background.jpg';
import { Container } from 'components/Container/Container.style';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { Title } from '../Cards.styles';

export const CardContainer = styled(Container).attrs({
  padding: 'none',
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
  justify-content: space-between;

  ${Title} {
    color: ${colors.magenta};
  }
`;

interface ContentContainerProp {
  imgUrl?: string;
}

export const ContentContainer = styled.div<ContentContainerProp>`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 40px 0 20px 0;
  }

  h3,
  h4 {
    color: ${colors.richBlue};
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.25rem;

    ${(p) => p.theme.breakpoints.up('md')} {
      font-size: 1.5rem;
    }
  }

  p {
    color: ${colors.richBlack};
    margin-top: 0;
    font-size: 0.875rem;
    text-align: center;

    ${(p) => p.theme.breakpoints.up('md')} {
      font-size: 1rem;
    }
  }
`;
