// @ts-nocheck
// @todo - remove ts-nocheck
// func default argument is defaulting to any type

import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { FontSize, FontWeight } from 'components/design-system/Text/Text';
import React, { useEffect, useState } from 'react';
import { Input, InputProps } from './Input';
import {
  ClearIcon,
  Container,
  UploadLabel,
  UploadWrapper,
  UploadedImage,
} from './UploadFieldV2.styles';

export interface UploadFieldProps extends InputProps {
  label: string;
  labelIsAbove?: boolean;
  updateParent?: (
    name: string,
    fileName: string,
    isImage: boolean,
    base64: string | undefined,
    shouldClear?: boolean
  ) => void;
}

export function UploadField({
  name,
  label,
  labelIsAbove,
  updateParent,
  ...other
}: UploadFieldProps) {
  const [value, setValue] = useState('No file selected');
  const [fileTypeIsNotImage, setFileTypeIsNotImage] = useState<
    boolean | undefined
  >();
  const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
  const [fileSelected, setFileSelected] = useState(false);

  const imageTypes = ['image/jpeg', 'image/bmp', 'image/png', 'image/tiff'];

  function tryFiletoBase64(event: any) {
    // if existing image, clear it out
    if (imageBase64) setImageBase64(undefined);

    const file = event?.currentTarget?.files?.[0] ?? null;

    if (file) {
      const fileIsImage = imageTypes.includes(file.type);
      setFileSelected(true);
      if (fileIsImage) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImageBase64(reader.result?.toString());
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
        setFileTypeIsNotImage(false);
      } else {
        setFileTypeIsNotImage(true);
      }
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.currentTarget.value.split(/[/\\]/).pop() ?? '');
    tryFiletoBase64(event);
  }

  function clearValueOnClick(event) {
    event.target.value = '';
  }

  function handleClear() {
    setValue('No file selected');
    setImageBase64(undefined);
    setFileTypeIsNotImage(undefined);
    setFileSelected(false);

    updateParent?.(name, value, !!fileTypeIsNotImage, imageBase64, true);
  }

  useEffect(() => {
    if (fileTypeIsNotImage != null) {
      updateParent?.(name, value, !fileTypeIsNotImage, imageBase64);
    }
  }, [value, fileTypeIsNotImage, imageBase64, name, updateParent]);

  return (
    <FormControl fullWidth>
      <Container>
        {labelIsAbove && (
          <UploadLabel
            $fontSize={FontSize.small}
            $fontWeight={FontWeight.medium}
            $noMargin
          >
            {label}
          </UploadLabel>
        )}
        <UploadWrapper fileSelected={fileSelected}>
          <label>
            <Input
              name={name}
              {...other}
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => {
                handleOnChange(e);
              }}
              onClick={(e) => clearValueOnClick(e)}
            />
            {imageBase64 ? (
              <UploadedImage className="upload-image-wrapper">
                <img src={imageBase64} alt={label} />
              </UploadedImage>
            ) : (
              !fileTypeIsNotImage && (
                <CustomButton
                  className="magenta upload-button"
                  {...{ component: 'span' }}
                >
                  {labelIsAbove ? 'Upload' : label}
                </CustomButton>
              )
            )}
          </label>
          <UploadLabel
            $fontSize={FontSize.normal}
            $fontWeight={FontWeight.normal}
            $noMargin
          >
            {value}
          </UploadLabel>
          {fileSelected && (
            <ClearIcon size="1.5rem" onClick={() => handleClear()} />
          )}
        </UploadWrapper>
      </Container>
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText id={name + '-helper-text'} error={true}>
            {message}
          </FormHelperText>
        )}
      />
    </FormControl>
  );
}
