import WhiteBackground from 'assets/card_background_white.png';
import TransfersBackground from 'assets/transfers_card_background.jpg';
import { Container } from 'components/Container/Container';
import { QueryState } from 'components/QueryState';
import { useTransferStatusSummary } from 'hooks/useTransferStatusSummary';
import React from 'react';
import {
  TransferCardContentActiveMultiple,
  TransferCardContentActiveSingle,
  TransferCardContentCanceled,
  TransferCardContentComplected,
  TransferCardContentDefault,
  TransferCardContentHold,
  TransferCardContentRequested,
} from './TransferCardContent';

export interface TransferCardProps {
  className?: string;
  onClick?: () => void;
  dataTestid: string;
}

export function TransferCard({
  className,
  onClick,
  dataTestid,
}: TransferCardProps) {
  const transferStatus = useTransferStatusSummary();

  const handleClick = () => {
    onClick?.();
  };

  const CardBackground = transferStatus.transferQuery.isLoading
    ? WhiteBackground
    : TransfersBackground;

  return (
    <Container
      className={className}
      background={CardBackground}
      onClick={handleClick}
      data-testid={dataTestid}
    >
      <QueryState {...transferStatus.transferQuery}>
        {() => (
          <>
            {transferStatus.summary.status === 'onHold' && (
              <TransferCardContentHold />
            )}
            {transferStatus.summary.status === 'multi-active' && (
              <TransferCardContentActiveMultiple />
            )}
            {transferStatus.summary.status === 'requested' && (
              <TransferCardContentRequested
                cedingProvider={transferStatus.summary.providerName!}
              />
            )}
            {transferStatus.summary.status === 'single-active' && (
              <TransferCardContentActiveSingle
                cedingProvider={transferStatus.summary.providerName!}
              />
            )}
            {transferStatus.summary.status === 'cancelled' && (
              <TransferCardContentCanceled
                cedingProvider={transferStatus.summary.providerName!}
              />
            )}
            {transferStatus.summary.status === 'completed' && (
              <TransferCardContentComplected
                cedingProvider={transferStatus.summary.providerName!}
                method={transferStatus.summary.method}
                wrapperType={transferStatus.summary.wrapperType}
              />
            )}
            {![
              'onHold',
              'multi-active',
              'requested',
              'single-active',
              'cancelled',
              'completed',
            ].includes(transferStatus.summary.status) && (
              <TransferCardContentDefault />
            )}
          </>
        )}
      </QueryState>
    </Container>
  );
}
