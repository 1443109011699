import _ from 'lodash';
import { AccountsQueryAccountPosition } from 'types/graphqlTypes';

export function sortPositions(positions: Array<AccountsQueryAccountPosition>) {
  return _.orderBy(
    positions,
    [
      (p) => isDarkUniversePosition(p),
      (p) => p.allocation!,
      (p) => p.currentValue,
      (p) => p.instrument?.asset?.name,
    ],
    ['asc', 'desc', 'desc', 'asc']
  );
}

export function isDarkUniversePosition(position: AccountsQueryAccountPosition) {
  return position?.instrument?.asset?.isDarkUniverse ?? false;
}
