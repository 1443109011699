import { PageContainer } from 'components/PageContainer';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Create } from './steps/Create';
import { ThankYou } from './steps/ThankYou';

export interface WithdrawProps {
  accountId: string;
  onClose: () => void;
}

enum WithdrawFormSteps {
  Create = 'Create',
  Confirmation = 'Confirmation',
  ThankYou = 'ThankYou',
}

export function Withdraw({ accountId, onClose }: WithdrawProps) {
  const [activeStep, setActiveStep] = useState<WithdrawFormSteps | undefined>(
    WithdrawFormSteps.Create
  );

  return (
    <PageContainer>
      <AnimatePresence>
        {activeStep === WithdrawFormSteps.Create && (
          <Create
            accountId={accountId}
            onProceed={() => setActiveStep(WithdrawFormSteps.ThankYou)}
          />
        )}
        {activeStep === WithdrawFormSteps.ThankYou && (
          <ThankYou onClose={onClose} accountId={accountId} />
        )}
      </AnimatePresence>
    </PageContainer>
  );
}
