import styled from 'styled-components';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { Card } from 'components/design-system/Card/Card';

export const IsaLimitHit = styled(Text)`
  display: inline-flex;
  color: ${colors.danger};
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const BasketSummaryCardInner = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`;

export const StyledBasketSummaryCard = styled(Card)`
  margin-bottom: 1rem;
`;
