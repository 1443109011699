import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const CheckIconWrapper = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${transparentize(0.5, colors.magenta)};
  border-radius: 100%;
  padding: 1rem;
  margin: 0 auto 1.5rem;
  svg {
    color: ${transparentize(0.5, colors.magenta)};
  }
`;
