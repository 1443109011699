import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CustomButton } from 'components/Button/CustomButton';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import {
  StepActions,
  StepContainer,
  StepContent,
} from 'components/StepContainer';
import {
  ChangePasswordStatus,
  useChangePasswordMutation,
} from 'generated/graphql';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

const PasswordRequirements = styled(Typography).attrs({
  component: 'ul',
})`
  font-size: 0.8rem;
  padding-left: ${(p) => p.theme.spacing(2.5)}px;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;
`;

const changePasswordFormSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Please provide your old password'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must be at least 8 characters')
    .matches(/\d/, 'Must contain a number')
    .matches(/[A-Z]/, 'Must contain an upper case letter')
    .matches(/[a-z]/, 'Must contain a lowercase letter')
    .matches(/[^A-Za-z\d]/, 'Must contain a special character'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .when('password', (password: string, schema: Yup.StringSchema) =>
      password.length
        ? schema.oneOf([password], "Passwords don't match")
        : schema
    ),
});

type ChangePasswordFormInputs = Yup.TypeOf<typeof changePasswordFormSchema>;
type ChangePasswordFormValues = Yup.Asserts<typeof changePasswordFormSchema>;

export function ChangePasswordForm() {
  const [serverError, setServerError] = useState<string | null>();
  const history = useHistory();

  const defaultValues: ChangePasswordFormInputs = changePasswordFormSchema.cast(
    {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }
  );

  const { mutateAsync } = useChangePasswordMutation();

  const onSubmit = async (data: ChangePasswordFormValues) => {
    const response = await mutateAsync({ input: data });

    if (response.changePassword.status !== ChangePasswordStatus.Success) {
      setServerError(response.changePassword.message);
    } else {
      history.push('/confirmation');
    }
  };

  return (
    <StepContainer>
      <Form<ChangePasswordFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={changePasswordFormSchema}
      >
        <StepContent>
          <Box mb={2}>
            <Typography variant="subtitle1" component="h1">
              Change password
            </Typography>
          </Box>
          {serverError && <Alert severity="error">{serverError}</Alert>}
          <Grid container direction="column" spacing={2} alignItems="stretch">
            <Grid item>
              <TextField
                name="oldPassword"
                label="Old password"
                type="password"
              />
            </Grid>
            <Grid item>
              <TextField name="password" label="Password" type="password" />
              <PasswordRequirements>
                <li>Must be at least 8 characters long</li>
                <li>Must contain upper and lower case letters</li>
                <li>Must contain at least one number</li>
                <li>Must contain at least one special character</li>
              </PasswordRequirements>
            </Grid>
            <Grid item>
              <TextField
                name="confirmPassword"
                label="Confirm password"
                type="password"
              />
            </Grid>
          </Grid>
        </StepContent>

        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <CustomButton
                  type="submit"
                  variant="contained"
                  className="richBlue"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Continue
                </CustomButton>
              </>
            )}
          </FormState>
        </StepActions>
      </Form>
    </StepContainer>
  );
}
