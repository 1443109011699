import { DepositForm } from 'components/Dialogs/Deposit/DepositForm';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { QueryState } from 'components/QueryState';
import { AccountType, ActionType, useActionsQuery } from 'generated/graphql';
import { actionsAddCashPath } from 'paths';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface AddCashStepProps {
  accountType?: AccountType;
  onProceed: () => void;
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 40rem;
  .MuiDialogContent-root {
    padding: 0.5rem 0;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 0.5rem 0;
    flex-direction: column;
  }
  .trueLayer__selectProviderStep.MuiDialogContent-root {
    padding: 0.5rem 1rem;
  }
`;

export function AddCashStep({ onProceed }: AddCashStepProps) {
  const location = useLocation();
  const { search } = location;

  const queryParams = new URLSearchParams(search);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const returnPath = process.env.REACT_APP_BASE_URL + actionsAddCashPath;

  const actionsQuery = useActionsQuery();
  const addCashAction = actionsQuery.data?.actions?.find(
    (action) => action.type === ActionType.AddCash
  );

  return (
    <QueryState {...actionsQuery}>
      {() => {
        return (
          <Wrapper>
            {addCashAction && (
              <DepositForm
                accountId={addCashAction.accountId}
                variant={DepositFormVariant.Actions}
                returnPath={returnPath}
                urlPaymentId={paymentId}
                defaultValue={addCashAction.amount || null}
                isCheckout
                onClose={onProceed}
                proceedOnComplete
              />
            )}
          </Wrapper>
        );
      }}
    </QueryState>
  );
}
