import { WrapperType } from 'generated/graphql';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import { BasketOrderWrapper } from '../FundsBasket.styles';
import { FundBasketBuyOrder } from './fundBasketBuyOrder';

interface BuyOrderContentProps {
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
}

export const BuyOrderContent = ({
  selectedAccountId,
  selectedAccountType,
}: BuyOrderContentProps) => {
  const { basketBuyOrders } = useFundsBasket({
    selectedAccountId,
  });

  return (
    <BasketOrderWrapper>
      {basketBuyOrders.map((fundBasketDetails, index) => {
        return (
          <div key={fundBasketDetails.id}>
            <FundBasketBuyOrder
              buyOrder={fundBasketDetails}
              selectedAccountId={selectedAccountId}
              selectedAccountType={selectedAccountType}
            />
          </div>
        );
      })}
    </BasketOrderWrapper>
  );
};
