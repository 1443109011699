import { useMediaQuery } from '@material-ui/core';
import { getSellEstimate } from 'components/Dialogs/Trading/helpers';
import { QueryState } from 'components/QueryState';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  GoBackLink,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontSize,
  FontStyle,
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import {
  ActionsText,
  OpenInNew,
  StepSection,
  StyledChevronButton,
  WideSection,
} from 'components/feature/Checkout/steps/OrderConfirmation/OrderConfirmation.styles';
import { SmallCopyWrapper } from 'components/feature/PortfolioBuilder/_shared/OrderStatusTable/OrderStatusTable.styles';
import { ShareClassInfoPopover } from 'components/feature/PortfolioBuilder/_shared/ShareClassInfoPopover';
import { getKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import type {
  LocalRecurringDeposits,
  LocalRecurringOrder,
} from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import * as format from 'formatting';
import {
  WrapperType,
  useInstrumentsByIsinsQuery,
  useRecurringTransactionsQuery,
  useSetRecurringDepositsMutation,
  useSetRecurringOrdersMutation,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { getOrderInstrumentShareClass } from 'helpers/instrumentNaming';
import { generateAutoSaveInvestPathPath, generateFundDetailsPath } from 'paths';
import { ReactNode, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  AccountsQueryAccount,
  AccountsQueryAccountPosition,
  InstrumentsByIsinsQueryInstrumentsInstrument,
  RecurringTransactionsQueryRecurringLatestEmployerContribution,
} from 'types/graphqlTypes';
import { getInvestmentChangeLikelyEffectiveDate } from '../../NextPaymentDateHelpers';
import { MultipleDepositsStepDialog } from '../../regularInvest/ManageDepositsDialog';
import {
  ActionsWarning,
  ActionsWrapper,
  DetailsContainer,
  DetailsLayout,
  EditOrderWrapper,
  ErrorContainer,
  OrderDetailsContainer,
  OverViewContainerBuyHeader,
  OverViewContainerOverallocation,
} from './ConfirmStep.styles';
import { RegularDepositsSummary } from './RegularDepositsSummary';
import { TargetDateFundCheck } from './TargetDateFundCheck';
import { useToggle } from 'hooks/useFeatureToggle';

interface ItemDetailsProps {
  label?: string;
  children: ReactNode;
  isNumber?: boolean;
  showDesktopLabel?: boolean;
}
export const ItemDetails = ({
  children,
  label,
  isNumber,
  showDesktopLabel = true,
}: ItemDetailsProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {isMdUp && (
        <TextXS
          $fontSize={FontSize.xs}
          $fontWeight={FontWeight.normal}
          $textAlign={isMdUp && isNumber ? TextAlign.right : undefined}
          $noMargin
        >
          {showDesktopLabel && label && `${label}:`} {children}
        </TextXS>
      )}
      {!isMdUp && (
        <SmallCopyWrapper>
          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
            {label}:
          </TextSmall>{' '}
          <TextSmall $noMargin $fontWeight={FontWeight.normal}>
            {children}
          </TextSmall>
        </SmallCopyWrapper>
      )}
    </>
  );
};

interface OrderDetailsProps {
  regularOrder: LocalRecurringOrder;
  instruments: InstrumentsByIsinsQueryInstrumentsInstrument[];
  instrument: InstrumentsByIsinsQueryInstrumentsInstrument;
  position?: AccountsQueryAccountPosition;
  totalDepositAmount: number;
  wrapperType: WrapperType;
}
const OrderDetails = ({
  regularOrder,
  instruments,
  instrument,
  position,
  totalDepositAmount,
  wrapperType,
}: OrderDetailsProps) => {
  const [verifyVintageStepToggle] = useToggle('global-verify-vintage-step');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const existingUnits = position?.quantity ?? 0;
  const currentPositionValue = getSellEstimate(instrument, existingUnits);
  const kiidUrl = instrument?.kiidDocumentUrl;
  const factsheetUrl = instrument?.factsheetUrl;
  const asset = instrument?.asset;
  const isTargetDateFund = instrument.isTargetDateFund || false;

  return (
    <OrderDetailsContainer>
      <OverViewContainerBuyHeader
        $showDetails={showDetails}
        onClick={() => setShowDetails(!showDetails)}
      >
        <ItemDetails label="Instrument" showDesktopLabel={false}>
          <TextXS
            $noMargin
            as={Link}
            to={generateFundDetailsPath({
              id: instrument?.assetId!,
              slug: instrument?.asset?.slug!,
            })}
            target="_blank"
          >
            <strong>{instrument?.name}</strong> <OpenInNew />
          </TextXS>{' '}
          {regularOrder.isDeleted && (
            <Text as="span" $noMargin $fontStyle={FontStyle.italic}>
              (Removed)
            </Text>
          )}
          {regularOrder.isNew && (
            <Text as="span" $noMargin $fontStyle={FontStyle.italic}>
              (New)
            </Text>
          )}
          {!regularOrder.isDeleted &&
            !regularOrder.isNew &&
            regularOrder.isEdited && (
              <Text as="span" $noMargin $fontStyle={FontStyle.italic}>
                (Updated)
              </Text>
            )}
          {isTargetDateFund &&
            !regularOrder.isDeleted &&
            verifyVintageStepToggle?.enabled && (
              <TargetDateFundCheck
                wrapperType={wrapperType}
                assetId={instrument.assetId}
                isin={instrument.isin}
                type="autoSaveInvest"
              />
            )}
        </ItemDetails>
        <ItemDetails label="Asset class" showDesktopLabel={false}>
          {asset?.assetClass?.name}
        </ItemDetails>
        <ItemDetails label="Share class" showDesktopLabel={false}>
          {getOrderInstrumentShareClass(
            instrument,
            instrument.asset?.instruments?.nodes || [],
            true
          )}
        </ItemDetails>
        <ItemDetails label="Percentage" showDesktopLabel={false} isNumber>
          {regularOrder.isDeleted
            ? '-'
            : format.percent3dp(regularOrder.proportion, undefined, false)}
        </ItemDetails>
        <ItemDetails label="Amount" showDesktopLabel={false} isNumber>
          {regularOrder.isDeleted
            ? '-'
            : format.currencyFull(regularOrder.proportion * totalDepositAmount)}
        </ItemDetails>
        <StyledChevronButton>
          {showDetails ? (
            <HiChevronUp size="2rem" title="Close" />
          ) : (
            <HiChevronDown size="2rem" title="Close" />
          )}
        </StyledChevronButton>

        <ErrorContainer>
          {instrument && instrument.askPrice && (
            <MinTradeUnitStatus
              variant="popover"
              amount={regularOrder.proportion * totalDepositAmount}
              askPrice={instrument.askPrice}
              minimumTradeUnit={instrument.minimumTradeUnit}
              fontSize={FontSize.small}
            />
          )}
        </ErrorContainer>
      </OverViewContainerBuyHeader>
      <DetailsContainer $showDetails={showDetails}>
        <DetailsLayout $showDetails={showDetails}>
          <ItemDetails label={'Current position size'}>
            {existingUnits}{' '}
            {instrument.instrumentType === 'Fund' ? 'units' : 'shares'}
          </ItemDetails>
          <ItemDetails label={'Current value'}>
            {format.currencyFull(currentPositionValue)}
          </ItemDetails>
          <ItemDetails label={'Last price update'}>
            {format.date(instrument.priceDate)}
          </ItemDetails>
          {!!kiidUrl && (
            <TextXS $noMargin>
              <StyledLink href={kiidUrl} target="_blank">
                {getKidDocName(instrument.instrumentType)}
              </StyledLink>
            </TextXS>
          )}
          {!!factsheetUrl && (
            <TextXS $noMargin>
              <StyledLink href={factsheetUrl} target="_blank">
                Factsheet
              </StyledLink>
            </TextXS>
          )}
        </DetailsLayout>
      </DetailsContainer>
    </OrderDetailsContainer>
  );
};

type status = 'added' | 'updated' | 'removed';

export interface ConfirmStepSuccessProps {
  deposits: status;
  orders: status;
}

const deriveConfirmMetaStatus = (
  hasExisting: boolean,
  hasNew: boolean
): status => {
  if (hasExisting && hasNew) {
    return 'updated';
  }
  if (!hasExisting && hasNew) {
    return 'added';
  }
  if (hasExisting && !hasNew) {
    return 'removed';
  }
  return 'updated';
};

export interface ConfirmStepProps {
  recurringTransactionsDeposits: LocalRecurringDeposits[];
  recurringTransactionsOrders: LocalRecurringOrder[] | null;
  latestEmployerContribution?: RecurringTransactionsQueryRecurringLatestEmployerContribution | null;
  totalAllocationProportion: number;
  totalAllocationAmount: number;
  onProceed: (success: ConfirmStepSuccessProps) => void;
  account: AccountsQueryAccount;
}

export function ConfirmStep({
  recurringTransactionsDeposits,
  recurringTransactionsOrders,
  latestEmployerContribution,
  totalAllocationProportion,
  totalAllocationAmount,
  onProceed,
  account,
}: ConfirmStepProps) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const isinWithNoData = recurringTransactionsOrders?.map(({ isin }) => isin);

  const recurringTransactionsQuery = useRecurringTransactionsQuery({
    accountId: account.id,
  });
  const hasPendingRegularCashExpectations =
    recurringTransactionsQuery.data?.recurringTransactions
      ?.hasPendingRegularCashExpectations;

  const instrumentsByIsinQuery = useInstrumentsByIsinsQuery(
    { isins: isinWithNoData },
    {
      enabled: isinWithNoData && isinWithNoData?.length > 0,
    }
  );

  const instruments = instrumentsByIsinQuery.data?.instrumentsByIsins?.nodes;

  const totalRecurringDepositAmount = recurringTransactionsDeposits.reduce<number>(
    (total, selectedRecurringDeposit) => {
      if (selectedRecurringDeposit.autoInvest) {
        return total + selectedRecurringDeposit.amount;
      }
      return total;
    },
    0
  );

  const totalAutoInvestDepositAmount =
    totalRecurringDepositAmount +
    (latestEmployerContribution ? latestEmployerContribution.amount : 0);

  const setRecurringOrdersMutation = useSetRecurringOrdersMutation();
  const setRecurringDepositsMutation = useSetRecurringDepositsMutation();

  const onConfirm = async () => {
    if (!account.id) {
      return;
    }
    try {
      const setRecurringDepositsMutationResult = await setRecurringDepositsMutation.mutateAsync(
        {
          input: {
            accountId: account.id,
            deposits: recurringTransactionsDeposits
              .filter((deposit) => !deposit.isDeleted)
              .map((deposit) => ({
                amount: deposit.amount,
                autoInvest: deposit.autoInvest,
                paymentDate: deposit.paymentDate,
              })),
          },
        }
      );

      const setRecurringOrdersMutationResult = await setRecurringOrdersMutation.mutateAsync(
        {
          input: {
            accountId: account.id,
            investments: recurringTransactionsOrders
              ?.filter((order) => order.proportion > 0)
              .map((order) => ({
                isin: order.isin,
                proportion: order.proportion,
              })),
          },
        }
      );
      if (
        setRecurringOrdersMutationResult.setRecurringOrders.success &&
        setRecurringDepositsMutationResult.setRecurringDeposits.success
      ) {
        onProceed({
          deposits: deriveConfirmMetaStatus(
            !!hasExistingRecurringTransactionsDeposits,
            recurringTransactionsDeposits.length > 0
          ),
          orders: deriveConfirmMetaStatus(
            !!hasExistingRecurringTransactionsOrders,
            !!recurringTransactionsOrders &&
              recurringTransactionsOrders.length > 0
          ),
        });
      }
    } catch (e) {
      // error handled by state
    }
  };

  const likelyEffectiveDate = getInvestmentChangeLikelyEffectiveDate(
    recurringTransactionsDeposits.map((deposit) => deposit.paymentDate),
    hasPendingRegularCashExpectations ?? true
  );

  const isOverAllocated = totalAllocationProportion > 1;

  const actions = (
    <StepActions>
      {likelyEffectiveDate !== null && (
        <ActionsWarning>
          <H4>Important</H4>
          <Text $noMargin>
            This will be effective from{' '}
            <strong>{format.date(likelyEffectiveDate)}</strong>.
          </Text>
          <Text $noMargin>
            Any existing regular deposits we receive before this will be
            processed as per your pre-existing instructions.
          </Text>
        </ActionsWarning>
      )}
      {recurringTransactionsOrders?.length !== 0 && (
        <ActionsText $textAlign={TextAlign.center}>
          The value of investments can go up and down, so you could get back
          less than you put in.{' '}
          {account.wrapperType === WrapperType.Isa &&
            "Tax treatment depends on an individual's circumstances and may be subject to change."}
        </ActionsText>
      )}
      <ActionsWrapper>
        <StepButton
          onClick={onConfirm}
          className="magenta"
          disabled={
            isOverAllocated ||
            setRecurringOrdersMutation.isLoading ||
            setRecurringDepositsMutation.isLoading
          }
        >
          Confirm
        </StepButton>
        <GoBackLink
          to={generateAutoSaveInvestPathPath({
            wrapperType: getPathSegmentForWrapperType(account.wrapperType),
          })}
          disabled={
            setRecurringOrdersMutation.isLoading ||
            setRecurringDepositsMutation.isLoading
          }
        />
      </ActionsWrapper>
    </StepActions>
  );

  const hasExistingRecurringTransactionsDeposits =
    recurringTransactionsQuery.data?.recurringTransactions?.deposits &&
    recurringTransactionsQuery.data.recurringTransactions.deposits.length > 0;

  const hasExistingRecurringTransactionsOrders =
    recurringTransactionsQuery.data?.recurringTransactions?.orders &&
    recurringTransactionsQuery.data.recurringTransactions.orders.length > 0;

  const [showManageDeposits, setShowManageDeposits] = useState(false);

  const manageDepositsDialog = (
    <MultipleDepositsStepDialog
      account={account}
      open={showManageDeposits}
      closeDialog={() => {
        setShowManageDeposits(false);
      }}
      onProceed={() => {
        setShowManageDeposits(false);
      }}
    />
  );

  // If the user has no deposits AND no orders in their new setup,
  if (
    recurringTransactionsDeposits.length === 0 &&
    recurringTransactionsOrders?.length === 0
  ) {
    return (
      <StepContainer>
        {hasExistingRecurringTransactionsDeposits &&
          hasExistingRecurringTransactionsOrders && (
            <>
              <StepTitle>Remove your regular deposit & invest plan</StepTitle>
              <StepContent width={StepContentWidth.extraWide}>
                <StepIntroductionTypography>
                  Please confirm you want to remove your regular deposit &
                  invest plan. Please note you'll also need to remove the
                  standing order setup with your bank.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  If you need to make any changes, click ‘Go back’.
                </StepIntroductionTypography>
              </StepContent>
            </>
          )}
        {hasExistingRecurringTransactionsDeposits &&
          !hasExistingRecurringTransactionsOrders && (
            <>
              <StepTitle>Remove your regular deposit plan</StepTitle>
              <StepContent width={StepContentWidth.extraWide}>
                <StepIntroductionTypography>
                  Please confirm you want to remove your regular deposit plan.
                  Please note you'll also need to remove the standing order
                  setup with your bank.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  If you need to make any changes, click ‘Go back’.
                </StepIntroductionTypography>
              </StepContent>
            </>
          )}

        {!hasExistingRecurringTransactionsDeposits &&
          hasExistingRecurringTransactionsOrders && (
            <>
              <StepTitle>Clear your regular invest plan</StepTitle>
              <StepContent width={StepContentWidth.extraWide}>
                <StepIntroductionTypography>
                  Please confirm you want to clear your regular investment plan.
                  Any regular deposits made will be held as cash. You can sign
                  in at any time to invest cash held in your account.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  If you need to make any changes, click ‘Go back’.
                </StepIntroductionTypography>
              </StepContent>
            </>
          )}

        {!hasExistingRecurringTransactionsDeposits &&
          !hasExistingRecurringTransactionsOrders && (
            <>
              <StepTitle>Remove your regular deposit & invest plan</StepTitle>
              <StepContent width={StepContentWidth.extraWide}>
                <StepIntroductionTypography>
                  Please confirm you want to clear your regular deposit and
                  invest plan. Any regular deposits made will be held as cash.
                  You can sign in at any time to invest cash held in your
                  account.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  If you need to make any changes, click ‘Go back’.
                </StepIntroductionTypography>
              </StepContent>
            </>
          )}

        {actions}
        {manageDepositsDialog}
      </StepContainer>
    );
  }

  return (
    <QueryState {...instrumentsByIsinQuery}>
      {() => (
        <StepContainer>
          <StepTitle>Confirm your decisions</StepTitle>
          <StepContent width={StepContentWidth.extraWide}>
            <StepIntroductionTypography>
              Please review your regular
              {(recurringTransactionsDeposits?.length ?? 0) > 1
                ? ' deposits '
                : ' deposit '}
              and automatic investments. If you need to make any changes, click
              ‘Go back’.
            </StepIntroductionTypography>
          </StepContent>
          <WideSection>
            <StepSection>
              <RegularDepositsSummary
                recurringTransactionsDeposits={recurringTransactionsDeposits}
                latestEmployerContribution={latestEmployerContribution}
                onManageDeposits={() => setShowManageDeposits(true)}
              />
            </StepSection>

            {recurringTransactionsOrders?.length === 0 ? (
              <StepSection>
                <H4>Regular investments</H4>
                {hasExistingRecurringTransactionsOrders ? (
                  <TextSmall>
                    You are clearing your regular investment plan. Any regular
                    deposits wil be held as cash. You can sign in at any time to
                    invest cash held in your account.
                  </TextSmall>
                ) : (
                  <TextSmall>
                    You have no regular investment plan. Any regular deposits
                    will be held as cash. You can sign in at any time to invest
                    cash held in your account.
                  </TextSmall>
                )}
              </StepSection>
            ) : (
              <StepSection>
                <H4>Regular investments</H4>
                <TextSmall>
                  This is your regular investment plan. All deposits set up for
                  auto-investment will be allocated to the funds based on the
                  percentages below.
                </TextSmall>
                {recurringTransactionsOrders?.length === 0 ? (
                  <Text $noMargin>No regular investment instructions</Text>
                ) : (
                  <>
                    {isMdUp && (
                      <OrderDetailsContainer>
                        <OverViewContainerBuyHeader>
                          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                            Instrument
                          </TextSmall>

                          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                            Asset class
                          </TextSmall>

                          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                            Share class{' '}
                            <ShareClassInfoPopover size="small" disableMargin />
                          </TextSmall>

                          <Text
                            $fontSize={FontSize.small}
                            $fontWeight={FontWeight.medium}
                            $textAlign={TextAlign.right}
                            $noMargin
                          >
                            Percentage
                          </Text>
                          <Text
                            $fontSize={FontSize.small}
                            $fontWeight={FontWeight.medium}
                            $textAlign={TextAlign.right}
                            $noMargin
                          >
                            Monthly total
                          </Text>
                        </OverViewContainerBuyHeader>
                      </OrderDetailsContainer>
                    )}
                    {instruments &&
                      recurringTransactionsOrders?.map((regularOrder) => {
                        const instrument = instruments.find(
                          (instrument) => instrument?.isin === regularOrder.isin
                        );
                        const position = account.positions.find(
                          (p) => p.instrument?.isin === regularOrder.isin
                        );

                        if (instrument === undefined) {
                          return null;
                        }

                        return (
                          <OrderDetails
                            key={regularOrder.isin}
                            regularOrder={regularOrder}
                            instruments={instruments}
                            instrument={instrument}
                            position={position}
                            totalDepositAmount={totalAutoInvestDepositAmount}
                            wrapperType={account.wrapperType}
                          />
                        );
                      })}
                    {isOverAllocated ? (
                      <OverViewContainerOverallocation>
                        <TextXS $noMargin $fontWeight={FontWeight.normal}>
                          Shortfall
                        </TextXS>
                        <TextXS $noMargin></TextXS>
                        <TextXS
                          $noMargin
                          $fontWeight={FontWeight.normal}
                        ></TextXS>
                        <TextXS
                          $noMargin
                          $textAlign={TextAlign.right}
                          $fontWeight={FontWeight.normal}
                        >
                          {format.percent3dp(
                            totalAllocationProportion - 1,
                            undefined,
                            false
                          )}
                        </TextXS>
                        <TextXS
                          $noMargin
                          $textAlign={TextAlign.right}
                          $fontWeight={FontWeight.normal}
                        >
                          {format.currencyFull(
                            totalAllocationAmount - totalAutoInvestDepositAmount
                          )}
                        </TextXS>
                      </OverViewContainerOverallocation>
                    ) : (
                      <OrderDetailsContainer>
                        <OverViewContainerBuyHeader>
                          <TextXS $noMargin $fontWeight={FontWeight.normal}>
                            Cash
                          </TextXS>
                          <TextXS $noMargin></TextXS>
                          <TextXS
                            $noMargin
                            $fontWeight={FontWeight.normal}
                          ></TextXS>
                          <TextXS
                            $noMargin
                            $textAlign={TextAlign.right}
                            $fontWeight={FontWeight.normal}
                          >
                            {format.percent3dp(
                              1 - totalAllocationProportion,
                              undefined,
                              false
                            )}
                          </TextXS>
                          <TextXS
                            $noMargin
                            $textAlign={TextAlign.right}
                            $fontWeight={FontWeight.normal}
                          >
                            {format.currencyFull(
                              totalAutoInvestDepositAmount -
                                totalAllocationAmount
                            )}
                          </TextXS>
                        </OverViewContainerBuyHeader>
                      </OrderDetailsContainer>
                    )}
                  </>
                )}
              </StepSection>
            )}
            <EditOrderWrapper>
              <LinkCustomButton
                $size="normal"
                $color="light"
                $isWide={false}
                to={generateAutoSaveInvestPathPath({
                  wrapperType: getPathSegmentForWrapperType(
                    account.wrapperType
                  ),
                })}
              >
                Edit order
              </LinkCustomButton>
            </EditOrderWrapper>
          </WideSection>

          {(setRecurringOrdersMutation.error ||
            setRecurringOrdersMutation.data?.setRecurringOrders.success ===
              false) && (
            <div>
              <Alert severity={Severity.error}>
                <Text $noMargin>Something went wrong</Text>
              </Alert>
            </div>
          )}
          {(setRecurringDepositsMutation.error ||
            setRecurringDepositsMutation.data?.setRecurringDeposits.success ===
              false) && (
            <div>
              <Alert severity={Severity.error}>
                <Text $noMargin>Something went wrong</Text>
              </Alert>
            </div>
          )}
          {isOverAllocated && (
            <div>
              <Alert severity={Severity.error}>
                <Text $noMargin>
                  You have a shortfall, go back and reduce the percentage
                  allocation to less than 100%.
                </Text>
              </Alert>
            </div>
          )}
          {actions}
          {manageDepositsDialog}
        </StepContainer>
      )}
    </QueryState>
  );
}
