import { colors } from 'constants/colors';
import { TextField } from 'components/Form/TextField';
import styled, { css } from 'styled-components';

export interface TargetDateFormProps {
  $theme: 'light' | 'dark';
}

export const TargetDateFormContainer = styled.div<TargetDateFormProps>`
  width: 100%;
  max-width: 600px;
  padding: 1.25rem;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    ${({ $theme }) =>
      $theme === 'light' &&
      css`
        *:not(button span) {
          color: ${colors.white};
          label.Mui-focused {
            color: ${colors.white};
          }
          &:before,
          &:after {
            border-color: ${colors.white} !important;
          }
        }
      `}
  }
`;

export const StepTitle = styled.h1`
  font-family: 'GT Super', serif;
  font-size: 2.375rem;
  text-align: center;
  font-weight: 300;
  margin-bottom: 1rem;
  line-height: 2.75rem;
  color: ${colors.richBlue};
`;

export type FormFieldProps = {
  $theme: 'light' | 'dark';
};

export const FormTextField = styled(TextField)<FormFieldProps>`
  .MuiFormHelperText-root.Mui-error {
    color: ${colors.danger};
    background-color: ${({ $theme }) =>
      $theme === 'light' ? colors.white : 'transparent'};
    border-radius: ${({ $theme }) => ($theme === 'light' ? '3px' : '0')};
    padding: ${({ $theme }) => ($theme === 'light' ? '5px' : '5px 0')};
    margin-top: ${({ $theme }) => ($theme === 'light' ? '5px' : '0')};
  }
`;
