import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const ComponentContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  padding: ${(p) => p.theme.spacing(1.25)}px;
  background-color: ${transparentize(0.95, colors.seaBlue)};
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;

  &:nth-child(2n) {
    background-color: ${transparentize(0.95, colors.magenta)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;
  span {
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const BodyContent = styled.div`
  font-weight: 300;
  font-size: 0.875rem;
`;

export const Description = styled.div`
  text-align: left;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
