import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  getSellEstimate,
  getSellEstimateUnits,
} from 'components/Dialogs/Trading/helpers';
import { QueryState } from 'components/QueryState';
import { H6, assetClasses } from 'components/design-system/Heading/Heading';
import { Pill } from 'components/design-system/Pill/Pill';
import {
  FontSize,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import {
  WrapperType,
  useAccountsQuery,
  useSellOrderDetailsByAccountQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { amount } from 'helpers/yupExtensions';
import { generateFundDetailsPath } from 'paths';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import {
  AccountsQueryAccount,
  SellOrderDetailsByAccountQuerySellOrderDetails,
} from 'types/graphqlTypes';
import * as yup from 'yup';
import Reference from 'yup/lib/Reference';
import * as format from '../../../../../formatting';
import { slugify } from '../../../../../formatting';
import { BasketSellOrder, useFundsBasket } from '../../hooks/useFundsBasket';
import {
  AmountInput,
  AssetClassPill,
  DetailsContainer,
  ErrorWrapper,
  FundBasketOrderLayout,
  MobileOrder,
  MobileOrderDetailsRow,
  MobileOrderInputWrapper,
  MobileOrderName,
  MobileOrderNameRow,
  NameContainer,
  OpenInNew,
  RemoveContainer,
} from '../_shared/FundsBasketShared.styles';
import {
  RemoveButton,
  StyledCard,
} from '../fundBasketBuyOrder/fundBasketBuyOrder.styles';

interface FundBasketSellOrderProps {
  sellOrder: BasketSellOrder;
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
}

interface FundBasketSellOrderInnerProps extends FundBasketSellOrderProps {
  accounts: AccountsQueryAccount[];
  sellOrderDetails: SellOrderDetailsByAccountQuerySellOrderDetails;
}

const amountFormSchema = yup.object().shape({
  amount: amount()
    .label('Amount')
    .min(yup.ref('$minAmount') as Reference<number>)
    .max(
      yup.ref('$currentPositionVal') as Reference<number>,
      ({ max: currentPositionVal }) => {
        if (currentPositionVal === 0) {
          return "You don't hold any of this fund to sell";
        }
        return `You currently own ${format.currencyFull(
          currentPositionVal
        )}, please type in a value no bigger than ${format.currencyFull(
          currentPositionVal
        )}`;
      }
    )
    .required('Please type in a valid amount')
    .typeError('Please type in a valid amount'),
});

export const FundBasketSellOrderInner = ({
  sellOrder,
  selectedAccountId,
  selectedAccountType,
  sellOrderDetails,
  accounts,
}: FundBasketSellOrderInnerProps) => {
  const [, setMode] = useMode();
  const isMutatingRebalancing = useIsMutating(['UpdatePortfolioRebalancing']);

  const { updateSellOrder, getSellOrder, remove } = useFundsBasket({
    selectedAccountId,
  });

  const selectedAccount = accounts?.find((acc) => acc.id === selectedAccountId);
  const selectedPosition = selectedAccount?.positions.find(
    (position) => position.isin === sellOrder.isin
  );

  const asset = selectedPosition?.instrument?.asset;
  const assetClassName = (asset?.assetClass?.name as assetClasses) || undefined;
  const currentPositionVal = selectedPosition?.currentValue || 0;

  const rangeValues = sellOrderDetails?.validRange?.quantityRange;
  const minAmount = selectedPosition?.instrument
    ? getSellEstimate(
        selectedPosition?.instrument,
        rangeValues?.minimumQuantity || 0
      ).toFixed(2)
    : 0;

  const { register, handleSubmit, errors, trigger } = useForm({
    resolver: yupResolver(amountFormSchema),
    defaultValues: {
      amount: sellOrder.amount,
    },
    mode: 'onBlur',
    context: { minAmount, currentPositionVal },
  });

  useEffect(() => {
    // This doesn't work. It should trigger the validation of the amount field.
    trigger('amount');
  }, [trigger]);

  const units = selectedPosition
    ? getSellEstimateUnits(selectedPosition?.instrument!, sellOrder.amount)
    : 0;

  const onSubmit = (data: any) => {
    const existingSellOrder = getSellOrder(sellOrder.isin);
    if (!existingSellOrder) {
      return;
    }
    const updatedSellOrder = {
      ...existingSellOrder,
      units,
      amount: parseFloat(data.amount || 0),
    };
    updateSellOrder(updatedSellOrder);
    trackGa({
      event: GaEventNames.updateCart,
      orderType: 'rebalancing',
      items: [
        {
          currency: 'GBP',
          item_id: updatedSellOrder.id,
          item_name: updatedSellOrder.fundName,
          item_type: updatedSellOrder.assetClass,
          affiliation: 'Tillit',
          item_variant: selectedAccountType,
          item_list_name: 'DPC Basket sells',
          quantity: updatedSellOrder.amount,
        },
      ],
    });
  };

  const handleRemoveItem = async () => {
    if (isMutatingRebalancing <= 0) {
      trackGa({
        event: GaEventNames.removeFromCart,
        orderType: 'rebalancing',
        ecommerce: {
          items: [
            {
              currency: 'GBP',
              item_id: sellOrder.id,
              item_name: sellOrder.fundName,
              item_type: sellOrder.assetClass,
              affiliation: 'Tillit',
              item_variant: selectedAccountType,
              item_list_name: 'DPC Basket sells',
              quantity: sellOrder.amount,
            },
          ],
        },
      });

      const { accountBasket } = await remove(sellOrder.isin);

      if (
        accountBasket.basketBuyOrders.length === 0 &&
        accountBasket.basketSellOrders.length === 0
      ) {
        setMode(null);
      }
    }
  };

  const theme = useTheme();
  const isMbUp = useMediaQuery(theme.breakpoints.up('sm'));

  return isMbUp ? (
    <StyledCard $isDarkUniverse={sellOrder?.isDarkUniverse}>
      <FundBasketOrderLayout>
        <NameContainer>
          <Link
            to={generateFundDetailsPath({
              id: sellOrder.id!,
              slug: slugify(sellOrder.fundName),
            })}
          >
            <H6
              darkUniverse={sellOrder?.isDarkUniverse}
              $noMargin={!sellOrder?.isDarkUniverse}
            >
              {sellOrder.instrumentName}{' '}
              <OpenInNew $isDarkUniverse={sellOrder?.isDarkUniverse} />
            </H6>
          </Link>
          {assetClassName && (
            <div style={{ marginTop: '0.5rem' }}>
              <Pill $fontSize={FontSize.small}>{assetClassName}</Pill>
            </div>
          )}
        </NameContainer>
        <DetailsContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AmountInput
              id="amount"
              name="amount"
              placeholder="Amount"
              required
              readOnly={isMutatingRebalancing > 0}
              ref={register}
              $error={!!errors.amount}
              onBlur={handleSubmit(onSubmit)}
            />
          </form>
        </DetailsContainer>
        <RemoveContainer>
          <RemoveButton
            onClick={() => handleRemoveItem()}
            $isActive={isMutatingRebalancing <= 0}
            $isDarkUniverse={sellOrder?.isDarkUniverse}
          >
            <AiOutlineClose />
          </RemoveButton>
        </RemoveContainer>
      </FundBasketOrderLayout>
      {errors.amount && (
        <ErrorWrapper>
          <TextXS $noMargin>{errors.amount.message}</TextXS>
        </ErrorWrapper>
      )}
    </StyledCard>
  ) : (
    <MobileOrder $isDarkUniverse={sellOrder?.isDarkUniverse}>
      <MobileOrderNameRow>
        <Link
          to={generateFundDetailsPath({
            id: sellOrder.id,
            slug: sellOrder.slug ?? '',
          })}
        >
          <MobileOrderName
            $isDarkUniverse={sellOrder?.isDarkUniverse}
            $noMargin
          >
            {sellOrder.instrumentName}{' '}
            <OpenInNew $isDarkUniverse={sellOrder?.isDarkUniverse} />
          </MobileOrderName>
        </Link>
        <RemoveButton
          onClick={() => handleRemoveItem()}
          $isActive={isMutatingRebalancing <= 0}
        >
          <AiOutlineClose />
        </RemoveButton>
      </MobileOrderNameRow>

      <MobileOrderDetailsRow>
        {assetClassName && (
          <AssetClassPill $assetClass={assetClassName}>
            <TextSmall>{assetClassName}</TextSmall>
          </AssetClassPill>
        )}
        <MobileOrderInputWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AmountInput
              id="amount"
              name="amount"
              placeholder="Amount"
              required
              readOnly={isMutatingRebalancing > 0}
              ref={register}
              $error={!!errors.amount}
              onBlur={handleSubmit(onSubmit)}
            />
          </form>
        </MobileOrderInputWrapper>
      </MobileOrderDetailsRow>
      {!!errors.amount && (
        <ErrorWrapper>
          <TextXS $noMargin>{errors.amount.message}</TextXS>
        </ErrorWrapper>
      )}
    </MobileOrder>
  );
};

export const FundBasketSellOrder = ({
  sellOrder,
  selectedAccountId,
  selectedAccountType,
}: FundBasketSellOrderProps) => {
  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });

  const sellOrderDetailsByAccountQuery = useSellOrderDetailsByAccountQuery({
    isin: `${sellOrder.isin}`,
    id: selectedAccountId!,
  });

  return (
    <QueryState {...accountsQuery}>
      {() => (
        <QueryState {...sellOrderDetailsByAccountQuery}>
          {() => (
            <FundBasketSellOrderInner
              sellOrder={sellOrder}
              selectedAccountId={selectedAccountId}
              selectedAccountType={selectedAccountType}
              sellOrderDetails={
                sellOrderDetailsByAccountQuery.data?.sellOrderDetailsByAccount!
              }
              accounts={accountsQuery.data?.accounts || []}
            />
          )}
        </QueryState>
      )}
    </QueryState>
  );
};
