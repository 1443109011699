import { useMediaQuery, useTheme } from '@material-ui/core';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { percent } from 'formatting';
import max from 'lodash/max';
import min from 'lodash/min';
import { useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { TooltipLayout, TooltipText } from '../_shared/Breakdown.styles';
import { TooltipBreakdown } from '../_shared/TooltipBreakdown';
import {
  assetClassBreakdownColors,
  getDonutColourForProportion,
} from '../_shared/colourHelper';
import { Breakdown, BreakdownComponentProps } from '../breakdownTypes';
import {
  DonutBreakdownLayout,
  DonutCenterTextLayout,
  DonutTooltipLayout,
  LegendCircle,
  LegendItemLayout,
  LegendLayout,
  LegendName,
  LegendTextLayout,
  LegendValue,
} from './DonutBreakdown.styles';

interface DonutArcProps {
  path: string;
  fill: string;
  data: Breakdown;
}

export interface DonutBreakdownProps extends BreakdownComponentProps {
  $assetClassColors?: boolean;
}

export const DonutBreakdown = ({
  breakdown,
  $assetClassColors = false,
}: DonutBreakdownProps) => {
  const donutRef = useRef<SVGSVGElement>(null);
  const [tooltipData, setTooltipData] = useState<null | Breakdown>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState<DonutArcProps | null>(null);

  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));

  const { width } = useWindowSize(); // This ensures that the donut is sized correctly on window resize
  const donutWidth = isLargeUp ? 600 : width - 72; // page padding of 40 + component padding of 2 rem
  const donutHeight = donutWidth;

  const breakdownHasDrillDown =
    !!breakdown.lines?.length && breakdown.lines.some((line) => line.lines);

  const handleMouseOver = (_: any, arc: DonutArcProps) => {
    if (donutRef.current) {
      const rect = donutRef.current?.getBoundingClientRect();
      if (rect) {
        const x = rect.width / 2;
        const y = rect.height / 2;
        if (isLargeUp || breakdownHasDrillDown) {
          setTooltipPosition({ x, y });
        }
        setTooltipData(arc.data);
        setShowTooltip(arc);
      }
    }
  };

  const handleMouseOut = () => {
    setTooltipData(null);
    setTooltipPosition({ x: 0, y: 0 });
    setShowTooltip(null);
  };

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    if (breakdownHasDrillDown) return null;
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;

    const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());

    // Mouse position relative to SVG
    const mouseX = svgPoint.x + 5;
    const mouseY = svgPoint.y + 5;

    // Get SVG dimensions
    const svgRect = svg.getBoundingClientRect();
    const svgWidth = svgRect.width;
    const svgHeight = svgRect.height;

    // Calculate distances from right and bottom edges of SVG
    const distanceFromRight = svgWidth - mouseX;
    const distanceFromBottom = svgHeight - mouseY;

    // Adjust tooltip position based on distance from edges
    let tooltipX = mouseX;
    let tooltipY = mouseY;

    if (distanceFromRight < 150) {
      tooltipX -= 160;
    }

    if (distanceFromBottom < 100) {
      tooltipY -= 95;
    }
    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };

  const minProportion =
    min(breakdown.lines?.map(({ proportion }) => proportion)) || 0;
  const maxProportion =
    max(breakdown.lines?.map(({ proportion }) => proportion)) || 1;

  const convertAbsoluteProportionToRelativeProportion = d3
    .scaleLinear()
    .domain([minProportion, maxProportion])
    .range([0, 1]);

  const pie = d3.pie<Breakdown>().value((d) => d.proportion)(breakdown.lines!);
  const arcGenerator = d3
    .arc<d3.PieArcDatum<Breakdown>>()
    .innerRadius(donutWidth / 3.5)
    .outerRadius(donutWidth / 2);

  const arcs = pie.map((d) => ({
    path: arcGenerator(d) as string,
    fill: $assetClassColors
      ? assetClassBreakdownColors[d.data.name]
      : getDonutColourForProportion(
          convertAbsoluteProportionToRelativeProportion(d.data.proportion)
        ),
    data: d.data,
  }));

  return (
    <DonutBreakdownLayout>
      <svg
        ref={donutRef}
        viewBox={`0 0 ${donutWidth} ${donutHeight}`}
        height={donutHeight}
        width={donutWidth}
        style={{ position: 'relative' }}
        onMouseMove={(e) => (isLargeUp ? e.persist() : handleMouseMove(e))}
      >
        <g transform={`translate(${donutWidth / 2},${donutWidth / 2})`}>
          {arcs.map((arc) => {
            const isTooltipDataMatching = tooltipData === arc.data;
            const unSelectedColor = $assetClassColors
              ? colors['grey-100']
              : colors['purple-100'];

            return (
              <path
                key={arc.data.name}
                d={arc.path}
                fill={
                  showTooltip
                    ? isTooltipDataMatching
                      ? arc.fill
                      : unSelectedColor
                    : arc.fill
                }
                onMouseOver={(event) => handleMouseOver(event, arc)}
                onMouseOut={() => handleMouseOut()}
                style={{
                  cursor: 'pointer',
                  transition: 'fill 0.25s ease-in-out',
                  stroke: colors.white,
                  strokeWidth: '2px',
                }}
              />
            );
          })}
        </g>
      </svg>
      <DonutLegend
        breakdown={breakdown}
        $assetClassColors={$assetClassColors}
      />
      {showTooltip && tooltipData && (
        <>
          {!tooltipData.lines && (
            <>
              {isLargeUp ? (
                <DonutCenterTextLayout
                  style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
                >
                  <TooltipText>{tooltipData.name}</TooltipText>
                  <TooltipText>
                    <b>{percent(tooltipData.proportion)}</b>
                  </TooltipText>
                </DonutCenterTextLayout>
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    left: tooltipPosition.x,
                    top: tooltipPosition.y,
                    pointerEvents: 'none',
                  }}
                >
                  <TooltipLayout>
                    <TooltipText>
                      <b>{tooltipData.name}</b>
                    </TooltipText>
                    <TooltipText>{tooltipData.description}</TooltipText>
                  </TooltipLayout>
                </div>
              )}
            </>
          )}

          {tooltipData.lines && tooltipData.lines.length > 0 && (
            <div
              style={{
                position: 'absolute',
                left: tooltipPosition.x,
                top: tooltipPosition.y,
                pointerEvents: 'none',
              }}
            >
              <DonutTooltipLayout $hasDrillDown>
                <TooltipText $hasDrillDown>
                  <b>{tooltipData.name}</b>{' '}
                  <span>{percent(tooltipData.proportion)}</span>
                </TooltipText>
                <TooltipBreakdown lines={tooltipData.lines} />
              </DonutTooltipLayout>
            </div>
          )}
        </>
      )}
    </DonutBreakdownLayout>
  );
};

const DonutLegend = ({ breakdown, $assetClassColors }: DonutBreakdownProps) => {
  const minProportion =
    min(breakdown.lines?.map(({ proportion }) => proportion)) || 0;
  const maxProportion =
    max(breakdown.lines?.map(({ proportion }) => proportion)) || 1;

  const convertAbsoluteProportionToRelativeProportion = d3
    .scaleLinear()
    .domain([minProportion, maxProportion])
    .range([0, 1]);

  return (
    <LegendLayout>
      {breakdown.lines?.map((breakdown) => (
        <LegendItemLayout key={breakdown.name}>
          <LegendCircle
            $fillColor={
              $assetClassColors
                ? assetClassBreakdownColors[breakdown.name]
                : getDonutColourForProportion(
                    convertAbsoluteProportionToRelativeProportion(
                      breakdown.proportion
                    )
                  )
            }
          />
          <LegendTextLayout>
            <LegendName>
              {breakdown.name}
              {!!breakdown.description && (
                <InfoPopoverV2
                  color="default"
                  $width="wide"
                  placement={'top'}
                  content={
                    <Text $fontWeight={FontWeight.normal} $noMargin>
                      {breakdown.description}
                    </Text>
                  }
                />
              )}
            </LegendName>
            <LegendValue>{percent(breakdown.proportion)}</LegendValue>
          </LegendTextLayout>
        </LegendItemLayout>
      ))}
    </LegendLayout>
  );
};
