import { useTheme } from '@material-ui/core';
import { BuyButton } from 'components/Button/BuyButton';
import { SellButton } from 'components/Button/SellButton';
import { AddFundToRecurringOrderButton } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddFundToRecurringOrderButton';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { trackGa } from 'helpers/track';
import { useIsAssetAvailableToBuyById } from 'hooks/useIsAssetAvailableToBuy';
import { useCallback } from 'react';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { BackToResults } from './BackToResults';
import {
  ActionContainerInner,
  ActionsContainer,
  ButtonsContainer,
} from './Styles/ActionsBox.style';

export interface PureActionBoxProps {
  asset: AssetQueryAsset;
  isin: string;
  userHolds: boolean;
  isAssetAvailableToBuy: boolean;
  authIndicator: boolean;
  onSell: () => void;
  onBuy: () => void;
  handleInstrumentChange: (isin: string) => void;
}

export function PureActionBox({
  asset,
  isin: selectedIsin,
  userHolds,
  isAssetAvailableToBuy,
  authIndicator,
  onSell,
  onBuy,
  handleInstrumentChange,
}: PureActionBoxProps) {
  const [mode] = useMode();

  const theme = useTheme();
  const isDarkUniverse = theme.darkUniverse;

  let actions = null;

  if (!selectedIsin) {
    return null;
  }

  if (!authIndicator) {
    actions = (
      <BuyButton
        assetId={asset.id!.toString()}
        selectedIsin={selectedIsin}
        onClick={onBuy}
        handleInstrumentChange={handleInstrumentChange}
      />
    );
  }

  if (mode?.mode === 'autoSaveInvest') {
    actions = (
      <AddFundToRecurringOrderButton
        selectedAssetId={asset.id!}
        selectedIsin={selectedIsin}
      />
    );
  }

  if (actions === null && isAssetAvailableToBuy && userHolds) {
    actions = (
      <>
        <BuyButton
          assetId={asset.id!.toString()}
          selectedIsin={selectedIsin}
          handleInstrumentChange={handleInstrumentChange}
          onClick={onBuy}
          label="Buy More"
        />
        <SellButton
          asset={asset}
          canSell={true}
          isin={selectedIsin}
          $color={`${isDarkUniverse ? 'white' : 'secondary'}`}
          variant={`${isDarkUniverse ? 'outlined' : 'contained'}`}
          onClick={onSell}
        />
      </>
    );
  }

  if (actions === null && isAssetAvailableToBuy && !userHolds) {
    actions = (
      <BuyButton
        assetId={asset.id!.toString()}
        selectedIsin={selectedIsin}
        onClick={onBuy}
      />
    );
  }

  return (
    <ActionsContainer>
      <ActionContainerInner>
        <ButtonsContainer>{actions}</ButtonsContainer>
        <ButtonsContainer>
          <BackToResults $align="center" />
        </ButtonsContainer>
      </ActionContainerInner>
    </ActionsContainer>
  );
}
export interface ActionsBoxProps {
  isin: string;
  userHolds: boolean;
  asset: AssetQueryAsset;
  handleInstrumentChange: (isin: string) => void;
}

export function ActionsBox({
  asset,
  isin,
  userHolds,
  handleInstrumentChange,
}: ActionsBoxProps) {
  const assetId = asset.id!.toString();
  const isAssetAvailableToBuy = useIsAssetAvailableToBuyById(assetId);
  const { signedIn } = useAuth();

  const trackActionBuy = useCallback(() => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'buy', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  }, [asset, assetId]);

  const trackActionSell = useCallback(() => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'sell', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  }, [assetId, asset]);

  return (
    <PureActionBox
      asset={asset}
      isin={isin}
      handleInstrumentChange={handleInstrumentChange}
      userHolds={userHolds}
      isAssetAvailableToBuy={isAssetAvailableToBuy}
      authIndicator={signedIn}
      onSell={() => trackActionSell()}
      onBuy={() => trackActionBuy()}
    />
  );
}
