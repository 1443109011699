import { QueryState } from 'components/QueryState';
import { Text } from 'components/design-system/Text/Text';
import { useAssetsTotalCountQuery } from 'generated/graphql';
import React from 'react';
import { Highlighted, ViewCountContainer } from './ViewCount.style';

export type ViewCountProps = {
  viewing: number;
  total: number;
};

export function ViewCount({ viewing, total }: ViewCountProps) {
  const assetsTotalCount = useAssetsTotalCountQuery();

  return (
    <QueryState {...assetsTotalCount}>
      {({ data }) => (
        <ViewCountContainer>
          {total > 12 ? (
            <p>
              Showing <Highlighted> {viewing}</Highlighted> out of
              <Highlighted> {total} </Highlighted> funds
            </p>
          ) : (
            <p>
              Showing <Highlighted> {viewing}</Highlighted> funds
            </p>
          )}
        </ViewCountContainer>
      )}
    </QueryState>
  );
}

interface TotalViewCountProps {
  total: number;
}

export function TotalViewCount({ total }: TotalViewCountProps) {
  const assetsTotalCount = useAssetsTotalCountQuery();

  return (
    <QueryState {...assetsTotalCount}>
      {({ data }) => (
        <Text $noMargin>
          Found <Highlighted>{total}</Highlighted> fund
          {(total > 1 || total === 0) && 's'}
        </Text>
      )}
    </QueryState>
  );
}

interface FundsListDividerProps {
  type: 'exact' | 'similar';
}

export function FundsListDivider({ type }: FundsListDividerProps) {
  return (
    <Text $noMargin>
      {type === 'exact' && 'Exact matches'}
      {type === 'similar' && 'Similar matches, sorted by relevance'}
    </Text>
  );
}
