import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StackedBarComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

interface HeadingCardInnerProps {
  $variant: 'full' | 'compact';
}

/**
 * Heading Card layout
 *
 * grid layout 1fr / 350px in rem
 */
export const HeadingCardInner = styled.div<HeadingCardInnerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${({ $variant }) =>
      $variant === 'full' ? '1rem 2rem' : '1rem 0'};
  }
`;

interface ChartTypeSelectorContainerProps {
  $variant: 'full' | 'compact';
}

export const ChartTypeSelectorContainer = styled.div<ChartTypeSelectorContainerProps>`
  display: flex;
  justify-content: ${({ $variant }) =>
    $variant === 'full' ? 'flex-end' : 'center'};
`;

export const DrillDownContainer = styled.div`
  border-top: solid 1px ${colors.midGrey};
  margin: 1rem 0;
  padding: 1rem 0;
  display: flex;
  justify-content: start;
`;

export const DrillDownHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`;

export const DrillDownHeaderWithIcon = styled(DrillDownHeader)`
  grid-template-columns: auto auto auto;
  gap: 0.75rem;
  img {
    width: 2rem;
  }
`;

export const DrillDownTabContainer = styled.div`
  border: solid 1px ${colors.darkGrey};
  padding: 1.25rem;
  border-radius: 0.5rem;
`;

export const NoBreakdownMessageEl = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StackedBarChartKeyContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;
  align-items: center;
  justify-items: center;
`;
