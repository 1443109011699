import { Accordion } from '@material-ui/core';
import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SmallShadow } from 'theme/shared/base.styles';

export const AutoInvestOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 2rem auto;
  max-height: 300px;
  overflow-y: auto;
`;

export const AutoInvestOrderRow = styled(Accordion)`
  padding: 0rem;
  border: 1px solid ${colors['grey-300']};
  border-radius: 5px;
  ${SmallShadow};
`;

export const AutoInvestOrderDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: white;
  width: 100%;
`;

export const AutoInvestOrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
`;

export const AutoInvestOrderInstrumentName = styled(H6)`
  font-size: 1rem;

  a svg {
    font-size: 0.625rem;
  }
`;

export const AutoInvestOrderInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const AutoInvestOrderDoc = styled(Link)`
  font-size: 0.75rem;
  text-decoration: none;
  color: ${colors.richBlack};

  &:hover {
    text-decoration: none;
    color: ${colors.magenta};
  }

  svg {
    color: ${colors['magenta-200']};
    font-size: 0.5rem;
  }
`;

export const AutoInvestOrderValues = styled.div`
  width: auto;
  min-width: clamp(10rem, 12rem, 10rem);
  font-weight: 400;
  color: ${colors.richBlack};
  font-size: 1.125rem;
  text-align: right;

  span {
    color: ${colors.magenta};
  }
`;

export const AutoInvestOrderBuyInformation = styled.div`
  width: 100%;
`;
