import {
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { WrapperType } from 'generated/graphql';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

interface TransferCashCopyProps {
  wrapperType: WrapperType;
  cedingProviderName: string | null;
}

export const AboutCashTransfer = ({
  cedingProviderName,
  wrapperType,
}: TransferCashCopyProps) => {
  return (
    <>
      <StepTitle>Transfer as cash</StepTitle>
      <StepText>
        <StepIntroductionTypography>
          By transferring as cash, {cedingProviderName} will sell all your
          holdings down to cash before transferring your{' '}
          {wrapperNameFriendly(wrapperType)} to TILLIT.
        </StepIntroductionTypography>
        <StepIntroductionTypography>
          {wrapperType === WrapperType.Sipp
            ? "Cash pension transfers can complete within a couple of weeks, sometimes quicker, but it depends upon the provider. We'll keep you informed every step of the way."
            : 'Cash transfers typically take a few working days to complete and we will keep you informed every step of the way.'}
        </StepIntroductionTypography>
      </StepText>
    </>
  );
};
