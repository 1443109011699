import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import React from 'react';
import { StyledTitle } from '../styles/Deposit.styles';

export interface FailedStepProps {
  onContactSupport: () => void;
  onPayManually: () => void;
  amount: number;
}

export function FailedStep({
  onContactSupport,
  onPayManually,
  amount,
}: FailedStepProps) {
  const openBankingThreshold = 5000;

  return (
    <>
      <StyledTitle disableTypography>
        <Typography variant="subtitle1" component="h1">
          There was a problem with your payment
        </Typography>
      </StyledTitle>
      <DialogContent>
        <Typography>No money has left your account.</Typography>
        {amount > openBankingThreshold && (
          <Alert severity="warning">
            Large payments sometimes work best as a manual transfer.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonWrapper $align="center">
          <CustomButtonV2
            onClick={() => onContactSupport()}
            $color="secondary"
            fullWidth
          >
            Contact support
          </CustomButtonV2>
          <CustomButtonV2
            onClick={() => onPayManually()}
            $color="secondary"
            fullWidth
          >
            Manual transfer
          </CustomButtonV2>
        </ButtonWrapper>
      </DialogActions>
    </>
  );
}
