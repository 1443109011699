import { Grid } from '@material-ui/core';
import { Footer } from 'components/Footer';
import { Header } from 'components/design-system/Header';
import { LogoHeader } from 'components/design-system/Header/LogoHeader';
import Banner from 'components/feature/Banner/Banner';
import { ModeFooter } from 'components/feature/mode/ModeFooter';
import {
  appSignInPath,
  autoSaveInvestPathConfirmPath,
  autoSaveInvestPathCreateDepositPath,
  checkoutBasePath,
  openAccountPath,
  startTransferBasePath,
  startTransferPath,
} from 'paths';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { StyledGrid } from './Layout.styles';

interface LayoutProps {
  children: any;
}

export function Layout({ children }: LayoutProps) {
  const currentDate = new Date().getTime();
  const targetDate = new Date('2024/04/06').getTime();
  const isExpired = currentDate > targetDate;
  const location = useLocation();

  const isAutoSaveInvestHideFooter = useRouteMatch([
    autoSaveInvestPathCreateDepositPath,
    autoSaveInvestPathConfirmPath,
  ]);

  const hideModeFooter =
    isAutoSaveInvestHideFooter ||
    location.pathname.includes(openAccountPath) ||
    location.pathname.includes(appSignInPath) ||
    location.pathname.includes(checkoutBasePath) ||
    location.pathname.includes(startTransferBasePath);

  return (
    <>
      <StyledGrid container direction="column">
        <Switch>
          <Route path={[openAccountPath, startTransferPath]}>
            <LogoHeader />
          </Route>
          <Route>
            <>
              {!isExpired && <Banner />}
              <Grid item>
                <Header />
              </Grid>
            </>
          </Route>
        </Switch>

        <Grid
          item
          xs
          container
          direction="column"
          style={{ flexBasis: 'auto' }}
        >
          {children}
          {!hideModeFooter && <ModeFooter />}
        </Grid>

        <Grid item>
          <Footer />
        </Grid>
      </StyledGrid>
    </>
  );
}
