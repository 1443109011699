import { PageContainer } from 'components/PageContainer';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { NationalIdentifierStep } from 'components/feature/openAccount/steps/NationalIdentifierStep';
import React from 'react';

export interface NationalIdentifierDialogProps extends ResponsiveDialogProps {}

export function NationalIdentifierDialog({
  onClose,
  ...props
}: NationalIdentifierDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <PageContainer>
        <NationalIdentifierStep
          onProceed={() => {
            onClose?.();
          }}
          onGoBack={() => {}}
          hideBackButton={true}
          source="nationalIdentifierDialog"
        />
      </PageContainer>
    </ResponsiveDialog>
  );
}
