import { Card } from 'components/design-system/Card/Card';
import { H3, H5, TextAlign } from 'components/design-system/Heading/Heading';
import {
  FontSize,
  FontStyle,
  FontWeight,
  Text,
} from 'components/design-system/Text/Text';
import * as formatting from 'formatting';
import { WrapperType, useAccountsQuery } from 'generated/graphql';
import { getInstrumentSelectorLabel } from 'helpers/instrumentNaming';
import { useFilterInstrumentsUserCanTrade } from 'hooks/useFilterInstrumentsUserCanTrade';
import styled from 'styled-components';
import {
  AccountsQueryAccount,
  AccountsQueryAccountPosition,
  AnyAsset,
  AssetQueryAsset,
  SearchAssetsQueryAsset,
} from 'types/graphqlTypes';
import { Pill, PillContainer } from '../AddToBasketDialog.style';

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;
`;

const SelectAccountCardInner = styled.div`
  display: grid;
  gap: 0.5rem;
  justify-content: center;
`;

interface SelectAccountStepProps {
  onProceed: ({
    selectedAccountType,
  }: {
    selectedAccountType: WrapperType;
    selectedAccountId: string;
  }) => void;
  asset: AnyAsset;
}

export function SelectAccountStepSell({
  onProceed,
  asset,
}: SelectAccountStepProps) {
  const accountsQuery = useAccountsQuery();

  // filter to select accounts that the current asset in
  const accounts = accountsQuery.data?.accounts?.filter((acc) =>
    acc.positions.find((p) => p?.instrument?.asset?.id === asset.id)
  );

  return (
    <div>
      <PillContainer>
        <Pill $filled $color="sell">
          Sell
        </Pill>
      </PillContainer>
      <H3>{asset.name}</H3>
      <CardContainer>
        {accounts?.map((account) => {
          const positions =
            account.positions &&
            account.positions.filter(
              (p) => p?.instrument?.asset?.id === asset.id
            );
          return (
            <Card
              key={account.id}
              onClick={() => {
                if (!account.id) {
                  return;
                }
                onProceed({
                  selectedAccountType: account.wrapperType,
                  selectedAccountId: account.id,
                });
              }}
              fullCardPointer
            >
              <SelectAccountCardInner>
                <Text
                  $textAlign={TextAlign.center}
                  $fontStyle={FontStyle.italic}
                  $fontSize={FontSize.small}
                  $noMargin
                >
                  Sell from my:
                </Text>
                <H5 $textAlign={TextAlign.center}>{account.wrapperType}</H5>
                {positions.length < 2 && (
                  <Text
                    $fontWeight={FontWeight.medium}
                    $fontSize={FontSize.large}
                    $noMargin
                  >
                    Current value:
                    {positions.map((position) => {
                      return (
                        <div key={position.isin}>
                          <Text
                            $fontSize={FontSize.normal}
                            $fontWeight={FontWeight.normal}
                            $textAlign={TextAlign.center}
                            $noMargin
                          >
                            {formatting.currencyFull(position.currentValue)}
                          </Text>
                        </div>
                      );
                    })}
                  </Text>
                )}

                {positions.length > 1 &&
                  positions.map((position) => {
                    return (
                      <UserHoldInstruments
                        key={position.isin}
                        asset={asset}
                        position={position}
                        account={account}
                      />
                    );
                  })}
              </SelectAccountCardInner>
            </Card>
          );
        })}
      </CardContainer>
    </div>
  );
}

interface UserHoldInstrumentsProps {
  position: AccountsQueryAccountPosition;
  asset: AnyAsset;
  account: AccountsQueryAccount;
}

function isAccountsQueryAccountPositionInstrumentAsset(
  asset: AnyAsset
): asset is AssetQueryAsset | SearchAssetsQueryAsset {
  if ('nodes' in asset.instruments) {
    return true;
  }
  return false;
}

function UserHoldInstruments({
  position,
  asset,
  account,
}: UserHoldInstrumentsProps) {
  const filteredInstruments = useFilterInstrumentsUserCanTrade(
    isAccountsQueryAccountPositionInstrumentAsset(asset)
      ? asset.instruments.nodes
      : [],
    account.id
  );

  return (
    <div>
      <Text
        $fontSize={FontSize.large}
        $fontWeight={FontWeight.medium}
        $textAlign={TextAlign.center}
        $noMargin
      >
        {formatting.currencyFull(position?.currentValue!)}
      </Text>
      <Text
        $fontSize={FontSize.normal}
        $fontWeight={FontWeight.normal}
        $textAlign={TextAlign.center}
        $noMargin
      >
        {getInstrumentSelectorLabel(position?.instrument!, filteredInstruments)}
      </Text>
    </div>
  );
}
