import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: -2px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    bottom: 0;
  }
`;
interface OverlayContentProps {
  isFullHeight: boolean;
}

export const OverlayContent = styled.div<OverlayContentProps>`
  width: 100%;
  max-width: 480px;
  min-height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  border-radius: ${({ isFullHeight }) => (isFullHeight ? '12px' : '20px')};
  border: 1px solid ${colors.midGrey};
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 2rem;
    border-radius: 20px;
    min-height: auto;
  }
`;
