import { IconButton } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export const SearchIconButton = styled(IconButton)`
  color: rgb(190, 190, 190);
`;

export const SearchForm = styled.form`
  width: 100%;
  padding: 0;
`;

export interface FilterGridProps {
  $variant: 'portfolio-builder' | 'list' | 'slider';
}

export const FiltersGrid = styled.div<FilterGridProps>`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: 1fr;

    ${({ $variant }) =>
      $variant === 'list' &&
      css`
        gap: 6px;
        overflow-y: auto;
        flex-grow: 1;
        padding-bottom: 10px;
      `}
  }
`;

export const FilterActions = styled.div<FilterGridProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ $variant }) =>
    $variant === 'list' &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -50px;
        width: 100%;
        height: 40px;
        background-color: ${colors.white};
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }
    `}
`;

export const SortGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  > * {
    width: calc(50% - 5px);
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    justify-content: flex-end;
    > * {
      width: calc(33.33% - 10px);
      margin-left: 15px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    justify-content: flex-end;
    > * {
      width: calc(25% - 15px);
      margin-left: 20px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  ${(p) => p.theme.breakpoints.up('lg')} {
    justify-content: flex-end;
    > * {
      width: calc(20% - 15px);
      margin-left: 20px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  button {
    height: 28px;
  }
`;

export const ClearButton = styled(CustomButtonV2)`
  order: 3;
  width: 100%;
  margin-top: 15px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: calc(33.33% - 10px);
    order: 0;
    margin-right: auto;
    margin-left: 0;
    margin-top: 0;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: calc(25% - 15px);
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: calc(20% - 15px);
  }
`;

export const VideoAskSpacer = styled.div`
  height: 4rem;
`;
