import { QueryState } from 'components/QueryState';
import { AccountType, useUserProfileQuery } from 'generated/graphql';
import {
  actionsAddCashPath,
  actionsPath,
  actionsSetupMfa,
  actionsTransferPath,
} from 'paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SetupMfaMethodForm } from '../setupMfa/SetupMfaMethodForm';
import { ActionsListStep } from './steps/ActionsList';
import { AddCashStep } from './steps/AddCashStep/AddCashStep';
import { TransferStep } from './steps/TransferStep/TransferStep';

export const Actions = () => {
  const userProfile = useUserProfileQuery();
  const history = useHistory();

  return (
    <QueryState {...userProfile}>
      {() => (
        <Switch>
          <Route path={actionsAddCashPath}>
            <AddCashStep
              onProceed={() => {
                history.push(actionsPath);
              }}
            />
          </Route>
          <Route path={actionsSetupMfa}>
            <SetupMfaMethodForm
              proceedBtnText="Continue"
              onProceed={() => {
                history.push(actionsPath);
              }}
            />
          </Route>
          <Route path={actionsTransferPath}>
            <TransferStep
              accountType={AccountType.Sipp}
              onProceed={() => {
                history.push(actionsPath);
              }}
            />
          </Route>
          <Route>
            <ActionsListStep />
          </Route>
        </Switch>
      )}
    </QueryState>
  );
};
