import { SmallCopyContainer } from './Style/SmallCopy.style';

interface SmallCopyProps {
  copy: string;
  $fontStyle?: string;
  $size?: number;
  $textAlign?: string;
}

/**
 * @deprecated - use Text instead
 */
export function SmallCopy({
  copy,
  $size = 12,
  $fontStyle = 'normal',
  $textAlign = 'left',
}: SmallCopyProps) {
  return (
    <SmallCopyContainer
      $fontSize={$size}
      $fontStyle={$fontStyle}
      $textAlign={$textAlign}
    >
      {copy}
    </SmallCopyContainer>
  );
}
