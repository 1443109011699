export type Source =
  | 'portfolio-builder-gia'
  | 'portfolio-builder-isa'
  | 'portfolio-builder-unknown'
  | 'funds'
  | 'similar'
  | 'regular-order';

export function isTypeSource(source?: string): source is Source {
  return (
    source !== undefined &&
    [
      'portfolio-builder-gia',
      'portfolio-builder-isa',
      'portfolio-builder-unknown',
      'funds',
    ].includes(source)
  );
}
