import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { SecondAccountDialog } from 'components/Dialogs/SecondAccount/SecondAccountDialog';
import { WithdrawDialog } from 'components/Dialogs/Withdraw/WithdrawDialog';
import { DataPill } from 'components/Pills';
import { DataProp } from 'components/Pills/DataPill/DataPill';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import { IntercomEventNames } from 'constants/intercomConstants';
import * as format from 'formatting';
import {
  AccountStatus,
  AccountsQuery,
  Eligibility,
  WrapperType,
  useAccountEligibilitiesQuery,
  useAccountsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getNameForWrapperType,
  getPathSegmentForWrapperType,
} from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { useModal } from 'mui-modal-provider';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { AccountLevelGrowthExplained } from 'strings/tooltips';
import { SupportedWrapperType } from 'types/utilityTypes';
import { Title } from '../../Cards.styles';
import {
  OpenSectionAction,
  SupportAction,
  UnOpenAction,
} from './AccountActions';
import { PureAccountClosedSection } from './AccountClosedSection';
import { PureAccountClosingSection } from './AccountClosingSection';
import {
  PendingAccountContent,
  UnOpenedGiaContent,
  UnOpenedIsaContent,
  UnOpenedSippContent,
} from './AccountContent';
import {
  CannotOpenContainer,
  ContentContainer,
  DateLabel,
  OnboardingContainer,
  PendingContainer,
  PillWrapper,
  SectionInner,
  UnOpenContainer,
} from './AccountSection.style';

export type AccountData = Exclude<
  AccountsQuery['accounts'],
  undefined | null
>[number]['valuationSummary'];

export interface SectionTitleProps {
  accountType: WrapperType;
  className?: string;
}

export function SectionTitle({ accountType, className }: SectionTitleProps) {
  const title = getNameForWrapperType(accountType);

  return <Title className={className}>{title}</Title>;
}
export interface PureAccountSectionProps {
  accountType: SupportedWrapperType;
  accountId?: string;
  createdOn?: string;
  eligibility?: Eligibility;
  accountData?: AccountData;
  transferActive?: boolean;
  regularInvestmentActive?: boolean;
  children: React.ReactNode;
  onRegularInvestment?: () => void;
  onTransferAccount?: () => void;
  onFinishSetup: () => void;
  onOpenAccount: () => void;
  onWithdrawCash: () => void;
  onSupport: () => void;
  suppressGrowthPercentage: boolean;
}

export function PureAccountSection({
  accountType,
  accountId,
  createdOn,
  eligibility,
  accountData,
  transferActive = false,
  regularInvestmentActive = false,
  children,
  onRegularInvestment,
  onOpenAccount,
  onFinishSetup,
  onTransferAccount,
  onWithdrawCash,
  onSupport,
  suppressGrowthPercentage,
}: PureAccountSectionProps) {
  const pillContent: DataProp[] = [
    {
      value: format.currencyFull(accountData?.marketValue ?? 0),
      label: 'Current value',
    },
  ];

  if (!suppressGrowthPercentage) {
    pillContent.push({
      value: format.percent(accountData?.growthProportion ?? 0),
      label: 'Total return',
    });
  } else {
    pillContent.push({
      value: format.currencyFull(accountData?.growthValue ?? 0),
      label: (
        <>
          Growth{' '}
          <InfoPopoverV2
            size="small"
            $width="wide"
            placement={'top-end'}
            content={AccountLevelGrowthExplained(accountData?.growthProportion)}
          />
        </>
      ),
    });
  }

  return (
    <SectionInner id={accountId}>
      <SectionTitle accountType={accountType} />

      {accountData ? (
        <>
          {createdOn && (
            <DateLabel>
              Account opened on {format.date(createdOn + 'Z')}
            </DateLabel>
          )}
          <PillWrapper>
            <DataPill content={pillContent} />
          </PillWrapper>

          <OpenSectionAction
            isSection={true}
            accountId={accountId}
            accountType={accountType}
            transferActive={transferActive}
            regularInvestmentActive={regularInvestmentActive}
            onRegularInvestment={onRegularInvestment}
            onWithdrawCash={onWithdrawCash}
            onTransferAccount={onTransferAccount}
          />
          {children}
        </>
      ) : (
        <>
          {eligibility === Eligibility.CanOpen && (
            <UnOpenContainer>
              <ContentContainer>
                {accountType === WrapperType.Gia && (
                  <UnOpenedGiaContent subTitle={true} />
                )}
                {accountType === WrapperType.Isa && (
                  <UnOpenedIsaContent subTitle={true} />
                )}
                {accountType === WrapperType.Sipp && (
                  <UnOpenedSippContent subTitle={true} />
                )}
              </ContentContainer>
              <UnOpenAction
                isSection={true}
                accountType={accountType}
                canBeCreated={true}
                transferActive={transferActive}
                regularInvestmentActive={regularInvestmentActive}
                onRegularInvestment={onRegularInvestment}
                onOpenAccount={onOpenAccount}
                onTransferAccount={onTransferAccount}
              />
            </UnOpenContainer>
          )}
          {eligibility === Eligibility.Onboarding && (
            <OnboardingContainer>
              <ContentContainer>
                {accountType === WrapperType.Gia && (
                  <UnOpenedGiaContent subTitle={true} />
                )}
                {accountType === WrapperType.Isa && (
                  <UnOpenedIsaContent subTitle={true} />
                )}
                {accountType === WrapperType.Sipp && (
                  <UnOpenedSippContent subTitle={true} />
                )}
              </ContentContainer>
              <UnOpenAction
                isSection={true}
                accountType={accountType}
                isOnboarding={true}
                transferActive={transferActive}
                regularInvestmentActive={regularInvestmentActive}
                onFinishSetup={onFinishSetup}
                onTransferAccount={onTransferAccount}
              />
            </OnboardingContainer>
          )}
          {eligibility === Eligibility.Pending && (
            <PendingContainer>
              <PendingAccountContent />
              <SupportAction onSupport={onSupport} />
            </PendingContainer>
          )}
          {eligibility === Eligibility.CannotOpen && (
            <CannotOpenContainer>
              <ContentContainer>
                {accountType === WrapperType.Gia && (
                  <UnOpenedGiaContent subTitle={true} />
                )}
                {accountType === WrapperType.Isa && (
                  <UnOpenedIsaContent subTitle={true} />
                )}
                {accountType === WrapperType.Sipp && (
                  <UnOpenedSippContent subTitle={true} />
                )}
              </ContentContainer>
              <UnOpenAction
                isSection={true}
                accountType={accountType}
                transferActive={transferActive}
                canBeCreated={false}
                regularInvestmentActive={regularInvestmentActive}
                onRegularInvestment={onRegularInvestment}
                onTransferAccount={onTransferAccount}
              />
            </CannotOpenContainer>
          )}
        </>
      )}
    </SectionInner>
  );
}

export interface AccountSectionProps {
  accountType: SupportedWrapperType;
  children: React.ReactNode;
}

export function AccountSection({ accountType, children }: AccountSectionProps) {
  const [suppressTimeWeightedGrowthPercentagesToggle] = useToggle(
    'global-suppress-time-weighted-growth-percentages'
  );

  const history = useHistory();
  const accountsQuery = useAccountsQuery();
  const userProfileQuery = useUserProfileQuery();

  const { showNewMessages, trackEvent } = useIntercom();

  const { showModal } = useModal();

  const currentAccount = useMemo(
    () =>
      accountsQuery.data?.accounts?.find(
        (acc) => acc.wrapperType === accountType
      ),
    [accountType, accountsQuery.data?.accounts]
  );

  const hasAccount = !!accountsQuery.data?.accounts?.length;

  const accountEligibilitiesQuery = useAccountEligibilitiesQuery(undefined, {
    enabled: !userProfileQuery.isFetching && !currentAccount,
  });

  const currentAccountEligibility = useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.find(
        (acc) => acc!.wrapperType === accountType
      )?.eligibility,
    [accountEligibilitiesQuery.data?.accountEligibilities, accountType]
  );

  const showRegularInvestmentButton = useMemo(() => {
    return currentAccount?.valuationSummary ||
      currentAccountEligibility === Eligibility.CanOpen
      ? true
      : false;
  }, [currentAccount?.valuationSummary, currentAccountEligibility]);

  const handleOpenAccount = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `open account clicked - ${accountType} dashboard section`,
    });
    if (hasAccount && accountType !== WrapperType.Sipp) {
      showModal(SecondAccountDialog, {
        accountType,
      });
    } else {
      history.push(
        `/open-account/${getPathSegmentForWrapperType(accountType)}`
      );
    }
  };

  const handleFinishSetup = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `finish setup clicked - ${accountType} dashboard section`,
    });
    history.push(`/open-account/${getPathSegmentForWrapperType(accountType)}`);
  };

  const handleTransferAccount = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `transfer account clicked - ${accountType} dashboard section`,
    });
    // Register user interest in transferring an account
  };

  const handleRegularInvestment = () => {
    trackEvent(IntercomEventNames.regularInvestmentClicked);
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} section - regular deposit button clicked`,
    });
    showNewMessages("I'd like to set up a regular deposit");
  };

  const handleWithdrawCash = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'withdraw cash button clicked',
    });
    showModal(WithdrawDialog, {
      accountId: currentAccount?.id!,
    });
  };

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} dashboard section - pending account clicked`,
    });
    showNewMessages('Can I get an update on my pending account please?');
  };

  const handleReOpenAccount = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} section - re-open account clicked`,
    });
    showNewMessages(`I would like to re-open my ${accountType}.`);
  };

  let sectionContent: React.ReactNode = null;
  switch (currentAccount?.status) {
    case AccountStatus.Closed:
      sectionContent = (
        <PureAccountClosedSection
          accountType={accountType}
          accountId={currentAccount?.id || undefined}
          accountData={currentAccount?.valuationSummary}
          onWithdrawCash={handleWithdrawCash}
          onReOpen={handleReOpenAccount}
        >
          {children}
        </PureAccountClosedSection>
      );
      break;
    case AccountStatus.Closing:
      sectionContent = (
        <PureAccountClosingSection
          accountType={accountType}
          accountId={currentAccount?.id || undefined}
          accountData={currentAccount?.valuationSummary}
          onWithdrawCash={handleWithdrawCash}
          onSupport={handleSupport}
        >
          {children}
        </PureAccountClosingSection>
      );
      break;
    default:
      sectionContent = (
        <PureAccountSection
          accountType={accountType}
          accountId={currentAccount?.id || undefined}
          createdOn={currentAccount?.createdTimestampUtc}
          eligibility={currentAccountEligibility}
          accountData={currentAccount?.valuationSummary}
          regularInvestmentActive={showRegularInvestmentButton}
          onOpenAccount={handleOpenAccount}
          onFinishSetup={handleFinishSetup}
          onTransferAccount={handleTransferAccount}
          onRegularInvestment={handleRegularInvestment}
          onWithdrawCash={handleWithdrawCash}
          onSupport={handleSupport}
          suppressGrowthPercentage={
            suppressTimeWeightedGrowthPercentagesToggle?.enabled ?? false
          }
        >
          {children}
        </PureAccountSection>
      );
  }

  return <QueryState {...accountsQuery}>{() => sectionContent}</QueryState>;
}
