import { Container, IconButton } from '@material-ui/core';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const SearchBarPlaceMarker = styled.div`
  visibility: hidden;
  position: relative;
  top: -80px;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: sticky;
  top: 80px;
  z-index: 8;
  width: 100%;

  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 4rem;
  }

  &.is-stuck {
    max-width: 100%;
    width: 100%;
    background: white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid ${colors.grey};

    ${({ theme }) => theme.breakpoints.up('sm')} {
      border-left: none;
      border-right: none;
    }
    .MuiInputBase-root {
      border: none;
      box-shadow: none;
    }
  }
`;

export const FilterDesktopHeading = styled(Text)`
  font-size: 1.25rem;
  font-weight: normal;
`;

export const SearchBarContent = styled.div`
  position: relative;
  max-width: 1000px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-self: center;

  grid-template-columns: 1fr 2.75rem 2.75rem;
  gap: 0;
  padding: 0 1.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-self: center;
    grid-template-columns: 1fr auto auto;
    gap: 0.25rem;
    padding: 0 6rem 0 6rem;
  }
`;

export const LandingNavContainer = styled(Container)`
  padding: 0;
  display: flex;
  align-items: center;
`;

interface FundFilterLayoutProps {
  $showFilters: boolean;
}

export const FundFilterLayout = styled(Container)<FundFilterLayoutProps>`
  display: grid;
  grid-template-areas: 'content';
  grid-template-columns: ${({ $showFilters }) =>
    $showFilters ? '1fr 3fr' : '1fr'};

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-areas: ${({ $showFilters }) =>
      $showFilters ? "'filters content'" : "'content content'"};
    gap: ${({ $showFilters }) => ($showFilters ? '1.5rem' : '0')};
  }
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.5rem;
  }
`;

interface FiltersWrapperProps {
  $variant: 'portfolio-builder' | 'funds';
}

export const FiltersWrapper = styled.div<FiltersWrapperProps>`
  grid-area: filters;
  position: sticky;
  top: 9rem;
  align-self: start;
  padding: 0 1.5rem 1rem;
  border-right: 1px solid ${colors['grey-200']};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    ${({ $variant }) =>
      $variant === 'portfolio-builder'
        ? 'max-height: calc(100vh - 200px);'
        : 'max-height: calc(100vh - 148px);'}
  }
`;

export const FiltersHeading = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  height: 31px; // This matches the height of the divider bar
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors['grey-200']};
  padding: 0 0 0.3125rem 0;
`;

export const CloseWrapper = styled.div``;

export const StyledIconButton = styled(IconButton)`
  span span {
    top: 0.35rem;
    right: 0rem;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      top: 0.35rem;
      right: 0.25rem;
    }
  }
`;
