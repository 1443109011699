import { TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { useAuth } from 'context/AuthContext';
import { WrapperType } from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  dynamicPortfolioConstructionPath,
} from 'paths';
import { ReactNode } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { UserProfileQueryClientSummaryAccount } from 'types/graphqlTypes';
import { ActiveModeFooter } from './ActiveModeFooter';
import { ResumeFooter } from './ResumeFooter/ResumeFooter';
import { modeType, useMode } from './useMode';

interface FooterKeyValueTextProps {
  $needsAttention: boolean;
}

const FooterKeyValueText = styled(TextSmall)<FooterKeyValueTextProps>`
  color: ${({ $needsAttention }) =>
    $needsAttention ? colors['danger'] : 'inherit'};
  margin: 0;
`;

interface KeyValueProps {
  label: ReactNode;
  value: ReactNode;
  $needsAttention?: boolean;
}

export const KeyValue = ({ label, value, $needsAttention }: KeyValueProps) => {
  return (
    <FooterKeyValueText $noMargin $needsAttention={$needsAttention}>
      <strong>{value}</strong>
      <br />
      {label}
    </FooterKeyValueText>
  );
};

export interface ModeFooterContentProps {
  onDepositChange?: (amount: number) => void;
  accounts: UserProfileQueryClientSummaryAccount[];
  isMdUp: boolean;
  alwaysOpen: boolean;
  activeMode: modeType;
  activeWrapperType?: WrapperType;
}

export interface SelectFundsPercentagesFooterProps {
  onDepositChange?: (amount: number) => void;
}

export function ModeFooter({
  onDepositChange,
}: SelectFundsPercentagesFooterProps) {
  const { signedIn } = useAuth();

  const [modeToggle] = useToggle('global-mode');

  const [mode] = useMode();

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);
  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;

  const dynamicPortfolioConstructionPathMatch = useRouteMatch<{
    wrapperType: string;
  }>([dynamicPortfolioConstructionPath]);
  const isDynamicPortfolioConstructionPath =
    dynamicPortfolioConstructionPathMatch !== null;

  if (
    (mode === null &&
      !isAutoSaveInvestPage &&
      !isDynamicPortfolioConstructionPath) ||
    (isAutoSaveInvestPage && !signedIn)
  ) {
    return null;
  }

  if (!modeToggle?.enabled) {
    return null;
  }

  if (
    mode?.mode === 'resume' &&
    !isAutoSaveInvestPage &&
    !isDynamicPortfolioConstructionPath
  ) {
    return <ResumeFooter />;
  }

  return <ActiveModeFooter onDepositChange={onDepositChange} />;
}
