import { Card } from 'components/design-system/Card/Card';
import { H1, H5 } from 'components/design-system/Heading/Heading';
import { StepIntroductionTypography } from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { ExtraSmallStraitNeumorphicShadow } from 'theme/shared/base.styles';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0;
  gap: 1.25rem;
`;

export const EscalationCard = styled(Card)`
  padding: 2rem 2.5rem;
  max-width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const EscalationHeading = styled(H5)`
  font-weight: 300;
`;

export const EscalationPercentage = styled(H1)`
  font-size: 4rem;
  color: ${colors.magenta};
`;

export const EscalationControl = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.midGrey};
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out, border 0.25s ease-in-out;
  ${ExtraSmallStraitNeumorphicShadow};

  svg {
    color: ${colors.magenta};
    font-size: 2rem;
  }

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${colors.grey};
    box-shadow: 0px 10px 20px 0px rgba(166, 171, 189, 0.35);
  }

  &:active {
    border: 1px solid ${colors.white};
    box-shadow: 0px 10px 20px 0px rgba(166, 171, 189, 0.15);
  }

  &:disabled {
    cursor: inherit;
    opacity: 0.35;
  }
`;

export const StepIntroductionEmTypography = styled(StepIntroductionTypography)`
  font-style: italic;
`;
