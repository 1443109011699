import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Loading } from 'components/Loading';
import React from 'react';

export function ProcessingStep() {
  return (
    <>
      <DialogTitle disableTypography>
        <Typography>We’re processing your payment.</Typography>
      </DialogTitle>
      <DialogContent>
        <Loading />
      </DialogContent>
    </>
  );
}
