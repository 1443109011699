import { TextLarge } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const ColumnHeading = styled.div`
  padding: 1.5rem 0;
`;

export const ColumnHeadingTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const ColumnHeadingGroups = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    justify-content: start;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const ColumnHeadingTab = styled(TextLarge)`
  a,
  a:visited,
  a:hover,
  a:focus {
    color: ${colors.richBlack};
  }

  a.active-tab,
  a.active-tab:visited,
  a.active-tab:hover,
  a.active-tab:focus {
    color: ${colors.magenta};
    font-weight: 500;
  }

  font-weight: 300;
  margin-bottom: 0;
`;
