import { AuthContext, useProvideAuth } from 'hooks/useProvideAuth';
import React, { createContext, useContext } from 'react';
import { QueryClient } from 'react-query';

const authContext = createContext<AuthContext>(undefined!);

interface AuthProviderProps {
  queryClient: QueryClient;
  children: React.ReactNode;
}

export function AuthProvider({ queryClient, children }: AuthProviderProps) {
  const auth = useProvideAuth(queryClient);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}
