import { Card } from 'components/design-system/Card/Card';
import {
  FontWeight,
  TextLarge,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import React from 'react';
import styled from 'styled-components';
import { AssetBreakdownComparison } from '../../../helpers/deriveAssetBreakdownComparison';

import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

interface EstimatedStatBoxesProps {
  activeBreakdownComparison: AssetBreakdownComparison;
}

const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

const ChangeIconUp = styled(AiOutlineArrowUp)`
  color: ${colors.success};
  width: 0.75rem;
`;

const ChangeIconDown = styled(AiOutlineArrowDown)`
  color: red;
  width: 0.75rem;
`;

interface ChangeAmountProps {
  change?: 'up' | 'down';
}
const ChangeAmount = styled(TextSmall)<ChangeAmountProps>`
  color: ${({ change }) => (change === 'up' ? colors.success : colors.danger)};
  margin: 0;
`;

export const EstimatedStatBoxes = ({
  activeBreakdownComparison,
}: EstimatedStatBoxesProps) => {
  return (
    <>
      <Card>
        <TextXS $noMargin>{activeBreakdownComparison.text} value</TextXS>
        <TextLarge $noMargin $fontWeight={FontWeight.medium}>
          {format.currencyFull(activeBreakdownComparison.amount)}
        </TextLarge>
        {activeBreakdownComparison.amountChange !== 0 && (
          <Change>
            {activeBreakdownComparison.change > 0 ? (
              <ChangeIconUp />
            ) : (
              <ChangeIconDown />
            )}
            <ChangeAmount
              change={activeBreakdownComparison.change > 0 ? 'up' : 'down'}
            >
              {format.currencyFull(activeBreakdownComparison.amountChange)}
            </ChangeAmount>
          </Change>
        )}
      </Card>
      <Card>
        <TextXS $noMargin>{activeBreakdownComparison.text} allocation</TextXS>
        <TextLarge $noMargin $fontWeight={FontWeight.medium}>
          {format.percent(activeBreakdownComparison.end / 100)}
        </TextLarge>
        {activeBreakdownComparison.amountChange !== 0 && (
          <Change>
            {activeBreakdownComparison.change > 0 ? (
              <ChangeIconUp />
            ) : (
              <ChangeIconDown />
            )}
            <ChangeAmount
              change={activeBreakdownComparison.change > 0 ? 'up' : 'down'}
            >
              {format.percent(activeBreakdownComparison.change / 100)}
            </ChangeAmount>
          </Change>
        )}
      </Card>
    </>
  );
};
