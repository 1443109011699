import { hexagonPagePath } from 'paths';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const getSafeReturnPath = (path: string) => {
  try {
    // Validate that the return path is a platform-relative URL
    const baseUri = new URL(document.baseURI);
    const urlParsed = new URL(path, document.baseURI);
    if (urlParsed.origin !== baseUri.origin) {
      // Attempting to direct to a different origin - reject and just return to /
      console.error(
        `An attempt was made to redirect to a different origin (${path}). Redirecting to '/'.`
      );
      return '/';
    } else {
      return path;
    }
  } catch (e) {
    console.error(e);
    return '/';
  }
};

export const useGetReturnPath = () => {
  const { search, hash } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  if (queryParams.has('return-path')) {
    const returnPath = queryParams.get('return-path') as string;
    if (hash) {
      return getSafeReturnPath(returnPath + hash);
    }

    return getSafeReturnPath(returnPath);
  }

  return getSafeReturnPath(hexagonPagePath);
};
