import { QueryState } from 'components/QueryState';
import { useState } from 'react';
import { AddressInput } from '../addressSchema';
import { useLoqateRetrieveQuery } from '../useLoqateQueries';
import { ConfirmAddress } from './ConfirmAddress';
import { SearchAddress } from './SearchAddress';

interface CurrentAddressOnSubmit {
  data: AddressInput;
}

export type stageType = 'search' | 'confirm' | 'manual';

interface AddressFormProps {
  title: string;
  onSubmit: (onSubmit: CurrentAddressOnSubmit) => void;
  address: AddressInput;
  onGoBack: () => void;
  onStageChange?: (stage: stageType) => void;
}

export function AddressForm({
  title,
  onSubmit,
  address,
  onGoBack,
  onStageChange,
}: AddressFormProps) {
  const [addressId, setAddressId] = useState<string | undefined | null>(
    undefined
  );

  const addressQuery = useLoqateRetrieveQuery({ addressId });

  if (address?.buildingName) {
    return (
      <ConfirmAddress
        title={title}
        address={address}
        onSubmit={onSubmit}
        onGoBack={() => {
          onGoBack();
        }}
      />
    );
  }

  return (
    <>
      {addressId === undefined && (
        <SearchAddress
          title={title}
          setAddressId={(addressId) => {
            setAddressId(addressId);
            if (onStageChange) {
              onStageChange(addressId === null ? 'manual' : 'confirm');
            }
          }}
          onGoBack={() => {
            onGoBack();
          }}
        />
      )}
      {addressId !== undefined && (
        <QueryState {...addressQuery}>
          {() => {
            const addressyAddress = addressQuery.data;
            return (
              <ConfirmAddress
                title={title}
                onSubmit={onSubmit}
                onGoBack={() => {
                  setAddressId(undefined);
                  if (onStageChange) {
                    onStageChange('search');
                  }
                }}
                address={
                  addressyAddress
                    ? {
                        buildingNameOrNumber:
                          addressyAddress.BuildingNumber ||
                          addressyAddress.BuildingName,
                        line1OrStreet: addressyAddress.Street,
                        townOrCity: addressyAddress.City,
                        postCode: addressyAddress.PostalCode,
                        countryIso: addressyAddress.CountryIso2,
                      }
                    : undefined
                }
              />
            );
          }}
        </QueryState>
      )}
    </>
  );
}
