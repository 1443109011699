import { ServerError } from 'components/design-system/ServerError/ServerError';
import { AccountType, useUpdateUserProfileMutation } from 'generated/graphql';
import React from 'react';
import styled from 'styled-components';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const StepActionsTransferAccountType = styled(StepActions)`
  margin: 0;
  gap: 0.75rem;
`;

export interface TransferAccountTypeProps {
  onProceed: (accountType: AccountType) => void;
}

export function TransferAccountType({
  onProceed,
  ...props
}: TransferAccountTypeProps) {
  const {
    mutateAsync: mutateUserProfileAsync,
    error,
  } = useUpdateUserProfileMutation();

  const handleTransfer = async (accountType: AccountType) => {
    await mutateUserProfileAsync({
      input: {
        initialAccount: {
          value: accountType,
        },
      },
    });
    onProceed(accountType);
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Pick your account type</StepTitle>

        <ServerError isVisible={!!error} />

        <StepIntroduction>
          <StepIntroductionTypography>
            Before you can transfer your account, please select the account type
            you would like to transfer to TILLIT.
          </StepIntroductionTypography>
        </StepIntroduction>

        <StepActionsTransferAccountType>
          <StepButton
            className="richBlue"
            fullWidth
            style={{
              whiteSpace: 'break-spaces',
            }}
            onClick={() => {
              handleTransfer(AccountType.Isa);
            }}
          >
            Stocks &amp; Shares ISA
          </StepButton>
          <StepButton
            className="richBlue"
            fullWidth
            style={{
              whiteSpace: 'break-spaces',
            }}
            onClick={() => {
              handleTransfer(AccountType.Gia);
            }}
          >
            General investment account
          </StepButton>
        </StepActionsTransferAccountType>
      </StepContent>
    </StepContainer>
  );
}
