import {
  ButtonBase,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { HiOutlineXCircle } from 'react-icons/hi';
import styled from 'styled-components';

export const ErrorIcon = styled(HiOutlineXCircle)`
  color: ${transparentize(0.5, colors.magenta)};
  font-size: 4.5rem;
  path {
    stroke-width: 0.75;
  }
`;

export const StyledTitle = styled(DialogTitle)`
  margin-top: ${(p) => p.theme.spacing(4)}px;
  padding-left: 0;
  padding-right: 0;
`;

export const DetailsList = styled.dl`
  margin: ${(p) => p.theme.spacing(2, 0)};
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: ${(p) => p.theme.spacing(1)}px;

  dt {
    font-weight: 600;
  }

  dd {
    margin-left: ${(p) => p.theme.spacing(5)}px;
  }

  dt,
  dd {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SourceAccountWarning = styled(Typography)`
  grid-column: 1 / 3;
  margin: ${(p) => p.theme.spacing(2, 0)};
`;

export const ReasonWrapper = styled.div`
  .MuiTypography-root {
    margin: ${(p) => p.theme.spacing(1)}px;
    text-align: center;
  }
`;

export const PaymentMethodHeading = styled(Typography).attrs({ variant: 'h2' })`
  order: 4;
  text-align: center;

  &.MuiTypography-root {
    margin-top: 0;
    flex: 1 1 100%;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    order: 0;

    &.MuiTypography-root {
      margin-bottom: ${(p) => p.theme.spacing(7)}px;
    }
  }
`;

export const TrueLayerTermsOfServiceContainer = styled.div`
  &&& {
    order: 4;
    flex: 0 0 100%;
    margin-bottom: ${(p) => p.theme.spacing(4)}px;

    ${(p) => p.theme.breakpoints.up('sm')} {
      order: 0;
    }
  }
`;

export const ProviderLogoContainer = styled.div`
  &&& {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: ${(p) => p.theme.spacing(4)}px;

    ${(p) => p.theme.breakpoints.up('sm')} {
      order: 0;
    }

    img {
      width: 75%;
      max-height: 35px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  & > :nth-child(even) {
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    & > :nth-child(even) {
      margin-bottom: 0;
      margin-left: ${(p) => p.theme.spacing(2)}px;
    }

    & > * {
      flex: 1 1 calc(50% - ${(p) => p.theme.spacing(2)}px);
    }
  }
`;

export const StackedButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const BankLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(p) => p.theme.spacing(2, 0)};

  img {
    max-height: 150px;
  }
`;

// This is to prevent the tile shadows from being clipped
export const ProvidersDialogContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  && {
    margin-left: ${(p) => p.theme.spacing(-2)}px;
    margin-right: ${(p) => p.theme.spacing(-2)}px;
    padding: 0 ${(p) => p.theme.spacing(2)}px;
  }
`;

export const ProvidersContainer = styled.div`
  display: grid;
  gap: ${(p) => p.theme.spacing(2)}px;
  grid-template-columns: 1fr 1fr;
  overflow: visible;
  padding: ${(p) => p.theme.spacing(2)}px;
  margin: ${(p) => p.theme.spacing(0, -2, 2)};
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.lightGrey};
  }
  ::-webkit-scrollbar-track {
    background: ${colors.vapor};
  }
  ${(p) => p.theme.breakpoints.up('sm')} {
    height: 30rem;
    grid-template-columns: 1fr 1fr 1fr;
    overflow-y: scroll;
  }
`;

export const ProviderTile = styled(ButtonBase)`
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid transparent;
  padding: ${(p) => p.theme.spacing(2.5, 2)};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

  &[disabled] {
    opacity: 0.5;
  }

  &:hover {
    border-color: ${colors.magenta};
  }

  img,
  svg {
    width: 75%;
    max-width: 200px;
    height: auto;
    max-height: 50px;
  }

  .MuiTypography-root {
    color: ${colors.richBlack};
    margin-top: ${(p) => p.theme.spacing(3)}px;
  }
`;

export const AlternativeHeading = styled(Typography).attrs({
  variant: 'h2',
})`
  && {
    text-align: center;
    color: ${colors.richBlue};
    margin: ${(p) => p.theme.spacing(2, 0, 4)};
  }
`;

export const DialogActionsFooter = styled.div`
  &&& {
    text-align: center;
    margin-top: ${(p) => p.theme.spacing(2)}px;
    flex: 0 0 100%;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  flex-direction: column;
`;

export const BufferWrapper = styled.div`
  margin: 1rem 0;
`;

export const BufferActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
