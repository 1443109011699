import { ClickAwayListener, Fade, Paper, Slide } from '@material-ui/core';
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Arrow,
  RecurringIcon,
  StyledPopper,
} from './RecurringOrderIcon.styles';

export interface RecurringOrderIconProps {
  content: React.ReactNode | JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
}

export function RecurringOrderIcon({
  content,
  onClick,
}: RecurringOrderIconProps) {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const popperState = usePopupState({ variant: 'popper', popupId: null });

  const handleClickAway = useCallback(() => popperState.close(), [popperState]);

  const modifiers = useMemo(
    () => ({
      flip: {
        enabled: true,
      },
      arrow: {
        enabled: true,
        element: arrowRef,
      },
    }),
    [arrowRef]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span onClick={(e: React.MouseEvent) => onClick?.(e)}>
        <RecurringIcon {...bindToggle(popperState)} />
        <StyledPopper
          {...bindPopper(popperState)}
          placement="top"
          modifiers={modifiers}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps}>
              <div style={{ position: 'relative', overflow: 'visible' }}>
                <Paper elevation={0}>{content}</Paper>
                <Slide
                  {...TransitionProps}
                  direction={placement.startsWith('bottom') ? 'up' : 'down'}
                >
                  <Arrow ref={setArrowRef} />
                </Slide>
              </div>
            </Fade>
          )}
        </StyledPopper>
      </span>
    </ClickAwayListener>
  );
}
