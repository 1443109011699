import {
  CombinedBreakdownType,
  CurrentBreakdownType,
} from '../hooks/useGetBreakdowns';

export interface AssetBreakdownComparison {
  text: string;
  start: number;
  end: number;
  change: number;
  amount: number;
  existingAmount: number;
  amountChange: number;
}

export const deriveAssetBreakdownComparison = (
  breakdowns: CurrentBreakdownType[],
  combinedBreakdowns: CombinedBreakdownType[]
): AssetBreakdownComparison[] => {
  // get list of unique assetClass
  const assetClasses = [...combinedBreakdowns, ...breakdowns].reduce<string[]>(
    (acc, breakdownLine) => {
      const { name } = breakdownLine;
      if (!acc.includes(name)) {
        acc.push(name);
      }
      return acc;
    },
    []
  );

  return assetClasses.map((name: string) => {
    const currentBreakdown = breakdowns.find(
      (breakdown) => breakdown.name === name
    );
    const newBreakdown = combinedBreakdowns.find(
      (breakdown) => breakdown.name === name
    );

    return {
      text: name,
      start: currentBreakdown?.percentage || 0,
      end: newBreakdown?.percentage || 0,
      change:
        (newBreakdown?.percentage || 0) - (currentBreakdown?.percentage || 0),
      amount: newBreakdown?.amount || 0,
      existingAmount: currentBreakdown?.amount || 0,
      amountChange:
        (newBreakdown?.amount || 0) - (currentBreakdown?.amount || 0),
    };
  });
};
