import { TextField } from 'components/Form/TextField';
import styled from 'styled-components';

export interface StyledTextFieldProps {
  $noMargin?: boolean;
  $fullWidth?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  max-width: 400px;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '1rem')};
`;
