import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { WrapperType } from 'generated/graphql';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import { Card, CardProps } from 'components/design-system/Card/Card';
import { css } from 'styled-components';

type SelectFundsPercentagesStepCardProps = CardProps & {
  state?: 'isDeleted' | 'isEdited' | 'isNew' | '';
  $isDarkUniverse: boolean;
};

export const SelectFundsPercentagesStepCardWrapper = styled(
  Card
)<SelectFundsPercentagesStepCardProps>`
  background-color: ${({ state, $isDarkUniverse }) =>
    $isDarkUniverse
      ? colors.richBlack
      : state === 'isDeleted'
      ? colors['grey-50']
      : colors.white};

  border-color: ${({ state }) =>
    state === 'isDeleted' ? colors['grey-200'] : colors.midGrey};

  opacity: ${({ state }) => (state === 'isDeleted' ? 0.75 : 1)};
`;

interface InputWrapperProps {
  $error?: boolean;
  $readOnly?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  border-radius: 0.25rem;
  display: flex;
  padding: 0.25rem 0.5rem;
  opacity: ${({ $readOnly }) => ($readOnly ? 0.5 : 1)};
  transition: opacity 0.25s ease-in-out;
  background-color: ${colors.white};
`;

interface PercentStyledInputProps {
  $hasError: boolean;
}
export const StyledInput = styled.input<PercentStyledInputProps>`
  border: none;
  outline: none;
  width: 100%;
`;

export const AmountOr = styled(Text)`
  color: ${colors['grey-800']};
`;

export const CardLayout = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: 'title title icon' 'details controls controls';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto 1.125rem;
  gap: 0.75rem;
  align-items: start;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-area: title;

  ${({ theme }) => theme.breakpoints.up('sm')} {
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 4.375rem;
  gap: 0.75rem;
  align-items: center;
  justify-items: end;
  a {
    text-decoration: none;
    margin-right: auto;
  }

  ${Text} {
    line-height: 1.75rem;
  }
`;

export const DetailsWrapper = styled.div`
  grid-area: details;
  display: flex;
  gap: 0.25rem;
  align-self: center;
`;

export const IconWrapper = styled.div`
  grid-area: icon;
`;

export const ControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: controls;
`;

interface InputLayoutProps {
  wrapperType: WrapperType;
  $hasError?: boolean;
}

export const InputLayout = styled.div<InputLayoutProps>`
  display: grid;
  grid-template-columns: ${({ wrapperType }) =>
    wrapperType === WrapperType.Sipp ? '5.5rem' : '5.5rem auto 5.5rem'};
  align-items: center;
  border: 1px solid #ccc;
  border-color: ${({ $hasError }) =>
    $hasError ? colors.danger : colors.midGrey};
  border-radius: 0.25rem;
  background: white;
`;

export const MobileContainer = styled.div<SelectFundsPercentagesStepCardProps>`
  position: relative;
  width: 100%;
  padding: 1.25rem 0.75rem;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;

  background-color: ${({ state, $isDarkUniverse }) =>
    $isDarkUniverse
      ? colors.richBlack
      : state === 'isDeleted'
      ? colors['grey-50']
      : colors.white};

  opacity: ${({ state }) => (state === 'isDeleted' ? 0.75 : 1)};

  & + & {
    border-top: none;
  }

  // The styling bellow ensures that the dark universe cards have a full width black background on mobile;
  ${({ $isDarkUniverse }) =>
    $isDarkUniverse &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: ${colors.richBlack};
        z-index: -1;
      }
    `};
`;

export interface RemoveIconProps {
  $isDarkUniverse: boolean;
}

export const RemoveIcon = styled(IconButton)<RemoveIconProps>`
  color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.white : colors.richBlack};
`;
