import TillitGraphQL from 'api/TillitGraphQL';
import { GaEventNames } from 'constants/gaConstants';
import { UserProfileDocument, UserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';

const investmentRanges = [
  { text: '0-10k', from: 0 },
  { text: '10k-50k', from: 10000 },
  { text: '50k-100k', from: 50000 },
  { text: '100k-250k', from: 100000 },
  { text: '250k-500k', from: 250000 },
  { text: '500k-1m', from: 500000 },
  { text: '1m+', from: 1000000 },
];

/**
 * useSignInTracking
 */
export const useSignInTracking = () => {
  return (userId: string) =>
    new Promise<void>(async (resolve) => {
      const data = await TillitGraphQL.request<UserProfileQuery>(
        UserProfileDocument
      );
      const accountTypes = data.clientSummary?.accounts
        .map((map) => map.wrapperType)
        .join(', ');

      const hasInvestments = data.clientSummary?.accounts.some((account) => {
        const bookValue = account.valuationSummary?.bookValue || 0;
        const uninvestedCash = account.valuationSummary?.uninvestedCash || 0;
        const totalInvested = bookValue - uninvestedCash;
        return totalInvested > 0;
      });

      const investmentAmount =
        data.clientSummary?.accounts.reduce((acc, account) => {
          const bookValue = account.valuationSummary?.bookValue || 0;
          return acc + bookValue;
        }, 0) || 0;

      const investmentRange = investmentRanges.reverse().find(({ from }) => {
        return investmentAmount >= from;
      });

      trackGa({
        event: GaEventNames.login,
        method: 'email',
        userID: userId,
        accountTypes: accountTypes,
        accountSince: data.userProfile?.signupTimestampUtc
          .substr(0, 10)
          .replace(/-/g, '/'),

        eventCallback: () => {
          resolve();
        },
        eventTimeout: 350,
        withInvestments: hasInvestments ? 'yes' : 'no',
        investmentRange: investmentRange?.text || '',
        investmentAmount,
      });
    });
};
