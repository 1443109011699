import { useAuth } from 'context/AuthContext';
import { useUserProfileQuery } from 'generated/graphql';
import { Loading } from './Loading';
import { LoadingScreenContainer } from './LoadingScreen.styles';

export function LoadingScreen() {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  return userProfileQuery.isLoading ? (
    <LoadingScreenContainer>
      <Loading />
    </LoadingScreenContainer>
  ) : null;
}
