import { ServerError } from 'components/design-system/ServerError/ServerError';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { MfaMethod, useSwitchMfaMethodMutation } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export interface ConfirmSwitchToSmsStepProps {
  onProceed?: () => void;
  onCancel?: () => void;
}

export function ConfirmSwitchToSmsStep({
  onProceed,
  onCancel,
}: ConfirmSwitchToSmsStepProps) {
  const {
    mutateAsync: switchMfaMethodAsync,
    isError: switchMfaMethodError,
    isLoading: switchMfaMethodLoading,
  } = useSwitchMfaMethodMutation({
    onSuccess: () => {
      trackGa({
        event: GaEventNames.mfa,
        method: 'SMS',
      });
      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.mobileMfa,
        mfaStatus: 'complete',
      });

      onProceed?.();
    },
  });

  const handleContinue = async () => {
    await switchMfaMethodAsync({ input: { method: MfaMethod.Sms } });
  };

  return (
    <StepContainer>
      <StepContent width={StepContentWidth.wide}>
        <StepTitle>Confirm switch</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            If you switch to using text messages to secure your account, you
            will no longer be able to use your authenticator app to sign in.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            Using an authenticator app is normally considered more secure than
            using text messages. Are you sure you want to switch?
          </StepIntroductionTypography>
          {switchMfaMethodError && <ServerError isVisible={true} />}
        </StepIntroduction>
      </StepContent>
      <StepActions $isHorizontal>
        <StepButton
          className="richBlue"
          onClick={onCancel}
          variant="outlined"
          disabled={switchMfaMethodLoading}
        >
          Cancel
        </StepButton>
        <StepButton
          className="magenta"
          onClick={handleContinue}
          disabled={switchMfaMethodLoading}
        >
          Yes, use text messages
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
