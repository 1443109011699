import { useAuth } from 'context/AuthContext';
import { useDeriveRedirectPath } from 'hooks/useDeriveRedirectPath';
import React from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

interface AuthRouteProps extends RouteProps {
  alternative?: React.ReactNode;
}

export function AuthRoute({ children, alternative, ...rest }: AuthRouteProps) {
  return (
    <Route {...rest}>
      <RequireAuth alternative={alternative}>{children}</RequireAuth>
    </Route>
  );
}

interface RequireAuthProps {
  children: React.ReactNode;
  alternative?: React.ReactNode;
}

function RequireAuth({ children, alternative = null }: RequireAuthProps) {
  const { signedIn, signIn } = useAuth();
  const location = useLocation();
  const redirectPath = useDeriveRedirectPath();

  useEffectOnce(() => {
    const query = new URLSearchParams(location.search);

    if (!signedIn && !alternative) {
      signIn(redirectPath, query.has('signedOut'));
    }
  });

  return signedIn ? <>{children}</> : <>{alternative}</>;
}
