import { colors } from 'constants/colors';
import { HedgingStatus } from 'generated/graphql';
import { getInstrumentSelectorLabel } from 'helpers/instrumentNaming';
import _ from 'lodash';
import { HiOutlineStar } from 'react-icons/hi';
import Select from 'react-select';
import type { SearchAssetsQueryAssetInstrument } from 'types/graphqlTypes';
import {
  AccountsQueryAccountPositionInstrument,
  AssetQueryAssetInstrument,
} from 'types/graphqlTypes';
import styled from 'styled-components';

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
`;

interface OptionType {
  isin: string;
  label: string | JSX.Element;
  value: string;
  helpText: string;
}

export interface InstrumentSelectorSelectProps {
  instruments: (
    | AccountsQueryAccountPositionInstrument
    | AssetQueryAssetInstrument
    | SearchAssetsQueryAssetInstrument
  )[];

  defaultValue?: string;
  useShortName?: boolean;
  value?: string;
  onChange: (isin: string) => void;
}

export function InstrumentSelectorSelect({
  instruments,
  defaultValue = '',
  useShortName = false,
  value = '',
  onChange,
}: InstrumentSelectorSelectProps) {
  const getHelperText = ({
    incomeAccumulationFlag,
    hedging,
  }:
    | AccountsQueryAccountPositionInstrument
    | AssetQueryAssetInstrument
    | SearchAssetsQueryAssetInstrument) => {
    const lookup = {
      ['Accumulation' +
      HedgingStatus.Unhedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested.',
      ['Accumulation' +
      HedgingStatus.Hedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
      ['Income' +
      HedgingStatus.Unhedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement.',
      ['Income' +
      HedgingStatus.Hedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
    };

    return lookup[(incomeAccumulationFlag ?? '') + hedging];
  };

  const options = _(instruments)
    .orderBy([
      (i) => i.isCustomUniverse,
      (i) => i.hedging === HedgingStatus.Hedged,
      (i) => i.incomeAccumulationFlag,
    ])
    .map((instrument) => ({
      label: (
        <LabelWrapper>
          {useShortName
            ? getInstrumentSelectorLabel(instrument, instruments, true)
            : getInstrumentSelectorLabel(instrument, instruments)}
          {instrument.isCustomUniverse && (
            <>
              {' '}
              <HiOutlineStar />
            </>
          )}
        </LabelWrapper>
      ),
      isin: instrument?.isin,
      value: instrument?.isin,
      helpText: getHelperText(instrument),
    }))
    .value();

  return (
    <>
      <Select<OptionType>
        onChange={({ isin }: OptionType) => onChange(isin)}
        options={options}
        isSearchable={false}
        defaultValue={
          defaultValue
            ? options.find(({ isin }) => isin === defaultValue)
            : undefined
        }
        value={value ? options.find(({ isin }) => isin === value) : undefined}
        placeholder="Select instrument"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            maxWidth: '15rem',
            backgroundColor: colors.vapor,
            borderRadius: '1rem',
            borderColor: colors.lightGrey,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors.lightGrey,
            maxWidth: '15rem',
            borderRadius: '1rem',
            overflow: 'hidden',
          }),
        }}
      />
    </>
  );
}
