import { QueryState } from 'components/QueryState';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { StyledA } from 'components/design-system/Link';
import { TextSmall } from 'components/design-system/Text/Text';
import { WrapperType, useAssetQuery } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { useMatchesAllocatedVintage } from 'hooks/useMatchesAllocatedVintage';
import {
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { AssetQueryAsset } from 'types/graphqlTypes';

interface TargetDateFundCheckProps {
  isin: string;
  assetId: number;
  wrapperType: WrapperType;
  type: 'buy' | 'autoSaveInvest';
}

interface TargetDateFundInfoProps
  extends Pick<TargetDateFundCheckProps, 'isin' | 'wrapperType' | 'type'> {
  asset: AssetQueryAsset;
}

function TargetDateFundInfo({
  isin,
  asset,
  wrapperType,
  type,
}: TargetDateFundInfoProps) {
  const { matches } = useMatchesAllocatedVintage({
    isin,
    asset,
  });

  return matches ? null : (
    <InfoPopoverV2
      size="small"
      placement="top"
      content={
        type === 'buy' ? (
          <>
            <TextSmall>
              You are about to invest in a vintage that does not match the
              information you have given us.
            </TextSmall>

            <TextSmall>
              If this is not your intention please{' '}
              <StyledA
                href={generateDynamicPortfolioConstructionBasketPath({
                  wrapperType: getPathSegmentForWrapperType(wrapperType),
                })}
              >
                edit your order
              </StyledA>{' '}
              before confirming.
            </TextSmall>
          </>
        ) : (
          <>
            <TextSmall>
              You are about to setup an regular investment in a vintage that
              does not match the information you have given us.
            </TextSmall>
            <TextSmall>
              If this is not your intention please{' '}
              <StyledA
                href={generateAutoSaveInvestPathPath({
                  wrapperType: getPathSegmentForWrapperType(wrapperType),
                })}
              >
                edit your order
              </StyledA>{' '}
              before confirming.
            </TextSmall>
          </>
        )
      }
    />
  );
}

export function TargetDateFundCheck({
  isin,
  assetId,
  wrapperType,
  type,
}: TargetDateFundCheckProps) {
  const assetQuery = useAssetQuery({ id: assetId });
  const asset = assetQuery.data?.asset;

  return (
    <QueryState {...assetQuery}>
      {() => {
        if (!asset) {
          return null;
        }
        return (
          <TargetDateFundInfo
            isin={isin}
            asset={asset}
            wrapperType={wrapperType}
            type={type}
          />
        );
      }}
    </QueryState>
  );
}
