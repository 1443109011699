import { StepContainer } from 'components/design-system/StepComponents/StepComponents';
import { TransferMethod, WrapperType } from 'generated/graphql';
import { TransferPensionDetails } from 'hooks/useTransferV2';
import { CedingProvider } from '../../types';
import { ConfirmCashTransfer } from './ConfirmCashTransfer';
import { ConfirmInspecieTransfer } from './ConfirmInspecieTransfer';

export type onProceedCallback = () => void;

export interface AboutTransferMethodProps {
  onProceed: onProceedCallback;
  onGoBack: () => void;
  onError: () => void;
  transferMethod: TransferMethod;
  cedingProvider: CedingProvider;
  cedingProviderAccountNumber: string;
  wrapperType: WrapperType;
  pensionDetails?: TransferPensionDetails;
}

export function Confirm({
  wrapperType,
  onProceed,
  transferMethod,
  cedingProvider,
  cedingProviderAccountNumber,
  onGoBack,
  onError,
  pensionDetails,
}: AboutTransferMethodProps) {
  return (
    <StepContainer>
      {transferMethod === 'CASH' && (
        <ConfirmCashTransfer
          wrapperType={wrapperType}
          transferMethod={transferMethod}
          cedingProvider={cedingProvider}
          cedingProviderAccountNumber={cedingProviderAccountNumber}
          onProceed={onProceed}
          onGoBack={onGoBack}
          onError={onError}
          pensionDetails={pensionDetails}
        />
      )}
      {transferMethod === 'IN_SPECIE' && (
        <ConfirmInspecieTransfer
          wrapperType={wrapperType}
          transferMethod={transferMethod}
          cedingProvider={cedingProvider}
          cedingProviderAccountNumber={cedingProviderAccountNumber}
          onProceed={onProceed}
          onGoBack={onGoBack}
          onError={onError}
          pensionDetails={pensionDetails}
        />
      )}
    </StepContainer>
  );
}
