import { TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { tint } from 'polished';
import styled, { css } from 'styled-components';
import { ExtraSmallStraitNeumorphicShadow } from 'theme/shared/base.styles';

const previousData = css`
  color: ${tint(0.5, colors.richBlack)};
`;

const currentData = css`
  color: ${colors.mauve};
`;

export const TableWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MobileLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${colors.grey};
  ${ExtraSmallStraitNeumorphicShadow}
  background-color: ${colors.white};
`;

export const DataLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const PreviousDataCopy = styled(TextNormal)`
  ${previousData}
`;

export const CurrentDataCopy = styled(TextNormal)`
  ${currentData}
`;

export const MobileDivider = styled.hr`
  width: 100%;
  height: 1px;
  background: none;
  border: none;
  background-color: ${colors['grey-400']};
`;
