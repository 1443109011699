import Cookies from 'js-cookie';

export const tillitFetch = (input: RequestInfo, init?: RequestInit) => {
  return fetch(input, {
    credentials: 'include',
    ...init,
    headers: {
      ...init?.headers,
      'X-CSRF-TOKEN': Cookies.get(
        process.env.REACT_APP_ANTIFORGERY_COOKIE_NAME!
      )!,
    },
  });
};
