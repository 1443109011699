import TillitGraphQL from 'api/TillitGraphQL';
import { investmentGoalExemplarTagsMap } from 'components/feature/FundsList/helpers/exemplarTagsHelpers';
import { GaEventNames } from 'constants/gaConstants';
import {
  SearchAssetsDocument,
  SearchAssetsQuery,
  SearchAssetsQueryVariables,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useInfiniteQuery } from 'react-query';
import { useAssetsFilter } from './useAssetsFilter';

export const useInfiniteSearchAssetsQuery = (assetsPerPage = 10) => {
  const {
    variables: { tags: filterTags, search, sort },
  } = useAssetsFilter();

  let tags = filterTags;

  if (tags?.length === 1) {
    const exemplarTag = investmentGoalExemplarTagsMap[tags[0]];
    if (exemplarTag) {
      tags = [...tags, exemplarTag];
    }
  }

  return useInfiniteQuery(
    ['Assets', assetsPerPage, { tags, search, sort }],
    ({ pageParam }) =>
      TillitGraphQL.request<SearchAssetsQuery, SearchAssetsQueryVariables>(
        SearchAssetsDocument,
        {
          input: {
            search,
            sort,
            tags,
          },
          first: assetsPerPage,
          ...pageParam,
        }
      ),
    {
      onSuccess: ({ pages, pageParams }) => {
        if (pageParams.length === 1 && (search || (tags && tags?.length > 0))) {
          trackGa({
            event: GaEventNames.viewSearchResults,
            search_term: search,
            search_tags: tags,
            searchEngine: 'funds search',
            numResults: pages[0].assets?.totalCount,
          });
        }
      },
      getNextPageParam: (lastPage) => {
        const pageInfo = lastPage.assets?.pageInfo;
        return (
          pageInfo?.hasNextPage && {
            after: pageInfo.endCursor,
          }
        );
      },
    }
  );
};
