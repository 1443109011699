import { CookiePreferences } from '@tillitinvest/cookie-consent';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

/**
 * Prepares GA for use
 *
 * Typically in the google analytics instructions it suggests putting this in a script tag before
 * calling gtag elsewhere. This breaks the CSP - so we set it up here instead.
 */
export function prepareGtag() {
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function gtag() {
      window.dataLayer?.push(arguments);
    };
}

function activateGtag() {
  if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    return;
  }
  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };

  if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW) {
    tagManagerArgs.preview = process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;
    tagManagerArgs.auth = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
  }

  TagManager.initialize(tagManagerArgs);

  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    return;
  }
  gtag('js', new Date());
  gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    anonymize_ip: true,
  });
  gtag('event', 'page_view', {
    page_title: 'TILLIT',
    page_location: window.location.href,
    page_path: window.location.pathname + window.location.search,
  });
}

function setGtagConsent(preferences: CookiePreferences) {
  gtag('consent', 'update', {
    ad_storage: preferences.allowTrackingCookies ? 'granted' : 'denied',
    analytics_storage: preferences.allowPerformanceCookies
      ? 'granted'
      : 'denied',
  });
  gtag('event', 'consent.changed', preferences);
}

export function setupGtag(preferences: CookiePreferences) {
  prepareGtag();
  setGtagConsent(preferences);
  if (preferences.allowPerformanceCookies) {
    activateGtag();
  }
}
