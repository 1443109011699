import { tillitFetch } from 'api/tillitFetch';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

interface ResetPasswordApi {
  username: string;
  userContext: string | null;
}

/**
 * react-query hook
 */
const useResetPassword = () => {
  return useMutation<any, Error, ResetPasswordApi>((data) => {
    return tillitFetch('identity/reset-password', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  });
};

const emailFormSchema = Yup.object().shape({
  email: Yup.string().label('Email').required().min(3),
});

export interface EmailStepProps {
  onProceed: (values: EmailFormValues) => void;
}

export interface EmailFormValues extends Yup.Asserts<typeof emailFormSchema> {}

export function EmailStep({ onProceed }: EmailStepProps) {
  const { mutateAsync } = useResetPassword();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onSubmit = async (data: EmailFormValues) => {
    try {
      setErrorMessage(undefined);

      const userContext = getCognitoUserContextData(data.email);

      const res = await mutateAsync({
        username: data.email,
        userContext,
      });
      if (res.status === 200) {
        onProceed(data);
      } else {
        setErrorMessage('Something went wrong');
      }
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  return (
    <Form<EmailFormValues> onSubmit={onSubmit} schema={emailFormSchema}>
      <StepContainer>
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>Forgot password</StepTitle>

          <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              Please enter the email address you used to open an account.
            </StepIntroductionTypography>
          </StepIntroduction>

          {errorMessage && (
            <ErrorAlert severity={Severity.error}>{errorMessage}</ErrorAlert>
          )}

          <StepFormContainer>
            <TextField name="email" label="Email" fullWidth />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>
              </>
            )}
          </FormState>
        </StepActions>
      </StepContainer>
    </Form>
  );
}
