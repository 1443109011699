import * as format from 'formatting';
import { Breakdown } from '../breakdownTypes';
import { TooltipText, TooltipBreakdownContainer } from './Breakdown.styles';
import React from 'react';

export interface TooltipBreakdownProps {
  lines: Breakdown[];
}

export function TooltipBreakdown({ lines }: TooltipBreakdownProps) {
  return (
    <TooltipBreakdownContainer>
      {lines.map((line, index) => (
        <React.Fragment key={`${format.slugify(line.name)}-${index}}`}>
          <TooltipText $hasDrillDown>
            <b>{line.name}</b>
            <span>{format.percent(line.proportion)}</span>
          </TooltipText>
        </React.Fragment>
      ))}
    </TooltipBreakdownContainer>
  );
}
