import { FormControl, InputLabel, Input as MuiInput } from '@material-ui/core';
import { TextButton } from 'components/Button/TextButton';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState } from 'react';
import {
  GoBackButton,
  StepContent,
  StepTitle,
} from '../../../../../design-system/StepComponents/StepComponents';
import { useLoqateFindQuery } from '../useLoqateQueries';
import {
  BackToSearchResults,
  ButtonsContainer,
  SearchContainerResult,
  SearchResult,
  SearchResults,
  SearchResultsContainer,
  SearchResultsStepAction,
  SearchResultsStepFormContainer,
  SearchResultsTitle,
} from './SearchAddress.styles';

interface SearchAddressProps {
  title: string;
  setAddressId: (addressId: string | null | undefined) => void;
  onGoBack: () => void;
}

export const SearchAddress = ({
  title,
  setAddressId,
  onGoBack,
}: SearchAddressProps) => {
  const searchInput = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [containerValue, setContainerValue] = useState<string | null>(null);

  const searchQuery = useLoqateFindQuery({ searchValue, countries: 'GB' }, {});

  const containerQuery = useLoqateFindQuery(
    { container: containerValue !== null ? containerValue : '' },
    {
      enabled: containerValue !== null,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((ev) => {
      setSearchValue(ev.target.value);
    }, 300),
    [setSearchValue]
  );

  return (
    <>
      <StepContent>
        <StepTitle>{title}</StepTitle>
        <SearchResultsStepFormContainer>
          <FormControl fullWidth>
            <InputLabel htmlFor="search">
              Start typing your address...
            </InputLabel>
            <MuiInput
              name="search"
              id="search"
              inputRef={searchInput}
              onKeyUp={search}
              autoFocus
            />
          </FormControl>

          <SearchResultsContainer $active={searchValue !== ''}>
            <SearchResultsTitle>Search results</SearchResultsTitle>
            <SearchResults>
              {containerValue && (
                <>
                  <BackToSearchResults
                    onClick={() => {
                      setContainerValue(null);
                    }}
                  >
                    Back to search results
                  </BackToSearchResults>
                  {containerQuery?.data?.Items?.map((item) => {
                    return (
                      <SearchResult
                        key={item.Text}
                        onClick={() => {
                          setAddressId(item.Id);
                        }}
                      >
                        {item.Text}
                      </SearchResult>
                    );
                  })}
                </>
              )}
              {!containerValue &&
                searchQuery?.data?.Items?.map((item) => {
                  if (item.Type === 'Container') {
                    return (
                      <SearchContainerResult
                        key={item.Text}
                        onClick={() => {
                          setContainerValue(item.Id);
                        }}
                      >
                        {item.Text}
                      </SearchContainerResult>
                    );
                  }
                  return (
                    <SearchResult
                      key={item.Text}
                      onClick={() => {
                        setAddressId(item.Id);
                      }}
                    >
                      {item.Text}
                    </SearchResult>
                  );
                })}
            </SearchResults>
          </SearchResultsContainer>

          <ButtonsContainer>
            <TextButton
              onClick={() => {
                setAddressId(null);
              }}
            >
              Enter address manually
            </TextButton>
          </ButtonsContainer>
        </SearchResultsStepFormContainer>
      </StepContent>

      <SearchResultsStepAction>
        <GoBackButton onClick={onGoBack} />
      </SearchResultsStepAction>
    </>
  );
};
