import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';

import { GaEventNames } from 'constants/gaConstants';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export function ContactSupport() {
  const { showNewMessages } = useIntercom();

  const handleContactSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: 'open-account - need more information',
    });
    showNewMessages('I’m having trouble setting up an account');
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Contact support</StepTitle>
        <StepText>
          <StepIntroductionTypography>
            We might need some more information to process your request - please
            reach out to our support team.
          </StepIntroductionTypography>
        </StepText>
      </StepContent>
      <StepActions>
        <StepButton
          variant="contained"
          className="richBlue"
          onClick={handleContactSupport}
        >
          Talk to support
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
