import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { H6 } from 'components/design-system/Heading/Heading';
import { HighlightWords } from 'components/design-system/HighlightWords';
import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { StyledA } from 'components/design-system/Link';
import { ExclusivePill, Pill } from 'components/design-system/Pill/Pill';
import {
  FontSize,
  FontWeight,
  TextAlign,
  TextSmall,
} from 'components/design-system/Text/Text';
import { getTargetDateInstrument } from 'components/feature/FundDetails/helpers/getTargetDateInstrument';
import { useTargetDateInfo } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { AddBuyOrderToBasketFullFlowDialog } from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketFullFlowDialog';
import { AddFundToRecurringOrderDialog } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddToFundToRecurringOrder';
import { BuyDialog } from 'components/feature/mode/BuyDialog/BuyDialog';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { Asset, WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import { useToggle } from 'hooks/useFeatureToggle';
import { deriveIsAssetAvailableToBuy } from 'hooks/useIsAssetAvailableToBuy';
import { Source } from 'pages/FundDetails/fundDetailsTypes';
import { generateFundDetailsWithReturnPath } from 'paths';
import { useRef, useState } from 'react';
import { BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import { SearchAssetsQueryAsset } from 'types/graphqlTypes';
import { useIntersectionObserver } from 'usehooks-ts';
import {
  AddBuyOrderToBasketDialog,
  useDialog,
} from '../../PortfolioBuilder/AddToBasket/AddBuyOrderToBasketDialog';
import { neverDisplayTagCodes } from '../helpers/tagPolicies';
import { FundsGridCardOptions } from '../hooks/useFundListOptions';
import {
  CardExpandedCloseButton,
  CardFundTagsContainer,
  CardOverlayContainer,
  CardPerformanceContainer,
  CardSubtitleContainer,
  CardTagButton,
  CartButton,
  CartIcon,
  CartLink,
  Details,
  FundCard,
  FundCardContainerInner,
  FundCardHeader,
  FundCardHeaderTag,
  FundOneLiner,
  NewTag,
  NewTagWrapper,
  PlusMore,
  SearchAssetName,
} from './FundGridCard.style';
import { FundGridCardTags } from './FundGridCardTags';
import { FundGridCardVideo } from './FundGridCardVideo';
import { OCFStats, TotalReturnStats } from './Stats/index';

/**
 * We place a play area slither in the center of the page.
 *
 * On mobile, if *any* of the video is within this area the video plays.
 *
 * Because the slither height is less than the gap between the videos - so no two will be in the
 * play area at the same time.
 */
const playAreaSlitherHeight = 300; // The height of the play area.
const playAreaSlitherOffset = 0; // The amount we offset the play area from the center of the screen.
const halfWindowHeight = window.innerHeight / 2;
const topMargin =
  halfWindowHeight + playAreaSlitherOffset - playAreaSlitherHeight / 2; // The amount of space between the top of the screen and the play area
const bottomMargin =
  halfWindowHeight - playAreaSlitherOffset - playAreaSlitherHeight / 2; // The amount of space between the bottom of the screen and the play area

const trackFundOpen = (asset: PartialDeep<Asset>, index: number) => {
  const tags = asset.tags?.nodes
    ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
    : [];

  trackGa({
    event: GaEventNames.selectItem,
    ecommerce: {
      items: [
        {
          item_id: asset.id,
          item_name: asset.name,
          item_type: asset.assetClass?.name,
          affiliation: 'Tillit',
          item_brand: asset.assetManager?.name,
          item_category: tags[0],
          item_category2: tags[1],
          item_category3: tags[2],
          item_category4: tags[3],
          item_category5: tags[4],
          index: index,
          item_list_name: 'DPC Funds list',
        },
      ],
    },
  });
};

export type ActionOptions =
  | 'add_to_basket_only'
  | 'add_to_regular_order_only'
  | 'any';

export interface TagProps {
  title: string;
  code: string;
  content: JSX.Element | string | null;
  categoryCode?: string;
  isImportant: boolean;
}

export interface FundCardProps {
  asset: SearchAssetsQueryAsset;
  forceExpanded?: boolean;
  index: number; // position in the list
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  searchTags: TagProps[];
  searchTerm?: string;
  actionsOptions: ActionOptions; // add to basket only | any - add to basket OR quick flow
  missingTags: TagProps[];
  missingFrontTags?: TagProps[];
  missingTagsOnFrontCard?: boolean;
  canHoverTags?: boolean;
  removeTag?: (tag: { code: string }) => void;
  addTag?: (tag: { code: string }, important?: boolean) => void;
  options: FundsGridCardOptions;
  setOptions: (options: FundsGridCardOptions) => void;
  source?: Source;
}

export function FundGridCard({
  asset,
  index,
  selectedAccountType,
  selectedAccountId,
  searchTags,
  searchTerm,
  actionsOptions,
  missingTags,
  missingFrontTags,
  missingTagsOnFrontCard = false,
  canHoverTags = true,
  removeTag,
  addTag,
  options,
  setOptions,
  source = 'funds',
}: FundCardProps) {
  const [toggleBuyDialogModeStep] = useToggle('global-buy-dialog-mode-step');

  const [mode] = useMode();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const videoRef = useRef<HTMLDivElement>(null);
  const fundCardRef = useRef<HTMLDivElement>(null);
  const dialogProps = useDialog();
  const { variables } = useAssetsFilter();
  const canHover = useMediaQuery('(hover: hover)', {
    defaultMatches: true,
  });
  const customUniverseISINs = asset.instruments.nodes.filter(
    (instrument) => instrument.isCustomUniverse
  );
  const isCustomUniverse = customUniverseISINs.length
    ? customUniverseISINs.length >= asset.instruments.nodes.length
      ? 'all'
      : 'some'
    : 'none';

  const isTargetDateFund = asset?.isTargetDateFund;
  const { targetDateInfo } = useTargetDateInfo();
  const targetDateInstrument =
    isTargetDateFund &&
    targetDateInfo?.yearOfBirth &&
    targetDateInfo?.intendedRetirementAge
      ? getTargetDateInstrument(
          asset,
          Number(targetDateInfo?.yearOfBirth) +
            Number(targetDateInfo?.intendedRetirementAge)
        )
      : null;

  const tags = asset?.tags?.nodes || null;

  const tagCount = tags.reduce((acc, tag) => {
    const searchTag = (searchTags || []).find(
      (searchTag) => searchTag.code === tag?.code
    );
    const isActive = !!searchTag;
    return !neverDisplayTagCodes.includes(tag.code) &&
      (isActive || !!tag.display)
      ? acc + 1
      : acc;
  }, 0);

  const handleVideoPlay = () => {
    setIsHover(true);
  };

  const handleVideoPause = () => {
    setIsHover(false);
  };

  const handleAddToBasket = () => {
    handleVideoPause();
    dialogProps.openDialog();
  };

  const videoEntry = useIntersectionObserver(fundCardRef, {
    freezeOnceVisible: true,
    rootMargin: '0px 0px 100px 0px',
  });

  const videoInView = useIntersectionObserver(videoRef, {
    rootMargin: `-${topMargin}px 0px -${bottomMargin}px 0px`,
    threshold: 0.51,
  });

  const fundDetailsPath = generateFundDetailsWithReturnPath(
    {
      id: asset?.id!,
      slug: asset?.slug!,
    },
    source === 'similar'
      ? {}
      : {
          searchTags: searchTags.map((tag) => tag.code).join(','),
          searchTerm: searchTerm,
          source: source,
        }
  );

  const missingFrontTagsCount = missingFrontTags?.length;
  const missingTagsCountAmount =
    !!missingFrontTagsCount && missingFrontTagsCount > 1
      ? missingFrontTagsCount - 1
      : ``;

  const searchWords = variables.search
    ?.toLocaleLowerCase()
    .split(' ')
    .filter((word: string) => word !== '');
  const assetNameWords = asset.name.toLowerCase().split(' ');
  const matchAssetName = assetNameWords.some((word) =>
    searchWords?.includes(word)
  );

  const isAssetAvailableToBuy = deriveIsAssetAvailableToBuy(asset);

  return (
    <>
      <FundCard
        $isExpanded={isExpanded}
        onMouseEnter={canHover && !isExpanded ? handleVideoPlay : undefined}
        onMouseLeave={canHover && !isExpanded ? handleVideoPause : undefined}
        ref={fundCardRef}
      >
        {toggleBuyDialogModeStep?.enabled ? (
          <BuyDialog {...dialogProps} assetId={asset.id} />
        ) : (
          <>
            {mode?.mode !== 'autoSaveInvest' &&
              actionsOptions === 'add_to_basket_only' && (
                <AddBuyOrderToBasketDialog
                  {...dialogProps}
                  asset={asset}
                  selectedAccountType={selectedAccountType}
                  selectedAccountId={selectedAccountId}
                />
              )}

            {mode?.mode !== 'autoSaveInvest' && actionsOptions === 'any' && (
              <AddBuyOrderToBasketFullFlowDialog
                {...dialogProps}
                assetId={`${asset.id}`}
                selectedAccountId={selectedAccountId}
                selectedAccountType={selectedAccountType}
              />
            )}

            {(mode?.mode === 'autoSaveInvest' ||
              actionsOptions === 'add_to_regular_order_only') &&
              dialogProps.open && (
                <AddFundToRecurringOrderDialog
                  {...dialogProps}
                  selectedAssetId={asset.id}
                />
              )}
          </>
        )}

        <FundCardContainerInner>
          <FundCardHeader $isExpanded={isExpanded}>
            {isCustomUniverse !== 'none' && (
              <FundCardHeaderTag>
                <ExclusivePill $canHover={canHover}>
                  {isCustomUniverse === 'all'
                    ? 'Employer exclusive fund'
                    : 'Employer exclusive share classes'}
                </ExclusivePill>
              </FundCardHeaderTag>
            )}
            <FundGridCardVideo
              asset={asset}
              options={options}
              setOptions={setOptions}
              isExpanded={isExpanded}
              videoEntryIsIntersecting={videoEntry?.isIntersecting}
              canHover={canHover}
              isSmUp={isSmUp}
              videoInViewIsIntersecting={videoInView?.isIntersecting}
              isHover={isHover}
            />
          </FundCardHeader>

          <Details>
            <FundOneLiner
              $isName={asset.description?.length ? false : true}
              $noMargin
            >
              <Link
                to={fundDetailsPath}
                onClick={() => trackFundOpen(asset, index)}
              >
                {asset.description && (
                  <HighlightWords
                    text={asset.description}
                    wordsToHighlight={searchWords || ['']}
                  />
                )}
                {!asset.description && (
                  <HighlightWords
                    text={asset.name}
                    wordsToHighlight={searchWords || ['']}
                  />
                )}
              </Link>
            </FundOneLiner>
            {variables.search && matchAssetName && asset.description && (
              <SearchAssetName $noMargin>
                <HighlightWords
                  text={asset.name}
                  wordsToHighlight={searchWords?.length ? searchWords : ['']}
                />
              </SearchAssetName>
            )}

            <CardPerformanceContainer>
              <TotalReturnStats
                totalReturnOver5Years={asset.totalReturnOver5Years}
              />
              <OCFStats ongoingChargePercent={asset.ongoingChargePercent} />
            </CardPerformanceContainer>
            {tagCount > 0 && (
              <CardFundTagsContainer
                onClick={() => {
                  setIsExpanded(true);
                }}
              >
                <CardTagButton>
                  {missingTagsOnFrontCard && missingFrontTags?.length ? (
                    <>
                      <Pill
                        $fontSize={FontSize.small}
                        $color={'grey'}
                        $strikeThrough
                      >
                        {missingFrontTags[0].title}
                      </Pill>
                      {missingFrontTags.length > 1 && (
                        <PlusMore
                          $noMargin
                          $fontSize={FontSize.small}
                          $fontWeight={FontWeight.normal}
                        >
                          +{missingTagsCountAmount} more
                        </PlusMore>
                      )}
                    </>
                  ) : (
                    `${tagCount} tags`
                  )}
                </CardTagButton>
              </CardFundTagsContainer>
            )}
            {asset.isNew && (
              <NewTagWrapper>
                <NewTag>New!</NewTag>{' '}
              </NewTagWrapper>
            )}
          </Details>

          {tagCount > 0 && (
            <CardOverlayContainer $isExpanded={isExpanded}>
              <CardExpandedCloseButton
                onClick={() => {
                  setIsExpanded(false);
                }}
              >
                <BiX />
              </CardExpandedCloseButton>
              <H6 $noMargin>Fund tags</H6>
              <CardFundTagsContainer>
                <FundGridCardTags
                  asset={asset}
                  searchTags={searchTags}
                  missingTags={missingTags}
                  removeTag={removeTag}
                  addTag={addTag}
                  canHoverTags={canHoverTags}
                />
              </CardFundTagsContainer>
              <CardSubtitleContainer>
                <Link
                  component={StyledA}
                  to={{
                    pathname: fundDetailsPath,
                  }}
                  onClick={() => trackFundOpen(asset, index)}
                >
                  View fund
                </Link>
              </CardSubtitleContainer>
            </CardOverlayContainer>
          )}

          {isAssetAvailableToBuy && (
            <>
              {isTargetDateFund && !targetDateInstrument?.instrument?.isin ? (
                <CartLink
                  to={fundDetailsPath}
                  onClick={() => trackFundOpen(asset, index)}
                >
                  <CartIcon />
                </CartLink>
              ) : (
                <>
                  {canHover ? (
                    <Popover
                      popupId="add-to-basket"
                      openOn="hover"
                      placement="bottom"
                      $padding="narrow"
                      content={
                        <TextSmall $textAlign={TextAlign.center} $noMargin>
                          Add to basket
                        </TextSmall>
                      }
                    >
                      {(popoverProps) => (
                        <CartButton
                          onClick={() => {
                            handleAddToBasket();
                          }}
                          {...popoverProps}
                        >
                          <CartIcon />
                        </CartButton>
                      )}
                    </Popover>
                  ) : (
                    <CartButton
                      onClick={() => {
                        handleAddToBasket();
                      }}
                    >
                      <CartIcon />
                    </CartButton>
                  )}
                </>
              )}
            </>
          )}
        </FundCardContainerInner>
      </FundCard>
    </>
  );
}
