import { Alert } from 'components/design-system/Alert/Alert';
import styled from 'styled-components';

export const ValueContainer = styled.div`
  margin: 1.25rem 0;
  font-size: 1.25rem;
`;

export const ErrorAlert = styled(Alert)`
  margin: 1rem 0;
`;
