import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { AddBuyOrderToBasketFullFlowForm } from 'components/feature/PortfolioBuilder/AddToBasket/AddBuyOrderToBasketFullFlowDialog';
import { AddBuyOrderToBasketStep } from 'components/feature/PortfolioBuilder/AddToBasket/steps/AddBuyOrderToBasketStep';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { useAutoSaveInvestState } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { useAuth } from 'context/AuthContext';
import {
  Position,
  WrapperType,
  useAccountQuery,
  useAccountsQuery,
  useAssetQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getWrapperTypeForPathSegment } from 'helpers/accountHelpers';
import { useGetDefaultSelectedInstrumentIsin } from 'helpers/assetHelpers';
import 'numeral/locales/en-gb';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  dynamicPortfolioConstructionPath,
} from 'paths';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccount,
  AssetQueryAsset,
  UserProfileQueryUserProfile,
} from 'types/graphqlTypes';
import { useMode } from '../useMode';
import { SelectModeAccountStepBuy } from './SelectModeAccountStepBuy';
import { AddToFundToRecurringOrderForm } from 'components/feature/autoSaveInvest/regularInvest/AddToFundToRecurringOrder/AddToFundToRecurringOrder';

/**
 * Returns the active mode and wrapper type based on the current route
 */
export function useDeriveActiveMode() {
  const [mode] = useMode();

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);
  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;
  const autoSaveInvestWrapperType = autoSaveInvestPageMatch?.params.wrapperType;

  const dynamicPortfolioConstructionPathMatch = useRouteMatch<{
    wrapperType: string;
  }>([dynamicPortfolioConstructionPath]);
  const isDynamicPortfolioConstructionPath =
    dynamicPortfolioConstructionPathMatch !== null;
  const dynamicPortfolioConstructionWrapperType =
    dynamicPortfolioConstructionPathMatch?.params.wrapperType;

  const activeWrapperType = isAutoSaveInvestPage
    ? getWrapperTypeForPathSegment(autoSaveInvestWrapperType!)
    : isDynamicPortfolioConstructionPath
    ? getWrapperTypeForPathSegment(dynamicPortfolioConstructionWrapperType!)
    : mode === null || mode!.mode === 'resume'
    ? undefined
    : mode.wrapperType;

  const activeMode = isAutoSaveInvestPage
    ? 'autoSaveInvest'
    : isDynamicPortfolioConstructionPath
    ? 'buy'
    : mode?.mode;

  return { activeWrapperType, activeMode };
}

export interface BuyFormProps {
  closeDialog: () => void;
  asset: AssetQueryAsset;
  accounts: AccountsQueryAccount[];
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
  defaultActiveWrapperType?: WrapperType;
  defaultActiveMode?: 'buy' | 'autoSaveInvest' | 'resume';
  userProfile?: UserProfileQueryUserProfile;
}

export const BuyForm = ({
  closeDialog,
  asset,
  accounts,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
  defaultActiveWrapperType,
  defaultActiveMode,
  userProfile,
}: BuyFormProps) => {
  const [defaultSelectedMode, setDefaultSelectedMode] = useState<
    'buy' | 'autoSaveInvest'
  >('buy');

  const [activeMode, setActiveMode] = useState(defaultActiveMode);
  const [activeWrapperType, setActiveWrapperType] = useState(
    defaultActiveWrapperType
  );

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);
  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;

  const dynamicPortfolioConstructionPathMatch = useRouteMatch<{
    wrapperType: string;
  }>([dynamicPortfolioConstructionPath]);
  const isDynamicPortfolioConstructionPath =
    dynamicPortfolioConstructionPathMatch !== null;

  const urlParams = new URLSearchParams(window.location.search);
  const search = urlParams.get('search') || '';

  const { state } = useAutoSaveInvestState();

  const activeAccount = accounts?.find(
    ({ wrapperType }) => wrapperType === activeWrapperType
  );

  const activeIsin = useGetDefaultSelectedInstrumentIsin(
    asset,
    defaultActiveMode
      ? { mode: defaultActiveMode, wrapperType: defaultActiveWrapperType }
      : null,
    userProfile,
    accounts,
    search,
    undefined,
    state?.orders ?? undefined,
    selectedIsin
  );

  if (activeMode === 'autoSaveInvest' && activeWrapperType && activeIsin) {
    return (
      <AddToFundToRecurringOrderForm
        asset={asset}
        selectedAccountType={activeWrapperType}
        selectedAccountId={activeAccount?.id!}
        selectedIsin={activeIsin}
        handleInstrumentChange={handleInstrumentChange}
        closeDialog={closeDialog}
        onGoBack={
          isAutoSaveInvestPage
            ? undefined
            : () => {
                setDefaultSelectedMode('autoSaveInvest');
                setActiveMode(undefined);
                setActiveWrapperType(undefined);
              }
        }
      />
    );
  }

  if (activeMode === 'buy' && activeWrapperType && activeIsin) {
    if (isDynamicPortfolioConstructionPath) {
      return (
        <AddBuyOrderToBasketStep
          flow="addToBasketOnlyFlow"
          selectedAccountType={activeWrapperType}
          selectedPosition={selectedPosition}
          onProceed={closeDialog}
          asset={asset}
          selectedAccountId={activeAccount?.id!}
          selectedIsin={activeIsin}
          handleInstrumentChange={handleInstrumentChange}
        />
      );
    } else {
      return (
        <AddBuyOrderToBasketFullFlowForm
          closeDialog={closeDialog}
          asset={asset}
          selectedAccountId={activeAccount?.id!}
          selectedAccountType={activeWrapperType}
          selectedPosition={selectedPosition}
          selectedIsin={activeIsin}
          handleInstrumentChange={handleInstrumentChange}
          accounts={accounts}
          amountStepGoBack={() => {
            setDefaultSelectedMode('buy');
            setActiveMode(undefined);
            setActiveWrapperType(undefined);
          }}
        />
      );
    }
  }
  return (
    <SelectModeAccountStepBuy
      onProceed={({ selectedMode, selectedAccountType }) => {
        setActiveWrapperType(selectedAccountType);
        setActiveMode(selectedMode);
      }}
      asset={asset}
      defaultSelectedMode={defaultSelectedMode}
    />
  );
};

export interface BuyDialogProps extends ResponsiveDialogProps {
  closeDialog: () => void;
  open: boolean;
  assetId: number;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
}

export function BuyDialog({
  closeDialog,
  open,
  assetId,
  selectedIsin,
  handleInstrumentChange,
}: BuyDialogProps) {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const { activeWrapperType, activeMode } = useDeriveActiveMode();
  const accountsQuery = useAccountsQuery(undefined, {
    enabled: signedIn,
  });
  const accounts = accountsQuery.data?.accounts;

  const assetQuery = useAssetQuery({ id: assetId }, { enabled: open });
  const asset = assetQuery.data?.asset;

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    const activeAccount = accounts?.find(
      ({ wrapperType }) => wrapperType === activeWrapperType
    );
    // invalidate so dashboard updates
    queryClient.invalidateQueries(
      useAccountQuery.getKey({ id: activeAccount?.id! })
    );
    // invalidate so basket updates
    queryClient.invalidateQueries(useAccountsQuery.getKey());

    closeDialog();
  };

  const basketType = useWhichBasketType();

  return (
    <ResponsiveDialog open={open} onClose={handleCloseDialog}>
      {open && (
        <QueryState {...accountsQuery}>
          {() => (
            <QueryState {...assetQuery}>
              {() => {
                if (!asset) {
                  return null;
                }
                return basketType === 'LocalBasket' ? (
                  <AddBuyOrderToBasketStep
                    flow="addToBasketOnlyFlow"
                    selectedAccountType={activeWrapperType}
                    onProceed={closeDialog}
                    asset={asset}
                    selectedIsin={selectedIsin}
                    handleInstrumentChange={handleInstrumentChange}
                  />
                ) : (
                  <BuyForm
                    closeDialog={closeDialog}
                    asset={asset}
                    accounts={accountsQuery.data?.accounts!}
                    selectedIsin={selectedIsin}
                    handleInstrumentChange={handleInstrumentChange}
                    defaultActiveWrapperType={activeWrapperType}
                    defaultActiveMode={activeMode}
                    userProfile={userProfileQuery.data?.userProfile!}
                  />
                );
              }}
            </QueryState>
          )}
        </QueryState>
      )}
    </ResponsiveDialog>
  );
}
