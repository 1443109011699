import styled from 'styled-components';

export const TransactionsContainer = styled.div`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;

  > *:first-child {
    margin-bottom: ${(p) => p.theme.spacing(2.5)}px;
  }

  .MuiTableCell-root:first-child {
    width: 58px;
    padding-right: ${(p) => p.theme.spacing(1)}px;

    .MuiIconButton-root {
      margin: 0;
    }
  }

  .MuiTableCell-root:nth-child(2) {
    width: 80%;
    padding-left: ${(p) => p.theme.spacing(1)}px;
  }

  .MuiTableCell-root:nth-child(3) {
    padding-right: ${(p) => p.theme.spacing(8)}px;
  }

  .MuiTableCell-root:nth-child(4) {
    padding-left: ${(p) => p.theme.spacing(8)}px;
  }
`;

export const AmountCell = styled.span`
  white-space: nowrap;
`;
