import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { DPCheckoutStepsType } from '../../Checkout/Checkout';
import { DpFlowTypes } from '../../Checkout/flowType';

export const trackCheckoutProgress = (
  step: DPCheckoutStepsType,
  flow: DpFlowTypes
) => {
  trackGa({
    event: GaEventNames.checkout,
    checkoutStep: step,
    checkoutFlow: flow,
  });
};
