import { IconButton, OutlinedInput, useMediaQuery } from '@material-ui/core';
import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { TextAlign, TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import React, { useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import styled, { useTheme } from 'styled-components';
import { SearchForm } from './_shared';

export interface SearchFieldProps {
  $isStuck?: boolean;
}

const StyledSearchField = styled(OutlinedInput)<SearchFieldProps>`
  position: initial;
  width: 100%;
  font-size: 0.875rem;
  box-shadow: none;
  border-radius: none;
  border: none;

  &:after {
    content: '';
    position: absolute;
    left: 1.5rem;
    bottom: 0;
    right: 1.5rem;
    height: ${({ $isStuck }) => ($isStuck ? '0' : '1px')};
    background-color: ${colors.richBlack};

    ${({ theme }) => theme.breakpoints.up('md')} {
      left: 5.5rem;
      right: 6rem;
    }
  }

  fieldset {
    display: none;
  }

  > input {
    font-family: ${({ theme }) => theme.typography.searchIconFontFamily};
    padding: 4px 0;
  }

  &.MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  @supports (-webkit-touch-callout: none) {
    font-size: 1rem;
  }
`;

export interface SearchBarProps {
  isStuck?: boolean;
}

export function SearchBar({ isStuck = false }: SearchBarProps) {
  const { variables, update } = useAssetsFilter();
  const searchField = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(variables.search ?? '');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const canHover = useMediaQuery('(hover: hover)');

  const search = (searchValue: string | undefined) => {
    if (searchValue?.length) {
      trackGa({
        event: GaEventNames.submitSearch,
        search_term: searchField.current?.value,
        searchEngine: 'funds list',
      });
    }
    variables.search = searchValue;
    update(variables);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search(searchField.current?.value);
  };

  // Purely here to record input value as it's typed to render the closing adornment
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputClearClick = () => {
    if (searchField.current) {
      searchField.current.value = '';
      setInputValue(searchField.current.value);
      search('');
    }
  };

  const placeHolderText = isMobile
    ? `${String.fromCharCode(0o53)} Search`
    : `${String.fromCharCode(
        0o53
      )} Search funds, fund managers, asset managers or top 10 holdings`;

  return (
    <SearchForm onSubmit={handleSubmit}>
      <StyledSearchField
        inputRef={searchField}
        fullWidth
        placeholder={placeHolderText}
        defaultValue={variables?.search || ''}
        onBlur={(ev) => {
          search(ev.target.value);
        }}
        $isStuck={isStuck}
        inputProps={{
          onChange: handleInputChange,
        }}
        endAdornment={
          !!inputValue &&
          (canHover ? (
            <Popover
              popupId="clear-icon"
              openOn="hover"
              placement="bottom"
              $padding="narrow"
              content={
                <TextSmall $textAlign={TextAlign.center} $noMargin>
                  Clear
                </TextSmall>
              }
            >
              {(popoverProps) => (
                <IconButton
                  color="default"
                  aria-label="Clear"
                  onClick={() => {
                    handleInputClearClick();
                  }}
                  {...popoverProps}
                >
                  <HiX />
                </IconButton>
              )}
            </Popover>
          ) : (
            <IconButton
              color="default"
              aria-label="Clear"
              onClick={() => {
                handleInputClearClick();
              }}
            >
              <HiX />
            </IconButton>
          ))
        }
      />
    </SearchForm>
  );
}
