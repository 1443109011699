import CardBackground from 'assets/card_background.png';
import { Container } from 'components/Container/Container';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import { useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useUpdateSippInterestMutation } from 'hooks/useUpdateSippInterestMutation';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Title } from '../../Cards.styles';
import { CardButton, ContentContainer } from './SIPPInterestCard.style';
import {
  SIPPInterestContentRegistered,
  SIPPInterestContentUnregisteredCard,
} from './SIPPInterestContent';

export interface PureSIPPInterestCardProps {
  interestRegistered: boolean;
  isLoading: boolean;
  onInterest: () => void;
}

export function PureSIPPInterestCard({
  interestRegistered,
  isLoading,
  onInterest,
}: PureSIPPInterestCardProps) {
  return (
    <>
      <Title>Pension</Title>
      {interestRegistered && (
        <ContentContainer vAlign="center" imgUrl={CardBackground}>
          <SIPPInterestContentRegistered />
        </ContentContainer>
      )}
      {!interestRegistered && (
        <>
          <ContentContainer vAlign="center" imgUrl={CardBackground}>
            <SIPPInterestContentUnregisteredCard />
          </ContentContainer>
          <CardButton
            $color="primary"
            onClick={() => onInterest()}
            disabled={isLoading}
          >
            I'm interested
          </CardButton>
        </>
      )}
    </>
  );
}

export interface SIPPInterestCardProps {
  pathName?: string;
  className?: string;
  dataTestid: string;
  onClick?: () => void;
}

export function SIPPInterestCard({
  className,
  pathName,
  onClick,
  dataTestid,
}: SIPPInterestCardProps) {
  const history = useHistory();
  const userProfileQuery = useUserProfileQuery();
  const { mutateAsync, isLoading } = useUpdateSippInterestMutation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (pathName) {
      let target = event.target as HTMLElement;

      while (
        target.nodeName !== 'BUTTON' &&
        target.nodeName !== 'A' &&
        target !== event.currentTarget
      ) {
        target = target.parentElement!;
      }

      if (target === event.currentTarget) {
        trackGa({
          event: GaEventNames.selectContent,
          content_type: 'dashboard',
          item_id: 'SIPP - carousel card clicked',
        });
        history.push(pathName);
        onClick?.();
      }
    }
  };

  const hasRegistered =
    userProfileQuery.data?.userProfile?.interestedInSipp ?? false;

  const handleRegisterInterest = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `register interest - sipp carousel card`,
    });
    await mutateAsync({});
  };

  return (
    <Container
      padding="medium"
      $elevation={1}
      className={className}
      onClick={handleClick}
      data-testid={dataTestid}
    >
      <QueryState {...userProfileQuery}>
        {() => (
          <PureSIPPInterestCard
            interestRegistered={hasRegistered}
            onInterest={handleRegisterInterest}
            isLoading={isLoading}
          />
        )}
      </QueryState>
    </Container>
  );
}
