import { AssetQueryAsset } from 'types/graphqlTypes';
import { NoContent, TeamContainer, Title } from '../Styles/FundDetails.style';
import { TeamContentContainer } from '../TeamContainer';

interface TeamSliceProps {
  asset: AssetQueryAsset;
}

export function TeamSlice({ asset }: TeamSliceProps) {
  const assetClassName = asset?.assetClass?.name || '';
  return (
    <>
      <TeamContainer>
        <Title>The Team</Title>

        {!asset?.isActivelyManaged ? (
          <NoContent>This fund is run by robots and algorithms.</NoContent>
        ) : !asset?.team.nodes.length ? (
          <NoContent>Meet the manager is not yet available</NoContent>
        ) : (
          <TeamContentContainer
            team={asset?.team.nodes}
            assetClass={assetClassName}
          />
        )}
        {/* {(() => {
                              if (
                                data.asset?.isActivelyManaged &&
                                data.asset?.team.nodes.length
                              ) {
                                return (
                                  <SmallCopy
                                    $size={16}
                                    $fontStyle="italic"
                                    copy="Source: The asset manager."
                                  />
                                );
                              }
                            })()} */}
      </TeamContainer>
    </>
  );
}
