import numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');

function parseNumber(value: string | number) {
  return typeof value === 'string' ? parseFloat(value) : value;
}

export function percent(value: string | number, showSign: boolean = false) {
  const floatValue = parseNumber(value);
  return (
    (showSign && floatValue > 0 ? '+' : '') +
    numeral(floatValue).format('0,0.00%')
  );
}

export function percent0dp(value: string | number, showSign: boolean = false) {
  const floatValue = parseNumber(value);
  return (
    (showSign && floatValue > 0 ? '+' : '') + numeral(floatValue).format('0%')
  );
}

export function percent3dp(
  value: string | number,
  showSign: boolean = false,
  showTrailingZeros: boolean = true
) {
  const floatValue = parseNumber(value);
  return (
    (showSign && floatValue > 0 ? '+' : '') +
    numeral(floatValue).format(showTrailingZeros ? '0,0.000%' : '0,0.[000]%')
  );
}

export function number3dp(
  value: string | number,
  showSign: boolean = false,
  showTrailingZeros: boolean = true
) {
  const floatValue = parseNumber(value);
  return (
    (showSign && floatValue > 0 ? '+' : '') +
    numeral(floatValue).format(showTrailingZeros ? '0,0.000' : '0,0.[000]')
  );
}

export function currency(value: string | number) {
  const floatValue = parseNumber(value);
  const format = floatValue > 1000000 ? '$0,0am' : '$0,0a';
  return numeral(floatValue).format(format);
}

export function currencyFull(
  value: string | number,
  decimal: boolean = true,
  showSign: boolean = true
) {
  const floatValue = parseNumber(value);

  return decimal
    ? numeral(floatValue).format(`${showSign ? '$' : ''}0,0.00`)
    : numeral(floatValue).format(`${showSign ? '$' : ''}0,0.[00]`);
}

export function date(value: string | number | Date, full: boolean = false) {
  if (!value) {
    return null;
  }
  const date =
    typeof value === 'string' || typeof value === 'number'
      ? new Date(value)
      : value;
  return full ? date.toLocaleString() : date.toLocaleDateString();
}

export function day(value: string | number) {
  if (!value) {
    return null;
  }
  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return value + nth(Number(value));
}

export function units(value: string | number) {
  const units = parseNumber(value);
  return numeral(units).format('0,0.[00000]');
}

export function mask(str: string, visibleChars: number = 2) {
  const offset = str.length - visibleChars;
  return '*'.repeat(offset) + str.substring(offset);
}

/**
 * Slugify a string by removing accents, converting to lowercase, and replacing non-alphanumeric characters with hyphens.
 *
 * @param {string} str - The string to be slugified.
 * @return {string} The slugified string.
 */
export function slugify(str: string) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}
