import clsx from 'clsx';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import { TransferLineStatus, TransfersQuery } from 'generated/graphql';
import indexOf from 'lodash/indexOf';
import orderBy from 'lodash/orderBy';
import { Dispatch, SetStateAction } from 'react';
import { ICAlternatives } from '../../../Icons/alternatives';
import { ICBonds } from '../../../Icons/bonds';
import { ICEquity } from '../../../Icons/equity';
import { ICMultiAsset } from '../../../Icons/multiAsset';
import { ICProperty } from '../../../Icons/property';
import {
  AssetClassLogo,
  HoldingContainer,
  HoldingContentContainer,
  HoldingDetail,
  HoldingDetailsRow,
  HoldingStatus,
  HoldingStatusDate,
  HoldingStatusIcon,
  HoldingTitle,
  HoldingsFilterButton,
  HoldingsFilterContainer,
  HoldingsLabelDivider,
  MagentaTick,
} from './ActiveTransferCard.styles';

type HoldingType = TransfersQuery['transfers'][number]['holdings'][number];

interface InSpecieHoldingProps {
  holding: HoldingType;
}

export const InSpecieHolding = ({ holding }: InSpecieHoldingProps) => {
  const isDarkUniverse =
    holding.isin !== 'CASH' &&
    (holding.instrument?.asset?.isDarkUniverse ?? true);

  // each status requires a different label and styling
  const getStatus = (status: string, isDarkUniverse: boolean) => {
    let label;
    let style;
    switch (status) {
      case TransferLineStatus.Completed:
        label = 'Complete';
        style = { color: colors.white, background: colors.magenta };
        break;
      case TransferLineStatus.InProgress:
        label = 'In progress';
        style = {
          color: colors.magenta,
          background: 'rgba(215, 22, 235, 0.1)',
        };
        break;
      case TransferLineStatus.Delayed:
        label = 'Delayed';
        style = { color: colors.white, background: colors.mauve };
        break;
      case TransferLineStatus.Rejected:
      default:
        label = 'Rejected';
        style = { color: colors.white, background: colors.black };
    }

    if (isDarkUniverse) {
      style = { color: colors.magenta, background: colors.white };
    }

    return { label, style };
  };

  const statusObject = getStatus(holding.status, isDarkUniverse);
  const getAssetClassLogo = (classId: string = 'DEFAULT') => {
    switch (classId) {
      case 'EQUITIES':
        return <ICEquity color={colors.purple} />;
      case 'BONDS':
        return <ICBonds color={colors.richBlue} />;
      case 'ALTS':
        return <ICAlternatives color={colors.seaBlue} />;
      case 'PROPERTY':
        return <ICProperty color={colors.magenta} />;
      case 'MULTI':
        return <ICMultiAsset color={colors.mauve} />;
    }
  };

  return (
    <HoldingContainer $isDark={isDarkUniverse}>
      <AssetClassLogo>
        {!isDarkUniverse &&
          holding.instrument?.asset?.assetClassId &&
          getAssetClassLogo(holding.instrument?.asset?.assetClassId)}
      </AssetClassLogo>
      <HoldingContentContainer>
        <HoldingTitle $isDark={isDarkUniverse}>
          {holding.instrumentName}
        </HoldingTitle>
        <HoldingDetailsRow>
          {!!holding.value && (
            <>
              <HoldingDetail $isDark={isDarkUniverse}>
                <span>Estimated value:</span>
                <span className="holding-detail-value">
                  {format.currencyFull(holding.value!, true)}
                </span>
              </HoldingDetail>
              {holding.isin !== 'CASH' && <HoldingsLabelDivider />}
            </>
          )}
          {!!holding.units && (
            <>
              <HoldingDetail $isDark={isDarkUniverse}>
                <span>Quantity:</span>
                <span className="holding-detail-value">
                  {holding.units} unit
                  {(holding.units > 1 || holding.units === 0) && 's'}
                </span>
              </HoldingDetail>
              <HoldingsLabelDivider />
            </>
          )}
          {holding.isin !== 'CASH' && (
            <>
              <HoldingDetail $isDark={isDarkUniverse}>
                <span>ISIN:</span>
                <span className="holding-detail-value">{holding.isin}</span>
              </HoldingDetail>
            </>
          )}
        </HoldingDetailsRow>
      </HoldingContentContainer>
      <HoldingStatus>
        <HoldingStatusIcon style={statusObject.style}>
          {statusObject.label}
        </HoldingStatusIcon>
        {holding.status === TransferLineStatus.Completed && (
          <HoldingStatusDate $isDark={isDarkUniverse}>
            {format.date(holding.lastUpdatedTimestampUtc)}
          </HoldingStatusDate>
        )}
      </HoldingStatus>
    </HoldingContainer>
  );
};

interface HoldingsFilterValues {
  complete: boolean;
  pending: boolean;
  delayed: boolean;
}
interface HoldingsFilterProps {
  filters: HoldingsFilterValues;
  updateFilters: Dispatch<SetStateAction<HoldingsFilterValues>>;
}

export const HoldingsFilter = ({
  filters,
  updateFilters,
}: HoldingsFilterProps) => {
  const handleUpdateFilters = (filter: string) => {
    updateFilters({
      ...filters,
      [filter]: !filters[filter as keyof HoldingsFilterValues],
    });
  };
  return (
    <HoldingsFilterContainer>
      <HoldingsFilterButton
        className={clsx(filters.complete && 'active')}
        onClick={() => handleUpdateFilters('complete')}
      >
        <span>Complete</span>
        {filters.complete && <MagentaTick />}
      </HoldingsFilterButton>
      <HoldingsFilterButton
        className={clsx(filters.pending && 'active')}
        onClick={() => handleUpdateFilters('pending')}
      >
        <span>In progress</span>
        {filters.pending && <MagentaTick />}
      </HoldingsFilterButton>
      <HoldingsFilterButton
        className={clsx(filters.delayed && 'active')}
        onClick={() => handleUpdateFilters('delayed')}
      >
        <span>Delayed</span>
        {filters.delayed && <MagentaTick />}
      </HoldingsFilterButton>
    </HoldingsFilterContainer>
  );
};

const TransferLineStatusOrder = [
  TransferLineStatus.Completed,
  TransferLineStatus.InProgress,
  TransferLineStatus.Delayed,
  TransferLineStatus.Rejected,
  TransferLineStatus.Unknown,
];

interface HoldingsFilterValues {
  complete: boolean;
  pending: boolean;
  delayed: boolean;
}

export const filterSortHoldings = (
  filters: HoldingsFilterValues,
  holdings: Array<HoldingType>
) => {
  const filteredHoldings = holdings.filter((holding) => {
    if (holding.status === TransferLineStatus.Completed) {
      return filters.complete === true;
    }
    if (holding.status === TransferLineStatus.InProgress) {
      return filters.pending === true;
    }
    if (
      [
        TransferLineStatus.Delayed,
        TransferLineStatus.Rejected,
        TransferLineStatus.Unknown,
      ].includes(holding.status)
    ) {
      return filters.delayed === true;
    }
    return false;
  });

  return orderBy(
    filteredHoldings,
    [
      (holding) => holding.isin === 'CASH',
      (holding) => indexOf(TransferLineStatusOrder, holding.status),
      (holding) => holding.instrument?.asset?.isDarkUniverse ?? true,
      (holding) => holding.value,
    ],
    ['asc', 'asc', 'asc', 'desc']
  );
};
