import { FontWeight, TextSmall } from 'components/design-system/Text/Text';
import {
  getBuyEstimate,
  getSellEstimate,
} from 'components/Dialogs/Trading/helpers';
import * as format from 'formatting';
import {
  Account,
  Position,
  RebalancingTransactionStatus,
} from 'generated/graphql';
import { max } from 'lodash';
import { PartialDeep } from 'type-fest';
import { AnyRebalancingBuyOrder } from 'types/graphqlTypes';
import { isRebalancingQueryBuyOrder } from './BuyOrderDetails';
import { SmallCopyWrapper } from './OrderStatusTable.styles';

export interface LegacyBuyOrderInProgressProps {
  buyOrder: AnyRebalancingBuyOrder;
  position: PartialDeep<Position> | undefined;
  selectedAccount: PartialDeep<Account>;
  totalBasketAmount: number;
}

export function LegacyBuyOrderInProgress({
  buyOrder,
  position,
  selectedAccount,
  totalBasketAmount,
}: LegacyBuyOrderInProgressProps) {
  const buyOrderComplete =
    buyOrder.status === RebalancingTransactionStatus.Completed;

  const instrument = buyOrder.instrument;
  const context = isRebalancingQueryBuyOrder(buyOrder)
    ? buyOrder.context
    : undefined;

  const charges = context ? context.charges : undefined;
  const askPrice = instrument?.askPrice! * instrument?.quoteUnit!;

  const existingUnits = position?.quantity ?? 0;
  const boughtUnits =
    buyOrderComplete && buyOrder.executedQuantity
      ? buyOrder.executedQuantity
      : getBuyEstimate(instrument!, buyOrder.amount!, charges!);
  const totalUnits = boughtUnits + existingUnits;
  const currentValue = getSellEstimate(instrument!, existingUnits);
  const currentPositionSize = position?.allocation;

  const currentMarketValue =
    selectedAccount?.valuationSummary?.marketValue || 0;
  const availableCash = selectedAccount?.valuationSummary?.uninvestedCash || 0;

  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;

  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;
  const estimatedPositionSize =
    (currentValue + buyOrder.amount!) / percentageOf;

  const totalCost = askPrice * boughtUnits;
  const fundFees = totalCost * ((instrument?.ongoingChargePercent ?? 0) / 100);
  const tillitFee = charges?.tillitFeeProportion
    ? totalCost * charges?.tillitFeeProportion
    : null;
  const totalFees = tillitFee ? fundFees + tillitFee : null;

  return (
    <>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Current position size:
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {existingUnits}{' '}
          {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Current value:{' '}
        </TextSmall>
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.currencyFull(currentValue)}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Est. new position:
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.percent(estimatedPositionSize)}{' '}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Last price update:
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.date(instrument?.priceDate)}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Est. fund fee (
          {format.percent((instrument?.ongoingChargePercent ?? 0) / 100)}
          pa):{' '}
        </TextSmall>
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {`${format.currencyFull(fundFees)} per annum`}{' '}
        </TextSmall>
      </SmallCopyWrapper>
      {totalFees && (
        <SmallCopyWrapper>
          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
            Est. total fee:{' '}
          </TextSmall>
          <TextSmall $noMargin $fontWeight={FontWeight.normal}>
            {format.currencyFull(totalFees)} per annum
          </TextSmall>
        </SmallCopyWrapper>
      )}
      {charges?.tillitFeeProportion && tillitFee && (
        <SmallCopyWrapper>
          <TextSmall $noMargin $fontWeight={FontWeight.medium}>
            Est. TILLIT fee ({format.percent(charges?.tillitFeeProportion)} pa):{' '}
          </TextSmall>
          <TextSmall $noMargin $fontWeight={FontWeight.normal}>
            {format.currencyFull(tillitFee)} per annum{' '}
          </TextSmall>
        </SmallCopyWrapper>
      )}
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Est. new position size:{' '}
        </TextSmall>
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.units(totalUnits)}{' '}
          {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
        </TextSmall>
      </SmallCopyWrapper>
      <SmallCopyWrapper>
        <TextSmall $noMargin $fontWeight={FontWeight.medium}>
          Current position size:
        </TextSmall>{' '}
        <TextSmall $noMargin $fontWeight={FontWeight.normal}>
          {format.percent(currentPositionSize!)}
        </TextSmall>
      </SmallCopyWrapper>
    </>
  );
}
