import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { ResetPasswordForm } from 'components/feature/resetPassword/ResetPasswordForm';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';

export function ResetPassword() {
  const theme = useTheme();
  const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));

  const rootStyling = isMobileUp
    ? [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ]
    : [
        {
          property: '--main-logo-color',
          value: 'black',
        },
        {
          property: '--main-header-color',
          value: 'black',
        },
      ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <StepFormWrapper>
      <StepWrapper>
        <ResetPasswordForm />
      </StepWrapper>
    </StepFormWrapper>
  );
}
